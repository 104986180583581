const data = ({
  "en1985": {
    "id": "en1985",
    "languageCode": "en",
    "year": "1985",
    "data": [
      {
        "number": "1",
        "name": "Praise to the Lord",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Praise to the Lord, the Almighty, the King of creation!", "O my soul, praise Him, for He is thy health and salvation!", "All ye who hear, now to His temple draw near;", "Join ye in glad adoration!"], ["Praise to the Lord, Who o'er all things so wondrously reigneth,", "Shieldeth thee under His wings, yea, so gently sustaineth!", "Hast thou not seen how thy desires e'er have been", "Granted in what He ordaineth?"], ["Praise to the Lord, who doth prosper thy work and defend thee;", "Surely His goodness and mercy here daily attend thee.", "Ponder anew what the Almighty can do,", "If with His love He befriend thee."]]
      }, {
        "number": "2",
        "name": "All creatures of our God and King",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["All creatures of our God and King,", "Lift up your voice with us and sing:", "Alleluia! Alleluia!", "O burning sun with golden beam", "And silver moon with softer gleam:"], ["O rushing wind and breezes soft,", "O clouds that ride the winds aloft:", "Oh, praise Him! Alleluia!", "O rising morn, in praise rejoice,", "O lights of evening, find a voice."], ["0 flowing waters, pure and clear,", "Make music for your Lord to hear.", "Oh praise Him! Alleluia!", "O fire so masterful and bright,", "Providing us with warmth and light."], ["Let all things their Creator bless,", "And worship him in humbleness,", "O praise him Alleluia!", "Oh, praise the Father, praise the Son,", "And praise the Spirit, three in One!"]],
        "refrain": ["Oh, praise Him! Oh, praise Him!", "Alleluia, alleluia, alleluia!"],
        "refrainFirst": false
      }, {
        "number": "3",
        "name": "God Himself Is With Us",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["God Himself is with us: Let us now adore Him,", "And with awe appear before Him.", "God is in His temple, all within keep silence,", "Prostrate lie with deepest reverence.", "Him alone God we own, Him our God and Savior;", "Praise His Name forever."], ["God Himself is with us: Hear the harps resounding!", "See the crowds the throne surrounding!", "\"Holy, holy, holy,\" hear the hymn ascending,", "Angels, saints, their voices blending!", "Bow Thine ear to us here: Hear, O Christ, the praises", "That Thy church now raises."], ["O Thou fount of blessing, purify my spirit;", "Trusting only in Thy merit,", "Like the holy angels who behold Thy glory,", "May I ceaselessly adore Thee,", "And in all, great and small, seek to do most nearly", "What Thou lovest dearly."]]
      }, {
        "number": "4",
        "name": "Praise, My Soul, the King of Heaven",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Praise, my soul, the King of heaven;", "To His feet thy tribute bring.", "Ransomed, healed, restored, forgiven,", "Who like thee His praise should sing?", "Praise Him, praise Him, Alleluia,", "Praise the everlasting King."], ["Praise Him for His grace and favor", "To our fathers in distress.", "Praise Him, still the same forever,", "Slow to chide and swift to bless;", "Praise Him, praise Him, Alleluia,", "Glorious in His faithfulness."], ["Tenderly He shields and spares us;", "Well our feeble frame He knows.", "In His hands He gently bears us,", "Rescues us from all our foes.", "Praise Him, praise Him, Alleluia,", "Widely as His mercy flows."], ["Angels, help us to adore Him:", "Ye behold Him face to face;", "Sun and moon, bow down before Him:", "Dwellers all in time and space.", "Praise Him, praise Him, Alleluia,", "Praise with us the God of grace."]]
      }, {
        "number": "5",
        "name": "All My Hope on God Is Founded",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["All my hope on God is founded;", "He doth still my trust renew,", "Me through change and chance He guideth,", "Only good and only true.", "God unknown, He alone", "Calls my heart to be His own."], ["Pride of man and earthly glory,", "Sword and crown betray his trust;", "What with care and toil he buildeth,", "Tower and temple fall to dust.", "But God's power, hour by hour,", "Is my temple and my tower."], ["God's great goodness aye endureth,", "Deep His wisdom, passing thought:", "Splendour, light and life attend Him,", "Beauty springeth out of naught.", "Love doth stand at His hand;", "Joy doth wait on His command."], ["Still from man to God eternal", "Sacrifice of praise be done,", "High above all praises praising", "For the gift of Christ His Son.", "Christ doth call one and all:", "Ye who follow shall not fall."]]
      }, {
        "number": "6",
        "name": "O Worship the Lord",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["O worship the Lord in the beauty of holiness,", "Bow down before Him, His glory proclaim;", "With gold of obedience, and incense of lowliness,", "Kneel and adore Him: the Lord is His name."], ["Low at His feet lay thy burden of carefulness,", "High on His heart He will bear it for thee,", "Comfort thy sorrows, and answer thy prayerfulness,", "Guiding thy steps as may best for thee be."], ["Fear not to enter His courts in the slenderness", "Of the poor wealth thou wouldst reckon as thine;", "Truth in its beauty, and love in its tenderness,", "These are the offerings to lay on His shrine."], ["These, though we bring them in trembling and fearfulness,", "He will accept for the name that is dear;", "Mornings of joy give for evenings of tearfulness,", "Trust for our trembling, and hope for our fear."]]
      }, {
        "number": "7",
        "name": "The Lord in Zion Reigneth",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["The Lord in Zion reigneth, let all the world rejoice,", "And come before His throne of grace with tuneful heart and", "voice;", "The Lord in Zion reigneth, and there His praise shall ring,", "To Him shall princes bend the knee and kings their glory bring."], ["The Lord in Zion reigneth, and who so great as He?", "The depths of earth are in His hands; He rules the mighty sea.", "O crown His Name with honor, and let His standard wave,", "Till distant isles beyond the deep shall own His power to save."], ["The Lord in Zion reigneth, these hours to Him belong;", "O enter now His temple gates, and fill His courts with song;", "Beneath His royal banner let every creature fall,", "Exalt the King of heaven and earth, and crown Him Lord of all."]]
      }, {
        "number": "8",
        "name": "We Gather Together",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["We gather together to ask the Lord's blessing;", "He chastens and hastens His will to make known.", "The wicked oppressing now cease from distressing.", "Sing praises to His Name; He forgets not His own."], ["Beside us to guide us, our God with us joining,", "Ordaining, maintaining His kingdom divine;", "So from the beginning the fight we were winning;", "Thou, Lord, were at our side, all glory be Thine!"], ["We all do extol Thee, Thou leader triumphant,", "And pray that Thou still our defender wilt be.", "Let Thy congregation escape tribulation;", "Thy name be ever praised! O Lord, make us free!"]]
      }, {
        "number": "9",
        "name": "Let All the World in Every Corner Sing",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Let all the world in every corner sing, my God and King!", "The heavens are not too high, His praise may thither fly,", "The earth is not too low, His praises there may grow.", "Let all the world in every corner sing, my God and King!"], ["Let all the world in every corner sing, my God and King!", "The church with psalms must shout, no door can keep them out;", "But, above all, the heart must bear the longest part.", "Let all the world in every corner sing, my God and King!"]]
      }, {
        "number": "10",
        "name": "Come, Christians, Join to Sing",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Come, Christians, join to sing,", "Al-le-lu-ia! A-men!", "Loud praise to Christ our King;", "Al-le-lu-ia! A-men!", "Let all, with heart and voice,", "Before His throne rejoice;", "Praise is His gracious choice:", "Al-le-lu-ia! A-men!"], ["Come, lift your hearts on high;", "Al-le-lu-ia! A-men!", "Let praises fill the sky;", "Al-le-lu-ia! A-men!", "He is our Guide and Friend;", "To us He'll condescend;", "His love shall never end:", "Al-le-lu-ia! A-men!"], ["Praise yet our Christ again;", "Al-le-lu-ia! A-men!", "Life shall not end the strain;", "Al-le-lu-ia! A-men!", "On heaven's blissful shore", "His goodness we'll adore,", "Singing forevermore,", "Al-le-lu-ia! A-men!"]]
      }, {
        "number": "11",
        "name": "The God of Abraham Praise",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["The God of Abraham praise,", "Who reigns enthroned above;", "Ancient of everlasting days,", "And God of love;", "Jehovah! Great I AM!", "By earth and heaven confessed;", "I bow and bless the sacred name,", "Forever blest."], ["The God of Abraham praise,", "At whose supreme command", "From earth I rise, and seek the joys", "At His right hand;", "I all on earth forsake,", "Its wisdom, fame and power;", "And Him my only portion make,", "My shield and tower."], ["The whole triumphant host", "Give thanks to God on high;", "\"Hail, Father, Son, and Holy Ghost!\"", "They ever cry;", "Hail, Abraham's God and mine!", "I join the heavenly lays;", "All might and majesty are Thine,", "And endless praise."]]
      }, {
        "number": "12",
        "name": "Joyful, Joyful, We Adore Thee",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Joyful, joyful, we adore Thee,", "God of glory, Lord of love;", "Hearts unfold like flow'rs before Thee,", "Hail Thee as the sun above.", "Melt the clouds of sin and sadness,", "Drive the dark of doubt away;", "Giver of immortal gladness,", "Fill us with the light of day!"], ["All Thy works with joy surround Thee,", "Earth and heav'n reflect Thy rays,", "Stars and angels sing around Thee,", "Center of unbroken praise;", "Field and forest, vale and mountain,", "Bloss'ming meadow, flashing sea,", "Chanting bird and flowing fountain", "Call us to rejoice in Thee."], ["Thou art giving and forgiving,", "Ever blessing, ever blest,", "Wellspring of the joy of living,", "Oceandepth of happy rest!", "Thou the father, Christ our Brother -", "All who live in love are Thine:", "Teach us how to love each other,", "Lift us to the joy divine."]]
      }, {
        "number": "13",
        "name": "New Songs of Celebration Render",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["New songs of celebration render", "To Him who has great wonders done.", "Love sits enthroned in ageless splendor:", "Come, and adore the mighty One.", "He has made known His great salvation", "Which all His friends with joy confess:", "He has revealed to every nation", "His everlasting righteousness."], ["Joyfully, heartily resounding,", "Let every instrument and voice", "Peal out the praise of grace abounding", "Calling the whole world to rejoice.", "Trumpets and organs, set in motion", "Such sounds as make the heavens ring;", "All things that live in earth and ocean,", "Make music for your mighty King."], ["Rivers and seas and torrents roaring,", "Honor the Lord with wild acclaim;", "Mountains and stones look up adoring,", "And find a voice to praise His name.", "Righteous, commanding, ever glorious,", "Praises be His that never cease:", "Just is our God, whose truth victorious,", "Establishes the world in peace."]]
      }, {
        "number": "14",
        "name": "Let Us Praise the Name of the Lord",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Let us praise the name of the Lord!", "Give Him glory, Amen."], ["Go ye into all the world.", "Alleluia, Amen."], ["Amen, amen,", "Amen, amen."]]
      }, {
        "number": "15",
        "name": "My Maker and My King",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["My Maker and my King,", "To Thee my all I owe;", "Thy sovereign bounty is the spring", "Whence all my blessings flow;", "Thy sovereign bounty is the spring", "Whence all my blessings flow."], ["The creature of Thy hand,", "On Thee alone I live;", "My God, Thy benefits demand", "More praise than I can give.", "My God, Thy benefits demand", "More praise than I can give."], ["Lord, what can I impart", "When all is Thine before?", "Thy love demands a thankful heart;", "The gift, alas! how poor.", "Thy love demands a thankful heart;", "The gift, alas! how poor."], ["O! let Thy grace inspire", "My soul with strength divine;", "Let every word each desire", "And all my days be Thine.", "Let every word each desire", "And all my days be Thine."]]
      }, {
        "number": "16",
        "name": "All People That on Earth Do Well",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["All people that on earth do dwell,", "sing to the Lord with cheerful voice.", "Him serve with mirth, his praise forth tell;", "come ye before him and rejoice."], ["Know that the Lord is God indeed;", "without our aid he did us make;", "we are his folk, he doth us feed,", "and for his sheep he doth us take."], ["O enter then his gates with praise;", "approach with joy his courts unto;", "praise, laud, and bless his name always,", "for it is seemly so to do."], ["For why! the Lord our God is good;", "his mercy is forever sure;", "his truth at all times firmly stood,", "and shall from age to age endure."]]
      }, {
        "number": "17",
        "name": "Lord of All Being, Throned Afar",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Lord of all being, throned afar,", "Thy glory flames from sun and star;", "Centre and soul of every sphere,", "Yet to each loving heart how near!", "Yet to each loving heart how near!"], ["Sun of our life, Thy quickening ray", "Shed on our path the glow of day;", "Star of our hope, Thy softened light", "Cheers the long watches of the night,", "Cheers the long watches of the night."], ["Our midnight is Thy smile withdrawn;", "Our noontide is Thy gracious dawn;", "Our rainbow arch, Thy mercy's sign;", "All, save the clouds of sin, are Thine,", "All, save the clouds of sin, are Thine."], ["Lord of all life, below, above,", "Whose light is truth, whose warmth is love,", "Before Thy ever-blazing throne", "We ask no lustre of our own,", "We ask no lustre of our own."], ["Grant us Thy truth to make us free,", "And kindling hearts that burn for Thee;", "Till all Thy living altars claim", "One holy light, one heavenly flame!", "One holy light, one heavenly flame!"]]
      }, {
        "number": "18",
        "name": "O Morning Star, How Fair and Bright",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["O Morning Star, how fair and bright!", "You shine with God's own truth and light,", "Aglow with grace and mercy!", "Of Jacob's race, King David's son,", "Our Lord and master, You have won", "Our hearts to serve You only!", "Lowly, holy!", "Great and glorious,", "All victorious,", "Rich in blessing!", "Rule and might o'er all possessing!"], ["Lord, when You look on us in love,", "At once there falls from God above", "A ray of purest pleasure.", "Your Word and Spirit, flesh and blood", "Refresh our souls with heavenly food.", "You are our dearest treasure!", "Let Your mercy", "Warm and cheer us!", "O draw near us!", "For You teach us", "God's own love through You has reached us."], ["Almighty Father, in Your Son", "You loved us when not yet begun", "Was this old earth's foundation!", "Your Son has ransomed us in love", "To live in Him here and above;", "This is Your great salvation.", "Alleluia!", "Christ the living,", "To us giving", "Life forever,", "Keeps us Yours and fails us never!"], ["O let the harps break forth in sound!", "Our joy be all with music crowned,", "Our voices gladly blending!", "For Christ goes with us all the way-", "Today, tomorrow, every day!", "His love is never ending!", "Sing out!  Ring out!", "Jubilation!", "Exultation!", "Tell the story!", "Great is He, the King of Glory!"]]
      }, {
        "number": "19",
        "name": "O Sing a New Song to the Lord",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["O sing a new song to the Lord", "For marvels He has done;", "His right hand and His holy arm", "The victory have won."], ["With harp, and voice of psalms", "Unto Jehovah sing;", "Let trumpets and the echoing horn", "Acclaim the Lord our King!"], ["Let seas with all their creatures roar,", "The world and dwellers there,", "And let the rivers clap their hands,", "The hills their joy declare."], ["Before the Lord: because He comes,", "To judge the earth come He;", "He'll judge the world", "with righteousness,", "His folk with equity."]]
      }, {
        "number": "20",
        "name": "O Praise Ye the Lord",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["O praise ye the Lord!", "Praise Him in the height;", "Rejoice in His word,", "Ye angels of light;", "Ye heavens, adore Him", "By whom ye were made,", "And worship before Him,", "In brightness arrayed."], ["O praise ye the Lord!", "Praise Him upon earth,", "In tuneful accord:", "Ye sons of new birth;", "Praise Him who hath brought", "you His grace from above,", "Praise Him who hath taught", "you To sing of His love."], ["O praise ye the Lord,", "All things that give sound;", "Each jubilant chord,", "Re-echo around;", "Loud organs His glory", "Forth tell in deep tone,", "And sweet harp, the story", "Of what He hath done."], ["O praise ye the Lord!", "Thanksgiving and song", "To Him be outpoured", "All ages along:", "For love in creation,", "For heaven restored.", "For grace of salvation,", "O praise ye the Lord!"]]
      }, {
        "number": "21",
        "name": "Immortal, Invisible, God Only Wise",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Immortal, invisible, God only wise,", "In light inaccessible hid from our eyes,", "Most blessed, most glorious, the Ancient of Days,", "Almighty, victorious, Thy great Name we praise."], ["Unresting, unhasting, and silent as light,", "Nor wanting, nor wasting, Thou rulest in might;", "Thy justice, like mountains, high soaring above", "Thy clouds, which are fountains of goodness and love."], ["To all, life Thou givest, to both great and small;", "In all life Thou livest, the true life of all;", "We blossom and flourish as leaves on the tree,", "And wither and perish - but naught changeth Thee."], ["Great Father of glory, pure Father of light,", "Thine angels adore Thee, all veiling their sight;", "All praise we would render; O help us to see", "'Tis only the splendor of light hideth Thee!"]]
      }, {
        "number": "22",
        "name": "God Is Our Song",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["God is our Song, and every singer blest", "Who praising Him finds energy and rest.", "All who praise God with unaffected joy", "Give back to us the widom we destroy,", "Give back to us the widom we destroy."], ["God is our Song, for Jesus comes to save;", "While praising Him we offer all we have.", "New songs we sing, in ventures new unite,", "When Jesus leads us upward into light,", "When Jesus leads us upward into light."], ["This is our Song no conflict ever drowns;", "Who praises God our human wrath disowns.", "Love knows what rich complexities of sound", "God builds upon a simple common ground,", "God builds upon a simple common ground."], ["God is our Silence when no songs are sung,", "When ecstasy or sorrow stills the tongue.", "Glorious the faith which silently obeys", "Until we find again the voice of praise,", "Until we find again the voice of praise."]]
      }, {
        "number": "23",
        "name": "Now the Joyful Bells A-Ringing",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Now the joyful bells a-ringing,", "All ye mountains, praise the Lord!", "Lift our hearts like birds a winging,", "All ye mountains, praise the Lord!", "Now our festal season bringing", "Kinsmen all to bide and board,", "Sets our cheery voices singing:", "All ye mountains, praise the Lord!"], ["Dear our home as dear none other;", "Where the mountains praise the Lord!", "Gladly here our care we smother;", "Where the mountains praise the Lord!", "Here we know that Christ our brother", "Binds us all as by a cord:", "He was born of Mary mother", "Where the mountains praise the Lord!"], ["Cold the year, new whiteness wearing,", "All ye mountains, praise the Lord!", "Peace, good will to us abearing,", "All ye mountains praise the Lord!", "Now we all God's goodness sharing", "Break the bread and sheath the sword:", "Bright our hearts the signal flaring,", "All ye mountains, praise the Lord!"]]
      }, {
        "number": "24",
        "name": "Every Star Shall Sing a Carol",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Every star shall sing a carol,", "Every creature high or low.", "Come and praise the King of Heaven", "By whatever name you know."], ["When the king of all creation,", "Had a cradle on the earth.", "Holy was the human body,", "Holy was the human birth."], ["Every star and every planet,", "Every creature high and low.", "Come and praise the King of Heaven,", "By whatever name you know."]],
        "refrain": ["God above man below,", "Holy is the name I know."],
        "refrainFirst": false
      }, {
        "number": "25",
        "name": "Praise the Lord, His Glories Show",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Praise the Lord, His glories show, Alleluia!", "Saints within His courts below, Alleluia!", "Angels 'round His throne above, Alleluia!", "All that see and share His love, Alleluia!"], ["Earth to heaven and heaven to earth, Alleluia!", "Tell His wonders, sing His worth, Alleluia!", "Age to age and shore to shore, Alleluia!", "Praise Him, praise Him evermore! Alleluia!"], ["Praise the Lord, His mercies trace, Alleluia!", "Praise His providence and grace, Alleluia!", "All that He for man hath done, Alleluia!", "All He sends us through His Son. Alleluia!"]]
      }, {
        "number": "26",
        "name": "Praise the Lord! You Heavens Adore Him",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Praise the Lord: you heavens, adore Him;", "Praise Him, angels in the height;", "Sun and moon, rejoice before Him;", "praise Him, all you stars of light.", "Praise the Lord, for He has spoken;", "worlds His mighty voice obeyed.", "Laws which never shall be broken", "for their guidance He has made."], ["Praise the Lord! for He is glorious;", "never shall His promise fail.", "God has made His saints victorious;", "sin and death shall not prevail.", "Praise the God of our salvation!", "hosts on high, His power proclaim.", "Heaven and earth and all creation,", "laud and magnify His Name."], ["Worship, honor, glory, blessing,", "Lord, we offer as our gift.", "Young and old, Your praise expressing,", "Our glad songs to You we lift.", "All the saints in heaven adore You;", "we would join their glad acclaim;", "As Your angels serve before You,", "so on earth we praise Your name."]]
      }, {
        "number": "27",
        "name": "Rejoice, Ye Pure in Heart!",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Rejoice ye pure in heart!", "Rejoice, give thanks, and sing;", "Your festal banner wave on high,", "The cross of Christ your King."], ["With voice as full and strong", "As ocean's surging praise,", "Send forth the sturdy hymns of old,", "The psalms of ancient days."], ["With all the angel choirs,", "With all the saints of earth,", "Pour out the strains of joy and bliss,", "True rapture, noblest mirth."], ["Yes, on through life's long path,", "Still chanting as ye go;", "From youth to age, by night and day,", "In gladness and in woe."], ["Praise Him who reigns on high,", "The Lord whom we adore,", "The Father, Son and Holy Ghost,", "One God forever more.", "(Refrain)"]],
        "refrain": ["Rejoice, rejoice, rejoice,", "Give thanks and sing."],
        "refrainFirst": false
      }, {
        "number": "28",
        "name": "Praise We the Lord",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Praise we the Lord, who made all beauty", "For all our senses to enjoy;", "Owe we our humble thanks and duty", "That simple pleasures never cloy;", "Praise we the Lord, who made all beauty", "For all our senses to enjoy."], ["Praise Him who loves to see young lovers,", "Fresh hearts that swell with youthful pride;", "Thank Him who sends the sun above us,", "As bridegroom fit to meet his bride;", "Praise Him who loves to see young lovers,", "Fresh hearts that swell with youthful pride."], ["Praise Him who by a simple flower", "Lifts up our hearts to things above;", "Thank Him who gives to each one power", "To find a friend to know and love;", "Praise Him who by a simple flower", "Lifts up our hearts to things above."]]
      }, {
        "number": "29",
        "name": "Sing Praise to God",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Sing praise to God who reigns above,", "the God of all creation,", "the God of power, the God of love,", "the God of our salvation.", "With healing balm my soul He fills,", "and every faithless murmur stills;", "To God all praise and glory!"], ["What God's almighty power hath made", "His gracious mercy keepeth;", "By morning glow or evening shade,", "His watchful eye ne'er sleepeth,", "Within the kingdom of his might,", "Lo! all is just, and all is right:", "To God all praise and glory!"], ["The Lord is never far away,", "throughout all grief distressing,", "an ever present help and stay,", "our peace and joy and blessing.", "As with a mother's tender hand,", "He leads His own, His chosen band:", "To God all praise and glory!"], ["Then all my gladsome way along,", "I sing aloud thy praises,", "that men may hear the grateful song", "my voice unwearied raises:", "Be joyful in the Lord, my heart!", "both soul and body bear your part!", "To God all praise and glory."]]
      }, {
        "number": "30",
        "name": "Holy God, We Praise Your Name",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Holy God, we praise Your name;", "Lord of all, we bow before You!", "All on earth Your scepter claim,", "All in heaven above adore You;", "Infinite Your vast domain.", "Everlasting is Your reign."], ["Hark! The loud celestial hymn", "Angle choirs above are raising,", "Cherubim and seraphim,", "In unceasing chorus praising;", "Fill the heavens with sweet accord:", "Holy, holy, holy, Lord."], ["Holy Father, Holy Son,", "Holy Spirit, three we name You;", "While in essence only one,", "Undivided God we claim You;", "And adoring bend the knee,", "While we own the mystery."]]
      }, {
        "number": "31",
        "name": "Tell Out, My Soul",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Tell out, my soul, the greatness of the Lord:", "Unnumbered blessings give my spirit voice;", "Tender to me the promise of His Word;", "In God my Savior shall my heart rejoice."], ["Tell out, my soul, the greatness of His name:", "Make known His might, the deeds His arm has done;", "His mercy sure, from age to age the same;", "His holy Name, the Lord, the mighty One."], ["Tell out, my soul, the greatness of His might:", "Powers and dominions lay their glory by;", "Proud hearts and stubborn wills are put to flight;", "The hungry fed, the humble lifted high."], ["Tell out, my soul, the glories of His Word:", "Firm is His promise, and His mercy sure.", "Tell out my soul, the greatness of the Lord", "To children's children and forevermore!"]]
      }, {
        "number": "32",
        "name": "When in Our Music God Is Glorified",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["When in our music God is glorified,", "and adoration leaves no room for pride,", "it is as though the whole creation cried", "Alleluia!"], ["How often, making music, we have found", "a new dimension in the world of sound,", "as worship moved us to a more profound", "Alleluia!"], ["And did not Jesus sing a psalm that night", "when utmost evil strove against the Light?", "Then let us sing, for whom he won the fight,", "Alleluia!"], ["Let every instrument be tuned for praise!", "Let all rejoice who have a voice to raise!", "And may God give us faith to sing always", "Alleluia!"]]
      }, {
        "number": "33",
        "name": "Sing a New Song to the Lord",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Sing a new song to the Lord,", "he to whom wonders belong!", "Rejoice in his triumph and tell of his power.", "O sing a new song to the Lord."], ["Now to the ends of the earth", "see his salvation is shown!", "And still he remembers his mercy and truth,", "unchanging in love to his own."], ["Sing a new song and rejoice,", "publish his praises abroad!", "Let voices in chorus with trumpet and horn,", "resound for the joy of the Lord."], ["Join with the hills and the sea,", "thunders of praise to prolong!", "In judgment and justice he comes to the earth.", "O sing a new song to the Lord."]]
      }, {
        "number": "34",
        "name": "Wake the Song",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Wake the song of joy and gladness;", "Hither bring your noblest lays;", "Bannish every thought of sadness,", "Pouring forth your highest praise.", "Sing to Him whose care has brought us", "Once again with friends to meet,", "And whose loving voice has taught us", "Of the way to Jesus' feet."], ["Joyfully with songs and banners,", "We will greet the festal day;", "Shout aloud our glad hosannas,", "And our grateful homage pay.", "We will chant our Savior's glory", "while our thoughts we raise above,", "Telling still the old, old, story,", "Precious theme- redeeming love!"], ["Thanks to Thee, O holy Father,", "For the mercies of the year;", "May each heart, as here we gather,", "Swell with gratitude sincere,", "Thanks to Thee, O loving Savior,", "For redemption through Thy blood.", "Breathe upon us, Holy Spirit,", "Sweetly draw us near to God."]],
        "refrain": ["Wake the song, wake the song,", "The song of joy and gladness,", "Wake the song, wake the song,", "The song of Jubilee."],
        "refrainFirst": false
      }, {
        "number": "35",
        "name": "With Songs and Honors",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["With songs and honours sounding loud,", "Address the Lord on high;", "Over the heavens He spreads His cloud,", "And waters veil the sky."], ["He sends His showers of blessing down", "To cheer the plains below;", "He makes the grass the mountains crown,", "And corn in the valleys grow."], ["His steady counsels change the face", "Of the declining year;", "He bids the sun cut short his race,", "And wintry days appear."], ["He sends His word, and melts the snow;", "The fields no longer mourn;", "He calls the warmer gales to blow,", "And bids the spring return."], ["The changing wind, the flying cloud,", "Obey His mighty word:", "With songs and honours sounding loud", "Praise ye the sovereign Lord!"]]
      }, {
        "number": "36",
        "name": "O Thou in Whose Presence",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["O Thou in whose presence my soul takes delight,", "On whom in affliction I call,", "My comfort by day and my song in the night,", "My hope, my salvation, my all."], ["His voice, as the sound of the dulcimer sweet,", "Is heard through the shadows of death;", "The cedars of Lebanon bow at His feet,", "The air is perfumed with His breath."], ["His lips, as a fountain of righteousness flow,", "To water the gardens of grace;", "From which their salvation the Gentiles shall know,", "And bask in the smiles of His face."], ["He looks, and ten thousands of angels rejoice,", "And myriads wait for His word;", "He speaks, and eternity, filled with His voice,", "Re-echoes the praise of the Lord."]]
      }, {
        "number": "37",
        "name": "O Sing, My Soul, Your Maker's Praise",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["O sing my soul, your Maker's praise", "In grateful hymns ascending;", "Whose steadfast love has crowned your days", "With heav'nly gifts unending.", "I sought the Lord, He heard my cry;", "His holy angels hover nigh", "The tents of those who love Him."], ["The Lord is good to those who seek", "His face in time of sorrow,", "Providing comfort to the weak", "And grace for each tomorrow.", "Though grief may tarry for a night,", "The morn shall break in joy and light", "With blessings from His presence."], ["The Lord will turn His face in peace", "When troubled souls draw near Him;", "His loving-kindness shall not cease", "To those who trust and fear Him.", "Our God will not forsake His own;", "Eternal is His heav'nly throne;", "His kingdom stands forever."]]
      }, {
        "number": "38",
        "name": "Arise, My Soul, Arise!",
        "category": "Worship",
        "subcategory": "Adoration and Praise",
        "verses": [["Arise, my soul, arise! Stretch", "forth to things eternal", "And hasten to the feet of your Redeemer God.", "Though hid from mortal eyes,", "He dwells in light supernal;", "Yet worship Him in humbleness and call Him Lord.", "His banquet of love Awaits you above;", "Yet here He grants a foretaste of the feast to", "the feast to come! Rejoice, my soul, rejoice,", "To heav'n lift up your voice:", "Alleluia, alleluia, alleluia!"], ["Now hear the harps of heav'n!", "Oh, hear the song victorious,", "The neverending anthem sounding through the sky!", "To mortals is not giv'n To join in strains so glorious;", "Yet here on earth we too can sing our praises high!", "He bought with His blood The ransomed of God;", "To Him be ever lasting pow'r and victory.", "And let the great amen Resound through heav'n again.", "Alleluia, alleluia, alleluia!"]]
      }, {
        "number": "39",
        "name": "Lord, in the Morning",
        "category": "Worship",
        "subcategory": "Morning Worship",
        "verses": [["Lord, in the morning Thou shalt hear", "My voice ascending high;", "To Thee will I direct my prayer,", "To Thee lift up mine eye-"], ["Up to the hills where Christ is gone", "To plead for all His saints,", "Presenting at His Father's throne", "Our songs and our complaints."], ["O may Thy Spirit guide my feet", "In ways of righteousness;", "Make every path of duty straight", "And plain before my face."], ["The men that love and fear Thy name", "Shall see their hopes fulfilled;", "The mighty God will compass them", "With favor as a shield."]]
      }, {
        "number": "40",
        "name": "The Dawn of God's Dear Sabbath",
        "category": "Worship",
        "subcategory": "Morning Worship",
        "verses": [["The dawn of God's dear Sabbath", "Breaks o'er the earth again,", "As some sweet summer morning", "After a night of pain;", "It comes as cooling showers", "To some exhausted land,", "As shade of clustered palm trees", "'Mid weary wastes of sand."], ["Lord, we would bring for offering,", "Though marred with earthly soil,", "A week of earnest labor,", "Of steady, faithful toil,", "Fair fruits of self denial,", "Of strong, deep love to Thee,", "Fostered by Thine own Spirit,", "In true humility."], ["And we would bring our burden", "Of sinful thought and deed,", "In Thy pure presence kneeling,", "From bondage to be freed,", "Our heart's most bitter sorrow", "For all Thy work undone-", "So many talents wasted!", "So few bright laurels won!"], ["And with that sorrow mingling,", "A steadfast faith, and sure,", "And love so deep and fervent,", "For Thee to make it pure,", "In Thy dear presence finding", "The pardon that we need,", "And then the peace so lasting-", "Celestial peace indeed."]]
      }, {
        "number": "41",
        "name": "O Splendor of God's Glory Bright",
        "category": "Worship",
        "subcategory": "Morning Worship",
        "verses": [["O splendor of God's glory bright,", "From light eternal bringing light;", "O Light of life, light's living spring,", "True day, all days illumining."], ["O Thou true Sun, on us Thy glance", "Let fall in royal radiance;", "The Spirit's sanctifying beam", "Upon our earthly senses stream."], ["O joyful be the passing day", "With thoughts as clear as morning's ray,", "With faith like noontide shining bright,", "Our souls unshadowed by the night."], ["Dawn's glory gilds the earth and skies;", "Let Him, our perfect morn, arise;", "The Father's help His children claim,", "And sing the Father's glorious name. Amen."]]
      }, {
        "number": "42",
        "name": "Now That the Daylight Fills the Sky",
        "category": "Worship",
        "subcategory": "Morning Worship",
        "verses": [["Now that the daylight fills the sky,", "We lift our hearts to God on high,", "That He, in all we do or say,", "Would keep us free from our harm today;"], ["Would guard our hearts and tongues", "from strife;", "From anger's din would shield our life;", "From evil sights would turn our eyes,", "And close our ears to vanities;"], ["So we, when this new day is gone", "And night in turn is drawing on,", "With conscience by the world unstained", "Shall praise His name for vict'ry gained."], ["\"All praise to You, creator Lord!", "All praise to You, eternal Word!", "All praise to You, O Spirit wise!\"", "We sing as daylight fills the skies."]]
      }, {
        "number": "43",
        "name": "When Morning Gilds the Skies",
        "category": "Worship",
        "subcategory": "Morning Worship",
        "verses": [["When morning gilds the skies my heart awaking cries,", "May Jesus Christ be praised!", "Alike at work and prayer, to Jesus I repair:", "May Jesus Christ be praised!"], ["Whene'er the sweet church bell peals over hill and dell,", "May Jesus Christ praised!", "O hark to what it sings, as joyously it rings,", "May Jesus Christ be praised!"], ["The night becomes as day when from the heart we say:", "May Jesus Christ be praised!", "The powers of darkness fear when this sweet chant they hear:", "May Jesus Christ be praised!"], ["Ye nations of mankind, in this your concord find,", "May Jesus Christ praised!", "Let all the earth around ring joyous with the sound,", "May Jesus Christ praised!"], ["In heaven's eternal bliss the loveliest strain is this,", "May Jesus Christ praised!", "Let earth, and sea and sky from depth to height reply,", "May Jesus Christ praised!"], ["Be this, while life is mine, my canticle divine:", "May Jesus Christ be praised!", "Be this th'eternal song through all the ages long,", "May Jesus Christ be praised!"]]
      }, {
        "number": "44",
        "name": "Morning Has Broken",
        "category": "Worship",
        "subcategory": "Morning Worship",
        "verses": [["Morning has broken like the first morning,", "Blackbird has spoken like the first bird.", "Praise for the singing!Praise for the morning!", "Praise for the springing fresh from the Word!"], ["Sweet the rain's new fall sunlit from heaven,", "Like the first dewfall on the first grass.", "Praise for the sweetness of the wet garden,", "Sprung in completeness where His feet pass."], ["Mine is the sunlight! Mine is the morning", "Born of the one light Eden saw play!", "Praise with elation, Praise every morning,", "God's recreation of the new day!"]]
      }, {
        "number": "45",
        "name": "Open Now Thy Gates of Beauty",
        "category": "Worship",
        "subcategory": "Morning Worship",
        "verses": [["Open now thy gates of beauty,", "Zion, let me enter there,", "Where my soul in joyful duty", "Waits for God Who answers prayer.", "Oh, how blessed is this place,", "Filled with solace, light, and grace!"], ["Gracious God, I come before Thee,", "Come Thou also unto me;", "Where we find Thee and adore Thee,", "There a heav'n on earth must be.", "To my heart, oh, enter Thou,", "Let it be Thy temple now!"], ["Here Thy praise is gladly chanted,", "Here Thy seed is duly sown;", "Let my soul, where it is planted,", "Bring forth precious sheaves alone,", "So that all I hear may be", "Fruitful unto life in me."], ["Thou my faith increase and quicken,", "Let me keep Thy gift divine,", "Howsoe'er temptations thicken;", "May Thy Word still o'er me shine", "As my guiding star through life,", "As my comfort in my strife."], ["Speak, O God, and I will hear Thee,", "Let Thy will be done indeed;", "May I undisturbed draw near Thee", "While Thou dost Thy people feed.", "Here of life the fountain flows;", "Here is balm for all our woes."]]
      }, {
        "number": "46",
        "name": "Abide With Me, 'Tis Eventide",
        "category": "Worship",
        "subcategory": "Evening Worship",
        "verses": [["Abide with me, 'tis eventide!", "The day is past and gone;", "The shadows of the evening fall;", "The night is coming on!", "Within my heart a welcome guest,", "Within my home abide;"], ["Abide with me, 'tis eventide!", "Thy walk today with me", "Has made my heart within me burn,", "As I communed with Thee.", "Thy earnest words have filled my soul", "And kept me near Thy side;"], ["Abide with me, 'tis eventide!", "And lone will be the night,", "If I cannot commune with Thee,", "Nor find in Thee my light.", "The darkness of the world, I fear,", "Would in my home abide;"]],
        "refrain": ["O Savior, stay this night with me;", "Behold, 'tis eventide!", "O Savior, stay this night with me;", "Behold, 'tis eventide."],
        "refrainFirst": false
      }, {
        "number": "47",
        "name": "God, Who Made the Earth and Heaven",
        "category": "Worship",
        "subcategory": "Evening Worship",
        "verses": [["God, who made the earth and heaven, Darkness and light:", "You the day for work have given, For rest the night.", "May Your angel guards defend us,", "Slumber sweet Your mercy send us,", "Holy dreams and hopes attend us", "All through the night."], ["And when morn again shall call us to run life's way,", "May we still, what-e'er befall us, Your will obey.", "From the pow'r of evil hide us,", "In the narrow pathway guide us,", "Never be Your smile denied us", "All through the day."], ["Guard us waking, guard us sleeping, And, when we die,", "May we in Your mighty keeping All peaceful lie.", "When the trumpet call shall wake us, Then,", "O Lord, do not forsake us,", "But to reign in glory take us with You on high."], ["Holy Father, throned in heaven, All holy Son,", "Holy Spirit, freely given, Blest Three in One:", "Grant us grace, we now implore You,", "Till we lay our crowns before You", "And in worthier strains adore You while ages run."]]
      }, {
        "number": "48",
        "name": "Softly Now the Light of Day",
        "category": "Worship",
        "subcategory": "Evening Worship",
        "verses": [["Softly now the light of day", "Fades upon out sight away:", "Free from care, from labor free,", "Lord, we would commune with Thee."], ["Thou, whose allpervading eye", "Nought escapes, without, within,", "Pardon each infirmity,", "Open fault, and secret sin."], ["Soon from us the light of day", "Shall forever pass away;", "Then, from sin and sorrow free,", "Take us, Lord, to dwell with Thee."]]
      }, {
        "number": "49",
        "name": "Savior, Breathe an Evening Blessing",
        "category": "Worship",
        "subcategory": "Evening Worship",
        "verses": [["Savior, breathe an evening blessing,", "Ere repose our spirits seal;", "Sin and want we come confessing;", "Thou canst save, and Thou canst heal."], ["Though the night be dark and dreary,", "Darkness cannot hide from Thee;", "Thou art He who, never weary,", "Watchest where Thy people be."], ["Though destruction walk around us.", "Though the arrow past us fly,", "Angel guards from Thee Surround us,", "We are safe if Thou art nigh."], ["Should swift death this night o're-take us,", "And our couch become our tomb,", "May the morn of glory wake us,", "Clad in light and deathless bloom."]]
      }, {
        "number": "50",
        "name": "Abide With Me",
        "category": "Worship",
        "subcategory": "Evening Worship",
        "verses": [["Abide with me; fast falls the eventide;", "The darkness deepens; Lord with me abide!", "When other helpers fail and comforts flee,", "Help of the helpless, O abide with me."], ["Swift to its close ebbs out life's little day;", "Earth's joys grow dim; its glories pass away;", "Change and decay in all around I see;", "O Thou who changest not, abide with me."], ["I need Thy presence every passing hour.", "What but Thy grace can foil the tempter's power?", "Who, like Thyself, my guide and stay can be?", "Through cloud and sunshine, Lord, abide with me."], ["I fear no foe, with Thee at hand to bless;", "Ills have no weight, and tears no bitterness.", "Where is death's sting? Where, grave, thy victory?", "I triumph still, if Thou abide with me!"]]
      }, {
        "number": "51",
        "name": "Day Is Dying in the West",
        "category": "Worship",
        "subcategory": "Evening Worship",
        "verses": [["Day is dying in the west;", "Heaven is touching earth with rest;", "Wait and worship while the night", "Sets the evening lamps alight", "Through all the sky."], ["Lord of life, beneath the dome", "Of the universe, Thy home,", "Gather us who seek Thy face", "To the fold of Thy embrace,", "For Thou art nigh."], ["While the deepening shadows fall,", "Heart of love enfolding all,", "Through the glory and the grace", "Of the stars that veil Thy face,", "Our hearts ascend."], ["When forever from our sight", "Pass the stars, the day, the night,", "Lord of angels, on our eyes", "Let eternal morning rise", "And shadows end."]],
        "refrain": ["Holy, holy, holy, Lord God of Hosts!", "Heaven and earth are full of Thee!", "Heaven and earth are praising Thee,", "O Lord most high!"],
        "refrainFirst": false
      }, {
        "number": "52",
        "name": "Now the Day Is Over",
        "category": "Worship",
        "subcategory": "Evening Worship",
        "verses": [["Now the day is over,", "night is drawing nigh,", "Shadows of the evening", "steal across the sky."], ["Father, give the weary,", "calm and sweet repose;", "With Thy tenderest blessing", "May our eyelids close."], ["Through the long night watches,", "may thine angels spread", "Their white wings above me,", "watching round my bed."]]
      }, {
        "number": "53",
        "name": "All Praise to Thee",
        "category": "Worship",
        "subcategory": "Evening Worship",
        "verses": [["All praise to thee, my God, this night,", "for all the blessings of the light!", "Keep me, O keep me, King of kings,", "beneath thine own almighty wings."], ["Forgive me, Lord, for thy dear Son,", "the ill that I this day have done,", "that with the world, myself, and thee,", "I, ere I sleep, at peace may be."], ["O may my soul on thee repose,", "and with sweet sleep mine eyelids close,", "sleep that may me more vigorous make", "to serve my God when I awake."], ["Praise God, from whom all blessings flow;", "praise him, all creatures here below;", "praise him above, ye heavenly host;", "praise Father, Son, and Holy Ghost."]]
      }, {
        "number": "54",
        "name": "O Gladsome Light",
        "category": "Worship",
        "subcategory": "Evening Worship",
        "verses": [["O gladsome light, O grace", "Of God the Father's face,", "The eternal splendour wearing;", "Celestial, holy, blest,", "Our Saviour, Jesus Christ,", "Joyful in Thine appearing."], ["Now e'er day fadeth quite,", "We see the evening light,", "Our wonted hymn outpouring;", "Father of might unknown,", "Thee His incarnate Son,", "And Holy Spirit adoring."], ["To Thee of right belongs", "All praise of holy songs,", "O Son of God, life giver;", "Thee, therefore, O most high,", "The world doth glorify", "And shall exalt forever."]]
      }, {
        "number": "55",
        "name": "Jesus, Tender Shepherd, Hear Me",
        "category": "Worship",
        "subcategory": "Evening Worship",
        "verses": [["Jesus, tender Shepherd, hear me,", "Bless Thy little lamb tonight;", "Through the darkness be Thou near me;", "Watch my sleep till morning light."], ["All this day Thy hand has led me,", "And I thank Thee for Thy care;", "Thou hast clothed me, warmed and fed me;", "Listen to my evening prayer."]]
      }, {
        "number": "56",
        "name": "The Day Thou Gavest",
        "category": "Worship",
        "subcategory": "Evening Worship",
        "verses": [["The day Thou gavest, Lord, is ended;", "the darkness falls at Thy behest;", "to Thee our morning hymns ascended;", "Thy praise shall hallow now our rest."], ["We thank Thee that Thy church, unsleeping", "while earth rolls onward into light,", "through all the world her watch is keeping,", "and rests not now by day or night."], ["As o'er each continent and island", "the dawn leads on another day,", "the voice of prayer is never silent,", "nor die the strains of praise away."], ["So be it, Lord; Thy throne shall never,", "like earth's proud empires, pass away.", "Thy kingdom stands, and grows forever,", "till all Thy creatures own Thy sway."]]
      }, {
        "number": "57",
        "name": "Now All the Woods Are Sleeping",
        "category": "Worship",
        "subcategory": "Evening Worship",
        "verses": [["Now all the woods are sleeping,", "And night and stillness creeping", "O'er city, man, and beast;", "But thou, my heart, awake thee,", "To pray'r awhile be take thee,", "And praise thy Maker ere thou rest."], ["My Jesus, stay Thou by me,", "And let no foe come nigh me,", "Safe sheltered by Thy wing;", "But would the foe alarm me,", "O let him never harm me,", "But still Thine angels round me sing!"], ["My loved ones, rest securely,", "From every peril surely", "Our God will guard your heads;", "And happy slumbers send you,", "And bid His hosts attend you,", "And golden armed watch o'er your beds."]]
      }, {
        "number": "58",
        "name": "Hark, the Vesper Hymn Is Stealing",
        "category": "Worship",
        "subcategory": "Evening Worship",
        "verses": [["Hark! the vesper hymn is stealing", "O'er the waters soft and clear;", "Jubilate, Jubilate, Jubilate,", "Amen."], ["Nearer yet, and nearer pealing,", "Soft it breaks upon the ear.", "Jubilate, Jubilate, Jubilate,", "Amen."]]
      }, {
        "number": "59",
        "name": "Great Our Joy as Now We Gather",
        "category": "Worship",
        "subcategory": "Opening of Worship",
        "verses": [["Great our joy as now we gather", "Where the Master makes us one:", "Where we worship God the Father", "Through the Spirit of his Son.", "All who search", "For his Church", "Find it where his will is done."], ["Precious is the tie that binds us", "To our God when faith grows cold;", "Precious all that now reminds us", "He is still our safe stronghold.", "Faithful love", "Serves to prove", "Here the shepherd has his fold."], ["May we learn from Christ's example", "How to use this House of Prayer:", "He who loved and cleansed his temple", "Wants us all to worship there.", "God the Son", "Shuts out none:", "In his Kingdom all may share."], ["Lord, inspire us with your vision", "Of a world which must be won!", "Glorious is the Church's mission,", "Long endeavoured, scarce begun!", "'Faithful now'-", "This is how", "God's eternal will is done!"]]
      }, {
        "number": "60",
        "name": "Blessed Jesus, at Thy Word",
        "category": "Worship",
        "subcategory": "Opening of Worship",
        "verses": [["Blessed Jesus, at Thy word,", "We have gathered all to hear Thee;", "By Thy word our hearts were stirred,", "Now to seek and love and fear Thee.", "By Thy teachings sweet and holy,", "Let us learn to love Thee solely."], ["All our knowledge, sense, and sight,", "Lie in deepest darkness shrouded;", "Till Thy Spirit breaks our night,", "With His beams of truth unclouded.", "He alone to God canst win us,", "He who works all good within us."], ["Glorious Lord, Thy self impart!", "Light of light, from God proceeding.", "Open Thou our ears and heart", "Help us, by Thy Spirit's pleading.", "Hear the cry Thy people raises!", "Hear, and bless our prayer and praises!"]]
      }, {
        "number": "61",
        "name": "God Is Here!",
        "category": "Worship",
        "subcategory": "Opening of Worship",
        "verses": [["God is here as we his people", "meet to offer praise and prayer,", "may we find in fuller measure", "what it is in Christ we share.", "Here, as in the world around us,", "all our varied skills and arts", "wait the coming of his Spirit", "into open minds and hearts."], ["Here are symbols to remind us", "of our lifelong need of grace;", "here are table, font and pulpit;", "here the Word has central place.", "Here in honesty of preaching,", "here in silence, as in speech,", "here in newness and renewal,", "God the Spirit comes to each."], ["Here our children find a welcome", "in the Shepherd's flock and fold.", "Here, as bread and wine are taken,", "Christ sustains us, as of old.", "Here the servants of the Servant", "seek in worship to explore", "what it means in daily living", "to believe and to adore."], ["Lord of all, Church and Kingdom,", "in an age of change and doubt,", "keep us faithful to the Gospel,", "help us work your purpose out.", "Here, in this day's dedication,", "all we have to give, receive:", "we, who cannot live without you,", "we adore you! We believe!"]]
      }, {
        "number": "62",
        "name": "How Lovely Is Thy Dwelling Place",
        "category": "Worship",
        "subcategory": "Opening of Worship",
        "verses": [["How lovely is Thy dwelling place,", "O Lord of hosts to me!", "The tabernacles of Thy grace", "How pleasant, Lord, they be!"], ["My thirsty soul longs ardently,", "Yea, faints Thy courts to see;", "My very heart and flesh cry out,", "O living God, for Thee."], ["Behold the sparrow findeth out", "A house where-in to rest;", "The swallow also, for herself", "Provided hath a nest."], ["Ev'n Thine own altars, where she safe", "Her young ones forth may bring,", "O Thou, almighty Lord of hosts,", "Who art my God and King."], ["Blest are they in Thy house that dwell,", "They ever give Thee praise.", "Blest is the man whose strength Thou art,", "In whose heart are Thy ways."]]
      }, {
        "number": "63",
        "name": "O Come, Let Us Sing to the Lord",
        "category": "Worship",
        "subcategory": "Opening of Worship",
        "verses": [["O come, let us sing to the Lord,", "Come let us every one", "A joyful noise make to the Rock", "Of our salvation."], ["Let us before His presence come", "With glad and thankful voice;", "Let us sing psalms of praise to Him,", "And make a joyful noise."], ["For God, a great God and great King,", "Above all gods, He is;", "The depths of earth are in His hand,", "The strength of hills is His."], ["To Him the ocean vast belongs,", "For He the sea did make;", "The dry land also from His hands,", "Its form at first did take."], ["O come, bow down and worship Him,", "And kneeling, humbly pray,", "Come to our Maker and our God,", "And hear His voice today."]]
      }, {
        "number": "64",
        "name": "Lord, Dismiss Us With Thy Blessing",
        "category": "Worship",
        "subcategory": "Close of Worship",
        "verses": [["Lord, dismiss us with thy blessing;", "fill our hearts with joy and peace;", "let us each, thy love possessing,", "triumph in redeeming grace.", "O refresh us, O refresh us,", "traveling through this wilderness."], ["Thanks we give and adoration", "for thy gospel's joyful sound.", "May the fruits of thy salvation", "in our hearts and lives abound;", "ever faithful, ever faithful", "to the truth may we be found."]]
      }, {
        "number": "65",
        "name": "God Be With You",
        "category": "Worship",
        "subcategory": "Close of Worship",
        "verses": [["God be with you till we meet again;", "By His counsels guide, uphold you,", "With His sheep securely fold you;", "God be with you till we meet again."], ["God be with you till we meet again;", "'Neath His wings securely hide you;", "Daily manna still provide you;", "God be with you till we meet again."], ["God be with you till we meet again;", "When life's perils thick confound you;", "Put His arms unfailing round you;", "God be with you till we meet again."], ["God be with you till we meet again;", "Keep love's banner floating over you,", "Strike death's threatening wave before you;", "God be with you till we meet again."]],
        "refrain": ["Till we meet, till we meet,", "Till we meet at Jesus' feet;", "Till we meet, till we meet,", "God be with you till we meet again."],
        "refrainFirst": false
      }, {
        "number": "66",
        "name": "God Be With You",
        "category": "Worship",
        "subcategory": "Close of Worship",
        "verses": [["God be with you till we meet again;", "By His counsels guide, uphold you,", "With His sheep securely fold you:", "God be with you till we meet again."], ["God be with you till we meet again;", "Neath His wings securely hide you,", "Daily manna still provide you:", "God be with you till we meet again."], ["God be with you till we meet again;", "When life's perils thick confound you,", "Put His arms unfailing round you:", "God be with you till we meet again."], ["God be with you till we meet again;", "Keep love's banner floating o'er you,", "Smite death's threatening wave before you:", "God be with you till we meet again."]]
      }, {
        "number": "67",
        "name": "O Lord, Now Let Your Servant",
        "category": "Worship",
        "subcategory": "Close of Worship",
        "verses": [["O Lord, now let Your servant", "Depart in heav'nly peace,", "For I have seen the glory", "Of Your redeeming grace:", "A light to lead the Gentiles", "Unto Your holy hill,", "The glory of Your people,", "Your chosen Israel."], ["Then grant that I may follow Your gleam,", "O glorious Light,", "Till earthly shadows scatter,", "And faith is changed to sight;", "Till raptured saints shall gather", "Upon that shining shore,", "Where Christ, the blessed Day star,", "Shall light them evermore."]]
      }, {
        "number": "68",
        "name": "On Our Way Rejoicing",
        "category": "Worship",
        "subcategory": "Close of Worship",
        "verses": [["On our way rejoicing", "Gladly let us go;", "Conquer'd hath our Leader,", "Vanquish'd is the foe.", "Christ without, our safety;", "Christ within, our joy;", "Who, if we be faithful,", "Can our hope destroy?"], ["Unto God the Father", "Joyful songs we sing,", "Unto God the Savior", "Thankful hearts we bring,", "Unto God the Spirit", "Bow we and adore,", "On our way rejoicing", "Now and evermore."]],
        "refrain": ["On our way rejoicing", "As we forward move,", "Hearken to our praises,", "O blest God of love!"],
        "refrainFirst": false
      }, {
        "number": "69",
        "name": "Lord, Make Us More Holy",
        "category": "Worship",
        "subcategory": "Close of Worship",
        "verses": [["Lord, make us more holy;", "Lord, make us more holy;", "Lord, make us more holy", "Until we meet again."], ["Lord, make us more faithful;", "Lord, make us more faithful;", "Lord, make us more faithful", "Until we meet again."], ["Lord, make us more humble;", "Lord, make us more humble;", "Lord, make us more humble", "Until we meet again."], ["Lord, make us more loving;", "Lord, make us more loving;", "Lord, make us more loving", "Until we meet again.", "Like Jesus, the Saviour,", "Until we meet again."]]
      }, {
        "number": "70",
        "name": "Praise Ye the Father",
        "category": "Trinity",
        "verses": [["Praise ye the Father for His loving kindness,", "Tenderly cares He for His erring children;", "Praise Him, ye angels, praise Him in the heavens;", "Praise ye Jehovah!"], ["Praise ye the Savior, great is the compassion,", "Graciously cares He for His chosen people;", "Young men and maidens, ye old men and children,", "Praise ye the Savior!"], ["Praise ye the Spirit, comforter of Israel,", "Sent of the Father and the Son to bless us;", "Praise ye the Father, Son, and Holy Spirit,", "Praise the Eternal Three!"]]
      }, {
        "number": "71",
        "name": "Come, Thou Almighty King",
        "category": "Trinity",
        "verses": [["Come, Thou almighty King,", "Help us Thy name to sing,", "Help us to praise!", "Father all glorious,", "O'er all victorious,", "Come, and reign over us,", "Ancient of Days!"], ["Come, Thou incarnate Word,", "Gird on Thy mighty sword,", "Our prayer attend;", "Come, and Thy people bless,", "And give Thy Word success;", "Spirit of holiness,", "On us descend!"], ["Come, holy Comforter,", "Thy sacred witness bear,", "In this glad hour:", "Thou who almighty art,", "Now rule in every heart,", "And ne'er from us depart,", "Spirit of power!"], ["To Thee, great One in Three,", "Eternal praises be,", "Hence, evermore:", "Thy sovereign majesty", "May we in glory see,", "And to eternity", "Love and adore!"]]
      }, {
        "number": "72",
        "name": "Creator of the Stars of Night",
        "category": "Trinity",
        "verses": [["Creator of the stars of night,", "Thy people's everlasting light,", "O Christ, Thou Saviour of us all,", "We pray Thee, hear us when we call."], ["At the great name of Jesus, now", "All knees must bend, all hearts must bow;", "And things celestial Thee shall own,", "And things terrestrial, Lord alone."], ["To God the Father, God the Son,", "And God the Spirit, Three in One,", "Laud, honour, might, and glory be", "From age to age eternally.", "Amen."]]
      }, {
        "number": "73",
        "name": "Holy, Holy, Holy",
        "category": "Trinity",
        "verses": [["Holy, holy, holy! Lord God Almighty!", "Early in the morning our song shall rise to Thee;", "Holy, holy, holy, merciful and mighty!", "God in three Persons, blessed Trinity!"], ["Holy, holy, holy! Angels adore Thee,", "Casting down their golden crowns around the glassy sea;", "Thousands and ten thousands worship low before Thee,", "Which wert, and art, and evermore shalt be."], ["Holy, holy, holy! though the darkness hide Thee,", "Though the eye of sinful man Thy glory may not see;", "Only Thou art holy; there is none beside Thee,", "Perfect in power, in love, and purity."], ["Holy, holy, holy! Lord God Almighty!", "All Thy works shall praise Thy name, in earth, and sky, and sea;", "Holy, holy, holy; merciful and mighty!", "God in three Persons, blessed Trinity!"]]
      }, {
        "number": "74",
        "name": "Like a River Glorious",
        "category": "God the Father",
        "subcategory": "Love of God",
        "verses": [["Like a river glorious, is God's perfect peace,", "Over all victorious, in its bright increase;", "Perfect, yet it floweth, fuller every day,", "Perfect, yet it groweth, deeper all the way."], ["Hidden in the hollow of His blessed hand,", "Never foe can follow, never traitor stand;", "Not a surge of worry, not a shade of care,", "Not a blast of hurry touch the spirit there."], ["Every joy or trial falleth from above,", "Traced upon our dial by the Sun of Love;", "We may trust Him fully all for us to do.", "They who trust Him wholly find Him wholly true."]],
        "refrain": ["Stayed upon Jehovah, hearts are fully blessed", "Finding, as He promised, perfect peace and rest."],
        "refrainFirst": false
      }, {
        "number": "75",
        "name": "The Wonder of It All",
        "category": "God the Father",
        "subcategory": "Love of God",
        "verses": [["There's the wonder of sunset at evening,", "The wonder as sunrise I see;", "But the wonder of wonders that thrills my soul", "Is the wonder that God loves me."], ["There's the wonder of springtime and harvest,", "The sky, the stars, the sun;", "But the wonder of wonders that thrills my soul", "Is the wonder that's only begun."]],
        "refrain": ["O, the wonder of it all!", "The wonder of it all!", "Just to think that God loves me.", "O, the wonder of it all!", "The wonder of it all!", "Just to think that God loves me."],
        "refrainFirst": false
      }, {
        "number": "76",
        "name": "O Love That Wilt Not Let Me Go",
        "category": "God the Father",
        "subcategory": "Love of God",
        "verses": [["O Love that wilt not let me go,", "I rest my weary soul in thee;", "I give thee back the life I owe,", "that in thine ocean depths", "its flow may richer, fuller be."], ["O Light that followest all my way,", "I yield my flickering torch to thee;", "my heart restores its borrowed ray,", "that in they sunshine's blaze", "its day may brighter, fairer be."], ["O Joy that seekest me through pain,", "I cannot close my heart to thee;", "I trace the rainbow thru the rain,", "and feel the promise is not vain,", "that morn shall tearless be."], ["O Cross that liftest up my head,", "I dare not ask to fly from thee;", "I lay in dust life's glory dead,", "and from the ground there blossoms", "red life that shall endless be."]]
      }, {
        "number": "77",
        "name": "O Love of God Most Full",
        "category": "God the Father",
        "subcategory": "Love of God",
        "verses": [["O love of God most full,", "O love of God most free,", "Come, warm my heart, come fill my soul,", "Come, lead me unto Thee!"], ["Warm as the glowing sun,", "So shines Thy love on me;", "It wraps me 'round with kindly care,", "It draws me unto Thee!"], ["The wildest sea is calm,", "The tempest brings no fear,", "The darkest night is full of light", "Because Thy love is near."], ["O love of God most full,", "O love of God most free,", "It warms my heart, it fills my soul,", "With might it strengthens me."]]
      }, {
        "number": "78",
        "name": "For God So Loved Us",
        "category": "God the Father",
        "subcategory": "Love of God",
        "verses": [["For God so loved us,", "He sent the Savior:", "For God so loved us,", "and loves me too."], ["He sent the Savior,", "the blest Redeemer;", "He sent the Savior", "to set me free."], ["He bade me welcome,", "O word of mercy;", "He bade me welcome,", "O voice divine."], ["Glory and honor,", "O Love eternal,", "To Him be given", "while life shall last."]],
        "refrain": ["Love so unending!", "I'll sing His praises,", "God loves His children,", "loves even me."],
        "refrainFirst": false
      }, {
        "number": "79",
        "name": "O Love of God, How Strong and True!",
        "category": "God the Father",
        "subcategory": "Love of God",
        "verses": [["O love of God, how strong and true!", "Eternal, and yet ever new;", "Uncomprehended and unbought,", "Beyond all knowledge and all thought."], ["O love of God, how deep and great,", "Far deeper than man's deepest hate;", "Self-fed, self-kindled like the light,", "Changeless, eternal, infinite."], ["We read thee best in Him who cam", "To bear for us the cross of shame;", "Sent by the Father from on high,", "Our life to live, our death to die."], ["We read thy power to bless and save,", "E'en in the darkness of the grave;", "Still more in resurrection light", "We read the fullness of thy might."], ["O love of God, our shield and stay", "Through all the perils of our way!", "Eternal love, in thee we rest,", "Forever safe, forever blest."]]
      }, {
        "number": "80",
        "name": "O World of God",
        "category": "God the Father",
        "subcategory": "Love of God",
        "verses": [["O world of God, so vast and strange,", "profound and wonderful and fair,", "Beyond the utmost reach of thought,", "but not beyond a Father's care!", "We are not strangers on this earth", "whirling amid the suns of space;", "We are God's children, this our home,", "with those of every clime and race."], ["O world of man where life is lived,", "so strangely mingling joy and pain,", "So full of evil and of good,", "so needful that the good shall reign!", "It is this world that God has loved,", "and goodness was its Maker's plan,", "The promise of God's triumph is", "His coming in a Son of Man."], ["O world of time's farstretching years!", "there was a day when time stood still,", "A central moment when there rose", "across upon a cruel hill;", "In pain and death love's power was seen,", "the mystery of time revealed,", "The wisdom of the ways of God,", "the grace through which man's hurt is healed."]]
      }, {
        "number": "81",
        "name": "Though I Speak With Tongues",
        "category": "God the Father",
        "subcategory": "Love of God",
        "verses": [["Though I speak with tongues of men and angels,", "Though I have the prophet's gift,", "Though I hold the keys to hidden knowledge,", "Though my faith can mountains shift:", "Without love I am no better,", "Without love it's all for naught;", "Lord, You spent Your life in loving others:", "What this means I would be taught."], ["Love is patient, knows no envy,", "Never gloats when others sin;", "Love is never glad to see injustice,", "Always wants the truth to win.", "There's no end to love's endurance,", "There's no test it cannot face;", "Lord, You spent Your life in loving others:", "I shall fail without Your grace."], ["Though there'll be an end to hidden knowledge,", "Visions, raptures, prophecy:", "Faith and hope and love shall last forever,", "Love the greatest of the three.", "Without love I am no better,", "Without love it's all for naught;", "Lord, You gave Your life in saving others:", "What this means I would be taught."]]
      }, {
        "number": "82",
        "name": "Before Jehova's Awful Throne",
        "category": "God the Father",
        "subcategory": "Majesty and Power of God",
        "verses": [["Before Jehovah's awful throne,", "Ye nations, bow with sacred joy;", "Know that the Lord is God alone;", "He can create, and He destroy."], ["His sovereign power, without our aid,", "Made us of clay, and formed us men;", "and when like wandering sheep we strayed,", "He brought us to His fold again."], ["We'll crowd His gates with thankful songs,", "High as the heavens our voices raise;", "And earth, with her ten thousand tongues,", "Shall fill His courts with sounding praise."], ["Wide as the world is His command,", "Vast as Eternity His love;", "Firm as a rock His truth shall stand,", "When rolling years shall cease to move."]]
      }, {
        "number": "83",
        "name": "O Worship the King",
        "category": "God the Father",
        "subcategory": "Majesty and Power of God",
        "verses": [["O worship the King, all glorious above,", "O gratefully sing His wonderful love;", "Our Shield and Defender, the Ancient of Days,", "Pavilioned in splendor, and girded with praise."], ["O tell of His might, O sing of His grace,", "Whose robe is the light, whose canopy space,", "His chariots of wrath the deep thunderclouds form,", "And dark is His path on the wings of the storm."], ["Thy bountiful care, what tongue can recite?", "It breathes in the air, it shines in the light;", "It streams from the hills, it descends to the plain,", "And sweetly distills in the dew and the rain."], ["Frail children of dust, and feeble as frail,", "In Thee do we trust, nor find Thee to fail;", "Thy mercies how tender, how firm to the end!", "Our Maker, Defender, Redeemer, and Friend."]]
      }, {
        "number": "84",
        "name": "God the Omnipotent",
        "category": "God the Father",
        "subcategory": "Majesty and Power of God",
        "verses": [["God the Omnipotent! King, who ordainest", "Great winds Thy clarions, the lightnings Thy sword;", "Show forth Thy pity on high where Thou reignest,", "Give to us peace, O most merciful Lord."], ["God the all merciful! earth hath forsaken", "Thy precepts holy, and slighted Thy word;", "Bid not Thy wrath in its terrors awaken;", "Give to us peace, O most merciful Lord."], ["God the all righteousness One! man hath defied Thee;", "Yet to eternity standeth Thy word;", "Falsehood and wrong shall not tarry beside Thee;", "Prosper the right, O most merciful Lord."], ["So shall we render Thee thankful devotion,", "For Thy deliverance from peril and sword,", "Singing in chorus from ocean to ocean,", "\"Thine is the power and the glory, O Lord.\""]]
      }, {
        "number": "85",
        "name": "Eternal Father, Strong to Save",
        "category": "God the Father",
        "subcategory": "Majesty and Power of God",
        "verses": [["Eternal Father, strong to save,", "Whose arm hath bound the restless wave,", "Who bid'st the mighty ocean deep", "Its own appointed limits keep;", "Oh hear us when we cry to Thee", "For those in peril on the sea."], ["O Christ, whose voice the waters heard,", "And hushed their raging at Thy word,", "Who walkedst on the foaming deep,", "And calm amidst its rage didst sleep;", "Oh, hear us when we cry to Thee", "For those in peril on the sea."], ["O Holy Spirit, who didst brood", "Upon the waters dark and rude,", "And bid their angry tumult cease,", "And give, for wild confusion, peace:", "Oh, hear us when we cry to Thee", "For those in peril on the sea."], ["O Trinity of love and power,", "All trav'lers shield in danger's hour;", "From rock and tempest, fire and foe,", "Protect them wheresoe'er they go;", "Thus evermore shall rise to The", "Glad humns of praise from land and sea."]]
      }, {
        "number": "86",
        "name": "How Great Thou Art",
        "category": "God the Father",
        "subcategory": "Majesty and Power of God",
        "verses": [["O Lord my God", "When I in awesome wonder", "Consider all the worlds Thy Hands have made", "I see the stars", "I hear the rolling thunder", "Thy power throughout the universe displayed"], ["When through the woods", "And forest glades I wander", "And hear the birds sing sweetly in the trees", "When I look down", "From lofty mountain grandeur", "And see the brook and feel the gentle breeze"], ["And when I think", "That God His Son not sparing", "Sent Him to die I scarce can take it in", "That on the Cross", "My burden gladly bearing", "He bled and died to take away my sin."], ["When Christ shall come", "With shouts of acclamation", "And take me home", "What joy shall fill my heart", "Then I shall bow", "In humble adoration", "And then proclaim", "\"My God, how great Thou art!\""]],
        "refrain": ["Then sings my soul", "My Saviour God to Thee", "How great Thou art", "How great Thou art", "Then sings my soul", "My Saviour God to Thee", "How great Thou art", "How great Thou art"],
        "refrainFirst": false
      }, {
        "number": "87",
        "name": "God Who Spoke in the Beginning",
        "category": "God the Father",
        "subcategory": "Majesty and Power of God",
        "verses": [["God who spoke in the beginning,", "Forming rock and shaping spar,", "Set all life and growth in motion,", "Earthly world and distant star;", "He who calls the earth to order", "Is the ground of what we are."], ["God who spoke thro' men and nations,", "Thro' events long past and gone,", "Showing still today His purpose,", "Speaks supremely through His Son;", "He who calls the earth to order", "Gives His word and it is done."], ["God whose speech becomes incarnate-", "Christ is servant, Christ is Lord-", "Calls us to a life of service,", "Heart and will to action stirred;", "He who uses man's obedience", "Has the first and final word."]]
      }, {
        "number": "88",
        "name": "I Sing the Migthy Power of God",
        "category": "God the Father",
        "subcategory": "Majesty and Power of God",
        "verses": [["I sing the almighty power of God,", "that made the mountains rise,", "that spread the flowing seas abroad,", "and built the lofty skies.", "I sing the wisdom that ordained", "the sun to rule the day;", "the moon shines full at God's command,", "and all the stars obey."], ["I sing the goodness of the Lord,", "who filled the earth with food,", "who formed the creatures thru the Word,", "and then pronounced them good.", "Lord, how thy wonders are displayed,", "where'er I turn my eye,", "if I survey the ground I tread,", "or gaze upon the sky!"], ["There's not a plant or flower below,", "but makes thy glories known,", "and clouds arise, and tempests blow,", "by order from thy thrown;", "while all that borrows life from thee", "is ever in thy care;", "and everywhere that we can be,", "thou, God, art present there."]]
      }, {
        "number": "89",
        "name": "Let All on Earth Their Voices Raise",
        "category": "God the Father",
        "subcategory": "Majesty and Power of God",
        "verses": [["Let all the earth their voices raise,", "To sing the great Jehovah's praise,", "And bless His holy name:", "His glory let the people know,", "His wonders to the nations show,", "His saving grace proclaim."], ["He framed the globe; He built the sky;", "He made the shining worlds on high,", "And reigns in glory there:", "His beams are majesty and light;", "His beauties, how divinely bright!", "His dwelling place, how fair!"], ["Come, the great day, the glorious hour,", "When earth shall feel His saving power,", "All nations fear His name;", "Then shall the race of men confess", "The beauty of His holiness,", "His saving grace proclaim."]]
      }, {
        "number": "90",
        "name": "Eternal God, Whose Power Upholds",
        "category": "God the Father",
        "subcategory": "Majesty and Power of God",
        "verses": [["Eternal God, whose power upholds Both flower and flaming star,", "To whom there is no here nor there, No time, no near nor far,", "No alien race, no foreign shore, No child unsought, unknown:", "O send us forth, Your prophets true, To make all lands Your own!"], ["O God of truth, whom science seeks And reverent souls adore,", "Illumine every earnest mind Of every clime and shore:", "Dispel the gloom of error's night, Of ignorance and fear,", "Until true wisdom from above Shall make life's pathway clear!"], ["O God of beauty, oft revealed In dreams of human art,", "In speech that flows to melody, In holiness of heart:", "Teach us to ban al ugliness, And all disharmony,", "Till all shall know the loveliness Of lives made fair and free!"], ["O God of righteousness and grace, Seen in the Christ, Your Son,", "Whose life and death reveal Your face, By whom Your will was done;", "Help us to spread Your gracious reign Till greed and hate shall cease,", "And kindness dwell in human hearts, And all the earth find peace!"]]
      }, {
        "number": "91",
        "name": "Ye Watchers and Ye Holy Ones",
        "category": "God the Father",
        "subcategory": "Majesty and Power of God",
        "verses": [["Ye watchers and ye holy ones,", "Bright seraphs, cherubim and thrones,", "Raise the glad strain, Alleluia!", "Cry out, dominions, princedoms, powers,", "Virtues, archangels, angels' choirs:"], ["O higher than the cherubim,", "More glorious than the seraphim,", "Lead their praises, Alleluia!", "Thou bearer of th'eternal Word,", "Most gracious, magnify the Lord."], ["O friends, in gladness let us sing,", "Supernal anthems echoing,", "Alleluia! Alleluia!", "To God the Father, God the Son,", "And God the Spirit, Three in One."]],
        "refrain": ["Alleluia! Alleluia!", "Alleluia! Alleluia!", "Alleluia!"],
        "refrainFirst": false
      }, {
        "number": "92",
        "name": "This Is My Father's World",
        "category": "God the Father",
        "subcategory": "Power of God in Nature",
        "verses": [["This is my Father's world,", "and to my listening ears", "all nature sings, and round me rings", "the music of the spheres.", "This is my Father's world:", "I rest me in the thought", "of rocks and trees, of skies and seas;", "his hand the wonders wrought."], ["This is my Father's world,", "the birds their carols raise,", "the morning light, the lily white,", "declare their maker's praise.", "This is my Father's world:", "he shines in all that's fair;", "in the rustling grass I hear him pass;", "he speaks to me everywhere."], ["This is my Father's world.", "O let me ne'er forget", "that though the wrong seems oft so strong,", "God is the ruler yet.", "This is my Father's world:", "why should my heart be sad?", "The Lord is King; let the heavens ring!", "God reigns; let the earth be glad!"]]
      }, {
        "number": "93",
        "name": "All Things Bright and Beautiful",
        "category": "God the Father",
        "subcategory": "Power of God in Nature",
        "verses": [["Each little flower that opens,", "Each little bird that sings;", "He made their glowing colors,", "He made their tiny wings."], ["The purpleheaded mountain,", "The river running by", "The sunset, and the morning", "That brightens up the sky,"], ["The cold wind in the winter,", "The pleasant summer sun,", "The ripe fruits in the garden,", "He made them every one."], ["He gave us eyes to see them,", "And lips that we might tell", "How great is God Almighty,", "Who has made all things well."], []],
				"refrain": ["All things bright and beautiful,", "All creatures great and small,", "All things wise and wonderful,", "The Lord made them all."],
				"refrainFirst": true // strange structure: R 1 R 2 R 3 R 4 R
      }, {
        "number": "94",
        "name": "Nature With Open Volume Stands",
        "category": "God the Father",
        "subcategory": "Power of God in Nature",
        "verses": [["Nature with open volume stands,", "To spread its Maker's praise abroad;", "And every labor of His hands", "Shows something worthy of our God."], ["But in the grace that rescued us", "His brightest form of glory shines;", "'Tis fairest drawn upon the cross", "In precious blood and crimson lines."], ["Here His whole name appears complete.", "Nor wit can guess, nor reason prove,", "Which of the letters best is writ,", "The pow'r, the wisdom, or the love."], ["We would forever speak His name", "In sounds to mortal ears unknown,", "With angels join to praise the Lamb,", "And worship at His Father's throne."]]
      }, {
        "number": "95",
        "name": "Spring Has Now Unwrapped the Flowers",
        "category": "God the Father",
        "subcategory": "Power of God in Nature",
        "verses": [["Spring has now unwrapped the flowers,", "Day is fast reviving,", "Life in all her growing powers", "Towards the light is striving:", "Gone the iron touch of cold,", "Winter time and frost time,", "Seedlings, working through the mould,", "Now make up for lost time."], ["Herb and plant that winter long,", "Slumbered at their leisurek,", "Now be stirring, green and strong,", "Find in growth their pleasure:", "All the world with beauty fills,", "Gold the green enhacing;", "Flowers make glee among the hills,", "Set the meadows dancing."], ["Through each wonder of fair days", "God Himself expresses;", "Beauty follows all His ways,", "As the world He blesses:", "So, as He renews the earth,", "Artist without rival,", "In His grace of glad new birth", "We must seek revival."]]
      }, {
        "number": "96",
        "name": "The Spacious Firmament",
        "category": "God the Father",
        "subcategory": "Power of God in Nature",
        "verses": [["The spacious firmament on high,", "With all the blue, ethereal sky,", "And spangled heavens, a shining frame,", "Their great Original proclaim.", "Th'unwearied sun from day to day", "Does his Creator's power display,", "And publishes to every land", "The work of an almighty hand"], ["Soon as the evening shades prevail,", "The moon takes up the wondrous tale;", "And nightly to the listening earth", "Repeats the story of her birth;", "While all the stars that round her burn,", "And all the planets in their turn,", "Confirm the tidings as they roll,", "And spread the truth from pole to pole"], ["What though in solemn silence all", "Move round the dark terrestrial ball?", "What though no real voice nor sound", "Amid their radiant orbs be found?", "In reason's ear they all rejoice", "And utter forth a glorious voice,", "Forever singing as they shine,", "\"The hand that made us is divine.\""]]
      }, {
        "number": "97",
        "name": "Lord of the Boundless Curves of Space",
        "category": "God the Father",
        "subcategory": "Power of God in Nature",
        "verses": [["Lord of the boundless curves of space", "And time's deep mystery,", "To Your creative might we trace", "All nature's energy."], ["Your mind conceived the galaxy,", "Each atom's secret planned,", "And every age of history", "Your purpose, Lord, has spanned."], ["Yours is the image stamped on man,", "Though marred by man's own sin;", "And Yours the liberating plan", "Again his soul to win."], ["Give us to know Your truth; but more,", "The strength to do Your will;", "Until the love our souls adore", "Shall all our being fill."]]
      }, {
        "number": "98",
        "name": "Can You Count the Stars?",
        "category": "God the Father",
        "subcategory": "Power of God in Nature",
        "verses": [["Can you count the stars that brightly", "Twinkle in the midnight sky?", "Can you count the clouds, so lightly", "O'er the meadows floating by?", "God, the Lord, doth mark their number", "With His eyes that never slumber;", "He hath made every one,", "He hath made them every one."], ["Can you count the wings now flashing", "In the sunshine's golden light?", "Can you count the fishes splashing", "In the cooling waters bright?", "God, the Lord, a name hath given,", "To all creatures under heaven;", "He hath named them every one,", "He hath named them every one."], ["Do you know how many children", "Rise each morning blithe and gay?", "Can you count their jolly voices,", "Singing sweetly day by day?", "God hears all the happy voices,", "In their merry songs rejoices;", "And He loves them, every one,", "And He loves them, every one."]]
      }, {
        "number": "99",
        "name": "God Will Take Care of You",
        "category": "God the Father",
        "subcategory": "Faithfulness of God",
        "verses": [["Be not dismayed whate'er betide,", "God will take care of you;", "beneath his wings of love abide,", "God will take care of you."], ["Through days of toil when heart doth fail,", "God will take care of you;", "when dangers fierce your path assail,", "God will take care of you."], ["All you may need he will provide,", "God will take care of you;", "nothing you ask will be denied,", "God will take care of you."], ["No matter what may be the test,", "God will take care of you;", "lean, weary one, upon his breast,", "God will take care of you."]],
        "refrain": ["God will take care of you,", "through every day, o'er all the way;", "he will take care of you,", "God will take care of you."],
        "refrainFirst": false
      }, {
        "number": "100",
        "name": "Great Is Thy Faithfulness",
        "category": "God the Father",
        "subcategory": "Faithfulness of God",
        "verses": [["Great is Thy faithfulness, O God my Father,", "There is no shadow of turning with Thee;", "Thou changest not, Thy compassions, they fail not;", "As Thou has been Thou forever will be."], ["Summer and winter, and springtime and harvest,", "Sun, moon, and stars in their courses above,", "Join with all nature in manifold witness", "To Thy great faithfulness, mercy, and love."], ["Pardon for sin and a peace that endureth,", "Thy own dear presence to cheer and to guide;", "Strength for today and bright hope for tomorrow,", "Blessings all mine, with ten thousand beside."]],
        "refrain": ["Great is Thy faithfulness!", "Great is Thy faithfulness!", "Morning by morning new mercies I see", "All I have needed Thy hand hath provided,", "Great is Thy faithfulness!", "Lord unto me!"],
        "refrainFirst": false
      }, {
        "number": "101",
        "name": "Children of the Heavenly Father",
        "category": "God the Father",
        "subcategory": "Faithfulness of God",
        "verses": [["Children of the heavenly Father", "Safely in His bossom gather;", "Nestling bird nor star in heaven", "Such a refuge e'er was given."], ["God His own doth tend and nourish,", "In His holy love they flourish;", "From all evil things He spares them,", "In His mighty arms He bears them."], ["Neither life nor death shall ever", "From the Lord His children sever;", "Unto them His grace He showeth,", "And their sorrows all He knoweth."], ["Praise the Lord in joyful numbers,", "Your Protector never slumbers;", "At the will of your Defender", "Every foe-man must surrender."], ["Though He giveth or He taketh,", "God His children ne'er forsaketh;", "His the loving purpose solely", "To preserve them pure and holy."]]
      }, {
        "number": "102",
        "name": "Unto the Hills",
        "category": "God the Father",
        "subcategory": "Faithfulness of God",
        "verses": [["Unto the hills around shall I lift up my longing eyes?", "O whence for me shall my salvation come,", "From whence arise? from God the Lord doth come my certain aid,", "From God the Lord, who heav'n and earth hath made."], ["He will not suffer that thy foot be moved;", "safe shalt Thou be no careless slumber", "shall His eyelids close who keepeth thee.", "Behold our God, the Lord, He slumbereth ne'er,", "Who keepeth Israel in His holy care."], ["Jehovah is Himself thy keeper true, thy changeless shade.", "Jehovah thy defense on thy right hand Himself hath made", "And thee no sun by day shall ever smite,", "No moon shall harm thee in the silent night."], ["From every evil shall He keep thy soul, from every sin;", "Jehovah shall preserve thy going out, thy coming in.", "Above thee watching, He, whom we adore,", "Shall keep thee henceforth, yea, forevermore."]]
      }, {
        "number": "103",
        "name": "O God, Our Help in Ages Past",
        "category": "God the Father",
        "subcategory": "Faithfulness of God",
        "verses": [["O God, our help in ages past,", "our hope for years to come,", "our shelter from the stormy blast,", "and our eternal home!"], ["Under the shadow of thy throne,", "still may we dwell secure;", "sufficient is thine arm alone,", "and our defense is sure."], ["Before the hills in order stood,", "or earth received her frame,", "from everlasting, thou art God,", "to endless years the same."], ["A thousand ages, in thy sight,", "are like an evening gone;", "short as the watch that ends the night,", "before the rising sun."], ["O God, our help in ages past,", "our hope for years to come;", "be thou our guide while life shall last,", "and our eternal home!"]]
      }, {
        "number": "104",
        "name": "My Shepherd Will Supply My Need",
        "category": "God the Father",
        "subcategory": "Faithfulness of God",
        "verses": [["My Shepherd will supply my need,", "Jehovah is His name.", "In pastures fresh He makes me feed", "Beside the living stream.", "He brings my wand'ring spirit back", "When I forsake His ways,", "And leads me, for His mercy's sake,", "In paths of truth and grace."], ["When I walk through the shades of death,", "Thy presence is my stay;", "A word of Thy supporting breath", "Drives all my fears away.", "Thy hand in sight of all my foes", "Doth still my table spread;", "My cup with blessings over flows,", "Thine oil anoints my head."], ["The sure provisions of my God", "Attend me all my days;", "O may Thy house be mine abode", "And all my work be praise.", "There would I find a settled rest", "While others go and come;", "No more a stranger or a guest,", "But like a child at home."]]
      }, {
        "number": "105",
        "name": "Sing to the Great Jehovah's Praise",
        "category": "God the Father",
        "subcategory": "Grace and Mercy of God",
        "verses": [["Sing to the great Jehovah's praise!", "All praise to Him belongs;", "Who kindly lengthens out our days,", "In spires our choicest songs,", "Inspires our choicest songs."], ["His providence hath brought us through", "Another various year;", "We all, with vows and anthems new,", "Before our God appear,", "Before our God appear."], ["O God, Thy mercies past we own,", "And Thy continued care;", "To Thee presenting through Thy Son", "What e'er we have and are,", "What e'er we have and are."]]
      }, {
        "number": "106",
        "name": "Give to Our God Immortal Praise",
        "category": "God the Father",
        "subcategory": "Grace and Mercy of God",
        "verses": [["Give to our God immortal praise;", "Mercy and truth are all His ways:", "Wonders of grace to God belong,", "Repeat His mercies in your song."], ["Give to the Lord of lords renown,", "The King of kings with glory crown:", "His mercies ever shall endure", "When lords and kings are known no more."], ["He sent His Son with power to save", "From guilt and darkness and the grave:", "Wonders of grace to God belong,", "Repeat His mercies in your song."], ["Thro' this vain world He guides our feet,", "And leads us to His heav'nly seat;", "His mercies ever shall endure,", "When this vain world shall be no more."]]
      }, {
        "number": "107",
        "name": "God Moves in a Mysterious Way",
        "category": "God the Father",
        "subcategory": "Grace and Mercy of God",
        "verses": [["God moves in a mysterious way", "His wonders to perform;", "He plants His footsteps in the sea,", "And rides upon the storm."], ["Ye fearful saints, fresh courage take;", "The clouds ye so much dread", "Are big with mercy, and shall break", "In blessings on your head."], ["Judge not the Lord by feeble sense,", "But trust Him for His grace;", "Behind a frowning providence", "He hides a smiling face."], ["His purposes will ripen fast,", "Unfolding every hour;", "The bud may have a bitter taste,", "But sweet will be the flower."], ["Blind unbelief is sure to err,", "And scan His work in vain;", "God is His own interpreter,", "And He will make it plain."]]
      }, {
        "number": "108",
        "name": "Amazing Grace",
        "category": "God the Father",
        "subcategory": "Grace and Mercy of God",
        "verses": [["Amazing grace! How sweet the sound", "that saved a wretch like me!", "I once was lost, but now am found;", "was blind, but now I see."], ["'Twas grace that taught my heart to fear,", "and grace my fears relieved;", "how precious did that grace appear", "the hour I first believed."], ["The Lord has promised good to me,", "his word my hope secures;", "he will my shield and portion be,", "as long as life endures."], ["Through many dangers, toils, and snares,", "I have already come;", "'tis grace hath brought me safe thus far,", "and grace will lead me home."], ["When we've been there ten thousand years,", "bright shining as the sun,", "we've no less days to sing God's praise", "than when we first begun."]]
      }, {
        "number": "109",
        "name": "Marvelous Grace",
        "category": "God the Father",
        "subcategory": "Grace and Mercy of God",
        "verses": [["Marvelous grace of our loving Lord.", "Grace that exceeds our sin and our guilt!", "Yonder on Calvary's mount outpoured", "There where the blood of the Lamb was spilt."], ["Sin and despair, like the seawaves cold,", "Threaten the soul with infinite loss;", "Grace that is greater yes grace untold", "Points to the Refuge, the mighty Cross."], ["Marvelous, infinite, matchless grace,", "Freely bestowed on all who believe!", "You that are longing to see His face,", "Will you this moment His grace receive?"]],
        "refrain": ["Grace, grace,God's grace,", "Grace that will pardon and cleanse within", "Grace, grace, God's grace,", "Grace that is greater than all our sin!"],
        "refrainFirst": false
      }, {
        "number": "110",
        "name": "God's Free Mercy Streameth",
        "category": "God the Father",
        "subcategory": "Grace and Mercy of God",
        "verses": [["God's free mercy streameth over all the world,", "And His banner gleameth, By His church unfurled;", "Broad and deep and glorious, As the heaven above,", "Shines in might victorious His eternal love."], ["Summer suns are glowing over land and sea;", "Happy light is flowing, Bountiful and free;", "Everything rejoices in the mellow rays;", "Earth's ten thousand voices swell the psalm of praise."], ["Lord, upon our blindness thy pure radiance pour;", "For Thy loving kindness we would love Thee more;", "And when clouds are drifting dark across the sky,", "Then, the veil uplifting, father, be Thou nigh."], ["We will never doubt Thee, tho' veil Thy light;", "Life is dark without Thee, death with Thee is bright.", "Light of light, shine o'er us on our pilgrim way,", "Go Thou still before us to the endless day."]]
      }, {
        "number": "111",
        "name": "It Took a Miracle",
        "category": "God the Father",
        "subcategory": "Grace and Mercy of God",
        "verses": [["My Father is omnipotent", "And that you can't deny;", "A God of might and miracles;", "'Tis written in the sky."], ["Though here His glory has been shown,", "We still can't fully see", "The wonders of His might, His throne;", "'Till eternity."], ["The Bible tells us of His power", "And wisdom all way through;", "And every little bird and flower", "Are testimonies, too."]],
        "refrain": ["It took a miracle to put the stars in place;", "It took a miracle to hang the world in space.", "But when He saved my soul,", "Cleansed and made me whole,", "It took a miracle of love and grace!"],
        "refrainFirst": false
      }, {
        "number": "112",
        "name": "Let Us With a Gladsome Mind",
        "category": "God the Father",
        "subcategory": "Grace and Mercy of God",
        "verses": [["Let us with a gladsome mind", "Praise the Lord, for He is kind:", "For His mercies shall endure,", "Ever faithful, ever sure."], ["He, with all commanding might,", "Filled the new made world with light:", "For His mercies shall endure,", "Ever faithful, ever sure."], ["All things living He does feed;", "His full hand supplies their need:", "For His mercies shall endure,", "Ever faithful, ever sure."], ["Let us then with gladsome mind", "Praise the Lord, for He is kind:", "For His mercies shall endure,", "Ever faithful, ever sure."]]
      }, {
        "number": "113",
        "name": "As Pants the Hart",
        "category": "God the Father",
        "subcategory": "Grace and Mercy of God",
        "verses": [["As pants the hart for cooling streams", "When heated in the chase,", "So longs my soul, O God, for Thee,", "And Thy refreshing grace."], ["For Thee, my God, the living God,", "My thirsty soul doth pine:", "O when shall I behold Thy face,", "Thou Majesty divine?"], ["Why restless, why cast down, my soul?", "Hope still, and thou shalt sing", "The praise of Him who is thy God,", "Thy health's eternal spring."], ["To Father, Son, and Holy Ghost,", "The God whom we adore,", "Be glory, as it was, is now,", "And shall be evermore."]]
      }, {
        "number": "114",
        "name": "There's a Wideness",
        "category": "God the Father",
        "subcategory": "Grace and Mercy of God",
        "verses": [["There's a wideness, in God's mercy,", "like the wideness of the sea;", "There is mercy, with the Savior,", "Which is more than liberty."], ["There is welcome, for the sinner,", "and more graces for the good;", "There is mercy, with the Savior,", "There is healing in His blood."], ["For the love of God is broader", "Than the measure of man's mind.", "And the heart of the Eternal,", "is most wonderfully kind."], ["If our love were but more simple,", "We should take Him at His word;", "And our lives would be all sunshine,", "In the sweetness of our Lord."]]
      }, {
        "number": "115",
        "name": "O Come, O Come, Immanuel",
        "category": "Jesus Christ",
        "subcategory": "First Advent",
        "verses": [["O come, O come, Immanuel,", "And ransom captive Israel", "That mourns in lonely exile here", "Until the Son of God appear."], ["O come, Thou Wisdom from on high,", "And order all things, far and nigh;", "To us the path of knowledge show,", "And cause us in her ways to go."], ["O come, Desire of nations, bind", "All peoples in one heart and mind;", "Bid envy, strife, and quarrels cease;", "Fill the whole world with heaven's peace."]],
        "refrain": ["Rejoice! Rejoice! Immanuel", "Shall come to thee, O Israel!"],
        "refrainFirst": false
      }, {
        "number": "116",
        "name": "Of the Father's Love Begotten",
        "category": "Jesus Christ",
        "subcategory": "First Advent",
        "verses": [["Of the Father's love begotten,", "Ere the worlds began to be,", "He is Alpha and Omega,", "He the source, the ending He,", "Of the things that are, that have been,", "And that future years shall see,", "Evermore and evermore! Amen."], ["O ye heights of heaven adore Him;", "Angel hosts, His praises sing;", "Powers, dominions, bow before Him,", "And extol our God and King;", "Let no tongue on earth be silent,", "Every voice in concert ring,", "Evermore and evermore! Amen."], ["Christ, to Thee with God the Father", "And, O Holy Ghost, to Thee,", "Hymn and chant and high thanksgiving", "And unwearied praises be.", "Honor, glory, and dominion,", "And eternal victory,", "Evermore and evermore! Amen."]]
      }, {
        "number": "117",
        "name": "The Advent of Our God",
        "category": "Jesus Christ",
        "subcategory": "First Advent",
        "verses": [["The advent of our God", "With eager prayers we greet,", "And singing haste upon His road", "His coming reign to meet."], ["The everlasting Son", "Was born to make us free;", "And He a servant's form put on", "To gain our liberty."], ["As Judge, on clouds of light,", "He soon will come again,", "His scattered people to unite", "With them in heaven to reign."], ["Praise to the incarnate Son", "Who comes to set us free,", "With Father, Spirit, ever one,", "To all eternity."]]
      }, {
        "number": "118",
        "name": "The First Noel",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["The first noel the angel did say", "Was to certain poor shepherds in fields where they lay;", "In fields where they lay keeping their sheep,", "On a cold winter's night that was so deep."], ["They looked up and say a star", "Shining in the east, beyond them far,", "And to the earth it gave great light,", "And so it continued both day and night."], ["And by the light of that same star,", "Three wise men came from country far,", "And to the earth it gave great light,", "And to follow the star wherever it went."], ["This star drew nigh to the northwest,", "O'er Bethlehem it took its rest,", "And there it did both stop and stay,", "Right over the place where Jesus lay."], ["Then entered in those wise men three,", "Full reverently upon the knee,", "And offered there, in His presence,", "Their gold, and myrrh, and frankincense."]],
        "refrain": ["Noel, Noel, Noel, Noel,", "Born is the King of Israel"],
        "refrainFirst": false
      }, {
        "number": "119",
        "name": "Angels From the Realms of Glory",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["Angels from the realms of glory,", "Wing your flight o`er all the earth;", "Ye, who sang creation`s story,", "Now proclaim Messiah`s birth;", "Come and worship, Come and worship,", "Worship Christ, the newborn King."], ["Shepherds, in the field abiding,", "Watching o`er your flocks by night,", "God with man is now residing;", "Yonder shines the Infant Light;", "Come and worship, Come and worship,", "Worship Christ, the newborn King."], ["Sages, leave your contemplations,", "Brighter visions beam afar;", "Seek the great Desire of nations;", "Ye have seen His natal star;", "Come and worship, Come and worship,", "Worship Christ, the newborn King."], ["Saints, before the altar bending,", "Watching long in hope and fear,", "Suddenly the Lord, descending,", "In His temple shall appear;", "Come and worship, Come and worship,", "Worship Christ, the newborn King."]]
      }, {
        "number": "120",
        "name": "There's a Song in the Air",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["There's a song in the air!", "There's a star in the sky!", "There's a mother's deep prayer", "And a baby's low cry!", "And the star rains its fire", "while the beautiful sing,", "For the manger of Bethlehem", "cradles a King!"], ["There's a tumult of joy", "O'er the wonderful birth,", "For the virgin's sweet boy", "Is the Lord of the earth.", "Aye! the star rains its fire", "while the beautiful sing,", "For the manger of Bethlehem", "cradles a King!"], ["In the light of that star", "Lie the ages impearled;", "And that song from afar", "Has swept over the world.", "Every hearth is aflame", "and the beautiful sing", "In the homes of the nations", "that Jesus is King!"], ["We rejoice in the light,", "And we echo the song", "That comes down through the night", "From the heavenly throng.", "Aye! we shout to the lovely evangel they bring,", "And we greet in His cradle", "our Savior and King!"]]
      }, {
        "number": "121",
        "name": "Go, Tell It On the Mountain",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "refrain": ["Go, tell it on the mountain,", "Over the hills and everywhere:", "Go, tell it on the mountain", "That Jesus Christ is born!"],
        "refrainFirst": true,
        "verses": [["While shepherds kept their watching", "O'er silent flocks by night,", "Behold throughout the heavens", "There shone a holy light."], ["The shepherds feared and trembled", "When lo! Above the earth", "Rang out the angel refrain", "That hailed our Savior's birth."], ["Down in a lowly manger", "The humble Christ was born,", "And brought us God's salvation", "That blessed Christmas morn."]]
      }, {
        "number": "122",
        "name": "Hark! the Herald Angels Sing",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["Hark! the herald angels sing,", "\"Glory to the new born King,", "peace on earth, and mercy mild,", "God and sinners reconciled!\"", "Joyful, all ye nations rise,", "join the triumph of the skies;", "with th' angelic host proclaim,", "\"Christ is born in Bethlehem!\"", "Hark! the herald angels sing,", "\"Glory to the new born King!\""], ["Christ, by highest heaven adored;", "Christ, the everlasting Lord;", "late in time behold him come,", "offspring of a virgin's womb.", "Veiled in flesh the Godhead see;", "hail th' incarnate Deity,", "pleased with us in flesh to dwell,", "Jesus, our Emmanuel.", "Hark! the herald angels sing,", "\"Glory to the new born King!\""], ["Hail the heaven-born Prince of Peace!", "Hail the Sun of Righteousness!", "Light and life to all he brings,", "risen with healing in his wings.", "Mild he lays his glory by,", "born that we no more may die,", "born to raise us from the earth,", "born to give us second birth.", "Hark! the herald angels sing,", "\"Glory to the new born King!\""]]
      }, {
        "number": "123",
        "name": "As With Gladness Men of Old",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["As with gladness, men of old", "Did the guiding star behold", "As with joy they hailed its light", "Leading onward, beaming bright", "So, most glorious Lord, may we", "Evermore be led to Thee."], ["As with joyful steps they sped", "To that lowly manger bed", "There to bend the knee before", "Him Whom heaven and earth adore;", "So may we with willing feet", "Ever seek Thy mercy seat."], ["As they offered gifts most rare", "At that manger rude and bare;", "So may we with holy joy,", "Pure and free from sin's alloy,", "All our costliest treasures bring,", "Christ, to Thee, our heavenly King."], ["Holy Jesus, every day", "Keep us in the narrow way;", "And, when earthly things are past,", "Bring our ransomed souls at last", "Where they need no star to guide,", "Where no clouds Thy glory hide."], ["In the heavenly country bright,", "Need they no created light;", "Thou its light, its joy, its crown,", "Thou its sun which goes not down;", "There forever may we sing", "Alleluias to our King!"]]
      }, {
        "number": "124",
        "name": "Away in a Manger",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["Away in a manger, no crib for a bed,", "The little Lord Jesus laid down his sweet head.", "The stars in the sky looked down where he lay,", "The little Lord Jesus asleep in the hay."], ["The cattle are lowing, the baby awakes,", "But little Lord Jesus no crying he makes.", "I love Thee, Lord Jesus, look down from the sky", "And stay by my cradle til morning is nigh."], ["Be near me, Lord Jesus, I ask Thee to stay", "Close by me forever, and love me, I pray.", "Bless all the dear children in thy tender care,", "And take us to heaven, to live with Thee there."]]
      }, {
        "number": "125",
        "name": "Joy to the World",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["Joy to the world,", "the Lord is come!", "Let earth", "receive her King;", "Let every heart", "prepare Him room,", "And heaven and nature sing,", "And heaven and nature sing,", "And heaven, and heaven", "and nature sing."], ["Joy to the earth,", "the Savior reigns!", "Let men their", "songs employ;", "While fields and floods,", "rocks, hills, and plains,", "Repeat the sounding joy,", "Repeat the sounding joy,", "Repeat, repeat", "the sounding joy."], ["No more let sin", "and sorrow grow,", "Nor thorns", "infest the ground;", "He comes to make", "His blessings flow", "Far as the curse is found,", "Far as the curse is found,", "Far as, far as", "the curse is found."], ["He rules the world", "with truth and grace,", "And makes the", "nations prove", "The glories of", "His righteousness,", "And wonders of His love,", "And wonders of His love,", "And wonders, and", "wonders of His love."]]
      }, {
        "number": "126",
        "name": "In the Bleak Midwinter",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["In the bleak midwinter Frosty wind made moan;", "Earth stood hard as iron, Water like a stone;", "Snow had fallen, snow on snow, Snow on snow,", "In the bleak minwinter, Long ago."], ["Angels and archangels May have gathered there,", "Chrubim and seraphim Thronged the air.", "But His mother only In her maiden bliss,", "Worshiped the beloved With a kiss."], ["What can I give Him, Poor as I am?", "If I were a shepherd I would bring a lamb;", "If I were a wise man I would do my part;", "Yet what can I give Him? Give my heart."]]
      }, {
        "number": "127",
        "name": "Infant Holy, Infant Lowly",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["Infant holy, infant lowly,", "For His bed a cattle stall;", "Oxen lowing, little knowing", "Christ the babe is Lord of all;", "Swift are winging angels singing,", "Noels ringing, tidings bringing,", "Christ the babe is Lord of all,", "Christ the babe is Lord of all."], ["Flocks were sleeping, shepherds keeping", "Vigil till the morning new;", "Saw the glory, heard the story,", "Tidings of the gospel true;", "Thus rejoicing, free from sorrow,", "Praising voicing greet the morrow,", "Christ the babe was born for you,", "Christ the babe was born for you."]]
      }, {
        "number": "128",
        "name": "Break Forth, O Beauteous Heavenly Light",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["Break forth, O beautiful heavenly light,", "And usher in the morning;", "Ye shepherds, shrink not with affright,", "But hear the abgel's warning.", "This child, now weak in infancy,", "Our confidence and joy shall be,", "The power of Satan breaking,", "Our peace eternal making."], ["Break forth, O beauteous heavenly light", "To herald our salvation;", "He stoops to earth-the God of might,", "Our hope and expectation.", "He comes in human flesh to dwell,", "Our God with us, Immanuel,", "The night of darkness ending,", "Our fallen race befriending."]]
      }, {
        "number": "129",
        "name": "As It Fell Upon a Night",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["As it fell upon a night", "In the winter weather,", "Angels bright in starry height", "Began to sing together."], ["Shepherds sleeping on the plain", "Woke to see the glory,", "All amazed they stood and gazed", "And heard the angels' story."], ["Unto you a child is born", "In a manger lowly,", "Humble, He, yet born to be", "The King of Love most holy."], ["Happy angels from afar,", "Cease your singing never!", "In excelsis gloria!", "For ever and for ever."]]
      }, {
        "number": "130",
        "name": "It Came Upon the Midnight Clear",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["It came upon the midnight clear,", "that glorious song of old,", "from angels bending near the earth", "to touch their harps of gold;", "\"Peace on earth, goodwill to men,", "from heav'n's all gracious King!\"", "The world in solemn stillness lay", "to hear the angels sing."], ["Still through the cloven skies they come,", "with peaceful wings unfurled;", "And still their heav'nly music floats", "o'er all the weary world;", "Above its sad and lowly plains", "they bend on hovering wing;", "And ever o'er its Babel sounds", "the blessed angels sing!"], ["Yet with the woes of sin and strife", "the world has suffered long;", "beneath the angel strain have rolled", "two thousand years of wrong;", "And man, at war with man, hears not", "the love song which they bring", "O hush the noise, ye men of strife,", "and hear the angels sing!"], ["For lo! The days are hast'ning on,", "by prophet bards foretold,", "When, with the ever circling years,", "comes round the age of gold;", "When peace shall over all the earth", "its ancient splendors fling;", "And the whole world send back", "the song which now the angels sing!"]]
      }, {
        "number": "131",
        "name": "Lo, How a Rose E'er Blooming",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["Lo, how a rose e're blooming", "From tenderstem hath sprung,", "Of Jesse's lineage coming", "As men of old have sung.", "It came, a floweret bright,", "Amid the cold of winter", "When half spent was the night."], ["Isaiah 'twas foretold it,", "The Rose I have in mind,", "With Mary we beheld it,", "The virgin mother kind.", "To show God's love aright", "She bore to them a Savior,", "When half spent was the night."]]
      }, {
        "number": "132",
        "name": "O Come, All Ye Faithful",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["O come, all ye faithful,", "joyful and triumphant,", "O come ye, O come", "ye to Bethlehem!", "Come and behold Him,", "born the King of angels!", "O come, let us adore Him,", "O come, let us adore Him,", "O come, let us adore", "Him, Christ, the Lord!"], ["Sing, choirs of angels", "sing in exultation,", "O sing all ye citizens", "of heaven above!", "Glory to God, all", "glory in the highest!", "O come, let us adore Him,", "O come, let us adore Him,", "O come, let us adore", "Him, Christ, the Lord!"], ["Yea, Lord, we greet Thee,", "born this happy morning,", "Jesus, to Thee be", "all glory given;", "Word of the Father,", "now in flesh appearing!", "O come, let us adore Him,", "O come, let us adore Him,", "O come, let us adore", "Him, Christ, the Lord!"]]
      }, {
        "number": "133",
        "name": "Now Is Born the Divine Christ Child",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "refrain": ["Now is born the divine Christ child,", "Play the musette, play the tuneful oboe,", "Now is born the divine Christ child,", "Sing we all and rejoice this day."],
        "refrainFirst": true,
        "verses": [["He was born in a stable bare,", "On bed of straw how He sleeps so soundly,", "He was born in a stable bare,", "Let us worship and to Him bow."], ["Ages long since are past and gone,", "When the wise men foretold His coming,", "Ages long since are past and gone,", "When the wise men foretold His birth."]]
      }, {
        "number": "134",
        "name": "O Jesus Sweet",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["O Jesus sweet, O Jesus mild,", "Thy Father's will hast Thou fulfilled;", "For Thou hast left Thy heavenly throne", "Our lowly state to make Thine own.", "O Jesus sweet, O Jesus mild."], ["O Jesus sweet, O Jesus mild,", "With joy hast Thou the whole world filled;", "Thou comest down from heaven's hall", "To comfort us whom tears enthrall.", "O Jesus sweet, O Jesus mild."], ["O Jesus sweet, O Jesus mild,", "Thou art love's image undefiled.", "Inflame our hearts with love's pure fire,", "That we may share Thy heart's desire.", "O Jesus sweet, O Jesus mild."], ["O Jesus sweet, O Jesus mild,", "Help us to do as Thou hast willed.", "What e'er we have belongs to Thee:", "O may we ever faithful be.", "O Jesus sweet, O Jesus mild."]]
      }, {
        "number": "135",
        "name": "O Little Town of Bethlehem",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["O little town of Bethlehem", "How still we see thee lie!", "Above thy deep and dreamless", "sleep The silent stars go by;", "Yet in thy dark streets shineth", "The everlasting light;", "The hopes and fears of all the", "years Are met in thee tonight."], ["For Christ is born of Mary;", "And gathered all above,", "While mortals sleep,", "the angels keep", "Their watch of", "wondering love.", "O morning stars, together", "Proclaim the holy birth!", "And praises sing to God the King,", "And peace to men on earth."], ["How silently, how silently", "The wondrous gift is given!", "So God imparts to human hearts", "The blessings of His heaven.", "No ear may hear His coming;", "But in this world of sin,", "Where meek souls will", "recieve Him still,", "The dear Christ", "enters in."], ["O holy Child of Bethlehem,", "Descend to us, we pray;", "Cast out our sin and enter", "in-- Be born in us today.", "We hear the Christmas angels", "The great glad tidings tell--", "Oh, come to us, abide with us,", "Our Lord Immanuel!"]]
      }, {
        "number": "136",
        "name": "Good Christians, Now Rejoice",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["Good Christians, now rejoice,", "With heart, and soul, and voice;", "Give ye heed to what we say:", "Jesus Christ is born today;", "Ox and ass before Him bow,", "And He is in the manger now.", "Christ is born today!", "Christ is born today!"], ["Good Christians, now rejoice,", "With heart, and soul, and voice;", "Now ye hear of endless bliss:", "Jesus Christ was born for this!", "He hath ope'd the heav'nly door,", "And we are blessed evermore.", "Christ was born for this!", "Christ was born for this!"], ["Good Christians, now rejoice,", "With heart, and soul, and voice;", "Now ye need not fear the grave:", "Jesus Christ was born to save!", "Calls you one and calls you all", "To gain His everlasting hall.", "Christ was born to save!", "Christ was born to save!"]]
      }, {
        "number": "137",
        "name": "We Three Kings",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["We three kings of Orient are;", "Bearing gifts we traverse afar", "Field and fountain, moor and mountain,", "Following yonder star."], ["Born a King on Bethlehem's plain,", "Gold I bring to crown Him again,", "King forever, ceasing never", "Over us all to reign."], ["Frankincense to offer have I;", "Incense owns a Deity nigh;", "Prayer and praising all men raising,", "Worship Him, God on high."], ["Myrrh is mine; its bitter perfume", "Breathes a life of gathering gloom:", "Sorrowing, sighing, bleeding, dying,", "Sealed in the stonecold tomb."], ["Glorious now behold Him arise,", "King and God and sacrifice;", "Alleluia, Alleluia!", "Sounds through the earth and skies."]],
        "refrain": ["O star of wonder, star of night,", "Star with royal beauty bright,", "Westward leading, still proceeding,", "Guide us to Thy perfect light."],
        "refrainFirst": false
      }, {
        "number": "138",
        "name": "Rise Up, Shepherd, and Follow",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["There's a star in the east on Christmas morn.", "Rise up shepherd, and follow.", "It will lead to the place where the Savior's born,", "Rise up shepherd, and follow."], ["If you take good heed to the angel's words,", "Rise up, shepherd, and follow.", "You'll forget your flocks, you'll forget your herds,", "Rise up, shepherd, and follow."]],
        "refrain": ["Leave your sheep and leave your lambs,", "Rise up, shepherd, and follow.", "Leave your ewes and leave your rams,", "Rise up shepherd, and follow.", "Follow, follow, Rise up, shepherd and follow.", "Follow the star of Behlehem, Rise up, shepherd, and follow."],
        "refrainFirst": false
      }, {
        "number": "139",
        "name": "While Shepherds Watched Their Flocks",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["While shepherds watched their flocks by night,", "All seated on the ground,", "The angel of the Lord came down,", "And glory shone around."], ["\"Fear nor!\" said he- for mighty dread", "Had seized their troubled mind-", "\"Glad tidings of great joy I bring,", "To you and all mankind."], ["\"To you, in David's town this day,", "Is born of David's line,", "The Savior who is Christ the Lord;", "And this shall be the sign:"], ["\"The heavenly Babe you there shall find", "To human view displayed,", "All meanly wrapped in swathing bands,", "And in a manger laid.\""], ["Thus spake the seraph; and forth with", "Appeared a shining throng", "Of angels praising God on high,", "Who thus addressed their song:"], ["\"All glory be to God on high,", "And to the earth be peace;", "Good will henceforth from heaven to men,", "Begin and never cease!\""]]
      }, {
        "number": "140",
        "name": "Thou Didst Leave Thy Throne",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["Thou didst leave Thy throne", "And Thy kingly crown", "When Thou camest to earth for me;", "But in Bethlehem's home", "Was there found no room", "For Thy holy nativity."], ["Heaven's arches rang", "When the angels sang", "Proclaiming Thy royal degree;", "But of lowly birth", "Didst Thou come to earth,", "And in greatest humility."], ["The foxes found rest,", "And the birds their nest", "In the shade of the forest tree;", "But Thy couch was the sod,", "O Thou Son of God,", "In the deserts of Galilee."], ["Thou camest, O Lord,", "With the living word", "That should set Thy people free;", "But with mocking scorn,", "And with crown of thorn,", "They bore Thee to Calvary."], ["When the heavens shall ring,", "And the angels sing,", "At Thy coming to victory,", "Let Thy voice call me home,", "Saying, \"Yet there is room,", "There is room at My side for thee.\""]],
        "refrain": ["O come to my heart, Lord Jesus,", "There is room in my heart for Thee."],
        "refrainFirst": false,
        "last": ["My heart shall rejoice, Lord Jesus,", "When Thou comest and callest for me."]
      }, {
        "number": "141",
        "name": "What Child Is This?",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["What child is this, who, laid to rest,", "On Mary`s lap is sleeping?", "Whom angels greet with anthems sweet,", "While shepherds watch are keeping?"], ["Why lies He in such mean estate", "Where ox and ass are feeding?", "Good Christian, fear: for sinners here", "The silent Word is pleading."], ["So bring Him incense, gold, and myrrh,", "Come, peasant, king, to own Him,", "The King of kings salvation brings,", "Let loving hearts enthrone Him."]],
        "refrain": ["This, this is Christ the King,", "Whom shepherds guard and angels sing:", "Haste, haste to bring Him laud,", "The babe, the son of Mary."],
        "refrainFirst": false
      }, {
        "number": "142",
        "name": "Angels We Have Heard on High",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["Angels we have heard on high,", "Singing sweetly through the night,", "And the mountains, in reply,", "Echoing their brave delight."], ["Shepherds, why this jubilee?", "Why this songs of happy cheer?", "What great brightness did you see?", "What glad tidings did you hear?"], ["Come to Bethlehem and see", "Him whose birth the angels sing;", "Come adore, on bended knee,", "Christ the Lord, the newborn King."], ["See Him in a manger laid,", "Whom the angels praise above;", "Mary, Joseph, lend your aid,", "While we raise our hearts in love."]],
        "refrain": ["Gloria, in excelsis Deo,", "Gloria in excelsis Deo."],
        "refrainFirst": false
      }, {
        "number": "143",
        "name": "Silent Night, Holy Night",
        "category": "Jesus Christ",
        "subcategory": "Birth",
        "verses": [["Silent night, holy night,", "All is calm, all is bright", "Round yon virgin mother and Child,", "Holy Infant so tender and mild,", "Sleep in heavenly peace,", "sleep in heavenly peace."], ["Silent night, holy night,", "Shepherds quake at the sight,", "glories stream from heaven afar,", "heavenly hosts sing Alleluia;", "Christ, the Savior is born,", "Christ, the Savior is born."], ["Silent night, holy night,", "Son of God, love's pure light,", "Radiant beams from Thy holy face,", "with the dawn of redeeming grace,", "Jesus, Lord, at Thy birth,", "Jesus, Lord, at Thy birth."], ["Silent night, holy night,", "Wondrous star, lend thy light;", "With the angels let us sing,", "Alleluia to our King;", "Christ the Saviour is born,", "Christ the Saviour is born."]]
      }, {
        "number": "144",
        "name": "O Sing a Song of Bethlehem",
        "category": "Jesus Christ",
        "subcategory": "Life and Ministry",
        "verses": [["O sing a song of Bethlehem,", "Of shepherds watching there,", "And of the news that came to them", "From angels in the air:", "The light that shone on Bethlehem", "Fills all the world today;", "Of Jesus'birth and peace on earth", "The angels sing alway."], ["O sing a song of Nazareth,", "Of sunny days of joy,", "O sing of fragrant flowers breath,", "And of the sinless Boy:", "For now the flowers of Nazareth", "In every heart may grow;", "Now spreads the fame of His dear name", "On all the winds that blow."], ["O sing a song of Calvary,", "Its glory and dismay;", "Of Him who hung upon the tree,", "And took our sins away:", "For He who died on Calvary", "Is risen from the grave;", "And Christ, our Lord, by heaven adored,", "Is mighty now to save."]]
      }, {
        "number": "145",
        "name": "Songs of Thankfulness and Praise",
        "category": "Jesus Christ",
        "subcategory": "Life and Ministry",
        "verses": [["Songs of thankfulness and praise,", "Jesus, Lord, to You we raise,", "Manifested by the star", "To the sages from afar;", "Branch of royal David's stem", "In Your birth at Bethlehem;", "Anthems be to You addressed,", "God in man made manifest."], ["Manifest at Jordan's stream,", "Prophet's, Priest, and King supreme;", "And at Cana, wedding guest,", "In Your Godhead manifest;", "Manifest in power divine,", "Changing water into wine;", "Anthems be to You addressed,", "God in man made manifest."], ["Manifest in making whole", "Palsied limbs and fainting soul;", "Manifest in valliant fight,", "Quelling all the devil's might;", "Manifest in gracious will,", "Ever bringing good from ill;", "Anthems be to You addressed,", "God in man made manifest."], ["Grant us grace to see You, Lord,", "Mirrored in Your holy Word;", "May we imitate Your way,", "And be pure, as pure we may,", "That we like to You may be", "At Your great epiphany;", "Let us praise You, ever blest,", "God in man made manifest."]]
      }, {
        "number": "146",
        "name": "I Think When I Read That Sweet Story",
        "category": "Jesus Christ",
        "subcategory": "Life and Ministry",
        "verses": [["I think when I read that sweet story of old,", "When Jesus was here among men,", "How He called little children", "as lambs to His fold,", "I should like to have been with Him then."], ["I wish that His hands had been placed on my head,", "That His arm had been thrown around me,", "And that I might have seen His kind", "look when He said,", "\"Let the little ones come unto Me.\""], ["I long for the joy of that glorious time,", "The sweetest and brightest and best,", "When the dear little children of every clime", "Shall crowd to His arms and be blest."]]
      }, {
        "number": "147",
        "name": "Christ Upon the Mountain Peak",
        "category": "Jesus Christ",
        "subcategory": "Life and Ministry",
        "verses": [["Christ upon the mountain peak", "Stands alone in glory blazing;", "Let us, if we dare to speak,", "With the saints and angels praise Him:", "Alleluia, Alleluia, Alleluia."], ["Trembling at His feet we saw", "Moses and Elijah speaking;", "All the prophets and the law", "Shout thro'them their joyful greeting:", "Alleluia, Alleluia, Alleluia."], ["Swift the cloud of glory came,", "God proclaiming in the thunder", "Jesus as His Son by name;", "Nations cry aloud in wonder!", "Alleluia, Alleluia, Alleluia."], ["This is God's beloved Son:", "Law and prophets fade before Him;", "First and last, the only One,", "Let creation now adore Him.", "Alleluia, Alleluia, Alleluia."]]
      }, {
        "number": "148",
        "name": "O Love, How Deep, How Broad",
        "category": "Jesus Christ",
        "subcategory": "Life and Ministry",
        "verses": [["O love, how deep, how broad, how high,", "Beyond all thought and fantasy,", "That God, the Son of God, should take", "Our mortal form for mortal's sake!"], ["For us baptized, for us He bore", "His holy fast and hungered sore;", "For us temptation sharp He knew;", "For us the tempter over threw."], ["For us by wickedness betrayed,", "For us, in crown of thorns arrayed,", "He bore the shameful cross and death;", "For us He gave His dying breath."], ["For us He rose from death again,", "For us He went on high to reign;", "For us He sent His Spirit here", "To guide, to strengthen, and to cheer."], ["All glory to our Lord and God", "For love so deep, so high, so broad;", "The Trinity whom we adore", "Forever and forevermore."]]
      }, {
        "number": "149",
        "name": "Once in Royal David's City",
        "category": "Jesus Christ",
        "subcategory": "Life and Ministry",
        "verses": [["Once in royal David's city", "Stood a lowly cattle shed,", "Where a mother laid her Baby", "In a manger for His bed;", "Mary was that mother mild,", "Jesus Christ her little Child."], ["He came down to earth from heaven,", "Who is God and Lord of all,", "And His shelter was a stable,", "And His cradle was a stall;", "With the poor, and mean, and lowly,", "Lived on earth our Savior holy."], ["And through all His wondrous childhood", "He would honor and obey,", "Love and watch the lowly mother", "In whose gentle arms He lay.", "Christian children all must be", "Mild, obedient, good as He."], ["And our eyes at last shall see Him,", "Through His own redeeming love;", "For that Child so dear and gentle", "Is our Lord in heaven above;", "And He leads His children on", "To the place where He is gone."]]
      }, {
        "number": "150",
        "name": "Who Is He in Yonder Stall?",
        "category": "Jesus Christ",
        "subcategory": "Life and Ministry",
        "verses": [["Who is He in yonder stall,", "At whose feet the shepherds fall?"], ["Who is He in deep distress,", "Fasting in the wilderness?"], ["Who is He, the gathering throng", "Greet with loud triumphant song?"], ["Lo, at midnight, who is He", "Prays in dark Gethsemane?"], ["Who is He on yonder tree", "Dies in shame and agony?"], ["Who is He that from the grave", "Comes to heal and help and save?"], ["Who is He that from His throne", "Rules through all the world alone?"]],
        "refrain": ["'Tis the Lord, O wondrous story,", "'Tis the Lord, the King of glory!", "At His feet we humbly fall,", "Crown Him, crown Him Lord of all.", "At His feet we humbly fall- the Lord of all.", "Crown Him, crown Him, Jesus, Jesus,", "Crown Him Lord of all."],
        "refrainFirst": false
      }, {
        "number": "151",
        "name": "Jesus Walked This Lonesome Valley",
        "category": "Jesus Christ",
        "subcategory": "Life and Ministry",
        "verses": [["Jesus walked this lonesome valley;", "He had to walk it by Himself.", "O nobody else could walk it for Him.", "He had to walk it by Himself."], ["I must go and stand my trial.", "I have to stand it by myself.", "O nobody else could stand it for me.", "I have to stand it by myself."], ["Jesus walked this lonesome valley;", "He had to walk it by Himself.", "O nobody else could walk it for Him.", "He had to walk it by Himself."]]
      }, {
        "number": "152",
        "name": "Tell Me the Story of Jesus",
        "category": "Jesus Christ",
        "subcategory": "Life and Ministry",
        "verses": [["Tell me the story of Jesus,", "Write on my heart every word,", "Tell me the story most precious", "Sweetest that ever was heard;", "Tell how the angels, in chorus,", "Sang as they welcomed His birth,", "Glory to God in the highest,", "Peace and good tidings to earth."], ["Fasting, alone in the desert,", "Tell of the days that He passed,", "How for our sins He was tempted,", "Yet was triumphant at last;", "Tell of the years of His labor,", "Tell of the sorrow He bore,", "He was despised and afflicted,", "Homeless, rejected, and poor."], ["Tell of the cross where they nailed Him,", "Writhing in anguish and pain;", "Tell of the grave where they laid Him,", "Tell how He liveth again;", "Love in that story so tender,", "Clearer than ever I see;", "Stay, let me weep while you whisper,", "Love paid the ransom for me."]],
        "refrain": ["Tell me the story of Jesus,", "Write on my heart every word,", "Tell me the story most precious,", "Sweetest that ever was heard."],
        "refrainFirst": false
      }, {
        "number": "153",
        "name": "Prince of Peace, Control My Will",
        "category": "Jesus Christ",
        "subcategory": "Life and Ministry",
        "verses": [["Prince of Peace, control my will,", "Bid this struggling heart be still;", "Bid my fears and doubtings cease,", "Hush my spirit into peace."], ["Thou hast bought me with Thy blood,", "Opened wide the gate to God;", "Peace, I ask, but peace must be,", "Lord, in being one with Thee."], ["May Thy will, not mine, be done,", "May Thy will and mine be one;", "Chase these doubtings from my heart,", "Now Thy perfect peace impart."]]
      }, {
        "number": "154",
        "name": "When I Survey the Wondrous Cross",
        "category": "Jesus Christ",
        "subcategory": "Sufferings and Death",
        "verses": [["When I survey the wondrous cross", "on which the Prince of Glory died;", "my richest gain I count but loss,", "and pour contempt on all my pride."], ["Forbid it, Lord, that I should boast,", "save in the death of Christ, my God;", "all the vain things that charm me most,", "I sacrifice them to his blood."], ["See, from his head, his hands, his feet,", "sorrow and love flow mingled down.", "Did e'er such love and sorrow meet,", "or thorns compose so rich a crown."], ["Were the whole realm of nature mine,", "that were an offering far too small;", "love so amazing, so divine,", "demands my soul, my life, my all."]]
      }, {
        "number": "155",
        "name": "When I Survey the Wondrous Cross",
        "category": "Jesus Christ",
        "subcategory": "Sufferings and Death",
        "verses": [["When I survey the wondrous cross", "On which the Prince of glory died,", "My richest gain I count but loss,", "And pour contempt on all my pride."], ["See, from His head, His feet,", "Sorrow and love flow mingled down;", "Did e'er such love and sorrow meet?", "Or thorns compose so rich a crown?"], ["Since I, who was undone and lost,", "Have pardon through His name and word;", "Forbid it, then, that I should boast,", "Save in the cross of Christ my Lord."], ["Were the whole realm of nature mine,", "That were a tribute far too small;", "Love so amazing, so divine,", "Demands my life, my soul, my all."]]
      }, {
        "number": "156",
        "name": "O Sacred Head Now Wounded",
        "category": "Jesus Christ",
        "subcategory": "Sufferings and Death",
        "verses": [["O sacred Head, now wounded,", "with grief and shame weighed down,", "now scornfully surounded", "with thorns, thine only crown:", "how pale thou art with anguish,", "with sore abuse and scorn!", "How does that visage languish", "which once was bright as morn!"], ["What thou, my Lord, has suffered", "was all for sinners' gain;", "mine, mine was the transgression,", "but thine the deadly pain.", "Lo, here I fall, my Savior!", "'Tis I deserve thy place;", "look on me with thy favor,", "vouchsafe to me thy grace."], ["What language shall I borrow", "to thank thee, dearest friend,", "for this thy dying sorrow,", "thy pity without end?", "O make me thine forever;", "and should I fainting be,", "Lord, let me never, never", "outlive my love for thee."]]
      }, {
        "number": "157",
        "name": "Go to Dark Gethsemane",
        "category": "Jesus Christ",
        "subcategory": "Sufferings and Death",
        "verses": [["Go to dark Gethsemane,", "ye that feel the tempter's power;", "your Redeemer's conflict see,", "watch with him one bitter hour.", "Turn not from his griefs away;", "learn of Jesus Christ to pray."], ["See him at the judgment hall,", "beaten, bound, reviled, arraigned;", "O the wormwood and the gall!", "O the pangs his soul sustained!", "Shun not suffering, shame, or loss;", "learn of Christ to bear the cross."], ["Calvary's mournful mountain climb;", "there, adoring at his feet,", "mark that miracle of time,", "God's own sacrifice complete.", "\"It is finished!\" hear him cry;", "learn of Jesus Christ to die."]]
      }, {
        "number": "158",
        "name": "Were You There?",
        "category": "Jesus Christ",
        "subcategory": "Sufferings and Death",
        "verses": [["Were you there when they crucified my Lord?", "Were you there when they crucified my Lord?", "O! Sometimes it causes me to tremble, tremble, tremble.", "Were you there when they crucified my Lord?"], ["Were you there when they nailed Him to the tree?", "Were you there when they nailed Him to the tree?", "O! Sometimes it causes me to tremble, tremble, tremble.", "Were you there when they nailed Him to the tree?"], ["Were you there when they pierced Him in the side?", "Were you there when they pierced Him in the side?", "O! Sometimes it causes me to tremble, tremble, tremble.", "Were you there when they pierced Him in the side?"], ["Were you there when the sun refused to shine?", "Were you there when the sun refused to shine?", "O! Sometimes it causes me to tremble, tremble, tremble.", "Were you there when the sun refused to shine?"], ["Were you there when they laid Him in the tomb?", "Were you there when they laid Him in the tomb?", "O! Sometimes it causes me to tremble, tremble, tremble.", "Were you there when they laid Him in the tomb?"]]
      }, {
        "number": "159",
        "name": "The Old Rugged Cross",
        "category": "Jesus Christ",
        "subcategory": "Sufferings and Death",
        "verses": [["On a hill far away stood an old rugged cross,", "the emblem of suffering and shame;", "and I love that old cross where the dearest and best", "for a world of lost sinners was slain."], ["O that old rugged cross, so despised by the world,", "has a wondrous attraction for me;", "for the dear Lamb of God left his glory above", "to bear it to dark Calvary."], ["To that old rugged cross I will ever be true,", "its shame and reproach gladly bear;", "then he'll call me some day to my home far away,", "where his glory forever I'll share."]],
        "refrain": ["So I'll cherish the old rugged cross,", "till my trophies at last I lay down;", "I will cling to the old rugged cross,", "and exchange it some day for a crown."],
        "refrainFirst": false
      }, {
        "number": "160",
        "name": "Ride On in Majesty",
        "category": "Jesus Christ",
        "subcategory": "Sufferings and Death",
        "verses": [["Ride on! ride on in majesty!", "Hark, all the tribes hosanna cry;", "O Savior meek, pursue Thy road", "With palms and scattered garments strowed."], ["Ride on! ride on in majesty!", "In lowly pomp ride on to die;", "O Christ, Thy triumphs now begin", "O'er captive death and conquered sin."], ["Ride on! ride on in majesty!", "The winged squadrons of the sky", "Look down with sad and wondering eyes", "To see the approaching sacrifice."], ["Ride on! ride on in majesty!", "In lowly pomp ride on to die;", "Bow Thy meek head to mortal pain,", "Then take, O God, Thy power and reign."]]
      }, {
        "number": "161",
        "name": "Throned Upon the Awful Tree",
        "category": "Jesus Christ",
        "subcategory": "Sufferings and Death",
        "verses": [["Throned upon the awful tree,", "Lamb of God, Your grief we see.", "Darkness veils Your anguished face;", "None its lines of woe can trace.", "None can tell what pangs unknown", "Hold You silent and alone."], ["Silent through those three dread hours,", "Wrestling with the evil powers,", "Left alone with human sin,", "Gloom around You and within,", "Till th'appointed time is nigh,", "Till the Lamb of God may die."], ["Hark, that cry that peals aloud", "Upward through the whelming cloud!", "You, the Father's only Son,", "You, His own anointed one,", "You are asking- can it be?", "\"Why have You forsaken Me?\""], ["Lord, should fear and anguish roll", "Darkly o'er our sinful soul,", "You, who once were thus bereft", "That Your own might ne'er be left,", "Teach us by that bitter cry", "In the gloom to know You nigh."]]
      }, {
        "number": "162",
        "name": "Wondrous Love",
        "category": "Jesus Christ",
        "subcategory": "Sufferings and Death",
        "verses": [["What wondrous love is this, O my soul, O my soul?", "What wondrous love is this,", "O my soul? What wondrous love is this", "That caused the Lord of bliss", "To bear the dreadful curse for my soul, for my soul;", "To bear the dreadful curse for my soul"], ["To God and to the Lamb I will sing,", "I will sing; To God and to the Lamb,", "I will sing; To God and to the Lamb", "Who is the great I am, While millions join the theme,", "I will sing, I will sing; While millions join the theme, I will sing."], ["And when from death I'm free, I'll sing on, I'll sing on;", "And when from death I'm free, I'll sing on;", "And when from death I'm free,", "I'll sing and joyful be, And through eternity", "I'll sing on, I'll sing on! And through eternity, I'll sing on"]]
      }, {
        "number": "163",
        "name": "At the Cross",
        "category": "Jesus Christ",
        "subcategory": "Sufferings and Death",
        "verses": [["Alas! and did my Savior bleed,", "and did my Sovereign die?", "Would he devote that sacred head", "for sinners such as I?"], ["Was it for crimes that I have done,", "he groaned upon the tree?", "Amazing pity! Grace unknown!", "And love beyond degree!"], ["But drops of grief can ne'er repay", "the debt of love I owe:", "Here, Lord, I give myself away;", "'tis all that I can do!"]],
        "refrain": ["At the cross, at the cross,", "where I first saw the light,", "and the burden of my heart rolled away;", "it was there by faith I received my sight,", "and now I am happy all the day!"],
        "refrainFirst": false
      }, {
        "number": "164",
        "name": "There Is a Green Hill Far Away",
        "category": "Jesus Christ",
        "subcategory": "Sufferings and Death",
        "verses": [["There is a green hill far away,", "Without a city wall,", "Where the dear Lord was crucified,", "Who died to save us all."], ["We may not know, we cannot tell,", "What pains He had to bear,", "But we believe it was for us", "He hung and suffered there."], ["He died that we might be forgiven,", "He died to make us good,", "That we might go at last to heaven,", "Saved by His precious blood."], ["There was no other good enough", "To pay the price of sin;", "He only could unlock the gate", "Of heaven, and let us in."], ["O dearly, dearly has He loved!", "And we must love Him too,", "And trust in His redeeming blood,", "And try His works to do."]]
      }, {
        "number": "165",
        "name": "Look, You Saints! the Sight Is Glorious",
        "category": "Jesus Christ",
        "subcategory": "Resurrection and Ascension",
        "verses": [["Look, you saints, the sight is glorious,", "See the Man of sorrows now;", "From the fight returned victorious,", "Every knee to Him shall bow.", "Crown Him! Crown Him! Crown Him!", "Crown Him! Crown Him! Crown Him!", "Crowns become the victor's brow.", "Crowns become the victor's brow."], ["Crown the Savior! angels crown Him!", "Rich the trophies Jesus brings;", "On the seat of power enthrone Him", "While the vault of heaven rings.", "Crown Him! Crown Him! Crown Him!", "Crown Him! Crown Him! Crown Him!", "Crown the Savior, King of Kings.", "Crown the Savior, King of Kings."], ["Sinners in derision crowned Him,", "Mocking thus the Savior's claim;", "Saints and angels crowd around Him,", "Own His title, praise His name.", "Crown Him! Crown Him! Crown Him!", "Crown Him! Crown Him! Crown Him!", "Spread abroad the victor's fame!", "Spread abroad the victor's fame!"], ["Hark! those bursts of acclamation!", "Hark! those loud triumphant chords!", "Jesus takes the highest station;", "Oh, what joy the sight affords!", "Crown Him! Crown Him! Crown Him!", "Crown Him! Crown Him! Crown Him!", "King of kings and Lord of lords!", "King of kings and Lord of lords!"]]
      }, {
        "number": "166",
        "name": "Christ the Lord Is Risen Today",
        "category": "Jesus Christ",
        "subcategory": "Resurrection and Ascension",
        "verses": [["Christ the Lord is risen today, Alleluia!", "Sons of man and angels say, Alleluia!", "Raise your joys and triumphs high, Alleluia!", "Sing, ye heavens, and earth reply, Alleluia!"], ["Lives again our glorious King, Alleluia!", "Where, O death, is now thy sting? Alleluia!", "Once he died our souls to save, Alleluia!", "Where's thy victory, boasting grave? Alleluia!"], ["Love's redeeming work is done, Alleluia!", "Fought the fight, the battle won, Alleluia!", "Death in vain forbids him rise, Alleluia!", "Christ has opened paradise, Alleluia!"], ["Soar we now where Christ has led, Alleluia!", "Following our exalted Head, Alleluia!", "Made like him, like him we rise, Alleluia!", "Ours the cross, the grave, the skies, Alleluia!"]]
      }, {
        "number": "167",
        "name": "Alleluia! Sing to Jesus!",
        "category": "Jesus Christ",
        "subcategory": "Resurrection and Ascension",
        "verses": [["Alleluia! sing to Jesus! His the scepter, His the throne.", "Alleluia! His the triumph, His the victory alone.", "Hark! the songs of peaceful Zion thunder like a mighty flood.", "Jesus out of every nation has redeemed us by His blood."], ["Alleluia! not as orphans are we left in sorrow now;", "Alleluia! He is near us, faith believes, nor questions how;", "Though the cloud from sight received Him when the forty days were o'er", "Shall our hearts forget His promise, \"I am with you evermore\"?"], ["Alleluia! bread of angels, Thou on earth our food, our stay;", "Alleluia! here the sinful flee to Thee from day to day:", "Intercessor, Friend of sinners, Earth's Redeemer, plead for me,", "Where the songs of all the sinless sweep across the crystal sea."]]
      }, {
        "number": "168",
        "name": "And Have the Bright Immensities",
        "category": "Jesus Christ",
        "subcategory": "Resurrection and Ascension",
        "verses": [["And have the bright immensities", "Received our risen Lord,", "Where light years frame the Pleiades", "And point Orion's sword?", "Do flaming suns His footsteps trace", "Thro' corridors sublime,", "The Lord of inter stellar space", "And conqueror of time?"], ["The heav'n that hides Him from our sight", "Knows neither near nor far;", "A little candle sheds its light", "As surely as a star.", "And where His loving people meet", "To share the gift divine,", "There stands He with unhurrying feet;", "There heav'nly splendors shine."]]
      }, {
        "number": "169",
        "name": "Come, You Faithful",
        "category": "Jesus Christ",
        "subcategory": "Resurrection and Ascension",
        "verses": [["Come, you faithful, raise the strain", "Of triumphant gladness;", "God has brought His people", "Now rejoice, Jerusalem,", "And with true affection", "Welcome in unwearied strains", "Jesus'resurrection."], ["'Tis the spring of souls today;", "Christ has burst His prison", "From the frost and gloom of death", "light and life have risen", "All the winter of our sins,", "Long and dark, is flying", "From His ligth, to whom we give", "Thanks and praise undying."], ["\"Alleluia!\" now we cry", "to our King immortal,", "Who, triumphant, burst the", "bars of the tomb's dark portal", "\"Alleluia!\" with the Son,", "God the Father praising;", "\"Alleluia!\" yet again to", "the Spirit raising."]]
      }, {
        "number": "170",
        "name": "Come, You Faithful",
        "category": "Jesus Christ",
        "subcategory": "Resurrection and Ascension",
        "verses": [["Come, you faithful, raise the strain", "Of triumphant gladness;", "God has brought His Israel", "Into joy from sadness;", "Loosed from Pharoah's bitter yoke", "Jacob's sons and daughters;", "Led them with unmoistened foot", "Through the Red Sea waters."], ["'Tis the spring of souls today;", "Christ has burst His prison,", "And from three days'sleep in death", "As a sun has risen;", "All the winter of our sins,", "Long and dark, is flying", "From His light, to whom is giv'n", "Laud and praise undying."], ["Now the queen of seasons, bright", "With the day of splendor,", "With the royal feast of feasts,", "Comes its joy to render;", "Comes to gladden faithful hearts", "Which with true affection", "Welcome in unwearied strain", "Jesus'resurrection."], ["For today among the twelve", "Christ appeared, be stowing", "His deep peace, which evermore", "Passes human knowing.", "Neither could the gates of death,", "Nor the tomb's dark portal,", "Nor the watchers, nor the seal,", "Hold Him as a mortal."], ["\"Alleluia!\" now we cry", "To our King immortal,", "Who, triumphant, burst the bars", "Of the tomb's dark portal;", "\"Alleluia\" with the Son,", "God the Father praising;", "\"Alleluia!\" yet again", "To the Spirit raising."]]
      }, {
        "number": "171",
        "name": "Thine Is the Glory",
        "category": "Jesus Christ",
        "subcategory": "Resurrection and Ascension",
        "verses": [["Thine is the glory,", "Risen, conquering Son;", "Endless is the victory", "Thou o'er death hast won.", "Angels in bright raiment", "Rolled the stone away,", "Kept the folded grave-clothes", "Where Thy body lay."], ["Lo! Jesus meets us.", "Risen from the tomb,", "Lovingly He greets us,", "Scatters fear and gloom;", "Let His Church with gladness", "Hymns of triumph sing,", "For her Lord now liveth;", "Death has lost its sting."], ["No more we doubt Thee,", "Glorious Prince of life!", "Life is nought without Thee;", "Aid us in our strife;", "Make us more than conquerors,", "Through Thy deathless love;", "Bring us safe through Jordan", "To Thy home above."]],
        "refrain": ["Thine is the glory,", "Risen, conquering Son;", "Endless is the victory", "Thou o'er death hast won."],
        "refrainFirst": false
      }, {
        "number": "172",
        "name": "The Strife Is O'er",
        "category": "Jesus Christ",
        "subcategory": "Resurrection and Ascension",
        "verses": [["Alleluia, alleluia, alleluia!", "The strife is o'er, the battle done;", "Now is the victor's triumph won!", "Now be the song of praise begun. Alleluia!"], ["The pow'rs of death have done their worst,", "But Christ their legions has dispersed.", "Let shouts of holy joy outburst. Alleluia!"], ["The three sad days have quickly sped,", "He rises glorious from the dead.", "All glory to our risen head! Alleluia!"], ["Lord, by the stripes which wounded You,", "From death's sting free Your servants too,", "That we may live and sing to You. Alleluia!"]]
      }, {
        "number": "173",
        "name": "Good Christian Friends, Rejoice!",
        "category": "Jesus Christ",
        "subcategory": "Resurrection and Ascension",
        "verses": [["Good Christian friends, rejoice and sing!", "Now is the triumph of our King!", "To all the world glad news we bring:", "Alleluia! Alleluia! Alleluia!"], ["The Lord of life is risen for aye;", "Bring flowers of song to strew His way;", "Let all mankind rejoice and say:", "Alleluia! Alleluia! Alleluia!"], ["Praise we in songs of victory", "That love, that life which cannot die,", "And sing with hearts uplifted high:", "Alleluia! Alleluia! Alleluia!"], ["Thy name we bless, O risen Lord,", "And sing today with one accord", "The life laid down, the life restored:", "Alleluia! Alleluia! Alleluia!"]]
      }, {
        "number": "174",
        "name": "Star of Our Hope",
        "category": "Jesus Christ",
        "subcategory": "Resurrection and Ascension",
        "verses": [["Star of our hope! He'll soon appear,", "The last loud trumpet speaks Him near;", "Hail Him, all saints, from pole to pole-", "How welcome to the faithful soul!"], ["From heaven angelic voices sound:", "Behold the Lord of glory crowned,", "Arrayed in majesty divine,", "And in His highest glories shine."], ["The grave yields up its precious trust,", "Which long has slumbered in the dust,", "Resplendent forms ascending, fair,", "Now meet the Savior in the air."], ["Descending with His azure throne,", "He claims the kingdom for His own;", "The saints rejoice, they shout, they sing,", "And hail Him their triumphant King."]]
      }, {
        "number": "175",
        "name": "Now the Green Blade Rises",
        "category": "Jesus Christ",
        "subcategory": "Resurrection and Ascension",
        "verses": [["Now the green blade rises from the buried grain,", "What that in dark earth many days has lain;", "Love lives again, that with the dead has been;", "Love is come again like wheat arising green."], ["In the grave they laid Him, love by hatred slain,", "Thinking that He would never wake again,", "Laid in the earth like grain that sleeps unseen;", "Love is come again like wheat arising green."], ["Forth He came in triumph, like the risen grain,", "He that for three days in the grave had lain;", "Raised from the dead, my living Lord is seen;", "Love is come again like wheat arising green."], ["When our hearts are wintry, grieving, or in pain,", "Your touch can call us back to life again,", "Field of our hearts that dead and bare have been;", "Love is come again like wheat arising green."]]
      }, {
        "number": "176",
        "name": "Hail the Day That Sees Him Rise",
        "category": "Jesus Christ",
        "subcategory": "Resurrection and Ascension",
        "verses": [["Hail the day that sees Him rise, Alleluia!", "Glorious to His native skies; Alleluia!", "Christ, awhile to mortals giv'n, Alleluia!", "Enters now the highest heav'n! Alleluia!"], ["There the glorious triumph waits; Alleluia!", "Lift your heads, eternal gates! Alleluia!", "Wide unfold the radiant scene; Alleluia!", "Take the King of glory in! Alleluia!"], ["See! He lifts His hands above; Alleluia!", "See! He shows the prints of love: Alleluia!", "Hark! His gracious lips bestow, Alleluia!", "Blessings on His Church below. Alleluia!"], ["Lord beyond our mortal sight, Alleluia!", "Raise our hearts to reach Thy height, Alleluia!", "There Thy face unclouded see, Alleluia!", "Find our heav'n of heav'ns in Thee. Alleluia!"]]
      }, {
        "number": "177",
        "name": "Jesus, Your Blood and Righteousness",
        "category": "Jesus Christ",
        "subcategory": "Priesthood",
        "verses": [["Jesus, your blood and righteousness", "My beauty are, my glorious dress;", "Mid flaming worlds, in these arrayed,", "With joy shall I lift up my head."], ["Bold shall I stand in that great day,", "Cleansed and redeemed, no debt to pay;", "For by your cross absolved I am", "From sin and guilt, from fear and shame."], ["Lord, I believe your precious blood,", "Which at the mercy seat of God", "Pleads for the captives' liberty,", "Was also shed in love for me."], ["When from the dust of death I rise", "To claim my mansion in the skies,", "This then shall be my only plea:", "Christ Jesus lived and died for me."]]
      }, {
        "number": "178",
        "name": "The Unveiled Christ",
        "category": "Jesus Christ",
        "subcategory": "Priesthood",
        "verses": [["Once our blessed Christ of beauty", "Was veiled off from human views;", "But through suffering, death, and sorrow", "He has rent the veil in two."], ["Yes, He is with God, the Father,", "Interceding there for you;", "For He is the Well beloved", "Since He rent the veil in two."], ["Holy angels bow before Him,", "Men of earth give praises due;", "For He is the mighty Conqueror", "Since He rent the veil in two."]],
        "refrain": ["O behold the Man of Sorrows!", "O behold Him in plain view!", "Lo! He is the mighty Conqueror", "Since He rent the veil in two;", "Lo! He is the mighty Conqueror", "Since He rent the veil in two."],
        "refrainFirst": false
      }, {
        "number": "179",
        "name": "The Wonders of Redeeming Love",
        "category": "Jesus Christ",
        "subcategory": "Priesthood",
        "verses": [["The wonders of redeeming love", "Our highest thoughts exceed;", "The Son of God comes from above", "For sinful man to bleed."], ["He gives Himself, His life, His all,", "A sinless sacrifice.", "For man He drains the cup of gall,", "For man the victim dies."], ["And now before His Father's face", "His precious blood He pleads;", "For those who seek the throne of grace", "His lovee still intercedes."], ["He knows the frailties of our frame,", "For He has borne our grief;", "Our great High Priest once felt the same,", "And He can send relief."], ["His love will not be satisfied,", "Till He in glory sees", "The faithful ones for whom He died", "From sin forever free."]]
      }, {
        "number": "180",
        "name": "O Listen to Our Wondrous Story",
        "category": "Jesus Christ",
        "subcategory": "Priesthood",
        "verses": [["O listen to our wondrous story,", "Counted once among the lost;", "Yet One came down from heaven's glory,", "Saving us at awful cost!"], ["No angel could His place have taken,", "Highest of the high tho' he;", "The loved One on the cross forsaken", "Was One of the Godhead three!"], ["Will you surrender to this Savior?", "To His scepter humbly bow?", "You, too, shall come to know His favor,", "He will save you, save you now."]],
        "refrain": ["Who saved us from eternal loss?", "Who but God's Son upon the cross?", "What did He do? He died for you!", "Where is He now? Believe it thou,", "In heaven interceding! In heaven interceding!"],
        "refrainFirst": false
      }, {
        "number": "181",
        "name": "Does Jesus Care?",
        "category": "Jesus Christ",
        "subcategory": "Love of Christ for Us",
        "verses": [["Does Jesus care when my heart is pained", "Too deeply for mirth and song;", "As the burdens press, and the cares distress,", "And the way grows weary and long?"], ["Does Jesus care when my way is dark", "With a nameless dread and fear?", "As the daylight fades into deep night shades,", "Does He care enough to be near?"], ["Does Jesus care when I've said goodbye", "To the dearest on earth to me,", "And my sad heart aches till it nearly breaks -", "Is it aught to Him? Does He see?"]],
        "refrain": ["O yes, He cares- I know He cares!", "His heart is touched with my grief;", "When the days are weary,", "The long nights dreary,", "I know my Savior cares. (He cares.)"],
        "refrainFirst": false
      }, {
        "number": "182",
        "name": "Christ Is Alive",
        "category": "Jesus Christ",
        "subcategory": "Love of Christ for Us",
        "verses": [["Christ is alive!", "Let Christians sing.", "His cross stands empty to the sky.", "Let streets and homes with praises ring.", "His love in death shall never die."], ["Christ is alive!", "No longer bound", "To distant years in Palestine,", "He comes to claim the here and now", "And conquer every place and time."], ["In every insult,", "rift, and war", "Where color, scorn or wealth divide,", "He suffers still, yet loves the more,", "And lives, though ever crucified."], ["Christ is alive!", "Ascended Lord", "He rules the world His Father made,", "Till, in the end, His love adored", "Shall be to all on earth displayed."]]
      }, {
        "number": "183",
        "name": "I Will Sing of Jesus' Love",
        "category": "Jesus Christ",
        "subcategory": "Love of Christ for Us",
        "verses": [["I Will sing of Jesus love,", "Sing of Him,who first loved me;", "for He left bright worlds above,", "And died on Calvary."], ["O the depths of love divine!", "Earth or heaven can never know", "How that sin as dark as mine", "can be made as white as snow."], ["Nothing good for Him I've done;", "How could He such love bestow?", "Lord, I own my heart is won,", "help me now my love to show."]],
        "refrain": ["I will sing of Jesus love", "Endless praise my heart shall give;", "He has died that I might live", "I will sing His love to me."],
        "refrainFirst": false
      }, {
        "number": "184",
        "name": "Jesus Paid It All",
        "category": "Jesus Christ",
        "subcategory": "Love of Christ for Us",
        "verses": [["I hear the Savior say,", "\"Thy strength indeed is small;", "Child of weakness, watch and pray,", "Find in Me thine all in all.\""], ["Lord, now indeed I find", "Thy power, and Thine alone,", "Can change the leper's spots,", "And melt the heart of stone."], ["Since nothing good have I", "Whereby Thy grace to claim,", "I'll wash my garment white", "In the blood of Calvary's Lamb."], ["And when before the throne", "I stand in Him complete,", "I'll lay my trophies down,", "All down at Jesus' feet."]],
        "refrain": ["Jesus paid it all,", "All to Him I owe;", "Sin had left a crimson stain;", "He washed it white as snow."],
        "refrainFirst": false
      }, {
        "number": "185",
        "name": "Jesus Is All the World to Me",
        "category": "Jesus Christ",
        "subcategory": "Love of Christ for Us",
        "verses": [["Jesus is all the world to me,", "my life, my joy, my all;", "he is my strength from day to day,", "without him I would fall.", "When I am sad, to him I go,", "no other one can cheer me so;", "when I am sad, he makes me glad,", "he's my friend."], ["Jesus is all the world to me,", "my friend in trials sore;", "I go to him for blessings, and", "he gives them o'er and o'er.", "He sends the sunshine and the rain,", "he sends the harvest's golden grain;", "sunshine and rain, harvest of grain,", "he's my friend."], ["Jesus is all the world to me,", "and true to him I'll be;", "O how could I this friend deny,", "when he's so true to me?", "Following him I know I'm right,", "he watches o'er me day and night;", "following him by day and night,", "he's my friend."], ["Jesus is all the world to me,", "I want no better friend;", "I trust him now, I'll trust him when", "life's fleeting days shall end.", "Beautiful life with such a friend,", "beautiful life that has no end;", "eternal life, eternal joy,", "he's my friend."]]
      }, {
        "number": "186",
        "name": "I've Found a Friend",
        "category": "Jesus Christ",
        "subcategory": "Love of Christ for Us",
        "verses": [["I've found a Friend; oh, such a Friend!", "He loved me ere I knew Him;", "He drew me with the cords of love,", "And thus He bound me to Him.", "And 'round my heart still closely twine", "Those ties which nought can sever,", "For I am His, and He is mine,", "Forever and forever."], ["I've found a Friend; oh, such a Friend!", "He bled, He died to save me;", "And not alone the gift of life,", "But His own self He gave me.", "Nought that I have my own I call,", "I hold it for the Giver;", "My heart, my strength, my life my all,", "Are His, and His forever."], ["I've found a Friend; oh, such a Friend!", "All power to Him is given;", "To guard me on my upward course,", "And bring me safe to heaven.", "The eternal glories gleam afar,", "To nerve my faint endeavor;", "So now to watch, to work, to war,", "And then to rest forever."], ["I've found a Friend; oh, such a Friend!", "So kind, and true, and tender,", "So wise a counselor and guide,", "So mighty a defender.", "From Him, who loveth me so well,", "What power my soul can sever?", "Shall life or death, or earth, or hell?", "No; I am His forever."]]
      }, {
        "number": "187",
        "name": "Jesus, What a Friend for Sinners",
        "category": "Jesus Christ",
        "subcategory": "Love of Christ for Us",
        "verses": [["Jesus! what a Friend for sinners!", "Jesus! Lover of my soul;", "Friends may fail me, foes assail me,", "He, my Savior, makes me whole."], ["Jesus! what a Strength in weakness!", "Let me hide myself in Him.", "Tempted, tried, and sometimes failing,", "He, my Strength, my victory wins."], ["Jesus! what a Help in sorrow!", "While the billows over me roll,", "Even when my heart is breaking,", "He, my Comfort, helps my soul."], ["Jesus! I do now receive Him,", "[or Jesus! I do now adore Him,]", "More than all in Him I find.", "He hath granted me forgiveness,", "I am His, and He is mine."]],
        "refrain": ["Hallelujah! what a Savior!", "Hallelujah! what a Friend!", "Saving, helping, keeping, loving,", "He is with me to the end."],
        "refrainFirst": false
      }, {
        "number": "188",
        "name": "My Song Is Love Unknown",
        "category": "Jesus Christ",
        "subcategory": "Love of Christ for Us",
        "verses": [["My song is love unknown,", "My Savior's love to me,", "Love to the loveless shown,", "That they might lovely be.", "O who am I that for my sake", "My Lord should take frail flesh and die?"], ["He came from His blest throne,", "Salvation to bestow,", "But men made strange, and none", "The longed for Christ would know.", "But O my Friend, my Friend indeed", "Who at my need His life did spend."], ["Sometimes they strew His way,", "And His sweet praises sing,", "Resounding all the day,", "Hosannas to their King.", "Then \"Crucify\" is all their breath,", "And for His death they thirst and cry."], ["Why, what hath my Lord done?", "What makes this rage and spite?", "He made the lame to run,", "He gave the blind their sight.", "Sweet injuries! yet they at these", "Themselves displease, and 'gainst Him rise."], ["They rise, and needs will have", "My dear Lord made away;", "A murderer they save,", "The Prince of life they slay.", "Yet cheerful He to suff'ring goes,", "That He His foes from thence might free."], ["Here might I stay and sing,", "No story so divine:", "Never was love, dear King,", "Never was grief like Thine!", "This is my Friend, in whose sweet praise", "I all my days could gladly spend."]]
      }, {
        "number": "189",
        "name": "All That Thrills My Soul",
        "category": "Jesus Christ",
        "subcategory": "Love of Christ for Us",
        "verses": [["Who can cheer the heart like Jesus,", "By His presence all divine?", "True and tender, pure and precious,", "Oh, how blest to call Him mine!"], ["Love of Christ so freely given,", "Grace of God beyond degree,", "Mercy higher than the heaven,", "Deeper than the deepest sea."], ["Every need His hand supplying,", "Every good in Him I see;", "On His strength divine relying,", "He is All in All to me."], ["By the crystal flowing river", "With the ransomed I will sing,", "And forever and forever", "Praise and glorify the King"]],
        "refrain": ["All that thrills my soul is Jesus;", "He is more than life to me; (to me;)", "And the fairest of ten thousand,", "In my blessed Lord I see."],
        "refrainFirst": false
      }, {
        "number": "190",
        "name": "Jesus Loves Me",
        "category": "Jesus Christ",
        "subcategory": "Love of Christ for Us",
        "verses": [["Jesus loves me! this I know,", "For the Bible tells me so;", "Little ones to Him belong", "They are weak but He is strong"], ["Jesus loves me! He wo died", "Heaven's gate to open wide:", "He will wash away my sin,", "Let His little child come in."], ["Jesus, take this heart of mine,", "Make it pure and wholly thine;", "On the coross You died for me,", "I will love and live for Thee."]],
        "refrain": ["Yes, Jesus loves me!", "Yes, Jesus loves me!", "Yes, Jesus loves me!", "The Bible tells me so."],
        "refrainFirst": false
      }, {
        "number": "191",
        "name": "Love Divine",
        "category": "Jesus Christ",
        "subcategory": "Love of Christ for Us",
        "verses": [["Love divine, all loves excelling,", "joy of heaven, to earth come down;", "fix in us thy humble dwelling;", "all thy faithful mercies crown!", "Jesus thou art all compassion,", "pure, unbounded love thou art;", "visit us with thy salvation;", "enter every trembling heart."], ["Breathe, O breathe thy loving Spirit", "into every troubled breast!", "Let us all in thee inherit;", "let us find that second rest.", "Take away our bent to sinning;", "Alpha and Omega be;", "end of faith, as its beginning,", "set our hearts at liberty."], ["Come, Almighty to deliver,", "let us all thy life receive;", "suddenly return and never,", "nevermore thy temples leave.", "Thee we would be always blessing,", "serve thee as thy hosts above,", "pray and praise thee without ceasing,", "glory in thy perfect love."], ["Finish, then, thy new creation;", "pure and spotless let us be.", "Let us see thy great salvation", "perfectly restored in thee;", "changed from glory into glory,", "till in heaven we take our place,", "till we cast our crowns before thee,", "lost in wonder, love, and praise."]]
      }, {
        "number": "192",
        "name": "O Shepherd Divine",
        "category": "Jesus Christ",
        "subcategory": "Love of Christ for Us",
        "verses": [["O Shepherd divine, I know Thou art mine;", "Thy search in the night was for me.", "This bleak world is cold, but warm is Thy fold;", "My Shepherd, I follow Thee.", "Thy beautiful lamp shineth bright o'r my way,", "Thy glorious light unto Thy perfect day.", "Thro' pastures serene, Thro'valleys of green,", "My Shepherd, I follow Thee."], ["O Shepherd divine, I know Thou art mine;", "Thy great heart was broken for me.", "Thy grace and Thy law I picture in awe;", "They kissed upon Calvary.", "Ah! life that was given to ransom my soul,", "Ah! heart that was broken to make sinners whole,", "This world is but loss In view of Thy cross,", "My Shepherd, I follow Thee."], ["O Shepherd divine, I know Thou art mine;", "I hear Thee say, Follow thou Me.", "Thy message today illumines my way;", "The Spirit of Prophecy.", "I thrill at Thy marvelous love to Thy sheep,", "The way Thou dost lead to the still waters deep,", "One staff and one rod, One fold and one God,", "My Shepherd, I follow Thee."]]
      }, {
        "number": "193",
        "name": "Savior, Teach Me",
        "category": "Jesus Christ",
        "subcategory": "Love of Christ for Us",
        "verses": [["Savior, teach me day by day,", "Love's sweet lesson to obey,", "Sweeter lesson cannot be,", "Loving Him who first loved me."], ["With a child's glad heart of love,", "At Thy bidding may I move,", "Prompt to serve and follow Thee,", "Loving Him who first loved me."], ["Teach me I am not my own,", "I am Thine, and Thine alone;", "Thine to keep, to rule, to save", "From all sin that would enslave."], ["Love in loving finds employ,", "In obedience all her joy;", "Ever new that joy will be,", "Loving Him who first loved me."], ["Teach me thus Thy steps to trace,", "Strong to follow in Thy grace.", "Learning how to love from Thee,", "Loving Him who first loved me."]]
      }, {
        "number": "194",
        "name": "Sing We of the Modern City",
        "category": "Jesus Christ",
        "subcategory": "Love of Christ for Us",
        "verses": [["Sing we of the modern city,", "Scene alike of joy and stress;", "Sing we of its namelss people", "In their urban wilderness.", "Into endless rows of houses", "Life is set a millionfold,", "Life expressed in human beings", "Daily born and growing old."], ["In the city full of people,", "World of speed and hectic days,", "In the everchanging setting", "Of the latest trend and craze,", "Christ is present, and among us;", "In the crowd we see Him stand.", "In the bustle of the city", "Jesus Christ is every man."], ["God is not remote in heaven,", "But on earth to share our shame,", "Changing graph and mass and number", "Into persons with a name.", "Christ has shown, beyond statistics,", "Human life with glory crowned,", "By His timeless presence proving", "People matter, people count!"]]
      }, {
        "number": "195",
        "name": "Showers of Blessing",
        "category": "Jesus Christ",
        "subcategory": "Love of Christ for Us",
        "verses": [["\"There shall be showers of blessing;\"", "This is the promise of love;", "There shall be seasons refreshing,", "Sent from the Savior above."], ["\"There shall be showers of blessing;\"", "Precious reviving again;", "Over the hills and the valleys,", "Sound of abundance of rain."], ["\"There shall be showers of blessing;\"", "Send them upon us, O Lord;", "Grant to us now a refreshing;", "Come, and now honor Thy word."], ["\"There shall be showers of blessing;\"", "O that today they might fall,", "Now as to God were confessing,", "Now as on Jesus we call!"]],
        "refrain": ["Showers of blessing,", "Showers of blessing we need;", "Mercy drops round us are falling,", "But for the showers we plead."],
        "refrainFirst": false
      }, {
        "number": "196",
        "name": "Tell Me the Old, Old Story",
        "category": "Jesus Christ",
        "subcategory": "Love of Christ for Us",
        "verses": [["Tell me the old, old story, Of unseen things above,", "Of Jesus and His glory, Of Jesus and His love;", "Tell me the story simply, As to a little child,", "For I am weak and weary, And helpless and defiled."], ["Tell me the story softly, With earnest tones and grave;", "Remember I'm the sinner Whom Jesus came to save;", "Tell me the story always, If you would really be,", "In any time of trouble, A comforter to me."], ["Tell me the same old story, When you have cause to fear", "That this world's empty glory Is costing me too dear;", "Yes, and when that world's glory Is dawning on my soul,", "Tell me the old, old story: \"Christ Jesus makes thee whole.\""]]
      }, {
        "number": "197",
        "name": "The King of Love My Shepherd Is",
        "category": "Jesus Christ",
        "subcategory": "Love of Christ for Us",
        "verses": [["The King of love my Shepherd is,", "Whose goodness faileth never;", "I nothing lack if I am His,", "And He is mine forever."], ["Where streams of living water flow", "My ransomed soul He leadeth,", "And, where the verdant pastures grow,", "With food celestial feedeth."], ["Perverse and foolish, oft I strayed,", "But yet in love He sought me,", "And on His shoulder gently laid,", "And home, rejoicing, brought me."], ["And so through all the length of days", "Thy goodness faileth never;", "Good Shepherd! I would sing Thy praise", "Within Thy house forever."]]
      }, {
        "number": "198",
        "name": "And Can It Be?",
        "category": "Jesus Christ",
        "subcategory": "Love of Christ for Us",
        "verses": [["And can it be that I should gain", "an interest in the Savior's blood!", "Died he for me? who caused his pain!", "For me? who him to death pursued?", "Amazing love! How can it be", "that thou, my God, shouldst die for me?"], ["He left his Father's throne above", "so free, so infinite his grace!;", "emptied himself of all but love,", "and bled for Adam's helpless race.", "'Tis mercy all, immense and free,", "for O my God, it found out me!"], ["Long my imprisoned sprit lay,", "fast bound in sin and nature's night;", "thine eye diffused a quickening ray;", "I woke, the dungeon flamed with light;", "my chains fell off, my heart was free,", "I rose, went forth, and followed thee."], ["No condemnation now I dread;", "Jesus, and all in him, is mine;", "alive in him, my living Head,", "and clothed in righteousnes divine,", "bold I approach th' eternal throne,", "and claim the crown, through Christ my own."]],
        "refrain": ["Amazing love! How can it be", "that thou, my God, shouldst die for me?"],
        "refrainFirst": false
      }, {
        "number": "199",
        "name": "The Head That Once Was Crowned",
        "category": "Jesus Christ",
        "subcategory": "Love of Christ for Us",
        "verses": [["The head that once was crowned with thorns", "Is crowned with glory now;", "A royal diadem adorns", "The mighty victor's brow."], ["The highest place that heaven affords", "Is His, is His by right;", "The King of kings, and Lord of lords,", "And heaven's eternal light."], ["The joy of all who dwell above,", "The joy of all below,", "To whom He manifests His love,", "And grants His name to know."], ["To them the cross, with all its shame,", "With all its grace is given;", "Their name an everlasting name,", "Their joy the joy of heaven."]],
      }, {
        "number": "200",
        "name": "The Lord Is Coming",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["The Lord is coming, let this be", "The herald note of jubilee;", "And when we meet and when we part", "The salutation from the heart."], ["The Lord is coming! sound it forth", "From east to west, from south to north;", "Speed on! speed on the tidings glad,", "That none who love Him may be sad."], ["The Lord is coming, swift and sure", "And all His judgments shall endure,", "And none can hope to escape", "His wrath, Who walk not in the narrow path."], ["This earth, with her ten thousand wrongs", "Will soon be tuned to nobler songs;", "Our praise shall then, in realms of light,", "With all His universe unite."]],
        "refrain": ["The Lord is coming, let this be", "The herald note of jubilee,", "The herald note of jubilee."],
        "refrainFirst": false
      }, {
        "number": "201",
        "name": "Christ Is Coming",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["Christ is coming! let creation", "Bid her groans and travail cease;", "Let the glorious proclamation", "Hope restore and faith increase;", "Christ is coming! Christ is coming!", "Come, Thou blessed Prince of Peace!", "(Prince of Peace!)", "Come, Thou blessed Prince of Peace!"], ["Earth can now but tell the story", "Of Thy bitter cross and pain;", "She shall yet behold Thy glory", "When Thou comest back to reign;", "Christ is coming! Christ is coming!", "Let each heart repeat the strain.", "(repeat the strain)", "Let each heart repeat the strain."], ["With that \"blessed hope\" before us,", "Let no harp remain unstrung;", "Let the mighty advent refrain", "Onward roll from tongue to tongue:", "Christ is coming! Christ is soling!", "Come, Lord Jesus, quickly come!", "(quickly come!)", "Come, Lord Jesus, quickly come!"]]
      }, {
        "number": "202",
        "name": "Hail Him the King of Glory",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["Tell it to every kindred and nation,", "Tell it far and near;", "Earth's darkest night will fade with the dawning,", "Jesus will soon appear."], ["Nations again in strife and commotion,", "Warnings by the way;", "Signs in the heavens, unerring omens,", "Herald the glorious day."], ["Children of God look up with rejoicing;", "Shout and sing His praise;", "Blessed are they who, waiting and watching,", "Look for the dawning rays."]],
        "refrain": ["Hail Him the king of glory,", "Once the Lamb for sinners slain;", "Tell, tell the wondrous story,", "\"Jesus comes to reign.\""],
        "refrainFirst": false
      }, {
        "number": "203",
        "name": "This Is the Threefold Truth",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["This is the threefold truth", "On which our faith depends;", "And with this joyful cry", "Worship begins and ends."], ["By this we are upheld", "When doubt and grief assail", "Our Christian fortitude", "And only grace avails."], ["This is the threefold truth", "Which, if we hold it fast,", "Changes the world and us", "And brings us home at last."]],
        "refrain": ["Christ had died! Christ has risen!", "Christ will come again!"],
        "refrainFirst": false
      }, {
        "number": "204",
        "name": "Come, Thou Long Expected Jesus",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["Come, thou long expected Jesus!", "born to set thy people free;", "from our fears and sins release us,", "let us find our rest in thee.", "Israel's strength and consolation,", "hope of all the earth thou art;", "dear desire of every nation,", "joy of every longing heart."], ["Born thy people to deliver,", "born a child and yet a King,", "born to reign in us forever,", "now thy gracious kingdom bring.", "By thine own eternal spirit", "rule in all our hearts alone;", "by thine all sufficient merit,", "raise us to thy glorious throne."]]
      }, {
        "number": "205",
        "name": "Gleams of the Golden Morning",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["The golden morning is fast approaching;", "Jesus soon will come", "To take his faithful and happy children", "to their promised home"], ["The gospel summons will soon be carried", "to the nations round;", "The Bridegroom then will cease to tarry", "and the trumpet sound."], ["Attended by all the shining angels,", "Down the flaming sky", "the Judge will come, and will take his people", "where they will not die."], ["The lov'd of earth who have long been parted,", "Meet in that glad day;", "The tears of those who are broken hearted", "shall be wiped away."]],
        "refrain": ["O, we see the gleams of the golden morning", "piercing thro' this night of gloom!", "O, see the gleams of the golden morning", "that will burst the tomb."],
        "refrainFirst": false
      }, {
        "number": "206",
        "name": "Face to Face",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["Face to face with Christ my Savior,", "Face to face, what will it be,", "When with rapture I behold Him,", "Jesus Christ, who died for me?"], ["Only faintly now I see Him,", "With the darkening veil between,", "But a blessed day is coming,", "When His glory shall be seen."], ["What rejoicing in His presence,", "When are banished grief and pain;", "When the crooked ways are straightened,", "And the dark things shall be plain!"], ["Face to face! oh, blissful moment!", "Face to face to see and know;", "Face to face with my Redeemer,", "Jesus Christ, who loves me so."]],
        "refrain": ["Face to face shall I behold Him,", "Far beyond the starry sky;", "Face to face in all His glory", "I shall see Him by and by!"],
        "refrainFirst": false
      }, {
        "number": "207",
        "name": "It May Be at Morn",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["It may be at morn, when the day is awaking,", "When sunlight through darkness and shadow is breaking,", "That Jesus will come in the fullness of glory", "To receive from the world His own."], ["It may be at midday, it may be at twilight,", "It may be, perchance, that the blackness of midnight", "Will burst into light in the blaze of His glory,", "When Jesus receives His own."], ["O joy! O delight! should we go without dying,", "No sickness, no sadness, no dread, and no crying,", "Caught up through the clouds with our Lord into glory,", "When Jesus receives His own."]],
        "refrain": ["O Lord Jesus, how long, how long", "Ere we shout the glad song?", "Christ returneth, Hallelujah!", "Hallelujah! Amen, Hallelujah! Amen."],
        "refrainFirst": false
      }, {
        "number": "208",
        "name": "There'll Be No Dark Valley",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["There'll be no dark valleys when Jesus comes,", "There'll be no dark valleys when Jesus comes,", "There'll be no dark valleys when Jesus comes,", "To gather His loved ones home."], ["There'll be no more sorrow when Jesus comes,", "There'll be no more sorrow when Jesus comes,", "There'll be a happy tomorrow when Jesus comes,", "To gather His loved ones home."], ["There'll be songs of greeting when Jesus comes,", "There'll be songs of greeting when Jesus comes,", "There'll be songs of greeting when Jesus comes,", "To gather His loved ones home."]],
        "refrain": ["To gather His loved ones home,", "To gather His loved ones home.", "There'll be no dark valleys when Jesus comes,", "To gather His loved ones home."],
        "refrainFirst": false
      }, {
        "number": "209",
        "name": "That Glorious Day Is Coming",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["That glorious day is coming,", "The hour is hastening on;", "Its radiant light is nearing,", "Far brighter than the sun;", "In yonder clouds of heaven,", "The Savior will appear,", "And gather all His chosen,", "To meet Him in the air."], ["The saints, then all victorious", "Will go to meet their Lord;", "An earth both bright and glorious,", "Will then be their reward;", "And God Himself there reigning,", "Will wipe all tears away;", "Nor clouds nor night remaining,", "But one eternal day."], ["O Christian, keep from sleeping,", "And let your love abound;", "Be watchful, prayerful, faithful,", "The trumpet soon will sound!", "O sinner, hear the warning!", "To Jesus quickly!", "Then you, in that blest morning,", "May meet Him in the sky!"]]
      }, {
        "number": "210",
        "name": "Wake, Awake. for the Night Is Flying",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["Wake, awake, for night is flying,", "The watchmen on the heights are crying,", "Awake, Jerusalem, arise!", "Midnight's solemn hour is tolling,", "His chariot wheels are nearer rolling,", "He comes; prepare, ye virgins wise.", "Rise up with willing feet", "Go forth, the Bridegroom meet; Alleluia!", "Bear through the night your well-trimmed light,", "Speed forth to join the marriage rite."], ["Zion hears the watchmen singing,", "Her heart with deep delight is springing,", "She wakes, she rises from her gloom;", "Forth her Bridegroom comes, all-glorious,", "In grace arrayed, by truth victorious;", "Her Star is risen, her Light is come!", "All hail, incarnate Lord,", "Our crown, and our reward! Alleluia!", "We haste along, in pomp and song,", "And gladsome join the marriage throng."], ["Lamb of God, the heavens adore Thee,", "And men and angels sing before Thee,", "With harp and cymbal's clearest tone.", "By the pearly gates in wonder", "We stand, and swell the voice of thunder,", "That echoes round Thy dazzling throne.", "No vision ever brought,", "No ear hath ever caught,", "Such bliss and joy;", "We raise the song, we swell the throng,", "To praise Thee ages all along."]]
      }, {
        "number": "211",
        "name": "Lo! He Comes",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["Lo! He comes, with clouds descending,", "Once for favored sinners slain;", "Countless angels, Him attending,", "Swell the triumph of His train:", "Hallelujah! Hallelujah!", "Jesus comes, and comes to reign."], ["Every eye shall now behold Him", "Robed in dreadful majesty!", "Those who set at nought and sold Him,", "Pierced, and nailed Him to the tree,", "Deeply wailing, Deeply wailing,", "Shall the true Mesiah see!"], ["When the solemn trump has sounded,", "Heaven and earth shall flee away;", "All who hate Him, must, confounded,", "Hear the summons of that day-", "\"Come to judgment! Come to judgment!", "Come to judgment! Come away!\""], ["Yea, amen! let all adore Thee,", "High on Thy eternal throne!", "Savior, take the power and glory,", "Make Thy righteous sentence known;", "O come quickly, O come quickly,", "Claim the kingdom for Thine own!"]]
      }, {
        "number": "212",
        "name": "'Tis Almost Time for the Lord to Come",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["'Tis almost time for the Lord to come,", "I hear the people say;", "The stars of heaven are growing dim,", "It must be the breaking of the day."], ["The signs foretold in the sun and moon,", "In earth and sea and sky,", "Aloud proclaim to all mankind,", "The coming of the Master draweth nigh."], ["It must be time for the waiting church", "To cast her pride away,", "With girded loins and burning lamps,", "To look for the breaking of the day."], ["Go quickly out in the streets and lanes", "And in the broad highway,", "And call the maimed, the halt, and blind,", "To be ready for the breaking of the day."]],
        "refrain": ["O it must be the breaking of the day!", "O it must be the breaking of the day!", "The night is almost gone,", "The day is coming on;", "O it must be the breaking of the day!"],
        "refrainFirst": false
      }, {
        "number": "213",
        "name": "Jesus Is Coming Again",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["Lift up the trumpet, and loud let it ring:", "Jesus is coming again!", "Cheer up, ye pilgrims, be joyful and sing:", "Jesus is coming again!"], ["Echo it, hilltops; proclaim it, ye plains:", "Jesus is coming again!", "Coming in glory, the Lamb that was slain;", "Jesus is coming again!"], ["Heavings of earth, tell the vast, wondering throng:", "Jesus is coming again!", "Tempests and whirlwinds, the anthem prolong;", "Jesus is coming again!"], ["Nations are angry-by this we do know", "Jesus is coming again!", "Knowledge increases; men run to and fro;", "Jesus is coming again!"]],
        "refrain": ["Coming again, coming again,", "Jesus is coming again!"],
        "refrainFirst": false
      }, {
        "number": "214",
        "name": "We Have This Hope",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "other": [["Hope in the coming of the Lord.", "We have this faith that Christ alone imparts,", "Faith in the promise of His Word.", "We believe the time is here,", "When the nations far and near", "Shall awake, and shout and sing", "Hallelujah! Christ is King!", "We have this hope that burns within our heart,", "Hope in the coming of the Lord."], ["We are united in His love.", "Love for the waiting people of the world,", "People who need our Savior's love.", "Soon the heav'ns will open wide,", "Christ will come to claim His bride,", "All the universe will sing", "Hallelujah! Christ is King!", "We have this hope this faith, and God's great love,", "We are united in Christ."]]
      }, {
        "number": "215",
        "name": "The King Shall Come",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["The King shall come when morning dawns", "And light triumphant breaks,", "When beauty gilds the eastern hills", "And life to joy awakes."], ["Not as of old a little child,", "To bear and fight and die,", "But crowned with glory like the sun", "That lights the morning sky."], ["O, brighter than the rising morn", "When Christ, victorious, rose", "And left the lonesome place of death,", "Despite the rage of foes."], ["O, brighter than that glorious morn", "Shall dawn upon our race", "The day when Christ in splendor comes,", "And we shall see His face."], ["The King shall come when morning dawns", "And light and beauty brings.", "Hail, Christ the Lord! Your people pray:", "Come quickly, King of kings."]]
      }, {
        "number": "216",
        "name": "When the Roll Is Called Up Yonder",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["When the trumpet of the Lord shall sound,", "and time shall be no more,", "And the morning breaks, eternal, bright and fair;", "When the saved of earth shall gather", "over on the other shore,", "And the roll is called up yonder, I'll be there."], ["On that bright and cloudless morning,", "when the dead in Christ shall rise,", "And the glory of His resurection share;", "When His chosen ones shall gather", "to their home beyond the skies,", "And the roll is called up yonder, I'll be there."], ["Let us labor for the Master", "from the dawn till setting sun,", "Let us talk of all His wondrous love and care,", "Then, when all of life is over,", "and our work on earth is done,", "And the roll is called up yonder I'll be there."]],
        "refrain": ["When the roll is called up yonder,", "When the roll is called up yonder,", "When the roll is called up yonder,", "When the roll is called up yonder, I'll be there."],
        "refrainFirst": false
      }, {
        "number": "217",
        "name": "The Church Has Waited Long",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["The church has waited long", "Her absent Lord to see;", "And still in loneliness she waits,", "A friendless stranger she."], ["How long, O Lord our God,", "Holy and true and good,", "Wilt Thou not judge Thy suffering church,", "Her sighs and tears and blood?"], ["We long to hear Thy voice,", "To see Thee face to face,", "To share Thy crown and glory then,", "As now we share Thy grace."], ["Come, Lord, and wipe away", "The curse, the sin, the stain,", "And make this blighted world of ours", "Thine own fair world again."]]
      }, {
        "number": "218",
        "name": "When He Cometh",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["When He cometh, when He cometh", "To make up His jewels,", "All His jewels, precious jewels,", "His loved and His own."], ["He will gather, He will gather", "The gems for His kingdom;", "All the pure ones, all the bright ones,", "His loved and His own."], ["Little children, little children,", "Who love their Redeemer,", "Are the jewels, precious jewels,", "His loved and His own."]],
        "refrain": ["Like the stars of the morning,", "His brightness adorning,", "They shall shine in their beauty,", "Bright gems for His crown."],
        "refrainFirst": false
      }, {
        "number": "219",
        "name": "When Jesus Comes in Glory",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["When Jesus comes in glory,", "As Lord and King of kings,", "O what a wondrous story", "The blessed Bible brings:", "His face will shine like sunlight,", "His head be white as snow,", "His eyes like flaming firelight,", "His feet like brass aglow."], ["His voice like rushing waters", "Will reach with mighty sound", "Into the deepest quarters", "Of all creation round;", "And at this wondrous greeting", "The dead in Christ shall rise,", "Their Lord and Savior meeting", "In glory in the skies."], ["And we who are believing", "And His appearing love,", "Shall know we are receiving", "His glory from above;", "His resurrection power", "Will raise us to the place", "Where we that wondrous hour", "Shall see Him face to face."], ["O hasten Thine appearing,", "Thou bright and Morning Star!", "Lord, may we soon be hearing", "The trumpet sound afar;", "Thy people all are yearning", "To be Thy raptured bride,", "And at Thine own returning", "Be caught up to Thy side."]]
      }, {
        "number": "220",
        "name": "When He Comes",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["When He comes, (when He comes)", "When He comes, (when He comes)", "We shall see the Lord in glory", "when He comes! (When He comes)", "As I read the gospel story,", "We shall see the Lord in glory,", "We shall see the Lord in glory", "when He comes! (when He comes)", "With the alleluias ringing to the sky, (to the sky)", "With the alleluias ringing to the sky, (to the sky)", "As I read the gospel story,", "We shall see the Lord in glory,", "With the alleluias ringing to the sky! (to the sky)"], ["When He comes, (when He comes)", "When He comes, (when He comes)", "We shall hear the trumpet sounded,", "when He comes! (When He comes)", "We shall hear the trumpet sounded,", "See the Lord by saints surrounded,", "We shall hear the trumpet sounded,", "when He comes! (when He comes)", "With the alleluias ringing to the sky, (to the sky)", "With the alleluias ringing to the sky, (to the sky)", "We shall hear the trumpet sounded,", "See the Lord by saints surrounded,", "With the alleluias ringing to the sky! (to the sky)"], ["When He comes, (when He comes)", "When He comes, (when He comes)", "We shall all rise up to meet Him", "when He comes! (WhenHe comes)", "We shall all rise up to meet Him,", "When He calls His own to greet Him", "We shall all rise up to meet Him", "when He comes! (when He comes)", "With the alleluias ringing to the sky, (to the sky)", "With the alleluias ringing to the sky, (to the sky)", "We shall all rise up to meet Him,", "When He calls His own to greet Him,", "With the alleluias ringing to the sky! (to the sky)"]]
      }, {
        "number": "221",
        "name": "Rejoice, the Lord Is King",
        "category": "Jesus Christ",
        "subcategory": "Second Advent",
        "verses": [["Rejoice, the Lord is King!", "Your Lord and King adore!", "Rejoice, give thanks, and", "sing and triumph evermore:", "Lift up your heart,", "lift up your voice!", "Rejoice, again", "I say, rejoice!"], ["Jesus, the Savior, reigns,", "The God of truth and love;", "When He had purged our stains,", "He took His seat above:", "Lift up your heart,", "lift up your voice!", "Rejoice, again", "I say, rejoice!"], ["His kingdom cannot fail,", "He rules o'er earth and heaven;", "The keys of death and grave", "Are to our Jesus given:", "Lift up your heart,", "lift up your voice!", "Rejoice, again", "I say, rejoice!"], ["Rejoice in glorious hope!", "Our Lord the judge shall come,", "And take His servants up", "To their eternal home:", "Lift up your heart,", "lift up your voice!", "Rejoice, again", "I say, rejoice!"]]
      }, {
        "number": "222",
        "name": "Hark! Ten Thousand Harps and Voices",
        "category": "Jesus Christ",
        "subcategory": "Kingdom and Reign",
        "verses": [["Hark! ten thousand harps and voices", "Sound the note of praise above;", "Jesuse reigns, and heaven rejoices,", "Jesus reigns, the God of love:", "See, He sits on yonder throne;", "Jesus rules the world alone.", "Alleluia! Alleluia!", "Alleluia! Amen."], ["King of glory, reign forever,", "Thine an everlasting crown;", "Nothing from Thy love shall sever", "Those whom Thou hast made Thine own;", "Happy objects of Thy grace,", "Destined to behold Thy face.", "Alleluia! Alleluia!", "Alleluia! Amen."], ["Savior, hasten Thine appearing;", "Bring, O bring the glorious day,", "When, the awful summons hearing,", "Heaven and earth shall pass away:;", "Then, with golden harps we'll sing,", "\"Glory, glory to our King!\"", "Alleluia! Alleluia!", "Alleluia! Amen."]]
      }, {
        "number": "223",
        "name": "Crown Him With Many Crowns",
        "category": "Jesus Christ",
        "subcategory": "Kingdom and Reign",
        "verses": [["Crown him with many crowns,", "the Lamb upon his throne,", "Hark! how the heavenly anthem drowns", "all music but its own.", "Awake, my soul, and sing", "of him who died for thee,", "and hail him as thy matchless King", "through all eternity."], ["Crown him the Lord of love!", "behold his hands and side,", "those wounds, yet visible above,", "in beauty glorified;", "No angel in the sky", "Can fully bear that sight,", "But downward bend his wondering eye", "At mysteries so great."], ["Crown him the Lord of peace!", "whose power a scepter sways", "from pole to pole, that wars may cease,", "and all be prayer and praise.", "His reign shall know no end,", "and round his pierced feet", "fair flowers of paradise extend", "their fragrance ever sweet."], ["Crown him the Lord of years;", "The potentate of time,", "Creator of the rolling spheres,", "Ineffably sublime!", "All hail, Redeemer, hail!", "For thou hast died for me;", "thy praise shall never, never fail", "throughout eternity."]]
      }, {
        "number": "224",
        "name": "Seek Ye First the Kingdom",
        "category": "Jesus Christ",
        "subcategory": "Kingdom and Reign",
        "verses": [["Seek ye first the kingdom, 'Tis your Father's will.", "So the voice of Jesus bids us follow still.", "Savior, we would hear Thee, Follow, find, and see;", "And in life's adventure Thy disciples be."], ["As for hidden treasure, Or for matchless pearl,", "When at last discovered, some will sell their all:", "So, when breaks the vision of that kingdom fair,", "Ours shall be its riches and its beauty rare."], ["As the silent leaven works its secret way,", "Or as grows the seed grain through the night and day;", "Lord, so be the increase, peaceable but sure,", "Of Thy word within us, And Thy kingdom's power."], ["As the tender seedling grows up tall and strong,", "And the birds of heaven to its branches throng;", "So shall all God's children, from the east and west,", "Gather to His kingdom, In its shadow rest."], ["Humblest shall be greatest, poor in spirit reign;", "Home shall come the childlike, born through Thee again", "Eager hearts arrive there on the pilgrim's road,", "Hail! the kingdom glorious of the living God!"]]
      }, {
        "number": "225",
        "name": "God Is Working His Purpose Out",
        "category": "Jesus Christ",
        "subcategory": "Kingdom and Reign",
        "verses": [["God is working His purpose out", "As year suceeds to year;", "God is working His purpose out,", "And the time is drawing near;", "Nearer and nearer draws the time,", "The time that shall surely be", "When the earth shall be filled with the glory of God", "As the waters cover the sea."], ["From utmost east to utmost west,", "Where'er man's foot hath trod,", "By the mouth of many messengers", "Goes forth the voice of God;", "Give ear to me, ye confinents,", "Ye isles, give ear to me,", "That the earth shall be filled with the glory of God", "As the waters cover the sea."], ["March we forth in the strength of God", "With the banner of Christ unfurled,", "That the light of the glorious gospel of truth", "May shine throughout the world:", "Fight we the fight with sorrow and sin", "To set their captives free,", "That the earth shall be filled with the glory of God", "As the waters cover the sea."], ["All we can do is nothing worth", "Unless God blesses the deed;", "Vainly we hope for the harvest tide", "Till God gives life to teh seed;", "Yet nearer and nearer draws the time,", "The time that shall surely be,", "When the earth shall be filled with the glory of God", "As the waters cover the sea."]]
      }, {
        "number": "226",
        "name": "Lift Up Your Heads",
        "category": "Jesus Christ",
        "subcategory": "Kingdom and Reign",
        "verses": [["Lift up your heads, ye mighty gates!", "Behold the King of glory waits;", "The King of kings is drawing near,", "The Savior of the world is here."], ["The Lord is just, a helper tried;", "Mercy is ever at His side;", "His kingly crowns is holiness,", "His scepter, pity in distress."], ["O blest the land, the city blest,", "Where Christ the Ruler is confessed!", "O happy hearts and happy homes", "To whom this King in triumph comes!"], ["Fling wide the portals of your heart;", "Make it a temple, set apart", "From earthly use for heaven's employ,", "Adorned with prayer, and love, and joy."], ["Redeemer, come; I open wide", "My heart to Thee; here, Lord, abide.", "Let me Thy inner presence feel,", "Thy grace and love in me reveal."]]
      }, {
        "number": "227",
        "name": "Jesus Shall Reign",
        "category": "Jesus Christ",
        "subcategory": "Kingdom and Reign",
        "verses": [["Jesus shall reign where'er the sun", "Does his successive journeys run;", "His kingdom stretch from shore to shore,", "Till moons shall wax and wane no more."], ["People and realms of ev'ry tongue", "Dwell on His love with sweetest song,", "And infant voices shall proclaim", "Their early blessings on His name."], ["Blessings abound wherever He reigns;", "The prisoner leaps to lose his chains;", "The weary find eternal rest,", "And all who suffer want are blessed."], ["Let every creature rise and bring", "Honors peculiar to our King;", "Angels descend with songs again,", "And earth repeat the loud amen!"]]
      }, {
        "number": "228",
        "name": "A Hymn of Glory Let Us Sing",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["A hymn of glory let us sing;", "New hymns thro'-out the world shall ring", "Alleluia!  Alleluia!", "Christ, by a road before untrod,", "Ascends unto the throne of God.", "Alleluia!  Alleluia!", "Alleluia, alleluia, alleluia!"], ["O Lord, our homeward pathway bend,", "That our unwearied hearts ascend.", "Alleluia!  Alleluia!", "Where, seated on Your Father's throne,", "You reign as King of kings alone.", "Alleluia!  Alleluia!", "Alleluia, alleluia, alleluia!"], ["Give us Your joy on earth, O Lord,", "In heav'n to be our great reward.", "Alleluia!  Alleluia!", "When throned with You forever, we", "Shall praise Your name eternally.", "Alleluia!  Alleluia!", "Alleluia, alleluia, alleluia!"], ["O risen Christ, ascended Lord,", "All praise to you let earth accord:", "Alleluia!  Alleluia!", "You are, while endless ages run,", "With Father and with Spirit one.", "Alleluia!  Alleluia!", "Alleluia, alleluia, alleluia!"]]
      }, {
        "number": "229",
        "name": "All Hail the Power of Jesus' Name",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["All hail the power of Jesus' name! Let angels prostrate fall;", "Bring forth the royal diadem, and crown Him Lord of all.", "Bring forth the royal diadem, and crown Him Lord of all."], ["Ye chosen seed of Israel's race, ye ransomed from the fall,", "Hail Him who saves you by His grace, and crown Him Lord of all.", "Hail Him who saves you by His grace, and crown Him Lord of all."], ["Let every kindred, every tribe on this terrestrial ball", "To Him all majesty ascribe, and crown Him Lord of all.", "To Him all majesty ascribe, and crown Him Lord of all."], ["O that with yonder angel throng we at His feet may fall!", "We'll join the everlasting song, and crown Him Lord of all.", "We'll join the everlasting song, and crown Him Lord of all."]]
      }, {
        "number": "230",
        "name": "All Glory, Laud, and Honor",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["All glory, laud, and honor,", "to thee, Redeemer, King,", "to whom the lips of children", "made sweet hosannas ring.", "Thou art the King of Israel,", "thou David's royal Son,", "who in the Lord's name comest,", "the King and Blessed One."], ["The company of angels", "are praising thee on high,", "and we with all creation", "in chorus make reply.", "The people of the Hebrews", "with psalms before thee went;", "our prayer and praise and anthems", "before thee we present."], ["To thee, before thy passion,", "they sang their hymns of praise;", "to thee, now high exalted,", "our melody we raise.", "Thou didst accept their praises;", "accept the prayers we bring,", "who in all good delightest,", "thou good and gracious King."]]
      }, {
        "number": "231",
        "name": "Blest Be the King",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["Blest be the King whose coming", "Is in the name of God!", "For Him let doors be opened,", "No hearts against Him barred!", "Not robed in royal splendor,", "In power and pomp comes He;", "But clad as are the poorest,", "Such His humility."], ["Blest be the King whose coming", "Is in the name of God!", "By those who truly listen", "His voice is truly heard;", "Pity the proud and haughty,", "Who have not learned to heed", "The Christ who is the promise,", "Who has atonement made."], ["Blest be the King whose coming", "Is in the name of God!", "He only to the humble", "Reveals the face of God.", "All power is His, all glory!", "All things are in His hand,", "All ages and all peoples,", "'Till time itself shall end."], ["Blest be the King whose coming", "Is in the name of God!", "He offers to the burdened", "The rest and grace they need.", "Gentle is He and humble!", "And light his Yoke shall be,", "For He would have us bear it,", "So He can made us free!"]]
      }, {
        "number": "232",
        "name": "At the Name of Jesus",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["At the name of Jesus every knee shall bow,", "Every tongue confess Him King of glory now;", "\"Tis the Father's pleasure we should call Him Lord,", "Who from the beginning was the mighty Word."], ["At His voice creation sprang at once to sight,", "All the angel faces, All the hosts of light,", "Thrones and dominations stars upon their way,", "All the heavenly orders in their great array."], ["Humbled for a season, To receive a name", "From the lips of sinners, Unto whom He came;", "He is God the Savior, He is Christ the Lord,", "Ever to be worshiped, Trusted and adored."], ["In your hearts enthrone Him: There let Him subdue", "All that is not holy, All that is not true;", "Crown Him as your captain in temptation's hour,", "Let His will enfold you in its light and power."], ["Surely, this Lord Jesus shall return again,", "With His Father's glory, With His angel train;", "For all wreaths of empire meet upon His brow,", "And our hearts confess Him King of glory now."]]
      }, {
        "number": "233",
        "name": "Christ, Whose Glory Fills the Skies",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["Christ, whose glory fills the skies,", "Christ, the true, the only light,", "Sun of Righteousness, arise,", "triumph o'er the shades of night;", "Dayspring from on high, be near;", "Daystar, in my heart appear."], ["Dark and cheerless is the morn", "unaccompanied by thee;", "joyless is the day's return,", "till thy mercy's beams I see;", "till they inward light impart,", "cheer my eyes and warm my heart."], ["Visit then this soul of mine;", "pierce the gloom of sin and grief;", "fill me, Radiancy divine,", "scatter all my unbelief;", "more and more thyself display,", "shining to the perfect day."]]
      }, {
        "number": "234",
        "name": "Christ Is the World's Light",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["Christ is the world's Light,", "He and none other; born in our darkness,", "He became our brother.", "If we have seen Him, we have seen the Father:", "Glory to God on high!"], ["Christ is the world's Peace,", "He and none other; no one can serve Him", "and despise another.", "Who else unites us one in God the Father?", "Glory to God on high!"], ["Christ is the world'sLife,", "He and none other; sold once for silver,", "murdered here, our broher,", "He who redeems us reigns with God the Father."], ["Give God the glory,", "God and none other; give God the glory,", "Spirit, Son, and Father; give God the glory,", "God in Man my brother; Glory to God on high!"]]
      }, {
        "number": "235",
        "name": "Christ Is Made the Sure Foundation",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["Christ is made the sure foundation,", "Christ, our head and cornerstone,", "Chosen of the Lord and precious,", "Binding all the Church in one;", "Holy Zion's help forever", "And our confidence alone."], ["To this temple where we call You,", "Come, O Lord of hosts, and stay;", "Come, with all Your loving kindness,", "Hear Your people as they pray;", "And Your fullest benediction", "Shed within these walls today."], ["Grant, we pray to all Your faithful", "All the gifts they ask to gain;", "What they gain from You forever", "With the blessed to retain;", "And hereafter in Your glory", "Evermore with You to reign."], ["Praise and honor to the Father,", "Praise and honor to the Son,", "Praise and honor to the Spirit,", "Ever three and ever one:", "One in might and one in glory", "While unending ages run!"]]
      }, {
        "number": "236",
        "name": "I Love Thee",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["I love Thee, I love Thee,", "I love Thee, my Lord;", "I love Thee, my Savior,", "I love Thee, my God.", "I love Thee, I love Thee,", "and that Thou dost know;", "But how much I love Thee", "my actions will show."], ["I'm happy, I'm happy,", "O, wondrous account!", "My joys are immortal,", "I stand on the mount!", "I gaze on my treasure", "and long to be there,", "With Jesus and angles,", "and kindred so dear."], ["O Jesus, my Savior,", "with Thee I am blest,", "My life and salvation,", "my joy and my rest:", "Thy name by my theme,", "and Thy love be my song;", "Thy grace shall inspire both", "my heart and my tongue."], ["O, who's like my Savior?", "He's Salem's bright King;", "He smiles, and He loves me,", "and helps me to sing.", "I'll praise Him, I'll praise Him,", "with notes loud and clear,", "While rivers of pleasure", "my spirit do cheer."]]
      }, {
        "number": "237",
        "name": "In the Cross of Christ I Glory",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["In the cross of Christ I glory,", "towering o'er the wrecks of time;", "all the light of sacred story", "gathers round its head sublime."], ["When the woes of life o'ertake me,", "hopes deceive, and fears annoy,", "never shall the cross forsake me.", "Lo! it glows with peace and joy."], ["When the sun of bliss is beaming", "light and love upon my way,", "from the cross the radiance streaming", "adds more luster to the day."], ["Bane and blessing, pain and pleasure,", "by the cross are sanctified;", "peace is there that knows no measure,", "joys that through all time abide."]]
      }, {
        "number": "238",
        "name": "How Sweet the Name!",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["How sweet the name of Jesus sounds", "In a believer's ear!", "It soothes his sorrows, heals his wounds,", "And drives away his fear."], ["It makes the wounded spirit whole,", "And calms the troubled breast;", "\"Tis manna to the hungry soul,", "And to the weary, rest."], ["Dear name, the rock on which I build,", "My shield and hiding place,", "My neverfailing treasury, filled", "With boundless stores of grace."], ["Jesus! my Shepherd, Guardian, Friend,", "My Prophet, Priest, and King!", "My Lord, my life, my way, my end!", "Accept the praise I bring."], ["Weak is the effort of my heart,", "And cold my warmest thought;", "But when I see Thee as Thou art,", "I'll praise Thee as I ought."]]
      }, {
        "number": "239",
        "name": "Jesus, Priceless Treasure",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["Jesus, priceless treasure,", "source of purest pleasure,", "truest friend to me,", "long my heart hath panted,", "till it well-nigh fainted,", "thirsting after thee.", "Thine I am, O spotless Lamb,", "I will suffer naught to hide thee,", "ask for naught beside thee."], ["In thine arms I rest me;", "foes who would molest me", "cannot reach me here.", "Though the earth be shaking,", "every heart be quaking,", "Jesus calms our fear;", "sin and hell in conflict fell", "with their heaviest storms assail us;", "Jesus will not fail us."], ["Hence, all thoughts of sadness!", "For the Lord of gladness,", "Jesus, enters in.", "Those who love the Father,", "through the storms may gather,", "still have peace within;", "yea, whate'er we here must bear,", "still in thee lies purest pleasure,", "Jesus, priceless treasure!"]]
      }, {
        "number": "240",
        "name": "Fairest Lord Jesus",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["Fairest Lord Jesus, ruler of all nature,", "O thou of God and man the Son!", "Thee will I cherish, Thee will I honor,", "thou, my soul's glory, joy, and crown."], ["Fair are the meadows, fairer still the woodlands,", "robed in the blooming garb of spring:", "Jesus is fairer, Jesus is purer", "who makes the woeful heart to sing."], ["Fair is the sunshine, fairer still the moonlight,", "and all the twinkling starry host:", "Jesus shines brighter, Jesus shines purer", "than all the angels heaven can boast."], ["Beautiful Savior! Lord of all the nations,", "Son of God and Son of Man!", "Glory and honor, praise, adoration,", "now and forevermore be thine!"]]
      }, {
        "number": "241",
        "name": "Jesus, the Very Thought of Thee",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["Jesus, the very thought of thee", "with sweetness fills the breast;", "but sweeter far thy face to see,", "and in thy presence rest."], ["No voice can sing, no heart can frame,", "Nor can the memory find", "A sweeter sound than Jesus' name,", "The Savior of mankind."], ["O hope of every contrite heart!", "O joy of all the meek,", "to those who fall, how kind thou art!", "How good to those who seek!"], ["But what to those who find? Ah, this", "nor tongue nor pen can show;", "the love of Jesus, what it is,", "none but his loved ones know."], ["Jesus, our only joy be thou,", "as thou our prize wilt be;", "Jesus, be thou our glory now,", "and through eternity."]]
      }, {
        "number": "242",
        "name": "Jesus, Thou Joy of Loving Hearts",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["Jesus, Thou joy of loving hearts!", "Thou fount of life! Thou light of men!", "From the best bliss that earth imparts,", "We turn unfilled to Thee again."], ["Thy truth unchanged has ever stood;", "Thou savest those that on Thee call;", "To them that seek Thee Thou art good,", "To them that find Thee, all in all."], ["We taste Thee, O Thou living Bread,", "And long to feast upon Thee still;", "We drink of Thee, the Fountainhead,", "And thirst our souls from Thee to fill!"], ["Our restless spirits yearn for Thee,", "Where-e'r our changeful lot is cast;", "Glad, when Thy gracious smile we see,", "Blest, when our faith can hold Thee fast."], ["O Jesus, ever with us stay;", "Make all our moments calm and bright;", "Chase the dark night of sin away,", "Shed o'r the world Thy holy light!"]]
      }, {
        "number": "243",
        "name": "King of Glory, King of Peace",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["King of glory, King of peace, I will love Thee;", "And that love may never cease, I will move Thee.", "Thou hast granted my request, Thou hast heard me;", "Thou didst note my working breast, Thou hast spared me."], ["Wherefore with my utmost art I will sing Thee,", "And the cream of all my heart I will bring Thee.", "Though my sins against me cried, Thou didst clear me;", "And alone, when they replied, Thou didst hear me."], ["Seven whole days, not one in seven, I will praise Thee;", "In my heart, though not in heav'n I can raise Thee.", "Small it is, in this poor sort to enroll Thee:", "Ev'n eternity's too short to extol Thee."]]
      }, {
        "number": "244",
        "name": "My Song Shall Be of Jesus",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["My song shall be of Jesus;", "His mercy crowns my days,", "He fills my cup with blessings,", "And tunes my heart to praise.", "My song shall be of Jesus,", "The precious Lamb of God,", "Who gave Himself my ransom,", "And bought me with His blood."], ["My song shall be of Jesus", "When, sitting at His feet,", "I call to mind His goodness", "In meditation sweet:", "My song shall be of Jesus,", "Whatever ill betide;", "I'll sing the grace that saves me,", "And keeps me at His side."], ["My song shall be of Jesus", "While pressing on my way", "To reach the blissful kingdom", "Of pure and perfect day:", "And when my soul shall enter", "That realm of splendor fair,", "A song of praise to Jesus", "I'll sing forever there."]]
      }, {
        "number": "245",
        "name": "More About Jesus",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["More about Jesus, I would know,", "More of His grace to others show;", "More of His saving fullness see,", "More of His love who died for me."], ["More about Jesus, let me learn,", "More of His holy will discern;", "Spirit of God, my teacher be,", "Showing the things of Christ to me."], ["More about Jesus, in His word,", "Holding communion with my Lord;", "Hearing His voice in every line,", "Making each faithful saying mine."], ["More about Jesus, on His throne,", "Riches in glory all His own;", "More of His kingdom's sure increase,", "More of His coming, Prince of Peace."]],
        "refrain": ["More, more about Jesus,", "More, more about Jesus;", "More of His saving fullness see,", "More of His love who died for me."],
        "refrainFirst": false
      }, {
        "number": "246",
        "name": "Worthy, Worthy Is the Lamb",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["Worthy, worthy is the Lamb,", "Worthy, worthy is the Lamb,", "Worthy, worthy is the Lamb,", "That was slain."], ["Savior, let Thy kingdom come!", "Now the power of sin consume;", "Bring Thy blest millennium,", "Holy Lamb."], ["Thus may we", "each moment feel,", "Love Him, serve Him,", "praise Him still,", "Till we all on Zion's hill", "See the Lamb."]],
        "refrain": ["Glory, hallelujah!", "Praise Him, hallelujah!", "Glory, hallelujah!", "To the Lamb!"],
        "refrainFirst": false
      }, {
        "number": "247",
        "name": "Come, My Way",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["Come, my Way, my Truth, my Life:", "Such a Way, as gives us breath;", "Such a Truth, as ends all strife;", "Such a Life, as killeth death."], ["Come, my Light, my Feast, my Strength:", "Such a Light, as shows a feast;", "Such a Feast, as mends in length;", "Such a Strength, as makes His guest."], ["Come, my Joy, my Love, my Heart:", "Such a Joy, as none can move;", "Such a Love, as none can part;", "Such a Heart, as joys in love."]]
      }, {
        "number": "248",
        "name": "O, How I Love Jesus",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["There is a Name I love to hear,", "I love to sing its worth;", "It sounds like music in my ears,", "The sweetest Name on earth."], ["It tells me of a Savior's love,", "Who died to set me free;", "It tells me of a Savior's blood,", "The sinners' perfect plea."], ["It tells of one whose loving heart", "Can feel my deepest woe;", "Who in each sorrow bears", "A part that none can bear below."]],
        "refrain": ["O how I love Jesus,", "O how I love Jesus,", "O how I love Jesus,", "Because He first loved me."],
        "refrainFirst": false
      }, {
        "number": "249",
        "name": "Praise Him! Praise Him!",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["Praise Him! praise Him! Jesus, our blessed Redeemer!", "Sing, O Earth, His wonderful love proclaim!", "Hail Him! hail Him! highest archangels in glory;", "Strength and honor give to His holy Name!", "Like a shepherd, Jesus will guard His children,", "In His arms He carries them all day long:"], ["Praise Him! Praise Him! Jesus, our blessed Redeemer!", "For our sins He suffered, and bled, and died.", "He our Rock, our hope of eternal salvation,", "Hail Him! hail Him! Jesus the Crucified.", "Sound His praises! Jesus who bore our sorrows,", "Love unbounded, wonderful, deep and strong."], ["Praise Him! Praise Him! Jesus, our blessed Redeemer!", "Heavenly portals loud with hosannas ring!", "Jesus, Savior, reigneth forever and ever.", "Crown Him! Crown Him! Prophet, and Priest, and King!", "Christ is coming! over the world victorious,", "Power and glory unto the Lord belong."]],
        "refrain": ["Praise Him! Praise Him! Tell of His excellent greatness.", "Praise Him! Praise Him! Ever in joyful song!"],
        "refrainFirst": false
      }, {
        "number": "250",
        "name": "O for a Thousand Tongues to Sing",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["O for a thousand tongues to sing", "my great Redeemer's praise,", "the glories of my God and King,", "the triumphs of his grace!"], ["My gracious Master and my God,", "assist me to proclaim,", "to spread through all the earth abroad", "the honors of thy name."], ["Jesus! the name that charms our fears,", "that bids our sorrows cease;", "'tis music in the sinner's ears,", "'tis life, and health, and peace."], ["He breaks the power of canceled sin,", "he sets the prisoner free;", "his blood can make the foulest clean;", "his blood availed for me."], ["He speaks, and listening to his voice,", "new life the dead receive;", "the mournful, broken hearts rejoice,", "the humble poor believe."], ["Hear him, ye deaf; his praise, ye dumb,", "your loosened tongues employ;", "ye blind, behold your savior come,", "and leap, ye lame, for joy."]]
      }, {
        "number": "251",
        "name": "He Lives",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["I serve a risen Savior, He's in the world today;", "I know that He is living, whatever men may say;", "I see His hand of mercy, I hear His voice of cheer", "And just the time I need Him He's always near."], ["In all the world around me I see His loving care,", "And tho' my heart grows weary", "I never will despair;", "I know that He is leading thru all the stormy blast,", "The day of His appearing will come at last."], ["Rejoice, rejoice, O Christian, lift up", "your voice and sing,", "Eternal hallelujahs to Jesus Christ the King!", "The hope of all who seek Him,", "the help of all who find,", "None other is so loving, so good and kind."]],
        "refrain": ["He lives, He lives, Christ Jesus lives today!", "He walks with me and talks with me", "along life's narrow way.", "He lives, He lives, salvation to impart!", "You ask me how I know He lives?", "He lives within my heart."],
        "refrainFirst": false
      }, {
        "number": "252",
        "name": "Come, Let Us Sing",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["Come, let us sing the song of songs-", "The angels first began the strain-", "The homage which to Christ belongs:", "\"Worthy the Lamb, for He was slain!\""], ["Slain to redeem us by His blood,", "To cleanse from every sinful stain,", "And make us kings and priests to God:", "\"Worthy the Lamb, for He was slain!\""], ["To Him who suffered on the tree,", "Our souls, at His soul's price, to gain,", "Blessing, and praise, and glory be:", "\"Worthy the Lamb, for He was slain!\""], ["To Him, enthroned by filial right", "All power in heaven and earth proclaim,", "Honor, and majesty, and might:", "\"Worthy the Lamb, for He was slain!\""], ["Long as we live, and when we die,", "And while in heaven with Him we reign,", "This song our song of songs shall be:", "\"Worthy the Lamb, for He was slain!\""]]
      }, {
        "number": "253",
        "name": "There's No Other Name Like Jesus",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["There's no other name like Jesus,", "'Tis the dearest name we know,", "'Tis the angel's joy in heaven,", "'Tis the Christian's joy below."], ["'Tis the hope that I shall see Him", "When in glory He appears,", "'Tis the hope to hear His welcome", "That my fainting spirit cheers."], ["If He wills that I should labor", "In His vineyard day by day,", "Then 'tis well if only Jesus", "Blesses all I do or say."], ["If He wills that death's cold finger", "Touch my feeble, mortal clay,", "Then 'tis well if only Jesus", "Is my dying trust and stay."]],
        "refrain": ["Sweet name, (sweet name) dear name, (dear name)", "There's no other name like Jesus;", "Sweet name, (sweet name) dear name, (dear name)", "There's no other name like Jesus.", "2", "There's no other name like Jesus", "When the heart with grief is sad,", "There's no other name like Jesus", "When the heart is free and glad."],
        "refrainFirst": false
      }, {
        "number": "254",
        "name": "The Great Physician Now is Near",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["The great Physician now is near,", "The sympathizing Jesus;", "He speaks the drooping heart to cheer,", "Oh! hear the voice of Jesus."], ["Your many sins are all forgiven,", "Oh! hear the voice of Jesus;", "Go on your way in peace to heaven,", "And wear a crown with Jesus."], ["All glory to the dying Lamb!", "I now believe in Jesus;", "I love the blessed Savior's name,", "I love the name of Jesus."], ["His name dispels my guilt and fear,", "No other name but Jesus;", "Oh! how my soul delights to hear", "The charming name of Jesus."]],
        "refrain": ["Sweetest note in seraph song,", "Sweetest name on mortal tongue;", "Sweetest carol ever sung,", "Jesus, blessed Jesus."],
        "refrainFirst": false
      }, {
        "number": "255",
        "name": "I Cannot Tell Why",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["I cannot tell why He whom angels worship", "Should set His love upon the souls of men,", "Or why as Shepherd He should seek the wanderers,", "To bring them back, they know not how nor when.", "But this I know that He was born of Mary", "When Bethlehem's manger was His only home,", "And that He lived at Nazareth and labored;", "And so the Savior, Savior of the world, has come."], ["I cannot tell how silently He suffered", "As with His peace He graced this place of tears,", "Nor how His heart upon the cross was broken,", "The crown of pain to three and thirty years.", "But this I know He heals the broken hearted", "And stays our sin and calms our lurking fear,", "And lifts the burden from the heavy laden;", "For still the Savior, Savior of the world, is here."], ["I cannot tell how He will win the nations,", "How He will claim His earthly heritage,", "How satisfy the needs and aspirations", "Of east and west, of sinner and of sage.", "But this I know all flesh shall see His glory,", "And He shall reap the harvest He has sown,", "And some glad day His sun will shine in splendor", "When He the Savior, Savior of the world, is known."], ["I cannot tell how all the lands shall worship,", "When at His bidding every storm is stilled,", "Or who can say how great the jubilation", "When all our hearts with love for Him are filled.", "But this I know the skies shall sound His praises,", "Ten thousand thousand human voices sing,", "And earth to heaven, and heaven to earth will answer,", "At last the Savior, Savior of the world, is King!"]]
      }, {
        "number": "256",
        "name": "Ye Servants of God",
        "category": "Jesus Christ",
        "subcategory": "Glory and Praise",
        "verses": [["Ye servants of God, your Master proclaim,", "and publish abroad his wonderful name;", "the name all-victorious of Jesus extol,", "his kingdom is glorious and rules over all."], ["God ruleth on high, almighty to save,", "and still he is nigh, his presence we have;", "the great congregation his triumph shall sing,", "ascribing salvation to Jesus, our King."], ["\"Salvation to God, who sits on the throne!\"", "Let all cry aloud and honor the Son;", "the praises of Jesus the angels proclaim,", "fall down on their faces and worship the Lamb."], ["Then let us adore and give him his right,", "all glory and power, all wisdom and might;", "all honor and blessing with angels above,", "and thanks never ceasing and infinite love."]]
      }, {
        "number": "257",
        "name": "Come Down, O Love Divine",
        "category": "Holy Spirit",
        "verses": [["Come down, O Love divine,", "seek thou this soul of mine,", "and visit it with thine own ardor glowing;", "O Comforter, draw near,", "within my heart appear,", "and kindle it, thy holy flame bestowing."], ["O let it freely burn,", "till earthly passions turn", "to dust and ashes in its heat consuming;", "and let thy glorious light", "shine ever on my sight,", "and clothe me round, the while my path illuming."], ["Let holy charity", "Mine outward vesture be,", "and lowliness become my inner clothing;", "True lowliness of heart", "Which takes the humbler part,", "And o'er its own shortcomings weeps with loathing."], ["And so the yearning strong,", "with which the soul will long,", "shall far outpass the power of human telling;", "for none can guess its grace,", "till Love create a place", "wherein the Holy Spirit makes a dwelling."]]
      }, {
        "number": "258",
        "name": "Baptize Us Anew",
        "category": "Holy Spirit",
        "verses": [["Baptize us anew With power from on high,", "With love, O refresh us! Dear Savior, draw nigh."], ["Unworthy we cry, Unholy, unclean,", "O wash us and cleanse us From sin's guilty stain."], ["O heavenly Dove, Descend from on high!", "We plead Thy rich blessing; In mercy draw nigh."], ["O list the glad voice! From heaven it came:", "Thou art My beloved, Well pleased I am."]],
        "refrain": ["We humbly beseech Thee, Lord Jesus, we pray,", "With love and the Spirit baptize us today."],
        "refrainFirst": false,
        "last": ["We praise Thee, we bless Thee, dear Lamb that was slain", "We laud and adore Thee, Amen and Amen."]
      }, {
        "number": "259",
        "name": "Draw Us in the Spirit's Tether",
        "category": "Holy Spirit",
        "verses": [["Draw us in the Spirit's tether,", "For when humbly in Thy name,", "Two or three are met together,", "Thou art in the midst of them;", "Alleluia! Alleluia!", "Touch we now Thy garment's hem."], ["As the brethren used to gather", "In the name of Christ to sup,", "Then with thanks to God the Father", "Break the bread and bless the cup,", "Alleluia! Alleluia!", "So knit Thou our friendship up"], ["All our meals and all our living", "Make us sacraments of Thee,", "That by caring, helping, giving,", "We may true disciples be.", "Alleluia! Alleluia!", "We will serve Thee faithfully."]]
      }, {
        "number": "260",
        "name": "Hover O'er Me, Holy Spirit",
        "category": "Holy Spirit",
        "verses": [["Hover o'er me, Holy Spirit,", "Bathe my trembling heart and brow;", "Fill me with Thy hallowed presence,", "Come, O come and fill me now."], ["Thou canst fill me, gracious Spirit,", "Though I cannot tell Thee how;", "But I need Thee, greatly need Thee;", "Come, O come and fill me now."], ["I am weakness, full of weakness;", "At Thy sacred feet I bow;", "Blest, divine, eternal Spirit,", "Fill with love, and fill me now."], ["Cleanse and comfort, bless and save me;", "Bathe, O, bathe my heart and brow;", "Thou art comforting and saving,", "Thou art sweetly filling now."]],
        "refrain": ["Fill me now, fill me now,", "Jesus, come and fill me now.", "Fill me with Thy hallowed presence,", "Come, O come and fill me now."],
        "refrainFirst": false
      }, {
        "number": "261",
        "name": "The Spirit of the Lord Revealed",
        "category": "Holy Spirit",
        "verses": [["The Spirit of the Lord revealed", "His will to saints of old;", "Their heart and mind lips unsealed", "His glory to unfold.", "Amid the gloom of ancient night", "They hailed the dawning Word,", "And in the coming of the light", "Proclaimed the coming Lord."], ["The prophets passed; at length there came", "To sojourn and abide,", "The Word incarnate, to whose name", "The prophets testified;", "And He, the twilight overpast,", "Himself, the Light of light,", "As man with man, revealed at last", "The Father to our sight."], ["Eternal Spirit, who dost speak", "To mind and conscience still,", "That we in this our day, may seek", "To do our Father's will,", "To us the word of life impart,", "Of Christ, the living way;", "Give us the quiet, humble heart", "To hear and to obey."]]
      }, {
        "number": "262",
        "name": "Sweet, Sweet Spirit",
        "category": "Holy Spirit",
        "verses": [["There's a sweet, sweet spirit in this place,", "And I know that it's the spirit of the Lord;"], ["There are sweet expressions on each face", "And I know they feel the presence of the Lord."]],
        "refrain": ["Sweet Holy Spirit, Sweet Heavenly Dove,", "Stay right here with us Filling us with Your love,", "And for these blessings we lift our hearts in praise,", "Without a doubt we'll know that we have been revived", "When we shall leave this place."],
        "refrainFirst": false
      }, {
        "number": "263",
        "name": "Fire of God, Thou Sacred Flame",
        "category": "Holy Spirit",
        "verses": [["Fire of God, Thou sacred flame,", "Spirit who in splendor came,", "Let Thy heat my soul refine", "Till it glows with love divine."], ["Breath of God, that swept in power", "In the pentecostal hour,", "Holy Breath, be Thou in me", "Source of vital energy."], ["Strength of God, Thy might within", "Conquers sorrow, pain, and sin:", "Fortify from evil's art", "All the gateways of my heart."], ["Truth of God, Thy piercing rays", "Penetrate my secret ways.", "May the light that shames my sin", "Guide me holier paths to win."], ["Love of God, Thy grace profound", "Knoweth neither age nor bound:", "Come, my heart's own guest to be,", "Dwell forevermore in me."]]
      }, {
        "number": "264",
        "name": "O for That Flame of Living Fire",
        "category": "Holy Spirit",
        "verses": [["O for that flame of living fire", "Which shone so bright in saints of old;", "Which bade their souls to heaven aspire,", "Calm in distress, in danger bold!"], ["Where is that spirit, Lord, which dwelt", "In Abram's breast, and sealed Him Thine?", "Which made Paul's heart with sorrow melt,", "And glow with energy divine?"], ["That spirit which from age to age", "Proclaimed Thy love, and taught Thy ways?", "Brightened Isaiah's vivid page,", "And breathed in David's hallowed lays?"], ["Is not Thy grace as mighty now", "As when Elijah felt its power?", "When glory beamed from Moses' brow,", "Or Job endured the trying hour?"], ["Remember, Lord, the ancient days;", "Renew Thy work, Thy grace restore;", "And while to Thee our hearts we raise,", "On us Thy Holy Spirit pour."]]
      }, {
        "number": "265",
        "name": "Breathe on Me, Breath of God",
        "category": "Holy Spirit",
        "verses": [["Breathe on me, breath of God,", "Fill me with life anew,", "That I may love what Thou dost love,", "And do what Thou wouldst do."], ["Breathe on me, breath of God,", "Until my heart is pure,", "Until with Thee I will one will,", "To do and to endure."], ["Breathe on me, breath of God,", "Till I am wholly Thine,", "Until this earthly part of me", "Glows with Thy fire divine."], ["Breathe on me, breath of God,", "So shall I constant be,", "And live with Thee the perfect life", "Of Thine eternity."]]
      }, {
        "number": "266",
        "name": "Spirit of God",
        "category": "Holy Spirit",
        "verses": [["Spirit of God, descend upon my heart;", "wean it from earth; through all its pulses move;", "stoop to my weakness, mighty as thou art,", "and make me love thee as I ought to love."], ["I ask no dream, no prophet ecstasies,", "no sudden rending of the veil of clay,", "no angel visitant, no opening skies;", "but take the dimness of my soul away."], ["Has thou not bid me love thee, God and King?", "All, all thine own, soul, heart and strength and mind.", "I see thy cross; there teach my heart to cling.", "O let me seek thee, and O let me find."], ["Teach me to feel that thou art always nigh;", "teach me the struggles of the soul to bear.", "To check the rising doubt, the rebel sigh,", "teach me the patience of unanswered prayer."], ["Teach me to love thee as thine angels love,", "one holy passion filling all my frame;", "the kindling of the heaven-descended Dove,", "my heart an altar, and thy love the flame."]]
      }, {
        "number": "267",
        "name": "Spirit Divine",
        "category": "Holy Spirit",
        "verses": [["Spirit divine, attend our prayers,", "And make this house Thy home;", "Descend with all Thy gracious powers,", "O come, great Spirit, come!"], ["Come as the light; to us reveal", "Our emptiness and woe,", "And lead us in those paths of life", "Where-on the righteous go."], ["Come as the fire, and purge our hearts", "Like sacrificial flame;", "Let our whole soul an of - f'ring be", "To our Redeemer's name."], ["Come as the dove, and spread Thy wings,", "The wings of peaceful love;", "And let Thy church on earth become", "Blest as the church above."], ["Spirit divine, attend our prayers,", "Make a lost world Thy home;", "Descend with all Thy gracious powers,", "O come, great Spirit, come!"]]
      }, {
        "number": "268",
        "name": "Holy Spirit, Light Divine",
        "category": "Holy Spirit",
        "verses": [["Holy Spirit, light divine,", "Shine upon this heart of mine,", "Chase the shades of night away,", "Turn my darkness into day."], ["Holy Spirit, power divine,", "Cleanse this guilty heart of mine;", "Long has sin, without control,", "Held dominion o'er my soul."], ["Holy Ghost, with joy divine,", "Cheer this saddened heart of mine,", "Bid my many woes depart,", "Heal my wounded, bleeding heart."], ["Holy Spirit, all divine,", "Dwell within this heart of mine,", "Cast down every idol throne,", "Reign supreme, and reign alone."]]
      }, {
        "number": "269",
        "name": "Come, Holy Spirit",
        "category": "Holy Spirit",
        "verses": [["Come, Holy Spirit, heavenly Dove,", "With all Thy quickening powers;", "Kindle a flame of sacred love", "In these cold hearts of ours."], ["O raise our thoughts from things below,", "From vanities and toys!", "Then shall we with fresh courage go", "To reach eternal joys."], ["Awake our souls to joyful songs;", "Let pure devotions rise;", "Till praise employs our thankful tongues,", "And doubt forever dies."], ["Father, we would no longer live", "At this poor, dying rate;", "To Thee our thankful love we give,", "For Thine to us is great."], ["Come, Holy Spirit, heavenly Dove,", "With all Thy quickening powers;", "Come, shed abroad a Savior's love,", "And that shall kindle ours."]]
      }, {
        "number": "270",
        "name": "O Holy Dove of God Descending",
        "category": "Holy Spirit",
        "verses": [["O holy Dove of God descending,", "You are the love that knows no ending.", "All of our shattered dreams You're mending:", "Spirit, now live in me."], ["O holy Wind of God now blowing,", "You are the seed that God us sowing.", "You are the life that starts us growing:", "Spirit, now live in me."], ["O holy Rain of God now falling,", "You make the Word of God enthralling,", "You are that inner voice now calling:", "Spirit, now live in me."], ["O holy Flame of God now burning,", "You are the power of Christ returning.", "You are the answer to our yearning:", "Spirit, now live in me."]]
      }, {
        "number": "271",
        "name": "Break Thou the Bread of Life",
        "category": "Holy Scriptures",
        "verses": [["Break thou the bread of life, dear Lord, to me,", "as thou didst break the loaves beside the sea;", "beyond the sacred page I seek thee, Lord;", "my spirit pants for thee, O Living Word!"], ["Bless thou the truth, dear Lord, to me, to me,", "as thou didst bless the bread by Galilee;", "then shall all bondage cease, all fetters fall;", "and I shall find my peace, my all in all."], ["Thou art the bread of life, O Lord, to me;", "Thy holy Word the truth That saveth me;", "Give me to eat and live With Thee above;", "Teach me to love Thy truth, For Thou art love."], ["Spirit and life are they, Words Thou dost speak;", "I hasten to obey, But I am weak;", "Thou art my only help, Thou art my life;", "Heeding Thy holy Word I win the strife."]]
      }, {
        "number": "272",
        "name": "Give Me the Bible",
        "category": "Holy Scriptures",
        "verses": [["Give me the Bible, star of gladness gleaming,", "To cheer the wanderer lone and tempest tossed,", "No storm can hide that peaceful radiance beaming", "Since Jesus came to seek and save the lost."], ["Give me the Bible when my heart is broken,", "When sin and grief have filled my soul with fear,", "Give me the precious words by Jesus spoken,", "Hold up faith's lamp to show my Savior near."], ["Give me the Bible, all my steps enlighten,", "Teach me the danger of these realms below,", "That lamp of safety, o'er the gloom shall brighten,", "That light alone the path of peace can show."]],
        "refrain": ["Give me the Bible-holy message shining,", "Thy light shall guide me in the narrow way.", "Precept and promise, law and love combining,", "'Til night shall vanish in eternal day."],
        "refrainFirst": false
      }, {
        "number": "273",
        "name": "Lord, I Have Made Thy Word My Choice",
        "category": "Holy Scriptures",
        "verses": [["Lord, I have made Thy word my choice,", "My lasting heritage;", "There shall my noblest powers rejoice,", "My warmest thoughts engage."], ["I'll read the histories of Thy love,", "And keep Thy laws in sight;", "While through Thy promises I rove,", "With ever fresh delight."], ["In this broad land of wealth unknown,", "Where springs of life arise,", "Seeds of immortal bliss are sown,", "And hidden glory lies."], ["The best relief that mourners have,", "It makes our sorrows blest;", "Our fairest hope beyond the grave,", "And our eternal rest."]]
      }, {
        "number": "274",
        "name": "O Word of God Incarnate",
        "category": "Holy Scriptures",
        "verses": [["O Word of God incarnate,", "O Wisdom from on high,", "O Truth unchanged, unchanging,", "O Light of our dark sky:", "we praise you for the radiance", "that from the hallowed page,", "a lantern to our footsteps,", "shines on from age to age."], ["The church from her dear Master", "received the gift divine,", "and still that light is lifted", "o'er all the earth to shine.", "It is the sacred vessel", "where gems of truth are stored;", "it is the heaven-drawn picture", "of Christ, the living Word."], ["It floateth like a banner", "before God's host unfurled;", "it is a shining beacon", "above the darkling world.", "It is the chart and compass", "that o'er life's surging tide,", "mid mists and rocks and quicksands,", "Still guides, O Christ, to Thee."], ["O make Thy church, dear Savior,", "a lamp of purest gold,", "to bear before the nations", "your true light as of old.", "O teach your wandering pilgrims", "by this their path to trace,", "till, clouds and darkness ended,", "they see Thee face to face."]]
      }, {
        "number": "275",
        "name": "O God of Light",
        "category": "Holy Scriptures",
        "verses": [["O God of light, Your Word, a lamp unfailing,", "Shall pierce the darkness of our earthbound way", "And show your grace, Your plan for us unveiling,", "And guide our footsteps to the perfect day."], ["From days of old,through blind and willful ages,", "Though we rebelled, You gently sought again,", "And spoke through saints, apostles, prophets, sages,", "Who wrote with eager or reluctant pen."], ["Undimmed by time,those words are still revealing", "To sinful hearts Your justice and Your grace;", "And questing mortals longing for Your healing,", "See Your compassion in the Savior's face."], ["To all the world Your summons You are sending,", "Through all the earth, to every land and race,", "That myriad tongues, in one great anthem blending,", "May praise and celebrate Your gift of grace."]]
      }, {
        "number": "276",
        "name": "Thanks to God",
        "category": "Holy Scriptures",
        "verses": [["Thanks to God whose Word was spoken", "In the deed that made the earth.", "His the voice that called a nation;", "His the fires that tried her worth.", "God has spoken;", "Praise Him for His open Word."], ["Thanks to God whose Word incarnate", "Glorified the flesh of man.", "Deeds and words and death and rising", "Tell the grace in heaven's plan.", "God has spoken;", "Praise Him for His open Word."], ["Thanks to God whose Word is answered", "By the Spirit's voice wihin.", "Here we drink of joy unmeasured,", "Life redeemed from death and sin.", "God is speaking;", "Praise Him for His open Word."]]
      }, {
        "number": "277",
        "name": "For Your Holy Book We Thank You",
        "category": "Holy Scriptures",
        "verses": [["For Your holy book we thank You,", "And for all who served You well,", "Writing, guarding, and translating,", "That its pages might forth tell", "Your strong love and tender care", "For Your people everywhere."], ["For Your holy book we thank You,", "And for those who work today,", "That the people of all nations,", "Reading it and following may", "Know Your love and tender care", "For Your people everywhere."], ["For Your holy book we thank You,", "May its message be our guide,", "May we understand the wisdom", "Of the laws it will provide:", "And Your love and tender care", "For Your people everywhere."], ["For Your holy book we thank You,", "May its message in our hearts", "Lead us now to see in Jesus", "All the grace Your word imparts:", "All Your love and tender care", "For Your people everywhere."]]
      }, {
        "number": "278",
        "name": "Lord Jesus, Once You Spoke to Men",
        "category": "Holy Scriptures",
        "verses": [["Lord Jesus, once You spoke to men", "Upon the mountain and the plain,", "O help us listen now as then", "And wonder at Your words again."], ["We all have secret fears to face,", "Our minds and motives to amend.", "We seek Your truth, we need Your grace,", "Our living Lord and present friend."], ["The gospel speaks; and we receive", "Your light, Your love, Your own command.", "O help us live what we believe,", "In daily word of heart and hand."]]
      }, {
        "number": "279",
        "name": "Only Trust Him",
        "category": "Gospel",
        "subcategory": "Invitation",
        "verses": [["Come, every soul by sin oppressed,", "There's mercy with the Lord,", "And He will surely give you rest,", "By trusting in His word."], ["For Jesus shed his precious blood", "Rich blessings to bestow;", "Plunge now into the crimson flood", "That washes white as snow."], ["Yes, Jesus is the truth, the way,", "That leads you into rest;", "Believe in Him without delay,", "And you are fully blest."], ["Come, then, and join this holy band,", "And on to glory go,", "To dwell in that celestial land,", "Where joys immortal flow."]],
        "refrain": ["Only trust Him, only trust Him,", "Only trust Him now;", "He will save you, He will save you,", "He will save you now."],
        "refrainFirst": false
      }, {
        "number": "280",
        "name": "Come, Ye Sinners",
        "category": "Gospel",
        "subcategory": "Invitation",
        "verses": [["Come, ye sinners poor and needy,", "Weak and wounded, sick and sore;", "Jesus ready stands to save you,", "Full of pity, love, and power."], ["Come. ye thirsty, come, and welcome,", "God's free bounty glorify;", "True belief and true repentance,", "Every grace that brings you nigh."], ["Let not conscience make you linger,", "Nor of fitness fondly dream;", "All the fitness He requireth", "Is to feel your need of Him."], ["Lo! th'incarnate God, ascended,", "Pleads the merit of His blood;", "Venture on Him, venture wholly,", "Let no other trust intrude."]],
        "refrain": ["I will arise and go to Jesus,", "He will embrace me in His arms;", "In the arms of my dear Savior,", "O there are ten thousand charms."],
        "refrainFirst": false
      }, {
        "number": "281",
        "name": "I Gave My Life for Thee",
        "category": "Gospel",
        "subcategory": "Invitation",
        "verses": [["I gave my life for thee,", "My precious blood I shed,", "That thou might'st ransom be,", "And quickened from the dead;", "I gave, I gave My life for thee,", "What hast thou given for Me?", "I gave, I gave My life for thee,", "What hast thou given for Me?"], ["My Fathers house of light,", "My glory circled throne,", "I left for earthly night,", "For wanderings sad and lone;", "I left, I left it all for thee,", "Hast thou left aught for Me?", "I left, I left it all for thee,", "Hast thou left aught for Me?"], ["I suffered much for thee,", "more than thy tongue can tell,", "Of bitterest agony,", "To rescue thee from hell;", "I've borne, I've borne it all for thee,", "What hast thou borne for Me?", "I've borne, I've borne it all for thee,", "What hast thou borne for Me?"]]
      }, {
        "number": "282",
        "name": "I Hear Thy Welcome Voice",
        "category": "Gospel",
        "subcategory": "Invitation",
        "verses": [["I hear Thy welcome voice,", "That calls me, Lord, to Thee;", "For cleansing in Thy precious blood,", "That flowed on Calvary."], ["Though coming weak and vile,", "Thou dost my strength assure;", "Thou dost my vileness fully cleanse,", "Till spotless all, and pure."], ["'Tis Jesus calls me on", "To perfect faith and love,", "To perfect hope, and peace, and trust,", "For earth and heaven above."], ["All hail, atoning blood!", "All hail, redeeming grace!", "All hail! the gift of Christ, our Lord,", "Our Strength and Righteousness."]],
        "refrain": ["I am coming, Lord!", "Coming now to Thee!", "Wash me, cleanse me, in the blood", "That flowed on Calvary."],
        "refrainFirst": false
      }, {
        "number": "283",
        "name": "O Jesus, Thou Art Standing",
        "category": "Gospel",
        "subcategory": "Invitation",
        "verses": [["O Jesus, thou art standing,", "outside the fast closed door,", "in lowly patience waiting", "to pass the threshold o'er:", "O shame, professing Christians,", "his Name and sign who bear,", "O shame, thrice shame upon us,", "to keep him standing there!"], ["O Jesus, thou art knocking;", "and lo, that hand is scarred,", "and thorns thy brow encircle,", "and tears thy face have marred:", "O love that passeth knowledge,", "so patiently to wait!", "O sin that hath no equal,", "so fast to bar the gate!"], ["O Jesus, thou art pleading", "in accents meek and low,", "\"I died for you, my children,", "and will you treat me so?\"", "O Lord, with shame and sorrow", "we open now the door;", "dear Savior, enter, enter,", "and leave us never more."]]
      }, {
        "number": "284",
        "name": "For You I Am Praying",
        "category": "Gospel",
        "subcategory": "Invitation",
        "verses": [["I have a Savior, He's pleading in glory,", "A dear, loving Savior, though earth", "friends be few;", "And now He is watching in tenderness", "o'er me,", "But oh that my Savior were your", "Savior, too."], ["I have a Father; to me He has given", "A hope for eternity, blessed and true;", "And soon He will call to meet Him in", "heaven,", "But oh that He'd let me bring you", "with me, too!"], ["I have a robe; 'tis resplendent in", "whiteness,", "Awaiting in glory my wondering", "view;", "Oh, when I receive it, all shining in", "brightness,", "Dear friend, could I see you receiv-", "ing one, too!"], ["When Jesus has found you, tell others", "the story,", "That my loving Savior is your Sav-", "ior, too;", "Then pray that your Savior will bring", "them to glory,", "And prayer will  be answered-'twas", "answered for you!"]],
        "refrain": ["For you I am praying, For you I am", "Praying,", "For you am I praying, I'm praying for you."],
        "refrainFirst": false
      }, {
        "number": "285",
        "name": "Jesus Calls Us",
        "category": "Gospel",
        "subcategory": "Invitation",
        "verses": [["Jesus calls us o'er the tumult", "of our life's wild, restless sea;", "day by day his sweet voice soundeth,", "saying, \"Christian, follow me!\""], ["Jesus calls us from the worship", "of the vain world's golden store,", "from each idol that would keep us,", "saying, \"Christian, love me more!\""], ["In our joys and in our sorrows,", "days of toil and hours of ease,", "still he calls, in cares and pleasures,", "\"Christian, love me more than these!\""], ["Jesus calls us! By thy mercies,", "Savior, may we hear thy call,", "give our hearts to thine obedience,", "serve and love thee best of all."]]
      }, {
        "number": "286",
        "name": "Wonderful Words of Life",
        "category": "Gospel",
        "subcategory": "Invitation",
        "verses": [["Sing them over again to me,", "wonderful words of life;", "let me more of their beauty see,", "wonderful words of life;", "words of life and beauty", "teach me faith and duty."], ["Christ, the blessed one, gives to all", "wonderful words of life;", "sinner, list to the loving call,", "wonderful words of life;", "all so freely given,", "wooing us to heaven."], ["Sweetly echo the gospel call,", "wonderful words of life;", "offer pardon and peace to all,", "wonderful words of life;", "Jesus, only Savior,", "sanctify forever."]],
        "refrain": ["Beautiful words, wonderful words,", "wonderful words of life.", "Beautiful words, wonderful words,", "wonderful words of life."],
        "refrainFirst": false
      }, {
        "number": "287",
        "name": "Softly and Tenderly",
        "category": "Gospel",
        "subcategory": "Invitation",
        "verses": [["Softly and tenderly Jesus is calling,", "calling for you and for me;", "see, on the portals he's waiting and watching,", "watching for you and for me."], ["Why should we tary when Jesus is pleading,", "pleading for you and for me?", "Why should we linger and heed not his mercies,", "mercies for you and for me?"], ["Think of the wonderful love he has promised,", "promised for you and for me!", "Though we have sinned, he has mercy and pardon,", "pardon for you and for me."]],
        "refrain": ["Come home, come home;", "ye who are weary come home;", "earnestly, tenderly, Jesus is calling,", "calling, O sinner, come home!"],
        "refrainFirst": false
      }, {
        "number": "288",
        "name": "I Am Going to Calvary",
        "category": "Gospel",
        "subcategory": "Invitation",
        "verses": [["I am going to Calvary.", "Would you like to come with Me", "All the way and back again?", "You must follow the Leader then.", "You must follow the Leader,", "You must follow the Leader,", "All the way and back again,", "You must follow the Leader."], ["If I wear a thorny crown,", "If the soldiers knock Me down", "Can I really be a king?", "Love will answer everything", "If you follow the Leader,", "If you follow the Leader,", "Love will answer everything", "If you follow the Leader."], ["When I go along the road,", "I shall lift a heavy load.", "I will carry a cross for you.", "You will learn to carry it too", "When you follow the Leader,", "When you follow the Leader,", "You will learn to carry it too", "If you follow the Leader."], ["I am going to stretch My hands,", "Reaching out to all the lands.", "Can I really be a king?", "Love's the Lord of everything,", "When you follow the Leader,", "When you follow the Leader,", "Love's the Lord of everything", "When you follow the Leader."]]
      }, {
        "number": "289",
        "name": "The Savior Is Waiting",
        "category": "Gospel",
        "subcategory": "Invitation",
        "verses": [["The Savior is waiting to enter your heart", "Why don't you let Him come in?", "There's nothing in this world to keep you apart", "What is your answer to Him?"], ["If you'll take one step", "Toward the Savior, my friend,", "You'll find His arms open wide.", "Receive Him, and all of your darkness will end", "Within your heart He'll abide."]],
        "refrain": ["Time after time", "He has waited before", "And now He is waiting again", "To see if you are willing", "To open the door", "Oh, how He wants to come in."],
        "refrainFirst": false
      }, {
        "number": "290",
        "name": "Turn Your Eyes Upon Jesus",
        "category": "Gospel",
        "subcategory": "Invitation",
        "verses": [["O soul, are you weary and troubled?", "No light in the darkness you see?", "There's light for a look at the Savior,", "And life more abundant and free!"], ["Through death into life everlasting", "He passed, and we follow Him there;", "Over us sin no more hath dominion-", "For more than conquerors we are!"], ["His Word shall not fail you-He promised;", "Believe Him, and all will be well:", "Then go to a world that is dying,", "His perfect salvation to tell!"]],
        "refrain": ["Turn your eyes upon Jesus,", "Look full in His wonderful face,", "And the things of earth will grow strangely dim,", "In the light of His glory and grace."],
        "refrainFirst": false
      }, {
        "number": "291",
        "name": "We Have Not Known Thee",
        "category": "Gospel",
        "subcategory": "Repentance",
        "verses": [["We have not known Thee as we ought,", "Nor learned Thy wisdom, grace, and power;", "The things of earth have filed our thoughts,", "And trifles of the passing hour.", "Lord, give us light Thy truth to see,", "And make us wise in knowing Thee."], ["We have not feared Thee as we ought,", "Nor bowed beneath Thine awful eye,", "Nor guarded deed, and word, and thought,", "Remembering that God was nigh.", "Lord, give us faith to know Thee near,", "And grant the grace of holy fear."], ["We have not loved Thee as we ought,", "Nor cared that we are loved by Thee;", "Thy presence we have coldly sought,", "And feebly longed Thy face to see.", "Lord, give a pure and loving heart", "To feel and own the love Thou art."], ["We have not served Thee as we ought;", "Alas! the duties left undone,", "The work with little fervor wrought,", "The battles lost, or scarcely won!", "Lord, give the zeal, and give the might,", "For Thee to toil, for Thee to fight."], ["When shall we know Thee as we ought,", "And fear, and love, and serve aright!", "When shall we, out of trial brought,", "Be perfect in the land of light!", "Lord, may we day by day prepare", "To see Thy face, and serve Thee there."]]
      }, {
        "number": "292",
        "name": "Jesus, I Come",
        "category": "Gospel",
        "subcategory": "Repentance",
        "verses": [["Out of my bondage, sorrow and night,", "Jesus, I come, Jesus, I come;", "Into Thy freedom, gladness and light,", "Jesus, I come to Thee;", "Out of my sickness into Thy health,", "Out of my want and into Thy wealth,", "Out of my sin and into Thyself,", "Jesus, I come to Thee."], ["Out of my shameful failure and loss,", "Jesus, I come, Jesus, I come;", "Into the glorious gain of Thy cross,", "Jesus, I come to Thee;", "Out of earth's sorrows into Thy balm,", "Out of life's storms and into Thy calm,", "Out of distress to jubilant psalm,", "Jesus, I come to Thee."], ["Out of unrest and arrogant pride,", "Jesus, I come, Jesus, I come;", "Into Thy blessed will to abide,", "Jesus, I come to Thee;", "Out of myself to dwell in Thy love,", "Out of despair into raptures above,", "Upward for aye on wings like a dove,", "Jesus, I come to Thee."], ["Out of the fear and dread of the tomb,", "Jesus, I come, Jesus, I come;", "Into the joy and light of Thy home,", "Jesus, I come to Thee;", "Out of the depths of ruin untold,", "Into the peace of Thy sheltering fold,", "Ever Thy glorious face to behold,", "Jesus, I come to Thee."]]
      }, {
        "number": "293",
        "name": "Heavenly Father, Bless Us Now",
        "category": "Gospel",
        "subcategory": "Repentance",
        "verses": [["Heavenly Father, bless us now;", "At the cross of Christ we bow;", "Take our guilt and grief away;", "Hear and heal us now, we pray."], ["Now, O Lord, this very hour,", "Send Thy grace and show Thy power;", "While we rest upon Thy word,", "Come, and bless us now, O Lord!"], ["Mercy now, O Lord, we plead", "In this hour of utter need;", "Turn us not away unblest;", "Calm our anguish into rest."], ["O Thou loving, blessed One,", "Rising oér us like the sun,", "Light and life art Thou within:", "Savior, Thou, from every sin!"]]
      }, {
        "number": "294",
        "name": "Power in the Blood",
        "category": "Gospel",
        "subcategory": "Repentance",
        "verses": [["Would you be free", "from the burden of sin?", "There's pow'r in the blood,", "pow'r in the blood;", "Would you o'er evil", "a victory win?", "There's wonderful", "power in the blood."], ["Would be free from", "your passion and pride?", "There's pow'r in the blood,", "pow'r in the blood;", "Come for a cleansing", "to Calvary's tide?", "There's wonderful", "power in the blood."], ["Would you do service", "for Jesus your King?", "There's pow'r in the blood,", "pow'r in the blood;", "Would you live daily", "His praises to sing?", "There's wonderful", "power in the blood."]],
        "refrain": ["There is pow'r, pow'r,", "wonder working pow'r", "In the blood", "of the Lamb;", "There is pow'r, pow'r,", "wonder working pow'r", "In the precious", "blood of the Lamb."],
        "refrainFirst": false
      }, {
        "number": "295",
        "name": "Chief of Sinners",
        "category": "Gospel",
        "subcategory": "Repentance",
        "verses": [["Chief of sinners though I be,", "Jesus shed His blood for me;", "Died that I might live on high,", "Died that I might never die;", "As the brance is to the vine,", "I am His, and He is mine."], ["O the height of Jesus' love!", "Higher than the heaven above,", "Deeper than the deepest sea,", "Lasting as eternity;", "Love that found me-wondrous thought!", "Found me when I sought Him not!"], ["Chief of sinners though I be,", "Christ is all in all to me;", "All my wants to Him are known,", "All my sorrows are His own;", "Safe with Him from earthly strife,", "He sustains the hidden life."]]
      }, {
        "number": "296",
        "name": "Lord, I'm Comming Home",
        "category": "Gospel",
        "subcategory": "Repentance",
        "verses": [["I've wandered far away from God,", "Now I'm coming home;", "The paths of sin too long I've trod;", "Lord, I'm coming home."], ["I've wasted many precious years,", "Now I'm coming home;", "I now repent with bitter tears;", "Lord, I'm coming home."], ["I'm tired of sin and straying Lord,", "Now I'm coming home;", "I'll trust thy love belive thy word;", "Lord I'm coming home."], ["My only hope, my only plea,", "Now I'm coming home;", "That Jesus died, and died for me;", "Lord I'm coming home."], ["I need His cleansing blood I know,", "Now I'm coming home;", "O wash me whiter than the snow;", "Lord I'm coming home."]],
        "refrain": ["Coming home, coming home", "Never more to roam;", "Open wide Thine arms of love;", "Lord I'm coming home."],
        "refrainFirst": false
      }, {
        "number": "297",
        "name": "God Be Merciful to Me",
        "category": "Gospel",
        "subcategory": "Forgiveness",
        "verses": [["God, be merciful to me,", "On Thy grace I rest my plea;", "Plenteous in compassion Thou,", "Blot out my transgressions now;", "Wash me, make me pure within,", "Cleanse, O cleanse me from my sin,", "Wash me, make me pure within,", "Cleanse, O cleanse me from my sin."], ["I am evil, born in sin;", "Thou desirest truth within.", "Thou alone my Savior art,", "Teach Thy wisdom to my heart;", "Make me pure, Thy grace bestow,", "Wash me whiter than the snow,", "Make me pure, Thy grace bestow,", "Wash me whiter than the snow."], ["Gracious God, my heart renew,", "Make my spirit right and true;", "Cast me not away from Thee,", "Let Thy Spirit dwell in me;", "Thy salvation's joy impart,", "Steadfast make my willing heart,", "Thy salvation's joy impart,", "Steadfast make my willing heart."], ["Sinners then shall learn from me", "And return, O God, to Thee;", "Savior, all my guilt remove,", "And my tongue shall sing Thy love;", "Touch my silent lips, O Lord,", "And my mouth shall praise accord,", "Touch my silent lips, O Lord,", "And my mouth shall praise accord."]]
      }, {
        "number": "298",
        "name": "I Lay My Sins on Jesus",
        "category": "Gospel",
        "subcategory": "Forgiveness",
        "verses": [["I lay my sins on Jesus,", "The spotless Lamb of God;", "He bears them all, and frees us", "From the accursed load,", "From the accursed load."], ["I bring my guilt to Jesus,", "To wash my crimson stains", "White in His blood most precious,", "Till not a stain remains,", "Till not a stain remains."], ["I lay my wants on Jesus,", "All fullness dwells in Him;", "He healeth my diseases,", "He doth my soul redeem,", "He doth my soul redeem."], ["I long to be like Jesus,", "Meek loving, lowly, mild;", "I long to be like Jesus,", "The Father's holy child,", "The Father's holy child."], ["I long to be with Jesus", "Amid the heav'nly throng,", "To sing with them His praises,", "And learn the angels'song,", "And learn the angels'song."]]
      }, {
        "number": "299",
        "name": "Forgive Our Sins as We Forgive",
        "category": "Gospel",
        "subcategory": "Forgiveness",
        "verses": [["\"Forgive our sins as we forgive,\"", "You taught us, Lord, to pray;", "But You alone can grant us grace", "To live the words we say."], ["How can Your pardon reach and bless", "The unforgiving heart", "That broods on wrongs and will not let", "Old bitterness depart?"], ["In blazing light Your cross reveals", "The truth we dimly knew:", "How trifling others' debts to us;", "How great our debt to You!"], ["Lord, cleanse the depths within our souls", "And bid resentment cease;", "Then, by Your mercy reconciled,", "Our lives will spread Your peace."]]
      }, {
        "number": "300",
        "name": "Rock of Ages",
        "category": "Gospel",
        "subcategory": "Forgiveness",
        "verses": [["Rock of Ages, cleft for me,", "let me hide myself in Thee;", "let the water and the blood,", "from thy riven side which flowed,", "be of sin the double cure;", "cleanse me from its guilt and power."], ["Not the labors of my hands", "can fulfill thy law's demands;", "could my zeal no respite know,", "could my tears forever flow,", "all for sin could not atone;", "thou must save, and thou alone."], ["When my pilgrimage I close,", "Victor o'er the last of foes,", "When I soar to worlds unknown,", "and behold Thee on Thy throne,", "Rock of Ages, cleft for me,", "let me hide myself in Thee."]]
      }, {
        "number": "301",
        "name": "Near, Still Nearer",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["Nearer, still nearer, close to Thy heart,", "Draw me, my Savior, so precious Thou art;", "Fold me, O fold me close to Thy breast,", "Shelter me safe in that haven of rest,", "Shelter me safe in that haven of rest."], ["Nearer, still nearer, nothing I bring,", "Nought as an offering to Jesus my King-", "Only my sinful now contrite heart,", "Grant me the cleansing Thy blood doth impart,", "Grant me the cleansing Thy blood doth impart."], ["Nearer, still nearer, Lord, to be Thine;", "Sin, with its follies, I gladly resign,", "All of its pleasures, pomp and its pride;", "Give me but Jesus, my Lord crucified,", "Give me but Jesus, my Lord crucified."], ["Nearer, still nearer, while life shall last;", "Till safe in glory my anchor is cast;", "Through endless ages, ever to be,", "Nearer, my Savior, still nearer to Thee,", "Nearer, my Savior, still nearer to Thee."]]
      }, {
        "number": "302",
        "name": "Deeper Yet",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["In the blood from the cross,", "I have been washed from sin;", "But to be free from dross,", "Still I would enter in."], ["Day by day, hour by hour,", "Blessings are sent to me;", "But for more of His power,", "Ever my prayer shall be."], ["Near to Christ I would live,", "Following Him each day;", "What I ask He will give;", "So then with faith I pray."], ["Now I have peace, sweet peace,", "While in this world of sin;", "But to pray I'll not cease", "Till I am pure within."]],
        "refrain": ["Deeper yet, deeper yet,", "Into the crimson flood;", "Deeper yet, deeper yet,", "Under the precious blood."],
        "refrainFirst": false
      }, {
        "number": "303",
        "name": "Beneath the Cross of Jesus",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["Beneath the cross of Jesus", "I fain would take my stand,", "the shadow of a mighty rock", "within a weary land;", "a home within the wilderness,", "a rest upon the way,", "from the burning of the noontide heat,", "and the burden of the day."], ["Upon that cross of Jesus", "mine eye at times can see", "the very dying form of One", "who suffered there for me;", "and from my stricken heart with tears", "two wonders I confess:", "the wonders of redeeming love", "and my unworthiness."], ["I take, O cross, thy shadow", "for my abiding place;", "I ask no other sunshine than", "the sunshine of his face;", "content to let the world go by,", "to know no gain nor loss,", "my sinful self my only shame,", "my glory all the cross."]]
      }, {
        "number": "304",
        "name": "Faith of Our Fathers",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["Faith of our fathers, living still,", "In spite of dungeon, fire and sword;", "O how our hearts beat high with joy", "Whenever we hear that glorious Word!", "Faith of our fathers, holy faith!", "We will be true to thee till death."], ["Our fathers, chained in prisons dark,", "Were still in heart and conscience free:", "How sweet would be their children's fate.", "If they, like them, could die for thee!", "Faith of our fathers, holy faith!", "We will be true to thee till death."], ["Faith of our fathers, we will love", "Both friend and foe in all our strife;", "And preach Thee, too, as love knows how", "By kindly words and virtuous life.", "Faith of our fathers, holy faith!", "We will be true to thee till death."]]
      }, {
        "number": "305",
        "name": "Give Me Jesus",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["In the morning, when I rise", "In the morning, when I rise", "In the morning, when I rise"], ["Dark mid-night was my cry,", "Dark mid-night was my cry,", "Dark midnight was my cry,", "Give me Jesus."], ["Just about the break of day,", "Just about the break of day,", "Just about the break of day,", "Give me Jesus."], ["Oh, when I come to die,", "Oh, when I come to die,", "Oh, when I come to die,", "Give me Jesus."]],
        "refrain": ["Give me Jesus.", "Give me Jesus,", "Give me Jesus.", "You can have all this world,", "Just give me Jesus."],
        "refrainFirst": false
      }, {
        "number": "306",
        "name": "Draw Me Nearer",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["I am thine, O Lord, I have heard thy voice,", "and it told thy love to me;", "but I long to rise in the arms of faith", "and be closer drawn to thee."], ["Consecrate me now to thy service, Lord,", "by the power of grace divine;", "let my soul look up with a steadfast hope,", "and my will be lost in thine."], ["O the pure delight of a single hour", "that before thy throne I spend,", "when I kneel in prayer, and with thee, my God,", "I commune as friend with friend!"]],
        "refrain": ["Draw me nearer, nearer, blessed Lord,", "to the cross where thou hast died.", "Draw me nearer, nearer, nearer, blessed Lord,", "to thy precious, bleeding side."],
        "refrainFirst": false
      }, {
        "number": "307",
        "name": "I Am Coming to the Cross",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["I am coming to the cross;", "I am poor and weak and blind;", "I am counting all but dross;", "I shall full salvation find."], ["Long my heart has sighed for Thee;", "Long has evil reigned within;", "Jesus sweetly speaks to me:", "\"I will cleanse you from all sin.\""], ["Here I give my all to Thee:", "Friends and time and earthly store;", "Soul and body Thine to be,", "Wholly Thine forevermore."], ["Jesus comes! He fills my soul!", "Perfected in Him I am;", "I am every whit made whole:", "Glory, glory to the Lamb!"]],
        "refrain": ["I am trusting, Lord, in Thee.", "O thou Lamb of Calvary;", "Humbly at Thy cross I bow.", "Save me, Jesus, save me now."],
        "refrainFirst": false
      }, {
        "number": "308",
        "name": "Wholly Thine",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["I would be, dear Savior, wholly Thine;", "Teach me how, teach me how;", "I would do Thy will, O Lord, not mine;", "Help me, help me now."], ["What is worldly pleasure, wealth or fame,", "Without Thee, without Thee?", "I will leave them all for Thy dear Name,", "This my wealth shall be."], ["As I cast earth's transient joys behind,", "Come Thou near, come Thou near;", "In Thy presence all in all I find,", "'Tis my comfort here."]],
        "refrain": ["Wholly Thine, wholly Thine,", "Wholly Thine, this is my vow;", "Wholly Thine, wholly Thine,", "Wholly Thine, O Lord, just now."],
        "refrainFirst": false
      }, {
        "number": "309",
        "name": "I Surrender All",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["All to Jesus I surrender;", "all to him I freely give;", "I will ever love and trust him,", "in his presence daily live."], ["All to Jesus I surrender;", "humbly at his feet I bow,", "worldly pleasures all forsaken;", "take me, Jesus, take me now."], ["All to Jesus I surrender;", "make me, Savior, wholly thine;", "fill me with thy love and power;", "truly know that thou art mine."], ["All to Jesus I surrender;", "now I feel the sacred flame.", "O the joy of full salvation!", "Glory, glory, to his name!"]],
        "refrain": ["I surrender all, I surrender all,", "all to thee, my blessed Savior,", "I surrender all."],
        "refrainFirst": false
      }, {
        "number": "310",
        "name": "I Would Draw Nearer to Jesus",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["I would draw nearer to Jesus,", "In His sweet presence abide,", "Constantly trying to serve Him,", "Safe and secure at His side."], ["I would draw nearer to Jesus,", "Nothing withholding from Him,", "Knowing He loves to be gracious,", "I would draw nearer to Him."], ["I would draw nearer to Jesus,", "Seeking Hi strength to be true,", "Willing to tell of His goodness,", "Gladly His blest will to do."]],
        "refrain": ["I would draw nearer to Jesus,", "I would draw nearer to Him;", "Fully surrendered each moment,", "I would draw nearer to Him."],
        "refrainFirst": false
      }, {
        "number": "311",
        "name": "I Would Be Like Jesus",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["Earthly pleasures vainly call me;", "I would be like Jesus;", "Nothing worldly shall enthrall me;", "I would be like Jesus."], ["He has broken every fetter,", "I would be like Jesus;", "That my soul may serve Him better,", "I would be like Jesus."], ["All the way from earth to glory,", "I would be like Jesus;", "Telling o'er and o'er the story,", "I would be like Jesus."], ["That in Heaven He may meet me,", "I would be like Jesus;", "That His words \"Well done\" may greet me,", "I would be like Jesus."]],
        "refrain": ["Be like Jesus, this my song,", "In the home and in the throng;", "Be like Jesus, all day long!", "I would be like Jesus."],
        "refrainFirst": false
      }, {
        "number": "312",
        "name": "Near the Cross",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["Jesus, keep me near the cross;", "there a precious fountain,", "free to all, a healing stream,", "flows from Calvary's mountain."], ["Near the cross, a trembling soul,", "love and mercy found me;", "there the bright and morning star", "sheds its beams around me."], ["Near the cross! O Lamb of God,", "bring its scenes before me;", "help me walk from day to day", "with its shadow o'er me."], ["Near the cross I'll watch and wait,", "hoping, trusting ever,", "till I reach the golden strand", "just beyond the river."]],
        "refrain": ["In the cross, in the cross,", "be my glory ever,", "till my raptured soul shall find", "rest beyond the river."],
        "refrainFirst": false
      }, {
        "number": "313",
        "name": "Just as I Am",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["Just as I am, without one plea,", "But that Thy blood was shed for me,", "And that Thou bidst me come to Thee,", "O Lamb of God, I come, I come."], ["Just as I am, and waiting not", "To rid my soul of one dark blot,", "To Thee whose blood can cleanse each spot,", "O Lamb of God, I come, I come."], ["Just as I am, though tossed about", "With many a conflict, many a doubt,", "\"Fightings within, and fears without,\"", "O Lamb of God, I come, I come."], ["Just as I am, poor, wretched, blind;", "Sight, riches, healing of the mind,", "Yea, all I need in Thee to find,", "O Lamb of God, I come, I come."], ["Just as I am, Thou wilt receive,", "Wilt welcome, pardon, cleanse, relieve;", "Because Thy promise I believe,", "O Lamb of God, I come, I come."], ["Just as I am, Thy love I own", "Has broken every barrier down;", "Now, to be Thine, and Thine alone,", "O Lamb of God, I come, I come."]]
      }, {
        "number": "314",
        "name": "Just as I Am",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["Just as I am, without one plea,", "but that thy blood was shed for me,", "and that thou bidst me come to thee,", "O Lamb of God, I come, I come."], ["Just as I am, and waiting not", "to rid my soul of one dark blot,", "to thee whose blood can cleanse each spot,", "O Lamb of God, I come, I come."], ["Just as I am, though tossed about", "with many a conflict, many a doubt,", "fightings and fears within, without,", "O Lamb of God, I come, I come."], ["Just as I am, poor, wretched, blind;", "sight, riches, healing of the mind,", "yea, all I need in thee to find,", "O Lamb of God, I come, I come."], ["Just as I am, thou wilt receive,", "wilt welcome, pardon, cleanse, relieve;", "because thy promise I believe,", "O Lamb of God, I come, I come."], ["Just as I am, thy love I own", "hath broken every barrier down;", "now, to be thine, and thine alone,", "O Lamb of God, I come, I come."]]
      }, {
        "number": "315",
        "name": "O for a Closer Walk!",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["O for a closer walk with God,", "A calm and heavenly frame,", "A light to shine upon the road", "That leads me to the Lamb!"], ["Return, O holy Dove, return,", "Sweet messenger of rest!", "I hate the sins that made Thee mourn", "And drove Thee from my breast."], ["What peaceful hours I once enjoyed!", "How sweet their memory still!", "But they have left an aching void", "The world can never fill."], ["The dearest idol I have known,", "Whate'er that idol be", "Help me to tear it from Thy throne,", "And worship only Thee."]]
      }, {
        "number": "316",
        "name": "Live Out Thy Life Within Me",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["Live out Thy life within me, O Jesus, King of kings!", "Be Thou Thyself the answer To all my questionings;", "Live out Thy life within me, In all things have Thy way!", "I, the transparent medium Thy glory to display."], ["The temple has been yielded, And purified of sin;", "Let Thy Shekinah glory Now shine forth from within,", "And all the earth keep silence, The body henceforth be", "Thy silent, gentle servant, Moved only as by Thee."], ["Its members every moment Held subject to Thy call,", "Ready to have Thee use them, Or not be used at all;", "Held without restless longing, Or strain, or stress, or fret", "Or chafings at Thy dealings, Or thoughts of vain regret."], ["But restful, calm, and pliant, From bend and bias free,", "Awaiting Thy decision, When Thou hast need of me.", "Live out Thy life within me, O Jesus, King of kings!", "Be Thou the glorious answer To all my questionings."]]
      }, {
        "number": "317",
        "name": "Lead Me to Calvary",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["King of my life, I crown Thee now,", "Thine shall the glory be;", "Lest I forget Thy thorn crowned brow,", "Lead me to Calvary."], ["Show me the tomb where Thou wast laid,", "Tenderly mourned and wept;", "Angels in robes of light arrayed", "Guarded Thee whilst Thou slept."], ["Let me like Mary, through the gloom,", "Come with a gift to Thee;", "Show to me now the empty tomb,", "Lead me to Calvary."], ["May I be willing, Lord, to bear", "Daily my cross for Thee;", "Even Thy cup of grief to share,", "Thou hast borne all for me."]],
        "refrain": ["Lest I forget Gethsemane,", "Lest I forget Thine agony;", "Lest I forget Thy love for me,", "Lead me to Calvary."],
        "refrainFirst": false
      }, {
        "number": "318",
        "name": "Whiter Than Snow",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["Lord Jesus, I long to be perfectly whole;", "I want Thee forever to live in my soul;", "Break down every idol, cast out every foe;", "Now wash me, and I shall be whiter than snow."], ["Lord Jesus, look down from Thy throne in the skies,", "And help me to make a complete sacrifice;", "I give up myself, and whatever I know;", "Now wash me, and I shall be whiter than snow."], ["Lord Jesus, for this I most humbly entreat;", "I wait, blessed Lord, at Thy crucified feet,", "By faith, for my cleansing; I see Thy blood flow;", "Now wash me, and I shall be whiter than snow."], ["Lord Jesus, Thou seest I patiently wait;", "Come now, and within me a new heart create;", "To those who have sought Thee,", "Thou never said'st No;", "Now wash me, and I shall be whiter than snow."]],
        "refrain": ["Whiter than snow, yes, whiter than snow;", "Now wash me, and I shall be whiter than snow."],
        "refrainFirst": false
      }, {
        "number": "319",
        "name": "Lord, I Want to Be a Christian",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["Lord, I want to be a Christian in my heart, in my heart,", "Lord, I want to be a Christian in my heart, in my heart.", "In my heart, in my heart,", "Lord, I want to be a Christian in my heart, in my heart."], ["Lord, I want to be more loving in my heart, in my heart,", "Lord, I want to be more loving in my heart, in my heart.", "In my heart, in my heart,", "Lord, I want to be more loving in my heart, in my heart."], ["Lord, I want to be more holy in my heart, in my heart,", "Lord, I want to be more holy in my heart, in my heart.", "In my heart, in my heart,", "Lord, I want to be more holy in my heart, in my heart."], ["Lord, I want to be more like Jesus in my heart, in my heart,", "Lord, I want to be more like Jesus in my heart, in my heart.", "In my heart, in my heart,", "Lord, I want to be more like Jesus in my heart, in my heart."]]
      }, {
        "number": "320",
        "name": "Lord of Creation",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["Lord of creation, to you be all praise!", "Most mighty your working, most wondrous your ways!", "Your glory and might are beyond us to tell,", "And yet in the heart of the humble you dwell."], ["Lord of all power, I give you my will,", "In joyful obedience your tasks to fulfill.", "Your bondage is freedom; your service is song;", "And held in your keeping, my weakness is strong."], ["Lord of all wisdom, I give you my mind,", "Rich truth that surpasses man's knowledge to find;", "What eye has not seen and what ear has not heard", "Is taught by your spirit and shines from your word."], ["Lord of all being, I give you my all,", "If I ever disown you, I stumble and fall;", "But led in your service your word to obey,", "I'll walk in your freedom to the end of the way."]]
      }, {
        "number": "321",
        "name": "My Jesus, I Love Thee",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["My Jesus, I love thee, I know thou art mine;", "for thee all the follies of sin I resign.", "My gracious Redeemer, my Savior art thou;", "if ever I loved thee, my Jesus, 'tis now."], ["I love thee because thou hast first loved me,", "and purchased my pardon on Calvary's tree;", "I love thee for wearing the thorns on thy brow;", "if ever I loved thee, my Jesus, 'tis now."], ["I'll love Thee in life, I will love Thee 'til death,", "And praise Thee as long as Thou lendest me breath;", "And say when the death dew lies cold on my brow,", "if ever I loved thee, my Jesus, 'tis now."], ["In mansions of glory and endless delight;", "I'll ever adore thee in heaven so bright;", "I'll sing with the glittering crown on my brow;", "if ever I loved thee, my Jesus, 'tis now."]]
      }, {
        "number": "322",
        "name": "Nothing Between",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["Nothing between my soul and my Savior,", "naught of this world's delusive dream;", "I have renounced all sinful pleasure;", "Jesus is mine, there's nothing between."], ["Nothing between, like worldly pleasure;", "habits of life, though harmless they seem,", "must not my heart from him ever sever;", "he is my all, there's nothing between."], ["Nothing between, e'en many hard trials,", "though the whole world against me convene;", "watching with prayer and much self denial,", "I'll triumph at last, there's nothing between."]],
        "refrain": ["Nothing between my soul and my Savior,", "so that his blessed face may be seen;", "nothing preventing the least of his favor;", "keep the way clear! let nothing between."],
        "refrainFirst": false
      }, {
        "number": "323",
        "name": "O for a Heart to Praise My God!",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["O for a heart to praise my God,", "A heart from sin set free,", "A heart that always feels Thy blood", "So freely shed for me."], ["A heart resigned, submissive, meek,", "My dear Redeemer's throne,", "Where only Christ is heard to speak,", "Where Jesus reigns alone."], ["A heart in every thought renewed", "And full of love divine,", "Perfect and right and pure and good,", "A copy, Lord, of Thine."], ["Thy nature, gracious Lord, impart;", "Come quickly from above;", "Write Thy new name upon my heart,", "Thy new, best name of Love."]]
      }, {
        "number": "324",
        "name": "Just as I Am, Thine Own to Be",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["Just as I am, Thine own to be,", "Friend of the young, who lovest me,", "To consecrate myself to Thee,", "O Jesus Christ, I come."], ["In the glad morning of my day,", "My life to give, my vows to pay,", "With no reserve and no delay,", "With all my heart I come."], ["I would live ever in the light,", "I would work ever for the right;", "I would serve Thee with all my might;", "Therefore, to Thee I come."], ["Just as I am, young, strong, and free,", "To be the best that I can be", "For truth, and righteousness, and Thee,", "Lord of my life, I come."]]
      }, {
        "number": "325",
        "name": "Jesus, I My Cross Have Taken",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["Jesus, I my cross have taken,", "All to leave and follow Thee.", "All things else I have forsaken,", "Thou from hence my all shall be.", "Perish every fond ambition,", "All I've sought or hoped or known.", "Yet how rich is my condition!", "God and Heaven are still mine own."], ["Let the world despise and leave me,", "They have left my Savior, too.", "Human hearts and looks deceive me;", "Thou art faithful, Thou art true.", "O, 'tis not in grief to harm me,", "while thy love is left to me;", "O, 'twere not in joy to charm me,", "If that love be hid from me."], ["Soul, then know thy full salvation;", "Rise o'er sin, and fear, and care;", "Joy to find in every station something", "Still to do or bear:", "Think what Spirit dwells within thee;", "Think what Father's smiles are thine;", "Think that Jesus died to win thee,", "Child of heaven, canst thou repine?"], ["Haste then on from grace to glory,", "Armed by faith, and winged by prayer,", "Heaven's eternal day's before thee,", "God's own hand shall guide thee there.", "Soon shall close thy earthly mission,", "Soon shall pass thy pilgrim days;", "Hope soon change to glad fruition,", "Faith to sight, and prayer to praise."]]
      }, {
        "number": "326",
        "name": "Open My Eyes That I May See",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["Open my eyes, that I may see", "Glimpses of truth Thou hast for me;", "Place in my hands the wonderful key", "That shall unclasp and set me free.", "Silently now I wait for Thee,", "Ready, my God, Thy will to see;", "Open my eyes,", "illumine me, Spirit Divine!"], ["Open my ears that I may hear", "Voices of truth Thou sendest clear;", "and while the wavenotes fall on my ear,", "Everything false will disappear.", "Silently now I wait for Thee,", "Ready, my God, Thy will to see;", "Open my ears, illumine me, Spirit Divine!"], ["Open my mouth, and let me bear", "Gladly the warm truth everywhere;", "Open my heart, and let me prepare", "Love with Thy children thus to share.", "Silently now I wait for Thee,", "Ready, my God, Thy will to see;", "Open my heart, illumine me, Spirit Divine!"]]
      }, {
        "number": "327",
        "name": "I'd Rather Have Jesus",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["I'd rather have Jesus than silver or gold;", "I'd rather be His than have riches untold:", "I'd rather have Jesus than houses or lands.", "I'd rather be led by His nail-pierced hand"], ["I'd rather have Jesus than men's applause;", "I rather be faithful to His dear cause;", "Id rather have Jesus than worldwide fame.", "I'd rather be true to His holy name."], ["He's fairer than lilies of rarest bloom;", "He's sweeter than honey from out the comb;", "He's all than my hungering spirit needs.", "I'd rather hav Jesus and let Him lead"]],
        "refrain": ["Than to be the king of a vast domain", "Or be held in sin's dread sway.", "I'd rather have Jesus tan anything", "This world affords today."],
        "refrainFirst": false
      }, {
        "number": "328",
        "name": "Must Jesus Bear the Cross Alone",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["Must Jesus bear the cross alone,", "and all the world go free?", "No, there's a cross for everyone,", "and there's a cross for me."], ["The consecrated cross I'll bear", "till He shall set me free;", "and then go home my crown to wear,", "for there's a crown for me."], ["Upon the crystal pavement, down", "at Jesus' pierced feet,", "with joy I'll cast my golden crown,", "and His dear name repeat."]]
      }, {
        "number": "329",
        "name": "Take the World, but Give Me Jesus",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["Take the wold, but give me Jesus;", "All its Joys are but a name,", "But His love abideth ever,", "Through eternal years the same."], ["Take the wold, but give me Jesus,", "Sweetest comfort of my soul;", "With my Savior watching o'er me,", "I can sing, though billows roll."], ["Take the wold, but give me Jesus;", "Let me view his constant smile;", "Then throughout my pilgrim journey", "Light will cheer me all the while."], ["Take the wold, but give me Jesus;", "In His cross my trust shall be,", "Till, with clearer, brighter vision", "Face to face my Lord I see."]],
        "refrain": ["Oh, the height and depth of mercy!", "Oh, the length and breadth of love!", "Oh, the fullness of redemption,", "Pledge of endless life above"],
        "refrainFirst": false
      }, {
        "number": "330",
        "name": "Take My Life and Let It Be",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["Take my life, and let it be", "Consecrated, Lord, to Thee;", "Take my hands, and let them move", "At the impulse of Thy love;", "At the impulse of Thy love."], ["Take my feet, and let them be", "Swift and beautiful for Thee.", "Take my voice, and let me sing", "Always, only, for my King;", "Always, only, for my King."], ["Take my lips, and let them be", "Filled with messages from Thee.", "Take my silver and my gold:", "Not a mite would I withhold;", "Not a mite would I withhold."], ["Take my will, and make it Thine,", "It shall be no longer mine;", "Take my heart, it is Thine own,", "It shall be Thy royal throne.", "It shall be Thy royal throne."], ["Take my love, my Lord, I pour", "At Thy feet its treasure store;", "Take myself, and I will be,", "Ever, only, all for Thee.", "Ever, only, all for Thee."]]
      }, {
        "number": "331",
        "name": "O Jesus, I Have Promised",
        "category": "Gospel",
        "subcategory": "Consecration",
        "verses": [["O Jesus, I have promised", "to serve thee to the end;", "be thou forever near me,", "my Master and my friend.", "I shall not fear the battle", "if thou art by my side,", "nor wander from the pathway", "if thou wilt be my guide."], ["O let me feel thee near me!", "The world is ever near;", "I see the sights that dazzle,", "the tempting sounds I hear;", "my foes are ever near me,", "around me and within;", "but Jesus, draw thou nearer,", "and shield my soul from sin."], ["O Jesus, thou hast promised", "to all who follow thee", "that where thou art in glory", "there shall thy servant be.", "And Jesus, I have promised", "to serve thee to the end;", "O give me grace to follow,", "my Master and my Friend."]]
      }, {
        "number": "332",
        "name": "The Cleansing Wave",
        "category": "Gospel",
        "subcategory": "Baptism",
        "verses": [["Oh now I see the crimson wave,", "The fountain deep and wide;", "Jesus, my Lord, mighty to save,", "Points to His wounded side."], ["I see the new creation rise,", "I hear the speaking blood;", "It speaks polluted nature dies,", "Sinks 'neath the cleansing flood."], ["I rise to walk in heaven's own light,", "Above the world and sin;", "With heart made pure and garments white,", "And Christ enthroned within."], ["Amazing grace! 'tis heaven below", "To feel the blood applied,", "And Jesus, only Jesus, know,", "My Jesus crucified."]],
        "refrain": ["The cleansing stream I see, I see,", "I plunge, and O, it cleanseth me!", "O praise the Lord! it cleanseth me,", "It cleanseth me, yes, cleanseth me."],
        "refrainFirst": false
      }, {
        "number": "333",
        "name": "On Jordan's Banks the Baptist's Cry",
        "category": "Gospel",
        "subcategory": "Baptism",
        "verses": [["On Jordan's banks the Baptist's cry", "Announces that the Lord is nigh;", "Awake and hearken, for he brings", "Glad tidings of the King of kings!"], ["Then cleansed be every life from sin;", "Make straight the way for God within,", "And let us all our hearts prepare", "For Christ to come and enter there."], ["We hail You as our Savior, Lord,", "Our refuge and our great reward;", "Without Your grace we waste away", "Like flow'rs that wither and decay."], ["Stretch forth Your hand, our health restore,", "And make us rise to fall no more;", "Oh, let Your face upon us shine", "And fill the world with love divine."]]
      }, {
        "number": "334",
        "name": "Come, Thou Fount of Every Blessing",
        "category": "Gospel",
        "subcategory": "Salvation and Redemption",
        "verses": [["Come, thou Fount of every blessing,", "tune my heart to sing thy grace;", "streams of mercy, never ceasing,", "call for songs of loudest praise.", "Teach me some melodious sonnet,", "sung by flaming tongues above.", "Praise the mount! I'm fixed upon it,", "mount of thy redeeming love."], ["Here I raise mine Ebenezer;", "hither by thy help I'm come;", "and I hope, by thy good pleasure,", "safely to arrive at home.", "Jesus sought me when a stranger,", "wandering from the fold of God;", "he, to rescue me from danger,", "interposed his precious blood."], ["O to grace how great a debtor", "daily I'm constrained to be!", "Let thy goodness, like a fetter,", "bind my wandering heart to thee.", "Prone to wander, Lord, I feel it,", "prone to leave the God I love;", "here's my heart, O take and seal it,", "seal it for thy courts above."]]
      }, {
        "number": "335",
        "name": "What a Wonderful Savior",
        "category": "Gospel",
        "subcategory": "Salvation and Redemption",
        "verses": [["Christ has for sin atonement made,", "What a wonderful Saviour!", "We are redeemed! the price is paid!", "What a wonderful Savior!"], ["I praise Him for the cleansing blood,", "What a wonderful Saviour!", "That reconciled my soul to God;", "What a wonderful Saviour!"], ["He walks beside me all the way,", "What a wonderful Saviour!", "And keeps me faithful day by day;", "What a wonderful Saviour!"], ["He gives me overcoming power,", "What a wonderful Saviour!", "And triumph in each trying hour;", "What a wonderful Saviour!"]],
        "refrain": ["What a wonderful Savior", "is Jesus, my Jesus!", "What a wonderful Savior", "is Jesus, my Lord!"],
        "refrainFirst": false
      }, {
        "number": "336",
        "name": "There Is a Fountain",
        "category": "Gospel",
        "subcategory": "Salvation and Redemption",
        "verses": [["There is a fountain filled with blood", "drawn from Emmanuel's veins;", "and sinners plunged beneath that flood", "lose all their guilty stains.", "Lose all their guilty stains,", "lose all their guilty stains;", "and sinners plunged beneath that flood", "lose all their guilty stains."], ["The dying thief rejoiced to see", "that fountain in his day;", "and there may I, though vile as he,", "wash all my sins away.", "Wash all my sins away,", "wash all my sins away;", "and there may I, though vile as he,", "wash all my sins away."], ["Thou dying Lamb!, thy precious blood", "shall never lose its power", "till all the ransomed church of God", "be saved, to sin no more.", "Be saved, to sin no more,", "be saved, to sin no more;", "till all the ransomed church of God", "be saved, to sin no more."], ["E'er since, by faith, I saw the stream", "thy flowing wounds supply,", "redeeming love has been my theme,", "and shall be till I die.", "And shall be till I die,", "and shall be till I die;", "redeeming love has been my theme,", "and shall be till I die."], ["Lord, I believe Thow hast prepared,", "Unworthy though I be,", "For me a blood-bought, free reward", "A golden harp for me!", "A golden harp for me!", "A golden harp for me!", "For me a blood-bought, free reward", "A golden harp for me!"], ["There in a nobler, sweeter song,", "I'll sing thy power to save,", "when this poor lisping, stammering tongue", "Is ransomed form the grave,", "Is ransomed form the grave,", "Is ransomed form the grave,", "when this poor lisping, stammering tongue", "Is ransomed form the grave."]]
      }, {
        "number": "337",
        "name": "Redeemed!",
        "category": "Gospel",
        "subcategory": "Salvation and Redemption",
        "verses": [["Redeemed, how I love to proclaim it!", "Redeemed by the blood of the Lamb;", "Redeemed through His infinite mercy,", "His child and forever I am."], ["Redeemed, and so happy in Jesus,", "No language my rapture can tell;", "I know that the light of His presence", "With me doth continually dwell."], ["I know there's a crown that is waiting", "In yonder bright mansion for me;", "And soon, with the spirit made perfect,", "At home with the Lord I shall be."]],
        "refrain": ["Redeemed, redeemed,", "Redeemed by the blood of the Lamb;", "Redeemed, redeemed,", "His child and forever I am."],
        "refrainFirst": false
      }, {
        "number": "338",
        "name": "Redeemed!",
        "category": "Gospel",
        "subcategory": "Salvation and Redemption",
        "verses": [["Redeemed, how I love to proclaim it!", "Redeemed by the blood of the Lamb;", "Redeemed thro' His infinite mercy,", "His child, and forever, I am."], ["I think of my blessed Redeemer,", "I think of Him all the day long;", "I sing, for I cannot be silent;", "His love is the theme of my song."], ["I know I shall see in His beauty", "The King in whose law I delight,", "Who lovingly guardeth my footsteps,", "And giveth me songs in the night."]],
        "refrain": ["Redeemed, redeemed,", "Redeemed by the blood of the Lamb;", "Redeemed, how I love to proclaim it!", "His child and forever I am."],
        "refrainFirst": false
      }, {
        "number": "339",
        "name": "God Is My Strong Salvation",
        "category": "Gospel",
        "subcategory": "Salvation and Redemption",
        "verses": [["God is my strong salvation,", "What foe have I to fear?", "In darkness and temptation,", "My Light, my Help, is near:", "Though hosts encamp around me,", "Firm in the fight I stand;", "What terror can confound me,", "With God at my right hand?"], ["Place on the reliance;", "My soul with courage wait;", "His truth be thine affiance,", "When faint and desolate.", "His might thy heart shall strengthen,", "His love thy joy increase;", "Mercy thy days shall lengthen;", "The Lord will give thee peace."]]
      }, {
        "number": "340",
        "name": "Jesus Saves",
        "category": "Gospel",
        "subcategory": "Salvation and Redemption",
        "verses": [["We have heard a joyful sound,", "Jesus saves, Jesus saves;", "Spread the gladness all around,", "Jesus saves, Jesus saves;", "Bear the news to every land,", "Climb the steeps and cross the waves,", "Onward, 'tis our Lord's command,", "Jesus saves, Jesus saves."], ["ft it on the rolling tide,", "Jesus saves, Jesus saves;", "Tell to sinners, far and wide,", "Jesus saves, Jesus saves;", "Sing, ye islands of the sea.", "Echo back, ye ocean caves,", "Earth shall keep her jubilee,", "Jesus saves, Jesus saves."], ["ng above the battle's strife,", "Jesus saves, Jesus saves;", "By His death and endless life,", "Jesus saves, Jesus saves;", "Sing it softly through the gloom,", "When the heart for mercy craves,", "Sing in triumph o'er the tomb,", "Jesus saves, Jesus saves."], ["ve the winds a mighty voice,", "Jesus saves, Jesus saves;", "Let the nations now rejoice,", "Jesus saves, Jesus saves;", "Shout salvation full and free,", "Highest hills and deepest caves,", "This our song of victory,", "Jesus saves, Jesus saves."]]
      }, {
        "number": "341",
        "name": "To God Be the Glory",
        "category": "Gospel",
        "subcategory": "Salvation and Redemption",
        "verses": [["To God be the glory, great things he hath done!", "So loved he the world that he gave us his Son,", "who yielded his life an atonement for sin,", "and opened the lifegate that all may go in."], ["O perfect redemption, the purchase of blood,", "to every believer the promise of God;", "the vilest offender who truly believes,", "that moment from Jesus a pardon receives."], ["Great things he hath taught us, great things he hath done,", "and great our rejoicing thru Jesus the Son;", "but purer, and higher, and greater will be", "our wonder, our transport, when Jesus we see."]],
        "refrain": ["Praise the Lord, praise the Lord,", "let the earth hear his voice!", "Praise the Lord, praise the Lord,", "let the people rejoice!", "O come to the Father thru Jesus the Son,", "and give him the glory, great things he hath done!"],
        "refrainFirst": false
      }, {
        "number": "342",
        "name": "Is This a Day of New Beginnings?",
        "category": "Gospel",
        "subcategory": "Salvation and Redemption",
        "verses": [["Is this a day of new beginnings,", "Time to remember and move on,", "Time too believe what love is bringing,", "Laying to rest the pain that's gone?"], ["How can the seasons of a planet", "Mindlessly spinning round its sun", "With just a human name and number", "Say that some new thing has begun?"], ["Yet through the life and death of Jesus", "Love's mighty Spirit, now as then,", "Can make for us a world of difference", "As faith and hope are born again."], ["Then let us, with the Spirit's daring,", "Step from the past and leave behind", "Its disappointment, guilt, and grieving,", "Seeking new paths, and sure to find."], ["Christ is alive, and goes before us", "To show and share what love can do.", "This is a day of new beginnings;", "Our God is making all things new."]]
      }, {
        "number": "343",
        "name": "I Will Sing of My Redeemer",
        "category": "Gospel",
        "subcategory": "Salvation and Redemption",
        "verses": [["I will sing of my Redeemer,", "And His wondrous love to me;", "On the cruel cross He suffered,", "From the curse to set me free."], ["I will tell the wondrous story,", "How my lost estate to save,", "In His boundless love and mercy,", "He the ransom freely gave."], ["I will sing of my Redeemer,", "And His heavenly love to me;", "He from death to life hath brought me,", "Son of God with Him to be."]],
        "refrain": ["Sing, oh sing, of my Redeemer,", "With His blood, He purchased me.", "On the cross, He sealed my pardon,", "Paid the debt, and made me free."],
        "refrainFirst": false
      }, {
        "number": "344",
        "name": "I Love Your Kingdom, Lord",
        "category": "Christian Church",
        "subcategory": "Community in Christ",
        "verses": [["I love thy kingdom, Lord,", "the house of thine abode,", "the church our blest Redeemer saved", "with his own precious blood."], ["I love thy church, O God!", "Her walls before thee stand", "dear as the apple of thine eye,", "and graven on thy hand."], ["Beyond my highest joy", "I prize her heavenly ways,", "her sweet communion, solemn vows,", "her hymns of love and praise."], ["Sure as thy truth shall last,", "to Zion shall be given", "the brightest glories earth can yield,", "and brighter bliss of heaven."]]
      }, {
        "number": "345",
        "name": "Christ Is the World's True Light",
        "category": "Christian Church",
        "subcategory": "Community in Christ",
        "verses": [["Christ is the world's true light,", "Its captain of salvation,", "The daystar clear and bright", "Of every man and nation;", "New life, new hope awakes", "Where'er men own His sway:", "Freedom her bondage breaks,", "And night is turned to day."], ["In Christ all races meet,", "Their ancient feuds forgeting,", "The whole round world complete,", "From sunrise to its setting:", "When Christ is throned as Lord,", "Men shall forsake their fear,", "To plowshare beat the sword,", "To pruning hook the spear."], ["One Lord, in one great name", "Unite us all who own Thee,", "Cast out our pride and shame", "That hinder to enthrone Thee;", "The world has waited long,", "Has travailed long in pain,", "To heal its ancient wrong,", "Come, Prince of Peace, and reign."]]
      }, {
        "number": "346",
        "name": "Lord, Who Dost Give to Thy Church",
        "category": "Christian Church",
        "subcategory": "Community in Christ",
        "verses": [["Lord, who dost give to Thy church for its healing", "Gifts, and the grace to sustain and renew,", "Hear as we pray that today and each morrow", "We to Thy purpose may show ourselves true."], ["Clear be the voices of preachers and prophets", "Fearlessly speaking the word of the Lord,", "Word of redemption thro'God's Son incarnate,", "Blessing for cursing, and peace for the sword."], ["Tender and wise be the hearts of the pastors,", "Guiding and guarding the souls in their care,", "Firm with the wayward, a strength to the doubting,", "Helping the needy their burdens to bear."], ["May those who teach grow in knowledge and patience,", "Guiding to wisdom the young and the old,", "Training for worship and witness and service,", "Foes to all falsehood, in truthfulness bold."], ["Lord, ever give to us gifts in due measure,", "Each needing other, and all having worth;", "So to the Father, the Son, and the Spirit,", "Glory be shown by the church here on earth."]]
      }, {
        "number": "347",
        "name": "Built on the Rock",
        "category": "Christian Church",
        "subcategory": "Community in Christ",
        "verses": [["Built on the Rock the Church shall stand,", "Even when steeples are falling;", "Crumbled have spires in every land,", "Bells still are chiming and calling-", "Calling the young and old to rest,", "Calling the souls of those distressed,", "Longing for life everlasting."], ["Not in our temples made with hands", "God, the Almighty, is dwelling;", "High in the heav'ns His temple stands,", "All earthly temples exceling.", "Yet He who dwells in heav'n above", "Deigns to abide with us in love,", "Making our bodies His temple."], ["We are God's house of living stones,", "Built for His own habitation;", "He fills our hearts, His humble thrones,", "Granting us life and salvation.", "Were two or three to seek His face,", "He in their midst would show His grace,", "Blessings upon them bestowing."], ["Yet in this house, an earthly frame,", "Jesus His children is blessing;", "Hither we come to praise His name,", "Faith in our Savior confessing.", "Jesus to us His Spirit sent,", "Making with us His covenant,", "Granting His children the kingdom."], ["Thro'all the passing years, O Lord,", "Grant that, when church bells are ringing,", "Many may come to hear God's Word", "Where He this promise is bringing:", "I know My own, My own know Me:", "You, not the world, My face shall see;", "My peace I leave with you. Amen."]]
      }, {
        "number": "348",
        "name": "The Church Has One Foundation",
        "category": "Christian Church",
        "subcategory": "Community in Christ",
        "verses": [["The church's one foundation", "is Jesus Christ her Lord;", "she is his new creation", "by water and the Word.", "From heaven he came and sought her", "to be his holy bride;", "with his own blood he bought her,", "and for her life he died."], ["Elect from every nation,", "yet one o'er all the earth;", "her charter of salvation,", "one Lord, one faith, one birth;", "one holy name she blesses,", "partakes one holy food,", "and to one hope she presses,", "with every grace endued."], ["Though with a scornful wonder", "we see her sore oppressed,", "by schisms rent asunder,", "by heresies distressed,", "yet saints their watch are keeping;", "their cry goes up, \"How long?\"", "And soon the night of weeping", "shall be the morn of song."], ["'Mid toil and tribulation,", "and tumult of her war,", "she waits the consummation", "of peace forevermore;", "till, with the vision glorious,", "her longing eyes are blest,", "and the great church victorious", "shall be the church at rest."]]
      }, {
        "number": "349",
        "name": "God is Love",
        "category": "Christian Church",
        "subcategory": "Community in Christ",
        "refrain": ["God is love, and where true love is", "God Himself is there."],
        "refrainFirst": true,
        "verses": [["Here in Christ we gather, love of Christ our calling.", "Christ, our love, is with us, gladness be His greeting", "Let us fear Him, yes, and love Him, God eternal.", "Loving Him, let each love Christ in all his brethren."], ["When we Christians gather, members of one body,", "Let there be in us no discord but one spirit.", "Banished now be anger, strife, and every quarrel.", "Christ, our God, be always present here among us."], ["Grant us love's fulfillment, joy with all the blessed,", "When we see Your face, O Savior, in its glory.", "Shine on us, O purest Light of all creation,", "Be our bliss while endless ages sing Your praises."]]
      }, {
        "number": "350",
        "name": "Blest Be the Tie That Binds",
        "category": "Christian Church",
        "subcategory": "Community in Christ",
        "verses": [["Blest be the tie that binds", "our hearts in Christian love;", "the fellowship of kindred minds", "is like to that above."], ["Before our Father's throne", "we pour our ardent prayers;", "our fears, our hopes, our aims are one,", "our comforts and our cares."], ["We share each other's woes,", "our mutual burdens bear;", "and often for each other flows", "the sympathizing tear."], ["When we asunder part,", "it gives us inward pain;", "but we shall still be joined in heart,", "and hope to meet again."]]
      }, {
        "number": "351",
        "name": "Thy Hand, O God Has Guided",
        "category": "Christian Church",
        "subcategory": "Community in Christ",
        "verses": [["Thy hand, O God, has guided", "Thy flock from age to age;", "The wondrous tale is written", "Full clear on every page;", "Our fathers owned Thy goodness,", "And we their deeds record;", "And both of this bear witness,", "One church, one faith, one Lord."], ["Thy heralds brought glad tidings", "To greatest as to least;", "They bade men rise and hasten", "To share the great King's feast:", "And this was all their teaching,", "In every deed and word,", "To all alike proclaiming", "One church, one faith, one Lord."], ["When shadows thick were falling,", "And all seemed sunk in night,", "Thou, Lord, did send Thy servants,", "Thy chosen sons of light.", "On them and on Thy people", "Thy plenteous grace was poured,", "And this was still their message:", "One church, one faith, one Lord."], ["Thy mercy will not fail us,", "Nor leave Thy work undone;", "With Thy right hand to help us,", "The vict'ry shall be won;", "And then by men and angels", "Thy name shall be adored,", "And this shall be their anthem:", "One church, one faith, one Lord."]]
      }, {
        "number": "352",
        "name": "This Is My Will",
        "category": "Christian Church",
        "subcategory": "Community in Christ",
        "verses": [["This is My will, My one command,", "That love should dwell among you all.", "This is My will that you should love", "As I have shown that I love you."], ["No greater love a man can have", "Than that he die to save his friends.", "You are My friends if you obey", "What I command that you should do."], ["You chose not Me, but I chose you", "That you should go and bear much fruit.", "I chose you out that you in Me", "Should bear much fruit that will abide."], ["All that I ask My Father, dear,", "For My name's sake you shall receive.", "This is My will, My one command,", "That love should dwell in each, in all."]]
      }, {
        "number": "353",
        "name": "Father, Help Your People",
        "category": "Christian Church",
        "subcategory": "Community in Christ",
        "verses": [["Father, help Your people in this world to build", "Something of Your kingdom and to do Your will,", "Lead us to discover partnership in love;", "Bless our ways of sharing and our pride remove."], ["Lord of desk and altar, bind our lives in one,", "That in work and worship love may set the tone.", "Give us grace to listen, clarity of speech;", "Make us truly thankful for the gifts of each."], ["Holy is the setting of each room and yard,", "Lecture hall and kitchen, office, shop, and ward.", "Holy is the rhythm of our working hours;", "Hallow then our purpose, energy, and pow'rs."], ["Strengthen, Lord, for service hand and heart and brain;", "Help us good relations daily to maintain.", "Let the living presence of the servant Christ", "Heighten our devotion, make our life a feast."]]
      }, {
        "number": "354",
        "name": "Thy Love, O God",
        "category": "Christian Church",
        "subcategory": "Community in Christ",
        "verses": [["Thy love, O God, has all mankind created,", "And led Thy people to this present hour;", "In Christ we see life's glory consummated;", "Thy Spirit manifests His living power."], ["From out the darkness of our hope's frustration,", "From all the broken idols of our pride,", "We turn to seek Thy truth's illumination,", "And find Thy mercy waiting at our side."], ["In pity look upon Thy children's striving", "For life and freedom, peace and brotherhood,", "Till at the fullness of Thy truth arriving,", "We find in Christ the crown of every good."], ["Inspire Thy church, mid earth's discordant voices,", "To preach the gospel of her Lord above,", "Until the day this warring world rejoices", "To hear the mighty harmonies of love."]]
      }, {
        "number": "355",
        "name": "Where Cross the Crowded Ways of Life",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["Where cross the crowded ways of life,", "where sound the cries of race and clan,", "above the noise of selfish strife,", "we hear your voice, O Son of Man."], ["From tender childhood's helplessness,", "from woman's grief, man's burdened toil,", "from famished souls, from sorrow's stress,", "your heart has never known recoil."], ["The cup of water given for you still", "holds the freshness of your grace;", "yet long these multitudes to view", "the sweet compassion of your face."], ["O Master, from the mountainside", "make haste to heal these hearts of pain;", "among these restless throngs abide;", "O tread the city's streets again."], ["Till all the world shall learn your love", "and follow where your feet have trod,", "till, glorious from your heaven above,", "shall come the city of our God."]]
      }, {
        "number": "356",
        "name": "All Who Love and Serve Your City",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["All who love and serve your city,", "All who bear its daily stress,", "All who cry for peace and justice,", "All who curse and all who bless."], ["In your day of loss and sorrow,", "In your day of helpless strife,", "Honor, peace, and love retreating,", "Seek the Lord, who is your life."], ["For all days are days of judgment,", "And the Lord is waiting still,", "Drawing near His friends who spurn Him,", "Off'ring peace from Calv'ry's hill."], ["Risen Lord, shall yet the city", "Be the city of despair?", "Come today, our judge, our glory;", "Be its name \"The Lord is there!\""]]
      }, {
        "number": "357",
        "name": "Come, Labor On",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["Come, labor on.", "Who dares stand idle on the harvest plain", "While all around him waves the golden grain?", "And to each servant does the Master say,", "\"Go work today.\""], ["Come, labor on.", "Claim the high calling angels cannot share;", "To young and old the gospel gladness bear;", "Redeem the time; its hours too swiftly fly.", "The night draws nigh."], ["Come, labor on.", "No time for rest; till glows the western sky,", "Till the long shadows oér our pathway lie,", "And a glad sound comes with the setting sun,", "\"Well done, well done!\""]]
      }, {
        "number": "358",
        "name": "Far and Near the Fields Are Teeming",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["Far and near the fields are teeming", "with the sheaves of ripened grain;", "Far and near their gold is gleaming", "O'er the summy slope and plain."], ["Send them forth with morn's first beaming,", "Send them in the noon-tides's glare;", "When the sun's last rays are streaming,", "bid them gather everywhere."], ["O thou, whom thy Lord is sending,", "gather now the sheaves of gold;", "Heavenward then at evening wending", "Thou shalt come with joy untold."]],
        "refrain": ["Lord of harvest, send forth reapers!", "Hear us Lord,to Thee we cry;", "Send them now the sheaves to gather,", "Ere the harvest-time pass by."],
        "refrainFirst": false
      }, {
        "number": "359",
        "name": "Hark! the Voice of Jesus Calling",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["Hark! the voice of Jesus calling,", "\"Who will go and work today?", "Fields are white, the harvest waiting,", "Who will bear the sheaves away?\"", "Loud and long the Master calleth,", "Rich reward He offers free;", "Who will answer, gladly saying,", "\"Here am I, O Lord, send me\"?"], ["If you cannot cross the ocean", "And the heathen lands explore,", "You can find the heathen nearer,", "You can help them at your door;", "If you cannot speak like angels,", "If you cannot preach like Paul,", "You can tell the love of Jesus,", "You can say He died for all."], ["If you cannot be the watchman,", "Standing high on Zion's wall,", "Pointing out the path to heaven,", "Offering life and peace to all;", "With your prayers and with your bounties", "You can do what Heaven demands,", "You can be like faithful Aaron,", "Holding up the prophet's hands."], ["While the souls of men are dying,", "And the Master calls for you,", "Let none hear you idly saying,", "\"There is nothing I can do!\"", "Gladly take the task He gives you,", "Let His work your pleasure be;", "Answer quickly when He calleth,", "\"Here am I, O Lord, send me.\""]]
      }, {
        "number": "360",
        "name": "From the Eastern Mountains",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["From the eastern mountains Pressing on they come,", "Wise men in their wisdom, To His humble home;", "Stirred by deep devotion, Hasting from afar,", "Ever journeying onward, Guided by a star."], ["There their Lord and Savior Meek and lowly,", "Wondrous light that led them Onward on their way,", "Ever now to lighten Nations from afar,", "As they journey homeward By that guiding star."], ["Gather in the outcasts All who've gone astray,", "Throw Thy radiance o'er them, Guide them on their way;", "Those who never knew Thee, Those who've wandered far,", "Guide them by the brightness Of Thy guiding star."], ["Until every nation, Whether bond or free,", "'Neath Thy starlit banner, Jesus follows thee.", "O'er the distant mountains To that heavenly home,", "Where no sin nor sorrow Evermore shall come."]]
      }, {
        "number": "361",
        "name": "Hark! 'Tis the Shepherd's Voice I Hear",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["Hark! 'tis the Shepherd's voice I hear,", "Out in the desert dark and drear,", "Calling the sheep who've gone astray,", "Far from the Shepherd's fold away."], ["Who'll go and help the Shepherd kind,", "Help Him the wandering ones to find?", "Who'll bring them back into the fold,", "Where they'll be sheltered from the cold?"], ["Out in the desert hear their cry,", "Out on the mountain wild and high,", "Hark! 'tis the Master speaks to thee,", "\"Go, find My sheep where 'er they be.\""]],
        "refrain": ["Bring them in, Bring them in,", "Bring them in from the fields of sin;", "Bring them in, Bring them in,", "Bring the wanderers to Jesus."],
        "refrainFirst": false
      }, {
        "number": "362",
        "name": "Lift High the Cross",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "refrain": ["Lift high the cross, the love of Christ proclaim,", "Till all the world adore His sacred Name."],
        "refrainFirst": true,
        "verses": [["Come, Christians, follow where our Captain trod,", "Our King victorious, Christ, the Son of God."], ["Led on their way by this triumphant sign,", "The hosts of God in conquering ranks combine."], ["All newborn soldiers of the Crucified", "Bear on their brows the seal of Him Who died."], ["O Lord, once lifted on the glorious tree,", "As Thou hast promised, draw us all to Thee."], ["So shall our song of triumph ever be:", "Praise to the Crucified for victory!"]]
      }, {
        "number": "363",
        "name": "Lord, Whose Love in Humble Service",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["Lord, whose love in humble service", "Bore the weight of human need,", "Who upon the cross, forsaken,", "Worked Your mercy's perfect deed:", "We, Your servants, bring the worship", "Not of voice alone, but heart;", "Consecrating to Your purpose", "Every gift which You impart."], ["Still Your children wander homeless;", "Still the hungry cry for bread;", "Still the captives long for freedom;", "Still in grief we mourn our dead.", "As you, Lord, in deep compassion", "Healed the sick and freed the soul,", "By Your Spirit send Your power", "To our world to make it whole."], ["As we worship, grant us vision,", "Till your love's revealing light", "In its height and depth and greatness", "Dawns upon our quickened sight,", "Making known the needs and burdens", "Your compassion bids us bear,", "Stirring us to ardent service,", "Your abundant life to share."]]
      }, {
        "number": "364",
        "name": "O Jesus Christ, to You",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["O Jesus Christ, to You may hymns be rising", "In every city for Your love and care;", "Inspire our worship, grant the glad surprising", "That Your blest Spirit brings men everywhere."], ["Grant us new courage sacrificial humble", "Strong in Your strength to venture and to dare,", "To lift the fallen, guide the feet that stumble,", "Seek out the lonely &God's mercy share"], ["Show us Your Spirit, brooding o'er each city,", "As You did weep above Jerusalem,", "Seeking to gather all in love and pity,", "And healing those who touch Your garment's hem."]]
      }, {
        "number": "365",
        "name": "O Zion, Haste",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["O Zion, haste, thy mission high fulfilling,", "to tell to all the world that God is light,", "that he who made all nations is not willing", "one soul should perish, lost in shades of night."], ["Proclaim to every people, tongue, and nation", "that God, in whom they live and move, is love;", "tell how he stooped to save his lost creation,", "and died on earth that we might live above."], ["Give of thine own to bear the message glorious;", "give of thy wealth to speed them on their way;", "pour out thy soul for them in prayer victorious;", "O Zion, haste to bring the brighter day."], ["He comes again; O Zion, ere thou meet Him,", "Make known to every heart His saving grace;", "Let none whom He hath ransomed fail to greet Him,", "Through thy neglect, unfit to see His face."]],
        "refrain": ["Publish glad tidings, tidings of peace;", "tidings of Jesus, redemption and release."],
        "refrainFirst": false
      }, {
        "number": "366",
        "name": "O Where Are the Reapers?",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["O where are the reapers that garner in", "The sheaves of the good from the fields of sin?", "With sickles of truth must the work be done,", "And no one may rest till the \"harvest home.\""], ["The fields all are ripening, and far and wide", "The world now is waiting the harvest tide:", "But reapers are few, and the work is great,", "And much will be lost should the harvest wait."], ["So come with your sickles, ye sons of men,", "And gather together the golden grain;", "Toil on till the Lord of the harvest come,", "Then share ye His joy in the \"harvest home.\""]],
        "refrain": ["Where are the reapers? O who will come", "And share in the glory of the \"harvest home\"?", "O who will help us to garner in", "The sheaves of good from the fields of sin?"],
        "refrainFirst": false
      }, {
        "number": "367",
        "name": "Rescue the Perishing",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["Rescue the perishing, care for the dying,", "snatch them in pity from sin and the grave;", "weep o'er the erring one, lift up the fallen,", "tell them of Jesus, the mighty to save."], ["Though they are slighting him, still he is waiting,", "waiting the penitent child to receive;", "plead with them earnestly, plead with them gently;", "he will forgive if they only believe."], ["Rescue the perishing, duty demands it;", "strength for thy labor the Lord will provide;", "back to the narrow way patiently win them;", "tell the poor wanderer a Savior has died."]],
        "refrain": ["Rescue the perishing, care for the dying;", "Jesus is merciful, Jesus will save."],
        "refrainFirst": false
      }, {
        "number": "368",
        "name": "Watchman, Blow the Gospel Trumpet",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["Watchman, blow the gospel trumpet,", "Every soul a warning give;", "Whosoever hears the message", "May repent, and, turn and live."], ["Sound it loud oér every hilltop,", "Gloomy shade and sunny plain;", "Ocean depths repeat the message,", "Full salvation's glad refrain."], ["Sound it in the hedge and highway,", "Earth's dark spots where exiles roam;", "Let it tell all things are ready,", "Father waits to welcome home."], ["Sound it for the heavy laden,", "Weary, longing to be free;", "Sound a Savior's invitation,", "Sweetly saying, \"Come to me.\""]],
        "refrain": ["Blow the trumpet, trusty watchman,", "Blow it loud o'er land and sea;", "God commissions, sound the message!", "Every captive may be free."],
        "refrainFirst": false
      }, {
        "number": "369",
        "name": "Bringing in the Sheaves",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["Sowing in the morning, sowing seeds of kindness,", "Sowing in the noontide and the dewy eve,", "Waiting for the harvest and the time of reaping -", "We shall come rejoicing, bringing in the sheaves."], ["Sowing in the sunshine, sowing in the shadows,", "Fearing neither clouds nor winter's chilling breeze;", "By and by the harvest and the labor ended -", "We shall come rejoicing, bringin in the sheaves."], ["Going forth with weeping, sowing for the Master,", "Tho the loss sustained our spirit often grieves;", "When our weeping's over He will bid us welcome -", "We shall come rejoicing, bringing in the sheaves"]],
        "refrain": ["Bringing in the sheaves, bringing in the sheaves,", "We shall come rejoicing, bringing in the sheaves.", "Bringing in the sheaves, bringing in the sheaves,", "We shall come rejoicing, bringing in the sheaves."],
        "refrainFirst": false
      }, {
        "number": "370",
        "name": "Christ for the World",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["Christ for the world we sing,", "the world to Christ we bring,", "with loving zeal;", "the poor, and them that mourn,", "the faint and overborne,", "sinsick and sorrow-worn,", "whom Christ doth heal."], ["Christ for the world we sing,", "the world to Christ we bring,", "with fervent prayer;", "the wayward and the lost,", "by restless passions tossed,", "redeemed at countless cost,", "from dark despair."], ["Christ for the world we sing,", "the world to Christ we bring,", "with joyful song;", "the newborn souls, whose days,", "reclaimed from error's ways,", "inspired with hope and praise,", "to Christ belong."]]
      }, {
        "number": "371",
        "name": "Lift Him Up",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["Lift Him up, 'tis He that bids you,", "Let the dying look and live;", "To all weary, thirsting sinners,", "Living waters will He give;", "And though once so meek and lowly,", "Yet the Prince of heaven was He;", "And the blind, who grope in darkness,", "Through the blood of Christ shall see."], ["Lift Him up, this precious Savior,", "Let the multitude behold;", "They with willing hearts shall seek Him,", "He will draw them to His fold;", "They shall gather from the wayside,", "Hastening on with joyous feet,", "They shall bear the cross of Jesus,", "And shall find salvation sweet."], ["Lift Him up in all His glory,", "'Tis the Son of God on high;", "Lift Him up, His love shall draw them,", "Eén the careless shall draw nigh;", "Let them hear again the story", "Of the cross, the death of shame;", "And from tongue to tongue repeat it;", "Mighty throngs shall bless His name."], ["O then lift Him up in singing,", "Lift the Savior up in prayer;", "He, the glorious Redeemer,", "All the sins of men did bear;", "Yes, the young shall bow before Him,", "And the old their voices raise;", "All the deaf shall hear hosannah;", "And the dumb shall shout His praise."]],
        "refrain": ["Lift Him up, the risen Savior,", "High amid the waiting throng;", "Lift Him up, 'tis He that speaketh,", "Now He bids you flee from wrong."],
        "refrainFirst": false
      }, {
        "number": "372",
        "name": "How Beauteous Are Their Feet",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["How beauteous are their feet", "Who stand on Zion's hill;", "Who bring salvation on their tongues,", "And words of peace reveal!"], ["How charming is their voice,", "So sweet the tidings are:", "\"Zion, behold thy Savior King;", "He reigns and triumphs here!\""], ["How happy are our ears,", "That hear the joyful sound", "Which kings and prophets waited for,", "And sought, but never found!"], ["How blessed are our eyes,", "That see this heavenly light;", "Prophets and kings desired it long,", "But died without the sight!"], ["The watchmen join their voice,", "And tuneful notes employ;", "Jerusalem breaks forth in songs,", "And deserts learn the joy."]]
      }, {
        "number": "373",
        "name": "Seeking the Lost",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["Seeking the lost-yes, kindly entreating", "Wanderers on the mountain astray", "\"Come unto Me,\" His message repeating,", "Words of the Master speaking today."], ["Seeking the lost-and pointing to Jesus", "Souls that are weak and hearts that are sore,", "Leading them forth in ways of salvation,", "Showing the path to life evermore."], ["Thus I would go on missions of mercy", "Following Christ from day unto day,", "Cheering the faint and raising the fallen,", "Pointing the lost to Jesus, the Way"]],
        "refrain": ["Men: Going afar Upon the moun-tain", "Ladies: (Going afar) (Upon the mountain)", "Men: Bringing the wand'+rer back again, back again", "Ladies: (Bringing the wand'rer back again,) back again", "Men: Into the fold of my Redeem-er", "Ladies: Into the fold of my Redeemer,", "Men: Jesus, the lamb +for sinners slain, for sinners slain", "Ladies: (Jesus the Lamb +for sinners slain,) for sinners slain"],
        "refrainFirst": false,
      }, {
        "number": "374",
        "name": "Jesus, With Thy Church Abide",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["Jesus, with Thy church abide;", "Be her Savior, Lord, and Guide,", "While on earth her faith is tried:", "We beseech Thee, hear us."], ["May her voice be ever clear,", "Warning of a judgment near,", "Telling of a Savior dear:", "We beseech Thee, hear us."], ["May she guide the poor and blind,", "Seek the lost until she find,", "And the broken hearted bind:", "We beseech Thee, hear us."], ["May she holy triumps win,", "Overthrow the hosts of sin,", "Gather all the nations in:", "We beseech Thee, hear us."]]
      }, {
        "number": "375",
        "name": "Work, for the Night Is Coming",
        "category": "Christian Church",
        "subcategory": "Mission of the Church",
        "verses": [["Work for the night is coming,", "Work through the morning hours;", "Work while the dew is sparkling;", "Work 'mid springing flow'rs.", "Work when the day grows brighter,", "Work in the glowing sun;", "Work for the night is coming,", "When man's work is done."], ["Work for the night is coming,", "Work thro' the sunny noon;", "Fill brightest hours with labor,", "Rest comes sure and soon.", "Give every flying minute", "Something to keep in store;", "Work for the night is coming,", "When man works no more."], ["Work for the night is coming,", "Under the sunset skies;", "While their bright tints are glowing,", "Work for daylight flies.", "Work till the last beam fadeth,", "Fadeth to shine no more;", "Work while the night is dark'ning,", "When man's work is o'er."]]
      }, {
        "number": "376",
        "name": "All Things Are Thine",
        "category": "Christian Church",
        "subcategory": "Church Dedication",
        "verses": [["All things are Thine; no gift have we,", "Lord of all gifts, to offer Thee;", "And hence with grateful hearts today,", "Thine own before Thy feet we lay."], ["Thy will was in the builder's thought;", "Thy hand unseen amidst us wrought;", "Thro' mortal motive, scheme and plan,", "Thy wise, eternal purpose ran."], ["No lack Thy perfect fullness knew;", "For human needs and longings grew", "This house of prayer- this home of rest.", "Here may Thy saints be often blessed."], ["In weakness and in want we call", "On Thee, for whom the heav'ns are small;", "Thy glory is Thy children's good,", "Thy joy Thy tender Fatherhood."], ["O Father! deign these walls to bless;", "Make this the abode of righteousness,", "And let these doors a gateway be", "To lead us from ourselves to Thee!"]]
      }, {
        "number": "377",
        "name": "Go Forth, Go Forth With Christ",
        "category": "Christian Church",
        "subcategory": "Ordination",
        "verses": [["Go forth, go forth with Christ,", "Who called you to this day,", "He who has led, will lead", "And keep you in His way:", "His word is fast, His promise sure", "To all who serve Him and endure."], ["Go forth, go forth with Christ,", "With purpose not your own,", "Each vict'ry you shall gain", "Through Him your Lord alone:", "To guard you in fidelity", "His Spirit shall your strength'ner be."], ["Go forth, go forth with Christ,", "His Priesthood you shall share,", "Who bought us by His blood", "To be His servants here:", "Walk in the way your Savior trod,", "Go forth with Him, go forth with God."]]
      }, {
        "number": "378",
        "name": "Go, Preach My Gospel",
        "category": "Christian Church",
        "subcategory": "Ordination",
        "verses": [["\"Go, preach My gospel,\" saith the Lord;", "\"Bid the whole world My grace receive;", "He shall be saved who trusts My word,", "And they condemned who disbelieve."], ["\"I'll make your great commission known,", "And ye shall prove My gospel true", "By all the works that I have done,", "By all the wonders ye shall do."], ["\"Teach all the nations My commands;", "I'm with you till the world shall end;", "All power is vested in My hands;", "I can destroy, and I defend.\""], ["He spake, and light shone round His head;", "On a bright cloud to heaven He rode;", "They to the farthest nations spread", "The grace of their ascended Lord."]]
      }, {
        "number": "379",
        "name": "We Give This Child to You",
        "category": "Christian Church",
        "subcategory": "Child Dedication",
        "verses": [["We give this child to You,", "Our precious gift of love.", "Help us to lead each step aright", "With guidance from above."], ["O bless each child of Yours,", "And grant when they are grown,", "They will have learned to love Your way,", "And choose it for their own."], ["We give ourselves to You,", "And may Your Spirit fill", "Our hearts and home, that all we do", "Be subject to Your will."]]
      }, {
        "number": "380",
        "name": "Welcome, Day of Sweet Repose",
        "category": "Doctrines",
        "subcategory": "Sabbath",
        "verses": [["Welcome, day of sweet repose!", "Blessed be thy sacred hours!", "We would trust the One who knows", "All our weak and failing powers."], ["Welcome, day in Eden born!", "Holy rest for sinless man!", "Like the dawning of fair morn", "Come thy hours to us again."], ["Welcome, day blessed by our Lord!", "Toil shall cease and anxious care.", "Day commanded by His word,", "Day for song and praise and prayer."], ["Welcome, day our Savior kept!", "Keeping, wrought our righteousness,", "Day God bids us ne'er forget,", "Day of days His name to bless."]]
      }, {
        "number": "381",
        "name": "Holy Sabbath Day of Rest",
        "category": "Doctrines",
        "subcategory": "Sabbath",
        "verses": [["Holy Sabbath day of rest,", "By our Master richly blest,", "God created and divine,", "Set aside for holy time."], ["Seek not pleasures of this earth,", "With its folly, noise, and mirth,", "There are better things in store,", "Over on the other shore."], ["As the Sabbath draweth on", "Friday eve at set of sun,", "Christian household then should meet,", "Sing and pray at Jesus'feet."], ["Asking Him for saving grace,", "Also vict'ry in the race,", "And to help us by His pow'r,", "To keep holy every hour."]],
        "refrain": ["Yes, the holy Sabbath rest,", "By our God divinely blest,", "It to us a sign shall be", "Throughout all eternity."],
        "refrainFirst": false
      }, {
        "number": "382",
        "name": "O Day of Rest and Gladness",
        "category": "Doctrines",
        "subcategory": "Sabbath",
        "verses": [["O day of rest and gladness,", "O day of joy and light,", "O balm of care and sadness,", "most beautiful, most bright;", "on thee, the high and lowly,", "who bend before throne,", "sing, \"Holy, holy, holy,\"", "to the Eternal One."], ["Thou art a port protected", "from storms that round us rise;", "a garden intersected", "with streams of paradise;", "thou art a cooling fountain", "in life's dry, dreary sand;", "from thee, like Pisgah's mountain,", "we view our promised land."], ["A day of sweet reflection,", "thou art a day of love,", "A day to raise affection", "from earth to things above.", "New graces ever gaining", "from this our day of rest,", "We reach the rest remaining", "in mansions of the blessed."]]
      }, {
        "number": "383",
        "name": "O Day of Rest and Gladness",
        "category": "Doctrines",
        "subcategory": "Sabbath",
        "verses": [["O day of rest and gladness,", "O day of joy and light,", "O balm of care and sadness,", "Most beautiful, most bright,", "On thee the high and lowly", "Before th' eternal throne", "Sing, \"Holy, holy, holy,\"", "To the great Three in One."], ["Thou art a port protected", "From storms that round us rise;", "A garden intersected", "With streams of paradise;", "Thou art a cooling fountain", "In life's dry dreary sand;", "From Thee, like Pisgah's mountain,", "We view our promised land."], ["A day of sweet reflection", "Thou art, a day of love,", "A day to raise affection", "From earth to things above.", "New graces ever gaining", "From this our day of rest,", "We seek the rest remaining", "To mansions of the blest."]]
      }, {
        "number": "384",
        "name": "Safely Through Another Week",
        "category": "Doctrines",
        "subcategory": "Sabbath",
        "verses": [["Safely through another week", "God has brought us on our way;", "Let us now a blessing seek,", "Waiting in His courts today;", "Day of all the week the best,", "Emblem of eternal rest:", "Day of all the week the best,", "Emblem of eternal rest."], ["While we seek supplies of grace,", "Thro' the dear Redeemer's name,", "Show Thy reconciling face;", "Take away the sin and shame:", "From our worldly cares set free,", "May we rest this day in Thee:", "From our worldly cares set free,", "May we rest this day in Thee."], ["When the more shall bid us rise,", "May we feel Thy presence near;", "May Thy glory meet our eyes,", "While we in Thy house appear:", "Here afford us, Lord, a taste", "Of our everlasting feast:", "Here afford us, Lord, a taste", "Of our everlasting feast."], ["May the gospel's joyful sound", "Conquer sinners, comfort saints;", "Make the fruits of grace abound,", "Bring relief to all complaints:", "Thus may all our Sabbaths be,", "Till we we rise to reign with Thee.", "Thus may all our Sabbaths be,", "Till we we rise to reign with Thee."]]
      }, {
        "number": "385",
        "name": "Crowning Jewel of Creation",
        "category": "Doctrines",
        "subcategory": "Sabbath",
        "verses": [["Crowning jewel of creation,", "Blest and hallowed, sanctified;", "Time and changes all transcending,", "Shared forever, glorified."], ["Sin and sickness, prayer and weeping", "Cease at close of earthly days;", "But Thy Sabbath is eternal,", "Joyful thanks to Thee we raise!"], ["Teach us Lord, in storm or sunshine", "How to truly rest in Thee,", "May Thy Sabbath peace enfold us", "And our shelter ever be."]],
        "refrain": ["Blessed Sabbath made for man,", "Gift from the Creator's hand."],
        "refrainFirst": false
      }, {
        "number": "386",
        "name": "The Sacred Anthem",
        "category": "Doctrines",
        "subcategory": "Sabbath",
        "verses": [["The sacred anthem slowly rang", "Across the fields of praise,", "When earth's first Sabbath made complete", "All creatures and all days."], ["Walking with God, there,", "Woman and man together share", "The blessed Sabbath mood;", "And in that green and golden world", "Know all God's works are good."], ["But now in our diminished lives", "We sing a blemished song;", "The earth is worn and disarrayed", "And all our work goes wrong."], ["Still in our worship,", "Joining in praise and fellowship,", "By Sabbath radiance blessed,", "We put our doubt and fear away", "And rest within God's rest."], ["And arching over time and space", "The Lord of Sabbaths wills", "Renewal for the weary earth", "And healing for our ills."], ["Hearts will rejoice then;", "There will be no more weeping, when", "We know and shall be known.", "With hosts of the redeemed we'll sing", "Around God's shining throne."]]
      }, {
        "number": "387",
        "name": "Come, O Sabbath Day",
        "category": "Doctrines",
        "subcategory": "Sabbath",
        "verses": [["Peace and healing on thy wing;", "And to every troubled breast", "Speak of the divine behest:", "Thou shalt rest, Thou shalt rest!"], ["Earthly longings bid retire,", "Quench the passions' hurtful fire;", "To the wayward, sin oppressed,", "Bring thou thy divine behest:", "Thou shalt rest, Thou shalt rest!"], ["Wipe from every cheek the tear,", "Banish care and silence fear;", "All things working for the best,", "Teach us the divine behest:", "Thou shalt rest, Thou shalt rest!"]]
      }, {
        "number": "388",
        "name": "Don't Forget the Sabbath",
        "category": "Doctrines",
        "subcategory": "Sabbath",
        "verses": [["Don't forget the Sabbath,", "The Lord our God hath blest,", "Of all the week the brightest,", "Of all the week the best;", "It brings repose from labor,", "It tells of joy divine,", "Its beams of light descending,", "With heavenly beauty shine."], ["Keep the Sabbath holy,", "And worship Him today,", "Who said to His disciples,", "\"I am the living way;\"", "And if we meekly follow", "Our Savior here below,", "He'll give us of the fountain", "Whose streams eternal flow."], ["Day of sacred pleasure!", "Its golden hours we'll spend", "In thankful hymns to Jesus,", "The children's dearest Friend;", "O gentle loving, Savior,", "How good and kind Thou art,", "How precious is Thy promise", "To dwell in every heart!"]],
        "refrain": ["Welcome, welcome, ever welcome,", "Blessed Sabbath day,", "Welcome, welcome, ever welcome,", "Blessed Sabbath day."],
        "refrainFirst": false
      }, {
        "number": "389",
        "name": "Light of Light, Enlighten Me",
        "category": "Doctrines",
        "subcategory": "Sabbath",
        "verses": [["Light of light, enlighten me,", "Now anew the day is dawning;", "Sun of grace, the shadows flee;", "Brighten Thou my Sabbath morning;", "With Thy joyous sunshine blest,", "Happy is my day of rest."], ["Let me with my heart today,", "Holy, holy, holy, singing,", "Rapt awhile from earth away,", "All my soul to Thee up springing,", "Have a foretaste inward given", "How they worship Thee in heaven."], ["Hence all care, all vanity!", "For the day to God is holy;", "Come, Thou glorious Majesty,", "Deign to fill this temple lowly;", "Nought today my soul shall move,", "Simply resting in Thy love."]]
      }, {
        "number": "390",
        "name": "We Love Thy Sabbath, Lord",
        "category": "Doctrines",
        "subcategory": "Sabbath",
        "verses": [["We love Thy Sabbath, Lord,", "And worship at Thy will;", "Oh may these hours sweet peace afford", "And deeper faith instill."], ["Thine angels sang for joy", "Creations's work to see;", "We too, this day, would raise our hearts", "In grateful praise to Thee."], ["Praise for Thy wondrous love,", "That sealed this sacred day,", "A sign that all may understand", "We own Thy sovereign sway."], ["O great Creator King,", "Through Thy redeeming grace,", "Renew and sanctify our hearts", "That we may see Thy face."], ["And with the white-robed throng,", "Upon Mount Sion be,", "And joyful sing our Sabbath song", "Through all eternity."]]
      }, {
        "number": "391",
        "name": "Welcome, Welcome, Day of Rest",
        "category": "Doctrines",
        "subcategory": "Sabbath",
        "verses": [["To the world in kindness given;", "Welcome to this humble breast,", "As the beaming light from heaven."], ["Day of calm and sweet repose,", "Gently now thy moments run;", "Balm to soothe our cares and woes,", "Till our labor here is done."], ["Holy day that most we prize,", "Day of solemn praise and prayer,", "Day to make the simple wise,", "O, how great thy blessings are!"], ["Welcome, welcome, day of rest,", "With thy influence all divine;", "May thy hallowed hours be blessed", "to this waiting heart of mine."]]
      }, {
        "number": "392",
        "name": "Dear Lord, We Come at Set of Sun",
        "category": "Doctrines",
        "subcategory": "Sabbath",
        "verses": [["Dear Lord, we come at set of sun,", "And at Your feet we kneel", "To worship You, Creator, King,", "This day, Your sign and seal."], ["Our earthly tasks we lay aside,", "According to Your Word,", "To enter now Your holy rest,", "The Sabbath of the Lord."], ["Sweet Sabbath rest, your sacred hours", "Are as a golden chain", "That reaches back to Eden's gate", "And points us home again."], ["And when this earth shall be renewed,", "And sin and death destroyed,", "Shall all redeemed each Sabbath day", "Still meet to praise their God."]]
      }, {
        "number": "393",
        "name": "Lord of the Sabbath",
        "category": "Doctrines",
        "subcategory": "Sabbath",
        "verses": [["Lord of the Sabbath and its light,", "I hail Thy hallowed day of rest;", "It is my weary soul's delight,", "The solace of my careworn breast,", "The solace of my careworn breast."], ["O sacred day of peace and joy,", "Thy hours are ever dear to me;", "Ne'er may a sinful thought destroy", "The holy calm I find in thee,", "The holy calm I find in thee."], ["How sweetly now they glide along!", "How hallowed is the calm they yield!", "Transporting is their rapturous song,", "And heavenly visions seem revealed,", "And heavenly visions seem revealed."], ["O Jesus, let me ever hail", "Thy presence with the day of rest;", "Then will Thy servant never fail", "To deem Thy Sabbath doubly blest,", "To deem Thy Sabbath doubly blest."]]
      }, {
        "number": "394",
        "name": "Far From All Care",
        "category": "Doctrines",
        "subcategory": "Sabbath",
        "verses": [["Far from all care we hail the Sabbath morning;", "O'er waving fields and from the distant sea", "Swell notes of praise in harmony resounding", "As all creation turns her heart to Thee."], ["Though man alone, Lord, of Thy great creation", "Fails now to laud Thee for Thy love and power,", "Yet still a remnant love Thee and remember", "Thy holy law and each sweet Sabbath hour."], ["Lord of the Sabbath, Savior and Creator,", "Calm now the throbbings of each troubled breast.", "Speak to our hearts the peace of Thy commandments,", "Breathe on each soul fair Eden's hallowed ret."], ["Strong in Thy might and quiet in Thy meekness,", "May we Thine image bear from day to day.", "Then may we enter pearly gates eternal", "And sing redemption's song each Sabbath day."]]
      }, {
        "number": "395",
        "name": "As Birds Unto the Genial Homeland",
        "category": "Doctrines",
        "subcategory": "Sabbath",
        "verses": [["As birds unto the genial homeland fly,", "The winter's cold and low'ring skies to flee,", "So seeks my soul Thy gracious presence here", "And finds, O God, its rest and peace in Thee."], ["Here at Thy shrine we leave all vexing care,", "For get the disappointment, grief and tear,", "And on the wings of hopeful song and prayer", "We rise, and rising feel Thy Spirit here."], ["Bless all who spend this night in pain and woe,", "The burdened heart, the fainting, and distressed,", "Thy comfort send to darkened homes bereaved,", "Thy saving help to those by want oppressed."], ["Come, Sabbath joy, each trusting heart now fill,", "And blissful peace within our homes abide,", "May thankful praise each grateful heart now thrill,", "And to God's loving care their lives confide."]]
      }, {
        "number": "396",
        "name": "Lord God, Your Love Has Called Us Here",
        "category": "Doctrines",
        "subcategory": "Communion",
        "verses": [["Lord God, Your love has called us here,", "As we, by love, for love were made.", "Your living likeness still we bear", "Tho' marred, dishonored, disobeyed.", "We come, with all our heart and mind", "Your call to hear, Your love to find."], ["We come with self inflicted pains", "Of broken trust and chosen wrong,", "Half free, half bound by inner chains,", "By social forces swept along,", "By powers and systems close confined,", "Yet seeking hope for human kind."], ["Lord God, in Christ You call our name,", "And then receive us as Your own,", "Not thro' some merit, right, or claim,", "But by Your gracious love alone.", "We strain to glimpse Your mercy seat,", "And find You kneeling at our feet."], ["Then take the towel, and break the bread,", "And humble us, and call us friends.", "Suffer and serve till all are fed", "And show how grandly love intends", "To work till all creation sings,", "To fill all worlds, to crown all things."], ["Lord God, in Christ You set us free", "Your life to live, Your joy to share.", "Give us Your Spirit's liberty", "To turn from guilt and dull despair", "And offer all that faith can do,", "While love is making all things new."]]
      }, {
        "number": "397",
        "name": "An Upper Room",
        "category": "Doctrines",
        "subcategory": "Communion",
        "verses": [["An upper room did our Lord prepare", "For those loved until the end:", "And His disciples still gather there,", "To celebrate their risen Friend."], ["And after supper He washed their feet,", "For service, too, is sacrament.", "In Him our joy shall be made complete", "Sent out to serve, as He was sent."], ["A lasting gift Jesus gave His own:", "To share His bread, His loving cup.", "Whatever burdens may bow us down,", "He by His cross shall lift us up."], ["No end there is! we depart in peace.", "He loves beyond our uttermost:", "In every room in our Father's house,", "He will be there, as Lord and host."]]
      }, {
        "number": "398",
        "name": "Bread of the World",
        "category": "Doctrines",
        "subcategory": "Communion",
        "verses": [["Bread of the world in mercy broken,", "Wine of the soul in mercy shed,", "By whom the words of life are spoken,", "And in whose death our sins are dead;"], ["Look on the heart by sorrow broken,", "Look on the tears by sinners shed;", "And be Thy feast to us the token", "That by Thy grace our souls are fed. Amen."]]
      }, {
        "number": "399",
        "name": "Beneath the Forms of Outward Rite",
        "category": "Doctrines",
        "subcategory": "Communion",
        "verses": [["Beneath the forms of outward rite", "Thy supper, Lord, is spread", "In every quiet upper room", "Where fainting souls are fed."], ["The bread is always consecrate", "Which men divide with men;", "And every act of brotherhood", "Repeats Thy feast again."], ["The blessed cup is only passed", "True memory of Thee,", "When life anew pours out its wine", "With rich sufficiency."], ["O Master, through these symbols shared,", "Thine own dear self impart,", "That in our daily life may flame", "The passion of Thy heart."]]
      }, {
        "number": "400",
        "name": "I Come With Joy",
        "category": "Doctrines",
        "subcategory": "Communion",
        "verses": [["I come with joy to meet my Lord,", "Forgiven, loved and free,", "In awe and wonder to recall", "His life laid down for me,", "His life laid down for me."], ["I come with Christians far and near", "To find, as all are fed,", "Our true community of love", "In Christ's communion bread,", "In Christ's communion bread."], ["As Christ breaks bread for us to share", "Each proud division ends.", "That love that made us makes us one,", "And strangers now are friends,", "And strangers now are friends."], ["And thus with joy we meet our Lord.", "His presence always near,", "Is in such friendship better known:", "We see and praise Him here,", "We see and praise Him here."], ["Together met, together bound,", "We'll go our diff'rent ways,", "And as His people in the world,", "We'll live and speak His praise,", "We'll live and speak His praise."]]
      }, {
        "number": "401",
        "name": "In Imitation, Lord of Thee",
        "category": "Doctrines",
        "subcategory": "Communion",
        "verses": [["In imitation, Lord, of Thee,", "This solemn service we repeat;", "For Thine example full of grace,", "Has made this humble duty sweet."], ["Renew each sacred spark of love,", "And vitalize the holy flame;", "May union strong our hearts unite", "While this we do in Jesus' name."], ["Our great example Thou shalt be,", "In washing Thy disciples feet;", "And as we follow Thy command,", "Make Thou our fellowship complete."]]
      }, {
        "number": "402",
        "name": "By Christ Redeemed",
        "category": "Doctrines",
        "subcategory": "Communion",
        "verses": [["By Christ redeemed, In Christ restored,", "We keep the memory adored,", "And show the death of our dear Lord,", "Until He come."], ["His broken body in our stead is here,", "In his memorial bread;", "And so our feeble love is fed,", "Until He come."], ["His fearful drops of agony,", "His lifeblood shed for us we see;", "The wine shall tell the mystery,", "Until He come."], ["And thus that dark betrayal night,", "With the last advent we unite", "The shame, the glory, by this rite,", "Until He come."]]
      }, {
        "number": "403",
        "name": "Let Us Break Bread Together",
        "category": "Doctrines",
        "subcategory": "Communion",
        "verses": [["Let us break bread together on our knees,", "let us break bread together on our knees.", "When I fall on my knees with my face to the rising sun,", "O Lord, have mercy on me."], ["Let us drink wine together on our knees,", "let us drink wine together on our knees.", "When I fall on my knees with my face to the rising sun,", "O Lord, have mercy on me."], ["Let us praise God together on our knees,", "let us praise God together on our knees.", "When I fall on my knees with my face to the rising sun,", "O Lord, have mercy on me."]]
      }, {
        "number": "404",
        "name": "Now Let Us From This Table Rise",
        "category": "Doctrines",
        "subcategory": "Communion",
        "verses": [["Now let us from this table rise", "Renewed in body, mind, and soul;", "With Christ we die and live again,", "His selfless love has made us whole."], ["With minds alert, upheld by grace,", "To spread the word in speech and deed,", "We follow in the steps of Christ,", "At one with all in hope and need."], ["To fill each human house with love,", "It is the sacrament of care;", "The work that Christ began to do", "We humbly pledge ourselves to share."], ["Then grant us courage, Father God,", "To choose again the pilgrim way", "And help us to accept with joy", "The challenge of tomorrow's day."]]
      }, {
        "number": "405",
        "name": "O God, Unseen, Yet Ever Near",
        "category": "Doctrines",
        "subcategory": "Communion",
        "verses": [["O God, unseen, yet ever near,", "Reveal Thy presence now", "While we in love that hath no fear,", "Before Thy glory bow."], ["Here may Thy faithful people know", "The blessings of Thy love,", "The streams that thro' the dessert flow,", "The manna from above."], ["We come, obedient to Thy Word", "To feast on heav'nly food,", "Our meat, the body of our Lord,", "Our drink, His precious blood."], ["Thus may we all Thy words obey,", "For we, O God, are Thine,", "And go rejoicing on our way,", "Renewed by strength divine."]]
      }, {
        "number": "406",
        "name": "Love Consecrates the Humblest Act",
        "category": "Doctrines",
        "subcategory": "Communion",
        "verses": [["Love consecrates the humblest act", "And haloes mercy's deeds;", "It sheds a benediction sweet", "And hallows human needs."], ["When in the shadow of the cross", "Christ knelt and washed the feet", "Of His disciples, He gave us", "A sign of love complete."], ["Love serves and willing, stoops to serve;", "What Christ in love so true", "Has freely done for one and all,", "Let us now gladly do!"]]
      }, {
        "number": "407",
        "name": "Sent Forth by God's Blessing",
        "category": "Doctrines",
        "subcategory": "Communion",
        "verses": [["Sent forth by God's blessing,", "Our true faith confessing,", "The people of God from His dwelling take leave.", "The supper is ended.", "Oh, now be extended", "The fruits of this service in all who believe."], ["The seed of His teaching,", "Receptive souls reaching,", "Shall blossom in action for God and for all.", "His grace did invite us,", "His love shall unite us", "To work for God's kingdom and answer His call."], ["With praise and thanksgiving", "To God ever living,", "The tasks of our everyday life we will face.", "Our faith ever sharing,", "In love ever caring,", "Embracing His children of each tribe and race."], ["With Your feast You feed us,", "With Your light now lead us;", "Unite us as one in this life that we share.", "Then may all the living", "With praise and thanks giving", "Give honor to Christ and His name that we bear."]]
      }, {
        "number": "408",
        "name": "Lord, Enthroned in Heavenly Splendor",
        "category": "Doctrines",
        "subcategory": "Communion",
        "verses": [["Lord, enthroned in heav'nly splendor,", "First begotten from the dead,", "You alone, our strong defender,", "Lifting up Your people's head.", "Alleluia, Jesus, true and living Bread!"], ["Tho' the lowliest form now veil You", "As of old in Bethlehem,", "Here as there Your angels hail You", "Branch and flow'r of Jesse's stem.", "Alleluia, We in worship join with them."], ["Paschal Lamb, Your off'ring finished", "Once for all when You were slain,", "In its fullness undiminished", "Shall forever more remain.", "Alleluia, Cleansing souls from every stain."], ["Life imparting heav'nly manna,", "Stricken rock with streaming side,", "Heav'n and earth with loud hosanna", "Worship You the Lamb who died.", "Alleluia, Ris'n, ascended, glorified!"]]
      }, {
        "number": "409",
        "name": "Jesus Invites His Saints",
        "category": "Doctrines",
        "subcategory": "Communion",
        "verses": [["Jesus invites His saints", "To meet around His board,", "And sup in memory of the death", "And sufferings of their Lord."], ["We take the bread and wine", "As emblems of Thy death;", "Lord, raise our souls above the sign,", "To feast on Thee by faith."], ["Faith eats the bread of life,", "And drinks the living wine;", "It looks beyond this scene of strife", "Unites us to the Vine."], ["Soon shall the night be gone,", "Our Lord will come again;", "The marriage supper of the Lamb", "Will usher in His reign."]]
      }, {
        "number": "410",
        "name": "Thy Broken Body, Gracious Lord",
        "category": "Doctrines",
        "subcategory": "Communion",
        "verses": [["Thy broken body, gracious Lord,", "Is shadowed by this broken bread;", "The wine which in this cup is poured,", "Points to the blood which Thou hast shed."], ["And while we meet together thus,", "We show that we are one in Thee;", "Thy precious blood was shed for us,", "Thy death, O Lord, hast set us free!"], ["We have one hope that Thou wilt come,", "Thee in the air we wait to see;", "When Thou wilt give Thy saints a home,", "And we shall ever reign with Thee."]]
      }, {
        "number": "411",
        "name": "The Son of God Proclaim",
        "category": "Doctrines",
        "subcategory": "Communion",
        "verses": [["The Son of God proclaim,", "The Lord of time and space;", "The God who bade the light break forth", "Now shines in Jesus' face."], ["Behold His out stretched hands,", "Though all was in His power", "He took the towel and basin then,", "And serves us in this hour."], ["He, God's creative word,", "The church's Lord and head,", "Here bids us gather as His friends,", "And share His wine and bread."], ["The Lord of life and death", "With wondering praise we sing;", "We break the bread at His command,", "And name Him God and King."], ["We take this cup in hope:", "For He, who gladly bore", "The shameful cross, is ris'n again,", "And reigns forevermore."]]
      }, {
        "number": "412",
        "name": "Cover With His Life",
        "category": "Doctrines",
        "subcategory": "Law and Grace",
        "verses": [["Look upon Jesus, sinless is He;", "Father, impute His life unto me.", "My life of scarlet, my sin and woe,", "Cover with His life, whiter than snow."], ["Deep are the wounds transgression has made;", "Red are the stains; my soul is afraid.", "O to be covered, Jesus, with Thee,", "Safe from the law that now judgeth me!"], ["Longing the joy of pardon to know;", "Jesus holds out a robe white as snow;", "\"Lord, I accept it! Leaving my own,", "Gladly I wear Thy pure life alone.\""], ["Reconciled by His death for my sin,", "Justified by His life pure and clean,", "Sanctified by obeying His word,", "Glorified when returneth my Lord."]],
        "refrain": ["Cover with His life, whiter than snow;", "Fullness of His life then shall I know;", "My life of scarlet, my sin and woe,", "Cover with His life, whither than snow."],
        "refrainFirst": false
      }, {
        "number": "413",
        "name": "God Has Spoken by His Prophets",
        "category": "Doctrines",
        "subcategory": "Spiritual Gifts",
        "verses": [["God has spoken by His prophets,", "Spoken His unchanging Word,", "Each from age to age proclaiming", "God, the one, the righteous Lord.", "Mid the world's despair and turmoil,", "One firm anchor holding fast;", "God is King, His throne eternal,", "God the first, and God the last."], ["God has spoken by Christ Jesus,", "Christ, the everlasting Son,", "Brightness of the Father's glory,", "With the Father ever one;", "Spoken by the Word incarnate,", "God of God, ere time began,", "Light of light, to earth descending,", "Man, revealing God to man."], ["God yet speaks by His own Spirit", "Speaking to the hearts of men,", "In the age-long Word expounding", "God's own message, now as then;", "Through the rise and fall of nations", "One sure faith yet standing fast,", "God is King, His Word unchanging,", "God the first, and God the last."]]
      }, {
        "number": "414",
        "name": "Fruitful Trees, the Spirit's Sowing",
        "category": "Doctrines",
        "subcategory": "Spiritual Gifts",
        "verses": [["Fruitful trees, the Spirit's sowing,", "May we ripen and increase,", "Fruit to life eternal growing,", "Rich in love and joy and peace."], ["Laden branches freely bearing", "Gifts the Giver loves to bless;", "Here is fruit that grows by sharing,", "Patience, kindness, gentleness."], ["Rooted deep in Christ, our Master,", "Christ, our pattern and our goal,", "Teach us, as the years fly faster,", "Goodness, faith, and self control."], ["Fruitful trees, the Spirit's tending,", "May we grow till harvests cease;", "Till we taste, in life unending,", "Heaven's love and joy and peace."]]
      }, {
        "number": "415",
        "name": "Christ the Lord, All Power Possessing",
        "category": "Doctrines",
        "subcategory": "Judgment",
        "verses": [["Christ, the Lord, all power possessing,", "Parting, mounted heaven's height,", "Gracious hands outstretched in blessing,", "Clouds received His from their sight.", "Christ ascended, Christ ascended,", "Christ ascended on the clouds.", "(on the clouds)", "Christ ascended on the clouds."], ["Daniel views earth's judgment hour,", "Angels gathering open books.", "God enthroned inflaming power", "For His Son's arrival looks.", "Christ approaches, Christ approaches,", "Christ approaches on the clouds.", "(on the clouds)", "Christ approaches on the clouds."], ["Revelation's word fulfilling,", "Trumpet, voices pierce the air.", "Saint and sinner fainting, thrilling,", "Every eye behold Him there.", "Christ is coming, Christ is coming,", "Christ is coming on the clouds.", "(on the clouds)", "Christ is coming on the clouds."]]
      }, {
        "number": "416",
        "name": "The Judgment Has Set",
        "category": "Doctrines",
        "subcategory": "Judgment",
        "verses": [["The judgment has set, the books have been opened;", "How shall we stand in that great day,", "When every thought, and word, and action,", "God, the righteous Judge, shall weigh?"], ["The work is begun with those who are sleeping,", "Soon will the living here be tried,", "Out of the books of God's remembrance,", "His decision to abide."], ["O, how shall we stand that moment of searching,", "When all our sins those books reveal?", "When from that court, each case decided,", "Shall be granted no appeal?"]],
        "refrain": ["How shall we stand in that great day?", "How shall we stand in that great day?", "Shall we be found before Him wanting?", "Or with our sins all washed away?"],
        "refrainFirst": false
      }, {
        "number": "417",
        "name": "O Solemn Thought",
        "category": "Doctrines",
        "subcategory": "Judgment",
        "verses": [["O solemn thought and can it be", "The hour o judgment now is come", "Which soon must fix our destiny,", "And seal the sinner's fearful down?", "Yes, it is so; the judgment hour", "Is swiftly hastening to its close;", "Then will the Judge, in mighty power,", "Descend in vengeance on His foes."], ["He who came down to earth to die", "An offering for the sins of men,", "And then ascended upon on high,", "And will e'er long return again,", "Is standing now before the ark,", "And mercy seat and cherubim,", "To plead His blood for saints, and make", "The last remembrance of their sin."], ["The solemn moment is at hand", "When we who have His name confessed,", "Each in his lot must singly stand", "And pass the final, searching test.", "Jesus! we hope in Thee alone;", "In mercy now upon us look,", "Confess our names before the throne,", "And blot our sins from out Thy book."], ["O blessed Savior! may we feel", "The full importance of this hour.", "Inspire our hearts with holy zeal,", "And aid us by Thy Spirit's power,", "That we may in Thy strength be strong,", "And brave the conflict valiantly;", "Then, on Mount Zion, join the song,", "And swell the notes of victory."]]
      }, {
        "number": "418",
        "name": "Day of Judgment, Day of Wonders!",
        "category": "Doctrines",
        "subcategory": "Resurrection of the Saints",
        "verses": [["Day of judgement, day of wonders!", "Hark the trumpet's awful sound,", "Louder than a thousand thunders,", "Shakes the vast creation round!", "How the summons", "Will the sinner's heart confound!"], ["See the Lord in the glory nearing,", "Clothed in majesty divine,", "You who long for His appearing,", "Then shall say, \"This God is mine!\"", "Gracious Savior, Gracious Savior,", "Own me in that day as Thine."], ["At His call the dead awaken,", "Rise to life form earth and sea!", "All the powers of nature shaken", "By His looks prepare to flee.", "Careless sinner, Careless sinner,", "What will then become of thee?"], ["But to those who have confessed", "Loved and served the Lord below,", "He will say, \"Come near, ye blessed,", "See the kingdom I bestow;", "You forever, You forever", "Shall my love and glory know.\""]]
      }, {
        "number": "419",
        "name": "Soon Shall the Trump of God",
        "category": "Doctrines",
        "subcategory": "Resurrection of the Saints",
        "verses": [["Soon shall the trump of God", "Give out the welcome sound,", "That shakes death's silent chamber walls,", "And breaks the turf sealed ground."], ["You dwellers in the dust,", "Awake, come forth, and sing;", "Sharp has your frost of winter been,", "But bright shall be your spring."], ["'Twas sown in weakness here;", "'Twill then be raised in power;", "That which was sown in earthly seed", "Shall rise a heav'nly flower."]]
      }, {
        "number": "420",
        "name": "Jerusalem, My Happy Home",
        "category": "Doctrines",
        "subcategory": "Eternal Life",
        "verses": [["Jerusalem, my happy home,", "O how I long for thee!", "When will my sorrows have an end,", "The joys when shall I see?"], ["The walls are all of precious stone,", "Most glorious to behold;", "Thy gates are richly set with pearl,", "Thy streets are paved with gold."], ["Thy garden and thy pleasant walks", "My study long have been;", "Such dazzling views by human sight", "Have never yet been seen."], ["Lord, help us by Thy mighty grace,", "To keep in view the prize,", "Till Thou dost come to take us home", "To that blessed paradise."]]
      }, {
        "number": "421",
        "name": "For All the Saints",
        "category": "Doctrines",
        "subcategory": "Eternal Life",
        "verses": [["For all the saints", "who from their labors rest,", "Who thee by faith", "before the world confessed.", "Thy name, O Jesus,", "be forever blest.", "Alleluia!", "Alleluia!"], ["Thou wast their Rock,", "their Fortress and their Might;", "Thou, Lord, their captain", "in the well-fought fight;", "Thou, in the darkness drear,", "their one true light.", "Alleluia!", "Alleluia!"], ["O may Thy soldiers,", "faithful, true, and bold,", "Fight as the saints", "who nobly fought of old,", "And win with them", "the victor's crown of gold.", "Alleluia!", "Alleluia!"], ["And when the strife is fierce,", "the warfare long,", "Steals on the ear", "the distant triumph song,", "And hearts are brave again,", "and arms are strong.", "Alleluia!", "Alleluia!"], ["From earth's wide bounds,", "from ocean's farthest coast,", "Thro' gates of pearl", "streams in the countless host,", "Singing to Father,", "Son, and Holy Ghost,", "Alleluia!", "Alleluia!"]]
      }, {
        "number": "422",
        "name": "Marching to Zion",
        "category": "Doctrines",
        "subcategory": "Eternal Life",
        "verses": [["Come, we that love the Lord,", "And let our joys be known;", "Join in a song with sweet accord,", "Join in a song with sweet accord,", "And thus surround the throne,", "And thus surround the throne."], ["Let those refuse to sing,", "Who never knew our God;", "But children of the heavenly King,", "But children of the heavenly King,", "May speak their joys abroad,", "May speak their joys abroad."], ["The hill of Zion yields", "A thousand sacred sweets,", "Before we reach the heavenly fields,", "Before we reach the heavenly fields,", "Or walk the golden streets,", "Or walk the golden streets."], ["Then let our songs abound,", "And every tear be dry;", "We're marching through Immanuel's ground", "We're marching through Immanuel's ground", "To fairer worlds on high,", "To fairer worlds on high."]],
        "refrain": ["We're marching to Zion,", "Beautiful, beautiful Zion;", "We're marching upward to Zion,", "The beautiful city of God."],
        "refrainFirst": false
      }, {
        "number": "423",
        "name": "Glorious Things of Thee Are Spoken",
        "category": "Doctrines",
        "subcategory": "Eternal Life",
        "verses": [["Glorious things of thee are spoken,", "Zion, city of our God;", "He whose word cannot be broken", "Formed thee for His own abode;", "On the Rock of Ages founded,", "What can shake thy sure repose?", "With salvation's wall surrounded,", "Thou mayest smile at all thy foes."], ["See the streams of living waters", "Springing from eternal love,", "Well supply thy sons and daughters,", "And all fear and want remove;", "Who can faint when such a river", "Ever flows their thirst to assuage?", "Grace, which, like the Lord, the Giver,", "Never fails from age to age."], ["Round each habitation hovering,", "See the cloud and fire appear", "For a glory and a covering,", "Showing that the Lord is near;", "Blest inhabitants of Zion,", "Washed in the Redeemer's blood;", "Jesus, whom their souls rely on,", "Makes them kings and priests to God."], ["Savior, if of Zion's city", "I, through grace, a member am,", "Let the world deride or pity,", "I will glory in Thy name;", "Fading is the worldling's pleasure,", "All his boasted pomp and show;", "Solid joys and lasting treasure", "None but Zion's children know."]]
      }, {
        "number": "424",
        "name": "For Thee, O Dear, Dear Country",
        "category": "Doctrines",
        "subcategory": "Eternal Life",
        "verses": [["For thee, O dear country,", "Mine eyes their vigils keep;", "For very love beholding", "Thy holy name, they weep."], ["O one, O only mansion!", "O Paradise of joy!", "Where tears are ever banish'd", "And smiles have no alloy;"], ["With jasper glow thy burwarks,", "Thy streets with emeralds blaze;", "The sardius and the topaz", "Unite in thee their rays;"], ["The cross is all thy splendor,", "The Crucified thy praise;", "His laud and benediction", "Thy ransom'd saints shall raise;"], ["O sweet and blessed country,", "The home of God's elect!", "O sweet and blessed country", "That eager hearts expect!"]]
      }, {
        "number": "425",
        "name": "Holy, Holy, Is What the Angels Sing",
        "category": "Doctrines",
        "subcategory": "Eternal Life",
        "verses": [["There is singing up in heaven", "such as we have never known,", "Where the angels sing the praises", "of the Lamb upon the throne;", "Their sweet harps are ever tuneful", "and their voices are always clear,", "O that we might be more like them", "while we serve the Master here!"], ["But I hear another anthem,", "blending voices clear and strong,", "\"Unto Him who hath redeemed us", "and hath bought us,\" is the song;", "We have come thro' tribulations", "to this land so fair and bright,", "In the fountain freely flowing", "He hath made our garments white."], ["Then the angels stand and listen,", "for they cannot join that song,", "Like the sound of many waters,", "by that happy, blood-washed throng;", "For they sing about great trials,", "battles fought and vict'ries won,", "And they praised the great Redeemer,", "who hath said to them, \"Well done.\""], ["So, although I'm not an angel,", "yet I know that over there", "I will join a blessed refrain", "that the angels cannot share;", "I will sing about my Savior,", "who upon dark Calvary", "Freely pardoned my transgressions,", "died to set the sinner free."]],
        "refrain": ["Holy, holy, is what the angels sing,", "And I expect to help them make", "the courts of heaven ring;", "But when I sing redemption's story,", "they will fold their wings,", "For angels never felt the joys", "that our salvation brings."],
        "refrainFirst": false
      }, {
        "number": "426",
        "name": "I Shall See the King",
        "category": "Doctrines",
        "subcategory": "Eternal Life",
        "verses": [["I shall see the King where the angels sing,", "I shall see the King some day, in the better land,", "On that golden strand,", "And with Him shall ever stay."], ["In the land of song, in the glory throng,", "Where there never comes a night,", "With my Lord once slain I shall ever reign", "In the glory land of light."], ["I shall see the King, all my tributes bring,", "And shall look upon His face;", "Then my song shall be how He ransomed me", "And has kept me by His grace."]],
        "refrain": ["In His glory, I shall see the King,", "And forever endless praises sing;", "'Twas on Calvary Jesus died for me;", "I shall see the King someday."],
        "refrainFirst": false
      }, {
        "number": "427",
        "name": "No Night There",
        "category": "Doctrines",
        "subcategory": "Eternal Life",
        "verses": [["In the land of fadeless day", "Lies \"the city four-square;\"", "It shall never pass away,", "And there is \"no night there.\""], ["All the gates of pearl are made,", "In \"the city four-square;\"", "All the streets with gold are laid,", "And there is \"no night there.\""], ["And the gates shall never close", "To \"the city four-square;\"", "There life's crystal river flows,", "And there is \"no night there.\""], ["There they need no sunshine bright,", "In \"that city four-square;\"", "For the Lamb is all the light,", "And there is \"no night there.\""]],
        "refrain": ["God shall \"wipe away all tears;\"", "There's no death, no pain, nor fears;", "And they count not time by years,", "For there is \"no night there.\""],
        "refrainFirst": false
      }, {
        "number": "428",
        "name": "Sweet By and By",
        "category": "Doctrines",
        "subcategory": "Eternal Life",
        "verses": [["There's a land that is fairer than day,", "And by faith we can see it afar;", "For the Father waits over the way,", "To prepare us a dwelling place there."], ["We shall sing on that beautiful shore", "The melodious songs of the blest,", "And our spirits shall sorrow no more,", "Not a sigh for the blessings of rest."], ["To our bountiful Father above,", "We will offer a tribute of praise,", "For the glorious gift of His love,", "And the blessings that hallow our days."]],
        "refrain": ["In the sweet by and by,", "We shall meet on that beautiful shore;", "In the sweet by and by,", "We shall meet on that beautiful shore;"],
        "refrainFirst": false
      }, {
        "number": "429",
        "name": "Jerusalem the Golden",
        "category": "Doctrines",
        "subcategory": "Eternal Life",
        "verses": [["Jerusalem the golden,", "With milk and honey blest,", "Beneath thy contemplation", "Sink heart and voice oppressed.", "I know not, O I know not", "What holy joys are there;", "What radiancy of glory,", "What bliss beyond compare."], ["They stand, those hall of Zion,", "All jubilant with song,", "And bright with many an angel,", "And all the martyr throng.", "The Prince is ever in them,", "The daylight is serene;", "The pastures of the blessed", "Are deck in glorious sheen."], ["There is the throne of David,", "And there, from care released,", "The shout of them that triumph,", "The song of them that feast;", "And they who, with their Leader,", "Have conquered in the fight,", "Forever and forever", "Are clad in robes of white."], ["O sweet and blessed country,", "The home of God's elect!", "O sweet and blessed country,", "That eager hearts expect!", "Jesus, in mercy bring us", "To that dear land of rest;", "Who art, with God the Father,", "And Spirit, ever blest."]]
      }, {
        "number": "430",
        "name": "Joy By and By",
        "category": "Doctrines",
        "subcategory": "Eternal Life",
        "verses": [["O there'll be joy when the work is done,", "Joy when the reapers gather home,", "Bringing the sheaves at set of sun", "To the New Jerusalem."], ["Sweet are the songs that we hope to sing,", "Grateful the thanks our hearts shall bring,", "Praising forever Christ our King", "In the New Jerusalem."], ["Pure are the joys that await us there,", "Many the golden mansions fair;", "Jesus Himself doth them prepare,", "In the New Jerusalem."]],
        "refrain": ["Joy, joy, joy, there'll be joy by and by,", "Joy, joy, joy, where the joys never die;", "Joy, joy, joy, for the day draweth nigh", "When the workers gather home."],
        "refrainFirst": false
      }, {
        "number": "431",
        "name": "Over Yonder",
        "category": "Doctrines",
        "subcategory": "Eternal Life",
        "verses": [["Come let us sing of homeland,", "Down by the crystal sea;", "Wonderful land where Jesus", "Buildeth a mansion for me."], ["Water of life there floweth,", "Fruit in abundant store;", "Citizens of that country", "Hunger and thirst never more."], ["Come go with me to homeland,", "Jesus invites you there;", "Help spread the invitation,", "Tell it to men everywhere."]],
        "refrain": ["Over yonder, down by the crystal sea,", "(down by the crystal sea,)", "Over yonder, there's where I long to be,", "(There's where I long to be,)", "No more sorrow, toil, grief, nor care,", "In the homeland bright and fair,", "Over, over there.", "(Over there.)"],
        "refrainFirst": false
      }, {
        "number": "432",
        "name": "Shall We Gather at the River",
        "category": "Doctrines",
        "subcategory": "Eternal Life",
        "verses": [["Shall we gather at the river,", "where bright angel feet have trod,", "with its crystal tide forever", "flowing by the throne of God."], ["On the margin of the river", "washing up its silver spray,", "we will walk and worship ever,", "all the happy golden day."], ["Ere we reach the shining river,", "lay we ev'ry burden down;", "grace our spirits will deliver,", "and provide a robe and crown."], ["Soon we'll reach the shining river,", "soon our pilgrimage will cease;", "soon our happy hearts will quiver", "with the melody of peace."]],
        "refrain": ["Yes, we'll gather at the river,", "the beautiful, the beautiful river;", "gather with the sains at the river", "that flows by the throne of God."],
        "refrainFirst": false
      }, {
        "number": "433",
        "name": "Ten Thousand Times Ten Thousand",
        "category": "Doctrines",
        "subcategory": "Eternal Life",
        "verses": [["Ten thousand times ten thousand,", "In sparkling raiment bright,", "The armies of the ransomed saints", "Throng up the steeps of light.", "'Tis finished, all is finished,", "Their fight with death and sin.", "Fling open wide the golden gates,", "And let the victors in."], ["What rush of hallelujahs", "Fills all the earth and sky!", "The ringing of a thousand harps", "Proclaims the triumph high.", "O day for which creation", "And all its tribes were made!", "O joy, for all its former woes", "A thousandfold repaid!"], ["O then what raptured greetings", "On Canaan's happy shore!", "What knitting severed friendship where", "Death partings are no more!", "Then eyes with joy shall sparkle,", "That brimmed with tears of late;", "Orphans no longer fatherless,", "Nor widows desolate."], ["Bring near Thy great salvation,", "Thou Lamb for sinners slain,", "Fill up the roll of Thine elect,", "Then take Thy power and reign!", "Appear, Desire of nations,", "Thine exiles long for home;", "Show in the heavens Thy promised sign;", "Thou Prince and Savior come!"]]
      }, {
        "number": "434",
        "name": "We Speak of the Realms",
        "category": "Doctrines",
        "subcategory": "Eternal Life",
        "verses": [["We speak of the realms of the blest,", "That country so bright and so fair,", "And oft are its glories confessed-", "But what must it be to be there!", "We speak of its pathway of gold-", "Its walls decked with jewels so rare,", "Its wonders and pleasures untold-", "But what must it be to be there!"], ["We speak of its freedom of sin,", "From sorrow, temptation and care,", "From trials without and within---", "But what must it be to be there!", "We speak of its service of love,", "Of the robes which glorified wear,", "Of the church of the First Born above-", "But what must it be to be there!"], ["Our mourning is all at an end,", "When, raised by the life-giving word,", "We see the new city descend,", "Adorned as a bride for her Lord;", "The city so holy and clean,", "No sorrow can breathe in the air;", "No gloom of affliction or sin,", "No shadow of evil, is there."], ["Do Thou, midst temptation and woe,", "For heaven my spirit prepare;", "And shortly I also so shall know", "And feel what it is to be there.", "Then o'er the bright fields we shall roam,", "In glory celestial and fair,", "With saints and with angels at home,", "And Jesus Himself will be there."]]
      }, {
        "number": "435",
        "name": "The Glory Song",
        "category": "Doctrines",
        "subcategory": "Eternal Life",
        "verses": [["When all my labors and trails are o'er,", "And I am safe on that beautiful shore,", "Just to be near the Lord I adore,", "Will through the ages be glory for me."], ["When, by the gift of His infinite grace,", "I am accorded in heaven a place,", "Just to be there and to look on His face,", "Will through the ages be glory for me."], ["Friends will be there I have loved long ago;", "Joy like a river around me will flow,", "Yet, just a smile form my Savior, I know,", "Will through the ages be glory for me."]],
        "refrain": ["O that will be", "(O that will)", "Glory for me, Glory for me,", "(Be glory for me, Glory for me,)", "Glory for me; When by His grace", "(Glory for me;)", "I shall look on His face,", "That will be glory, be glory for me."],
        "refrainFirst": false
      }, {
        "number": "436",
        "name": "The Homeland",
        "category": "Doctrines",
        "subcategory": "Eternal Life",
        "verses": [["The homeland! O the homeland!", "The land of the free born!", "There's no night in the homeland,", "But aye the fadeless morn;", "I'm sighing for homeland,", "My heart is aching here;", "There is no pain in the homeland", "To which I'm drawing near;", "There is no pain in the homeland", "To which I'm drawing near."], ["My Lord is in the homeland,", "With angels bright and fair;", "There's no sin in the homeland,", "And no temptation there;", "The music of the homeland", "Is ringing in my ears;", "And when I think of the homeland", "My eyes are filled with tears;", "And when I think of the homeland", "My ears are filled with tears."], ["The dwellers in the homeland", "Are beckoning me to come,", "Where neither death nor sorrow", "Invades their holy name;", "O dear, dear native country!", "O rest and peace above!", "Christ bring us all to the homeland", "Of Thy redeeming love;", "Christ bring us all to the homeland", "Of Thy redeeming love"]]
      }, {
        "number": "437",
        "name": "I'm Going Home",
        "category": "Doctrines",
        "subcategory": "Eternal Life",
        "verses": [["My heav'nly home is bright and fair,", "Nor pain nor death can enter there;", "It's glitt'ring tow'rs the sun outshine,", "That heav'nly mansion shall me mine."], ["My Father's house is built on high,", "Far, far above the starry sky;", "When from this earthly prison free,", "That heav'nly mansion mine shall be."], ["Let others seek a home below,", "Which flames devour or waves oe'r flow;", "Be mine a happier lot to own", "A heav'nly mansion near the throne."]],
        "refrain": ["I'm going home, I'm going home,", "I'm going home to die no more;", "To die no more; to die no more-", "I'm going home to die no more."],
        "refrainFirst": false
      }, {
        "number": "438",
        "name": "You Will See Your Lord A-Coming",
        "category": "Early Advent",
        "verses": [["You will see your Lord a-coming,", "You will see your Lord a-coming,", "You will see your Lord a-coming", "In a few more days."], ["Gabriel sounds his mighty trumpet,", "Gabriel sounds his mighty trumpet,", "Gabriel sounds his mighty trumpet", "In a few more days."], ["You will see the saints arising,", "You will see the saints arising,", "You will see the saints arising", "In a few more days."], ["Angels bear them to the Savior,", "Angels bear them to the Savior,", "Angels bear them to the Savior", "In a few more days."], ["Then we'll shout, our suff'ring over,", "Then we'll shout, our suff'ring over,", "Then we'll shout, our suff'ring over", "In a few more days."]],
        "refrain": ["Hear the band of music,", "Hear the band of music,", "Hear the band of music", "which is sounding thro' the air."],
        "refrainFirst": false
      }, {
        "number": "439",
        "name": "How Far From Home?",
        "category": "Early Advent",
        "verses": [["How far from? I asked, as on", "I bent my steps-the watchman spake:", "\"the long, dark night is almost gone,", "The morning soon will break.", "Then weep no more, but speed thy flight,", "With Hope's bright star guiding ray,", "Till thou shalt reach the realms of light,", "In everlasting days.\""], ["I asked the warrior on the field;", "This was his soul-inspiring song:", "\"With courage, bold, the sword I'll wield,", "The battle is not long.", "Then weep no more, but well endure", "The conflict, till thy work is done;", "For this we know, the prize is sure,", "When victory is won.\""], ["I asked again; earth, sea, and sun", "Seemed, with one voice, to make reply:", "\"Time's wasting sands are nearly run,", "Eternity is nigh.", "Then weep no more-with warning tones,", "Portentous signs are thickening round,", "The whole creation, waiting, groans,", "To hear the trumpet sound.\""], ["Not far from home! O blessed thought!", "The traveler's lonely heart to cheer;", "Which oft a healing balm has brought,", "And dried the mourner's tear.", "Then weep no more, since we shall meet", "Where weary footsteps never roam-", "Our trails past, our joys complete,", "Safe in our Father's home."]]
      }, {
        "number": "440",
        "name": "How Cheering Is the Christian's Hope",
        "category": "Early Advent",
        "verses": [["How cheering is the Christian's hope,", "While toiling here below!", "It bouys us up while this passing through", "This wilderness of woe,"], ["It bouys us up while this passing through", "This wilderness of woe,", "It bouys us up while passing through", "This wilderness of woe."], ["It points us to a land of rest,", "Where saints with Christ will reign;", "Where we shall meet the loved of earth,", "And never part again."], ["Fly, lingering moments, fly, O, fly,", "Dear Savior, quickly come!", "We long to see Thee as Thou art,", "And reach that blissful home."]]
      }, {
        "number": "441",
        "name": "I Saw One Weary",
        "category": "Early Advent",
        "verses": [["I saw one weary, sad, and torn,", "With eager steps press on the way,", "Who long the hallowed cross had born,", "Still looking for the promised day;", "While many a line of grief and care,", "Upon his brow was furrowed there;", "I asked what buoyed his spirits up,", "\"O this\" said he-\"the blessed hope.\""], ["And one I saw, with sword and shield,", "Who boldly braved the world's cold frown,", "And fought, unyielding, on the field,", "To win an everlasting crown.", "Though worn with toil, oppressed by foes,", "No murmur from his heart arose;", "I asked what buoyed his spirits up,", "\"O this!\" said he-\"the blessed hope.\""], ["And there was one who left behind", "The cherished friends of early years,", "And honor, pleasure, wealth re-signed,", "To tread the path bedewed with tears.", "Through trials deep and conflict sore,", "Yet still a smile of joy he wore;", "O! what can bouy the spirit up?", "'Tis this alone-the blessed hope."], ["While pilgrims here we journey on", "In this dark vale of sin and gloom,", "Through tribulation, hate, and scorn,", "Or through the portals of the tomb,", "Till our returning King shall come", "To tkae His exile captives home,", "O! what can bouy the spirits up?", "'Tis this alone-- the blessed hope."]]
      }, {
        "number": "442",
        "name": "How Sweet Are the Tidings",
        "category": "Early Advent",
        "verses": [["How sweet are the tidings that greet the pilgrim's ear,", "As he wanders in exile from home!", "Soon, soon will the Savior in glory appear,", "And soon will the kingdom come."], ["The mossy old graves where the pilgrims sleep", "Shall be open as wide as before,", "And the millions that sleep in the mighty deep", "Shall live on this earth once more."], ["There we'll meet ne'er to part in our happy", "Eden home, Sweet songs of redemption we'll sing;", "From the north, from the south, all the ransomed shall come,", "And worship our heavenly King."], ["Hallelujah, Amen! Hallelujah again!", "Soon, if faithful, we all shall be there;", "O, be watchful, be hopeful, be joyful till then,", "And a crown of bright glory we'll wear."]],
        "refrain": ["He's coming, coming, coming soon I know,", "Coming back to this earth again;", "And the weary pilgrims will to glory go,", "When the Savior comes to reign."],
        "refrainFirst": false
      }, {
        "number": "443",
        "name": "There'll Be No Sorrow There",
        "category": "Early Advent",
        "verses": [["There'll be no night in heaven,", "In that blest world above;", "No anxious toil, no weary hours;", "For labor there is love."], ["There'll be no grief in heaven;", "For life is one glad day,", "And tears are those of former things", "Which all have passed away."], ["There'll be no sin in heaven;", "Behold that blessed throng,", "All holy in there spotless robes,", "All holy in their song."]],
        "refrain": ["There'll be no sorrow there,", "There'll be no sorrow there;", "In heaven above, where all is love,", "There'll be no sorrow there."],
        "refrainFirst": false
      }, {
        "number": "444",
        "name": "I'm a Pilgrim",
        "category": "Early Advent",
        "verses": [["I'm a pilgrim, and I'm a stranger;", "I can tarry, I can tarry but a night;", "Do not detain me, for I am going", "To where the fountains are ever flowing."], ["There the glory is ever shining!", "O, my longing heart, my longing heart is there;", "Here in this country so dark and dreary,", "I long have wandered forlorn and weary."], ["There's the city to which I journey;", "My Redeemer, my Redeemer is its light!", "There is no sorrow, nor any sighing,", "Nor any tears there, or any dying."]],
        "refrain": ["I'm a pilgrim, and I'm a stranger;", "I can tarry, I can tarry but a night."],
        "refrainFirst": false
      }, {
        "number": "445",
        "name": "I'm But a Stranger Here",
        "category": "Early Advent",
        "verses": [["I'm but a stranger here,", "Heav'n is my home;", "Earth is a desert drear,", "Heav'n is my home.", "Danger and sorrow stand", "Round me on every hand;", "Heav'n is my fatherland,", "Heav'n is my home."], ["What though the tempest rage,", "Heav'n is my home;", "Short is my pilgrimage,", "Heav'n is my home;", "And time's wild wintry blast", "Soon shall be over past;", "I shall reach home at last,", "Heav'n is my home."], ["There at my Savior's side", "Heav'n is my home;", "I shall be glorified,", "Heav'n is my home;", "There are the good and blest,", "Those I love most and best;", "And there I, too, shall rest,", "Heav'n is my home."]]
      }, {
        "number": "446",
        "name": "Lo, What a Glorious Sight Appears",
        "category": "Early Advent",
        "verses": [["Lo, what a glorious sight appears", "To our believing eyes!", "The earth and seas are passed away", "And the old rolling skies.", "And the old rolling skies,", "And the old rolling skies;", "The earth and seas are passed away,", "And the old rolling skies."], ["Attending angels shout for joy", "And the bright armies sing-", "Mortals! Behold the sacred seat", "Of your descending King.", "Of your descending King,", "Of your descending King;", "Mortals! Behold the sacred seat", "Of your descending King."], ["His own soft hand shall wipe the tears", "From every weeping eye;", "And pains, and groans, and griefs, and fears,", "And death itself shall die!", "And death itself shall die,", "And death itself shall die;", "And pains, and groans, and griefs, and fears,", "And death itself shall die."], ["How long, dear Savior! Oh, how long", "Shall this bright hour delay?", "Fly swifter round, ye wheels of time!", "And bring the welcome day.", "And bring the welcome day,", "And bring the welcome day;", "Fly swifter round, ye wheels of time!", "And bring the welcome day."]],
        "refrain": ["O that will be joyful, joyful, joyful!", "O that will be joyful", "When we meet to part no more!", "When we meet to part no more", "On Canaan's happy shore;", "'Tis there we'll meet at Jesus' feet,", "When we meet to part no more!"],
        "refrainFirst": false
      }, {
        "number": "447",
        "name": "Long Upon the Mountains",
        "category": "Early Advent",
        "verses": [["Long upon the mountains weary,", "Have the scattered flocks been torn;", "Dark the dessert paths, and dreary;", "Grievous trials have they borne.", "Now the gathering call is sounding,", "Solemn in its warning voice;", "Union, faith, and love, abounding,", "Bid the little flock rejoice."], ["Now the light of truth they're seeking,", "In its onward track pursue;", "All the ten commandments keeping,", "They are holy, just, and true.", "On the words of lire they're feeding,", "Precious to their taste so sweet;", "All their Master's percepts heeding,", "Bowing humbly to His feet."], ["In that light of light and beauty,", "In that golden city fair,", "Soon its pearly gates they'll enter,", "And of all its glories share.", "There, divine the soul's expansions;", "Free from sin, and death, and pain;", "Tear will never dim those mansions", "Where the souls immortal reign."], ["Soon He comes! With clouds descending;", "All His saints, entombed arise;", "The redeemed, in anthems blending,", "Shout their vict'ry thro' the skies.", "O, we long for Thine appearing;", "Come, O Savior, quickly come!", "Blessed hope! Our spirits cheering,", "Take thy ransomed children home."]]
      }, {
        "number": "448",
        "name": "O, When Shall I See Jesus",
        "category": "Early Advent",
        "verses": [["O, when shall I see Jesus and reign with Him above,", "And shall hear the trumpet sound in that morning?", "And from the flowing fountain drink everlasting love,", "And shall hear the trumpet sound in that morning."], ["Gird on the gospel armor of faith and hope and love,", "And you'll hear the trumpet sound in that morning.", "And when the combat's ended He'll carry you above,", "And you'll hear the trumpet sound in that morning."], ["Our ears shall hear with transport the host of heaven sing,", "And shall hear the trumpet sound in that morning.", "Our tongues shall chant the glories of our immortal King.", "And shall hear the trumpet sound in that morning,"]],
        "refrain": ["O, shout, glory!", "For I shall mount above the skies,", "When I hear the trumpet sound in that morning."],
        "refrainFirst": false
      }, {
        "number": "449",
        "name": "Never Part Again",
        "category": "Early Advent",
        "verses": [["There is a land of pure delight,", "Where bliss eternal reigns,", "Infinite day excludes the night", "And pleasures banish pain."], ["There everlasting spring abides,", "And never with'ring flowers,", "And but a little space divides", "This heav'nly land from ours."], ["Could we but stand where Moses stood,", "And view the landscape o'er,", "Not all this world's pretended good", "Could ever charm us more."]],
        "refrain": ["We're trav'ling to Immanuel's land,", "We soon shall hear the trumpet sound,", "And soon we shall with Jesus reign,", "And never, never part again.", "What! Never part again? No, never part again,", "What! Never part again? No, never part again,", "And soon we shall with Jesus reign,", "And never, never part again."],
        "refrainFirst": false
      }, {
        "number": "450",
        "name": "Beautiful Zion",
        "category": "Early Advent",
        "verses": [["Beautiful Zion, built above,", "Beautiful city that I love,", "Beautiful gates of pearly white,", "Beautiful temple, God its light."], ["Beautiful trees forever there,", "Beautiful fruit they always bear,", "Beautiful rivers gliding by,", "Beautiful fountains never dry."], ["Beautiful crowns on every brow,", "Beautiful palms the conquerors show,", "Beautiful robes the ransomed wear,", "Beautiful all who enter there."]]
      }, {
        "number": "451",
        "name": "Together Let Us Sweetly Live",
        "category": "Early Advent",
        "verses": [["Together let us sweetly live,", "I am bound for the land of Canaan.", "Together love to Jesus give;", "I am bound for the land of Canaan."], ["Together let us watch and pray;", "I am bound for the land of Canaan.", "And wait redemption's joyous day;", "I am bound for the land of Canaan."], ["Our songs of praise shall fill the skies;", "I am bound for the land of Canaan.", "While higher still our joys shall rise;", "I am bound for the land of Canaan."], ["Then come with me, beloved friend;", "I am bound for the land of Canaan.", "The joys to come shall never end;", "I am bound for the land of Canaan."]],
        "refrain": ["O Canaan, bright Canaan,", "I am bound for the land of Canaan.", "O Canaan, it is my happy home,", "I am bound for the land of Canaan."],
        "refrainFirst": false
      }, {
        "number": "452",
        "name": "What Heavenly Music",
        "category": "Early Advent",
        "verses": [["What heavenly music steals over the sea!", "Entrancing the senses like sweet melody!", "'Tis the voice of the angels borne soft on the air;", "For me they are singing; their welcome I hear."], ["On the banks of old Jordan, here gazing I stand,", "And earnestly longing, I stretch forth my hand;", "Send a convoy of angels, dear Jesus, I pray!", "Let me join that sweet music; come, take me away."], ["Though dark are the waters and rough is the wave,", "If Jesus permit, the wild surges I'll brave;", "For that heavenly music hath ravished me so,", "I must join in that chorus! I'll go! let me go!"]]
      }, {
        "number": "453",
        "name": "We Have Heard",
        "category": "Early Advent",
        "verses": [["We have heard from the bright, the holy, land;", "We have heard, and our hearts are glad;", "For we were a lonely pilgrim band,", "And weary, and worn, and sad.", "They tell us the saints have a dwelling there-", "No longer are homeless ones;", "And we know that the goodly land is fair,", "Where life's pure river runs."], ["They say green fields are waving there,", "That never a blight shall know;", "And the deserts wild are blooming fair,", "And the roses of Sharon grow.", "There are lovely birds in the bowers green,", "Their songs are blithe and sweet;", "And their warblings, gushing ever new,", "The angels' harpings greet."], ["We have heard of the palms, the robes, the crowns,", "And the silvery band in white;", "Of the city fair, with pearly gates,", "All radiant with light.", "We have heard of the angels there, and saints,", "With their harps of gold, how they sing;", "Of the mount with the fruitful tree of life,", "Of the leaves that healing bring."], ["The King of that country, He is fair,", "He's the joy and light of the place;", "In His beauty we shall behold Him there,", "And bask in His smiling face,", "We'll be there, we'll be there in a little while,", "We'll join the pure and the blest;", "We'll have the palm, the robe, the crown,", "And forever be at rest."]]
      }, {
        "number": "454",
        "name": "Don't You See My Jesus Coming",
        "category": "Early Advent",
        "verses": [["Don't you see my Jesus coming,", "See Him come in yonder cloud?", "With ten thousand angels round Him,", "How they do my Jesus crowd!"], ["Don't you see the saints ascending,", "Hear them shouting thro' the air.", "Jesus smiling, trumpets sounding,", "Now His glory they shall share."]],
        "refrain": ["I am bound for the kingdom,", "Will you go to glory with me?", "Hallelujah! O praise ye the Lord!"],
        "refrainFirst": false
      }, {
        "number": "455",
        "name": "Immortal Love, Forever Full",
        "category": "Christian Life",
        "subcategory": "Our Love for God",
        "verses": [["Immortal Love, forever full,", "Forever flowing free,", "Forever shared, forever whole,", "A never ebbing sea!"], ["We nay not climb the heavenly steeps", "To bring the Lord Christ down;", "In vain we search the lowest deeps,", "For him no depths can drown."], ["But warm, so tender, even yet", "A present help is He;", "And faith has still its Olivet,", "And love its Galilee."], ["The healing of His seamless dress", "Is by our beds of pain;", "We touch Him in life's throng and press,", "And we are whole again."], ["Through Him the first fond prayers are said,", "Our lips of childhood frame;", "The last low whispers of our dead", "Are burdened with His name."], ["O Lord and Master of us all:", "Whate'er our names or sign,", "We own Thy sway, we hear Thy call,", "We test our lives by Thine!"]]
      }, {
        "number": "456",
        "name": "My Lord and I",
        "category": "Christian Life",
        "subcategory": "Our Love for God",
        "verses": [["I have a Friend so precious,", "So very dear to me,", "He loves me with such tender love,", "He loves so faithfully;", "I could not live apart form Him,", "I love to feel Him nigh,", "And so we dwell together,", "My Lord and I."], ["Sometimes I'm faint and weary,", "He knows that I am weak,", "And as He bids me lean on Him,", "His help I gladly seek;", "He leads me in the paths of light,", "Beneath a sunny sky,", "And so we walk together,", "My Lord and I."], ["I tell Him all my sorrows,", "I tell Him all my joys,", "I tell Him all that pleases me,", "I tell Him what annoys;", "He tells me what I ought to do,", "He tells me how to try,", "And so we talk together,", "My Lord and I."], ["He knows that I am longing", "Some weary soul to win,", "And so He bids me go and speak", "The loving word for Him;", "He bids me tell His wondrous love,", "And why He came to die,", "And so we work together,", "My Lord and I."]]
      }, {
        "number": "457",
        "name": "I Love to Tell the Story",
        "category": "Christian Life",
        "subcategory": "Our Love for God",
        "verses": [["I love to tell the story", "of unseen things above,", "of Jesus and his glory,", "of Jesus and his love.", "I love to tell the story,", "because I know 'tis true;", "it satisfies my longings", "as nothing else can do."], ["I love to tell the story;", "more wonderful it seems", "than all the golden fancies", "of all our golden dreams.", "I love to tell the story,", "it did so much for me;", "and that is just the reason", "I tell it now to thee."], ["I love to tell the story;", "'tis pleasant to repeat", "what seems, each time I tell it,", "more wonderfully sweet.", "I love to tell the story,", "for some have never heard", "the message of salvation", "from God's own holy Word."], ["I love to tell the story,", "for those who know it best", "seem hungering and thirsting", "to hear it like the rest.", "And when, in scenes of glory,", "I sing the new, new song,", "'twill be the old, old story", "that I have loved so long."]],
        "refrain": ["I love to tell the story,", "'twill be my theme in glory,", "to tell the old, old story", "of Jesus and his love."],
        "refrainFirst": false
      }, {
        "number": "458",
        "name": "More Love to Thee",
        "category": "Christian Life",
        "subcategory": "Our Love for God",
        "verses": [["More love to thee, O Christ, more love to thee!", "Hear thou the prayer I make on bended knee.", "This is my earnest plea: More love, O Christ, to thee;", "more love to thee, more love to thee!"], ["Once earthly joy I craved, sought peace and rest;", "now thee alone I seek, give what is best.", "This all my prayer shall be: More love, O Christ, to thee;", "more love to thee, more love to thee!"], ["Let sorrow do its work, come grief and pain;", "sweet are thy messengers, sweet their refrain,", "when they can sing with me: More love, O Christ, to thee;", "more love to thee, more love to thee!"], ["Then shall my latest breath whisper thy praise;", "this be the parting cry my heart shall raise;", "this still its prayer shall be: More love, O Christ, to thee;", "more love to thee, more love to thee!"]]
      }, {
        "number": "459",
        "name": "As the Bridegroom to His Chosen",
        "category": "Christian Life",
        "subcategory": "Our Love for God",
        "verses": [["As the bridegroom to His chosen,", "As the king to His realm,", "As the keep unto the castle,", "As the pilot to the helm,", "So, Lord, art Thou to me."], ["As the fountain in the garden,", "As the candle in the dark,", "As the treasure in the coffer,", "As the manna in the ark,", "So, Lord, art Thou to me."], ["As the ruby in the setting,", "As the honey in the comb,", "As the light within the lantern,", "As the father in the home,", "So, Lord, art Thou to me."], ["As the sunshine in the heavens,", "As the image in the glass,", "As the fruits up in the fig tree,", "As the dew upon the grass,", "So, Lord, art Thou to me."]]
      }, {
        "number": "460",
        "name": "As Water to the Thirsty",
        "category": "Christian Life",
        "subcategory": "Our Love for God",
        "verses": [["As water to the thirsty,", "As beauty to the eyes,", "As strength that follows weakness,", "As truth instead of lies;", "As song-time and springtime and summertime to be,", "So is my Lord, my living Lord,", "So is my Lord to me."], ["Like calm in place of clamor", "Like peace that follows pain,", "Like meeting after parting,", "Like sunshine after rain;", "Like moonlight and starlight and sunlight on the sea,", "So is my Lord, my living Lord,", "So is my Lord to me."], ["As sleep that follows fever,", "As gold instead of grey,", "As freedom after bondage,", "As sunrise to the day;", "As home to the trav'ler and all he longs to see,", "So is my Lord, my living Lord,", "So is my Lord to me."]]
      }, {
        "number": "461",
        "name": "Be Still, My Soul",
        "category": "Christian Life",
        "subcategory": "Joy and Peace",
        "verses": [["Be still, my soul:", "the Lord is on thy side;", "Bear patiently the cross of grief or pain;", "Leave to thy God to order and provide;", "In every change He faithful will remain."], ["Be still, my soul:", "thy God doth undertake", "To guide the future as He has the past.", "Thy hope, thy confidence let nothing shake;", "All now mysterious shall be bright at last."], ["Be still, my soul:", "the hour is hastening on", "When we shall be forever with the Lord,", "When disappointment, grief, and fear are gone,", "Sorrow forgot, love's purest joys restored."]],
        "refrain": ["Be still, my soul:", "thy best, thy heavenly friend", "Through thorny ways leads to a joyful end."],
        "refrainFirst": false
      }, {
        "number": "462",
        "name": "Blessed Assurance, Jesus is Mine!",
        "category": "Christian Life",
        "subcategory": "Joy and Peace",
        "verses": [["Blessed assurance, Jesus is mine!", "O what a foretaste of glory divine!", "Heir of salvation, purchase of God,", "born of his Spirit, washed in his blood."], ["Perfect submission, perfect delight,", "visions of rapture now burst on my sight;", "angels descending bring from above", "echoes of mercy, whispers of love."], ["Perfect submission, all is at rest;", "I in my Savior am happy and blest,", "watching and waiting, looking above,", "filled with his goodness, lost in his love."]],
        "refrain": ["This is my story, this is my song,", "praising my Savior all the day long;", "this is my story, this is my song,", "praising my Savior all the day long."],
        "refrainFirst": false
      }, {
        "number": "463",
        "name": "Peace, Perfect Peace",
        "category": "Christian Life",
        "subcategory": "Joy and Peace",
        "verses": [["Peace, perfect peace, in this dark world of sin?", "The blood of Jesus whispers peace within."], ["Peace, perfect peace, by thronging duties pressed?", "To do the will of Jesus: this is rest."], ["Peace, perfect peace, with loved ones far away?", "In Jesus' keeping we are safe, and they."], ["Peace, perfect peace, our future all unknown?", "Jesus we know, and He is on the throne."]]
      }, {
        "number": "464",
        "name": "When I Can Read My Title Clear",
        "category": "Christian Life",
        "subcategory": "Joy and Peace",
        "verses": [["When I can read my title clear to mansions in the skies,", "I bid farewell to every fear, and wipe my weeping eyes.", "And wipe my weeping eyes, and wipe my weeping eyes", "I bid farewell to every fear, and wipe my weeping eyes."], ["Should earth against my soul engage, and hellish darts be hurled,", "Then I can smile at Satan's rage, and face a frowning world.", "And face a frowning world, and face a frowning world,", "Then I can smile at Satan's rage, and face a frowning world."], ["Let cares, like a wild deluge come, and storms of sorrow fall!", "May I but safely reach my home, my God, my heav'n, my All.", "My God, my heaven, my All, my God, my heave', my All,", "May I but safely reach my home, my God, my heaven, my All."]]
      }, {
        "number": "465",
        "name": "I Heard the Voice of Jesus",
        "category": "Christian Life",
        "subcategory": "Joy and Peace",
        "verses": [["I heard the voice of Jesus say,", "\"Come unto Me and rest;", "Lay down, thou weary one, lay down", "Thy head upon My breast.\"", "I came to Jesus as I was,", "Weary and worn and sad;", "I found in Him a resting place,", "And He has made me glad."], ["I heard the voice of Jesus say,", "\"Behold, I freely give", "The living water; thirsty one,", "Stoop down and drink and live.\"", "I came to Jesus, and I drank", "Of that life giving stream;", "My thirst was quench'd, my soul revived,", "And now I live in Him."], ["I heard the voice of Jesus say,", "\"I am this dark world's light;", "Look unto Me, thy morn shall rise,", "And all thy day be bright.\"", "I looked to Jesus, and I found", "In Him my star, my sun;", "And in that light of life I'll walk,", "Till trav'ling days are done."]]
      }, {
        "number": "466",
        "name": "Wonderful Peace",
        "category": "Christian Life",
        "subcategory": "Joy and Peace",
        "verses": [["Far away in the depths of my spirit tonight,", "Rolls a melody sweeter than psalm;", "In celestial like strains it unceasingly falls", "O'er my soul like an infinite calm."], ["What a treasure I have in this wonderful peace,", "Buried deep in my inner most soul,", "So secure that no power can mine it away,", "While the years of eternity roll!"], ["I believe when I rise to that city of peace,", "Where the Author of peace I shall see,", "That one strain of the song which the ransomed will sing,", "In that heavenly kingdom will be-"], ["Weary soul, without gladness or comfort or rest,", "Passing down the rough pathway of time!", "Make the Savior your friend ere the shadows grow dark;", "O accept of this peace so sublime."]],
        "refrain": ["Peace! Peace! wonderful peace,", "Coming down from the Father above;", "Sweep over my spirit forever, I pray,", "In fathomless billows of love."],
        "refrainFirst": false
      }, {
        "number": "467",
        "name": "Life Is Great! So Sing About It",
        "category": "Christian Life",
        "subcategory": "Joy and Peace",
        "verses": [["Life is great! So sing about it,", "As we can and as we should-", "Shop and buses, towns and people,", "Village, farmland, field and wood,", "Life is great and life is given;", "Life is lovely, free and good."], ["Life is great! What ever happens,", "Snow or sunshine, joy or pain,", "Hardship, grief or disillusion,", "Suffering that I can't explain", "Life is great if someone loves me,", "Holds my hand and calls my name."], ["Love is great! The love of lovers,", "Whispered words and longing eyes;", "Love that gazes at the cradle", "Where a child of loving lies;", "Love that lasts when youth has faded,", "Bends with age, but never dies."], ["Love is giving and receiving", "Boy and girl, or friend with friend;", "Love is bearing and forgiving", "All the hurts that hate can send;", "Love's the greatest way of living,", "Hoping, trusting to the end."], ["God is great! in Christ He loved us,", "As we should, but never can-", "Love that suffered, hoped and trusted", "When disciples turned and ran,", "Love that broke through death forever.", "Praise that loving, living Man!"]]
      }, {
        "number": "468",
        "name": "A Child of the King",
        "category": "Christian Life",
        "subcategory": "Joy and Peace",
        "verses": [["My Father is rich in houses and lands,", "He holdeth the wealth of the world in His hands!", "Of rubies and diamonds, of silver and gold,", "His coffers are full, He has riches untold."], ["My Father's own Son, the Savior of men,", "Once wandered on earth as the poorest of them;", "But now He is pleading our pardon on high,", "That we may be His when He comes by and by."], ["I once was an outcast stranger on earth,", "A sinner by choice, an alien by birth,", "But I've been adopted, my name's written down,", "An heir to a mansion, a robe and a crown."], ["A tent or a cottage, why should I care?", "They're building a palace for me over there;", "Though exiled from home, yet still may I sing:", "All glory to God, I'm a child of the King."]],
        "refrain": ["I'm a child of the King,", "A child of the King:", "With Jesus my Savior,", "I'm a child of the King."],
        "refrainFirst": false
      }, {
        "number": "469",
        "name": "Leaning on the Everlasting Arms",
        "category": "Christian Life",
        "subcategory": "Joy and Peace",
        "verses": [["What a fellowship, what a joy divine,", "leaning on the everlasting arms;", "what a blessedness, what a peace is mine,", "leaning on the everlasting arms."], ["O how sweet to walk in this pilgrim way,", "leaning on the everlasting arms;", "O how bright the path grows from day to day,", "leaning on the everlasting arms.", "(Refrain)"], ["What have I to dread, what have I to fear,", "leaning on the everlasting arms?", "I have blessed peace with my Lord so near,", "leaning on the everlasting arms."]],
        "refrain": ["Leaning, leaning,", "safe and secure from all alarms;", "leaning, leaning,", "leaning on the everlasting arms."],
        "refrainFirst": false
      }, {
        "number": "470",
        "name": "There's Sunshine in My Soul Today",
        "category": "Christian Life",
        "subcategory": "Joy and Peace",
        "verses": [["There's sunshine in my soul today,", "More glorious and bright", "Than glows in any earthly sky,", "For Jesus is my light."], ["There's music in my soul today,", "A carol to my King,", "And Jesus, listening, can hear", "The songs I cannot sing."], ["There's springtime in my soul today,", "For when the Lord is near,", "The dove of peace sings in my heart,", "The flowers of grace appear."], ["Theres gladness in my soul today,", "And hope, and praise, and love,", "For blessings which He gives me now,", "For joys \"laid up\" above."]],
        "refrain": ["O there's sunshine, blessed sunshine,", "When the peaceful, happy moments roll", "When Jesus shows His smiling face", "There is sunshine in the soul."],
        "refrainFirst": false
      }, {
        "number": "471",
        "name": "Grant Us Your Peace",
        "category": "Christian Life",
        "subcategory": "Joy and Peace",
        "verses": [["Dona nobis pacem, pacem;", "Dona nobis pacem.", "Dona nobis pacem;", "Dona nobis pacem.", "Dona nobis pacem;", "Dona nobis pacem."], ["Father grant us, grant us Your peace;", "Oh, loving Father, grant us Your peace.", "Grant us, grant us peace;", "Grant us, grant us, grant Your peace;", "Grant us, grant us peace;", "Loving Father, grant us Your peace."], ["Accordenous ta paix, ta paix, Accordenous ta paix.", "Accordenous ta paix;", "Accordenous ta paix.", "Accordenous ta paix,", "Accordenous ta paix."], ["Padre, danos tupaz, tupaz;", "Padre, danos, danos tupaz", "Padre, danos, tupaz.", "Padre, danos, danos tupaz", "Padre, danos tupaz.", "Padre, danos, danos tupaz."]]
      }, {
        "number": "472",
        "name": "A Song of Heaven and Homeland",
        "category": "Christian Life",
        "subcategory": "Hope and Comfort",
        "verses": [["Sometimes I hear strange music,", "Like none e'er heard before,", "Come floating softly earthward", "As thro' heav'n's open door:", "It seems like angel voices,", "In strains of and love,", "That swell the mighty refrain", "Around the throne above."], ["Now soft, and low, and restful,", "It floods my soul with peace,", "As if God's benediction", "Bade all earth's troubles cease.", "Then grander than the voices,", "Of wind, and wave, and sea-", "It fills the dome of heaven", "With glorious harmony."], ["This music haunts me ever,", "Like something heard in dreams-", "It seems to catch the cadence", "Of heav'nly winds and streams,", "My heart is filled with rapture,", "To think, some day to come,", "I'll sing it with the angels-", "The song of heav'n and home."]],
        "refrain": ["O sweet, celestial music,", "Heard from a land afar-", "The song of Heav'n and Homeland,", "Thro' doors God leaves ajar!"],
        "refrainFirst": false
      }, {
        "number": "473",
        "name": "Nearer My God, to Thee",
        "category": "Christian Life",
        "subcategory": "Hope and Comfort",
        "verses": [["Nearer, my God, to thee, nearer to thee!", "E'en though it be a cross that raiseth me,", "still all my song shall be,", "nearer, my God, to thee;", "nearer, my God, to thee, nearer to thee!"], ["Though like the wanderer, the sun gone down,", "darkness be over me, my rest a stone;", "yet in my dreams I'd be", "nearer, my God, to thee;", "nearer, my God, to thee, nearer to thee!"], ["There let the way appear, steps unto heaven;", "all that thou sendest me, in mercy given;", "angels to beckon me", "nearer, my God, to thee;", "nearer, my God, to thee, nearer to thee!"], ["Then, with my waking thoughts bright with thy praise,", "out of my stony griefs Bethel I'll raise;", "so by my woes to be", "nearer, my God, to thee;", "nearer, my God, to thee, nearer to thee!"], ["Or if, on joyful wing cleaving the sky,", "sun, moon, and stars forgot, upward I fly,", "still all my song shall be,", "nearer, my God, to thee;", "nearer, my God, to thee, nearer to thee!"]]
      }, {
        "number": "474",
        "name": "Take the Name of Jesus With You",
        "category": "Christian Life",
        "subcategory": "Hope and Comfort",
        "verses": [["Take the Name of Jesus with you,", "Child of sorrow and of woe,", "It will joy and comfort give you;", "Take it then, where'er you go."], ["Take the Name of Jesus ever,", "As a shield from every snare;", "If temptations round you gather,", "Breathe that holy Name in prayer."], ["O the precious Name of Jesus!", "How it thrills our souls with joy,", "When His loving arms receive us,", "And His songs our tongues employ!"], ["At the Name of Jesus bowing,", "Falling prostrate at His feet,", "King of kings in heav'n we'll crown Him,", "When our journey is complete."]],
        "refrain": ["Precious Name, O how sweet!", "Hope of earth and joy of Heav'n.", "Precious Name, O how sweet!", "Hope of earth and joy of Heav'n."],
        "refrainFirst": false
      }, {
        "number": "475",
        "name": "Balm in Gilead",
        "category": "Christian Life",
        "subcategory": "Hope and Comfort",
        "verses": [["There is a balm in Gilead", "To make the wounded whole;", "There is a balm in Gilead", "To heal the sin-sick soul."], ["Sometimes I feel discouraged,", "And think my work's in vain,", "But then the Holy Spirit", "Revives my soul again."], ["If you can not preach like Peter,", "If you can not pray like Paul,", "You can tell the love of Jesus,", "And say He died for all."]]
      }, {
        "number": "476",
        "name": "Burdens Are Lifted at Calvary",
        "category": "Christian Life",
        "subcategory": "Hope and Comfort",
        "verses": [["Days are filled with sorrow and care,", "Hearts are lonely and drear;", "Burdens are lifted at Calvary,", "Jesus is very near."], ["Cast your care on Jesus today,", "Leave your worry and fear;", "Burdens are lifted at Calvary,", "Jesus is very near."], ["Troubled soul, the Saviour can see,", "Ev'ry heartache and tear;", "Burdens are lifted at Calvary", "Jesus is very near."]],
        "refrain": ["Burdens are lifted at Calvary,", "Calvary, Calvary,", "Burdens are lifted at Calvary,", "Jesus is very near."],
        "refrainFirst": false
      }, {
        "number": "477",
        "name": "Come, Ye Disconsolate",
        "category": "Christian Life",
        "subcategory": "Hope and Comfort",
        "verses": [["Come, ye disconsolate, where'er ye languish.", "come to the mercy seat, fervently kneel.", "Here bring your wounded hearts, here tell your anguish;", "earth has no sorrow that heaven cannot heal."], ["Joy of the desolate, light of the straying,", "hope of the penitent, fadeless and pure!", "Here speaks the Comforter, tenderly saying,", "\"Earth has no sorrow that heaven cannot cure.\""], ["Here see the bread of life; waters flowing", "forth from the throne of God, pure from above.", "Come to the feast of love; come, ever knowing", "earth has no sorrow but heaven can remove."]]
      }, {
        "number": "478",
        "name": "Sweet Hour of Prayer",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["Sweet hour of prayer! sweet hour of prayer!", "that calls me from a world of care,", "and bids me at my Father's throne", "make all my wants and wishes known.", "In seasons of distress and grief,", "my soul has often found relief,", "and oft escaped the tempter's snare", "by thy return, sweet hour of prayer!"], ["Sweet hour of prayer! sweet hour of prayer!", "thy wings shall my petition bear", "to him whose truth and faithfulness", "engage the waiting soul to bless.", "And since he bids me seek his face,", "believe his word, and trust his grace,", "I'll cast on him my every care,", "and wait for thee, sweet hour of prayer!"], ["Sweet hour of prayer! sweet hour of prayer!", "May I thy consolation share", "Till from Mount Pisgah's lofty height", "I view my home and take my flight.", "In my immortal flesh I'll rise", "To size the everlasting prize.", "And shout while passing through the air,", "\"Farewell, farewell, sweet hour of prayer!\""]]
      }, {
        "number": "479",
        "name": "Tread Softly",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["Be silent, be silent, A whisper is heard;", "Be silent, and listen, Oh treasure each word."], ["Be silent, be silent, For holy this place,", "This altar that echoes The message of grace."], ["Be silent, be silent, Breathe humbly our prayer;", "A foretaste of Eden This moment we share."], ["Be silent, be silent, His mercy record;", "Be silent, be silent, And wait on the Lord."]],
        "refrain": ["Tread softly, tread softly, The Master is here;", "(Tread softly here, tread softly here,)", "Tread softly, tread softly, He bids us draw near.", "(Tread softly here, tread softly here,)"],
        "refrainFirst": false
      }, {
        "number": "480",
        "name": "Dear Lord and Father",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["Dear Lord and Father of mankind,", "forgive our foolish ways;", "reclothe us in our rightful mind,", "in purer lives thy service find,", "in deeper reverence, praise."], ["In simple trust like theirs who heard,", "beside the Syrian sea,", "the gracious calling of the Lord,", "let us, like them, without a word,", "rise up and follow thee."], ["O sabbath rest by Galilee,", "O calm of hills above,", "where Jesus knelt to share with thee", "the silence of eternity,", "interpreted by love!"], ["Drop thy still dews of quietness,", "till all our strivings cease;", "take from our souls the strain and stress,", "and let our ordered lives confess", "the beauty of thy peace."], ["Breathe through the heats of our desire", "thy coolness and thy balm;", "let sense be dumb, let flesh retire;", "speak through the earthquake, wind, and fire,", "O still, small voice of calm."]]
      }, {
        "number": "481",
        "name": "Dear Lord and Father",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["Dear Lord and Father of mankind,", "forgive our foolish ways;", "reclothe us in our rightful mind,", "in purer lives thy service find,", "in deeper reverence, praise."], ["In simple trust like theirs who heard,", "beside the Syrian sea,", "the gracious calling of the Lord,", "let us, like them, without a word,", "rise up and follow thee."], ["O sabbath rest by Galilee,", "O calm of hills above,", "where Jesus knelt to share with thee", "the silence of eternity,", "interpreted by love!"], ["Drop thy still dews of quietness,", "till all our strivings cease;", "take from our souls the strain and stress,", "and let our ordered lives confess", "the beauty of thy peace."], ["Breathe through the heats of our desire", "thy coolness and thy balm;", "let sense be dumb, let flesh retire;", "speak through the earthquake, wind, and fire,", "O still, small voice of calm."]]
      }, {
        "number": "482",
        "name": "Father, Lead Me Day by Day",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["Father, lead me day by day,", "Ever in Thine own sweet way;", "Teach me to be pure and true;", "Show me what I ought to do."], ["When in danger, make me brave;", "Make me know that Thou canst save;", "Keep me safe by Thy dear side;", "Let me in Thy love abide."], ["When tempted to do wrong,", "Make me steadfast, wise, and strong;", "And when all alone I stand,", "Shield me with Thy mighty hand."], ["May I do the good I know,", "Be Thy loving child below,", "Then at last go home to Thee,", "Evermore Thy child to be."]]
      }, {
        "number": "483",
        "name": "I Need Thee Every Hour",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["I need thee every hour, most gracious Lord;", "no tender voice like thine can peace afford."], ["I need thee every hour; stay thou nearby;", "temptations lose their power when thou art nigh."], ["I need thee every hour, in joy or pain;", "come quickly and abide, or life is vain."], ["I need thee every hour; teach me thy will;", "and thy rich promises in me fulfill."]],
        "refrain": ["I need thee, O I need thee;", "every hour I need thee;", "O bless me now, my Savior, I come to thee."],
        "refrainFirst": false
      }, {
        "number": "484",
        "name": "I Need Thee, Precious Jesus",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["I need Thee precious Jesus, for I am very poor;", "A stranger and a pilgrim, I have no earthly store.", "I need the love of Jesus to cheer me on my way,", "To guide my doubting foot steps, to be my strength and stay."], ["I need the heart of Jesus to feel each anxious care,", "To tell my every trial, and all my sorrows share.", "I need the Holy Spirit to teach me what I am,", "To show me more of Jesus, to point me to the Lamb."], ["I need Thee precious Jesus, I hope to see Thee soon,", "Encircled with the rainbow, and seated on Thy throne.", "There with Thy blood-bought children, my joy shall ever be", "To sing Thy ceaseless praises, to gaze, my Lord, on Thee."]]
      }, {
        "number": "485",
        "name": "I Must Tell Jesus",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["I must tell Jesus all of my trials,", "I cannot bear these burdens alone;", "In my distress He kindly will help me,", "He ever loves and cares for His own."], ["I must tell Jesus all of my troubles,", "He is a kind, compassionate Friend;", "If I but ask Him He will deliver,", "Make of my troubles quickly an end."], ["Oh, how the world to evil allures me!", "Oh, how my heart is tempted to sin!", "I must tell Jesus, and He will help me", "Over the world the vict'ry to win."]],
        "refrain": ["I must tell Jesus! I must tell Jesus!", "I cannot bear my burdens alone;", "I must tell Jesus! I must tell Jesus!", "Jesus can help me, Jesus alone."],
        "refrainFirst": false
      }, {
        "number": "486",
        "name": "I Do Believe",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["Father, I stretch my hands to Thee;", "No other help I know;", "If Thou withdraw Thyself from me,", "Ah, wither shall I go?"], ["On Thy dear Son I now believe,", "O let me feel Thy power;", "And all my varied wants relieve,", "In this accepted hour."], ["Author of faith! To Thee I lift", "My weary, longing eyes;", "O let me now receive that gift;", "My soul without it dies."], ["Surely Thou canst not let me die;", "O speak, and I shall live;", "And here I will unwearied lie,", "Till Thou Thy Spirit give."], ["How would my fainting soul rejoice", "Could I but see Thy face!", "Now let me hear Thy quickening voice,", "And taste Thy pardoning grace."], ["I do believe, I now believe", "That Jesus died for me,", "And that He shed His precious blood", "From sin to set me free."]]
      }, {
        "number": "487",
        "name": "In the Garden",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["I come to the garden alone,", "While the dew is still on the roses;", "And the voice I hear, falling on my ear,", "The Son of God discloses."], ["He speaks, and the sound of His voice", "Is so sweet the birds hush their singing,", "And the melody that He gave to me", "Within my heart is ringing."], ["I'd stay in the garden with Him", "Though the night around me be falling,", "But He bids me go; through the voice of woe,", "His voice to me is calling."]],
        "refrain": ["And He talks with me,", "And He tells me I am His own;", "And the joy we share as we tarry there,", "None other has ever known."]
      }, {
        "number": "488",
        "name": "At First I Prayed for Light",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["At first I prayed for light:", "Could I but see the way,", "How gladly, swiftly would I walk", "To everlasting day!"], ["And next I prayed for strength:", "That I might tread the road", "With firm, unfaltering feet, and win", "The heaven's serene abode."], ["And then I asked for faith:", "Could I but trust my God,", "I'd live infolded in His peace,", "Though foes were all abroad."], ["But now I pray for love:", "Deep love to God and man;", "A living love that will not fail,", "However dark His plan."], ["And light and strength and faith", "Are opening everywhere!", "God waited patiently until", "I prayed the larger prayer."]]
      }, {
        "number": "489",
        "name": "Jesus, Lover of My Soul",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["Jesus, lover of my soul,", "Let me to thy bosom fly,", "While the billows near me roll,", "While the tempest still is high;", "Hide me, O my Savior, hide!", "Till the storm of life is past;", "Safe into the haven guide,", "O receive my soul at last!"], ["Other refuge has I none,", "Hangs my helpless soul on thee;", "Leave O leave me not alone!", "Still support and comfort me;", "All my trust on Thee is stayed,", "All my help from Thee I bring;", "Cover my defenseless head", "With the shadow of Thy wing."], ["Thou, O Christ, all I want,", "More than all in Thee I find;", "Raise the fallen, cheer the faint,", "Heal the sick, and lead the blind.", "Just and holy is Thy name,", "I am all unrighteousness;", "Vile and full of sin I am;", "Thou art full of truth and grace."], ["Plenteous grace with Thee is found-", "Grace to pardon all my sin;", "Let the healing streams abound,", "Make and keep me pure within;", "Thou of life the Fountain art,", "Freely let me take of Thee;", "Spring Thou up within my heart,", "Rise to all eternity."]]
      }, {
        "number": "490",
        "name": "Jesus, Lover of My Soul",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["Jesus, lover of my soul,", "Let me to thy bosom fly,", "While the billows near me roll,", "While the tempest still is high;", "Hide me, O my Savior, hide!", "Till the storm of life is past;", "Safe into the haven guide,", "O receive my soul at last!"], ["Other refuge has I none,", "Hangs my helpless soul on thee;", "Leave O leave me not alone!", "Still support and comfort me;", "All my trust on Thee is stayed,", "All my help from Thee I bring;", "Cover my defenseless head", "With the shadow of Thy wing."], ["Thou, O Christ, all I want,", "More than all in Thee I find;", "Raise the fallen, cheer the faint,", "Heal the sick, and lead the blind.", "Just and holy is Thy name,", "I am all unrighteousness;", "Vile and full of sin I am;", "Thou art full of truth and grace."], ["Plenteous grace with Thee is found-", "Grace to pardon all my sin;", "Let the healing streams abound,", "Make and keep me pure within;", "Thou of life the Fountain art,", "Freely let me take of Thee;", "Spring Thou up within my heart,", "Rise to all eternity."]]
      }, {
        "number": "491",
        "name": "In the Hour of Trial",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["In the hour of trial, Jesus, plead for me,", "Lest by base denial I depart from Thee;", "When Thou see'st me waver, with a look recall,", "Nor, for fear or favor, suffer me to fall."], ["With forbidden pleasures would this vain world charm;", "Or its sordid treasures spread to work me harm;", "Bring to my remembrance sad Gethsemane,", "Or, in darker semblance, cross-crowned Calvary."], ["Should Thy mercy send me sorrow, toil, and woe,", "Or should pain attend me on my path below,", "Grant that I may never fail Thy hand to see;", "Grant that I may ever cast my care on Thee."]]
      }, {
        "number": "492",
        "name": "Like Jesus",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["Teach, me, Father, what to say;", "Teach me, Father, how to pray;", "Teach me all along the way", "How to be like Jesus."], ["Teach me as the days go by,", "Teach me not to reason why,", "Teach me that to do and die,", "Is to be like Jesus."], ["Teach me that the time is short,", "Teach me how to live and work,", "Teach me that to never shirk", "Is to be like Jesus."], ["Teach me how we may be one,", "Like the Father and the Son;", "And when all is overcome,", "I will be like Jesus."]],
        "refrain": ["I would be like Jesus,", "I would be like Jesus!", "Help me, Lord, to daily grow", "More and more like Jesus!"],
        "refrainFirst": false
      }, {
        "number": "493",
        "name": "Fill My Cup, Lord",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["Like the woman at the well I was seeking", "For things that could not satisfy:", "And then I heard my Savior speaking:", "\"Draw from my well that never shall run dry\"."], ["There are millions in this world who are craving", "The pleasures earthly things afford;", "But none can match the wondrous treasure", "That I find in Jesus Christ my Lord."], ["So, my brother, if the things this world gave you", "Leave hungers that won't pass away,", "My blessed Lord will come and save you,", "If you kneel to Him and humbly pray:"]],
        "refrain": ["Fill my cup Lord, I lift it up, Lord!", "Come and quench this thirsting of my soul;", "Bread of heaven, Feed me till I want no more--", "Fill my cup, fill it up and make me whole!"],
        "refrainFirst": false
      }, {
        "number": "494",
        "name": "We Would See Jesus",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["\"We would see Jesus;\" for the shadows lengthen", "Across the little landscape of our life;", "We would see Jesus, our weak faith to strengthen", "For the last conflict, in this mortal strife."], ["\"We would see Jesus,\" Rock of our salvation,", "Where on our feet were set with sovereign grace;", "Not life, nor death, with all their agitation,", "Can thence remove us, gazing on His face."], ["\"We would see Jesus;\" other lights are paling,", "Which for long years we did rejoice to see;", "The blessings of this sinful world are failing;", "We would not mourn them, in exchange for Thee."], ["\"We would see Jesus;\" this is all we're needing-", "Strength, joy and willingness come with the sight;", "We would see Jesus, dying, risen, pleading,", "Soon to return and end this mortal night!"]]
      }, {
        "number": "495",
        "name": "Near to the Heart of God",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["There is a place of quiet rest,", "near to the heart of God;", "a place where sin cannot molest,", "near to the heart of God."], ["There is a place of comfort sweet,", "near to the heart of God;", "a place where we our Savior meet,", "near to the heart of God."], ["There is place a place of full release,", "near to the heart of God;", "a place where all is joy and peace,", "near to the heart of God."]],
        "refrain": ["O Jesus, blest Redeemer,", "sent from the heart of God,", "hold us who wait before thee", "near to the heart of God."],
        "refrainFirst": false
      }, {
        "number": "496",
        "name": "Eternal Love, We Have No Good",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["Eternal Love, we have no good to bring Thee,", "No single good of all our hands have wrought,", "No worthy music have we found to sing Thee,", "No jewelled word, no quick up soaring thought."], ["And yet we come; and when our faith would falter", "Show us, O Love, the quiet place of prayer,", "The golden censer and the golden altar,", "And the great angel waiting for us there."]]
      }, {
        "number": "497",
        "name": "O Gracious Father of Mankind",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["O gracious Father of mankind,", "Our spirits' unseen friend;", "High heaven's Lord, our hearts' dear guest,", "To Thee our prayers ascend.", "Thou dost not wait till human speech", "Thy gifts divine implore;", "Our dreams, our aims, our work, our lives", "Are prayers Thou lovest more."], ["Thou hearest these, the good and ill,", "Deep buried in each breast;", "The secret thought, the hidden plan,", "Wrought out our unexpressed.", "O cleanse our prayers from human dross,", "Attune our lives to Thee,", "Until we labor for those gifts", "We ask on bended knee."], ["Our best is but Thyself in us,", "Our highest thought Thy will;", "To hear Thy voice we need but love,", "And listen, and be still.", "We would not bend Thy will to ours,", "But blend our wills to Thine;", "Not dreams, our aims, our work, our lives", "Are prayers Thou lovest more."], ["Thou seekest us in love and truth", "More than our minds seek Thee;", "Through open gates Thy power flows in", "Like flood tides from the sea.", "No more we seek Thee from afar,", "Nor ask Thee for a sign,", "Content to pray in life and love", "And toil, till all are Thine."]]
      }, {
        "number": "498",
        "name": "Still, Still With Thee",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["Still, still with Thee, when purple morning breaketh,", "When the bird waketh, and the shadows flee;", "Fairer than morning, lovelier than daylight,", "Dawns the sweet consciousness, I am with Thee."], ["Alone with Thee, amid the mystic shadows,", "The solemn hush of nature newly born;", "Alone with Thee in breathless adoration,", "In the calm dew and freshness of the morn."], ["Still, still with Thee! As to each newborn morning", "A fresh and solemn splendor still is given,", "So does this blessed consciousness, awaking,", "Breathe each day nearness unto Thee and heaven."], ["So shall it be at last, in that bright morning,", "When the soul waketh and life's shadows flee;", "O in that hour, fairer than daylight dawning,", "Shall rise the glorious thought, I am with Thee."]]
      }, {
        "number": "499",
        "name": "What a Friend We Have in Jesus",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["What a friend we have in Jesus,", "all our sins and griefs to bear!", "What a privilege to carry", "everything to God in prayer!", "O what peace we often forfeit,", "O what needless pain we bear,", "all because we do not carry", "everything to God in prayer."], ["Have we trials and temptations?", "Is there trouble anywhere?", "We should never be discouraged;", "take it to the Lord in prayer.", "Can we find a friend so faithful", "who will all our sorrows share?", "Jesus knows our every weakness;", "take it to the Lord in prayer."], ["Are we weak and heavy laden,", "cumbered with a load of care?", "Precious Savior, still our refuge;", "take it to the Lord in prayer.", "Do thy friends despise, forsake thee?", "Take it to the Lord in prayer!", "In his arms he'll take and shield thee;", "thou wilt find a solace there."]]
      }, {
        "number": "500",
        "name": "Take Time to Be Holy",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["Take time to be holy,", "speak oft with thy Lord;", "abide in him always,", "and feed on his word.", "Make friends of God's children,", "help those who are weak,", "forgetting in nothing", "his blessing to seek."], ["Take time to be holy,", "the world rushes on;", "spend much time in secret", "with Jesus alone.", "By looking to Jesus,", "like him thou shalt be;", "thy friends in thy conduct", "his likeness shall see."], ["Take time to be holy,", "let him be thy guide,", "and run not before him,", "whatever betide.", "In joy or in sorrow,", "still follow the Lord,", "and, looking to Jesus,", "still trust in his word."], ["Take time to be holy,", "be calm in thy soul,", "each thought and each motive", "beneath his control.", "Thus led by his spirit", "to fountains of love,", "thou soon shalt be fitted", "for service above."]]
      }, {
        "number": "501",
        "name": "'Tis the Blessed Hour of Prayer",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["'Tis the blessed hour of prayer, when our hearts lowly bend,", "And we gather to Jesus, our Savior and Friend;", "If we come to Him in faith, His protection to share,"], ["'Tis the blessed hour of prayer, when the Savior draws near,", "With a tender compassion His children to hear;", "When He tells us we may cast at His feet every care,"], ["'Tis the blessed hour of prayer, when the tempted and tried", "To the Savior who loves them their sorrows confide;", "With a sympathizing heart He removes every care;"], ["'Tis the blessed hour of prayer, trusting Him we believe,", "That the blessings we're needing we'll surely receive;", "In the fullness of this trust we shall lose every care;"]],
        "refrain": ["What a balm for the weary! O how sweet to be there!", "Blessed hour of prayer, Blessed hour of prayer,", "What a balm for the weary! O how sweet to be there!"],
        "refrainFirst": false
      }, {
        "number": "502",
        "name": "Sun of My Soul",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["Sun of my soul, O Savior dear!", "It is not night if Thou be near;", "O may no earth-born cloud arise", "To hide Thee from Thy servant's eyes."], ["When soft the dews of kindly sleep", "My weary eyelids gently steep,", "Be my last thought how sweet to rest", "Forever on my Savior's breast!"], ["Abide with me from morn till eve,", "For without Thee I cannot live;", "Abide with me when night is nigh,", "For without Thee I dare not die."], ["Be near and bless me when I wake,", "Ere through the world my way I take;", "Till in the ocean of Thy love", "I lose myself in heaven above."]]
      }, {
        "number": "503",
        "name": "A Quiet Place",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "other": [["There is a quiet place,", "Far from the rapid pace", "where God can soothe my troubled mind.", "Sheltered by tree and flow'r,", "There in my quiet hour with Him my cares are left behind.", "Whether a garden small,", "Or on a mountain tall,", "New strength and courage there I find;", "Then from this quiet place", "I go prepared to face a new day", "With love for all mankind."]]
      }, {
        "number": "504",
        "name": "Lord Jesus, Think on Me",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["Lord Jesus, think on me, And purge away my sin;", "From earth-born passions set me free, And make me pure within."], ["Lord Jesus, think on me, With care and woe oppressed;", "Let me Thy loving servant be, And taste Thy promised rest."], ["Lord Jesus, think on me, Nor let me go astray;", "Through darkness and perplexity Point Thou the heavenly way."], ["Lord Jesus, think on me, That I may sing above", "To Father, Spirit, and to Thee, The strains of praise and love."]]
      }, {
        "number": "505",
        "name": "I Need the Prayers",
        "category": "Christian Life",
        "subcategory": "Meditation and Prayer",
        "verses": [["I need the prayers of those I love,", "While trav'ling o'er life's rugged way,", "That I may true and faithful be,", "And live for Jesus every day."], ["I need the prayers of those I love,", "To help me in each trying hour,", "To bear my tempted soul to Him,", "That He may keep me by His pow'r."], ["I want my friends to pray for me,", "To hold me up on wing of faith,", "That I may walk the narrow way,", "Kept by our Father's glorious grace."]],
        "refrain": ["I want my friends to pray for me,", "To bear my tempted soul above,", "And intercede with God for me;", "I need the prayers of those I love."],
        "refrainFirst": false
      }, {
        "number": "506",
        "name": "A Mighty Fortress",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["A mighty fortress is our God,", "a bulwark never failing;", "our helper he amid the flood", "of mortal ills prevaling.", "For still our ancient foe", "doth seek to work us woe;", "his craft and power are great,", "and armed with cruel hate,", "on earth is not his equal."], ["Did we in our own strength confide,", "our striving would be losing,", "were not the right man on our side,", "the man of God's own choosing.", "Dost ask who that may be?", "Christ Jesus, it is he;", "Lord Sabbaoth, his name,", "from age to age the same,", "and he must win the battle."], ["And though this world, with devils filled,", "should threaten to undo us,", "we will not fear, for God hath willed", "his truth to triumph through us.", "The Prince of Darkness grim,", "we tremble not for him;", "his rage we can endure,", "for lo, his doom is sure;", "one little word shall fell him."], ["That word above all earthly powers,", "no thanks to them, abideth;", "the Spirit and the gifts are ours,", "thru him who with us sideth.", "Let goods and kindred go,", "this mortal life also;", "the body they may kill;", "God's truth abideth still;", "his kingdom is forever."]]
      }, {
        "number": "507",
        "name": "Moment by Moment",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["Dying with Jesus, by death reckoned mine,", "Living with Jesus, a new life divine,", "Looking to Jesus till glory doth shine,", "Moment by moment, O Lord, I am Thine."], ["Never a trial that He is not there,", "Never a burden that He doth not bear,", "Never a sorrow that He doth not share,", "Moment by moment I'm under His care."], ["Never a heart-ache, and never a groan,", "Never a teardrop and never a moan;", "Never a danger but there on the throne,", "Moment by moment He thinks of His own."], ["Never a weakness that He doth not feel,", "Never a sickness that He cannot heal;", "Moment by moment, in woe or in weal,", "Jesus, my Savior, abides with me still."]],
        "refrain": ["Moment by moment I'm kept in His love;", "Moment by moment I've life from above;", "Looking to Jesus till glory doth shine;", "Moment by moment, O Lord, I am Thine."],
        "refrainFirst": false
      }, {
        "number": "508",
        "name": "Anywhere With Jesus",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["Anywhere with Jesus I can safely go,", "Anywhere He leads me in this world below;", "Anywhere without Him, dearest joys would fade;", "Anywhere with Jesus I am not afraid."], ["Anywhere with Jesus I am not alone;", "Other friends may fail me, He is still my own;", "Though His hand may lead me over dreary ways,", "Anywhere with Jesus is a house of praise."], ["Anywhere with Jesus I can go to sleep,", "When the gloomy shadows round about me creep,", "Knowing I shall waken nevermore to roam;", "Anywhere with Jesus will be home sweet home."]],
        "refrain": ["Anywhere!  Anywhere!", "Fear I cannot know;", "Anywhere with Jesus", "I can safely go."],
        "refrainFirst": false
      }, {
        "number": "509",
        "name": "How Firm a Foundation",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["How firm a foundation, ye saints of the Lord,", "is laid for your faith in his excellent word!", "What more can he say than to you he hath said,", "to you who for refuge to Jesus have fled?"], ["\"Fear not, I am with thee, O be not dismayed,", "for I am thy God and will still give thee aid;", "I'll strengthen and help thee, and cause thee to stand", "upheld by my righteous, omnipotent hand.\""], ["\"When through deep waters I call thee to go,", "the rivers of woe shall not thee overflow;", "for I will be with thee, thy troubles to bless,", "and sanctify to thee thy deepest distress.\""], ["\"When through fiery trials thy pathways shall lie,", "my grace, all-sufficient, shall be thy supply;", "the flame shall not hurt thee; I only design", "thy dross to consume, and thy gold to refine.\""], ["\"The soul that on Jesus hath leaned for respose,", "I will not, I will not depart to His foes;", "That soul, though all hell should endeavor to shake,", "I'll never, no never, no never forsake!\""]]
      }, {
        "number": "510",
        "name": "If You But Trust in God to Guide You",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["If you but trust in God to guide you", "And place your confidence in Him,", "You'll find Him always there beside you,", "To give you hope and strength within.", "For those who trust God's changeless love", "Build on the rock that will not move."], ["What gain is there in futile weeping,", "In helpless anger and distress?", "If you are in His care and keeping,", "In sorrow will He love you less?", "For He who took for you a cross", "Will bring you safe through every loss."], ["In patient trust await His leisure", "In cheerful hope, with heart content", "To take whate'er your Father's pleasure", "And all discerning love have sent;", "Doubt not your inmost wants are known", "To Him who chose you for His own."], ["Sing, pray, and keep His ways unswerving,", "Offer your service faithfully,", "And trust His word; though undeserving,", "You'll find His promise true to be.", "God never will forsake in need", "The soul that trusts in Him indeed."]]
      }, {
        "number": "511",
        "name": "I Know Whom I Have Believed",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["I know not why God's wondrous grace To me He hath made known,", "Nor why, unworthy, Christ in love Redeemed me for His own."], ["I know not how this saving faith To me He did impart,", "Nor how believing in His word Wrought peace within my heart."], ["I know not how the Spirit moves, Convincing men of sin,", "Revealing Jesus through the word, Creating faith in Him."], ["I know not when my Lord may come, At night or noonday fair,", "Nor if I walk the vale with Him, Or meet Him in the air."]],
        "refrain": ["But \"I know whom I have believed, and am persuaded that His is able", "To keep that which I've committed Unto Him against that day.\""],
        "refrainFirst": false
      }, {
        "number": "512",
        "name": "Just When I Need Him Most",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["Just when I need Him, Jesus is near,", "Just when I falter, just when I fear;", "Ready to help me, ready to cheer,", "Just when I need Him most.", "Just when I need Him most,", "Just when I need Him most,", "Jesus is near to comfort and cheer,", "Just when I need Him most."], ["Just when I need Him, Jesus is true,", "Never forsaking, all the way through,", "Giving for burdens pleasures anew,", "Just when I need Him most.", "Just when I need Him most,", "Just when I need Him most,", "Jesus is near to comfort and cheer,", "Just when I need Him most."], ["Just when I need Him, Jesus is strong,", "Bearing my burdens all the day long;", "For all my sorrow giving a song,", "Just when I need Him most.", "Just when I need Him most,", "Just when I need Him most,", "Jesus is near to comfort and cheer,", "Just when I need Him most."], ["Just when I need Him, He is my all,", "Answering when upon Him I call;", "Tenderly watching lest I should fall,", "Just when I need Him most.", "Just when I need Him most,", "Just when I need Him most,", "Jesus is near to comfort and cheer,", "Just when I need Him most."]]
      }, {
        "number": "513",
        "name": "In Heavenly Love Abiding",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["In heavenly love abiding, No change my heart shall fear;", "And safe is such confiding, For nothing changes here.", "The storm may roar without me, My heart may low be laid;", "But God is round about me, And can I be dismayed?"], ["Wherever He may guide me, No want shall turn me back;", "My Shepherd is beside me, And nothing can I lack.", "His wisdom ever waketh, His sight is never dim;", "He knows the way He taketh, And I will walk with Him."], ["Green pastures are before me, Which yet I have not seen;", "Bright skies will soon be o'er me, Where darkest clouds have been.", "My hope I cannot measure, My path to life is free;", "My Savior has my treasure, And He will walk with me."]]
      }, {
        "number": "514",
        "name": "Lord of Our Life",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["Lord of our life, and God of our salvation,", "Star of our night, and hope of every nation,", "Hear and receive Thy church's supplication,", "Lord God Almighty."], ["Lord, Thou canst help when earthly armor faileth;", "Lord, Thou canst save when deadly sin assaileth;", "Lord, o'er Thy rock nor death nor hell prevaileth;", "Grant us Thy peace, Lord"], ["Peace in our hearts our evil thoughts assuaging;", "Peace in Thy church, where brothers are engaging;", "Peace, when the world its busy war is waging;", "Send us, O Savior."], ["Grant us Thy help till foes are backward driven;", "Grant them Thy truth that they may be forgiven;", "Grant peace on earth, and, after we have striven,", "Peace in Thy heaven."]]
      }, {
        "number": "515",
        "name": "The Lord Is My Light",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["The Lord is my light; then why should I fear?", "By day and by night His presence is near;", "He is my salvation from sorrow and sin;", "This blessed persuasion the Spirit brings in."], ["The Lord is my light; though clods may arise,", "Faith, stronger than sight, looks up to the skies", "Where Jesus forever in glory doth reign:", "Then how can I ever in darkness remain?"], ["The Lord is my light, the Lord is my strength;", "I know in His might I'll conquer at length;", "My weakness in mercy He covers with power,", "And, walking by faith, He upholds me each hour."], ["The lord is my light, my all and in all;", "There is in His sight no darkness at all;", "He is my Redeemer, my Savior and King;", "With saints and with angels His praises I sing."]],
        "refrain": ["The Lord is my light, my joy, and my song;", "By day and by night He leads me along;", "The Lord is my light, my joy, and my song;", "By day and by night He leads me along."],
        "refrainFirst": false
      }, {
        "number": "516",
        "name": "All the Way",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["All the way my Savior leads me;", "What have I to ask beside?", "Can I doubt His tender mercy,", "Who through life has been my guide?", "Heavenly peace, divinest comfort,", "Here by faith in Him to dwell;", "For I know whate'er befall me,", "Jesus doeth all things well;", "For I know whate'er befall me,", "Jesus doeth all things well."], ["All the way my Savior leads me;", "Cheers each winding path I tread;", "Gives me grace for every trial,", "Feeds me with the living bread;", "Though my weary steps may falter,", "And my soul athirst may be,", "Gushing from the Rock before me,", "Lo, a spring of joy I see;", "Gushing from the Rock before me,", "Lo, a spring of joy I see."], ["All the way my Savior leads me;", "O the fullness of His love!", "Perfect rest to me is promised", "In my Father's house above;", "When I wake to life immortal,", "Wing my flight to realms of day,", "This my song through endless ages,", "Jesus led me all the way;", "This my song through endless ages,", "Jesus led me all the way."]]
      }, {
        "number": "517",
        "name": "My Faith Looks Up to Thee",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["My faith looks up to thee,", "thou Lamb of Calvary,", "Savior divine;", "Now hear me while I pray,", "take all my guilt away,", "O let me from this day", "be wholly Thine."], ["May thy rich grace impart", "strength to my fainting heart,", "my zeal inspire!", "As thou hast died for me,", "O may my love to thee", "pure, warm, and changeless be,", "a living fire!"], ["While life's dark maze I tread,", "and griefs around me spread,", "be thou my guide;", "bid darkness turn to day,", "wipe sorrow's tears away,", "nor let me ever stray", "from Thee aside."]]
      }, {
        "number": "518",
        "name": "Standing on the Promises",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["Standing on the promises", "of Christ my King,", "Thru eternal ages", "let His praises ring;", "Glory in the highest", "I will shout and sing,", "Standing on the promises of God."], ["Standing on the promises", "that cannot fail,", "When the howling storms", "of doubt and fear assail,", "By the living word of", "God I shall prevail,", "Standing on the promises of God."], ["Standing on the promises", "of Christ the Lord,", "Bound to Him eternally", "by love's strong cord,", "Overcoming daily with", "the Spirit's sword,", "Standing on the promises of God."]],
        "refrain": ["Standing, standing,", "Standing on the promises", "of God my Savior;", "Standing,standing,", "I'm standing on the promises of God."],
        "refrainFirst": false
      }, {
        "number": "519",
        "name": "Give to the Winds Your Fears",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["Give to the winds your fears;", "In hope be undismayed:", "God hears your sighs and coundts your tears,", "God shall lift up your head."], ["To Him commit your griefs;", "Your ways put in His hands", "To His sure truth adn tender care", "Who earth adn heave commands."], ["O put your trust in God;", "In duty's path go on.", "Walk in His strength with faith and hope,", "So shall your work be done."], ["Leave to His sovereign sway", "To choose and to command;", "So you shall, faithful, seek His way", "how wise, how strong His hand!"]]
      }, {
        "number": "520",
        "name": "He Hideth My Soul",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["A wonderful Savior", "is Jesus my Lord,", "A wonderful", "Savior to me,", "He hideth my soul in", "the cleft of the rock,", "Where rivers of", "pleasure I see."], ["A wonderful Savior", "is Jesus my Lord,", "He taketh my", "burden away,", "He holdeth me up, and", "I shall not be moved,", "He giveth me", "strength as my day."], ["With numberless blessings", "each moment He crowns,", "And filled with", "His fullness divine,", "I sing in my rapture,", "Oh, Glory to God", "For such a", "Redeemer as mine."], ["When clothed in His", "brightness, transported I rise", "To meet Him in", "clouds of the sky,", "His perfect salvation,", "His wonderful love,", "I'll shout with", "the millions on high."]],
        "refrain": ["He hideth my soul in", "the cleft of the rock", "That shadows a", "dry, thirsty land;", "he hideth my life in", "the depths of His love,", "And covers me", "there with His hand,", "And covers me", "there with His hand."],
        "refrainFirst": false
      }, {
        "number": "521",
        "name": "Depth of Mercy",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["Depth of mercy!--can there be", "Mercy still reserved for me?", "Can my God His wrath forbear?", "Me, the chief of sinners, spare?"], ["I have long withstood His grace,", "Long provoked Him to His face,", "Would not hearken to His calls,", "Grieved Him by a thousand falls."], ["Now incline me to repent;", "Let me now my sins lament;", "Now my foul revolt deplore,", "Weep, believe, and sin no more."], ["There for me the Savior stands,", "Shows His wounds and spreads His hands;", "God is love! I know, I feel;", "Jesus weeps, and loves me still."]]
      }, {
        "number": "522",
        "name": "My Hope Is Built on Nothing Less",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["My hope is built on nothing less", "than Jesus' blood and righteousness.", "I dare not trust the sweetest frame,", "but wholly leand on Jesus' name."], ["When Darkness veils his lovely face,", "I rest on his unchanging grace.", "in every high and stormy gale,", "my anchor holds within the veil."], ["His oath, his covenant, his blood", "supports me in the whelming flood.", "When all around my soul gives way,", "he then is all my hope and stay."], ["When He shall come with trumpet sound,", "O may I then in him be found!", "Dressed in his righteousness alone,", "faultless to stand before the throne."]],
        "refrain": ["On Christ the solid rock I stand;", "all other ground is sinking sand,", "all other ground is sinking sand."],
        "refrainFirst": false
      }, {
        "number": "523",
        "name": "My Faith Has Found a Resting Place",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["My faith has found a resting place,", "Not in a manmade creed;", "I trust the ever living One,", "That He for me shall plead."], ["Enough for me that Jesus saves,", "This ends my fear and doubt;", "A sinful soul, I come to Him,", "He will not cast me out."], ["My soul is resting on the Word,", "The living Word of God,", "Salvation in my Savior's name,", "Salvation through His blood."], ["My great Physician heals the sick,", "The lost He came to save;", "For me His precious blood He shed,", "For me His life He gave."]],
        "refrain": ["I need no other evidence,", "I need no other plea;", "It is enough that Jesus died,", "And rose again for me."],
        "refrainFirst": false
      }, {
        "number": "524",
        "name": "'Tis So Sweet to Trust in Jesus",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["'Tis so sweet to trust in Jesus,", "and to take him at his word;", "just to rest upon his promise,", "and to know, \"Thus saith the Lord.\""], ["O how sweet to trust in Jesus,", "just to trust his cleansing blood;", "and in simple faith to plunge me", "neath the healing, cleansing flood!"], ["Yes, 'tis sweet to trust in Jesus,", "just from sin and self to cease;", "just from Jesus simply taking", "life and rest, and joy and peace."], ["I'm so glad I learned to trust thee,", "precious Jesus, Savior, friend;", "and I know that thou art with me,", "wilt be with me to the end."]],
        "refrain": ["Jesus, Jesus, how I trust him!", "How I've proved him o'er and o'er!", "Jesus, Jesus, precious Jesus!", "O for grace to trust him more!"],
        "refrainFirst": false
      }, {
        "number": "525",
        "name": "Hiding in Thee",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["O safe to the Rock that is higher than I,", "My soul in its conflicts and sorrows would fly;", "So sinful, so weary, Thine, Thine, would I be;", "Thou blest \"Rock of Ages,\" I'm hiding in Thee."], ["In the calm of the noontide, in sorrow's lone hour,", "In times when temptation casts over me its power;", "In the tempests of life, on its wide, heaving sea,", "Thou blest \"Rock of Ages,\" I'm hiding in Thee."], ["How oft in the conflict, when pressed by the foe,", "I have fled to my refuge and breathed out my woe;", "How often, when trials like sea billows roll,", "Have I hidden in Thee, O Thou Rock of my soul."]],
        "refrain": ["Hiding in Thee, hiding in Thee,", "Thou blest \"Rock of Ages,\"", "I'm hiding in Thee."],
        "refrainFirst": false
      }, {
        "number": "526",
        "name": "Because He Lives",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["God sent His Son, they called Him Jesus,", "He came to love, heal, and forgive;", "He lived and died to buy my pardon,", "An empty grave is there to prove my Savior lives."], ["How sweet to hold a newborn baby,", "And feel the pride, and joy He gives;", "But greater still the calm assurance,", "This child can face uncertain days because He lives."]],
        "refrain": ["Because He lives I can face tomorrow,", "Because He lives all fear is gone;", "Because I know He holds the future.", "And life is worth the living just because He lives."],
        "refrainFirst": false
      }, {
        "number": "527",
        "name": "From Every Stormy Wind",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["From every stormy wind that blows, From every swelling tide of woes,", "There is a calm, a sure retreat: 'Tis found beneath the mercy seat."], ["There is a place where Jesus sheds The oil of gladness on our heads,", "A place than all besides more sweet; It is the blood-bought mercy seat."], ["There is a scene where spirits blend, Where friend holds fellowship with friend;", "Though sundered far, by faith they meet Around one common mercy seat."], ["There, there, on angel's wings we soar, And earthly cares molest no more,", "And heaven comes down our souls to greet, And glory crowns the mercy seat."], ["Ah! whither should we flee for aid, When tempted, desolate dismayed?", "Or how the hosts of sin defeat, Had suffering saints no mercy seat?"]]
      }, {
        "number": "528",
        "name": "A Shelter in the Time of Storm",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["The Lord's our Rock, in Him we hide,", "A shelter in the time of storm;", "Secure whatever ill betide,", "A shelter in the time of storm."], ["A shade by day, defense by night,", "A shelter in the time of storm;", "No fears alarm, no foes affright,", "A shelter in the time of storm."], ["The raging storms may round us beat,", "A shelter in the time of storm", "We'll never leave our safe retreat,", "A shelter in the time of storm."], ["O Rock divine, O Refuge dear,", "A Shelter in the time of storm;", "Be Thou our helper ever near,", "A Shelter in the time of storm."]],
        "refrain": ["Mighty Rock in a weary land,", "Cooling shade on the burning sand,", "Faithful guide for the pilgrim band-", "A shelter in the time of storm."],
        "refrainFirst": false
      }, {
        "number": "529",
        "name": "Under His Wings",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["Under His wings I am safely abiding;", "Though the night deepens and tempests are wild,", "Still I can trust Him; I know He will keep me;", "he has redeemed me, and I am His child."], ["Under His wings, what a refuge in sorrow!", "How the heart yearningly turns to its rest!", "Often when earth has no balm for my healing,", "There I find comfort, and there I am blest."], ["Under His wings, O what precious enjoyment!", "There will I hide till life's trials are over;", "Sheltered, protected, no evil can harm me;", "Resting in Jesus I'm safe evermore."]],
        "refrain": ["Under His wings, under His wings,", "Who from His love can sever?", "Under His wings, my soul shall abide,", "Safely abide forever."],
        "refrainFirst": false
      }, {
        "number": "530",
        "name": "It Is Well With My Soul",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["When peace, like a river, attendeth my way,", "when sorrows like sea billows roll;", "whatever my lot, thou hast taught me to say,", "It is well, it is well with my soul."], ["My sin, -oh, the joy of this glorious thought-", "My sin, not in part but the whole,", "is nailed to the cross, and I bear it no more,", "praise the Lord, praise the Lord, O my soul!"], ["And, Lord, haste the day when my faith shall be sight,", "the clouds be rolled back as a scroll;", "the trump shall resound, and the Lord shall descend,", "even so, it is well with my soul."]],
        "refrain": ["It is well with my soul,", "it is well, it is well with my soul."],
        "refrainFirst": false
      }, {
        "number": "531",
        "name": "We'll Build on the Rock",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["We'll build on the Rock, the living Rock,", "On Jesus, the Rock of Ages;", "So shall we abide the fearful shock,", "When loud the tempest rages."], ["Some build on the sinking sands of life,", "On visions of earthly treasure;", "Some build on the waves of sin and strife,", "Of fame, and worldly pleasure."], ["O build on the Rock, for ever sure,", "The firm and the true foundation;", "Its hope is the hope which shall endure,", "The hope of our salvation."]],
        "refrain": ["We'll build on the Rock,", "We'll build on the Rock;", "We'll build on the Rock, on the solid Rock,", "On Christ, the mighty Rock."],
        "refrainFirst": false
      }, {
        "number": "532",
        "name": "Day by Day",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["Day by day, and with each passing moment,", "Strength I find, to meet my trials here;", "Trusting in my Father's wise bestowment,", "I've no cause for worry or for fear.", "He whose heart is kind beyond all measure", "Gives unto each day what He deems best.", "Lovingly, its part of pain and pleasure,", "Mingling toil with peace and rest."], ["Every day, the Lord Himself is near me", "With a special mercy for each hour;", "All my cares He fain would bear, and cheer me,", "He whose name is Counselor and power.", "The protection of His child and treasure", "Is a charge that on Himself He laid;", "As your days, your strength shall be in measure,", "This the pledge to me He made."], ["Help me then in every tribulation", "So to trust Thy promises, O Lord,", "That I lose not faith's sweet consolation", "Offered me within Thy holy Word.", "Help me, Lord, when toil and trouble meeting,", "E'er to take, as from a father's hand,", "One by one, the days, the moments fleeting,", "Till I reach the promised land."]]
      }, {
        "number": "533",
        "name": "O for a Faith",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["O, for a faith that will not shrink,", "Though pressed by many a foe,", "That will not tremble on the brink of poverty,", "Of poverty or woe; Of poverty or woe;"], ["That will not murmur or complain", "Beneath the chastening rod,", "But in the hour of grief or pain, of grief or pain", "Can lean upon its God; Can lean upon its God."], ["A faith that shines more bright and clear", "When tempests rage without;", "That when in danger knows no fear, knows of no fear,", "In darkness feels no doubt; In darkness feels no doubt."], ["That bears unmoved the world's dread frown,", "Nor heeds its scornful smile;", "That sin's wild ocean cannot drown, no, cannot drown,", "Nor its soft arts beguile; Nor its soft arts beguile."], ["Lord, give me such a faith as this,", "And then, whate'er may come", "I'll taste, e'en here the hallowed bliss, the hallowed bliss", "Of an eternal home; Of an eternal home."]]
      }, {
        "number": "534",
        "name": "Will Your Anchor Hold",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["Will your anchor hold in the storms of life,", "When the clouds unfold their wings of strife?", "When the strong tides lift, and the cables strain,", "Will your anchor drift or firm remain?"], ["It is safely moored, 'twill the storm withstand,", "For 'tis well secured by the Savior's hand;", "And the cables passed from His heart to mine,", "Can defy the blast, through strength divine."], ["It will firmly hold in the straits of fear,", "When the breakers have told the reef is near;", "Though the tempest rave and the wild winds blow,", "Not an angry wave shall our bark o'erflow."], ["It will surely hold in the floods of death,", "When the waters cold chill our latest breath;", "On the rising tide it can never fail,", "While our hopes abide within the veil."], ["When our eyes behold, in the dawning light,", "Shining gates of pearl, our harbor bright,", "We shall anchor fast to the heavenly shore,", "With the storms all past forevermore."]],
        "refrain": ["We have an anchor that keeps the soul", "Steadfast and sure while the billows roll,", "Fastened to the Rock which cannot move,", "Grounded firm and deep in the Savior's love."],
        "refrainFirst": false
      }, {
        "number": "535",
        "name": "I Am Trusting Thee, Lord Jesus",
        "category": "Christian Life",
        "subcategory": "Faith and Trust",
        "verses": [["I am trusting Thee, Lord Jesus,", "Trusting only Thee;", "Trusting Thee for full salvation,", "Great and free."], ["I am trusting Thee for pardon;", "At Thy feet I bow;", "For Thy grace and tender mercy,", "Trusting now."], ["I am trusting Thee to guide me;", "Thou alone shalt lead;", "Every day and hour supplying", "All my need."], ["I am trusting Thee, Lord Jesus;", "Never let me fall;", "I am trusting Thee forever,", "And for all."]]
      }, {
        "number": "536",
        "name": "God, Who Stretched the Spangled Heavens",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [["God, who stretched the spangled heavens", "Infinite in time and place,", "Flung the suns in burning radiance", "Through the silent fields of space;", "We, Your children in Your likeness,", "Share inventive powers with You;", "Great Creator, still creating,", "Show us what we yet may do."], ["We have ventured worlds undreamed of", "Since the childhood of our race;", "Knowing the ecstasy of winging", "Through untraveled realms of space,", "Probed the secrets of the atom,", "Yielding unimagined power,", "Facing us with life's destruction", "Or our most triumphant hour."], ["As each far horizon beckons,", "May it challenge us anew:", "Children of creative purpose,", "Serving others, honoring You.", "May our dreams prove rich with promise;", "Each endeavor well begun;", "Great Creator, give us guidance", "Till our goals and Yours are one."]]
      }, {
        "number": "537",
        "name": "He Leadeth Me",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [["He leadeth me: O blessed thought!", "O words with heavenly comfort fraught!", "Whate'er I do, where'er I be,", "still 'tis God's hand that leadeth me."], ["Sometimes mid scenes of deepest gloom,", "sometimes where Eden's bowers bloom,", "by waters still, o'er troubled sea,", "still 'tis his hand that leadeth me."], ["Lord, I would place my hand in thine,", "nor ever murmur nor repine;", "content, whatever lot I see,", "since 'tis my God that leadeth me."], ["And when my task on earth is done,", "when by thy grace the victory's won,", "e'en death's cold wave I will not flee,", "since God through Jordan leadeth me."]],
        "refrain": ["He leadeth me, he leadeth me,", "by his own hand he leadeth me;", "his faithful follower I would be,", "for by his hand he leadeth me."],
        "refrainFirst": false
      }, {
        "number": "538",
        "name": "Guide Me, O Thou Great Jehovah",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [["Guide me, O thou great Jehovah,", "pilgrim through this barren land.", "I am weak, but thou art mighty;", "hold me with thy powerful hand.", "Bread of heaven, bread of heaven,", "feed me till I want no more;", "feed me till I want no more."], ["Open now the crystal fountain,", "whence the healing stream doth flow;", "let the fire and cloudy pillar", "lead me all my journey through.", "Strong deliverer, strong deliverer,", "be thou still my strength and shield;", "be thou still my strength and shield."], ["When I tread the verge of Jordan,", "bid my anxious fears subside;", "death of death and hell's destruction,", "land me safe on Canaan's side.", "Songs of praises, songs of praises,", "I will ever give to thee;", "I will ever give to thee."]]
      }, {
        "number": "539",
        "name": "I Will Early Seek the Savior",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [["I will early seek the Savior,", "I will learn of Him each day;", "I will follow in His footsteps,", "I will walk the narrow way."], ["I will hasten where He bids me,", "I am not too young to go", "In the pathway where He leadeth,", "Not too young His will to know."], ["He is standing at the doorway", "Of escape from every sin;", "I will knock, for He has promised,", "He will hear and let me in."]],
        "refrain": ["For He loves me, yes, He loves me,", "Jesus loves me, this I know.", "Jesus loves me, died to save me,", "This is why I love Him so."],
        "refrainFirst": false
      }, {
        "number": "540",
        "name": "Gentle Jesus, Meek and Mild",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [["Gentle Jesus, meek and mild,", "Look upon a little child;", "Pity my simplicity,", "Suffer me to come to Thee."], ["Lamb of God, I look to Thee;", "Thou shalt my example be;", "Thou art gentle, meek, and mild,", "Thou wast once a little child."], ["Fain I would be as Thou art;", "Give me Thy obedient heart;", "Thou art pitiful and kind,", "Let me have Thy loving mind."], ["I shall then show forth Thy praise,", "Serve Thee all my happy days;", "Then the world shall always see", "Christ, the Holy Child, in me."]]
      }, {
        "number": "541",
        "name": "Lord, Speak to Me",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [["Lord, speak to me, that I may speak", "in living echoes of thy tone;", "as thou has sought, so let me seek", "thine erring children lost and lone."], ["O lead me, Lord, that I may lead", "the wandering and the wavering feet;", "O feed me, Lord, that I may feed", "thy hungering ones with manna sweet."], ["O strengthen me, that while I stand", "firm on the rock, and strong in thee,", "I may stretch out a loving land", "to wrestlers with the troubled sea."]]
      }, {
        "number": "542",
        "name": "Jesus, Friend So Kind",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [["Jesus, Friend, so kind and gentle,", "Little ones we bring to Thee:", "Grant to them Thy dearest blessing,", "Let Thine arms around them be;", "Now enfold them in Thy goodness,", "From all danger keep them free."], ["Thou who did receive the children", "To Thyself so tenderly,", "Give to all who teach and guide them", "Wisdom and humility.", "Vision true to keep them noble,", "Love to serve them faithfully."]]
      }, {
        "number": "543",
        "name": "Jesus, Friend of Little Children",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [["Jesus, Friend of little children,", "Be a friend to me;", "Take my hand and ever keep me", "Close to Thee."], ["Teach me how to grow in goodness,", "Daily as I grow;", "Thou hast been a child, and surely", "Thou dost know."], ["Step by step, O, lead me onward,", "Upward into youth;", "Wiser, stronger, still becoming", "In Thy truth."], ["Never leave me, nor forsake me;", "Ever be my friend;", "For I need Thee from life's dawning", "To its end."]]
      }, {
        "number": "544",
        "name": "Jesus, Son of Blessed Mary",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [["Jesus, Son of blessed Mary,", "Once on earth a little child,", "Pattern fair of holy living,", "Gracious, loving, undefiled;"], ["Though Thy eager heart was yearning", "Heavy laden souls to free,", "Yet Thou calledst little children", "In their happiness to Thee."], ["Grant that we, like little children,", "Free from pride and guile my be;", "Cheerful, trusting, safe, protected", "By the blessed Trinity."]]
      }, {
        "number": "545",
        "name": "Savior, Like a Shepherd",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [["Savior, like a shepherd lead us,", "much we need thy tenderest care;", "in thy pleasant pastures feed us,", "for our use thy folds prepare.", "Blessed Jesus, blessed Jesus!", "Thou hast bought us, thine we are.", "Blessed Jesus, blessed Jesus!", "Thou hast bought us, thine we are."], ["We are thine, thou dost befriend us,", "be the guardian of our way;", "keep thy flock, from sin defend us,", "seek us when we go astray.", "Blessed Jesus, blessed Jesus!", "Hear, O hear us when we pray.", "Blessed Jesus, blessed Jesus!", "Hear, O hear us when we pray."], ["Thou hast promised to receive us,", "poor and sinful though we be;", "thou hast mercy to relieve us,", "grace to cleanse and power to free.", "Blessed Jesus, blessed Jesus!", "We will early turn to thee.", "Blessed Jesus, blessed Jesus!", "We will early turn to thee."]]
      }, {
        "number": "546",
        "name": "The Lord's My Shepherd",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [["The Lord's my shepherd, I'll not want", "He makes me down to lie", "In pastures green; he leadeth me", "The quiet waters by.", "He leadeth me, He leadeth me", "The quiet waters by."], ["My soul he doth restore again", "And me to walk doth make", "Within the paths of righteousness", "Even for his own name's sake.", "Within the paths of righteousness", "Even for his own name's sake."], ["Yea, though I walk through death's dark vale", "Yet will I fear no ill", "For thou art with me, and thy rod", "And staff me comfort still.", "For thou art with me, and thy rod", "And staff me comfort still."], ["My table thou has furnished", "In presence of my foes;", "My head thou dost with oil anoint", "And my cup overflows.", "My head thou dost with oil anoint", "And my cup overflows."], ["Goodness and mercy all my life", "Shall surely follow me,", "And in God's house for evermore", "My dwelling-place shall be.", "And in God's house for evermore", "My dwelling-place shall be."]]
      }, {
        "number": "547",
        "name": "Be Thou My Vision",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [['Be Thou my Vision, O Lord of my heart;', 'Naught be all else to me, save that Thou art', 'Thou my best Thought, by day or by night,', 'Waking or sleeping, Thy presence my light.'], ['Be Thou my Wisdom, and Thou my true Word;', 'I ever with Thee and Thou with me, Lord;', 'Thou my great Father, I Thy true son;', 'Thou in me dwelling, and I with Thee one.'], ['Riches I heed not, nor man’s empty praise,', 'Thou mine Inheritance, now and always:', 'Thou and Thou only, first in my heart,', 'High King of Heaven, my Treasure Thou art.'], ['High King of Heaven, my victory won,', 'May I reach Heaven’s joys, O bright Heaven’s Sun!', 'Heart of my own heart, whatever befall,', 'Still be my Vision, O Ruler of all.']]
      }, {
        "number": "548",
        "name": "Now Praise the Hidden God of Love",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [["Now praise the hidden God of love", "In whom we all must live and move,", "Who shepherds us, at every stage", "Thro' youth, maturity, and age."], ["Who challenged us, when were young", "To storm the citadels of wrong;", "In care for others taught us how", "God's true community must grow."], ["Who bids us never lose our zest,", "Tho' age is urging us to rest,", "But proves to us that we have still", "A work to do, a place to fill."], ["Then talk no more of wasted time,", "But Godward look, and upward climb,", "Content to sleep, when day is done,", "And rise refreshed, and travel on."]]
      }, {
        "number": "549",
        "name": "Loving Shepherd of Thy Sheep",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [["Loving Shepherd of Thy sheep,", "Keep Thy lamb, in safety keep;", "Nothing can Thy power withstand;", "None can pluck me from Thy hand."], ["Loving Shepherd, ever near,", "Teach Thy lamb Thy voice to hear;", "Suffer not my steps to stray", "From the straight and narrow way."], ["Where Thou leadest I would go,", "Walking in Thy steps below,", "Till within the heavenly fold", "I my Shepherd shall behold."]]
      }, {
        "number": "550",
        "name": "Every Flower That Grows",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [["Every flow'r that grows, Every brook that flows,", "Tell of beauty God has giv'n for me:", "Throughout my life may beauty be", "Deep within a heart from sin set free."], ["Gracious Lord above, Looking down in love,", "Guide my thoughts, my life, in my walk with Thee,", "That day by day the world may see", "Christ, the Lord and Savior, lives in me."], ["All my earthly days, I shall sing and praise", "God the Father, Spirit, and Christ with the Son.", "Grant faith when life on earth is done,", "I shall sing with those whose rest is won."]]
      }, {
        "number": "551",
        "name": "Jesus, Savior, Pilot Me",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [["Jesus, Savior, pilot me", "over life's tempestuous sea;", "unknown waves before me roll,", "hiding rock and treacherous shoal.", "Chart and compass come from thee;", "Jesus, Savior, pilot me."], ["As a mother stills her child,", "thou canst hush the ocean wild;", "boisterous waves obey thy will,", "when thou sayest to them, \"Be still!\"", "Wondrous sovreign of the sea,", "Jesus, Savior, pilot me."], ["When at last I near the shore,", "and the fearful breakers roar", "'twixt me and the peaceful rest,", "then, while leaning on thy breast,", "may I hear thee say to me,", "\"Fear not, I will pilot thee.\""]]
      }, {
        "number": "552",
        "name": "The Lord's My Shepherd",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [["The Lord's my Shepherd, I'll not want.", "He makes me down to lie", "In pastures green; He leadeth me", "The quiet waters by."], ["My soul He doth restore again.", "And me to walk doth make", "Within the paths of righteousness,", "E'en for His own name's sake."], ["Yea, though I walk in death's dark vale,", "Yet will I fear no ill,", "For Thou art with me; and Thy rod", "And staff me comfort still."], ["My table Thou hast furnished", "In presence of my foes;", "My head Thou dost with oil anoint,", "And my cup overflows."], ["Goodness and mercy all my life", "Shall surely follow me;", "And in God's house forevermore", "My dwelling place shall be."]]
      }, {
        "number": "553",
        "name": "Jesus, Guide Our Way",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [["Jesus, guide our way", "To eternal day:", "So shall we, no more delaying,", "Follow Thee, Thy voice obeying:", "Lead us by the hand", "To our Father's land."], ["When we danger meet", "Steadfast make our feet;", "Lord, preserve us uncomplaining", "Mid the darkness round us reigning:", "Through adversity lies our way to Thee."], ["Order all our way", "Through the mortal day:", "In our toil, with aid be near us:", "In our need, with succour cheer us:", "Till we safely stand", "In our Father's land."]]
      }, {
        "number": "554",
        "name": "O Let Me Walk With Thee",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [["O let me walk with Thee, my God,", "As Enoch walked in days of old;", "Place Thou my trembling hand in Thine,", "And sweet communion with me hold;", "E'en though the path I may not see,", "Yet, Jesus, let me walk with Thee."], ["I cannot, dare not, walk alone;", "The tempest rages in the sky,", "A thousand snares beset my feet,", "A thousand foes are lurking nigh.", "Still Thou the raging of the sea,", "O Master! let me walk with Thee."], ["If I may rest my hand in Thine,", "I'll count the joys of earth but loss,", "And firmly, bravely journey on;", "I'll bear the banner of the cross", "Till Zion's glorious gates I see;", "Yet, Savior, let me walk with Thee."]]
      }, {
        "number": "555",
        "name": "Shepherd of Tender Youth",
        "category": "Christian Life",
        "subcategory": "Guidance",
        "verses": [["Shepherd of tender you,", "Guiding in love and truth,", "Through devious way;", "Christ our triumphant King,", "We come Thy name to sin,", "Hither our children bring", "To shout Thy praise."], ["Thou are our holy Lord,", "Thee all subduing Word,", "Healer of strife;", "Thou didst Thyself abase,", "That from sin's deep disgrace", "Thou mightest save our race,", "And give us life."], ["Thou are the great High Priest;", "Thou has prepared the feast", "Of heavenly love;", "While in our mortal pain", "None call on Thee in vain;", "Help Thou does not disdain,", "Help from above."], ["Ever be Thou our Guide,", "Our Shepherd and our pride,", "Our staff and song;", "Jesus, Thou Christ of God,", "By Thy perennial word,", "Lead us where Thou hast trod,", "Make our faith strong."]]
      }, {
        "number": "556",
        "name": "As Saints of Old",
        "category": "Christian Life",
        "subcategory": "Thankfulness",
        "verses": [["As saints of old their first fruits brought", "Of orchard, flock, and field", "To God the giver all good,", "The source of bounteous yield;", "So we today first fruits would bring:", "The wealth of this good land,", "Of farm and market, shop and home,", "Of mind, and heart, and hand."], ["A world in need now summons us", "To labor, love, and give;", "To make our life an offering", "To God, that all may live.", "The Church of Christ is calling us", "To make the dream come true:", "A world redeemed by Christ-like love;", "All life in Christ made new."], ["In gratitude and humble trust", "We bring our best today,", "To serve Your cause and share Your love", "With all along life's way.", "O God, who gave yourself to us", "In Jesus Christ Your Son,", "Teach us to give ourselves each day", "Until life's work is done."]]
      }, {
        "number": "557",
        "name": "Come, Ye Thankful People",
        "category": "Christian Life",
        "subcategory": "Thankfulness",
        "verses": [["Come, ye thankful people, come,", "Raise the song of harvest home;", "All is safely gathered in,", "Ere the winter storms begin.", "God our Maker doth provide", "For our wants to be supplied;", "Come to God's own temple, come;", "Raise the song of harvest home!"], ["We ourselves are God's own field,", "Fruit unto His praise to yield;", "Wheat and tares together sown,", "Unto joy or sorrow grown;", "First the blade and then the ear,", "Then the full corn shall appear;", "Grant, O harvest Lord, that we", "Wholesome grain and pure may be."], ["For the Lord our God shall come,", "And shall take His harvest home;", "From His field shall purge away", "All that doth offend, that day;", "Give His angels charge at last", "In the fire the tares to cast;", "But the fruitful ears to store", "In His garner evermore."], ["Then, thou church triumphant, come,", "Raise the song of harvest home;", "All are safely gathered in,", "Free from sorrow, free from sin,", "There, forever purified,", "In God's garner to abide;", "Come, ten thousand angels, come,", "Raise the glorious harvest home!"]]
      }, {
        "number": "558",
        "name": "For the Fruits of His Creation",
        "category": "Christian Life",
        "subcategory": "Thankfulness",
        "verses": [["For the fruits of His creation, thanks be to God;", "For the gifts to every nation, thanks be to God;", "For the plowing, sowing, reaping,", "Silent growth while men are sleeping,", "Future needs in earth's safe keeping, thanks be to God!"], ["In the just reward of labor, God's will is done;", "In the hop we give our neighbor, God's will is done;", "In our worldwide take of caring", "For the hungry and despairing,", "In the harvests men are sharing, God's will is done."], ["For the harvests of His Spirit, thanks be to God;", "For the good all men inherit, thanks be to God;", "For the wonders that astound us,", "For the truths that still confound us,", "Most of all, that love has found us, thanks be to God!"]]
      }, {
        "number": "559",
        "name": "Now Thank We All Our God",
        "category": "Christian Life",
        "subcategory": "Thankfulness",
        "verses": [["Now thank we all our God,", "with heart and hands and voices,", "who wondrous things has done,", "in whom this world rejoices;", "who from our mothers' arms", "has blessed us on our way", "with countless gifts of love,", "and still is ours today."], ["O may this bounteous God", "through all our life be near us,", "with ever joyful hearts", "and blessed peace to cheer us;", "and keep us still in grace,", "and guide us when perplexed;", "and free us from all ills,", "in this world and the next."], ["All praise and thanks to God", "the Father now be given;", "the Son, and him who reigns", "with them in highest heaven;", "the one eternal God,", "whom earth and heaven adore;", "for thus it was, is now,", "and shall be evermore."]]
      }, {
        "number": "560",
        "name": "Let All Things Now Living",
        "category": "Christian Life",
        "subcategory": "Thankfulness",
        "verses": [["Let all things now living a song of thanksgiving", "To God the Creator triumphantly raise,", "Who fashioned and made us, protected and stayed us,", "Who guideth us on to the end of our days.", "His banners are o'er us,", "His light goes before us,", "A pillar of fire shining forth in the night,", "'Til shadows have vanished and darkness is banished,", "as forward we travel from light into light."], ["His law He enforces: the stars in their courses,", "The sun in His orbit, obediently shine;", "The hills and the mountains, the rivers and fountains,", "The deeps of the ocean proclaim Him divine,", "We too should be voicing our love and rejoicing,", "With glad adoration a song let us raise,", "'Til all things now living unite in thanksgiving", "To God in the highest, hosanna and praise."]]
      }, {
        "number": "561",
        "name": "We Plow the Fields",
        "category": "Christian Life",
        "subcategory": "Thankfulness",
        "verses": [["We plow the fields, and scatter the good seed on the land,", "But it is fed and watered by God's almighty hand.", "He sends the snow in winter, the warmth to swell the grain,", "The breezes and the sunshine, and soft refreshing rain."], ["He only is the Maker of all things near and far;", "He paints the wayside flower, He lights the evening star.", "The winds and waves obey Him, by Him the birds are fed;", "Much more, to us His children, He gives our daily bread."], ["We thank Thee then, O Father, for all things bright and good,", "The seed-time and the harvest, our life, our health, and food.", "Accept the gifts we to offer for all Thy love imparts,", "And, what Thou most desirest, our humble, thankful hearts."]],
        "refrain": ["All good gifts around us", "Are sent from heaven above;", "Then thank the Lord, O thank the Lord", "For all His love."],
        "refrainFirst": false
      }, {
        "number": "562",
        "name": "Come, Sing a Song of Harvest",
        "category": "Christian Life",
        "subcategory": "Thankfulness",
        "verses": [["Come, sing a song of harvest, Of thanks for daily food!", "To offer God the first-fruits is old as gratitude."], ["Long, long ago, the reapers, before they kept the feast,", "Put first-fruits in a basket, and took it to the priest."], ["Shall we, sometimes forgetful oh where creation starts,", "With science in our pockets lose wonder from our hearts?"], ["May God, the great Creator, to whom all life belongs,", "Accept these gifts we offer, our service and our songs."], ["And lest the world go hungry while we ourselves are fed,", "Make each of us more ready to share our daily bread."]]
      }, {
        "number": "563",
        "name": "Praise and Thanksgiving",
        "category": "Christian Life",
        "subcategory": "Thankfulness",
        "other": [["Praise and thanksgiving let everyone bring", "Unto our Father for every good thing.", "All together joyfully sing!"]]
      }, {
        "number": "564",
        "name": "For Sunrise Hope and Sunset Calm",
        "category": "Christian Life",
        "subcategory": "Thankfulness",
        "verses": [["For sunrise hope and sunset calm, and all that lies between,", "For all the sweetness and the balm that is and that has been,", "For comradeship for peace in strife, and light on darkened days;", "For work to do and strength for life we sing our hymn of praise."], ["But O, we press far, far above these gifts of pure delight,", "And find in Thee, and in Thy love contentment infinite.", "O Lord, beloved, in whom are found all joys of time and place,", "What will it be when joy is crowned by vision of Thy face?"]]
      }, {
        "number": "565",
        "name": "For the Beauty of the Earth",
        "category": "Christian Life",
        "subcategory": "Thankfulness",
        "verses": [["For the beauty of the earth,", "for the glory of the skies,", "for the love which from our birth", "over and around us lies;", "Lord of all, to Thee we raise", "this our grateful song of praise."], ["For the joy of human love,", "brother, sister, parent, child,", "friends on earth and friends above,", "Pleasures pure and undefiled,", "Lord of all, to Thee we raise", "this our grateful song of praise."], ["For the gift of Thy dear Son,", "for the hope of heaven at last,", "for the Spirit's victory won,", "For the crown when life is past,", "Lord of all, to Thee we raise", "Songs of gratitude and praise."]]
      }, {
        "number": "566",
        "name": "Father, We Thank You",
        "category": "Christian Life",
        "subcategory": "Thankfulness",
        "verses": [["Father, we thank You for the light that shines all the day;", "For the bright sky You have given most like Your heaven;", "Father, we thank You."], ["Father, we thank You for the lamps that lighten the way;", "For human skill's exploration of Your creation;", "Father, we thank You."], ["Father, we thank You for the friends who brighten our play;", "For Your command to call others sisters and brothers;", "Father, we thank You."], ["Father, we thank You for Your love in Jesus today,", "Giving us hope for tomorrow through joy and sorrow;", "Father, we thank You."]]
      }, {
        "number": "567",
        "name": "Have Thine Own Way, Lord",
        "category": "Christian Life",
        "subcategory": "Humility",
        "verses": [["Have thine own way, Lord! Have thine own way!", "Thou art the potter, I am the clay.", "Mold me and make me after thy will,", "while I am waiting, yielded and still."], ["Have thine own way, Lord! Have thine own way!", "Search me and try me, Savior today!", "Wash me just now, Lord, wash me just now,", "as in thy presence humbly I bow."], ["Have thine own way, Lord! Have thine own way!", "Wounded and weary, help me I pray!", "Power, all power, surely is thine!", "Touch me and heal me, Savior divine!"], ["Have thine own way, Lord! Have thine own way!", "Hold o'er my being absolute sway.", "Fill with thy Spirit till all shall see", "Christ only, always, living in me!"]]
      }, {
        "number": "568",
        "name": "Make Me a Captive, Lord",
        "category": "Christian Life",
        "subcategory": "Humility",
        "verses": [["Make me a captive, Lord, and then I shall be free;", "Force me to render up my sword, and I shall conqueror be.", "I sink in life's alarms when by myself I stand;", "Imprison me within Thine arms, and strong shall be my hand."], ["My heart is weak and poor until it a master find;", "It has no spring of action sure, it varies with the wind.", "It cannot freely move till Thou has wrought its chain;", "Enslave it with Thy matchless love, and deathless it shall reign."], ["My will is not my own till Thou hast made it Thine;", "If it would reach a monarch's throne, it must its crown resign;", "It only stands unbent, amid the clashing strife,", "When on Thy bosom it has leant, and found in Thee its life."]]
      }, {
        "number": "569",
        "name": "Pass Me Not, O Gentle Savior",
        "category": "Christian Life",
        "subcategory": "Humility",
        "verses": [["Pass me not, O gentle Savior,", "hear my humble cry;", "while on others thou art calling,", "do not pass me by."], ["Let me at thy throne of mercy", "find a sweet relief,", "kneeling there in deep contrition;", "help my unbelief."], ["Trusting only in thy merit,", "would I seek thy face;", "heal my wounded, broken spirit,", "save me by thy grace."], ["Thou the spring of all my comfort,", "more than life to me,", "whom have I on earth beside thee?", "Whom in heaven but thee?"]],
        "refrain": ["Savior, Savior, hear my humble cry;", "while on others thou art calling,", "do not pass me by."],
        "refrainFirst": false
      }, {
        "number": "570",
        "name": "Not I, But Christ",
        "category": "Christian Life",
        "subcategory": "Humility",
        "verses": [["Not I, but Christ, be honored, loved, exalted;", "Not I, but Christ, be seen, be known, be heard;", "Not I, but Christ, in every look and action,", "Not I, but Christ, in every thought and word."], ["Not I, but Christ, to gently soothe in sorrow,", "Not I, but Christ, to wipe the falling tear;", "Not I, but Christ, to lift the weary burden,", "Not I, but Christ, to hush away all fear."], ["Christ, only Christ! No idle words e'er falling,", "Christ, only Christ; no needless bustling sound;", "Christ, only Christ, no self important bearing;", "Christ, only Christ, no trace of \"I\" be found."], ["Not I, but Christ, my every need supplying,", "Not I, but Christ, my strength and health to be;", "Christ, only Christ, for body, soul, and spirit,", "Christ, only Christ, here and eternally."]]
      }, {
        "number": "571",
        "name": "What Does the Lord Require?",
        "category": "Christian Life",
        "subcategory": "Loving Service",
        "verses": [["What does the Lord require for praise and offering?", "What sacrifice, desire or tribute bid you bring?", "Do justly; Love mercy; Walk humbly with your God."], ["Rulers of men, give ear! Should you not justice show?", "Will God your pleading hear, while crime and cruelty grow?", "Do justly; Love mercy; Walk humbly with your God."], ["How shall our life fulfill God's law so hard and high?", "Let Christ endue our will with grace to fortify.", "Then justly, in mercy we'll humbly walk with God."]]
      }, {
        "number": "572",
        "name": "Give of Your Best to the Master",
        "category": "Christian Life",
        "subcategory": "Loving Service",
        "verses": [["Give of your best to the master,", "Give of the strength of your youth;", "Throw your soul's fresh, glowing ardor", "Into the battle for truth.", "Jesus has set the example -", "Dauntless was He, young and brave;", "Give Him your loyal devotion,", "Give Him the best that you have"], ["Give of your best to the Master,", "Give Him first place in your heart;", "Give Him first place in your service,", "Consecrate every part.", "Give, and to you shall be given -", "God His beloved Son gave;", "Gratefully seeking to serve Him,", "Give Him the best that you have."], ["Give of your best to the Master,", "Naught else is worthy His love;", "He gave Himself for your ransom,", "Have up His glory above;", "Laid down His life without murmur,", "You from sin's ruin to save;", "Give Him your heart's adoration,", "Give Him the best that you have."]],
        "refrain": ["Give of your best to the master,", "Give of the strength of your youth;", "Clad in salvation's full armor,", "Join in the battle for truth."],
        "refrainFirst": false
      }, {
        "number": "573",
        "name": "I'll Go Where You Want Me to Go",
        "category": "Christian Life",
        "subcategory": "Loving Service",
        "verses": [["It may not be on the mountain height", "Or over the stormy sea,", "It may not be at the battle's front", "My Lord will have need of me.", "But if, by a still, small voice he calls", "To paths that I do not know,", "I'll answer, dear Lord, with my hand in thine;", "I'll go where you want me to go."], ["Perhaps today there are loving words", "Which Jesus would have me speak", "There may be now in the paths of sin", "Some wand'rer whom I should seek.", "O Savior, if thou wilt be my guide,", "Tho dark and rugged the way,", "My voice shall echo the message sweet;", "I'll say what you want me to say."], ["There's surely somewhere a lowly place", "In earth's harvest fields so wide", "Where I may labor through life's short day", "For Jesus, the Crucified.", "So trusting my all to thy tender care,", "And knowing thou lovest me,", "I'll do thy will with a heart sincere:", "I'll be what you want me to be."]],
        "refrain": ["I'll go where you want me to go, dear Lord,", "Over mountain or plain or sea;", "I'll say what you want me to say, dear Lord;", "I'll be what you want me to be."],
        "refrainFirst": false
      }, {
        "number": "574",
        "name": "O Master, Let Me Walk With Thee",
        "category": "Christian Life",
        "subcategory": "Loving Service",
        "verses": [["O Master, let me walk with thee", "in lowly paths of service free;", "tell me thy secret; help me bear", "the strain of toil, the fret of care."], ["Help me the slow of heart to move", "by some clear, winning word of love;", "teach me the wayward feet to stay,", "and guide them in the homeward way."], ["Teach me thy patience; still with thee", "in closer, dearer company,", "in work that keeps faith sweet and strong,", "in trust that triumphs over wrong;"], ["In hope that sends a shining ray", "far down the future's broadening way,", "in peace that only thou canst give,", "with thee, O Master, let me live."]]
      }, {
        "number": "575",
        "name": "Let Your Heart Be Broken",
        "category": "Christian Life",
        "subcategory": "Loving Service",
        "verses": [["Let your heart be broken for a world in need:", "Feed the mouths that hunger, soothe the wounds that bleed,", "Give the cup of water and the loaf of bread", "Be the hands of Jesus, serving in His stead."], ["Here on earth applying principles of love,", "Visible expression - God still rules above", "Living illustration of the Living Word", "To the minds of all who've Never seen or heard."], ["Blest to be a blessing privileged to care,", "Challenged by the need apparent everywhere.", "Where mankind is wanting, fill the vacant place.", "Be the means through which the Lord reveals His grace."], ["Add to your believing deeds that prove it true,", "Knowing Christ as Savior, make Him Master, too.", "Follow in His footsteps, go where He has trod;", "In the world's great trouble risk yourself for God."], ["Let your heart be tender and your vision clear;", "See mankind as God sees, serve Him far and near.", "Let your heart be broken by a brother's pain;", "Share your rich resources, give and give again."]]
      }, {
        "number": "576",
        "name": "Awake, Awake to Love and Work",
        "category": "Christian Life",
        "subcategory": "Loving Service",
        "verses": [["Awake, awake to love and work!", "The lark is in the sky;", "The fields are wet with diamond dew;", "The worlds awake to cry", "Their blessings on the Lord of life,", "As He goes meekly by."], ["Come, let thy voice be one with theirs,", "Shout with their shout of praise;", "See how the giant sun soars up,", "Great lord of years and days!", "So let the love of Jesus come", "And set thy soul ablaze."], ["To give and give, and give again,", "What God hath given thee;", "To spend thyself nor count the cost;", "To serve right gloriously", "The God who gave all worlds that are,", "And all that are to be."]]
      }, {
        "number": "577",
        "name": "In the Heart of Jesus",
        "category": "Christian Life",
        "subcategory": "Loving Service",
        "verses": [["In the heart of Jesus there is love for you,", "Love most pure and tender, love most deep and true;", "Why should you be lonely, why for friendship sigh,", "When the heart of Jesus has a full supply?"], ["In the mind of Jesus there is thought for you,", "Warm as summer sunshine, sweet as morning dew;", "Why should you be fearful, why take anxious thought,", "Since the mind of Jesus cares for those He bought?"], ["In the field of Jesus there is work for you;", "Such as even angels might rejoice to do;", "Why stand idly sighing for some lifework grand,", "While the field of Jesus seeks your reaping hand?"], ["In the home of Jesus there's a place for you;", "Glorious, bright, and joyous, calm and peaceful too;", "Why then, like a wanderer, roam with weary pace,", "If the home of Jesus holds for you a place?"]]
      }, {
        "number": "578",
        "name": "So Send I You",
        "category": "Christian Life",
        "subcategory": "Loving Service",
        "verses": [["So sent I you- by grace made strong to triumph", "O'er hosts of hell, o'er darkness, death, and sin,", "May name to bear, and in that name to conquer-", "So send I you, My victory to win."], ["So send I you- to take to souls in bondage", "The word of truth that sets the captive fee,", "To break the bonds of sin, to loose dearth's fetters-", "So send I you, to bring the lost to Me."], ["So send I you- My strength to know in weakness,", "My joy in grief, my perfect peace in pain,", "To prove My power, My grace, My promised presence-", "So send I you, eternal fruit to gain."], ["So send I you- to bear My cross with patience,", "And then one day with joy to lay it down,", "To hear My voice, \"Well done, My faithful servant-", "Come, share My throne, My kingdom, and My crown!\"", "\"As the Father hath sent Me, so send I you.\""]]
      }, {
        "number": "579",
        "name": "'Tis Love That Makes Us Happy",
        "category": "Christian Life",
        "subcategory": "Loving Service",
        "verses": [["'Tis love that makes us happy,", "'Tis love that smooths the way;", "It helps us mind, it makes us kind", "To others every day."], ["This world is full of sorrow,", "Of sickness, death, and sin;", "With loving heart we'll do our part,", "And try some soul to win."], ["And when this life is over,", "And we are called above", "Our song shall be, eternally,", "Of Jesus and His love."]],
        "refrain": ["God is love; we're his little children.", "God is love; we would be like Him.", "'Tis love that makes us happy,", "'Tis love that smooths the way;", "It helps us \"mind,\" it makes us kind", "To others every day."],
        "refrainFirst": false
      }, {
        "number": "580",
        "name": "This Little Light of Mine",
        "category": "Christian Life",
        "subcategory": "Loving Service",
        "verses": [["This little light of mine, I'm going to let it shine, (shine)", "This little light of mine, I'm going to let it shine, (shine)", "This little light of mine, I'm going to let it shine,", "Let it shine, let it shine, let it shine."], ["Every where I go, I'm going to let it shine, (shine)", "Every where I go, I'm going to let it shine, (shine)", "Every where I go, I'm going to let it shine,", "Let it shine, let it shine, let it shine."], ["All through the night, I'm going to let it shine, (shine)", "All through the night, I'm going to let it shine, (shine)", "All through the night, I'm going to let it shine,", "Let it shine, let it shine, let it shine."]]
      }, {
        "number": "581",
        "name": "When the Church of Jesus",
        "category": "Christian Life",
        "subcategory": "Loving Service",
        "verses": [["When the church of Jesus shuts its outer door,", "Lest the roar of traffic drown the voice of prayer:", "May our prayers, Lord make up ten times more aware", "That the world we banish is our Christian care."], ["If our hearts are lifted where devotion soars", "High above this hungry suffering world of ours:", "Lest our hymns should drug us to forget its needs,", "Forge our Christian worship into Christian deeds."], ["Lest the gifts we offer, money, talents, time,", "Serve to salve our conscience to our secret shame:", "Lord, reprove, inspire us by the way you give;", "Teach us, dying Savior, how true Christians live."]]
      }, {
        "number": "582",
        "name": "Working, O Christ, With Thee",
        "category": "Christian Life",
        "subcategory": "Loving Service",
        "verses": [["Working, O Christ, with Thee, working with Thee,", "Unworthy, sinful, weak, though we may be;", "Our all to Thee we give, for Thee alone we live,", "And by Thy grace achieve, working with Thee."], ["Along the city's waste, working with Thee,", "Our eager footsteps haste, like Thee to be;", "The poor we gather in, the outcasts raise from sin,", "And labor souls to win, working with Thee."], ["Savior, we weary not, working with Thee,", "As hard as Thine our lot can never be;", "Our joy and comfort this, \"Thy grace sufficent is;\"", "This changes toil to bliss, working with Thee."], ["So let us labor on, working with Thee,", "Till earth to Thee is won, from sin set free;", "Till men, from shore to shore, receive Thee, and adore,", "And join us evermore, working with Thee."]]
      }, {
        "number": "583",
        "name": "You That Know the Lord",
        "category": "Christian Life",
        "subcategory": "Loving Service",
        "verses": [["You that know the Lord is gracious,", "You for whom a cornerstone", "Stands, of God elect and precious,", "Laid that you may build there-on,", "See that on that sure foundation", "You a living temple raise.", "Towers that may tell forth salvation,", "Walls that may reecho praise."], ["Living stones by God appointed", "Each to his allotted place,", "Kings and priests, by God anointed,", "Shall you not declare His grace?", "You, a royal generation,", "Tell the tidings of your birth,", "Tidings of a new creation", "To an old and weary earth."], ["Tell the praise of Him who called you", "Out of darkness into light,", "Broke the fetters that enthralled you,", "Gave you freedom, peace, and sight:", "Tell the tale of sins forgiven,", "Strength renewed and hope restored,", "Till the earth, in tune with heaven,", "Praise and magnify the Lord."]]
      }, {
        "number": "584",
        "name": "There's a Spirit in the Air",
        "category": "Christian Life",
        "subcategory": "Loving Service",
        "verses": [["There's a Spirit in the air, telling Christians everywhere", "\"Praise the love that Christ revealed, living, working in our world.\""], ["Lose your shyness, find your tongue; tell the world what God has done:", "God in Christ has come to stay, we can see His pow'r today."], ["When believers break the bread, when a hungry child is fed:", "Praise the love that Christ revealed, living, working in our world."], ["Still His Spirit leads the fight, seeing wrong and setting right:", "God in Christ has come to stay, we can see His pow'r today."], ["When a stranger's not alone, where the homeless find a home,", "Praise the love that Christ revealed, living, working in our world."], ["May His Spirit fill our praise, guide our thoughts and change our ways.", "God in Christ has come to stay, we can see His power today."], ["There's a Spirit in the air, calling people everywhere;", "Praise the love that Christ revealed: living, working in our world."]]
      }, {
        "number": "585",
        "name": "When Christ Was Lifted From the Earth",
        "category": "Christian Life",
        "subcategory": "Love for One Another",
        "verses": [["When Christ was lifted from the earth His arms stretched out above", "Through every culture, every birth, to draw an answering love."], ["Still east and west His love extends and always, near or far,", "He calls and claims us as His friends and loves us as we are."], ["Where generation, class, or race divides us to our shame,", "He sees not labels but a face, a person and a name."], ["Thus freely loved, tho' fully known, may I in Christ be free", "To welcome and accept His own as Christ accepted me."]]
      }, {
        "number": "586",
        "name": "What Joy It Is to Worship Here",
        "category": "Christian Life",
        "subcategory": "Love for One Another",
        "verses": [["What joy it is to worship here,", "And find ourselves at home,", "Where God, who uses every gift,", "Has room for all who come!"], ["Yet are no two of us alike", "Of all the human race,", "And we must seek a common ground", "If we would share His grace."]]
      }, {
        "number": "587",
        "name": "In Christ There Is No East nor West",
        "category": "Christian Life",
        "subcategory": "Love for One Another",
        "verses": [["In Christ there is no east nor west,", "In Him no south or north;", "But one great fellowship of love", "Throughout the whole wide earth."], ["In Him shall true heart everywhere", "Their high communion find;", "His service is the golden cord", "Close binding all mankind."], ["Join hands, then, brothers of the faith,", "Whate'er your race may be.", "Who serves my Father as a son", "Is surely kin to me."], ["In Christ now meet both east and west,", "In Him meet south and north;", "All Christly souls are one in Him", "Throughout the whole wide earth."]]
      }, {
        "number": "588",
        "name": "Lord of All Nations",
        "category": "Christian Life",
        "subcategory": "Love for One Another",
        "verses": [["Lord of all nations, grant me grace", "To love all people, every race,", "And in each person may I see", "My kindred loved, redeemed by Thee."], ["Break down the wall that would divide", "Thy children, Lord, on every side.", "My neighbor's good let me pursue;", "Let Christian love bind warm and true."], ["Forgive me, Lord, where I have erred", "By loveless act and thoughtless word.", "Make me to see the wrong I do", "Will crucify my Lord anew."], ["Give me Thy courage, Lord, to speak", "Whenever strong oppress the weak.", "Should I myself the victim be,", "Help me forgive, remembering Thee."], ["With Thine own love may I be filled", "And by Thy Holy Spirit willed,", "That all I touch, where'er I be,", "May be divinely touched by Thee."]]
      }, {
        "number": "589",
        "name": "Holy Spirit, Gracious Guest",
        "category": "Christian Life",
        "subcategory": "Love for One Another",
        "verses": [["Holy Spirit, gracious guest,", "Hear and grant our heart's request", "For that gift supreme and best:", "Holy heav'nly love."], ["Faith that mountains could remove,", "Tongues of earth or heaven above,", "Knowledge, all things, empty prove", "If I have no love."], ["Though I as a martyr bleed,", "Give my goods the poor to feed,", "All is vain if love I need:", "Therefore give me love."], ["Love is kind and suffers long,", "Love is pure and thinks no wrong,", "Love than death it self more strong:", "Therefore give us love."], ["Prophecy will fade away,", "Melting in the light of day;", "Love will ever with us stay:", "Therefore give us love."], ["Faith and hope and love we see", "Joining hand in hand agree-", "But the greatest of the three,", "And the best is love."]]
      }, {
        "number": "590",
        "name": "Trust and Obey",
        "category": "Christian Life",
        "subcategory": "Obedience",
        "verses": [["When we walk with the Lord", "in the light of his word,", "what a glory he sheds on our way!", "While we do his good will,", "he abides with us still,", "and with all who will trust and obey."], ["Not a shadow can rise,", "not a cloud in the skies,", "but his smile quickly drives it away;", "not a doubt nor a fear,", "not a sigh nor a tear,", "can abide while we trust and obey."], ["Not a burden we bear,", "not a sorrow we share,", "but our toil he doth richly repay;", "not a grief or a loss,", "not a frown or a cross,", "but is blest if we trust and obey."], ["But we never can prove", "the delights of his love", "until all on the altar we lay;", "for the favor he shows,", "and the joy he bestows,", "are for them who will trust and obey.", "(Refrain)"], ["Then in fellowship sweet", "we will sit at his feet,", "or we'll walk by his side in the way;", "what he says we will do,", "where he sends we will go;", "never fear, only trust and obey."]],
        "refrain": ["Trust and obey, for there's no other way", "to be happy in Jesus, but to trust and obey."],
        "refrainFirst": false
      }, {
        "number": "591",
        "name": "In Our Work and in Our Play",
        "category": "Christian Life",
        "subcategory": "Obedience",
        "verses": [["In our work and in our play,", "Jesus, ever with us stay;", "May be always strive to be", "True and faithful unto Thee.", "Then we truthfully can sing,", "We are children of the King."], ["May we in Thy strength subdue", "Evil tempers, words untrue,", "Thoughts impure, and deeds unkind,", "All things hateful to Thy mind.", "Then we truthfully can sing,", "We are children of the King."], ["Children of the King are we!", "May we loyal to Him be;", "Try to please Him every day,", "In our work and in our play.", "Then we truthfully can sing,", "We are children of the King."]]
      }, {
        "number": "592",
        "name": "Watchman, Tell Us of the Night",
        "category": "Christian Life",
        "subcategory": "Watchfulness",
        "verses": [["Watchman, tell us of the night what its signs of promise are.", "Traveler, o'er yon mountain's height, see that glory beaming star.", "Watchman, does its beauteous ray aught of joy or hope foretell?", "Traveler, yes; it brings the day, promised day of Israel."], ["Watchman, tell us of the night, higher yet that star ascends.", "Traveler, blessedness and light, peace and truth its course portends.", "Watchman, will its beams alone gild the spot that gave them birth?", "Traveler, ages are its own; See it bursts o'er all the earth."], ["Watchman, tell us of the night, for the morning seems to dawn.", "Traveler, darkness takes its flight, doubt and terror are withdrawn.", "Watchman, let your wanderings cease; hasten to your quiet home.", "Traveler, lo, the Prince of Peace, lo, the Son of God is come!"]]
      }, {
        "number": "593",
        "name": "In Times Like These",
        "category": "Christian Life",
        "subcategory": "Watchfulness",
        "verses": [["In times like these you need a Savior,", "In times like these you need and anchor;", "Be very sure, be very sure Your anchor holds and grips the Solid rock!", "This Rock is Jesus, Yes, He's the One;", "This Rock is Jesus, The only One!", "Be very sure, be very sure Your anchor holds and grips the Solid rock!"], ["In times like these you need the Bible,", "In times like these O be not idle;", "Be very sure, be very sure Your anchor holds and grips the Solid rock!", "This Rock is Jesus, Yes, He's the One;", "This Rock is Jesus, The only One!", "Be very sure, be very sure Your anchor holds and grips the Solid rock!"], ["In times like these I have a Savior,", "In times like these I have an anchor", "I'm very sure, be very sure Your anchor holds and grips the Solid rock!", "This Rock is Jesus, Yes, He's the One;", "This Rock is Jesus, The only One!", "I'm very sure, be very sure Your anchor holds and grips the Solid rock!"]]
      }, {
        "number": "594",
        "name": "Heir of the Kingdom",
        "category": "Christian Life",
        "subcategory": "Watchfulness",
        "verses": [["Heir of the kingdom, O why dost thou slumber?", "Why art thou sleeping so near thy blest home?", "Wake thee, arouse thee, and gird on thine armor,", "Speed, for the moments are hurrying on."], ["Heir of the kingdom, say, why dost thou linger?", "How canst thou tarry in sight of the prize?", "Up, and adorn thee, the Savior is coming;", "Haste to receive Him descending the skies."], ["Earth's mighty nations, in strife and commotion,", "Tremble with terror, and sink in dismay;", "Listen, 'tis nought but the chariot's loud rumbling;", "Heir of the kingdom, no longer delay."], ["Stay not, O stay not for earth's vain allurements!", "See how its glory is passing away;", "Break the strong fetters the foe hath bound o'er thee;", "Heir of the kingdom, turn, turn thee away."], ["Keep the eye single, the head upward lifted;", "Watch for the glory of earth's coming King;", "Lo! o'er the mountaintops light is now breaking;", "Heirs of the kingdom, rejoice ye and sing."]]
      }, {
        "number": "595",
        "name": "Let Every Lamp Be Burning",
        "category": "Christian Life",
        "subcategory": "Watchfulness",
        "verses": [["Let every lamp be burning bright,", "The darkest hour is nearing;", "The darkest hour of earth's long night,", "Before the Lord's appearing."], ["Though thousands calmly slumber on,", "The last great message spuring,", "Wel'll rest our living faith upon", "His promise of returning."], ["His word our lamp, His truth our guide,", "We cannot be mistaken;", "Though dangers rise on every side,", "We shall not be forsaken."], ["Then let good works with faith appear,", "To help the world atound us;", "Obedience bring the blessing near", "When faith has firmly bound us."]],
        "refrain": ["Then trim your lamps, my brethren dear,", "Then trim your lamps with godly fear;", "The Master's coming draweth near,", "Let every lamp be burning."],
        "refrainFirst": false
      }, {
        "number": "596",
        "name": "Look for the Waymarks",
        "category": "Christian Life",
        "subcategory": "Watchfulness",
        "verses": [["Look for the way-marks as you journey on,", "Look for the way-marks passing one by one;", "Down through the ages, past the kingdoms four-", "Where are we standing? Look the way-marks o'er."], ["First, the Assyrian kingdom ruled the world,", "Then Medo-Persia's banners were unfurled;", "And after Greece held universal sway,", "Rome seized the scepter-where are we today?"], ["Down in the feet of iron and of clay,", "Weak and divided, soon to pass away;", "What will the next great, glorious drama be?", "Christ and His coming, and eternity."]],
        "refrain": ["Look for the way-marks, the great prophetic way-marks,", "Down through the ages, past the kingdoms four.", "Look for the waymarks, the great prophetic way-marks;", "The journey's almost o'er."],
        "refrainFirst": false
      }, {
        "number": "597",
        "name": "Ye Servants of the Lord",
        "category": "Christian Life",
        "subcategory": "Watchfulness",
        "verses": [["Ye servants of the Lord,", "Each in his office wait,", "Observant of His heavenly Word,", "And watchful at His gate."], ["Let all your lamps be bright,", "And trim the golden flame,", "Gird up your loins as in His sight,", "His coming thus proclaim."], ["Watch, 'tis your Lord's command,", "And while we speak He's near;", "Mark the first signal of His hand,", "And ready all appear."], ["O happy servant, he,", "In such a posture found!", "He shall His Lord with rapture see,", "And be with honor crowned."]]
      }, {
        "number": "598",
        "name": "Watch, Ye Saints",
        "category": "Christian Life",
        "subcategory": "Watchfulness",
        "verses": [["Watch, ye saints, with eyelids waking;", "Lo! The powers of heaven are shaking;", "Keep your lamps all trimmed an burning,", "Ready for your Lord's returning."], ["Lo! the promise of your Savior,", "Pardoned sin and purchased favor,", "Blood-washed robes and crowns of glory;", "Haste to tell redemption's story."], ["Kingdoms at their base are crumbling,", "Hark! His chariot wheels are rumbling;", "Tell, O tell of grace abounding,", "While the seventh trump is sounding."], ["Nations wane, though proud and stately;", "Christ His kingdom hasteneth greatly;", "Earth her latest pangs is summing;", "Shout, ye saints, your Lord is coming."], ["Sinners, come, while Christ is pleading;", "Now for you He's interceding;", "Haste, ere grace and time diminished", "Shall proclaim the mystery finished."]],
        "refrain": ["Lo! He comes, lo! Jesus comes;", "Lo! He come, He comes all glorious!", "Jesus comes to reign victorious,", "Lo! He comes, yes, Jesus comes."],
        "refrainFirst": false
      }, {
        "number": "599",
        "name": "Rejoice, Rejoice, Believers",
        "category": "Christian Life",
        "subcategory": "Watchfulness",
        "verses": [["Rejoice, rejoice, believers, and let your lights appear:", "The evening is advancing, and darker night is near.", "The Bridegroom is arising, and soon He draweth nigh.", "Up, pray, and watch, and wrestle, at midnight comes the cry."], ["The watchers on the mountain proclaim the Bride-groom near.", "Go, meet Him as He cometh, with hallelujahs clear.", "The marriage feast is waiting, the gates wide open stand;", "Up, up, you heirs of glory, the Bride-groom is at hand."], ["You saints who here in patience your cross and suff'rings bore,", "Shall live and reign forever, when sorrow is no more.", "Upon the throne of glory the Lamb you shall behold,", "In triumph cast before Him your diadems of gold!"], ["Our hope and expectation, O Jesus, now appear;", "Arise, O sun so longed for, o'er this benighted sphere!", "With hearts and hands uplifted, we plead, O Lord, to see", "The day of earth's redemption that brings us unto Thee."]]
      }, {
        "number": "600",
        "name": "Hold Fast Till I Come",
        "category": "Christian Life",
        "subcategory": "Watchfulness",
        "verses": [["Sweet promise is given to all who believe--", "\"Behold I come quickly, Mine own to receive;", "Hold fast till I come; the danger is great;", "Sleep not as do others; be watchful, and wait.\""], ["We'll \"watch unto prayer\" with lamps burning bright;", "He comes to all others a \"thief in the night.\"", "We know He is near, but know not the day--", "As spring shows that summer is not far away."], ["Yes! this is our hope, 'tis built on His word--", "The glorious appearing of Jesus, our Lord;", "Of promises all, it stands as the sum:", "\"Behold I come quickly; hold fast till I come.\""]],
        "refrain": ["\"Hold fast till I come,\" sweet promise of heaven--", "\"The kingdom restored, to you shall be given.\"", "\"Come, enter My joy, sit down on the throne;", "Bright crowns are in waiting; hold fast till I come.\""],
        "refrainFirst": false
      }, {
        "number": "601",
        "name": "Watchmen, on the Walls of Zion",
        "category": "Christian Life",
        "subcategory": "Watchfulness",
        "verses": [["Watchmen, on the walls of Zion,", "What O tell us, of the night?", "Is the daystar now arising?", "Will the morn soon greet our sight?", "O'er your vision Shine there now", "some rays of light?", "O'er your vision Shine there now", "some rays of light?"], ["Tell, O tell us, are the landmarks", "On our voyage all passed by?", "Are we nearing now the haven?", "Can we e'en the land descry?", "Do we truly See the heavenly kingdom nigh?", "Do we truly See the heavenly kingdom nigh?"], ["Light is beaming, day is coming!", "Let us sound aloud the cry;", "We behold the daystar rising", "Pure and bright in yonder sky!", "Saints, be joyful; Your redemption draweth nigh;", "Saints, be joyful; Your redemption draweth nigh."], ["We have found the chart and compass,", "And are sure the land is near;", "Onward, onward we are hasting,", "Soon the haven will appear;", "Let your voices Sound aloud your holy cheer;", "Let your voices Sound aloud your holy cheer."]]
      }, {
        "number": "602",
        "name": "O Brother, Be Faithful",
        "category": "Christian Life",
        "subcategory": "Watchfulness",
        "verses": [["O brother be faithful! soon Jesus will come,", "For whom we have waited so long;", "O, soon we shall enter our glorious home,", "And join in the conqueror's song.", "O brother be faithful! for why should we prove", "Unfaithful to Him who had shown", "Such deep, such unbounded and infinite love", "Who died to redeem us His own."], ["O brother be faithful! the city of gold,", "Prepared for the good and the blest,", "Is waiting its portals of pearl to unfold,", "And welcome thee into thy rest.", "Then, brother be faithful! not long shall we stay", "In weariness here, and forlorn,", "Time's dark night of sorrow is wearing away,", "We haste to the glorious morn."], ["O brother be faithful! He soon will descend,", "Creation's omnipotent King,", "While legions of angels His chariot attend,", "And palm wreaths, of victory bring.", "O brother be faithful! and soon shalt thou hear", "They Savior pronounce the glad word,", "Well done, faithful servant, they title is clear,", "To enter the joy of thy Lord."], ["O brother be faithful! eternity's years", "Shall tell for thy faithfulness now,", "When bright smiles of gladness shall scatter thy tears,", "A coronet gleam on thy brow.", "O brother be faithful! the promise is sure,", "That waits for the faithful and tried;", "To reign with the ransomed, immortal and pure,", "And ever with Jesus abide."]]
      }, {
        "number": "603",
        "name": "Christian, Seek Not Repose",
        "category": "Christian Life",
        "subcategory": "Watchfulness",
        "verses": [["Christian, seek not yet repose, Cast thy dreams of ease away;", "Thou art in the midst of foes; Watch and pray!"], ["Gird thy heavenly armor on, Wear it ever, night and day;", "Ambushed lies the evil one; Watch and pray!"], ["Hear the victors who o'ercame; Still they mark each warrior's way;", "All with one sweet voice exclaim: \"Watch and pray!\""], ["Hear, above all, hear thy Lord, Him thou lovest to obey;", "Hide within thy heart His word; \"Watch and pray!\""], ["Watch, as if on that alone Hung the issue of the day;", "Pray that help may be send down; Watch and pray!"]]
      }, {
        "number": "604",
        "name": "We Know Not the Hour",
        "category": "Christian Life",
        "subcategory": "Watchfulness",
        "verses": [["We know not the hour of the Master's appearing;", "Yet signs all foretell that the moment is nearing", "When He shall return 'tis the promise most cheering", "But we know not the hour."], ["There's light for the wise who are seeking salvation;", "There's truth in the book of the Lord's revelation;", "Each prophecy points to the great comsummation", "But we know not the hour."], ["We'll watch and we'll pray, with our lamps trimmed and burning;", "We'll work and we'll wait till the Master's returning;", "We'll sing and rejoice, every omen discerning", "But we know not the hour."]],
        "refrain": ["He will come, let us watch and be ready;", "He will come, hallelujah! hallelujah!", "He will come in the clouds of His Father's", "bright glory but we know not the hour."],
        "refrainFirst": false
      }, {
        "number": "605",
        "name": "My Soul, Be on Thy Guard",
        "category": "Christian Life",
        "subcategory": "Watchfulness",
        "verses": [["My soul, be on they guard!", "Ten thousand foes arise;", "The hosts of sin are pressing hard", "To draw thee from the skies."], ["O watch, and fight, and pray!", "The battle ne'er give o'er;", "Renew it boldly every day,", "And help divine implore."], ["Ne'er think the victory won,", "Nor lay thine armor down;", "Thy arduous task will not be done", "Till thou obtain the crown."]]
      }, {
        "number": "606",
        "name": "Once to Every Man and Nation",
        "category": "Christian Life",
        "subcategory": "Christian Warfare",
        "verses": [["Once to every man and nation, comes the moment to decide,", "In the strife of truth with falsehood, for the good or evil side;", "Some great cause, some great decision, offering each the bloom or blight,", "And the choice goes by forever, 'twixt that darkness and that light."], ["Then to side with truth is noble, when we share her wretched crust,", "Ere her cause bring fame and profit, and 'tis prosperous to be just;", "Then it is the brave man chooses while the coward stands aside,", "Till the multitude make virtue of the faith they had denied."], ["By the light of burning martyrs, Christ, Thy bleeding feet we track,", "Toiling up new Calv'ries ever with the cross that turns not back;", "New occasions teach new duties, time makes ancient good uncouth,", "They must upward still and onward, who would keep abreast of truth."], ["Though the cause of evil prosper, yet the truth alone is strong;", "Though her portion be the scaffold, and upon the throne be wrong;", "Yet that scaffold sways the future, and behind the dim unknown,", "Standeth God within the shadow, keeping watch above His own."]]
      }, {
        "number": "607",
        "name": "God of Grace and God of Glory",
        "category": "Christian Life",
        "subcategory": "Christian Warfare",
        "verses": [["God of grace and God of glory,", "On Thy people pour Thy power;", "Now fulfill Thy church's story,", "Bring her bud to glorious flower.", "Grant us wisdom, grant us courage,", "For the facing of this hour."], ["Lo, the hosts of evil round us", "Scorn Thy Christ, assail His ways;", "From the fears that long have bound us", "Free our hearts to faith and praise.", "Grant us wisdom, grant us courage,", "For the facing of this hour."], ["Cure Thy children's warring madness,", "Bend our pride to Thy control;", "Shame our wanton selfish gladness", "Rich in goods and poor in soul.", "Grant us wisdom, grant us courage,", "Lest we miss Thy kingdom's goal."], ["Set our feet on lofty places,", "Gird our lives that they may be", "Armored with all Christ-like graces", "In the fight to set all free.", "Grant us wisdom, grant us courage,", "That we fail not man nor Thee."]]
      }, {
        "number": "608",
        "name": "Faith Is the Victory",
        "category": "Christian Life",
        "subcategory": "Christian Warfare",
        "verses": [["Encamped along the hills of light,", "Ye Christian soldiers, rise,", "And press the battle ere the night", "Shall veil the glowing skies.", "Against the foe in vales below,", "Let all our strength be hurled;", "Faith is the victory, we know,", "That overcomes the world."], ["His banner over us in love,", "Our sword the Word of God;", "We tread the road the saints above", "With shouts of triumph trod.", "By faith they, like whirlwind's breath,", "Swept on o'er ev'ry field;", "The faith by which they conquered death", "Is still our shining shield."], ["To him who overcomes the foe", "White raiment shall be giv'n;", "Before the angels he shall know", "His name confessed in heav'n.", "Then onward from the hills of light,", "Our hearts with love aflame;", "We'll vanquish all the hosts of night,", "In Jesus' conq'ring name."]],
        "refrain": ["Faith is the victory!", "Faith is the victory!", "Oh, glorious victory", "That overcomes the world."],
        "refrainFirst": false
      }, {
        "number": "609",
        "name": "Am I a Soldier of the Cross?",
        "category": "Christian Life",
        "subcategory": "Christian Warfare",
        "verses": [["Am I a soldier of the cross,", "a follower of the Lamb,", "and shall I fear to own his cause,", "or blush to speak his name?"], ["Must I be carried to the skies", "on flowery beds of ease,", "while others fought to win the prize,", "and sailed through bloody seas?"], ["Are there no foes for me to face?", "Must I not stem the flood?", "Is this vile world a friend to grace,", "to help me on to God?"], ["Sure I must fight, if I would reign;", "increase my courage, Lord.", "I'll bear the toil, endure the pain,", "supported by thy word."], ["Thy saints in all this glorious war", "shall conquer though they die;", "they see the triumph from afar,", "by faith they bring it nigh."], ["When that illustrious day shall rise,", "and all thy armies shine", "in robes of victory through the skies,", "the glory shall be thine."]]
      }, {
        "number": "610",
        "name": "Stand Like the Brave",
        "category": "Christian Life",
        "subcategory": "Christian Warfare",
        "verses": [["O Christian, awake! 'tis the Master's command;", "With helmet and shield, and a sword in thy hand,", "To meet the bold tempter, go, fearlessly go,", "Then stand like the brave, with thy face to the foe."], ["The cause of thy Master with vigor defend;", "Be watchful, be zealous, and fight to the end;", "Wherever He leads thee, go, valiantly go,", "Then stand like the brave, with thy face to the foe."], ["Press on, never doubting, thy Captain is near,", "With grace to supply, and with comfort to cheer;", "His love, like a stream in the desert will flow;", "Then stand like the brave, with thy face to the foe."]],
        "refrain": ["Stand like the brave, stand like the brave,", "Stand like the brave, with thy face to the foe."],
        "refrainFirst": false
      }, {
        "number": "611",
        "name": "Awake, My Soul!",
        "category": "Christian Life",
        "subcategory": "Christian Warfare",
        "verses": [["Awake, my soul! stretch every nerve,", "And press with vigor on;", "A heavenly race demands thy zeal,", "And an immortal crown."], ["'Tis God's all animating voice", "That calls thee from on high;", "'Tis He whose hand presents the prize", "To thine aspiring eye."], ["A cloud of witnesses around", "Hold thee in full survey;", "Forget the steps already trod,", "And onward urge thy way."], ["Blest Savior, introduced by Thee,", "Our race have we begun;", "And, crowned with victory, at Thy feet", "We'll lay our trophies down."]]
      }, {
        "number": "612",
        "name": "Onward, Christian Soldiers!",
        "category": "Christian Life",
        "subcategory": "Christian Warfare",
        "verses": [["Onward, Christian soldiers, marching as to war,", "with the cross of Jesus going on before.", "Christ, the royal Master, leads against the foe;", "forward into battle see his banners go!"], ["Like a mighty army moves the church of God;", "brothers, we are treading where the saints have trod.", "We are not divided, all one body we,", "one in hope and doctrine, one in charity."], ["Crowns and thrones my perish, kingdoms rise and wane,", "but the church of Jesus constant will remain.", "Gates of hell can never 'gainst that church prevail;", "we have Christ's own promise, and that cannot fail."], ["Onward then, ye people, join our happy throng,", "blend with ours your voices in the triumph song.", "Glory, laud, and honor unto Christ the King,", "this through countless ages men and angels sing."]],
        "refrain": ["Onward, Christian soldiers, marching as to war,", "with the cross of Jesus going on before."],
        "refrainFirst": false
      }, {
        "number": "613",
        "name": "Fight the Good Fight",
        "category": "Christian Life",
        "subcategory": "Christian Warfare",
        "verses": [["Fight the good fight with all thy might;", "Christ is thy strength, and Christ thy right;", "Lay hold on life and it shall be", "Thy joy and crown eternally."], ["Run the straight race through God's good grace;", "Lift up thine eyes, and seek His face.", "Life with its path before us lies;", "Christ is the way, and Christ the prize."], ["Cast care aside, lean on thy guide,", "His boundless mercy will provide;", "Trust, and the trusting soul shall prove", "Christ is its life, and Christ its love."], ["Faint not, nor fear, His arms are near;", "He changeth not, and thou art dear.", "Only believe, and thou shalt see", "That Christ is all in all to thee."]]
      }, {
        "number": "614",
        "name": "Sound the Battle Cry",
        "category": "Christian Life",
        "subcategory": "Christian Warfare",
        "verses": [["Sound the battle cry,", "See! the foe is nigh;", "Raise the standard high", "For the Lord;", "Gird your armor on,", "Stand firm, every one,", "Rest your cause upon His holy word."], ["Strong to meet the foe,", "Marching on we go,", "While our cause we know", "Must prevail;", "Shield and banner bring,", "Gleaming in the light,", "Battling for the right,", "We ne'er can fail."], ["O Thou God of all,", "Hear us when we call,", "Help us, one and all,", "By Thy grace;", "When the battle's done,", "And the victory won,", "May we wear the crown", "Before Thy face."]],
        "refrain": ["Rouse, then soldiers!", "rally round the banner!", "Ready, steady, pass the word along;", "Onward, forward, shout aloud hosanna!", "Christ is Captain of the mighty throng."],
        "refrainFirst": false
      }, {
        "number": "615",
        "name": "Rise Up, O Church of God",
        "category": "Christian Life",
        "subcategory": "Christian Warfare",
        "verses": [["Rise up, O men of God!", "His kingdom tarries long.", "Bring in the day of brotherhood", "and end the night of wrong."], ["Let women all rise up!", "Have done with lesser things.", "Give heart and mind and soul and strength", "to serve the King of kings."], ["Rise up, O men of God!", "The church for you doth wait,", "her strength unequal to her task;", "rise up, and make her great!"], ["Lift high the cross of Christ!", "Tread where his feet have trod.", "Disciples of the Son of Man,", "rise up, O church of God!"]]
      }, {
        "number": "616",
        "name": "Soldiers of Christ, Arise",
        "category": "Christian Life",
        "subcategory": "Christian Warfare",
        "verses": [["Soldiers of Christ, arise,", "And put your armor on,", "Strong in the strength which God supplies", "Through His eternal Son", "Strong in the Lord of hosts,", "And in His mighty power,", "Who in the strength of Jesus trusts", "Is more than conqueror."], ["Stand then in His great might,", "With all His strength endued,", "But take, to arm you for the fight,", "The panoply of God;", "That, having all things done,", "And all your conflicts passed,", "Ye may o'ercome through Christ alone,", "And stand entire at last."], ["From strength to strength go on,", "Wrestle and fight and pray,", "Tread all the powers of darkness down", "And win the well-fought day.", "Still let the Spirit cry", "In all His soldiers, \"Come!\"", "Till Christ the Lord who reigns on high", "Shall take the conquerors home."]]
      }, {
        "number": "617",
        "name": "We Are Living, We Are Dwelling",
        "category": "Christian Life",
        "subcategory": "Christian Warfare",
        "verses": [["We are living, we are dwelling, In a grand and awful time,", "In an age on ages telling-- To be living is sublime.", "Hark! the waking up of nations, Gog and Magog to the fray;", "Hark! what soundeth? Is creation Groaning for her latter day?"], ["Christian, rouse and arm for conflict, Nerve thee for the battlefield;", "Bear the helmet of salvation, And the mighty gospel shield;", "Let the breastplate, peace, be on thee, Take the Spirit's sword in hand;", "Boldly, fearlessly, go forth then, In Jehovah's strength to stand."], ["And the prince of evil spirits, Great deceiver of the world!", "He who at the blessed Jesus Once his deadly weapons hurled,", "Cometh with unwonted power, Knowing that his reign will cease", "When the kingdom shall be given To the mighty Prince of Peace."], ["Christians, rouse! fight in this warfare, Cease not till the victory's won;", "Till your Captain loud proclaimeth, \"Servant of the Lord, well done!\"", "He, alone, who thus is faithful, Who abideth to the end,", "Hath the promise, in the kingdom An eternity to spend."]]
      }, {
        "number": "618",
        "name": "Stand Up! Stand Up for Jesus!",
        "category": "Christian Life",
        "subcategory": "Christian Warfare",
        "verses": [["Stand up, stand up for Jesus,", "ye soldiers of the cross;", "lift high his royal banner,", "it must not suffer loss.", "From victory unto victory", "his army shall he lead,", "till every foe is vanquished,", "and Christ is Lord indeed."], ["Stand up, stand up for Jesus,", "the trumpet call obey;", "forth to the mighty conflict,", "in this his glorious day.", "Ye that are brave now serve him", "against unnumbered foes;", "let courage rise with danger,", "and strength to strength oppose."], ["Stand up, stand up for Jesus,", "stand in his strength alone;", "the arm of flesh will fail you,", "ye dare not trust your own.", "Put on the gospel armor,", "each piece put on with prayer;", "where duty calls or danger,", "be never wanting there."], ["Stand up, stand up for Jesus,", "the strife will not be long;", "this day the noise of battle,", "the next the victor's song.", "To those who vanquish evil", "a crown of life shall be;", "they with the King of Glory", "shall reign eternally."]]
      }, {
        "number": "619",
        "name": "Lead On, O King Eternal",
        "category": "Christian Life",
        "subcategory": "Christian Warfare",
        "verses": [["Lead on, O King eternal,", "the day of march has come;", "henceforth in fields of conquest", "thy tents shall be our home.", "Through days of preparation", "thy grace has made us strong;", "and now, O King eternal,", "we lift our battle song."], ["Lead on, O King eternal,", "till sin's fierce war shall cease,", "and holiness shall whisper", "the sweet amen of peace.", "For not with swords loud clashing,", "nor roll of stirring drums;", "with deeds of love and mercy", "the heavenly kingdom comes."], ["Lead on, O King eternal,", "we follow, not with fears,", "for gladness breaks like morning", "where'er thy face appears.", "Thy cross is lifted o'er us,", "we journey in its light;", "the crown awaits the conquest;", "lead on, O God of might."]]
      }, {
        "number": "620",
        "name": "On Jordan's Stormy Banks",
        "category": "Christian Life",
        "subcategory": "Pilgrimage",
        "verses": [["On Jordan's stormy banks I stand,", "And cast a wishful eye", "To Canaan's fair and happy land,", "Where my possessions lie."], ["O'er all those wide extended plains", "Shines one eternal day;", "There God the Son forever reigns,", "And scatters night away."], ["No chilling winds or poisonous breath", "Can reach that healthful shore;", "Sickness and sorrow, pain and death,", "Are felt and feared no more."], ["When I shall reach that happy place,", "I'll be forever blest,", "For I shall see my Father's face,", "And in his bosom rest."]],
        "refrain": ["I am bound for the promised land,", "I am bound for the promised land;", "O who will come and go with me?", "I am bound for the promised land."],
        "refrainFirst": false
      }, {
        "number": "621",
        "name": "Gracious Father, Guard Thy Children",
        "category": "Christian Life",
        "subcategory": "Pilgrimage",
        "verses": [["Gracious Father, guard Thy children", "From the foe's destructive power;", "Save, O save them, Lord, from falling", "In this dark and trying hour.", "Thou wilt surely prove Thy people,", "All our graces must be tried;", "But Thy word illumes our pathway,", "And in God we still confide."], ["We are in the time of waiting;", "Soon we shall behold our Lord,", "Wafted far away from sorrow,", "To receive our rich reward.", "Keep us, Lord, till Thine appearing,", "Pure, unspotted from the world;", "Let Thy Holy Spirit cheer us", "Till Thy banner is unfurled."], ["With what joyful exultation", "Shall the saints Thy banner see,", "When the Lord for whom we've waited", "Shall proclaim the jubilee!", "Freedom from this world's pollutions;", "Freedom from all sin and pain;", "Freedom from the wiles of Satan,", "And from death's destructive reign."]]
      }, {
        "number": "622",
        "name": "Come, Come, Ye Saints",
        "category": "Christian Life",
        "subcategory": "Pilgrimage",
        "verses": [["Come, come, ye saints, no toil nor labor fear;", "But with joy wend your way.", "Though hard to you this journey may appear,", "Grace shall be as your day.", "We have a living Lord to guide,", "And we can trust Him to provide;", "Do this, and joy your hearts will swell:", "All is well! All is well!"], ["We'll find the place which God for us prepared,", "When at last He will call;", "Where none will come to hurt or make afraid,", "He will reign over all.", "We will make the air with music ring,", "Shout praise to God our Lord and King:", "O how we'll make the chorus swell:", "All is well! All is well!"]]
      }, {
        "number": "623",
        "name": "I Will Follow Thee",
        "category": "Christian Life",
        "subcategory": "Pilgrimage",
        "verses": [["I will follow Thee, my Savior,", "Wheresoe'er my lot may be.", "Where thou goest I will follow;", "Yes, my Lord, I'll follow Thee."], ["Though the road be rough and thorny,", "Trackless as the foaming sea,", "Thou hast trod this way before me,", "And I'll gladly follow Thee."], ["Though I meet with tribulations,", "Sorely tempted though I be;", "I remember Thou wast tempted,", "And rejoice to follow Thee."], ["Though Thou leadest me through affliction,", "Poor, forsaken though I be;", "Thou wast destitute, afflicted,", "And I only follow Thee."], ["Though to Jordan's rolling billows,", "Cold and deep, Thou leadest me,", "Thou hast crossed the waves before me,", "And I still will follow Thee."]],
        "refrain": ["I will follow Thee, my Saviour,", "Thou didst shed Thy blood for me;", "And though all men should forsake Thee;", "By Thy grace I'll follow Thee."],
        "refrainFirst": false
      }, {
        "number": "624",
        "name": "I Want Jesus to Walk With Me",
        "category": "Christian Life",
        "subcategory": "Pilgrimage",
        "verses": [["I want Jesus to walk with me.", "I want Jesus to walk with me.", "All along my pilgrim journey,", "I want Jesus to walk with me."], ["In my trials Lord, walk with me.", "In my trials Lord, walk with me.", "When the shades of life are falling,", "I want Jesus to walk with me."], ["In my sorrows, Lord, walk with me.", "In my sorrows, Lord, walk with me.", "When my heart within is aching,", "I want Jesus to walk with me."]]
      }, {
        "number": "625",
        "name": "Higher Ground",
        "category": "Christian Life",
        "subcategory": "Pilgrimage",
        "verses": [["I'm pressing on the upward way,", "New heights I'm gaining every day;", "Still praying as I'm onward bound,", "\"Lord, plant my feet on higher ground.\""], ["My heart has no desire to stay", "Where doubts arise and fears dismay;", "Though some may dwell where those abound,", "My prayer, my aim, is higher ground."], ["I want to live above the world,", "Though Satan's darts at me are hurled;", "For faith has caught the joyful sound,", "The song of saints on higher ground."], ["I want to scale the utmost height", "And catch a gleam of glory bright;", "But still I'll pray till Heav'n I've found,", "\"Lord, plant my feet on higher ground.\""]],
        "refrain": ["Lord, lift me up and let me stand,", "By faith, on Heaven's table land,", "A higher plane than I have found;", "Lord, plant my feet on higher ground."],
        "refrainFirst": false
      }, {
        "number": "626",
        "name": "In a Little While We're Going Home",
        "category": "Christian Life",
        "subcategory": "Pilgrimage",
        "verses": [["Let us sing a song that will cheer us by the way,", "In a little while we're going home;", "For the night will end in the everlasting day,", "In a little while we're going home."], ["We will do the work that our hands may find to do,", "In a little while we're going home;", "And the grace of God will our daily strength renew,", "In a little while we're going home."], ["We will smooth the path for some weary, way-worn feet,", "In a little while we're going home;", "And may loving hearts spread around an influence sweet!", "In a little while we're going home."], ["There's a rest beyond, there's relief from every care,", "In a little while we're going home;", "And no tears shall fall in that city bright and fair,", "In a little while we're going home."]],
        "refrain": ["In a little while, In a little while,", "We shall cross the billow's foam;", "We shall meet at last, When the stormy winds are past,", "In a little while we're going home."],
        "refrainFirst": false
      }, {
        "number": "627",
        "name": "Jacob's Ladder",
        "category": "Christian Life",
        "subcategory": "Pilgrimage",
        "verses": [["We are climbing Jacob's ladder,", "We are climbing Jacob's ladder,", "We are climbing Jacob's ladder,", "Soldiers of the cross."], ["Every round goes higher, higher,", "Every round goes higher, higher,", "Every round goes higher, higher,", "Soldiers of the cross."], ["Sinner, do you love my Jesus?", "Sinner, do you love my Jesus?", "Sinner, do you love my Jesus?", "Soldiers of the cross."], ["If you love him, why not serve him?", "If you love him, why not serve him?", "If you love him, why not serve him?", "Soldiers of the cross."]]
      }, {
        "number": "628",
        "name": "As Jacob With Travel Was Weary",
        "category": "Christian Life",
        "subcategory": "Pilgrimage",
        "verses": [["As Jacob with travel was weary one day,", "At night on a stone for a pillow he lay;", "He saw in a vision a ladder so high", "That its foot was on earth and its top in the sky."], ["Come let us ascend! All may climb it who will;", "For the angels of Jacob are guarding it still:", "And remember each step that by faith we passo'er,", "Some prophet or martyr has trod it before."], ["And when we arrive at the haven of rest", "We shall hear the glad words, \"Come up hither, ye blest,", "Here are regions of light, here are mansions of bliss.\"", "O who would not climb such a ladder as this?"]],
        "refrain": ["Alleluia to Jesus who died on the tree,", "And has raised up a ladder of mercy for me,", "And has raised up a ladder of mercy for me."],
        "refrainFirst": false
      }, {
        "number": "629",
        "name": "O Happy Band of Pilgrims",
        "category": "Christian Life",
        "subcategory": "Pilgrimage",
        "verses": [["O happy band of pilgrims,", "If onward ye will tread", "With Jesus as your fellow,", "To Jesus as your Head!"], ["O happy if ye labor", "As Jesus did for men;", "O happy if ye hunger", "As Jesus hungered then!"], ["The trials that beset you,", "The sorrows ye endure,", "The manifold temptations", "That death alone can cure,"], ["What are they but His jewels", "Of right celestial worth?", "What are they but the ladder", "Set up to heaven on earth?"], ["O happy band of pilgrims,", "Look upward to the skies,", "Where such a light affliction", "Shall win you such a prize!"]]
      }, {
        "number": "630",
        "name": "Rise, My Soul, and Stretch Thy Wings",
        "category": "Christian Life",
        "subcategory": "Pilgrimage",
        "verses": [["Rise, my soul, and stretch thy wings, thy better portion trace;", "Rise from transitory things toward heaven, thy native place;", "Sun, and moon, and stars decay; time shall soon this earth remove;", "Rise, my soul, and haste away to seats prepared above."], ["Rivers to the ocean run, nor stay in all their course;", "Fire ascending seeks the sun; both speed them to their source;", "So a soul that's born of God, longs to view His glorious face,", "Forward tends to His abode to rest in His embrace."], ["Cease, ye pilgrims, cease to mourn; press onward to the prize;", "Soon our Savior will return, triumphant in the skies;", "Yet a season, and you know happy entrance will be given,", "All our sorrows left below, and earth exchanged for heaven."]]
      }, {
        "number": "631",
        "name": "When on Life a Darkness Falls",
        "category": "Christian Life",
        "subcategory": "Pilgrimage",
        "verses": [["When on life a darkness falls,", "When the mist flows chilling,", "Paths and sign posts lost in doubt,", "Loveless, unfulfilling,", "Reach us, Jesus, from Your cross,", "Though we feel forsaken;", "Keep us through the aching night", "Till new dawns awaken."], ["When the dreams and vows of youth", "Painfully accuse us,", "Stab our conscience, steal our worth,", "Christ will not refuse us:", "Peace the world cannot provide,", "Daily resurrection,", "Strong companion at our side", "For each new direction."], ["Come and meet Him, Friend and Lord,", "Thro' the gospel story:", "Open door to life and peace,", "Window into glory.", "All who seek Him, soon are found,", "Made His close relation:", "Christ our pathway, Christ our home,", "Christ our sure foundation."]]
      }, {
        "number": "632",
        "name": "Until Then",
        "category": "Christian Life",
        "subcategory": "Pilgrimage",
        "verses": [["My heart can sing when I pause to remember,", "A heartache here is but a stepping stone.", "Along a trail, thats  winding always upward,", "this troubled world, is not my final home."], ["The things of earth will dim and loose there value,", "If we recall they're borrowed for awhile;", "And things of earth that cause the heart to tremble,", "Remember there, will only bring a smile."]],
        "refrain": ["But until then, my heart will go on singing,", "Until then, with joy I'll carry on,", "Until the day my eyes behold the city,", "Until the day God calls me home."],
        "refrainFirst": false
      }, {
        "number": "633",
        "name": "When We All Get to Heaven",
        "category": "Christian Life",
        "subcategory": "Pilgrimage",
        "verses": [["Sing the wondrous love of Jesus;", "Sing his mercy and his grace.", "In the mansions bright and blessed", "He'll prepare for us a place."], ["While we walk the pilgrim pathway,", "Clouds will overspread the sky;", "But when traveling days are over,", "Not a shadow, not a sigh."], ["Let us then be true and faithful,", "Trusting, serving every day;", "Just one glimpse of him in glory", "Will the toils of life repay."], ["Onward to the prize before us!", "Soon his beauty we'll behold;", "Soon the pearly gates will open;", "We shall tread the streets of gold."]],
        "refrain": ["When we all get to heaven,", "What a day of rejoicing that will be!", "When we all see Jesus,", "We'll sing and shout the victory!"],
        "refrainFirst": false
      }, {
        "number": "634",
        "name": "Come, All Christians, Be Committed",
        "category": "Christian Life",
        "subcategory": "Stewardship",
        "verses": [["Come, all Christians, be committed", "To the service of the lord;", "Make your lives for him more fitted,", "Tune your hearts with one accord.", "Come into His courts with gladness,", "Each his sacred vows renew,", "Turn away from sin and sadness,", "Be transformed with life anew."], ["Of your time and talents give ye,", "They are gifts from God above;", "To be used by Christians freely", "To proclaim His wondrous love.", "Come again to serve the Savior,", "Tithes and off'rings with you bring.", "In your work, with Him find favor,", "And with joy His praises sing."], ["God's command to love each other", "Is required of every one;", "Showing mercy to one another", "Mirrors His redemptive plan.", "In compassion He has given", "Of His love that is divine;", "On the cross sins were forgiven;", "Joy and peace are fully thine."], ["Come in praise and adoration,", "All who in Christ's name believe;", "Worship Him with consecration,", "Grace and love you will receive.", "For His grace give Him the glory,", "For the Spirit and the Word,", "And repeat the gospel story", "Till mankind His name has heard."]]
      }, {
        "number": "635",
        "name": "Lord of All Good",
        "category": "Christian Life",
        "subcategory": "Stewardship",
        "verses": [["Lord of all good, our gifts we bring You now;", "Use them Your holy purpose to fulfill.", "Tokens of love and pledges they shall be", "That our whole life is offered to Your will."], ["We give our minds to understand Your ways;", "Hands, voices, eyes to serve Your great design;", "Hearts with the flame of your own love ablaze:", "Thus for Your glory all our pow'rs combine."], ["Father, whose bounty all creation shows;", "Christ, by whose willing sacrifice we live;", "Spirit, from whom all life in fullness flows:", "To You with grateful hearts ourselves we give."]]
      }, {
        "number": "636",
        "name": "God, Whose Giving Knows No Ending",
        "category": "Christian Life",
        "subcategory": "Stewardship",
        "verses": [["God, whose giving knows no ending,", "From Your rich and endless store:", "Nature's wonder, Jesus' wisdom,", "Costly cross, grave's shattered door.", "Gifted by You, we turn to You,", "Off'ring up ourselves in praise:", "Thankful song shall rise forever,", "Gracious donor of our days."], ["Skills and time are ours for pressing", "Toward the goals of Christ, Your Son:", "All at peace in health and freedom,", "Races joined, the church made one.", "Now direct our daily labor,", "Lest we strive for self alone:", "Born with talents, make us servants", "Fit to answer at Your throne."], ["Treasure, too, You have entrusted,", "Gain through pow'rs Your grace conferred:", "Ours to use for home and kindred,", "And to spread the Gospel Word.", "Open wide our hands in sharing,", "As we heed Christ's ageless call.", "Healing, teaching, and reclaiming,", "Serving You by loving all."]]
      }, {
        "number": "637",
        "name": "Son of God, Eternal Savior",
        "category": "Christian Life",
        "subcategory": "Stewardship",
        "verses": [["Son of God, eternal Savior,", "Source of life and truth and grace,", "Work made flesh, whose birth among us", "Hallows all our human race,", "You our head, who throned in glory,", "For Your own will ever plead:", "Fill us with Your love and pity,", "Heal our wrongs, and help our need."], ["Bind us all as one together", "In Your church's sacred fold,", "Weak and healthy, poor and wealthy,", "Sad and joyful, young and old.", "Is there want or pain or sorrow?", "Make us all the burden share.", "Are there spirits crushed and broken?", "Teach us, Lord, to soothe their care."], ["As You, Lord, have lived for others,", "So may we for others live.", "Freely have Your gifts been granted;", "Freely may Your servants give.", "Yours the gold and Yours the silver,", "Yours the wealth of land and sea;", "We but stewards of Your bounty", "Held in solemn trust will be."], ["Come, O Christ, and reign among us,", "King of love and Prince of Peace;", "Hush the storm of strife and passion,", "Bid its cruel discords cease.", "By Your patient years of toiling,", "By Your silent hours of pain,", "Quench our fevered thirst of pleasure,", "Stem our selfish greed of gain."], ["Son of God, eternal Savior,", "Source of life and truth and grace,", "Word made flesh, whose birth among us", "Hallows all our human race:", "By Your praying, by Your willing", "That Your people should be one,", "Grant, oh, grant our hope's fruition:", "Here on earth Your will be done."]]
      }, {
        "number": "638",
        "name": "The Wise May Bring Their Learning",
        "category": "Christian Life",
        "subcategory": "Stewardship",
        "verses": [["wise may bring their learning,", "The rich may bring their wealth,", "And some may bring their greatness,", "And some their strength and health:", "We too would bring our treasures", "To offer to the King,", "We have no wealth or learning-", "What shall we children bring?"], ["We'll bring Him hearts that love Him,", "We'll bring Him thankful praise,", "And young souls meekly striving", "To follow in His ways:", "And these be the treasures", "We offer to the King,", "And these are gifts that ever", "The poorest child may bring."], ["We'll bring the little duties", "We have to do each day;", "We'll try our best to please Him", "At home, at school, at play:", "And better are these treasures", "To offer to the King", "Than richest gift without them:", "Yet these a child may bring."]]
      }, {
        "number": "639",
        "name": "A Diligent and Grateful Heart",
        "category": "Christian Life",
        "subcategory": "Stewardship",
        "verses": [["A diligent and grateful heart", "Prompts me to sing Thy praise.", "Thy love and mercies from the start", "Have blessed me all my days."], ["I thank Thee for the means to serve", "With talents and with tithes,", "For sharing brings the utmost joy", "When lifting other lives."], [["My thanks I give you for stewardship", "To minister through deeds,", "To serve and share with patient care", "Thy people in their needs."]], ["O Lord, I dedicate my all", "In this response to Thee.", "Help me to magnify this call", "In deep humility."]]
      }, {
        "number": "640",
        "name": "For Beauty of Meadows",
        "category": "Christian Life",
        "subcategory": "Stewardship",
        "verses": [["For beauty of meadows, for grandeur of trees,", "For flowers of woodlands, for creatures of seas,", "For all You created and gave us to share,", "We praise You,", "Creator, extolling your care."], ["As stewards of beauty received at Your hand,", "As creatures who hear Your most urgent command,", "We turn from our wasteful destruction of life,", "Confessing our failures, confessing our strife."], ["Teach us once again to be gardeners in peace;", "All nature around us is ours but on lease;", "Your name we would hallow in all that we do,", "Fulfilling our calling, creating with You."]]
      }, {
        "number": "641",
        "name": "God in His Love for Us",
        "category": "Christian Life",
        "subcategory": "Stewardship",
        "verses": [["God in His love for us lent us this planet,", "Gave it a purpose in time and in space:", "Small as a spark from the fire of creation,", "Cradle of life and the home of our race."], ["Thanks be to God for its bounty and beauty,", "Life that sustains us in body and mind:", "Plenty for all, if we learn how to share it,", "Riches undreamed of to fathom and find."], ["Long have our human wars ruined its harvest;", "Long has earth bowed to the terror of forced;", "Long have we wasted what others have need of,", "Poisoned the fountain of life at its source."], ["Earth is the Lord's: it is ours to enjoy it,", "Ours, as His stewards, to farm and defend.", "From its pollution, misuse, and destruction,", "Good Lord, deliver us, world without end!"]]
      }, {
        "number": "642",
        "name": "We Praise Thee With Our Minds",
        "category": "Christian Life",
        "subcategory": "Health and Wholeness",
        "verses": [["We praise Thee with our minds, O Lord,\u000bKept sharp to think Thy thought;", "Come, Holy Ghost with grace outpoured,", "To teach what Christ hath taught.", "In all our learning may we seek\u000bThat wisdom from above", "Which comes to all: the brave, the meek,\u000bWho ask in faith and love."], ["We praise Thee thro' our bodies, Lord,\u000bKept strong to do Thy will;", "Thy Spirit's temples, which afford", "A means to praise Thee still.", "We give ourselves, a sacrifice,", "To live as unto Thee;", "For Thou alone hast paid the price\u000bTo bring salvation free."], ["We praise Thee in our hearts, O King,\u000bKept pure to know Thy ways;", "And raise to Thee a hymn to sing", "Eternally Thy praise.", "Altho adoring hearts will bow", "As age on ages roll;", "We praise Thee in our beings now,", "Mind, body, heart, and soul."]]
      }, {
        "number": "643",
        "name": "Father, Who on Us Do Shower",
        "category": "Christian Life",
        "subcategory": "Health and Wholeness",
        "verses": [["Father, who on us do shower", "Gifts of plenty form Your dower,", "To Your people give the power", "All Your gifts to use aright."], ["Give pure happiness in leisure", "Temperance in every pleasure,", "Wholesome use of earthly treasure", "Bodies clean and spirits bright."], ["Lift from this and every nation", "All that brings us degradation;", "Quell the forces of temptation;", "Put Your enemies to flight."], ["Father, You who sought and found us,", "Son of God, whose love has bound us,", "Holy Spirit, in us, round us,", "Hear us, Godhead infinite."]]
      }, {
        "number": "644",
        "name": "O God, Whose Will Is Life and Good",
        "category": "Christian Life",
        "subcategory": "Health and Wholeness",
        "verses": [["O God, whose will is life and good", "For all of mortal breath:", "Unite in bonds of servanthood", "All those who strive with death."], ["Make strong their hands and hearts and wills", "To drive disease afar,", "To strive against the body's ills", "And wage Your healing war."], ["By healing of the sick and blind,", "Christ's mercy they proclaim,", "Make known the great physician's mind,", "Affirm the Savior's name."], ["Before them set Your gracious will,", "That they, with heart and soul,", "To You may consecrate their skill", "And make the sufferer whole."]]
      }, {
        "number": "645",
        "name": "God of our fathers",
        "category": "Christian Life",
        "subcategory": "Love of the Country",
        "verses": [["God of our fathers,", "whose almighty hand", "Leads forth in beauty", "all the starry band", "Of shining world in", "splendor through the skies,", "Our grateful songs", "before Thy throne arise."], ["Thy love divine hath", "led us in the past,", "In this free land by", "Thee our lots is cast;", "Be Thou our ruler,", "guardian, guide, and stay,", "Thy word our law,", "Thy paths our chosen way."], ["From war's alarms,", "from deadly pestilence,", "Be Thy strong arm our", "ever sure defense;", "Thy true religion in", "our hearts increase,", "Thy bounteous goodness", "nourish us in peace."], ["Refresh Thy people on", "their toilsome way,", "Lead us from night", "to never-ending day;", "Fill all our lives with", "love and grace divine,", "And glory, laud, and", "praise be ever Thine."]]
      }, {
        "number": "646",
        "name": "To the Name That Brings Salvation",
        "category": "Christian Life",
        "subcategory": "Love of the Country",
        "verses": [["To the name that brings salvation", "Let the nations bow the head;", "Let them kneel in adoration", "When this name of names is said;", "Let them pray for restoration", "Of all things in Christ the head."], ["He through every generation", "Rules in endless majesty;", "May the kings of every nation", "Now foreswear their enmity,", "And with humble veneration", "In the love of God agree."], ["Lord, we pray for upright rulers:", "Guard them surely in their need", "From the vanity of power", "And the emptiness of greed;", "Let them see the truth of lowness,", "And on justice let them feed."]]
      }, {
        "number": "647",
        "name": "Mine Eyes Have Seen the Glory",
        "category": "Christian Life",
        "subcategory": "Love of the Country",
        "verses": [["Mine eyes have seen the glory of the coming of the Lord;", "He is trampling out the vintage where the grapes of wrath are stored;", "He has loosed the fateful lightning of His terrible swift sword;", "His truth is marching on."], ["He has sounded forth the trumpet that shall never call retreat;", "He is sifting out the hearts of men before His judgment seat;", "O be swift, my soul, to answer Him! be jubilant, my feet!", "Our God is marching on."], ["In the beauty of the lilies Christ was born across the sea,", "With a glory in His bosom that transfigures you and me;", "As He died to make men holy, let us live to make men free!", "While God is marching on."]],
        "refrain": ["Glory! Glory! Hallelujah! Glory! Glory! Hallelujah!", "Glory! Glory! Hallelujah! His truth is marching on."],
        "refrainFirst": false
      }, {
        "number": "648",
        "name": "I Vow to Thee, My Country",
        "category": "Christian Life",
        "subcategory": "Love of the Country",
        "verses": [["I vow to thee, my country, all earthly things above,", "Entire and whole and perfect, the service of my love:", "The love that asks the reason, the love that stands the test,", "That lays upon the altar the dearest and the best;", "The love that never falters, the love that pays the price,", "The love that makes undaunted the final sacrifice."], ["And there's another country, I've heard of long ago,", "Most dear to them that love her, most great to them that know;", "We may not count her armies, we may not see her King;", "Her fortress is a faithful heart, her pride is suffering;", "And one by one and fervently we pray for her increase,", "And her ways are ways of gentleness, and all her paths are peace."]]
      }, {
        "number": "649",
        "name": "Lord, While for All Mankind We Pray",
        "category": "Christian Life",
        "subcategory": "Love of the Country",
        "verses": [["Lord, while for all mankind we pray", "Of every clime and coast,", "O hear us for our native land,", "The land we love the most!"], ["O guard our shores from every foe,", "With peace our borders bless;", "With prosperous times our cities crown,", "Our fields with plenteousness!"], ["Unite us in the sacred love", "Of knowledge, truth, and Thee;", "And let our hills and valleys shout", "The songs of liberty."], ["Lord of the nations, thus to Thee", "Our country we commend;", "Be Thou her Refuge and her trust,", "Her everlasting friend."]]
      }, {
        "number": "650",
        "name": "Our Father, by Whose Name",
        "category": "Christian Home",
        "subcategory": "Love in the Home",
        "verses": [["Our Father,", "by whose name,", "All fatherhood is known,", "Who dost in love proclaim\u000bEach family Thine own,", "Bless Thou all parents,", "guarding well,", "With constant love as sentinel,", "The homes in which Thy people dwell."], ["O Christ,", "Thyself a child\u000bWithin an earthly home,", "With heart still undefiled,", "Thou didst to manhood come;", "Our children bless, in every place,", "That they may all behold Thy face,", "And knowing Thee may grow in grace."], ["O Spirit,", "who dost bind\u000bOur hearts in unity,", "Who teaches us to find\u000bThe love from self set free,", "In all our hearts such love increase,", "That every home, by this release,", "May be dwelling place of peace."]]
      }, {
        "number": "651",
        "name": "Happy the Home That Welcomes You",
        "category": "Christian Home",
        "subcategory": "Love in the Home",
        "verses": [["Happy the home that welcomes You, Lord Jesus,", "Truest of friends, most honored guest of all,", "Where hearts and eyes are bright with joy to greet You,", "Your lightest wishes eager to fulfill."], ["Happy the home where men and wife together", "Are of one mind believing in Your love:", "Through love and pain, prosperity and hardship,", "Through good and evil days Your care they prove."], ["Happy the home, O loving Friend of children,", "Where they are giv'n to You with hands of prayer,", "Where at Your feet they early learn to listen", "To Your own words, and thank You for Your care."]]
      }, {
        "number": "652",
        "name": "Love at Home",
        "category": "Christian Home",
        "subcategory": "Love in the Home",
        "verses": [["There is beauty all around, When there's love at home;", "There is joy in every sound, When there's love at home.", "Peace and plenty here abide, Smiling fair on every side;", "Time doth softly, sweetly glide, When there's love at home."], ["Kindly heaven smiles above, When there's love at home;", "All the earth is fill'd with love, When there's love at home.", "Sweeter sings the brooklet by, Brighter beams the azure sky;", "O, there's One who smiles on high When there's love at home."], ["Jesus, make me wholly Thine, Then there's love at home;", "May Thy sacrifice be mine, Then there's love at home.", "Safely from all harm I'll rest, With no sinful care distress'd,", "Thro' Thy tender mercy blessed, When there's love at home."]],
        "refrain": ["Love at home, love at home;", "Time doth softly, sweetly glide,", "When there's love at home."],
        "refrainFirst": false
      }, {
        "number": "653",
        "name": "Lead Them, My God, to Thee",
        "category": "Christian Home",
        "subcategory": "Love in the Home",
        "verses": [["Lead them, my God, to Thee, Lead them to Thee,", "These children dear of mine, Thou gavest me;", "O, by Thy love divine, Lead them, my God, to Thee;", "Lead them, my God, to Thee, Lead them to Thee."], ["When earth looks bright and fair, Festive and gay,", "Let no delusive snare Lure them astray;", "But from temptation's power, Lead them, my God, to Thee,", "Lead them, my God, to Thee, Lead them to Thee."], ["E'en for such little ones, Christ came a child,", "And in this world of sin Lived undefiled.", "O, for His sake, I pray, Lead them, my God, to Thee,", "Lead them, my God, to Thee, Lead them to Thee."], ["Yea, though my faith be dim, I would believe", "That Thou this precious gift Wilt now receive;", "O take their young hearts now, Lead them my God to Thee,", "Lead them, my God, to Thee, Lead them to Thee."]]
      }, {
        "number": "654",
        "name": "Lord, Bless Our Homes",
        "category": "Christian Home",
        "subcategory": "Love in the Home",
        "verses": [["Lord, bless our homes with peace and love and laughter,", "With understanding and with loyalty.", "May we together follow Christ the Master", "And know the blessing of His sov'reignty."], ["May every heart receive His loving spirit", "And know the t4ruth that makes life truly free;", "Then, in that spirit may we live united,", "And find in God our deep security."], ["Forgive the hurts our selfishness inflicted", "On whose we love and those who love us best.", "Christ, heal the scars and draw us all together", "In Him whose will is peace and joy and rest."], ["Father, in gratitude for homes and loved one,", "We open now our hearts to all mankind.", "Grand us Your spirit love for one another", "So in Your peace may we our concord find."]]
      }, {
        "number": "655",
        "name": "Happy the Home",
        "category": "Christian Home",
        "subcategory": "Love in the Home",
        "verses": [["Happy the home when God is there,", "and love fills every breast;", "when one their wish, and one their prayer,", "and one their heavenly rest."], ["Happy the home where Jesus' name", "is sweet to every ear;", "where children early speak his fame,", "and parents hold him dear."], ["Happy the home where prayer is heard,", "and praise is wont to rise;", "where parents love the sacred Word", "and all its wisdom prize."], ["Lord, let us in our homes agree", "this blessed peace to gain;", "unite our hearts in love to thee,", "and love to all will reign."]]
      }, {
        "number": "656",
        "name": "O Perfect Love",
        "category": "Christian Home",
        "subcategory": "Marriage",
        "verses": [["O perfect Love, all human thought transcending,", "lowly we kneel in prayer before thy throne,", "that theirs may be the love which knows no ending,", "whom thou forevermore dost join in one."], ["O perfect Life, be thou their full assurance", "of tender charity and steadfast faith,", "of patient hope and quiet, brave endurance,", "with childlike trust that fears nor pain nor death."], ["Grant them the joy which brightens earthly sorrow;", "grant them the peace which calms all earthly strife,", "and to life's day the glorious unknown morrow", "that dawns upon eternal love and life."]]
      }, {
        "number": "657",
        "name": "O God, From Whom Mankind",
        "category": "Christian Home",
        "subcategory": "Marriage",
        "verses": [["O God from whom mankind derives its name;", "Whose covenant of grace remains the same,", "Be with these two who now before You wait;", "Enlarge the love they come to consecrate."], ["May through their union other lives be blessed;", "Their door be wide to stranger and to guest,", "Give them the understanding that is kind,", "Grant them the blessing of an open mind."], ["Preserve their days from inwardness of hear;", "To each the gift of truthful speech impart.", "Their bond be strong against all strain and strife", "Amid the changes of this earthly life."], ["From stage to stage on life's unfolding way", "Bring to their mind the vows they make this day,", "Your Spirit be their Guide in every move,", "Their faith in Christ the basis of their love."]]
      }, {
        "number": "658",
        "name": "Heavenly Father, Hear Our Prayer",
        "category": "Christian Home",
        "subcategory": "Marriage",
        "verses": [["Heav'nly Father, hear our prayer", "As we bow before You:", "Bless them in the life they share,", "Humble we implore You.", "Be their guide in all endeavors,", "Be their hope that nothing severs;", "Constant source of love divine,", "Let Your love within them shine!"], ["As they pledge their love this day", "Here before Your altar,", "May their hearts,, upon You stayed,", "Never fail or falter.", "Be their comfort in all sorrow;", "Be their reason for tomorrow.", "Grant them strength to live each hour", "Trusting solely in Your pow'r."], ["Blest Creator, Lord of life,", "Hear our glad thanksgiving.", "Husband You have joined to wife", "For their earthly living.", "Justified by Jesus' merit,", "Life eternal they inherit.", "When their days on earth have passed,", "Take them to Your home at last!"]]
      }, {
        "number": "659",
        "name": "May the Grace of Christ Our Savior",
        "category": "Christian Home",
        "subcategory": "Marriage",
        "verses": [["May the grace of Christ our Savior", "And the Father's boundless love,", "With the Holy Spirit's favor,", "Rest upon them from above."], ["Thus may they abide in union", "With each other and the Lord,", "And possess, in sweet communion,", "Joys which earth cannot afford."]]
      }, {
        "number": "660",
        "name": "Glory Be to the Father",
        "category": "Sentences and Responses",
        "other": [["Glory be to the Father", "and to the Son,", "and to the Holy Ghost;", "As it was in the beginning,", "is now, and ever shall be,", "world without end.", "Amen, Amen."]]
      }, {
        "number": "661",
        "name": "Holy, Holy, Holy",
        "category": "Sentences and Responses",
        "verses": [["Holy, holy, holy, Holy is the Lord!", "Holy, holy, holy, Holy is our God!", "He who always liveth, Evermore the same", "Heav'n and earth He ruleth, Come and praise His name!"], ["Holy, holy, holy, Holy is the Lord!", "Holy, holy, holy, Holy is our God!", "Glorious and beloved Is the One adored!", "Holy, holy, holy, Holy is the Lord."]]
      }, {
        "number": "662",
        "name": "Let All Mortal Flesh Keep Silence",
        "category": "Sentences and Responses",
        "other": [["Let all mortal flesh keep silence,", "and with fear and trembling stand;", "ponder nothing earthly-minded,", "for with blessing in his hand,", "Christ our God to earth descendeth,", "our full homage to demand. Amen."]]
      }, {
        "number": "663",
        "name": "Amens",
        "category": "Sentences and Responses",
        "verses": [["A-men, A---men."], ["A-----men."], ["A---men."], ["A---men, (A-men.)"], ["A-----men."], ["A-men, A-men, A---men."]]
      }, {
        "number": "664",
        "name": "Sevenfold Amen",
        "category": "Sentences and Responses",
        "other": [["A-men,", "A-men,", "A---men,", "A-----men,", "A-----men,", "A-----men,", "A-men."]]
      }, {
        "number": "665",
        "name": "All Things Come of Thee",
        "category": "Sentences and Responses",
        "other": [["All things come of thee, O Lord;", "and of thine own have we given thee.", "Amen."]]
      }, {
        "number": "666",
        "name": "Cast Thy Burden Upon the Lord",
        "category": "Sentences and Responses",
        "other": [["Cast thy burden upon the Lord,", "And He shall sustain thee;", "He never will suffer the righteous to fall;", "He is at thy right hand.", "Thy mercy, Lord, is great, and far above the heavens;", "Let none be made ashamed, that wait upon Thee."]]
      }, {
        "number": "667",
        "name": "Lord, Bless Thy Word to Every Heart",
        "category": "Sentences and Responses",
        "other": [["Lord, bless Thy word to every heart", "In this Thy house today,", "And help us each as now we part,", "Its precepts to obey.", "Amen", "Amen"]]
      }, {
        "number": "668",
        "name": "O Thou Who Hearest",
        "category": "Sentences and Responses",
        "other": [["O Thou who hearest every heartfelt prayer,", "With Thy rich grace, Lord, all our hearts prepare;", "Thou art our life, Thou art our love and light,", "O let this Sabbath hour with Him be bright. Amen."]]
      }, {
        "number": "669",
        "name": "The Lord Bless You and Keep You",
        "category": "Sentences and Responses",
        "other": [["The Lord bless you and keep you,", "the Lord life His countenance upon you,", "and give you peace;", "the Lord make His face to shine upon you,", "and be gracious unto you,", "be gracious,", "the Lord be gracious unto you.", "Amen."]]
      }, {
        "number": "670",
        "name": "We Give Thee But Thine Own",
        "category": "Sentences and Responses",
        "other": [["We give Thee but Thine own,", "What-e'er the gift may be;", "All that we have is Thine alone,", "A trust, O Lord, from Thee."]]
      }, {
        "number": "671",
        "name": "As We Come to You in Prayer",
        "category": "Sentences and Responses",
        "other": [["Now, dear Lord, as we pray,", "take our hearts and minds far away", "From the press of the world all around", "To Your throne where grace does abound.", "May our lives be transform'd by Your love,", "May our souls be refreshed from above.", "At this moment, let people everywhere", "Join us now as we come to You in prayer."]]
      }, {
        "number": "672",
        "name": "Spirit of the Living God",
        "category": "Sentences and Responses",
        "other": [["Spirit of the living God,", "Fall afresh on me!", "Spirit of the living God,", "Fall afresh on me!", "Break me, melt me, mold me, fill me!", "Spirit of the living God,", "Fall afresh on me!"]]
      }, {
        "number": "673",
        "name": "May God Be With You",
        "category": "Sentences and Responses",
        "verses": [["May God be with you Till we meet again,", "May God be with you, Keep you safe till then;", "And may His blessings Be within your heart,", "May God be with you While we're apart,", "May God be with you."], ["May God be with you, Watch you from above,", "May God protect you in His tender love;", "And with the dawning Of each bright, new day,", "May God be with you, To guide your way,", "May God be with you."]]
      }, {
        "number": "674",
        "name": "Shalom",
        "category": "Sentences and Responses",
        "other": [["shalom, good friends,", "shalom, good friends,", "shalom, shalom.", "Till we meet again,", "till we meet again,", "shalom, shalom."]]
      }, {
        "number": "675",
        "name": "May the Lord Bless and Keep You",
        "category": "Sentences and Responses",
        "other": [["May the Lord bless you and keep you", "both now and evermore.", "Amen, Amen."]]
      }, {
        "number": "676",
        "name": "Thy Word Is a Lantern",
        "category": "Sentences and Responses",
        "other": [["Thy Word is a lantern unto my feet,", "And a light unto my path."]]
      }, {
        "number": "677",
        "name": "Heavenly Father, to Thee We Pray",
        "category": "Sentences and Responses",
        "other": [["Heavenly Father, to Thee we pray", "On the holy Sabbath day;", "Through Thy Word Thy will make known;", "May each heart become Thy throne,", "Let Thy living water flow", "That we Thy boundless love may know.", "Amen."]]
      }, {
        "number": "678",
        "name": "God Be in My Head",
        "category": "Sentences and Responses",
        "other": [["God be in my head, and in my understanding;", "God be in mine eyes, and in my looking;", "God be in my mouth, and in my speaking;", "God be in my heart, and in my thinking;", "God be at mine end, and at my departing."]]
      }, {
        "number": "679",
        "name": "God Be in My Head",
        "category": "Sentences and Responses",
        "other": [["God be in my head,", "And in my thinking.", "god be in my eyes,", "And in my looking.", "God be in my mouth", "And in my speaking.", "Oh, God be in my hear,", "And in my understanding."]]
      }, {
        "number": "680",
        "name": "Holy Spirit, Hear Us",
        "category": "Sentences and Responses",
        "verses": [["Holy Spirit, hear us;", "Help us while we sing;", "Breath into the music", "Of the praise we bring."], ["Holy Spirit, prompt us", "When we kneel to pray;", "Nearer come, and teach us", "What we ought to say."], ["Holy Spirit, shine Thou", "On the Book we read;", "Gild it's holy pages", "With the light we need."]]
      }, {
        "number": "681",
        "name": "This Is the Day the Lord Hath Made",
        "category": "Sentences and Responses",
        "other": [["This is the day the Lord hath made;", "he calls the hours his own.", "Let heaven rejoice, let earth be glad,", "and praise surround the throne."]]
      }, {
        "number": "682",
        "name": "As You Have Promised, Lord",
        "category": "Sentences and Responses",
        "other": [["As You have promised, Lord, today,", "You are letting Your servant go away in peace.", "May eyes have seen You in broad daylight", "before all nations, planning salvation.", "Light of revelation for the nations,", "and glory of Your people Israel."]]
      }, {
        "number": "683",
        "name": "Jesus, Stand Among Us",
        "category": "Sentences and Responses",
        "verses": [["Jesus, stand among us", "In Thy risen power;", "Let this time of worship", "Be a hallowed hour."], ["Breathe the Holy Spirit", "Into every heart;", "Bid the fears and sorrows", "From each soul depart."], ["Thus with quickened footsteps", "We pursue our way,", "Watching for the dawning", "Of eternal day."]]
      }, {
        "number": "684",
        "name": "Hear Our Prayer, O Lord",
        "category": "Sentences and Responses",
        "other": [["Hear our prayer, O Lord,", "Hear our prayer, O Lord;", "Incline Thine ear to us,", "And grant us Thy peace.", "Amen."]]
      }, {
        "number": "685",
        "name": "Cause Me to Hear",
        "category": "Sentences and Responses",
        "other": [["Cause me to hear Thy loving kindness in the morning,", "for in Thee do I trust.", "Cause me to know they way where-in I shall walk", "for I lift up my soul to Thee.", "Amen"]]
      }, {
        "number": "686",
        "name": "Bless Thou the Gifts",
        "category": "Sentences and Responses",
        "other": [["Bless thou the gifts our hands have brought;", "bless thou the work our hearts have planned.", "Ours is the faith, the will, the thought;", "the rest, O God, is in thy hand.", "Amen."]]
      }, {
        "number": "687",
        "name": "The Lord Is in His Holy Temple",
        "category": "Sentences and Responses",
        "other": [["The Lord is in His holy temple,", "The Lord is in His holy temple,", "Let all the earth be silent", "be silent before Him."]]
      }, {
        "number": "688",
        "name": "Surely, Surely",
        "category": "Sentences and Responses",
        "other": [["Surely, surely the Lord has been here,", "Surely angels still linger near;", "I hear music soft on my ear,", "I feel His Spirit,", "I have no fear."]]
      }, {
        "number": "689",
        "name": "Day by Day, Dear Lord",
        "category": "Sentences and Responses",
        "other": [["Day by day, Dear Lord, of Thee three things I pray:", "To see Thee more clearly, Love Thee more dearly,", "Follow Thee more nearly, Day by day."]]
      }, {
        "number": "690",
        "name": "Dismiss Us, Lord, With Blessing",
        "category": "Sentences and Responses",
        "other": [["Dismiss us, Lord, with blessing, we pray;", "As from Thy worship we go our ways;", "Guide in life's conflicts, all through the day;", "Save in Thy kingdom, Thine be the praise.", "Amen."]]
      }, {
        "number": "691",
        "name": "Lead Me, Lord",
        "category": "Sentences and Responses",
        "other": [["Lead me, Lord, lead me in thy righteousness;", "make thy way plain before my face."], ["that makest me dwell in safety."]]
      }, {
        "number": "692",
        "name": "The Lord Is in His Holy Temple",
        "category": "Sentences and Responses",
        "other": [["The Lord is in His holy temple,", "The Lord is in His holy temple,", "Let all the earth keep silence,", "Let all the earth keep silence,", "before Him,", "Keep silence, keep silence,", "before Him. Amen."]]
      }, {
        "number": "693",
        "name": "Almighty Father",
        "category": "Sentences and Responses",
        "other": [["Almighty Father, hear our prayer,", "bless all souls that wait before Thee.", "Amen."]]
      }, {
        "number": "694",
        "name": "Praise God, From Whom All Blessings",
        "category": "Sentences and Responses",
        "other": [["Praise God, from Whom all blessings flow;", "Praise Him, all creatures here below;", "Praise Him above, ye heavenly host;", "Praise Father, Son, and Holy Ghost."]]
      }, {
        "number": "695",
        "name": "Praise God, From Whom All Blessings",
        "category": "Sentences and Responses",
        "other": [["Praise God, from Whom all blessings flow;", "Praise Him, all creatures here below;", "Praise Him above, ye heavenly host;", "Praise Father, Son, and Holy Ghost."]]
      }],
    "categories": {
      "main": [[0, 69, 73, 114, 256, 270, 278, 343, 379, 437, 454, 649, 659, 695], ["Worship", "Trinity", "God the Father", "Jesus Christ", "Holy Spirit", "Holy Scriptures", "Gospel", "Christian Church", "Doctrines", "Early Advent", "Christian Life", "Christian Home", "Sentences and Responses"]],
      "sub": [[0, 38, 45, 58, 63, 69, 73, 81, 91, 98, 104, 114, 117, 143, 153, 164, 176, 180, 199, 221, 227, 256, 270, 278, 290, 296, 300, 331, 333, 343, 354, 375, 376, 378, 379, 395, 411, 412, 414, 417, 419, 437, 454, 460, 471, 477, 505, 535, 555, 566, 570, 584, 589, 591, 605, 619, 633, 641, 644, 649, 655, 659, 695], ["Adoration and Praise", "Morning Worship", "Evening Worship", "Opening of Worship", "Close of Worship", undefined, "Love of God", "Majesty and Power of God", "Power of God in Nature", "Faithfulness of God", "Grace and Mercy of God", "First Advent", "Birth", "Life and Ministry", "Sufferings and Death", "Resurrection and Ascension", "Priesthood", "Love of Christ for Us", "Second Advent", "Kingdom and Reign", "Glory and Praise", undefined, undefined, "Invitation", "Repentance", "Forgiveness", "Consecration", "Baptism", "Salvation and Redemption", "Community in Christ", "Mission of the Church", "Church Dedication", "Ordination", "Child Dedication", "Sabbath", "Communion", "Law and Grace", "Spiritual Gifts", "Judgment", "Resurrection of the Saints", "Eternal Life", undefined, "Our Love for God", "Joy and Peace", "Hope and Comfort", "Meditation and Prayer", "Faith and Trust", "Guidance", "Thankfulness", "Humility", "Loving Service", "Love for One Another", "Obedience", "Watchfulness", "Christian Warfare", "Pilgrimage", "Stewardship", "Health and Wholeness", "Love of the Country", "Love in the Home", "Marriage", undefined]]
    }
  },
  "es2010": {
    "id": "es2010",
    "languageCode": "es",
    "year": "2010",
    "data": [
      {
				"name": "Cantad alegres al Señor",
				"number": "1",
				"category": "El culto",
				"subcategory": "Adoración y alabanza",
				"verses": [["Cantad alegres al Señor,", "mortales todos por doquier;", "servidle siempre con fervor,", "obedecedle con placer."], ["Con gratitud canción alzad", "al Hacedor que el ser os dio;", "al Dios excelso venerad,", "que como Padre nos amó."], ["Su pueblo somos: salvará", "a los que busquen al Señor;", "ninguno de ellos dejará;", "él los ampara con su amor."]]
			}, {
				"name": "Da gloria al Señor",
				"number": "2",
				"category": "El culto",
				"subcategory": "Adoración y alabanza",
				"verses": [["Da gloria al Señor, de rodillas adórale", "en la hermosura de su santidad,", "tu plena obediencia cual oro ofreciéndole", "con el incienso de grata humildad."], ["La carga del mal deposita hoy a sus pies,", "la llevará sobre su corazón;", "tus penas te quitará,", "limpiando tus lágrimas,", "guiando tus pies a mayor bendición."], ["En sus santos atrios adonde convídate,", "aunque eres pobre no temas entrar;", "la firme, constante fe y el puro, sencillo amor:", "tales ofrendas pon sobre el altar"], ["Y cuando tus dones le entregues a tu Señor,", "por su Hijo amado los aceptará;", "y tras noche lúgubre habrá aurora espléndida:", "gozo, alegría y paz te dará."]]
			}, {
				"name": "Unidos en espíritu",
				"number": "3",
				"category": "El culto",
				"subcategory": "Adoración y alabanza",
				"verses": [["Unidos en espíritu al coro celestial,", "cantemos con los ángeles un cántico triunfal.", "Y si vertimos lágrimas al frente de la cruz,", "rebose hoy el júbilo, pues vive el buen Jesús."], ["Lo que en el triste Gólgota derrota pareció,", "desde el sellado túmulo en triunfo se cambió.", "Vencido el enemigo está, menguado su poder;", "y el mortal su súbdito ya no habrá de ser."], ["Jesús, de gloria Príncipe, autor de nuestra paz,", "ven, muéstranos benévolo tu esplendorosa faz.", "Y acepta el dulce cántico de nuestra gratitud,", "por tu valiosa dádiva de la eternal salud."]]
			}, {
				"name": "Alabanzas sin cesar",
				"number": "4",
				"category": "El culto",
				"subcategory": "Adoración y alabanza",
				"verses": [["Alabanzas sin cesar entonemos al Señor;", "himnos mil a su bondad entonemos con amor.", "Él nos da la plenitud de su gracia celestial;", "es la fuente de salud para el infeliz mortal."], ["Del pecado abrumador él nos vino a libertar;", "nos ofrece salvación, y nos llama sin cesar.", "Ya podemos recorrer el camino terrenal", "sin temor hasta obtener nuestra herencia celestial."], ["Y entretanto que el Señor nos reciba donde está,", "entonemos el loor que bondadoso aceptará.", "Mientras huelle nuestro pie este mundo pecador,", "ofrezcámosle con fe nuestro canto, nuestro amor."]]
			}, {
				"name": "A ti, glorioso Dios",
				"number": "5",
				"category": "El culto",
				"subcategory": "Adoración y alabanza",
				"verses": [["A ti, glorioso Dios, cantamos alabanzas;", "rendímoste honor por todas tus grandezas.", "Nos das tu bendición en nuestra senda aquí;", "nos guiarás, Señor, a tu mansión allí."], ["Ven siempre, oh gran Dios, muy cerca de nosotros;", "con celo y con fervor queremos ir a otros.", "Tu brazo protector del mal nos guardará;", "en lucha y aflicción consuelo nos será."], ["Oh Padre, eterno Dios, cantámoste loores,", "y al Hijo Redentor, Señor de los señores,", "y al Santo Espíritu, el gran Consolador:", "al grande, trino Dios cantamos con fervor."]]
			}, {
        "name": "¡Hosanna!",
        "number": "6",
        "category": "El culto",
        "subcategory": "Adoración y alabanza",
        "verses": [["¡Hosanna! ¡Hosanna! ¡Hosanna!", "En cielo y tierra, es del Señor", "la gloria y potestad,", "y nos circunda con su amor", "por la eternidad.", "Alzad, pues, himnos de loor,", "que es grato a nuestro Dios;", "a él rindamos todo honor", "ahora y siempre, amén.", "¡A Dios rindamos todo honor,", "todo honor, todo honor!", "¡A Dios rindamos todo honor,", "ahora y siempre! Amén."]]
      }, {
        "name": "Oh Dios, mi soberano Rey",
        "number": "7",
        "category": "El culto",
        "subcategory": "Adoración y alabanza",
        "verses": [["Oh Dios, mi soberano Rey;", "a ti daré loor;", "tu nombre yo exaltaré,", "santísimo Señor."], ["Tus obras evidencia son", "de tu infinito amor,", "y cantan con alegre voz", "las glorias del Señor."], ["Aquel que busca salvación,", "en Cristo la hallará;", "y su ferviente petición", "él pronto atenderá."], ["Eternamente durará", "el reino del Señor.", "Allí sus siervos gozarán", "la plenitud de amor."]]
      }, {
        "name": "¡Suenen dulces himnos!",
        "number": "8",
        "category": "El culto",
        "subcategory": "Adoración y alabanza",
        "verses": [["¡Suenen dulces himnos gratos al Señor", "y óiganse en concierto universal!", "Desde el alto cielo baja el Salvador", "para beneficio del mortal."], ["Montes y collados fluyan leche y miel,", "y abundancia esparzan y solaz.", "Gócense los pueblos, gócese Israel,", "que a la tierra viene ya la paz."], ["Salte, de alegría lleno el corazón,", "la abatida y pobre humanidad;", "Dios se compadece viendo su aflicción", "y le muestra buena voluntad."], ["Vibre en nuestros pechos noble gratitud", "hacia quien nos brinda redención;", "y a Jesús el Cristo, que nos da salud,", "tributemos nuestra adoración."]],
        "refrainFirst": false,
        "refrain": ["¡Gloria!, ¡gloria sea a nuestro Dios!", "¡Gloria!, sí, cantemos a una voz.", "Y el cantar de gloria que se oyó en Belén,", "sea nuestro cántico también."]
      }, {
        "name": "Alabemos al Señor",
        "number": "9",
        "category": "El culto",
        "subcategory": "Adoración y alabanza",
        "verses": [["Alabemos al Señor;", "demos gloria al Señor.", "Aleluya, amén, aleluya, amén."], ["Prediquemos de su amor;", "anunciemos su perdón.", "Aleluya, amén,", "aleluya, amén."]]
      }, {
        "name": "Alaba al Dios de Abraham",
        "number": "10",
        "category": "El culto",
        "subcategory": "Adoración y alabanza",
        "verses": [["Alaba al Dios de Abraham, quien reina con honor;", "quien es anciano eterno y gran Dios de amor.", "Yo soy el gran Jehová proclama a gran voz.", "Me inclino y bendigo el nombre del santo Dios."], ["Alaba al Dios de Abraham, quien reina en majestad.", "Me elevo y busco el gozo que a su diestra va.", "La fama y el poder todo esto lo dejé.", "Él es mi parte, torre y escudo que yo busqué."], ["Triunfante multitud las gracias todos dan.", "Al Padre, Hijo, Espíritu, cantando están.", "Alaba al Dios de Abraham, Señor del gran confín.", "Pues suyo es el poder, majestad y loor sin fin."]]
      }, {
        "name": "Alma, bendice al Señor",
        "number": "11",
        "category": "El culto",
        "subcategory": "Adoración y alabanza",
        "verses": [["Alma, bendice al Señor, Rey potente de gloria;", "de sus mercedes esté viva en ti la memoria.", "¡Oh, despertad! y con salterio entonad", "himnos de honor y victoria."], ["Alma, bendice al Señor que a los cielos gobierna,", "y te conduce paciente con mano paterna;", "te perdonó, de todo mal te libró,", "porque su gracia es eterna."], ["Alma, bendice al Señor, de tu vida es la fuente", "que te creó, y en salud te sostiene clemente;", "tu defensor en todo trance y dolor;", "su diestra es omnipotente."]]
      }, {
        "name": "Todos juntos reunidos",
        "number": "12",
        "category": "El culto",
        "subcategory": "Adoración y alabanza",
        "verses": [["Todos juntos reunidos", "te alabamos, oh Señor;", "a tus hijos muy queridos", "les concedes este honor.", "Adorarte y alabarte", "sea nuestra ocupación;", "que podamos proclamarte", "Dios de nuestra salvación."], ["Siempre seas alabado", "por tu inmensa caridad,", "oh gran Dios, y celebrado", "seas en la eternidad.", "Eres el Señor benigno", "que perdona con amor;", "de tus hijos eres digno", "que te ofrezcan el loor."]]
      }, {
        "name": "Al Dios invisible",
        "number": "13",
        "category": "El culto",
        "subcategory": "Adoración y alabanza",
        "verses": [["Al Dios invisible, al Rey inmortal,", "que habita en la altura y en la santidad;", "Anciano de días, Señor sin igual,", "rendimos honores con sinceridad."], ["Sin prisa, ni pausa, constante y leal,", "gobiernas el mundo con solicitud;", "tú muestras a todos justicia imparcial;", "abundas en gracia, amor y virtud."], ["De cada ser vivo tú eres autor;", "sustentas la vida de todo mortal.", "Nosotros morimos, cual por al calor;", "mas tú permaneces por siempre inmortal."], ["Señor, te rodea infinito fulgor;", "los ángeles cubren su rostro ante ti.", "Nosotros con gran reverencia y fervor,", "te damos sincera alabanza aquí."]]
      }, {
        "name": "Engrandecido sea Dios",
        "number": "14",
        "category": "El culto",
        "subcategory": "Adoración y alabanza",
        "verses": [["Engrandecido sea Dios", "en esta ocasión.", "Alegres, juntos a una voz", "dad gloria, gloria, gloria", "al Dios eternal."], ["Durante el día que pasó,", "la mano del Señor", "de muchos males nos salvó:", "dad gloria, gloria, gloria", "al Dios eternal."], ["Él hasta aquí nos ayudó,", "y siempre proveerá.", "Con gratitud, placer y amor", "dad gloria, gloria, gloria", "al Dios eternal."], ["A otras almas salva, ¡oh Dios!", "Despiértalas, Señor;", "escucha nuestra petición,", "y salva, salva, salva,", "salva al pecador."]]
      }, {
        "name": "Loámoste, ¡oh Dios!",
        "number": "15",
        "category": "El culto",
        "subcategory": "Adoración y alabanza",
        "verses": [["Loámoste, ¡Oh Dios!,", "con alegre canción,", "porque en Cristo tu Hijo", "nos diste perdón."], ["A ti, oh Señor,", "que tu trono de luz", "has dejado por darnos", "perdón en la cruz."], ["Te damos loor,", "santo Consolador,", "que nos llenas de gozo", "y santo valor."]],
        "refrainFirst": false,
        "refrain": ["¡Aleluya! Te alabamos.", "¡Cuán grande es tu amor!", "¡Aleluya! Te adoramos,", "bendito Señor."]
      }, {
        "name": "A nuestro Padre Dios",
        "number": "16",
        "category": "El culto",
        "subcategory": "Adoración y alabanza",
        "verses": [["A nuestro Padre Dios", "alcemos nuestra voz.", "¡Gloria a él!", "Tal fue su amor que dio", "al Hijo que murió,", "en quien confío yo.", "¡Gloria a él!"], ["A nuestro Salvador", "demos con fe loor.", "¡Gloria a él!", "Su sangre derramó;", "con ella me lavó;", "y el cielo me abrió.", "¡Gloria a él!"], ["Espíritu de Dios,", "elevo a ti mi voz.", "¡Gloria a ti!", "Con celestial fulgor", "me muestras el amor", "de Cristo, mi Señor.", "¡Gloria a ti!"]]
      }, {
        "name": "Oh Padre, eterno Dios",
        "number": "17",
        "category": "El culto",
        "subcategory": "Adoración y alabanza",
        "verses": [["Oh Padre, eterno Dios,", "alzamos nuestra voz", "en gratitud", "por lo que tú nos das", "con sin igual amor,", "y hallamos dulce paz", "en ti, Señor."], ["Bendito Salvador,", "te damos con amor", "el corazón;", "acepta, oh Señor,", "lo que en tu altar", "venimos a ofrendar", "cual vivo don."], ["Espíritu de Dios,", "escucha nuestra voz,", "y en tu bondad", "derrama en nuestro ser", "divina claridad,", "copiosa bendición", "y santidad."]]
      }, {
        "name": "Load al Padre",
        "number": "18",
        "category": "El culto",
        "subcategory": "Adoración y alabanza",
        "verses": [["Load al Padre por su gracia eterna;", "quien tiernamente cuida de sus hijos.", "¡ngeles, alabad. Alabadle, cielos.", "Load a Jehová."], ["Load al Hijo, Salvador amante,", "que compasivo vela por sus hijos.", "Ancianos, jóvenes, padres, niños alabad", "a Cristo el Redentor."], ["Load al Santo Espíritu divino,", "que fue enviado para bendecirnos.", "Que tu presencia llene nuestras vidas,", "Santo Consolador."]]
      }, {
        "name": "Padre nuestro",
        "number": "19",
        "category": "El culto",
        "subcategory": "Adoración y alabanza",
        "verses": [["Padre nuestro, Padre nuestro,", "te alabamos porque eres", "nuestro Padre.", "Elevamos nuestras mentes", "como signo de amor.", "Padre nuestro, Padre nuestro."], ["Jesucristo, Jesucristo,", "gracias por tu muerte", "cruenta en el Calvario.", "Elevamos nuestras voces", "como signo de amor. Jesucristo, Jesucristo."], ["Santo Espíritu, Santo Espíritu;", "llena nuestros corazones plenamente.", "Consagramos nuestras vidas", "como signo de amor.", "Santo Espíritu, Santo Espíritu."]]
      }, {
        "name": "A Dios, el Padre celestial",
        "number": "20",
        "category": "El culto",
        "subcategory": "Adoración y alabanza",
        "verses": [["A Dios, el Padre celestial;", "al Hijo, nuestro Redentor;", "al eternal Consolador,", "unidos, todos alabad.", "Amén."]]
      }, {
        "name": "Gloria sea al Padre",
        "number": "21",
        "category": "El culto",
        "subcategory": "Adoración y alabanza",
        "verses": [["Gloria sea al Padre", "y al Hijo Dios", "y al Santo Espíritu.", "Como eran al principio,", "son hoy y serán por siempre.", "¡Gloria sin fin!", "Amén, amén."]]
      }, {
        "name": "Jehová está en su santo templo",
        "number": "22",
        "category": "El culto",
        "subcategory": "Inicio del culto",
        "verses": [["Jehová está en su santo templo,", "Jehová está en su santo templo;", "seamos reverentes,", "seamos reverentes", "ante el Señor.", "¡Silencio!, ¡silencio!,", "ante el Señor.", "Amén."]]
      }, {
        "name": "¡Silencio! ¡Silencio!",
        "number": "23",
        "category": "El culto",
        "subcategory": "Inicio del culto",
        "verses": [["¡Silencio! ¡Silencio!", "en este lugar;", "¡silencio! silencio", "habéis de guardar."], ["¡Silencio! ¡Silencio!", "Es tiempo de orar,", "la gracia divina podréis disfrutar."], ["¡Silencio! ¡Silencio!", "su amor recordad.", "A Dios, pues, postrados,", "honor tributad."]],
        "refrainFirst": false,
        "refrain": ["Quedad en silencio", "en este lugar;", "silencio, silencio", "guardad al orar."]
      }, {
        "name": "Imploramos tu presencia",
        "number": "24",
        "category": "El culto",
        "subcategory": "Inicio del culto",
        "verses": [["Imploramos tu presencia,", "Santo Espíritu de Dios;", "nos avive tu influencia,", "fe y amor auméntanos."], ["Da a las mentes luz divina", "y tu gracia al corazón.", "Nuestro pecho a Dios inclina", "en sincera devoción."], ["Que del Dios bendito tenga", "nuestro culto aceptación,", "y que sobre todos venga", "en raudales bendición."]]
      }, {
        "name": "Siento la presencia del Señor",
        "number": "25",
        "category": "El culto",
        "subcategory": "Inicio del culto",
        "verses": [["Siento la presencia del Señor que está aquí,", "su poder y su gracia, su amor.", "Puedo ver en cada rostro su belleza irradiar.", "Siento la presencia del Señor que está aquí."]]
      }, {
        "name": "Aquí reunidos",
        "number": "26",
        "category": "El culto",
        "subcategory": "Inicio del culto",
        "verses": [["Aquí reunidos en tu santo nombre", "rogamos nos des tu divino perdón.", "Perdido, afligido se siente el hombre,", "mas gracias, oh Jesús, por la salvación."], ["Es Cristo Jesús nuestro eterno amparo.", "Ordena y mantiene su reino de amor.", "Nos muestra el camino con célico faro,", "nos guarda y nos protege. ¡Gloria al Señor!"], ["Con voces alegres te glorificamos.", "¡Oh, Cristo, sé tú nuestro gran Protector!", "Contigo en las luchas victorias ganamos.", "¡Tu nombre sea honrado, oh Salvador!"]]
      }, {
        "name": "¡Oh Pastor divino, escucha!",
        "number": "27",
        "category": "El culto",
        "subcategory": "Inicio del culto",
        "verses": [["¡Oh Pastor divino!, escucha", "a los que en este buen lugar,", "como ovejas, congregados te venimos a buscar.", "Ven, oh Cristo; ven, oh Cristo, tu rebaño a apacentar."], ["Al perdido en el pecado,", "su peligro harás sentir;", "llama al pobre seducido, déjale tu voz oír.", "Al enfermo, al enfermo, pronto dígnate acudir."], ["Guía al triste y fatigado", "al aprisco del Señor;", "cría al tierno corderito a tu lado, buen Pastor,", "con los pastos, con los pastos de celeste y dulce amor."], ["¡Oh Jesús!, escucha el ruego", "y esta humilde petición.", "Ven a henchir a tu rebaño de sincera devoción.", "Cantaremos, cantaremos tu benigna protección."]]
      }, {
        "name": "Tu pueblo jubiloso",
        "number": "28",
        "category": "El culto",
        "subcategory": "Inicio del culto",
        "verses": [["Tu pueblo jubiloso se acerca a ti, Señor,", "y con triunfantes voces hoy canta tu loor;", "por todas tus bondades que das en plenitud,", "tu pueblo humildemente te expresa gratitud."], ["Aunque el humano nunca te pueda aquí palpar,", "tú siempre con los tuyos has prometido estar;", "los cielos te revelan, Rey nuestro y Creador,", "sentimos tu presencia en nuestro ser, Señor."], ["¡Oh, Cristo!, te adoramos, te damos nuestro amor;", "¡oh!, llena nuestras vidas de fuerza, fe y valor;", "impártanos tu gracia la vida celestial;", "que siempre te rindamos adoración leal."]]
      }, {
        "name": "Del culto el tiempo llega",
        "number": "29",
        "category": "El culto",
        "subcategory": "Inicio del culto",
        "verses": [["Del culto el tiempo llega, comienza la oración,", "el alma a Dios se entrega, ¡silencio y atención!", "Si al santo Dios la mente queremos elevar,", "silencio reverente habremos de guardar."], ["Mil coros celestiales a Dios cantando están.", "Con ellos los mortales sus voces unirán.", "Alcemos pues el alma en santa devoción,", "gozando en dulce calma de Dios la comunión."], ["La Biblia bendecida, de Dios revelación,", "a meditar convida en nuestra condición.", "¡Silencio!, que ha llegado del culto la ocasión;", "Dios se halla a nuestro lado, ¡silencio y devoción!"]]
      }, {
        "name": "Abre mis ojos",
        "number": "30",
        "category": "El culto",
        "subcategory": "Inicio del culto",
        "verses": [["Abre mis ojos,", "Jesús, quiero verte,", "conmigo tenerte,", "decir que te amo.", "Abre mi oído,", "hoy quiero escucharte.", "¡Abre mis ojos", "para contemplarte!"]]
      }, {
        "name": "¡Oh, Señor! al orar",
        "number": "31",
        "category": "El culto",
        "subcategory": "Inicio del culto",
        "verses": [["¡Oh, Señor! al orar,", "mis problemas quiero entregar,", "a tu Trono de gracia llegar", "y tu bendición alcanzar.", "Hoy mi vida transforma, Señor;", "que mi mente entienda tu amor,", "y al postrarme humilde ante ti,", "¡oh, Señor, escucha mi oración!"]]
      }, {
        "name": "Nos reunimos en tu santuario",
        "number": "32",
        "category": "El culto",
        "subcategory": "Inicio del culto",
        "verses": [["Nos reunimos en tu santuario,", "donde santos ángeles te rinden su loor.", "Vamos a adorar tu gran nombre;", "en la santidad de tu presencia te alabo, Señor."]]
      }, {
        "name": "Tu presencia, Padre amante, invocamos",
        "number": "33",
        "category": "El culto",
        "subcategory": "Inicio del culto",
        "verses": [["Tu presencia, Padre amante,", "invocamos reverentes.", "Nuestro ser y amor", "ofrendamos, Señor,", "y la gloria de tu verdad", "hoy descienda a este lugar."]]
      }, {
        "name": "En momentos así",
        "number": "34",
        "category": "El culto",
        "subcategory": "Inicio del culto",
        "verses": [["En momentos así", "yo canto canción,", "canción de amor a mi Cristo.", "En momentos así", "yo busco al Señor,", "elevo mi alma a Dios.", "Hoy te adoro, Señor,", "y te alabo, mi Dios;", "te amo, mi Jesús,", "te amo, mi Señor."]]
      }, {
        "name": "Oye, oh Señor",
        "number": "35",
        "category": "El culto",
        "subcategory": "Cierre del culto",
        "verses": [["Oye, oh Señor, nuestra oración;", "tu faz inclina hoy, y danos tu paz.", "Amén."]]
      }, {
        "name": "¡Oh, Dios, que oyes cada oración!",
        "number": "36",
        "category": "El culto",
        "subcategory": "Cierre del culto",
        "verses": [["¡Oh, Dios, que oyes cada oración,", "escucha nuestra humilde petición!", "Tú, que eres vida, gozo y solaz;", "danos tu gracia y tu dulce paz.", "Amén."]]
      }, {
        "name": "Dios os guarde",
        "number": "37",
        "category": "El culto",
        "subcategory": "Cierre del culto",
        "verses": [["Dios os guarde en su divino amor,", "hasta el día en que lleguemos", "a la patria do estaremos", "para siempre con el Salvador."], ["Dios os guarde en su divino amor;", "en la senda peligrosa", "de esta vida tormentosa", "os conserve en paz y sin temor."], ["Dios os guarde en su divino amor,", "os conduzca su bandera,", "y os conceda en gran manera", "de su Espíritu consolador."], ["Dios os guarde en su divino amor,", "con su gracia os sostenga", "hasta cuando Cristo venga", "en su reino con gran esplendor."]],
        "refrainFirst": false,
        "refrain": ["Al venir Jesús nos veremos a los pies de nuestro Rey;", "reunidos todos seremos, un redil habrá y solo una grey."]
      }, {
        "name": "Que Dios te guarde",
        "number": "38",
        "category": "El culto",
        "subcategory": "Cierre del culto",
        "verses": [["Que Dios te guarde dondequiera estés,", "que te proteja con su tierno amor;", "guíe tus pasos por doquiera vas;", "que Dios te guarde al separarnos,", "que Dios te guarde."], ["Que Dios te guarde, vele sobre ti,", "que te acompañe, cuide tu andar.", "Que cada día puedas ver su faz;", "que Dios te guarde hasta encontrarnos,", "que Dios te guarde."]]
      }, {
        "name": "Despide hoy tu grey",
        "number": "39",
        "category": "El culto",
        "subcategory": "Cierre del culto",
        "verses": [["Despide hoy tu grey", "en paz y bendición,", "y las palabras de tu Ley", "conserve el corazón."], ["Enséñanos, Señor,", "tu Ley a meditar,", "vivir unidos en amor,", "y en él por siempre andar."]]
      }, {
        "name": "Shalom",
        "number": "40",
        "category": "El culto",
        "subcategory": "Cierre del culto",
        "verses": [["Que Dios te dé su bendición,", "shalom, shalom.", "Te guarde Dios de todo mal,", "shalom, shalom."], ["Misericordia tenga de ti,", "shalom, shalom.", "y resplandezca su bondad,", "shalom, shalom."], ["Él alce su rostro sobre ti,", "shalom, shalom.", "Que ponga en ti su paz y amor,", "shalom, shalom."]]
      }, {
        "name": "Gracia, amor y comunión",
        "number": "41",
        "category": "El culto",
        "subcategory": "Cierre del culto",
        "verses": [["Que la gracia de Jesús,", "el amor de Dios el Padre", "y la comunión del Espíritu nos llenen hoy.", "Que la gracia de Jesús,", "el amor de Dios el Padre", "y la comunión del Espíritu eternal", "perduren por siempre en nuestro corazón."]]
      }, {
        "name": "Queda, Señor",
        "number": "42",
        "category": "El culto",
        "subcategory": "Cierre del culto",
        "verses": [["Queda, Señor, en este corazón;", "dame tu paz y lléname de amor;", "que tu influencia permanezca en mí;", "viva por siempre con tu bendición.", "Amén."]]
      }, {
        "name": "Agua de vida",
        "number": "43",
        "category": "El culto",
        "subcategory": "Cierre del culto",
        "verses": [["Agua de vida aquí encontré,", "fuente de eterna salvación;", "agua que siempre me saciará,", "pues Jesús me renovará."], ["Luego de tributar con fe", "culto de gloria al Creador,", "reconsagramos el corazón", "al Señor que nos redimió.", "Amén."]]
      }, {
        "name": "Despídenos con tu bendición",
        "number": "44",
        "category": "El culto",
        "subcategory": "Cierre del culto",
        "verses": [["Despídenos con tu bendición", "al retirarnos de este lugar;", "que la merced de esta reunión", "en nuestras almas pueda quedar.", "Amén."]]
      }, {
        "name": "Después, Señor",
        "number": "45",
        "category": "El culto",
        "subcategory": "Cierre del culto",
        "verses": [["Después, Señor, de haber tenido aquí", "de tu Palabra la bendita luz,", "a nuestro hogar condúcenos y allí", "de todos cuida, buen Pastor, Jesús."], ["En nuestras mentes grabe con poder", "tu fiel Palabra cada exhortación;", "y que tu Ley, pudiendo comprender,", "contigo estemos en mayor unión."], ["Al terminar, Señor, mi vida aquí,", "mis ojos pueda sin temor cerrar,", "y en mi glorioso despertar", "que en ti de paz eterna pueda disfrutar."]]
      }, {
        "name": "Hoy amanece",
        "number": "46",
        "category": "El culto",
        "subcategory": "Culto matutino",
        "verses": [["Hoy amanece como al principio", "del primigenio, glorioso, Edén.", "Aves canoras alzan sus trinos", "al que es la Fuente de todo bien."], ["Tiembla el rocío sobre la fronda,", "como antaño en la creación.", "Canto alabanzas al que otorga", "con su Palabra la bendición."], ["Esta mañana es mi delicia,", "es luz de gloria de mi Hacedor;", "a quien alabo porque me brinda", "un nuevo día y su tierno amor."]]
      }, {
        "name": "Por la mañana",
        "number": "47",
        "category": "El culto",
        "subcategory": "Culto matutino",
        "verses": [["Por la mañana, ¡oh Señor!,", "elevo a ti mi voz;", "a tu buen nombre doy loor", "con gratitud, mi Dios."], ["El sol brillante ya salió,", "camino en su luz;", "del Salvador es símbolo,", "del magno Rey, Jesús."], ["Los cielos cuentan al que cree", "la gloria del Señor;", "la llama avivan de la fe", "y alientan el amor."], ["En la mañana eterna, pues,", "contigo cuando esté,", "yo del Cordero y de Moisés", "el himno entonaré."]]
      }, {
        "name": "Oh Dios, si he ofendido un corazón",
        "number": "48",
        "category": "El culto",
        "subcategory": "Culto vespertino",
        "verses": [["Oh Dios, si he ofendido un corazón,", "si he sido causa de su perdición,", "si he andado hoy sin discreción", "te imploro perdón."], ["Si he proferido voces de maldad,", "faltando en demostrar la caridad,", "oh santo Dios, buscándote en verdad", "te imploro perdón."], ["Si he sido perezoso en trabajar,", "dejando mi deber sin realizar", "en vez de hacer tu celestial mandar,", "te imploro perdón."], ["Tú, del contrito, fiel perdonador,", "que atiendes al clamor del pecador,", "perdóname y guárdame en tu amor,", "por Cristo. Amén."]]
      }, {
        "name": "Cristo, ya la noche cierra",
        "number": "49",
        "category": "El culto",
        "subcategory": "Culto vespertino",
        "verses": [["Cristo, ya la noche cierra;", "al turbado da solaz;", "nuestro error te confesamos;", "da reposo, calma y paz."], ["Cuando el enemigo asalte", "y ande en torno destrucción,", "que tus ángeles, oh Padre,", "den amparo y protección."], ["Aunque lóbrega la noche,", "siempre vernos tú podrás;", "vigilante, sin cansarte,", "a tu pueblo guardarás."], ["Si la muerte nos alcanza", "en el lecho nuestro aquí,", "que Jesús en su gran día", "nos despierte en gloria allí."]]
      }, {
        "name": "Baja el sol",
        "number": "50",
        "category": "El culto",
        "subcategory": "Culto vespertino",
        "verses": [["Baja el sol tras las montañas, y la tarde ya llegó;", "calma y quieta cae la noche; otro día terminó;", "ya se fue con sus problemas, viene otro anochecer;", "más cercano está el día cuando a Cristo he de ver."], ["Muy cansado el peregrino ve el fin del día llegar,", "porque del trabajo arduo otra vez va a descansar.", "Tal la vida en este mundo, que me toca afrontar,", "y las sombras de la noche sé que pronto he de probar."], ["Otro día en el viaje a mi hogar, el celestial;", "ya más cerca está el río claro como el cristal;", "ya el cielo se aclara, puedo ver su áurea luz;", "cada día más me acerco a la patria de Jesús."]],
        "refrainFirst": false,
        "refrain": ["Más cerca estoy,", "más cerca estoy", "de mi hogar,", "mi hogar celestial;", "más cerca del Edén", "adonde pronto iré,", "donde el gozo es eternal."]
      }, {
        "name": "Nuestro sol se pone ya",
        "number": "51",
        "category": "El culto",
        "subcategory": "Culto vespertino",
        "verses": [["Nuestro sol se pone ya,", "todo en calma quedará;", "la plegaria levantad", "que bendiga la bondad", "de nuestro Dios."], ["¡Oh Señor!, tu protección", "dale ahora al corazón;", "dale aquella dulce paz", "que a los tuyos siempre das", "con plenitud."], ["¡Oh Señor!, que al descansar", "pueda en ti seguro estar,", "y mañana, mi deber", "pueda alegre y fiel hacer", "en tu loor."]],
        "refrainFirst": false,
        "refrain": ["¡Santo, santo, santo, Señor Jehová!", "Cielo y tierra de tu amor", "llenos hoy están, Señor,", "¡loor a ti!"]
      }, {
        "name": "Señor Jesús, el día ya se fue",
        "number": "52",
        "category": "El culto",
        "subcategory": "Culto vespertino",
        "verses": [["Señor Jesús, el día ya se fue;", "la noche cierra, oh, conmigo sé;", "al desvalido, por tu compasión", "dale tu amparo y consolación."], ["Veloz el día nuestro huyendo va,", "su gloria, sus ensueños pasan ya;", "mudanza y muerte veo en redor;", "no mudas tú: conmigo sé, Señor."], ["Tu gracia en todo el día he menester.", "¿Quién otro puede al tentador vencer?", "¿Qué otro amante guía encontraré?", "En sombra o sol, Señor, conmigo sé."], ["Que vea al fin en mi postrer visión", "de luz la senda que me lleve a Sion,", "do alegre cantaré al triunfar la fe:", "\"Jesús conmigo en vida y muerte fue\"."]]
      }, {
        "name": "¡Oh amor de Dios!",
        "number": "53",
        "category": "Dios el Padre",
        "subcategory": "Amor y fidelidad de Dios",
        "verses": [["¡Oh amor de Dios!, tu inmensidad, el hombre no podrá contar,", "ni comprender la gran verdad: que Dios al hombre pudo amar.", "Cuando el pecar entró al hogar de Adán y Eva en Edén,", "Dios los sacó, mas prometió un Salvador también."], ["Si fuera tinta todo el mar, y todo el cielo un gran papel,", "y todo hombre un escritor, y cada hoja un pincel,", "para expresar su gran amor, no bastarían jamás.", "Él me salvó, y me lavó y me da el cielo además."], ["Yo sé que el mundo pasará, con cada trama y plan carnal,", "y todo reino caerá, con cada trono mundanal.", "Pero el amor del Redentor por siempre durará;", "la gran canción de salvación su pueblo entonará."]],
        "refrainFirst": false,
        "refrain": ["¡Oh amor de Dios!, brotando estás,", "inmensurable, eternal,", "por las edades durarás", "inagotable raudal."]
      }, {
        "name": "Tan bueno es Dios",
        "number": "54",
        "category": "Dios el Padre",
        "subcategory": "Amor y fidelidad de Dios",
        "verses": [["Tan bueno es Dios, tan bueno es Dios,", "tan bueno es Dios, bueno para mí."], ["Cristo es real, Cristo es real,", "Cristo es real, es real en mí."], ["Pronto él vendrá, pronto él vendrá,", "pronto él vendrá, él vendrá por mí."]]
      }, {
        "name": "Grande, Señor, es tu misericordia",
        "number": "55",
        "category": "Dios el Padre",
        "subcategory": "Amor y fidelidad de Dios",
        "verses": [["Grande, Señor, es tu misericordia;", "nunca termina, ni cambia jamás.", "Tu compasión y bondad no se agotan;", "tal como has sido, por siempre serás."], ["El firmamento, el sol y la luna,", "las estaciones con su esplendor;", "todos demuestran tu misericordia,", "y testifican de tu gran amor."], ["Tú me perdonas, me das paz y gozo;", "con tu presencia me guías aquí.", "Fuerza, esperanza y más bendiciones", "siempre derramas, Señor, sobre mí."]],
        "refrainFirst": false,
        "refrain": ["Tú eres fiel, Señor, eternamente fiel;", "cada mañana renuevas tu amor.", "Nada me falta, pues todo provees,", "¡grande, Señor, es tu fidelidad!"]
      }, {
        "name": "De tal manera amó",
        "number": "56",
        "category": "Dios el Padre",
        "subcategory": "Amor y fidelidad de Dios",
        "verses": [["De tal manera amó al mundo nuestro Dios", "que ha dado a su Hijo, Cristo el Señor;", "que todos los que en él confían en verdad,", "tienen vida eternal."]]
      }, {
        "name": "Mi Dios me ama",
        "number": "57",
        "category": "Dios el Padre",
        "subcategory": "Amor y fidelidad de Dios",
        "verses": [["Mi Dios me ama, él me ha salvado;", "mi Dios me ama, él me ama a mí."], ["Cautivo estuve en el pecado;", "cautivo estuve, sin Salvador."], ["Envió a Cristo para librarme;", "envió a Cristo y me libró."], ["Me ha invitado por su Palabra;", "me ha invitado con tierno amor."]],
        "refrainFirst": false,
        "refrain": ["Y lo repito:", "Mi Dios me ama,", "mi Dios me ama,", "él me ama a mí."]
      }, {
        "name": "Grande es el amor divino",
        "number": "58",
        "category": "Dios el Padre",
        "subcategory": "Amor y fidelidad de Dios",
        "verses": [["Grande es el amor divino,", "es más amplio que el mar.", "¡Qué bondad en su justicia!", "Vino el mundo a libertar."], ["En la sangre del Maestro", "hay poder de salvación,", "sanidad hay para el alma,", "y del mal hay protección."], ["Mas, oh cuánto limitamos", "por la débil comprensión", "su poder, su magna gracia,", "despreciando su gran don."], ["Respondamos prestamente", "al llamado de Jesús;", "redimionos ampliamente", "por su muerte en la cruz."]],
        "refrainFirst": false,
        "refrain": ["Él nos llama con amor;", "¡oh, sigamos al Señor!"]
      }, {
        "name": "Mirad qué amor",
        "number": "59",
        "category": "Dios el Padre",
        "subcategory": "Amor y fidelidad de Dios",
        "verses": [["Mirad qué amor nos ha dado el Padre", "al hacernos hijos de Dios;", "mirad qué amor nos ha dado el Padre", "al hacernos hijos de Dios;", "para ser llamados hijos de Dios;", "para ser llamados hijos de Dios.", "Mirad qué amor nos ha dado el Padre", "al hacernos hijos de Dios;", "mirad qué amor nos ha dado el Padre", "al hacernos hijos de Dios."]]
      }, {
        "name": "¡Santo! ¡Santo! ¡Santo! Tu gloria llena",
        "number": "60",
        "category": "Dios el Padre",
        "subcategory": "Majestad y poder de Dios",
        "verses": [["Te bendecimos, te adoramos,", "glorificamos tu nombre, oh Dios.", "¡Oh, Rey del cielo, oye clemente", "nuestra ferviente y humilde voz!"], ["Perdona al hombre la falta impía;", "mira a tu Hijo, mi Redentor.", "Ferviente entonces el alma mía", "pueda alabarte con todo amor."], ["Dignos seamos de bendecirte,", "limpias las almas de todo mal.", "Cielos y tierra cantan tu nombre,", "¡oh Dios, oh Padre, Rey celestial!"]],
        "refrainFirst": true,
        "refrain": ["¡Santo! ¡Santo! ¡Santo!", "Tu gloria llena cielo y tierra.", "¡Hosanna, hosanna, gloria a Dios!"]
      }, {
        "name": "Santo, Santo, Santo, Dios Omnipotente",
        "number": "61",
        "category": "Dios el Padre",
        "subcategory": "Majestad y poder de Dios",
        "verses": [["Santo, Santo, Santo; Dios Omnipotente;", "canto de mañana tu excelsa majestad;", "Santo, Santo, Santo, fuerte y clemente,", "Dios sobre todo, Rey de eternidad."], ["Santo, Santo, Santo; ángeles te adoran;", "echan sus coronas del trono en derredor;", "miles y millones ante ti se postran;", "tú que eras, y eres, y has de ser, Señor."], ["Santo, Santo, Santo; aunque estés velado,", "aunque el ojo humano tu faz no pueda ver;", "solo tú eres santo, como tú no hay otro;", "puro es tu amor, perfecto es tu poder."]]
      }, {
        "name": "Santo, Santo, Santo, Santo es el Señor",
        "number": "62",
        "category": "Dios el Padre",
        "subcategory": "Majestad y poder de Dios",
        "verses": [["Santo, Santo, Santo,", "Santo es el Señor.", "Santo, Santo, Santo,", "Santo es nuestro Dios.", "Todopoderoso", "siempre reinará.", "Su dominio eterno", "nunca pasará."], ["Santo, Santo, Santo,", "Santo es el Señor.", "Santo, Santo, Santo,", "Santo es nuestro Dios.", "Digno es de gloria,", "honra y poder.", "Su grandiosa obra", "brilla por doquier."]]
      }, {
        "name": "Al Rey adorad",
        "number": "63",
        "category": "Dios el Padre",
        "subcategory": "Majestad y poder de Dios",
        "verses": [["Al Rey adorad, grandioso Señor,", "y con gratitud cantad de su amor.", "Anciano de días, y gran Defensor,", "de gloria vestido, te damos loor."], ["Load su amor, su gracia cantad;", "vestido de luz y de majestad.", "Su carro de fuego en las nubes mirad,", "refulgen sus huellas en la tempestad."], ["Tu inmensa bondad, ¿quién puede contar?", "El cielo azul la muestra sin par.", "En valles y en montes alumbra tu luz,", "y con gran dulzura me cuidas, Jesús."], ["Muy frágiles son los hombres aquí,", "mas por tu bondad confiamos en ti.", "Tu misericordia aceptamos, Señor,", "Creador nuestro, Amigo fiel y Redentor."]]
      }, {
        "name": "Yo canto el poder de Dios",
        "number": "64",
        "category": "Dios el Padre",
        "subcategory": "Majestad y poder de Dios",
        "verses": [["Yo canto el poder de Dios, del Creador, Jesús;", "habló con su potente voz y apareció la luz.", "Yo canto el poder de Aquel que en alto puso el sol,", "en la pradera el clavel, en playa el caracol."], ["Yo canto la bondad de quien los árboles plantó,", "el mar mantiene en su nivel, los pájaros creó.", "La maravilla de su amor la observo por doquier,", "ya mire al cielo en su esplendor o al oscurecer."], ["Tus glorias proclamadas son por cuanto aquí se ve:", "la flor, el viento y el gorrión, la risa del bebé.", "Bien sé que en tu presencia estoy, que tú conmigo vas,", "y si al confín del mundo voy, tú, Dios, allí estás."]]
      }, {
        "name": "El mundo es de mi Dios",
        "number": "65",
        "category": "Dios el Padre",
        "subcategory": "Majestad y poder de Dios",
        "verses": [["El mundo es de mi Dios; su eterna posesión.", "Eleva a Dios su dulce voz la entera creación.", "El mundo es de mi Dios; conforta así pensar.", "Él hizo el sol y el arrebol, la tierra, cielo y mar."], ["El mundo es de mi Dios; escucho alegre son", "del ruiseñor, que al Creador eleva su canción.", "El mundo es de mi Dios; y en todo mi redor", "las flores mil con voz sutil declaran fiel su amor."], ["El mundo es de mi Dios; jamás lo olvidaré.", "Aunque infernal parezca el mal, mi Padre Dios es Rey.", "El mundo es de mi Dios; y al Salvador Jesús", "hará vencer, por su poder, con la obra de la cruz."]]
      }, {
        "name": "¿Sabes cuántos?",
        "number": "66",
        "category": "Dios el Padre",
        "subcategory": "Majestad y poder de Dios",
        "verses": [["¿Sabes cuántos claros astros dan al cielo su fulgor?", "¿Sabes cuántas nubes bellas van del mundo alrededor?", "Solo Dios los ha contado y ninguno le ha faltado.", "Entre todos ¿cuántos son? Entre todos ¿cuántos son?"], ["¿Sabes cuántas mariposas jugueteando al sol están?", "¿Sabes cuántos pececitos en el agua saltos dan?", "Dios a todos ha creado, de la vida el gozo ha dado,", "para disfrutar su don, para disfrutar su don."], ["¿Sabes cuántos tiernos niños con el sol despertarán?", "¿Sabes cuántas son las madres que su sueño velarán?", "Dios, que a todos ha otorgado su placer y buen agrado,", "te conoce y te ama a ti, te conoce y te ama a ti."]]
      }, {
        "name": "¡Señor, yo te conozco!",
        "number": "67",
        "category": "Dios el Padre",
        "subcategory": "Majestad y poder de Dios",
        "verses": [["¡Señor, yo te conozco! La noche azul, serena,", "me dice desde lejos: \"Tu Dios se esconde allí\".", "Pero la noche oscura, la de nublados llena,", "me dice más pujante: \"Tu Dios se acerca a ti\"."], ["Te acercas, sí; conozco las orlas de tu manto", "en esa ardiente nube con que ceñido estás;", "el resplandor conozco de tu semblante santo", "cuando al cruzar el éter, relampagueando vas."], ["Conozco de tus pasos las invisibles huellas", "del repentino trueno en el crujiente son;", "las chispas de tu carro conozco en las centellas,", "tu aliento en el rugido del rápido aquilón."], ["¡Señor!, yo te conozco; mi corazón te adora;", "mi espíritu de hinojos ante tus pies está;", "pero mi lengua calla, porque mi mente ignora", "los cánticos que llegan al grande Jehová."]]
      }, {
        "name": "Todo lo que ha creado Dios",
        "number": "68",
        "category": "Dios el Padre",
        "subcategory": "Majestad y poder de Dios",
        "verses": [["Todo lo que ha creado Dios", "alce su voz para cantar: Aleluya, aleluya.", "Dorado y brillante sol,", "y luna de plateada luz."], ["Ligera brisa o vendaval,", "nube que cruza el cielo azul: Alabadle, aleluya.", "Rosado y bello amanecer,", "tranquilo y calmo anochecer."], ["Rugiente y tempestuoso mar,", "arroyo alegre al correr: Alabadle, aleluya.", "Oh, fuego ardiente, abrazador,", "que da calor a nuestro hogar."], ["Todas las cosas, adorad", "con humildad al Creador, alabadle, aleluya.", "Todo lo que ha creado Dios", "alabe al soberano Rey."]],
        "refrainFirst": false,
        "refrain": ["Alabadle, alabadle;", "aleluya, aleluya, aleluya."]
      }, {
        "name": "Señor, mi Dios",
        "number": "69",
        "category": "Dios el Padre",
        "subcategory": "Majestad y poder de Dios",
        "verses": [["Señor, mi Dios, al contemplar los cielos", "y astros mil girando en derredor,", "y al oírte en retumbantes truenos,", "y al contemplar el sol en su esplendor,"], ["Al contemplar arroyos y florestas,", "los pajarillos oigo en su cantar,", "y alrededor percibo mil bellezas,", "y brisa suave viene a refrescar."], ["Y cuando pienso en ti, Señor querido,", "quien por mis culpas en penosa cruz", "dolor sufriste que hombre no ha sufrido,", "¡cuánto te quiero, amado y buen Jesús!"], ["Y cuando vengas en brillante gloria", "me llevarás con gozo a mi hogar.", "Te alabaré por darme la victoria;", "tu gran poder y gloria he de cantar."]],
        "refrainFirst": false,
        "refrain": ["Te amo y proclamo por tu gran poder:", "cuán grande eres, ¡oh Jehová!", "Te exalto a ti con toda mi alma y ser:", "¡grande eres tú! ¡grande eres tú!"]
      }, {
        "name": "¡Nuestro Dios reina!",
        "number": "70",
        "category": "Dios el Padre",
        "subcategory": "Majestad y poder de Dios",
        "verses": [["Cuán bellos son los pies del que anuncia", "las nuevas de salvación;", "que trae paz y un mensaje animador:", "¡Nuestro Dios reina hoy!", "¡Nuestro Dios reina hoy!", "¡Nuestro Dios reina hoy!"]]
      }, {
        "name": "Cada cosa hermosa",
        "number": "71",
        "category": "Dios el Padre",
        "subcategory": "Majestad y poder de Dios",
        "verses": [["A cada flor que se abre,", "al ave musical.", "vistió de tonos bellos,", "el Padre celestial."], ["Montañas majestuosas,", "el río al correr,", "la paz del sol poniente,", "el bello amanecer."], ["El viento del invierno,", "la brisa estival,", "los frutos en el huerto,", "el fresco manantial."], ["Nos concedió la vista", "y labios para hablar", "del Creador eterno", "que tanto pudo amar."]],
        "refrainFirst": true,
        "refrain": ["Cada cosa hermosa aquí", "y todo su esplendor,", "es la obra sin igual", "del grande Creador."]
      }, {
        "name": "Fue un milagro",
        "number": "72",
        "category": "Dios el Padre",
        "subcategory": "Majestad y poder de Dios",
        "verses": [["Mi Padre omnipotente es;", "negarlo no podré.", "Dios de milagros y poder,", "doquier lo puedo ver."], ["Si bien su gloria aquí mostró,", "me cuesta comprender", "las maravillas de su amor,", "su trono y su poder."], ["La Biblia me revela hoy", "que todo lo creó;", "las aves y la bella flor", "proclaman su amor."]],
        "refrainFirst": false,
        "refrain": ["Fue un milagro que los astros ordenó,", "y este mundo en su sitio instaló.", "Mas cuando me salvó y me redimió", "fue el mayor milagro de su amor."]
      }, {
        "name": "La creación",
        "number": "73",
        "category": "Dios el Padre",
        "subcategory": "Majestad y poder de Dios",
        "verses": [["¡Cuán bella es, Señor, tu obra toda", "y de cuánta majestad llenaste el universo!", "Estrellas mil, que el cielo azul tachonan,", "y el sol, al fulgurar, me cuentan tu bondad.", "El vasto mar en su esplendor", "relata en cada ola tu grandeza sin igual,", "y el tibio arroyo al susurrar", "en rápidas corrientes canta de tu amor."], ["Potente veo el rayo refulgente", "y la lluvia alrededor, que riega mansamente.", "La suave brisa que las ramas mece", "y las aves al trinar regalan bello son.", "Ya tu imagen, Padre y Dios,", "formaste tú mi cuerpo, y cada parte de mi ser.", "¡Excelso don, tu hijo soy!", "Permite que te adore hoy con devoción."], ["Al contemplar, Señor, tus maravillas,", "mi corazón te canta, lleno de alegría.", "pues todo lo que veo testifica", "de tu magno Y gran poder, en muda adoración.", "Pues, ¿cómo yo podré callar?", "Con cánticos te alabe, y para siempre dé loor.", "El orbe entero entonará:", "\"¡ Honor y gloria al Rey y eterno Creador!\""]]
      }, {
        "name": "Himno al Creador",
        "number": "74",
        "category": "Dios el Padre",
        "subcategory": "Majestad y poder de Dios",
        "verses": [["Que cada ser vivo, cual rico presente,", "entone su canto al gran Creador.", "Él hizo el universo, es Dios omnipotente,", "que a todos sustenta con gracia y amor.", "Su nube protege del sol del desierto,", "columna es de fuego que imparte gran luz.", "Y para el peregrino el fin es ya cierto:", "quien guía a su pueblo es Cristo Jesús."], ["Sus leyes gobiernan planetas y estrellas", "a los que en el espacio su rumbo marcó.", "Colinas y montes, arroyos y ríos,", "los mares y lagos proclaman a Dios.", "Alcemos las voces con gran regocijo,", "cantemos un himno a nuestro Hacedor.", "Se acerca aquel día feliz en que todos", "loores daremos al gran Creador."]]
      }, {
        "name": "Grande es Jehová",
        "number": "75",
        "category": "Dios el Padre",
        "subcategory": "Majestad y poder de Dios",
        "verses": [["Grande es Jehová, poderoso Señor;", "a su nombre rendimos honor.", "Grande es Jehová, el supremo Hacedor;", "bondadoso nos da su amor.", "Grande es Jehová, y digno de gloria;", "Dios sin igual, bendito Señor.", "Grande es Jehová, alcemos la voz", "con santa unción;", "grande es Jehová, grande es Jehová."], ["Grande es Jehová, poderoso Señor;", "a su nombre rendimos honor.", "Grande es Jehová, el supremo Hacedor;", "bondadoso nos da su amor.", "Grande eres tú, y digno de gloria;", "te exaltaré, mi Dios y mi Rey.", "Grande eres tú, te alabaré con todo mi ser;", "grande eres tú, grande eres tú."]]
      }, {
        "name": "Eterno Dios, mi Creador",
        "number": "76",
        "category": "Dios el Padre",
        "subcategory": "Majestad y poder de Dios",
        "verses": [["Eterno Dios, mi Creador,", "mi amparo en aflicción,", "tú has sido mi Consolador", "en toda ocasión."], ["Mil años a tu vista son", "cual brisas del ayer;", "cual hierba es mi condición,", "perece atardecer."], ["Mi vida bajo tu ala está,", "seguro habitaré;", "tu Espíritu me ayudará", "y en calma andaré."], ["Eterno Dios, mi Redentor,", "confío solo en ti;", "sé tú mi Guía, oh Señor,", "en mi camino aquí."]]
      }, {
        "name": "Sea exaltado",
        "number": "77",
        "category": "Dios el Padre",
        "subcategory": "Majestad y poder de Dios",
        "verses": [["Sea exaltado el Señor, exaltado", "en cielo y tierra.", "Sea exaltado, por siempre exaltado;", "su nombre alabaré.", "Dios sin igual,", "para siempre él reinará.", "Su creación", "se alegra en adorarlo.", "Sea exaltado el Señor", "con mi vida también."]]
      }, {
        "name": "¡Al mundo paz!",
        "number": "78",
        "category": "Jesucristo",
        "subcategory": "Nacimiento de Cristo",
        "verses": [["¡Al mundo paz, nació Jesús,", "nació ya nuestro Rey!", "El corazón ya tiene luz,", "y paz su santa grey,", "y paz su santa grey,", "y paz, y paz, su santa grey."], ["¡Al mundo paz; el Salvador,", "supremo reinará!", "Ya es feliz el pecador:", "Jesús perdón le da,", "Jesús perdón le da,", "Jesús, Jesús perdón le da."], ["Al mundo él gobernará", "con gracia y con poder;", "y a toda nación demostrará", "su amor y su poder,", "su amor y su poder,", "su amor, su amor y su poder."]]
      }, {
        "name": "Se oye un canto en alta esfera",
        "number": "79",
        "category": "Jesucristo",
        "subcategory": "Nacimiento de Cristo",
        "verses": [["Se oye un canto en alta esfera.", "\"En los cielos gloria a Dios;", "al mortal paz en la tierra\",", "canta la celeste voz.", "Con los cielos alabemos", "al eterno Rey, cantemos", "a Jesús, a nuestro Bien,", "con el coro de Belén.", "Canta la celeste voz:", "\"¡En los cielos gloria a Dios!\""], ["El Señor de los señores,", "el Ungido celestial,", "a salvar los pecadores", "vino al seno virginal.", "¡Gloria al Verbo encarnado,", "en humanidad velado!", "¡Gloria al Santo de Israel,", "cuyo nombre es Emanuel!", "Canta la celeste voz:", "\"¡En los cielos gloria a Dios!\""], ["Príncipe de paz eterna,", "¡gloria a ti, Señor Jesús!", "Pues al dar tu vida entera", "tú nos traes vida y luz.", "Has tu majestad dejado,", "y buscarnos te has dignado;", "para darnos el vivir", "a la muerte quieres ir.", "Canta la celeste voz:", "\"¡En los cielos gloria a Dios!\""]]
      }, {
        "name": "Venid, pastorcillos",
        "number": "80",
        "category": "Jesucristo",
        "subcategory": "Nacimiento de Cristo",
        "verses": [["Venid, pastorcillos, venid a adorar", "al Rey de los cielos nacido en Judá;", "sin ricas ofrendas podemos llegar,", "que el Niño prefiere la fe y la bondad."], ["Un rústico techo abrigo le da,", "por cuna un pesebre, por templo un portal;", "en lecho de pajas incógnito está", "quien quiso a los astros su gloria prestar."], ["Hermoso lucero le vino a anunciar,", "y magos de oriente buscándole van;", "delante se postran del Rey de Judá;", "de incienso, oro y mirra, tributo le dan."]]
      }, {
        "name": "Noche de paz",
        "number": "81",
        "category": "Jesucristo",
        "subcategory": "Nacimiento de Cristo",
        "verses": [["Noche de paz, noche de amor.", "Todo duerme en derredor.", "Entre los astros que esparcen su luz,", "bella anunciando al niñito Jesús,", "brilla la estrella de paz,", "brilla la estrella de paz."], ["Noche de paz, noche de amor.", "Oye humilde, fiel pastor:", "coros celestes proclaman salud,", "gracias y glorias en gran plenitud,", "por nuestro buen Redentor,", "por nuestro buen Redentor."], ["Noche de paz, noche de amor.", "Ved qué bello resplandor", "luce en el rostro del niño Jesús,", "en el pesebre, del mundo la Luz;", "astro de eterno fulgor,", "astro de eterno fulgor."]]
      }, {
        "name": "Ya repican las campanas",
        "number": "82",
        "category": "Jesucristo",
        "subcategory": "Nacimiento de Cristo",
        "verses": [["Ya repican las campanas alabando al Señor.", "Nuestros cantos elevemos cual las aves al Señor.", "Es la estación festiva de la reunión familiar.", "Nuestras voces jubilosas den la gloria al cantar."], ["No existe otro sitio más querido que el hogar.", "Nuestras preocupaciones allí suelen disipar.", "Jesucristo, nuestro hermano, nos reúne con amor.", "Fue al hijo de María que alabaron con fervor."], ["Es la época dichosa y de gran felicidad.", "Haya paz entre los hombres y la buena voluntad.", "Compartamos bendiciones, nuestro pan y nuestro amor.", "Brille Cristo en nuestras vidas, alabemos al Señor."]]
      }, {
        "name": "La primera Navidad",
        "number": "83",
        "category": "Jesucristo",
        "subcategory": "Nacimiento de Cristo",
        "verses": [["La primera Navidad en Belén sucedió,", "a pastores el ángel les anunció.", "Velaban el rebaño con solicitud", "en la noche de invierno de dulce quietud."], ["La estrella contemplaron con grande pavor,", "en el este brillando con gran resplandor.", "Día y noche alumbró esa luz celestial,", "anunciando las nuevas de gozo eternal."], ["Del oriente es guiada por esa gran luz,", "una hueste de sabios buscando a Jesús.", "Deseaban contemplar y adorar al Señor;", "a la estrella siguieron por su resplandor."], ["Los sabios, al llegar al humilde mesón,", "reverentes se postran en adoración.", "Oro y mirra le ofrecen al niño Jesús", "y regresan gozosos con la nueva luz."]],
        "refrainFirst": false,
        "refrain": ["Navidad, Navidad,", "Jesús nació.", "Es el Mesías, el Rey de Israel."]
      }, {
        "name": "Ve, dilo en las montañas",
        "number": "84",
        "category": "Jesucristo",
        "subcategory": "Nacimiento de Cristo",
        "verses": [["Pastores muy piadosos,", "con gran admiración,", "oyeron, jubilosos,", "del don de Salvación."], ["En esa noche oscura", "los alumbró gran luz;", "y luego, con premura,", "buscaron a Jesús."], ["Doquiera que estemos", "debemos proclamar", "que, en Cristo, hoy podemos", "la redención gozar."]],
        "refrainFirst": true,
        "refrain": ["Ve, dilo en las montañas,", "en todas partes, por doquier.", "Ve, dilo en las montañas:", "que Cristo, el Rey, nació."]
      }, {
        "name": "Allá en el pesebre",
        "number": "85",
        "category": "Jesucristo",
        "subcategory": "Nacimiento de Cristo",
        "verses": [["Allá en el pesebre, sin cuna especial,", "Jesús tiernamente reposa en paz.", "Los astros nocturnos derraman su luz", "al rostro divino del niño Jesús."], ["La vaca mugiendo despierta al bebé,", "mas este, tranquilo, sonríe en solaz.", "Humildes pastores con gran emoción", "adoran al Niño que trae salvación.", "3, Jesús, yo te amo; anhelo, Señor,", "andar en tus sendas, brindarte mi amor.", "El día se acerca en que te veré,", "y en tus mansiones feliz viviré."]]
      }, {
        "name": "A medianoche en Belén",
        "number": "86",
        "category": "Jesucristo",
        "subcategory": "Nacimiento de Cristo",
        "verses": [["A medianoche en Belén un canto sin igual", "de dulces notas se oyó sublime y celestial:", "\"En las alturas gloria a Dios y al mundo salvación;", "al hombre buena voluntad y paz al corazón\"."], ["El canto de los ángeles aún se oye sonar;", "su eco dulce, arrobador, alivia mi pesar.", "Por sobre el mundo de maldad y el ruido terrenal,", "se escucha hoy la tierna voz del canto angelical."], ["Viajero que por la vida vas cansado y con temor,", "que asciendes con dificultad la senda del dolor;", "detente y contempla ya la angélica visión;", "si prestas atención, oirás la celestial canción."]]
      }, {
        "name": "Venid, fieles todos",
        "number": "87",
        "category": "Jesucristo",
        "subcategory": "Nacimiento de Cristo",
        "verses": [["Venid, fieles todos, a Belén marchemos,", "de gozo triunfantes, henchidos de amor,", "y al Rey de los cielos contemplar podremos."], ["En pobre pesebre yace reclinado,", "al hombre ofreciendo eternal salvación,", "el santo Mesías, Verbo humanado."], ["Cantad jubilosas, célicas criaturas,", "resuenen los cielos con vuestra canción:", "¡Al Dios bondadoso, gloria en las alturas!"]],
        "refrainFirst": false,
        "refrain": ["Venid, adoremos; venid, adoremos;", "venid, adoremos a Cristo, el Señor."]
      }, {
        "name": "Oh, aldehuela de Belén",
        "number": "88",
        "category": "Jesucristo",
        "subcategory": "Nacimiento de Cristo",
        "verses": [["Oh, aldehuela de Belén, afortunada tú,", "pues en tus campos brilla hoy la sempiterna luz.", "El Hijo tan deseado con santa expectación,", "el anunciado Salvador, en ti, Belén, nació."], ["Calladamente Dios nos da su incomparable don;", "así también impartirá sus bendiciones hoy.", "Ningún oído, acaso, perciba su venir;", "mas el de humilde corazón lo habrá de recibir."], ["Oh, santo Niño de Belén, desciende con tu paz;", "en nuestras almas nace hoy, limpiando todo mal.", "Los ángeles del cielo te anuncian al nacer:", "¡Ven con nosotros a morar, oh Cristo, Emanuel!"]]
      }, {
        "name": "Cristianos, alegraos hoy",
        "number": "89",
        "category": "Jesucristo",
        "subcategory": "Nacimiento de Cristo",
        "verses": [["Cristianos, alegraos hoy", "con alma, corazón y voz.", "Ha nacido hoy Jesús,", "presten todos atención.", "Buey y asno acompañan", "al bebé en el mesón.", "Cristo ya nació,", "Cristo ya nació."], ["Cristianos, alegraos hoy", "con alma, corazón y voz.", "Escuchad la bendición,", "fue por esto que él nació.", "Las ventanas de los cielos", "al nacer él las abrió.", "Cristo ya nació,", "Cristo ya nació."], ["Cristianos, alegraos hoy", "con alma, corazón y voz.", "No más miedo a morir:", "él nos vino a salvar.", "Él nos llama a todos hoy,", "la vida eterna quiere dar.", "Cristo ya nació,", "Cristo ya nació."]]
      }, {
        "name": "Somos del oriente",
        "number": "90",
        "category": "Jesucristo",
        "subcategory": "Nacimiento de Cristo",
        "verses": [["Somos del oriente los tres,", "y venimos a adorar", "al bebé recién nacido,", "que es Rey divinal."], ["Oro traigo para ofrendar", "al nacido para reinar", "y a sus plantas mi corona", "quiero depositar."], ["Llevo incienso para el Señor,", "que también es el Mediador", "entre el Padre y los mortales,", "único intercesor."], ["Mirra ofrezco para el dolor", "del sufriente Salvador,", "que en cruenta cruz su sangre", "dio por el pecador."], ["Ved la gloria de Emanuel,", "sacerdote, Rey, Redentor.", "Aleluya, tierra y cielo,", "canten su gran amor."]],
        "refrainFirst": false,
        "refrain": ["¡Oh!, bella estrella angelical,", "tu fulgor nos guiará", "al lugar donde hallaremos", "al Enviado celestial."]
      }, {
        "name": "¿Qué niño es este?",
        "number": "91",
        "category": "Jesucristo",
        "subcategory": "Nacimiento de Cristo",
        "verses": [["¿Qué niño es este", "que yace en el regazo de María?", "¿A quien los ángeles cantan", "mientras en vela están los pastores?"], ["¿Por qué en pesebre humilde está", "donde yacen bueyes y asnos?", "Es por amor que el Verbo", "se hizo hombre y vivió entre nosotros."], ["Traedle oro, incienso y mirra,", "y dadle ricos presentes.", "El Rey de reyes la vida da;", "entronadle en los corazones."]],
        "refrainFirst": false,
        "refrain": ["El es Jesús, el Rey,", "a quien cantaron los ángeles.", "Dad, dad alabanza y honra al niño de María."]
      }, {
        "name": "Angeles cantando están",
        "number": "92",
        "category": "Jesucristo",
        "subcategory": "Nacimiento de Cristo",
        "verses": [["¡ngeles cantando están", "bella y celestial canción.", "Sus palabras gozo dan", "a la entera creación."], ["Hoy anuncian con fervor", "paz y buena voluntad;", "pues nació el Salvador", "de la pobre humanidad."]],
        "refrainFirst": false,
        "refrain": ["Gloria, in excelsis Deo, (*)", "Gloria, in excelsis Deo."],
        "note": "(*) Gloria a Dios en lo alto."
      }, {
        "name": "Hubo Uno que quiso",
        "number": "93",
        "category": "Jesucristo",
        "subcategory": "Muerte de Cristo",
        "verses": [["Hubo Uno que quiso por mí padecer", "y morir, por mi alma salvar;", "el camino tan cruento a la cruz recorrer,", "para así mis pecados lavar."], ["El es todo ternura y amor para mí,", "mi alma impura su sangre lavó;", "ya no hay condenación, libre soy, yo lo sé;", "mi pecado en la cruz él clavó."], ["Me atendré al Maestro, jamás dejaré", "el sendero que él mismo trazó,", "y mis labios y mi alma alzarán su canción,", "pues él todas mis culpas quitó."]],
        "refrainFirst": false,
        "refrain": ["¡En la cruz, en la cruz", "mis pecados clavó!", "¡Cuánto quiso por mí padecer!", "Con angustia a la cruz", "fue el benigno Jesús,", "y en su cuerpo mis culpas llevó."]
      }, {
        "name": "Sangró mi soberano Dios",
        "number": "94",
        "category": "Jesucristo",
        "subcategory": "Muerte de Cristo",
        "verses": [["Sangró mi soberano Dios,", "murió mi Salvador.", "Su vida quiso entregar", "por mí, tan pecador."], ["Y yo, contrito, al ver su cruz,", "el rostro encubriré;", "con lágrimas de gratitud", "mi alma inundaré."], ["Mas no con llanto pagaré", "la deuda de su amor.", "Mi ser entero yo te doy,", "¡no puedo más, Señor!"], ["¡Oh, Salvador, ayúdame;", "que sea fiel a ti;", "y cuando en gloria reines tú,", "acuérdate de mí!"]]
      }, {
        "name": "Un día",
        "number": "95",
        "category": "Jesucristo",
        "subcategory": "Muerte de Cristo",
        "verses": [["Un día que el cielo sus glorias cantaba,", "un día que el mal imperaba más cruel,", "Jesús descendió y al nacer de una virgen", "nos dio con su vida un ejemplo tan fiel."], ["Un día lleváronle al monte Calvario,", "un día enclaváronle sobre una cruz;", "sufriendo dolores y pena de muerte,", "expiando el pecado, salvome Jesús."], ["Un día la tumba ocultarle no pudo,", "un día el ángel la piedra quitó;", "habiendo Jesús ya a la muerte vencido,", "a estar con su Padre en su trono, ascendió."], ["Un día vendrá y con voz de arcángel", "un día en su gloria el Señor brillará.", "¡Oh día admirable en que unido su pueblo", "loores a Cristo por siempre dará!"]],
        "refrainFirst": false,
        "refrain": ["Vivo, me amaba; muerto, salvome;", "y en el sepulcro mi mal enterró;", "resucitado, él es mi justicia;", "un día él viene, pues lo prometió."]
      }, {
        "name": "Al contemplar la excelsa cruz",
        "number": "96",
        "category": "Jesucristo",
        "subcategory": "Muerte de Cristo",
        "verses": [["Al contemplar la excelsa cruz", "do el Rey de gloria sucumbió,", "lo que antes parecía luz", "sin vacilar hoy dejo yo."], ["No me permitas, Dios, gloriar,", "más que en la muerte del Señor;", "lo que más pueda ambicionar", "lo doy gozoso por su amor."], ["Si la riqueza terrenal", "pudiera yo a mis plantas ver,", "pequeña ofrenda mundanal", "sería el írsela a ceder."], ["Aquel dolor tan grande y cruel", "que así sufrió mi Salvador", "demanda que consagre a él", "mi ser, mi vida y mi amor."]]
      }, {
        "name": "En el monte Calvario",
        "number": "97",
        "category": "Jesucristo",
        "subcategory": "Muerte de Cristo",
        "verses": [["En el monte Calvario estaba una cruz,", "emblema de afrenta y dolor, y yo amo esa cruz", "do murió mi Jesús por salvar al más vil pecador."], ["Y aunque el mundo desprecie la cruz de Jesús,", "para mí tiene suma atracción, pues en ella llevó", "el Cordero de Dios de mi alma la condenación."], ["En la cruz de Jesús do su sangre vertió,", "hermosura contemplo sin par; pues en ella triunfante", "a la muerte venció, y mi ser puede santificar."], ["Yo seré siempre fiel a la cruz de Jesús,", "su oprobio con él llevaré, y algún día feliz", "con los santos en luz para siempre su gloria veré."]],
        "refrainFirst": false,
        "refrain": ["¡Oh! yo siempre amaré esa cruz,", "en sus triunfos mi gloria será;", "y algún día en vez de una cruz,", "mi corona Jesús me dará."]
      }, {
        "name": "Rostro divino",
        "number": "98",
        "category": "Jesucristo",
        "subcategory": "Muerte de Cristo",
        "verses": [["Rostro divino, ensangrentado;", "cuerpo llagado por nuestro bien,", "calma, benigno, justos enojos,", "lloren los ojos que así te ven."], ["Manos preciosas, tan laceradas,", "por mí clavadas en una cruz.", "En este valle sean mi guía,", "mi alegría, fiel norte y luz."], ["Tus pies heridos, Cristo paciente,", "yo indiferente los taladré.", "Mas penitente, hoy que te adoro,", "tu gracia imploro: Señor, pequé."], ["Crucificado en un madero,", "manso Cordero, mueres por mí.", "Por eso el alma triste y llorosa", "suspira ansiosa, Señor, por ti."]]
      }, {
        "name": "Jamás podrá alguien separarnos",
        "number": "99",
        "category": "Jesucristo",
        "subcategory": "Muerte de Cristo",
        "verses": [["Jamás podrá alguien separarnos", "de Cristo nuestro Redentor", "ni cosa alguna arrebatarnos", "el gozo de su tierno amor:", "ni luchas, pruebas o dolores,", "ni amenazas o aflicción;", "ni aun este mundo y sus honores,", "su pompa, gloria y tentación."], ["Con furia loca lo azotaron,", "y así humillaron al Señor,", "y sin piedad atravesaron", "las manos de mi Salvador.", "A esos pies que lo llevaron", "a dar salud y a bendecir,", "horribles clavos traspasaron,", "la suerte humana al compartir."], ["Qué horror que por mi vil pecado", "el Salvador así sufrió,", "que por mis culpas fue azotado", "y burlas crueles recibió.", "¿Ingratos hemos de alejarnos", "de Aquel que tanto nos amó,", "y con anhelo de salvarnos", "su propia vida entregó?"]]
      }, {
        "name": "¡Dulces momentos!",
        "number": "100",
        "category": "Jesucristo",
        "subcategory": "Muerte de Cristo",
        "verses": [["¡Dulces momentos consoladores", "los que me paso junto a la cruz!", "Allí sufriendo crueles dolores", "veo al Cordero, Cristo Jesús."], ["De sus heridas, la viva fuente", "de pura sangre veo manar,", "que, salpicando mi impura frente,", "la infame culpa logra borrar."], ["Veo su angustia ya terminada,", "hecha la ofrenda de la expiación;", "su noble frente, mustia, inclinada,", "y consumada mi redención."], ["¡Dulces momentos, ricos en dones,", "de paz y gracia, de vida y luz!", "Solo hay consuelo y bendiciones,", "cerca de Cristo, junto a la cruz."]]
      }, {
        "name": "Cabeza sacrosanta",
        "number": "101",
        "category": "Jesucristo",
        "subcategory": "Muerte de Cristo",
        "verses": [["Cabeza sacrosanta, herida en mi favor;", "espinas la rodean que aumentan su dolor.", "Glorioso amor refleja y dulce salvación,", "aun cuando la desprecien, le doy mi devoción."], ["Tu grande sufrimiento da vida al transgresor.", "Mi culpa fue terrible, mas te agobió a ti.", "Ante tus pies me rindo, amado Salvador.", "Pidiendo, arrepentido, me des tu gracia hoy."], ["¡Oh!, ¿cómo agradecerte, querido y buen Jesús,", "por toda tu angustia, por tu dolor si fin?", "Hazme un fiel hijo tuyo, sostenme, mi Señor.", "Que nunca te abandone, precioso Redentor."]]
      }, {
        "name": "Cordero de Dios",
        "number": "102",
        "category": "Jesucristo",
        "subcategory": "Muerte de Cristo",
        "verses": [["Hijo de Dios, eterno Rey;", "desde su trono descendió", "para pisar un suelo vil", "y cual Cordero perecer."], ["En una cruz, clavado fue", "por una turba infame y cruel.", "Sacrificaron sin piedad", "a mi Jesús, Cordero fiel."], ["Yo, mi Jesús, soy pecador;", "pero tu gracia me salvó.", "De hoy en más te seguiré;", "soy tu cordero, mi Señor."]],
        "refrainFirst": false,
        "refrain": ["¡Oh, Salvador! ¡Mi Salvador!", "Cordero santo de mi Dios.", "Quien me lavó de mi maldad", "es el Cordero, mi Señor."]
      }, {
        "name": "Jesús resucitado",
        "number": "103",
        "category": "Jesucristo",
        "subcategory": "Resurrección de Cristo",
        "verses": [["Jesús resucitado está en el mundo hoy.", "Los hombres no lo creen. mas yo seguro estoy.", "Su tierna mano siento y puedo oír su voz,", "y encuentro dondequiera al Salvador."], ["Por dondequiera miro, lo puedo contemplar.", "Y si la angustia llena mi alma de pesar,", "yo sé que Cristo vive y al fin me llevará", "al a mansión del cielo, donde él está."], ["¡Alégrate, cristiano! Tu voz levanta ya", "y canta aleluya al Padre celestial.", "Nos trajo esperanza de eterna salvación,", "pues en su Hijo amado hay redención."]],
        "refrainFirst": false,
        "refrain": ["Jesús, Jesús mi Cristo vive hoy.", "Hablándome, mirándome, conmigo va el Señor.", "Jesús, Jesús, imparte salvación.", "Contento voy, pues suyo soy:", "ganó mi corazón."]
      }, {
        "name": "La tumba le encerró",
        "number": "104",
        "category": "Jesucristo",
        "subcategory": "Resurrección de Cristo",
        "verses": [["La tumba le encerró. ¡Cristo bendito!", "El alba allí esperó Cristo el Señor."], ["De guardas escapó. ¡Cristo bendito!", "El sello destruyó Cristo el Señor."], ["La muerte dominó. ¡Cristo bendito!", "y su poder venció Cristo el Señor."]],
        "refrainFirst": false,
        "refrain": ["Cristo la tumba venció;", "y con gran poder resucitó;", "ha vencido a la muerte y el dolor;", "vive para siempre nuestro Salvador.", "¡Gloria a Dios! ¡Gloria a Dios!", "El Señor resucitó."]
      }, {
        "name": "Cristo ha resucitado",
        "number": "105",
        "category": "Jesucristo",
        "subcategory": "Resurrección de Cristo",
        "verses": [["Cristo ha resucitado, ¡aleluya!,", "ya la muerte ha vencido, ¡aleluya!", "Con poder y con virtud, ¡aleluya!,", "cautivó la esclavitud. ¡Aleluya!"], ["Hasta el polvo se humilló, ¡aleluya!,", "vencedor se levantó, ¡aleluya!", "Hoy cantamos en verdad, ¡aleluya!,", "su gloriosa majestad. ¡Aleluya!"], ["A la muerte se entregó, ¡aleluya!,", "el que así nos redimió, ¡aleluya!", "Hoy en gloria celestial, ¡aleluya!,", "reina en vida triunfal. ¡Aleluya!"], ["Cristo, nuestro Salvador, ¡aleluya!,", "de la muerte vencedor, ¡aleluya!,", "todos te hemos de cantar, ¡aleluya!,", "alabanzas sin cesar. ¡Aleluya!"]]
      }, {
        "name": "Tuya es la gloria",
        "number": "106",
        "category": "Jesucristo",
        "subcategory": "Resurrección de Cristo",
        "verses": [["Tuya es la gloria, Cristo vencedor.", "De la muerte invicto eres, oh Señor.", "¡ngeles brillantes vieron tu esplendor,", "cuando derrotaste muerte y dolor."], ["Resucitado, Cristo el Salvador,", "con amor invita, quita el temor.", "Cante hoy la iglesia cántico triunfal;", "Cristo ha vencido al bastión mortal."], ["Ya no dudamos, Príncipe inmortal:", "solo tú das vida, líbranos del mal.", "Danos la victoria por tu amor sin par;", "llévanos a salvo al eterno hogar."]],
        "refrainFirst": false,
        "refrain": ["Tuya es la gloria,", "Cristo vencedor.", "De la muerte invicto", "eres, oh Señor."]
      }, {
        "name": "Canto el gran amor",
        "number": "107",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["Canto el gran amor de Aquel", "que primero a mí me amó,", "pues dejó la gloria allá", "y en el Gólgota murió."], ["Antes que llorara yo,", "ya por mí lloraba él;", "y antes que supiera orar,", "él por mí gustó la hiel."], ["Este mundo nunca vio", "tan profundo y santo amor,", "que del mal me rescató", "aliviando mi dolor."], ["Nada bueno se halla en mí.", "¿Cómo puedes tanto amar?", "Yo, Señor, me rindo a ti;", "hazme, pues, mi amor mostrar."]],
        "refrainFirst": false,
        "refrain": ["Tal amor ensalzaré,", "alabando al que murió", "inmolado para que", "vida eterna tenga yo."]
      }, {
        "name": "Amigo fiel es Cristo",
        "number": "108",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["Amigo fiel es Cristo, alivio él me da;", "me ama con un tierno amor que siempre durará.", "Sin él vivir no puedo ya; cercano siempre está.", "Así moramos juntos Jesús y yo."], ["Mis faltas no ignora. Mis males puede ver.", "Anhela que me apoye en él: me puede sostener.", "El guía mi alma a la luz, me indica mi deber.", "Así andamos juntos Jesús y yo."], ["Confíole mis penas, le digo mi gozar.", "Con él no siento ya temor, y alegre puedo andar.", "Me dice lo que debo hacer, me impulsa a progresar.", "Así hablamos juntos Jesús y yo."], ["Él sabe cuánto anhelo salvar a un pecador;", "me ruega ir a pregonar cuán grande es su amor,", "que anuncie eterna redención con fe y con fervor.", "Así obramos juntos Jesús y yo."]]
      }, {
        "name": "Un buen amigo tengo yo",
        "number": "109",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["Un buen amigo tengo yo, su amor salvó mi vida;", "y por su muerte gozo hoy tal gracia inmerecida.", "Y con el lazo de este amor, que nunca se ha cortado,", "seguro puedo siempre andar, a él estoy ligado."], ["Un buen amigo tengo yo, el ser me dio al crearme,", "y a sí mismo se entregó a fin de rescatarme.", "Pues, cuanto tengo es todo de él, él mismo me lo ha dado;", "mi vida, fuerza y corazón, a él le he entregado."], ["Un buen amigo tengo yo, el Todopoderoso,", "que quiere al fin llevarme a mí a su hogar glorioso.", "Para animar mi vida aquí, me deja ver su gloria,", "y con la ayuda que me da yo gano la victoria."], ["Un buen amigo tengo yo, tan fiel y tan amante;", "mi sabio consejero es, mi protector constante.", "De quien al mundo tanto amó, jamás podrá apartarme", "ni el mundo, ni la muerte, pues soy suyo para siempre."]]
      }, {
        "name": "Cristo es el mejor amigo",
        "number": "110",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["Cristo es el mejor amigo", "cuando triste o tentado estés;", "colmará de bendición", "tu afligido corazón."], ["En Jesús fiel amigo encuentro;", "paz perfecta y plena a mi alma da.", "Apoyado en él estoy,", "mi confianza a él le doy."], ["Aunque ande en algún peligro,", "o en el valle de la muerte esté,", "ningún mal me alcanzará,", "pues Jesús me amparará."], ["Cuando estemos al fin reunidos", "con los redimidos más allá,", "cantaremos con fervor", "en presencia del Señor."]],
        "refrainFirst": false,
        "refrain": ["Cristo es el mejor amigo.", "Cristo es el mejor amigo,", "Cristo es el mejor amigo.", "Él tus súplicas oirá", "y tu carga llevará.", "¡Oh, es Cristo el mejor amigo!"]
      }, {
        "name": "Como Jesús no hay otro amigo",
        "number": "111",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["Como Jesús no hay otro amigo,", "ni uno hay, ni uno hay;", "que lleve nuestro dolor consigo,", "ni uno hay, ni uno hay."], ["No hay amigo tan noble y digno,", "ni uno hay, ni uno hay;", "que es a la vez justo y benigno,", "ni uno hay, ni uno hay."], ["¿Hay santo que haya desamparado?", "Ni uno hay, ni uno hay.", "¿O pecador que haya rechazado?", "Ni uno hay, ni uno hay."]],
        "refrainFirst": false,
        "refrain": ["Nuestras luchas conoce todas;", "nos guiará hasta el mismo fin.", "Como Jesús no hay otro amigo;", "ni uno hay, ni uno hay."]
      }, {
        "name": "Ningún otro me amó cual Cristo",
        "number": "112",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["Cristo hallome en la miseria y el pecado,", "lleno estaba el corazón de iniquidad;", "mas Jesús abrió sus brazos y amoroso", "guió mis pasos por la senda de verdad."], ["Cada día mi Jesús me da más fuerza,", "hoy entiendo su lenguaje arrobador.", "¿Por qué vino él del cielo a salvarme?", "Lo sabré tan solo allá, en su mansión."], ["Yo quisiera hablarte del amor de Cristo,", "pues en él hallé un amigo fuerte y fiel,", "por su gracia transformó mi vida entera;", "todo lo que soy aquí lo debo a él."]],
        "refrainFirst": false,
        "refrain": ["Ningún otro me amó cual Cristo,", "no hay amigo tan leal y fiel;", "nadie más limpiar pudiera mis pecados,", "solo Cristo, mi buen Rey."]
      }, {
        "name": "Amor que no me dejarás",
        "number": "113",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["Amor que no me dejarás,", "descansa mi alma siempre en ti;", "es tuya y tú la guardarás,", "y en tu regazo acogedor", "la paz encontrará."], ["¡Oh Luz que en mi sendero vas!,", "mi antorcha débil rindo a ti;", "su luz apaga el corazón,", "seguro de encontrar en ti", "más bello resplandor."], ["¡Oh tú el Gozo!, que por mí", "sufriste aquí mortal dolor;", "tras la tormenta el arco vi,", "y la mañana, yo lo sé,", "sin lágrimas será."], ["¡Oh Cruz que miro sin cesar!,", "mi orgullo, gloria y vanidad,", "al polvo dejo por hallar", "la vida que en su sangre dio", "Jesús, mi Salvador."]]
      }, {
        "name": "Dime la antigua historia",
        "number": "114",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["Dime la antigua historia del celestial favor;", "de Cristo y de su gloria, de Cristo y de su amor.", "Dímela con llaneza, con toda candidez,", "porque es mi mente flaca y anhela sencillez."], ["Dime tan dulce historia con tono claro y fiel:", "\"Murió Jesús, y salvo tú puedes ser por él\".", "Dime la historia cuando me oprima la aflicción", "y quieras tú a mi alma brindar consolación."], ["Dime la misma historia cuando a tu parecer", "me cieguen de este mundo el brillo y el placer.", "Y cuando ya vislumbre del día final la luz", "repíteme la historia: \"Tu Salvador es Jesús\"."]],
        "refrainFirst": false,
        "refrain": ["Dime la antigua historia,", "cántame la victoria,", "háblame de la gloria", "de Cristo y de su amor."]
      }, {
        "name": "¡Oh, cuán grande amor!",
        "number": "115",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["¡Oh, cuán grande amor!", "¡Oh, cuán grande amor!", "¡Oh, qué maravilla!", "Es mío, Señor,", "¡qué maravilla de amor!"], ["¡Oh, cuán grande amor!", "¡Tan grande es, Señor,", "que a mi alma cautiva", "para el Salvador!", "¡Qué maravilla de amor!"]]
      }, {
        "name": "Cristo está a mi lado",
        "number": "116",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["Muchas veces me pregunto:", "¿Por qué él me ama a mí?", "Tantas veces yo fallé,", "sin embargo me ama.", "Cuando estoy desanimado", "y cuando todo va mal,", "Cristo viene a mi lado", "y me da su paz."], ["Cada día miro alrededor", "y puedo contemplar", "pruebas de un amor sin par", "que nunca fallará.", "Su palabra me revela", "milagros de gran poder;", "y de todos el más grande", "es su amor por mí."]],
        "refrainFirst": false,
        "refrain": ["Cristo está a mi lado;", "nunca me dejará.", "Nadie puede amarme", "como mi Jesús."]
      }, {
        "name": "No sé por qué",
        "number": "117",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["No sé por qué el que recibe adoración", "dio tanto amor por la humanidad,", "por qué Jesús, como Pastor amante,", "salió a buscar al que se descarrió.", "Pero esto sé: que es hijo de María,", "y que en pesebre humilde él durmió;", "y que vivió y trabajó en Nazaret;", "así llegó al mundo mi buen Salvador."], ["No sé por qué tan silencioso fue al sufrir", "y con su paz la gracia nos mostró.", "Su corazón fue traspasado en la cruz,", "y el dolor su vida coronó.", "Pero esto sé: que Cristo sana el corazón,", "limpia el pecado y calma la ansiedad,", "al quebrantado alivia sus pesares,", "porque aún está en el mundo el Salvador."], ["No entiendo cómo es que el Salvador Jesús", "a las naciones recuperará,", "ni cómo suple las necesidades", "del pecador, del sabio y del fiel.", "Pero esto sé: su gloria todos mirarán,", "recogerá el grano que sembró,", "día feliz será estar en su esplendor", "cuando conozcan en el mundo al Salvador."]]
      }, {
        "name": "Cuando estés cansado y abatido",
        "number": "118",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["Cuando estés cansado y abatido,", "dilo a Cristo, dilo a Cristo;", "angustiado por el gozo huido,", "dilo a Cristo, el Señor,"], ["Si en ti sientes grande amargura,", "dilo a Cristo, dilo a Cristo;", "si en tu vida hay faltas ocultadas,", "dilo a Cristo, el Señor."], ["Si el nublado de tristeza temes,", "dilo a Cristo, dilo a Cristo;", "si saber de tu mañana quieres,", "dilo a Cristo, el Señor."], ["¿Te perturba el contemplar la muerte?", "Dilo a Cristo, dilo a Cristo.", "¿En el reino anhelas pronto verte?", "Dilo a Cristo, el Señor."]],
        "refrainFirst": false,
        "refrain": ["Dilo a Cristo, dilo a Cristo,", "él es tu amigo mejor;", "otro no hay como él amante hermano;", "dilo a Cristo, el Señor,"]
      }, {
        "name": "De su trono, mi Jesús",
        "number": "119",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["De su trono, mi Jesús,", "a morir aquí bajó,", "y clavado en la cruz,", "mis pecados él pagó."], ["Bien me quiere el Salvador", "que sufrió por mi maldad.", "¡Te bendigo, mi Señor,", "reconozco tu bondad!"], ["Guarda fiel mi corazón", "tú, que velas sobre mí;", "y con toda devoción", "haz que viva yo por ti."]],
        "refrainFirst": false,
        "refrain": ["Sí, Cristo me ama;", "sí, Cristo me ama;", "sí, Cristo me ama,", "la Biblia dice así."]
      }, {
        "name": "¡Cuánto me alegra!",
        "number": "120",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["¡Cuánto me alegra que nuestro Señor", "diera su vida por el pecador!", "Hizo sin par maravillas aquí,", "y la más grande es que me ama a mí."], ["Aunque vagaba olvidándome de él,", "él me siguió porque siempre es fiel;", "presto a sus brazos amantes volví", "al recordar que Jesús me ama a mí."], ["Cuando en el cielo ver pueda a Jesús,", "ya revestido de gloriosa luz,", "entonaré mi himno eterno allí:", "\"¡Qué maravilla! ¡Jesús me ama a mí!\""]],
        "refrainFirst": false,
        "refrain": ["¡Qué maravilla! Me ama Jesús,", "me ama Jesús, me ama Jesús.", "¡Qué maravilla! Me ama Jesús;", "sí, me ama aún a mí."]
      }, {
        "name": "Es Jesucristo la vida, la luz",
        "number": "121",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["Es Jesucristo la vida, la luz;", "trae del cielo la eterna verdad;", "mártir divino que muere en la cruz", "por darnos libertad."], ["Quita del alma la negra maldad,", "limpia benigno el infiel corazón;", "es su carácter de suma bondad,", "la misma compasión."], ["Fuente preciosa de gracia y salud,", "agua que limpia de toda maldad.", "Quiere llenarnos de su plenitud", "y de su santidad."]],
        "refrainFirst": false,
        "refrain": ["Cristo es Pastor, Enviado,", "divino Emanuel.", "Él me conduce por sendas de paz", "como a su oveja fiel."]
      }, {
        "name": "Divino pastor",
        "number": "122",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["Divino Pastor, yo sé de tu amor;", "vagaste en la noche por mí.", "El mundo es hostil. prefiero el redil.", "Pastor, yo te seguiré.", "Tu luz resplandece en mi senda aquí;", "tu gloria hasta cuando regreses por mí.", "En pastos de paz, por valles de amor,", "Pastor, yo te seguiré."], ["Divino Pastor, yo sé de tu amor;", "subiste al Calvario por mí.", "La gracia y la Ley contemplo con fe;", "se unieron en cruenta cruz.", "La vida tú diste en rescate por mí,", "angustia sufriste por darme perdón.", "Atrás dejaré el mundo tan vil;", "Pastor, yo te seguiré."], ["Divino Pastor, yo sé de tu amor.", "\"Oh, sígueme\", dice tu voz.", "Yo quiero vivir tu santa verdad,", "alumbra mi senda aquí.", "Cuán grande es tu amor por tu tierno redil,", "a aguas tranquilas nos conducirás.", "Un solo redil y un solo Señor,", "Pastor, yo te seguiré."]]
      }, {
        "name": "¡Cuánto nos ama Jesús!",
        "number": "123",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["¡Cuánto Jesús te ama a ti!", "¡Cuánto me ama él a mí!", "Su vida dio, no podía dar más.", "¡Oh, cuánto te ama! ¡Oh, cuánto me ama!", "¡Cuánto nos ama Jesús!"], ["En el Calvario murió,", "su sangre allí derramó,", "el buen Jesús, nos compró en la cruz.", "¡Oh, cuánto te ama! ¡Oh, cuánto me ama!", "¡Cuánto nos ama Jesús!"]]
      }, {
        "name": "Ama el Pastor sus ovejas",
        "number": "124",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["Ama el Pastor sus ovejas con un amor paternal;", "ama el Pastor su rebaño con un amor sin igual.", "Ama el Pastor a las otras que descarriadas están,", "y conmovido las busca por dondequiera que van."], ["Ama el Pastor sus corderos; ama muy tierno el Pastor", "a los que errantes, heridos, se oye gemir de dolor.", "Ved al Pastor conmovido por los collados buscar,", "y los corderos en hombros vedlo llevando al hogar."], ["Son delicados tus pastos, tranquilas tus aguas son;", "henos aquí, ¡oh Maestro!, Danos hoy tu bendición.", "Haz que seamos fervientes, llénanos de santo amor", "por las ovejas perdidas de tu redil, buen Pastor."]],
        "refrainFirst": false,
        "refrain": ["Por el desierto errabundas", "las ve sufrir penas mil,", "y en sus brazos las lleva,", "tierno, de vuelta al redil."]
      }, {
        "name": "Infinito amor de Cristo",
        "number": "125",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["Infinito amor de Cristo,", "me conmueve más y más.", "Su profundidad y altura", "no comprenderé jamás."], ["Infinita es su clemencia,", "se complace en perdonar.", "Su misericordia quiere", "mis pecados expiar."], ["Infinita es su ternura,", "¿cómo puede tanto amar?", "Su bondad siempre procura", "al perdido rescatar."]]
      }, {
        "name": "Abrigadas y salvas en el redil",
        "number": "126",
        "category": "Jesucristo",
        "subcategory": "Amor de Cristo",
        "verses": [["Abrigadas y salvas en el redil", "las noventa y nueve están;", "pero allá en el bosque perdida va", "una pobre rendida de afán,", "por el monte escabroso y aterrador,", "muy lejos vagando del Pastor,", "muy lejos vagando del Pastor."], ["\"¿Del rebaño no bastan, tierno Pastor,", "las noventa y nueve aquí?\"", "Mas responde el Pastor \"Una oveja hoy", "descarriada va lejos de mí.", "Y en la sierra escarpada ya voy a entrar,", "mi pobre oveja a rescatar,", "mi pobre oveja a rescatar\"."], ["No sabrá el mortal del río veloz", "que el Pastor tuvo que cruzar;", "ni cuán negra la noche fue en que él salió", "su oveja perdida a buscar.", "Sus gemidos y quejas podía oír;", "enferma estaba y por morir,", "enferma estaba y por morir."], ["Una grande algazara luego se oyó,", "por doquier retumbando fue,", "a la célica puerta la voz llegó:", "\"Alegraos, que mi oveja encontré\".", "Y cantaban los ángeles en redor:", "íLo suyo rescata el buen Pastor,", "lo suyo rescata el buen Pastorí®"]]
      }, {
        "name": "Cristo, nombre dulce",
        "number": "127",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["Cristo, nombre dulce para mí;", "Cristo, escuchas siempre mi oración.", "Oh, Cristo, tú me levantas si caí;", "es muy dulce tu nombre, mi Jesús."], ["Cristo, alabarte es mi placer,", "Cristo, primero y último eres tú.", "oh, Cristo, por mí moriste en la cruz;", "es muy dulce tu nombre, mi Jesús."], ["Cristo, eres Rey que volverá;", "Cristo, necesitamos tu amor.", "Oh, Cristo, a ti cantamos con fervor;", "es muy dulce tu nombre, mi Jesús."]]
      }, {
        "name": "¡Tu nombre es dulce, buen Jesús!",
        "number": "128",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["¡Tu nombre es dulce, buen Jesús!", "¡Oh cuánta paz, consuelo y luz", "dimana de tu santa cruz!,", "mi luz, mi esperanza."], ["Jesús; en cuyo corazón", "descargo entera mi aflicción,", "pues calma toda turbación,", "Jesús, tu amado nombre."], ["Tu nombre pláceme escuchar;", "lo siento a mi alma alentar.", "Cual canto calma mi llorar,", "Jesús, tu santo nombre."], ["Jamás contó profano autor", "cuán dulce el nombre es del Señor;", "ascienda siempre mi loor", "a su glorioso nombre."]],
        "refrainFirst": false,
        "refrain": ["¡Dulce nombre: Emanuel!", "¡Dulce nombre, siempre fiel!", "¡Dulce nombre: gloria a él", "los santos todos canten!"]
      }, {
        "name": "Cual Jesús no hay otro nombre",
        "number": "129",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["No hay nombre en esta tierra", "cual el nombre de Jesús;", "fuente es para el cristiano", "de perdón, justicia y luz."], ["Mi esperanza es ver su gloria", "cuando vuelva vencedor,", "y ensalzar tan digno nombre", "es mi tema inspirador."], ["Si en su dilatada viña", "quiere que trabaje yo,", "bastará ir en su nombre", "para hacer lo que mandó."], ["Si la mano de la muerte", "con su frío siento en mí,", "al oír su santo nombre", "nada temeré allí."]],
        "refrainFirst": false,
        "refrain": ["Jesús, Jesús,", "cual Jesús no hay otro nombre;", "Jesús, Jesús,", "cual Jesús no hay otro nombre."]
      }, {
        "name": "Cristo, Cristo, Cristo",
        "number": "130",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["Cristo, Cristo, Cristo;", "otro nombre no hay igual.", "Santo, tierno, puro;", "tú llevaste la cruz por mí.", "Cristo, Cristo, Cristo,", "cielo y tierra te alabarán.", "Rey de reyes, sublime Señor,", "no hay otro igual a ti."]]
      }, {
        "name": "Bendito es el nombre de Jesús",
        "number": "131",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["Bendito es el nombre de Jesús,", "a cuantos le confiesan con amor.", "Jesús es Rey por la eternidad,", "¡Aleluya! ¡Aleluya!"], ["Tú eres Señor, Castillo y Roca fiel;", "el Capitán que lucha contra el mal;", "bendita Luz en la oscuridad,", "¡Aleluya! ¡Aleluya!"], ["Que con valor peleen por Jesús", "los santos que aman a su Salvador.", "Dios les dará, por gracia, galardón,", "¡Aleluya! ¡Aleluya!"], ["De mar a mar y en la Santa Ciudad,", "el canto pronto habrá de resonar", "al Creador, bendito Redentor:", "¡Aleluya! ¡Aleluya!"]]
      }, {
        "name": "Dulce, hermoso nombre es Jesús",
        "number": "132",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["Hay nombres que me encanta escuchar,", "mas no hay otro como el de Jesús;", "nombre sin igual, nombre celestial;", "Jesús, el más precioso nombre."], ["No hay en tierra o cielo nombre igual", "al que ofrezcamos todo nuestro honor,", "como el nombre fiel de mi Salvador;", "Jesús, el más precioso nombre."], ["Un día cara a cara lo veré,", "tributos por su gracia le daré.", "Él me redimió, me dio libertad;", "Jesús, el más precioso nombre."]],
        "refrainFirst": false,
        "refrain": ["Dulce, hermoso nombre es Jesús,", "así como es su infinito amor;", "por eso para siempre amaré", "el dulce nombre de mi buen Jesús."]
      }, {
        "name": "Venid, con cánticos venid",
        "number": "133",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["Venid, con cánticos venid,", "del trono en redor;", "con ángeles loor rendid", "a Cristo, Salvador.", "Con ángeles loor rendid", "a Cristo, Salvador."], ["De alabanzas digno es él,", "quien en la cruz bebió", "la copa de amarga hiel,", "que vida al hombre dio,", "la copa de amarga hiel,", "que vida al hombre dio."], ["Cantad, mortales, por doquier,", "cantadle con fervor.", "Él siempre es digno de poder,", "dominio y honor.", "Él siempre es digno de poder,", "dominio y honor."], ["Con gozo, pues, alzad la voz;", "a él alegres id,", "y con los ángeles de Dios,", "a Cristo bendecid.", "Con gozo, pues, alzad la voz;", "a él alegres id."]]
      }, {
        "name": "Cual mirra fragante",
        "number": "134",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["Cual mirra fragante que exhala su olor", "y ricos perfumes esparce en redor,", "tu nombre, ¡oh Amado!, a mi corazón", "lo llena de gozo, transpórtalo a Sion."], ["Cual voz amigable que al triste viador", "en bosque perdido le inspira valor,", "tu nombre me anima y me hace saber", "que ofreces, piadoso, rescate a mi ser."], ["Cual luz que, brillando del alto fanal,", "al nauta en la noche señala el canal,", "tu nombre, esparciendo benéfica luz,", "al cielo me lleva, bendito Jesús."]],
        "refrainFirst": false,
        "refrain": ["Aleluya, aleluya", "al Cordero de Dios;", "aleluya al Amado,", "al bendito Jesús."]
      }, {
        "name": "Cristo, nombre sublime",
        "number": "135",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["Cristo, nombre sublime,", "Salvador nuestro, glorioso Dios;", "es Emanuel, Dios con nosotros,", "Palabra viva, gran Redentor."]]
      }, {
        "name": "¡Oh, cuánto amo a Cristo!",
        "number": "136",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["Su nombre hermoso yo anhelo oír", "y siempre quiero alabar.", "Es dulce son en mi corazón,", "me llena de gozo y paz."], ["Me habla de su inmenso amor", "y de su muerte en la cruz.", "Su sangre Cristo vertió por mí,", "por darme la salvación."], ["Su nombre me habla de gran bondad.", "Él siente mi aflicción.", "Comprende penas, dolor, temor,", "cual nadie jamás podrá."]],
        "refrainFirst": false,
        "refrain": ["¡Oh, cuánto amo a Cristo!", "¡Oh, cuánto amo a Cristo!", "¡Oh, cuánto amo a Cristo!", "¡Primero Jesús me amó!"]
      }, {
        "name": "De Jesús el nombre invoca",
        "number": "137",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["De Jesús el nombre invoca,", "heredero del dolor,", "dulce hará tu amarga copa", "con el néctar de su amor."], ["De Jesús el nombre estima,", "que te sirva de broquel;", "alma débil, combatida,", "hallarás asilo en él."], ["De Jesús el nombre ensalza,", "cuyo sin igual poder", "del sepulcro nos levanta,", "renovando nuestro ser."]],
        "refrainFirst": false,
        "refrain": ["Suave luz, manantial", "de esperanza, fe y amor;", "sumo bien celestial", "es Jesús, el Salvador."]
      }, {
        "name": "De mi amante Salvador",
        "number": "138",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["De mi amante Salvador cantaré el inmenso amor;", "gloriareme en el favor de Jesús.", "De tinieblas me llamó, de cadenas me libró,", "de la muerte me salvó, mi Jesús."], ["¡Oh, qué triste condición de mi impío corazón!", "Lo salvó de perdición mi Jesús.", "Mi pecado perdonó, de la ruina me salvó,", "de la angustia me sacó mi Jesús."], ["Por el mundo al vagar, solitario, sin hogar,", "ignoraba el amor de Jesús.", "Mas las lágrimas de ayer han pasado, y hoy placer", "ya comienzo a tener en Jesús."], ["De lo falso a la verdad, de lo impuro a santidad,", "ya me trajo la bondad de Jesús.", "Y hecho fuerte en la virtud de su perennal salud,", "himnos doy de gratitud a Jesús."]],
        "refrainFirst": false,
        "refrain": ["¡Mi Jesús! ¡Mi Jesús!", "¡Cuán precioso es el nombre de Jesús!", "Con su sangre me limpió,", "de su gozo me llenó,", "de su vida me dotó, mi Jesús."]
      }, {
        "name": "La tierna voz del Salvador",
        "number": "139",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["La tierna voz del Salvador", "nos habla conmovida.", "Oíd al Médico de amor,", "que da a los muertos vida."], ["Cordero manso, ¡gloria a ti!", "Por Salvador te aclamo.", "Tu dulce nombre es para mí", "la joya que más amo."], ["\"Borradas ya tus culpas son\",", "su voz hoy te pregona;", "acepta, pues, la salvación,", "y espera la corona."], ["Y cuando al cielo del Señor", "con él nos elevemos,", "arrebatados en su amor,", "su gloria cantaremos."]],
        "refrainFirst": false,
        "refrain": ["Nunca los hombres cantarán,", "nunca los ángeles en luz,", "nota más dulce entonarán", "que el nombre de Jesús."]
      }, {
        "name": "Te quiero, te quiero",
        "number": "140",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["Te quiero, te quiero, te quiero, Señor;", "te quiero, Dios mío, mi fiel Salvador:", "a ti y a tu iglesia, tu casa, tu altar;", "mas cuánto, mis obras te lo han de mostrar."], ["Placer indecible, profundo, eternal;", "me encuentro en la cumbre de gozo inmortal;", "contemplo, arrobado, su gloria sin par,", "y anhelo a Cristo y al cielo volar."], ["Concédeme, oh Cristo, tu fuerza y virtud,", "tu gozo, tu vida, reposo y salud.", "Tu gracia, de mi alma es la inspiración;", "tu amor y tu nombre, mi santa canción."], ["Oh Cristo, ¿a quién como tú puedo hallar?", "Tu voz me consuela, me ayuda a cantar.", "Tú ligas a mi alma con cuerdas de amor.", "Te cantan mis labios con todo fervor."]]
      }, {
        "name": "¡Alabadle!",
        "number": "141",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["¡Alabadle, fiel salvador compasivo!", "¡Canta, oh tierra, canta su magno amor!", "¡Saludadle, ángeles santos en gloria,", "tributad al nombre de Cristo honor!", "En sus brazos él llevará a sus hijos;", "guardarálos siempre cual fiel pastor."], ["¡Alabadle, fiel Salvador compasivo!,", "quien por nuestras faltas su vida dio.", "¡Roca eterna, nuestra inmortal Esperanza,", "Rey del cielo que en la cruz murió!", "¡Dadle gloria; nuestros pesares lleva!", "¡Alabad tan ancho y profundo amor!"], ["¡Alabadle, fiel Salvador compasivo,", "querubines que obedecéis su Ley!", "Cristo en gloria reina por siglos de siglos;", "nuestro Abogado, Profeta y Rey.", "Cristo viene, grande en poder y gloria.", "¡Viene, sí, del mundo ya vencedor!"]],
        "refrainFirst": false,
        "refrain": ["¡Entonad canción a su excelsa grandeza;", "ensalzadle en himnos de santo amor!"]
      }, {
        "name": "Venid, cantad de gozo en plenitud",
        "number": "142",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["Venid, cantad, de gozo en plenitud,", "y dad loor al que su sangre dio;", "en ella luego nos lavó,", "de nuestra lepra nos limpió,", "y así libronos de la esclavitud."], ["El Dios de amor, que vino acá a sufrir", "llevando en sí por nos la maldición,", "en vez de eterna perdición,", "nos proporciona salvación,", "que sin él nadie puede conseguir."], ["Honor y gloria en todo su esplendor", "serán el fin de quien siga a Jesús.", "Si toma en pos de él su cruz", "y es guiado por su luz,", "tendrá el sello de su Salvador."]],
        "refrainFirst": false,
        "refrain": ["Él nos libró de culpabilidad,", "y redimionos por la eternidad;", "con ángeles del cielo nos igualó;", "precioso Salvador el que por nos murió."]
      }, {
        "name": "Digno eres tú",
        "number": "143",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["Eres la Perla de valor;", "eres la Rosa de Sarón;", "eres mi Salvador.", "Eres la Roca de poder,", "el Manantial para mi sed;", "contigo venceré."], ["Cuando tropiezo, cerca estás;", "tu fuerte brazo es mi sostén;", "eres mi Salvador.", "Cuando regreses triunfador", "llévame salvo a tu mansión;", "contigo venceré."]],
        "refrainFirst": false,
        "refrain": ["Cristo, mi Señor, digno eres tú.", "Cristo, mi Señor, digno eres tú."]
      }, {
        "name": "Mi Salvador",
        "number": "144",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["¡Mi Salvador, Jesucristo!", "¿Quién hay igual a ti?", "¡Mi Salvador, Jesucristo!", "Más que todo eres para mí.", "¡Mi Salvador, Jesucristo! ¡Cuánto te amo yo!", "Todo a ti consagro,", "Cristo Jesús, Señor."]]
      }, {
        "name": "Con acentos de alegría",
        "number": "145",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["Con acentos de alegría hoy loamos al Señor,", "que en este grato día nos constriñe con su amor.", "Adoremos al que quiso congregarnos otra vez", "como miembros de su iglesia, todos de una misma fe."], ["Gloria a nuestro Rey amante que nos da su santa paz,", "que en su gracia desbordante, para todos da solaz.", "Levantemos nuestras voces y digamos con fervor:", "¡Gloria al Hijo bondadoso! ¡Gloria a nuestro Redentor!"], ["¡Oh, Señor!, hoy te adoramos con fervor y gratitud,", "y anhelosos te pedimos que nos brindes tu salud.", "Que unidos como hermanos te adoremos sin cesar,", "y en tu gracia bienhechora procuremos siempre estar."]],
        "refrainFirst": false,
        "refrain": ["Con fervor, con fervor,", "a nuestro Rey cantemos,", "y su amor, y su amor,", "nos guarde en santa unión."]
      }, {
        "name": "Ni en la tierra",
        "number": "146",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["Ni en la tierra ni en el cielo", "nombre hay como el de Jesús;", "sobre todo siempre reina,", "él es solo eterna luz."], ["Es Jesús mi gran sustento,", "Pan divino y celestial;", "de mis dichas y mi gozo,", "es el rico manantial."], ["Infinita es su ternura.", "¿Quién la puede agotar?", "Con los ángeles, hoy quiero", "su grandeza pregonar."]]
      }, {
        "name": "A Dios sea gloria",
        "number": "147",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["A Dios sea gloria, es el Creador,", "y amó tanto al mundo que a su Hijo dio,", "quien puso su vida muriendo en la cruz", "y abrió los portales de gloria y luz."], ["Cantad a su gloria, pues Hijo es de Dios.", "Su amor inefable a todos nos dio.", "Contad hoy la historia de la redención;", "que todos los pueblos entonen canción."], ["Ya sea en el canto o en dulce oración,", "load al sublime y fiel Redentor.", "Que joven y anciano proclamen su amor;", "que sordos y mudos le rindan loor."]],
        "refrainFirst": false,
        "refrain": ["¡Exaltad a Jesús! Es el Rey y Señor.", "¡Alabad a Jesús! Es el buen Salvador.", "Load sobre todos su nombre inmortal.", "Él salva a sus hijos del yugo del mal."]
      }, {
        "name": "Solo Cristo",
        "number": "148",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["Solo Cristo, solo él", "me redime, me da paz.", "El anhelo de mi ser", "tan solo Cristo suplirá."]]
      }, {
        "name": "Junto a la cruz do Jesús murió",
        "number": "149",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["Junto a la cruz do Jesús murió,", "do por su gracia clamaba yo,", "mis manchas su sangre allí quitó:", "¡a su nombre gloria!"], ["Cuando por fe en la cruz lo vi,", "de mis pecados salvado fui,", "hoy él me guarda y mora en mí;", "¡a su nombre gloria!"], ["¡Fuente preciosa de salvación!", "Gozo en ti halla mi corazón;", "en ti, Jesús salva y da perdón:", "¡a su nombre gloria!"], ["¡Ven a esta fuente, oh pecador!", "Ponte a los pies de tu Salvador;", "te colmará de su santo amor:", "¡a su nombre gloria!"]],
        "refrainFirst": false,
        "refrain": ["¡A su nombre gloria!", "¡A su nombre gloria!", "Cristo Jesús es mi Salvador:", "¡a su nombre gloria!"]
      }, {
        "name": "A Cristo doy mi canto",
        "number": "150",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["A Cristo doy mi canto:", "él salva el alma mía,", "me libra del quebranto", "y con amor me guía."], ["Jamás dolor ni agravios", "enlutarán mi mente;", "refréscanse mis labios", "con aguas de su fuente."], ["Su amor me ha bendecido", "y alegra el alma mía;", "su nombre es en mi oído", "dulcísima armonía."], ["Me gozo en alabarle;", "y cuando deje el suelo,", "por siempre he de exaltarle", "con ángeles del cielo."]],
        "refrainFirst": false,
        "refrain": ["Ensalce pues mi canto", "su sacrosanta historia.", "Es hoy mi anhelo santo", "mirar Jesús, tu gloria."]
      }, {
        "name": "Por eso lo amo",
        "number": "151",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["El que me amó vino una vez", "a nuestro mundo a padecer;", "sobre la cruz me rescató", "y su amistad me demostró."], ["El que me amó vendrá otra vez;", "todos los ojos lo han de ver,", "todas las voces se alzarán", "y a Jesús aclamarán."]],
        "refrainFirst": false,
        "refrain": ["Por eso lo amo, por eso lo espero,", "por eso me entrego a mi Señor."]
      }, {
        "name": "A ti, Jesús",
        "number": "152",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["A ti, Jesús, alzaré mi voz,", "para adorarte, mi Salvador.", "Acepta hoy, mi Rey y Señor,", "el humilde don de mi amor", "en oración."]]
      }, {
        "name": "Ved a Cristo",
        "number": "153",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["Ved a Cristo, Rey de gloria;", "es del mundo el vencedor.", "De la guerra vuelve invicto;", "todos démosle loor."], ["¡Exaltadlo, exaltadlo!", "Ricos triunfos da Jesús.", "Entronadle en los cielos,", "en la refulgente luz."], ["Si los malos se burlaron", "coronando al Salvador,", "hoy los ángeles y santos", "lo proclaman su Señor."], ["Escuchad las alabanzas", "que se elevan hacia él.", "Victorioso reina Cristo:", "adorad a Emanuel."]],
        "refrainFirst": false,
        "refrain": ["¡Coronadle, santos todos!", "¡Coronadle Rey de reyes!", "¡Coronadle, santos todos!", "¡Coronad al Salvador!"]
      }, {
        "name": "Dad gloria al Cordero Rey",
        "number": "154",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["Dad gloria al Cordero Rey, suprema potestad;", "de su divino amor la Ley postrados aceptad,", "de su divino amor la Ley postrados aceptad."], ["Vosotros, hijos de Israel, residuo de su grey,", "loores dad a Emanuel y proclamadle Rey,", "loores dad a Emanuel y proclamadle Rey."], ["Naciones todas, escuchad y obedeced su Ley", "de gracia, amor y santidad, y proclamadle Rey,", "de gracia, amor y santidad, y proclamadle Rey."], ["Dios quiera que con los que están del trono en derredor,", "con cantos por la eternidad a Cristo demos honor,", "con cantos por la eternidad a Cristo demos honor."]]
      }, {
        "name": "En Sion Jesús hoy reina",
        "number": "155",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["En Sion Jesús hoy reina, alégrate, mortal,", "y ve con devoción y fe al trono celestial.", "En Sion Jesús hoy reina en gloria y majestad,", "venid, oh poderosos, y su gloria proclamad."], ["En Sion Jesús hoy reina cual infinito ser,", "los mares y abismos él gobierna con poder.", "¡Oh, todo el universo alabe al Salvador!", "Proclámenle los pueblos cual amante Redentor."], ["En Sion Jesús hoy reina, del orbe es regidor.", "¡Venid con regocijo y adoradle con fervor!", "y ante su grandeza venid con santa unción.", "Venid y coronadle Rey con toda devoción."]]
      }, {
        "name": "A Cristo coronad",
        "number": "156",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["A Cristo coronad divino Salvador.", "Sentado en alta majestad es digno de loor.", "Al Rey de gloria y paz loores tributad,", "y bendecid al Inmortal por toda eternidad."], ["A Cristo coronad Señor de nuestro amor,", "al Triunfante celebrad, glorioso Vencedor.", "Potente Rey de paz el triunfo consumó,", "y por su muerte en la cruz su grande amor mostró."], ["A Cristo coronad Señor de vida y luz;", "con alabanzas proclamad los triunfos de la cruz.", "A él, pues, adorad, Señor de salvación;", "loor eterno tributad de todo corazón."]]
      }, {
        "name": "¡Majestad!",
        "number": "157",
        "category": "Jesucristo",
        "subcategory": "Alabanza de Cristo",
        "verses": [["¡Majestad! ¡Gloria a su Majestad!", "Dad a Cristo gloria regia, honra y honor.", "Majestad y autoridad real", "son del Señor, nuestro gran Rey, Cristo Jesús.", "Exaltad, magnificad su santo nombre;", "alabad, glorificad a Cristo el Rey.", "¡Majestad! ¡Gloria a su Majestad!", "Pues él murió, resucitó y es el Rey."]]
      }, {
        "name": "Amanece ya la mañana de oro",
        "number": "158",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Amanece ya la mañana de oro,", "pronto el Rey vendrá;", "y su pueblo a la mansión del cielo", "Cristo llevará."], ["En aquel gran día los separados", "se encontrarán,", "y las lágrimas de los afligidos", "se enjugarán."], ["Con sus ángeles de esplendente aspecto", "Cristo, el Juez, vendrá,", "a llevar su iglesia a donde en gloria", "siempre morará."], ["Cuando llegue a todos el evangelio", "anunciando el fin,", "cesará el tardar del Esposo entonces,", "sonará el clarín."]],
        "refrainFirst": false,
        "refrain": ["Amanece ya la mañana de oro", "tras la noche terrenal,", "cuando surgirá del sepulcro abierto", "vida inmortal."]
      }, {
        "name": "Yo espero la mañana",
        "number": "159",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Yo espero la mañana", "del gran día sin igual,", "del cual dicha eterna emana", "y deleite perennal."], ["Yo espero la victoria,", "de la muerte al fin triunfar,", "recibir la eterna gloria", "y mis sienes coronar."], ["Yo espero ir al cielo,", "donde reina eterno amor;", "peregrino soy y anhelo", "las moradas del Señor."], ["Pronto espero unir mi canto", "al triunfante y celestial,", "y cambiar mi amargo llanto", "por el himno angelical."]],
        "refrainFirst": false,
        "refrain": ["Esperando, esperando", "otra vida sin dolor", "y la grata bienvenida", "de Jesús, mi amante Salvador."]
      }, {
        "name": "Viene otra vez nuestro Salvador",
        "number": "160",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Viene otra vez nuestro Salvador, ¡oh, si ya fuera hoy!,", "para reinar con poder y amor, ¡oh, si ya fuera hoy!", "Su fiel iglesia ataviada está con vestiduras de esplendor,", "y en busca del Esposo va. ¡Oh, si ya fuera hoy!"], ["Acabará el poder de Satán, ¡oh, si ya fuera hoy!", "Negras tristezas no se verán, ¡oh, si ya fuera hoy!", "Todos los justos con Cristo irán, a las mansiones de su Señor.", "¿Cuándo estas glorias llegarán? ¡Oh, si ya fuera hoy!"], ["Fieles a él nos debiera hallar si regresara hoy;", "todos velando con gozo y paz si regresara hoy.", "Multiplicadas señales hay; en el oriente se ve el albor,", "ya más cercano el tiempo está. ¡Oh, si ya fuera hoy!"]],
        "refrainFirst": false,
        "refrain": ["¡Gloria!, ¡gloria! Gozo sin fin traerá.", "¡Gloria!, ¡gloria! al coronarle Rey.", "¡Gloria!, ¡gloria! La senda preparad.", "¡Gloria!, ¡gloria! Cristo viene otra vez."]
      }, {
        "name": "¡Oh! cuán gratas las nuevas",
        "number": "161",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["¡Oh! cuán gratas las nuevas al peregrino aquí,", "en destierro obligado a vagar:", "\"He aquí, pronto en gloria tu Salvador vendrá,", "y podrás en su reino entrar\"."], ["Los sepulcros de cuantos en Cristo duermen ya,", "otra vez todos se han de abrir;", "los millones también que en el mar profundo están", "volverán otra vez a vivir."], ["Nos veremos allá en el nuevo Edén feliz;", "el \"adiós\" no diremos jamás;", "pues del norte y del sur los salvados llegarán,", "a morar en el reino de paz."]],
        "refrainFirst": false,
        "refrain": ["Sí, viene, viene, viene, esto sé;", "a la tierra Jesús vendrá;", "y los peregrinos a la gloria irán,", "a su reino el Señor los guiará."]
      }, {
        "name": "En presencia estar de Cristo",
        "number": "162",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["En presencia estar de Cristo,", "ver su rostro, ¿qué será,", "cuando al fin, en pleno gozo,", "mi alma le contemplará?"], ["Solo tras oscuro velo", "hoy lo puedo aquí mirar,", "pero pronto viene el día", "que su gloria ha de mostrar."], ["¡Cuánto gozo habrá con Cristo", "cuando no haya más dolor,", "cuando cesen los peligros", "al abrigo de su amor!"], ["Cara a cara, ¡cuán glorioso", "ha de ser así vivir,", "ver el rostro de quien quiso", "nuestras almas redimir!"]],
        "refrainFirst": false,
        "refrain": ["Cara a cara espero verle", "cuando venga en gloria y luz;", "cara a cara allá en el cielo", "he de ver a mi Jesús."]
      }, {
        "name": "Cristo viene, esto es cierto",
        "number": "163",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Cristo viene, esto es cierto,", "porque lo ha dejado escrito;", "siempre fiel a su promesa,", "por los suyos ya regresa.", "¡Vedle ya, ved al Señor!", "tráelos de la tumba triste,", "de inmortalidad los viste.", "¡Sí, vendrá! ¡Oh, sí, vendrá!"], ["El que en Gólgota muriendo", "dio su vida bendiciendo,", "viene ya resplandeciente,", "en las nubes, imponente.", "¡Vedle ya, ved al Señor!", "Vedle ya venir en gloria,", "coronado de victoria.", "¡Sí, vendrá! ¡Oh, sí, vendrá!"], ["Las espinas, de despecho,", "rayos rojos se han hecho,", "y la caña se ha vuelto", "regio cetro de su imperio.", "¡Vedle ya, ved al Señor!", "Síguenle ángeles gloriosos,", "escuadrones majestuosos.", "¡Sí, vendrá! ¡Oh, sí, vendrá!"], ["¡Ay! de aquel que no haya ido", "a Jesús ni recibido", "ropa santa, regalada,", "para bodas adornada.", "¡Vedle ya, ved al Señor!", "¡Al encuentro del Esposo!", "Es el día más dichoso.", "¡Sí, vendrá! ¡Oh, sí, vendrá!"]]
      }, {
        "name": "Jesús pronto volverá",
        "number": "164",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Jesús pronto volverá", "al mundo con gran poder.", "Promesa nos dio; y la cumplirá;", "su rostro podremos ver.", "En gloria y majestad", "vendrá nuestro Salvador,", "pues señales por doquier", "anuncian al Señor."], ["Muy pronto el Señor vendrá,", "y el pueblo que le esperó,", "del vil tentador librado será,", "por Cristo, quien lo salvó.", "Sus hijos disfrutarán", "de Cristo la comunión,", "y por siempre gozarán", "su eterna salvación."], ["Del norte y del sur vendrán", "trofeos del Redentor.", "Las islas del mar sus joyas darán", "que adornen al Salvador.", "Los santos con él irán", "las bodas a celebrar,", "y por siempre gozarán", "en su feliz hogar."], ["Muy pronto Jesús vendrá", "y el mal llegará a su fin:", "con gran majestad su voz sonará", "gloriosa por el confín.", "Los ángeles tocarán", "trompeta de salvación", "y los santos vivirán", "su eterna redención."]]
      }, {
        "name": "¡Vendrá el Señor!",
        "number": "165",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["¡Vendrá el Señor!", "Nadie sabe la hora;", "del día anhelado", "se ve la aurora.", "¡Oh, siervos de Dios!,", "anunciad sin demora", "que muy pronto vendrá."], ["En cielo y tierra", "se anuncia el portento", "de Cristo en su", "glorioso advenimiento.", "¡Oh, pueblo de Dios!,", "es solemne el momento,", "pues muy pronto vendrá."], ["Velad y orad", "con la vista alzada;", "salid y luchad", "con la santa espada;", "id a trabajar", "con la fe reanimada,", "pues muy pronto vendrá."]],
        "refrainFirst": false,
        "refrain": ["¡Él vendrá!", "¡Esperad y velad,", "pues él vendrá!", "¡Aleluya! ¡Aleluya!;", "En las nubes vendrá", "con sus huestes gloriosas;", "sí, muy pronto vendrá."]
      }, {
        "name": "Siervos de Dios, la trompeta tocad",
        "number": "166",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Siervos de Dios, la trompeta tocad:", "¡Cristo muy pronto vendrá!", "A todo el mundo las nuevas llevad:", "¡Cristo muy pronto vendrá!"], ["Fieles de Cristo, doquier anunciad:", "¡Cristo muy pronto vendrá!", "Siempre alegres, contentos, cantad:", "¡Cristo muy pronto vendrá!"], ["Montes y valles, canción entonad:", "¡Cristo muy pronto vendrá!", "Ondas del mar vuestras voces alzad:", "¡Cristo muy pronto vendrá!"]],
        "refrainFirst": false,
        "refrain": ["¡Pronto vendrá! ¡Pronto vendrá!", "¡Cristo muy pronto vendrá!"]
      }, {
        "name": "¿Quién en deslumbrante gloria?",
        "number": "167",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["¿Quién en deslumbrante gloria", "con sus huestes está?", "¿Quién con célica armadura", "delante de ellos va?"], ["Canta, pueblo remanente,", "presto salvo serás;", "pronto fin tendrán tus pruebas", "si con el Rey estás."], ["Oh, ven pronto, santo día", "de esplendor sin igual,", "cuando en hermosura vuelva", "Jesús, Rey eternal."]],
        "refrainFirst": false,
        "refrain": ["¡Ve adelante, Señor, supremo Rey!", "En gloria sublime y santa majestad.", "¡Ve adelante, a la victoria, ve!,", "que cielo y tierra tuyos son,", "Señor, supremo Rey."]
      }, {
        "name": "El Rey que viene",
        "number": "168",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["El Rey que viene cerca está,", "el mismo que en la cruz murió;", "mas solo viene esta vez", "por los que rescató."], ["De su venida vemos ya", "señales muchas por doquier,", "y pronto el alba eternal", "podrán los pueblos ver."], ["Pues no contéis con gozo y paz:", "aquí las luchas seguirán;", "mas cuando vuelva el Salvador,", "eterno fin tendrán."], ["Entonces nuestro hogar será", "la Tierra Nueva, eternal;", "la muerte nunca entrará,", "pues todo es inmortal."]],
        "refrainFirst": false,
        "refrain": ["Cerca está, cerca está,", "a las puertas mismas llega ya;", "viene presto, viene presto;", "a las puertas llega ya."]
      }, {
        "name": "Cuando suene la trompeta",
        "number": "169",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Cuando suene la trompeta en el día del Señor,", "su esplendor y eterna claridad veré;", "cuando lleguen los salvados ante el magno Redentor,", "y se pase lista, yo responderé."], ["Resucitarán gloriosos los que duermen en Jesús,", "las delicias celestiales a gozar;", "y triunfantes entrarán en las mansiones de la luz;", "para mí también habrá un dulce hogar."], ["Trabajemos, pues, por Cristo, pregonando su amor,", "mientras dure nuestra vida terrenal;", "y al fin de la jornada, con los santos del Señor", "entraremos en la patria celestial."]],
        "refrainFirst": false,
        "refrain": ["Cuando allá se pase lista,", "cuando allá se pase lista,", "cuando allá se pase lista", "y mi nombre llamen, yo responderé."]
      }, {
        "name": "La segunda venida de Cristo",
        "number": "170",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["La segunda venida de Cristo un suceso imponente será,", "tan grandioso cual nunca fue visto; más glorioso jamás se verá.", "De los cielos el Hijo del hombre en la gloria del Padre vendrá;", "\"Verdadero y Fiel\" es su nombre, y el cetro del reino tendrá,", "\"Verdadero y Fiel\" es su nombre, y el cetro del reino tendrá."], ["Cual relámpago, luce del este una nube con luz de crisol,", "cuyo brillo, que alcanza al oeste, sobrepuja los rayos del sol;", "es la hueste de ángeles santos, refulgentes de gloria y luz,", "que escoltan y loan con cantos al invicto y glorioso Jesús,", "que escoltan y loan con cantos al invicto y glorioso Jesús."], ["Los impíos de miedo se espantan y perecen al ver al Señor;", "mas los justos las manos levantan hacia Cristo, su buen Redentor.", "Contemplando sus gratos fulgores, le aclaman con férvida voz:", "\"Rey de reyes, Señor de señores; mil hosannas al Hijo de Dios\",", "\"Rey de reyes, Señor de señores; mil hosannas al Hijo de Dios\"."], ["Del sepulcro los lazos quebranta que ataban al pobre mortal;", "a sus santos Jesús los levanta revestidos de luz inmortal.", "Y los lleva consigo al cielo, los corona y palmas les da,", "y entonces disfrutan sin velo la presencia del Dios Jehová,", "y entonces disfrutan sin velo la presencia del Dios Jehová."]]
      }, {
        "name": "¿Has oído el mensaje?",
        "number": "171",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["¿Has oído el mensaje del regreso del Señor?", "La trompeta a medianoche sonará;", "a sus fieles todos llamará sin olvidarte a ti,", "si con Cristo te alistas a vivir."], ["A los cielos subiremos con los ángeles de luz;", "los amados separados se unirán", "con nosotros para siempre; nunca se apartarán,", "pues allí habitaremos con Jesús."], ["Andaremos por los mundos do el pecado no entró;", "del amor les hablaremos de Jesús,", "que a buscarnos vino para darnos vida eternal,", "pues allí habitaremos con Jesús."], ["Pasarán los siglos uno tras el otro sin cesar,", "y el vigor perenne no se perderá", "de esa juventud eterna, primavera sin menguar,", "pues allí habitaremos con Jesús."]],
        "refrainFirst": false,
        "refrain": ["Él viene, él viene, mirad; cercano, cercano él está.", "Las perlinas puertas ya ábrense de par en par", "y los salvos entrarán en el Edén;", "y sus voces jubilosas con los ángeles se oirán,", "pues allí habitaremos con Jesús."]
      }, {
        "name": "Promesa dulce",
        "number": "172",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Promesa dulce: \"Yo vendré,", "y a los que sufren salvaré\".", "Responde mi alma: \"Presto ven;", "ansioso esperaré\"."], ["Los santos vuelven a vivir,", "al cielo todos subirán;", "y en tierra atado años mil", "ha de quedar Satán."], ["Desciende entonces la ciudad,", "los malos levantados son", "a oír el fallo de su Juez:", "\"Eterna perdición\"."], ["El nuevo Edén florecerá", "en hermosura celestial;", "jamás la muerte turbará", "la playa inmortal."]],
        "refrainFirst": false,
        "refrain": ["¡Ven, Señor, mi Redentor!", "¡Ven, Señor, mi Redentor!", "Responde mi alma:", "\"¡Presto ven, Señor, mi Redentor!\""]
      }, {
        "name": "¿Será al albor?",
        "number": "173",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["¿Será al albor, cuando el día despierta", "y el sol cada sombra nocturna ahuyenta?", "¿Será al albor cuando en toda su gloria", "volverá a la tierra Jesús?"], ["Trompeta triunfal todos pronto oiremos;", "su escolta de ángeles santos veremos;", "su sien coronada de luz miraremos", "cuando venga en gloria Jesús."], ["¡Qué gozo habrá cuando acabe la muerte!", "Vivir sin dolor tocaranos en suerte;", "las penas y el mal quitará el Dios fuerte", "cuando venga en gloria Jesús."]],
        "refrainFirst": false,
        "refrain": ["¿Cuánto aún faltará, Señor,", "hasta que cante así:", "\"Cristo vuelve, ¡aleluya! ¡aleluya! Amén.", "¡Aleluya! Amén\"?"]
      }, {
        "name": "Mira los hitos",
        "number": "174",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Mira los hitos de la humanidad,", "mira los hitos, su continuidad.", "Cuatro imperios han pasado ya,", "y desde ahora mira el porvenir."], ["De Babilonia gran reinado vio,", "luego el persa su lugar quitó.", "Después, los griegos, Roma imperial", "y las naciones de la actualidad."], ["La Roca eterna desmenuzará", "todos los reinos de la humanidad.", "Y Jesucristo establecerá", "su reino eterno, nuestra heredad."]],
        "refrainFirst": false,
        "refrain": ["Mira los hitos, proféticas señales,", "del gran mensaje dado a Daniel.", "Mira los hitos, proféticas señales,", "de aquel profeta fiel."]
      }, {
        "name": "Ved a Cristo, que se acerca",
        "number": "175",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Ved a Cristo, que se acerca", "con gloriosa majestad.", "Los que aguardan su regreso", "dicen: \"Este es nuestro Dios,", "lo hemos esperado siempre,", "él es quien nos salvará\"."], ["Los que duermen en el polvo", "se levantan por doquier.", "Los poderes del maligno", "no los pueden retener.", "Pecadores descuidados,", "¿qué destino encontrarán?"], ["Y entonces los salvados", "entrarán en la mansión.", "Jesucristo los recibe", "y les da coronación:", "\"Bien, buen siervo; fiel serviste;", "entra al gozo del Señor\"."]]
      }, {
        "name": "Tú verás al Rey viniendo",
        "number": "176",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Tú verás al Rey viniendo,", "y oirás son de trompetas.", "Tú verás al Rey viniendo,", "falta poco ya."], ["Resucitarán los justos,", "miles de ángeles los llevan", "a los pies de Jesucristo;", "falta poco ya."], ["Es Gabriel el que proclama", "y nosotros cantaremos:", "\"Los pesares terminaron\".", "Falta poco ya."]],
        "refrainFirst": false,
        "refrain": ["Hoy escucha el coro,", "oye las trompetas.", "Hoy escucha el coro", "que anuncia al Señor."]
      }, {
        "name": "Los tres mensajes angélicos",
        "number": "177",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Ved al ángel que proclama", "buenas nuevas por doquier.", "Con potente voz anuncia:", "respetad y honrad a Dios,", "pues la hora de su juicio ya llegó;", "y adorad al que hizo cielo, tierra y mar."], ["Otro ángel sigue presto", "pregonando en alta voz:", "Ha caído Babilonia", "pues al mundo engañó.", "Pueblo mío, apartaos de su error;", "pues así sus plagas no os tocarán."], ["El tercer mensaje llega", "con solemne admonición:", "El que adore a la bestia", "o reciba su señal,", "el castigo ha de recibir al fin,", "pues la gracia no podrá cubrirlo más."], ["Aquí están los que esperan", "con paciencia a Jesús.", "Los que guardan sin reservas", "del Señor la Ley de amor.", "Por la fe en Cristo ellos vivirán", "y gozosos en el cielo entrarán."]]
      }, {
        "name": "Contemplé la gloria",
        "number": "178",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Contemplé la gloria del regreso de mi Salvador,", "que a su paso va pisando el lagar del detractor;", "con su espada refulgente, como gran conquistador,", "avanza vencedor."], ["Cual el lirio de los valles Cristo vino a nacer,", "para revelar del Padre el poder transformador;", "él murió para salvarnos, anunciemos por doquier,", "que avanza vencedor."], ["Ha sonado la trompeta que jamás se rendirá,", "ha juzgado a los mortales su divino tribunal.", "Respondamos prestamente al Señor que nos dará", "victoria eterna!"]],
        "refrainFirst": false,
        "refrain": ["¡Gloria, gloria, aleluya!", "¡Gloria, gloria, aleluya!", "¡Gloria, gloria, aleluya!", "Venciendo, ven, Jesús."]
      }, {
        "name": "¡Cristo viene! Aquel día se acerca",
        "number": "179",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["¡Cristo viene! Aquel día se acerca, bien lo sé.", "Renovemos la esperanza y afirmemos nuestra fe.", "¡Cristo viene! ¡Cristo viene!", "¡Ven, oh Príncipe de paz!", "¡Ven, oh Príncipe de paz!"], ["De una cruz, dolor y angustia nuestra tierra puede hablar.", "Pero mirará tu gloria, cuando vuelvas a reinar.", "¡Cristo viene! ¡Cristo viene!", "¡Ven, eterno Rey de luz!", "¡Ven, eterno Rey de luz!"], ["La bendita esperanza vibra en nuestro corazón.", "Compartamos nuestro gozo, entonemos la canción:", "¡Cristo viene! ¡Cristo viene!", "¡Ven muy pronto, mi Jesús!", "¡Ven muy pronto, mi Jesús!"]]
      }, {
        "name": "Sí, lo veremos",
        "number": "180",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Sí, lo veremos cuando venga en gloria.", "Sí, lo veremos al Rey y Señor.", "¡ngeles y hombres le darán la gloria;", "alzando voces, hosannas cantarán."], ["Sí, lo veremos la mañana hermosa.", "Sí, lo veremos en todo esplendor.", "Huestes triunfantes de los redimidos", "entonaremos hosannas a Jesús."], ["Sí, lo veremos en aquel gran día.", "Sí, lo veremos en su majestad. Todos unidos le", "tributaremos nuestra alabanza:", "¡Hosanna al Señor! ¡Hosanna al Señor!"]]
      }, {
        "name": "Una esperanza",
        "number": "181",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Una esperanza arde en nuestro ser,", "la del retorno del Señor.", "Esta es la fe que solo Cristo da,", "fe en la promesa del Señor.", "Muy cercano el tiempo está", "cuando la humanidad", "jubilosa cantará:", "¡Aleluya! ¡Cristo es Rey!", "Una esperanza arde en nuestro ser,", "la del retorno del Señor."], ["Cristo nos une, él es nuestro Rey;", "Cristo nos une en su amor.", "Amor por los que en este mundo están", "y necesitan a Jesús.", "Pronto el cielo se abrirá", "y Jesús descenderá,", "todo el mundo cantará:", "¡Aleluya! ¡Cristo es Rey!", "Una esperanza y una misma fe", "nos une a todos en su amor."]]
      }, {
        "name": "Veremos a Cristo",
        "number": "182",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["El cielo se abrirá y le dará paso;", "millones de estrellas lo alabarán.", "Su mirada radiante nos dará santo gozo", "y contemplaremos al Salvador."], ["Trompeta se oirá llamando a los muertos;", "del sueño despertarán, a vida eternal.", "Y los que estemos vivos,", "transformados seremos", "y contemplaremos al Salvador."]],
        "refrainFirst": false,
        "refrain": ["Veremos a Cristo, al Rey veremos", "cara acara en toda su gloria.", "Veremos a Cristo, al Rey veremos", "cara a cara, al Rey y Señor."]
      }, {
        "name": "No me olvidé de ti",
        "number": "183",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Si las pruebas de esta vida debilitan ya tu fe,", "y en tu mente surgen dudas de que venga otra vez.", "Mira hoy hacia el Calvario, a la cruz donde sufrí,", "¿Qué más prueba quieres que esta: Te amé hasta morir?"], ["¿Te parece que podría olvidarme de cumplir,", "si en la historia de este mundo rojas huellas ya dejé?", "Si una madre se olvidara de su hijo alguna vez,", "aun así jamás pensaría olvidarme yo de ti."]],
        "refrainFirst": false,
        "refrain": ["Aunque pienses que demoro y sientas que me atraso,", "un día prometí volver y pronto lo cumpliré.", "Aunque pienses que demoro y sientas que me atraso,", "yo no me olvidé de ti, vendré otra vez."]
      }, {
        "name": "Nunca te rindas",
        "number": "184",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Días hay, lo sé, en que triste te sientes", "y sin esperanza estás.", "Pero existe un Dios que promete ayudarte,", "y fuerzas tu alma tendrá."], ["Este mundo cruel no será para siempre,", "el mal pronto terminará;", "y mi fe en Jesús cada día es más fuerte,", "pues sé que Jesús volverá."]],
        "refrainFirst": false,
        "refrain": ["Nunca te rindas, Cristo ya viene,", "y su rostro pronto verás.", "Nunca te rindas, Cristo ya viene;", "nos llevará a su hogar."]
      }, {
        "name": "Al cielo voy",
        "number": "185",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Yo te veré a ti, Señor,", "yo te veré, mi Salvador;", "en dulce luz y esplendor", "yo te veré, mi Salvador."], ["Tu pura sangre carmesí", "la mancha vil borró de mí;", "ventura gozaré allí;", "yo te veré, mi Salvador."], ["Feliz aquel que en ti confió,", "por Salvador te aceptó;", "en ti, también espero yo;", "yo te veré, mi Salvador."]],
        "refrainFirst": true,
        "refrain": ["Al cielo voy, al cielo voy;", "yo confío en Jesús;", "él me salvó, él me salvó,", "por mí ha muerto en la cruz."]
      }, {
        "name": "Hace años escuché",
        "number": "186",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Hace años escuché que Jesús volverá;", "con gozo acepté esta verdad.", "La esperanza firme está, pues ahora bien yo sé", "que mi Cristo muy pronto volverá."], ["Odio y guerras por doquier; violencia y maldad.", "No dudes, vendrá el buen Señor.", "Su promesa cumplirá, por sus hijos volverá;", "sí, confiad, pronto nuestro Rey vendrá."]],
        "refrainFirst": false,
        "refrain": ["Todo proclama con poder,", "y las señales anuncian ya el fin.", "Atención: la vista levantad,", "la redención muy cerca ya está."]
      }, {
        "name": "Aunque anochezca",
        "number": "187",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Aunque anochezca, vendrá la aurora", "de un nuevo día primaveral.", "Se irán las penas, y Cristo en gloria", "a sus mansiones nos llevará."], ["Cuando amanezca, no habrá más sombras;", "no habrá temores, ni tempestad.", "En ese día, que no demora,", "la dicha eterna siempre será."], ["Cuando amanezca, Cristo amoroso,", "con mano amiga nos llevará", "por las riberas de aquel hermoso", "y transparente mar de cristal."], ["Cuando amanezca, nos juntaremos", "con redimidos de toda edad.", "No habrá más llanto, ni \"adiós\" diremos", "en el eterno, feliz hogar."]]
      }, {
        "name": "Gran alegría",
        "number": "188",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Doquiera yo puedo ver dolor,", "personas que sufren sin amor,", "muriendo de hambre o enfermedad,", "luchando apenas por vivir.", "Hay muchos que quieren resolver", "los males del mundo sin saber", "que solo en Cristo existe el poder", "que puede cambiar la realidad."], ["En breve habrá de terminar", "el mundo y toda vanidad;", "el mal y el pecado pronto acabarán;", "Jesús volverá y reinará.", "Un cielo hermoso puedo ver,", "mirando por medio de la fe.", "El brillo del mundo se apagará;", "la luz de Jesús me alumbrará."]],
        "refrainFirst": true,
        "refrain": ["Gran alegría inundó mi corazón", "cuando escuché que Cristo pronto volverá.", "Gran alegría y emoción llenó mi ser", "por lo que Cristo hizo y hará por mí."]
      }, {
        "name": "Cristo muy pronto vendrá",
        "number": "189",
        "category": "Jesucristo",
        "subcategory": "Segunda venida de Cristo",
        "verses": [["Cristo muy pronto vendrá;", "ya vuelve con poder.", "Prometió que volverá, y cumplirá.", "Quiero con Cristo morar", "por la eternidad.", "Él me promete en el cielo vivir,", "pues vida abundante hay allí."]]
      }, {
        "name": "Santo Espíritu de Cristo",
        "number": "190",
        "category": "El Espíritu Santo",
        "verses": [["Santo Espíritu de Cristo,", "mora en este corazón,", "lléname de tu presencia,", "cólmame de bendición."], ["Santo Espíritu, lo puedes,", "aunque cómo, no lo sé;", "mas si tú mis ruegos oyes,", "sé que puro yo seré."], ["Débil soy, flaqueza todo,", "mas me postro a tus pies,", "para que tu amor eterno,", "fuerte, puro y fiel me des."], ["Lávame, bendice y salva", "cuerpo, alma, espíritu;", "ya me salvas, me consuelas,", "de bondad me colmas tú."]],
        "refrainFirst": false,
        "refrain": ["¡Cólmame! ¡Cólmame!", "¡Ven ahora y cólmame!", "¡Cólmame de tu presencia!", "¡Ven, oh ven y cólmame!"]
      }, {
        "name": "La nueva proclamad",
        "number": "191",
        "category": "El Espíritu Santo",
        "verses": [["La nueva proclamad doquier que el hombre esté,", "doquier haya aflicción, miserias y dolor;", "cristianos, anunciad que el Padre nos envió", "el fiel Consolador."], ["La noche ya pasó; brillando está la luz", "que habrá de disipar las sombras del terror;", "es para el pecador la aurora celestial", "el fiel Consolador."], ["Es él quien da salud y plena libertad", "a los que encadenó el fiero tentador;", "los rotos hierros hoy dirán que vino ya", "el fiel Consolador."], ["Mi lengua mueve tú, que sepa hablar aquí", "del don que recibí, oh grande Dios de amor,", "al renovar en mí la imagen celestial", "el fiel Consolador."]],
        "refrainFirst": false,
        "refrain": ["Al mundo vino ya el fiel Consolador", "que Dios nos prometió, la prenda de su amor;", "doquier que el hombre esté,", "decid que descendió el fiel Consolador."]
      }, {
        "name": "Llena mi ser",
        "number": "192",
        "category": "El Espíritu Santo",
        "verses": [["Santo Espíritu de Dios,", "llena tú mi ser.", "Santo Espíritu de Dios,", "dame tu poder.", "Toma mi ser", "para amoldarlo.", "Santo Espíritu de Dios,", "llena tú mi ser."]]
      }, {
        "name": "Dios nos ha dado promesa",
        "number": "193",
        "category": "El Espíritu Santo",
        "verses": [["Dios nos ha dado promesa:", "\"Lluvias de gracia enviaré,", "dones que os den fortaleza,", "gran bendición os daré\"."], ["Cristo nos dio la promesa", "del Santo Consolador;", "paz y perdón y pureza,", "para su gloria y honor.", "3.Dios nuestro, a todo creyente", "muestra tu amor y poder;", "tú eres de gracia la fuente,", "llenas de paz nuestro ser."], ["Obra en tus siervos piadosos", "celo, virtud y valor;", "del tentador victoriosos", "salgan contigo, Señor."]],
        "refrainFirst": false,
        "refrain": ["Lluvias de gracia,", "lluvias pedimos, Señor;", "mándanos lluvias copiosas,", "lluvias del Consolador."]
      }, {
        "name": "Vive en mí",
        "number": "194",
        "category": "El Espíritu Santo",
        "verses": [["Vive, Santo Espíritu,", "en este pobre ser;", "y que tu fuego celestial", "transforme mi existir."], ["Habla, Santo Espíritu,", "háblame con poder;", "y al escuchar tu dulce voz,", "consagre a ti mi ser."], ["Santo, Santo Espíritu,", "lléname de fervor,", "para servirte con amor,", "y sea yo vencedor."]],
        "refrainFirst": false,
        "refrain": ["Vive en mí, vive en mí,", "Santo y fiel Consolador.", "Limpia del mal mi corazón;", "llénalo siempre de tu amor."]
      }, {
        "name": "Abre mis ojos a la luz",
        "number": "195",
        "category": "El Espíritu Santo",
        "verses": [["Abre mis ojos a la luz; tu rostro quiero ver, Jesús.", "Pon en mi corazón tu bondad, y dame paz y santidad.", "Humildemente acudo a ti, porque tu tierna voz oí.", "Mi guía sé, Espíritu Consolador."], ["Abre mi oído a tu verdad; yo quiero oír con claridad", "bellas palabras de dulce amor, ¡oh, mi bendito Salvador!", "Consagro a ti mi frágil ser; tu voluntad yo quiero hacer.", "Llena mi ser, Espíritu Consolador."], ["Abre mis labios para hablar y a todo el mundo proclamar", "que tú viniste a rescatar al más indigno pecador.", "La mies es mucha, ¡oh, Señor!; obreros faltan de valor.", "Heme aquí, Espíritu Consolador."], ["Abre mi mente para ver más de tu amor y gran poder.", "Haz que en la lucha pueda triunfar y en tus caminos fiel andar.", "De mi alma escudo siempre sé y aumenta mi valor y fe.", "Mi mano ten, Espíritu Consolador."]]
      }, {
        "name": "Santo Espíritu de Dios",
        "number": "196",
        "category": "El Espíritu Santo",
        "verses": [["Santo Espíritu de Dios", "brilla en mi corazón;", "vuelve en día la oscuridad", "de mi noche de aflicción."], ["Poderoso Espíritu,", "limpia este corazón,", "porque sobre mi alma el mal", "ejerció cruel opresión."], ["Oh, divino Espíritu,", "mora en mi corazón.", "Quita todo ídolo,", "reina en plena posesión."]]
      }, {
        "name": "Dulce Espíritu",
        "number": "197",
        "category": "El Espíritu Santo",
        "verses": [["Hay un dulce Espíritu aquí,", "y yo sé que es el Espíritu de mi Señor.", "En los rostros veo gozo y paz,", "y yo sé que está presente el Señor.", "Dulce presencia del Salvador;", "permanece aquí, Señor,", "llénanos con tu amor.", "Por tus bondades", "te adoramos hoy;", "y que al salir de aquí,", "con nueva vida en ti,", "llevemos tu amor."]]
      }, {
        "name": "Desciende, Espíritu de amor",
        "number": "198",
        "category": "El Espíritu Santo",
        "verses": [["Desciende, Espíritu de amor,", "Paloma celestial,", "promesa fiel del Salvador,", "de gracia manantial."], ["Aviva nuestra escasa fe,", "concédenos salud;", "benigno, guía nuestro pie", "por sendas de virtud."], ["Consuela nuestro corazón", "y habita siempre en él;", "concédele el precioso don", "de serte siempre fiel."], ["A nuestro Padre, celestial;", "al Hijo, autor del bien,", "y al Santo Espíritu eternal,", "sea la gloria. Amén."]]
      }, {
        "name": "Movidos por tu Espíritu",
        "number": "199",
        "category": "El Espíritu Santo",
        "verses": [["Movidos por tu Espíritu, Señor,", "podemos alcanzar consagración,", "lanzarnos a los campos con fervor", "sirviéndote con gozo y devoción."], ["El mundo necesita tu poder,", "vencer al enemigo, conquistar", "el Reino de justicia y conocer", "al que tiene virtud para salvar."], ["Tu iglesia está despierta y en pie,", "ungida con tu Espíritu de amor.", "Unidos marcharemos por la fe", "en Cristo nuestro amante Redentor."]],
        "refrainFirst": false,
        "refrain": ["Muévenos, Señor; muévenos, Señor.", "Muévanos tu Espíritu, Señor."]
      }, {
        "name": "Bautízanos hoy",
        "number": "200",
        "category": "El Espíritu Santo",
        "verses": [["Bautízanos hoy", "con tu gran poder;", "tu amor nos renueve,", "acércate hoy."], ["Indignos del don,", "confiamos en ti.", "¡Oh, lava y limpia", "hoy nuestra maldad!"], ["Paloma de amor,", "desciende aquí,", "otorga a tu pueblo", "tu gran bendición."]],
        "refrainFirst": false,
        "refrain": ["Humildes pedimos", "a ti, Salvador,", "que el Espíritu Santo", "descienda aquí hoy."]
      }, {
        "name": "Canción del Espíritu",
        "number": "201",
        "category": "El Espíritu Santo",
        "verses": [["Oh, deja que el Señor te envuelva", "con su Espíritu de amor;", "satisfaga hoy tu alma y corazón.", "Entrégale lo que él te indique,", "y su Espíritu vendrá", "sobre ti y vida nueva te dará."], ["Alzamos nuestra voz con gozo,", "nuestra alabanza a ti;", "con dulzura te entregamos nuestro ser.", "Entrega toda tu tristeza", "en el nombre de Jesús,", "y abundante vida hoy tendrás en él."]],
        "refrainFirst": false,
        "refrain": ["Cristo, ¡oh, Cristo!", "Ven y llénanos.", "Cristo, ¡oh, Cristo!", "Llénanos de ti."]
      }, {
        "name": "Danos el fuego",
        "number": "202",
        "category": "El Espíritu Santo",
        "verses": [["Danos el fuego que ardió", "en hombres tales cual Daniel,", "que en rudas pruebas lo guardó", "y lo mantuvo siempre fiel."], ["Danos la llama que animó", "la fe potente de Abraham,", "que a Pablo un gran valor le dio,", "y amor profundo al joven Juan."], ["Danos del cielo la virtud,", "la que a Elías dio poder.", "Danos tu divinal salud,", "tu fe y tu gracia a conocer."], ["Pronto en la nubes volverás;", "necesitamos más fervor;", "y te imploramos además:", "Danos tu Espíritu, Señor."]]
      }, {
        "name": "Santo Espíritu llena mi vida",
        "number": "203",
        "category": "El Espíritu Santo",
        "verses": [["Santo Espíritu llena mi vida,", "pues por Cristo yo quiero brillar.", "Santo Espíritu llena mi vida,", "úsame las almas a salvar."], ["Quiero dar tu mensaje a otros", "hasta que Cristo vuelva otra vez.", "Quiero dar tu mensaje a otros,", "úsame las almas a salvar."]],
        "refrainFirst": false,
        "refrain": ["Alabanzas, alabanzas,", "alabanzas doy a Cristo el Rey.", "Alabanzas, alabanzas,", "alabanzas doy al Rey."]
      }, {
        "name": "Oh, cantádmelas otra vez",
        "number": "204",
        "category": "Las Sagradas Escrituras",
        "verses": [["Oh, cantádmelas otra vez,", "bellas palabras de vida;", "hallo en ellas mi gozo y luz,", "bellas palabras de vida.", "Sí, de luz y vida; son sostén y guía."], ["Jesucristo a todos da", "bellas palabras de vida;", "hoy escúchalas pecador,", "bellas palabras de vida.", "Bondadoso te salva, y al cielo te llama."], ["Grato el cántico sonará:", "bellas palabras de vida;", "tus pecados perdonará,", "bellas palabras de vida.", "Sí, de luz y vida; son sostén y guía."]],
        "refrainFirst": false,
        "refrain": ["¡Qué bellas son! ¡Qué bellas son!", "Bellas palabras de vida.", "¡Qué bellas son! ¡Qué bellas son!", "Bellas palabras de vida."]
      }, {
        "name": "Dadme la Biblia",
        "number": "205",
        "category": "Las Sagradas Escrituras",
        "verses": [["Dadme la Biblia, reluciente estrella,", "norte del nauta en tormentoso mar;", "nunca el nublado esconderá su brillo,", "pues las tinieblas puede disipar."], ["Dadme la Biblia, en mi desaliento,", "cuando el pecado cáuseme temor;", "dadme los fieles dichos del Maestro;", "siempre me encuentre junto al Salvador."], ["Dadme la Biblia, antorcha a mis pisadas", "en la insegura senda terrenal;", "única luz constante en las tinieblas;", "prenda de paz y amparo celestial."], ["Dadme la Biblia, luz de vida eterna;", "junto al sepulcro su esplendor alzad;", "sobre el Jordán destáquense las puertas", "fúlgidas de la célica ciudad."]],
        "refrainFirst": false,
        "refrain": ["Dadme la Biblia, santa y clara nueva,", "luz del camino angosto y celestial;", "regla y promesa, ley y amor unidos", "hasta que rompa el alba eternal."]
      }, {
        "name": "Padre, tu Palabra es mi delicia",
        "number": "206",
        "category": "Las Sagradas Escrituras",
        "verses": [["Padre, tu Palabra es", "mi delicia y mi solaz,", "guía siempre aquí mis pies", "y a mi pecho trae la paz.", "Es tu santa Ley, Señor,", "faro eterno, celestial,", "que en perenne resplandor", "norte y guía es al mortal."], ["Cuando obedecí tu voz", "en tu gracia fuerza hallé,", "y con firme pie, y veloz,", "por tus sendas caminé.", "Tu verdad es mi sostén", "contra duda y tentación,", "y destila calma y bien", "cuando asalta la aflicción."], ["Son tus dichos para mí", "prendas fieles de salud.", "Dame, pues, que te oiga a ti", "con filial solicitud.", "Es mi ciencia, mi saber,", "tu divina voluntad;", "y por siempre lo ha de ser", "en la grande eternidad."]]
      }, {
        "name": "Dios nos habla",
        "number": "207",
        "category": "Las Sagradas Escrituras",
        "verses": [["Dios nos habla por profetas; su Palabra nos envió.", "Pues fielmente cada uno el mensaje transmitió.", "En el mundo tormentoso roca firme es su voz.", "Dios no cambia; Dios nos ama, y sus dones nos dará."], ["Dios nos habla por su Hijo; el Eterno se encarnó.", "Él es uno con el Padre y su gloria reveló.", "Cristo dijo: \"Soy la Vida, el Camino, la Verdad;", "al que crea en mi nombre vida eterna le daré\"."], ["Por su Espíritu, Dios habla; nos ayuda a entender", "la Palabra revelada que da vida y poder.", "La por cae y se seca, mas la Biblia durará.", "Ilumina nuestra mente, y renueva nuestro ser."]]
      }, {
        "name": "¡Santa Biblia!",
        "number": "208",
        "category": "Las Sagradas Escrituras",
        "verses": [["¡Santa Biblia!, para mí eres un tesoro aquí.", "Tú contienes con verdad la divina voluntad;", "tú me dices lo que soy, de quién vine y a quién voy."], ["Tú reprendes mi dudar; tú me exhortas sin cesar.", "Eres faro que a mi pie va guiando, por la fe,", "a las fuentes del amor del benigno Salvador."], ["Eres la infalible voz del Espíritu de Dios,", "que vigor al alma da cuando en aflicción está.", "Tú me enseñas a triunfar de la muerte y el pecar."], ["Por tu santa letra sé que con Cristo reinaré.", "Yo, que tan indigno soy, por tu luz al cielo voy.", "¡Santa Biblia!, para mí eres un tesoro aquí."]]
      }, {
        "name": "La Biblia nos habla de Cristo",
        "number": "209",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["La Biblia nos habla de Cristo", "y de su muerte en la cruz.", "Su santa Palabra ha dicho", "que él pronto vuelve en luz."], ["No anheles el bien de esta vida,", "pues ella se pasará.", "Entrégate a él sin medida,", "que hoy llamando está."], ["No dejes que pase más tiempo", "sin entregarte a Jesús.", "Alístate, pues el Maestro", "muy pronto vuelve en luz."]],
        "refrainFirst": false,
        "refrain": ["¿Te hallas listo a encontrar al Señor?", "¿Lo haces todo con fe, con amor?", "¿Has peleado por fe la batalla del bien?", "¿Pueden otros a Cristo en ti ver?", "¿Eres fiel por doquiera que vas?", "¿Puedes tú contemplarlo en su faz", "y triunfante decir:", "\"Este es mi Dios\"?", "¿Puedes tú encontrar al Señor?"]
      }, {
        "name": "Huye cual ave",
        "number": "210",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Huye cual ave a tu monte,", "alma abrumada del mal;", "allí en Jesús, clara fuente,", "lava tu lepra mortal.", "Huye del mal vergonzoso,", "clame tu ser, temeroso;", "Cristo te ofrece reposo,", "¡oh! alma abrumada del mal;", "¡oh! alma abrumada del mal."], ["Quiere el Maestro salvarte,", "tu llanto enjugará;", "promete nunca dejarte,", "defensa fiel te será.", "Ven, pues, va el día volando;", "no andes más suspirando", "ni te detengas llorando:", "tus males Jesús quitará;", "tus males Jesús quitará."]]
      }, {
        "name": "Fija tus ojos en Cristo",
        "number": "211",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Te sientes cansado y turbado,", "sin luz en la oscuridad.", "Contempla por fe al Maestro,", "tendrás vida y libertad."], ["De muerte a vida eterna", "pasé al seguir a Jesús.", "Me libra de todo pecado", "mediante su muerte en la cruz."], ["Jamás fallará su promesa;", "con fe hazle tu petición.", "Al mundo perdido anuncia", "las nuevas de la salvación."]],
        "refrainFirst": false,
        "refrain": ["Fija tus ojos en Cristo", "tan lleno de gracia y amor,", "y lo terrenal sin valor será", "a la luz del glorioso Señor."]
      }, {
        "name": "A tu puerta Cristo está",
        "number": "212",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["A tu puerta Cristo está.", "¡brele.", "Si le abres entrará.", "¡brele. Tu pecado quitará,", "luz y paz derramará,", "su perdón te otorgará.", "¡brele."], ["¡brele, oh pecador.", "¡brele.", "Al amante Salvador", "ábrele.", "Hoy te ofrece salvación,", "del pecado el perdón;", "saciará tu corazón.", "¡brele."], ["No le hagas esperar.", "¡brele.", "No le obligues a marchar.", "¡brele. ¡Qué dolor después tendrás,", "cuando en vano clamarás", "y perdido te hallarás!", "¡brele."]]
      }, {
        "name": "Tierno y amante, Jesús nos invita",
        "number": "213",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Tierno y amante, Jesús nos invita.", "Llámate a ti, y a mí.", "Mírale allá en la puerta esperando;", "aguarda a ti y a mí."], ["Sigue llamando; ¿por qué dilatamos?", "Llámate a ti, y a mí.", "¿Tantas mercedes en poco tendremos?", "¡mate a ti y a mí."], ["¡Oh, maravilla de amor prometido", "tanto a ti como a mí!", "Ven y recibe el perdón ofrecido,", "dado de gracia a ti."]],
        "refrainFirst": false,
        "refrain": ["¡Venid, venid, tristes, cansados, venid!", "Tierno y amante, Jesús nos invita:", "¡Oh, pecadores, venid!"]
      }, {
        "name": "Mientras Jesús te llama",
        "number": "214",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Mientras Jesús te llama,", "ven, pecador.", "Mientras por ti oramos,", "ven, pecador.", "Hoy es el día propicio.", "Ven, pecador.", "Hoy puedes conocerle.", "Ven, pecador."], ["¿Andas de mal cargado?", "Ven, pecador.", "Hay en Jesús alivio. Ven, pecador.", "No quiere engañarte.", "Ven, pecador.", "Hoy quiere rescatarte.", "Ven, pecador."], ["Oye sus tiernos ruegos.", "Ven, pecador.", "Su bendición recibe. Ven, pecador.", "Mientras Jesús te llama,", "ven, pecador.", "Mientras por ti oramos,", "ven, pecador."]]
      }, {
        "name": "Con voz benigna te llama Jesús",
        "number": "215",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Con voz benigna te llama Jesús:", "invitación de puro amor.", "¿Por qué le dejas en vano llamar?", "¿Sordo serás, pecador?"], ["A los cansados invita Jesús;", "él ve su afán, siente el dolor.", "Tráele tu carga; te la quitará,", "te sostendrá tu Señor."], ["Siempre aguardándote mira Jesús.", "¡Tanto esperar, con tanto amor!", "¡Ven, oh cargado, trayendo a sus pies", "tu tentación, tu dolor!"]],
        "refrainFirst": false,
        "refrain": ["Llámate hoy, llámate hoy,", "hoy ven a Cristo y dile:", "\"Mi alma te doy\"."]
      }, {
        "name": "Dios al pródigo llama",
        "number": "216",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Dios al pródigo llama que venga sin tardar.", "Oye pues su voz que hoy te llama a ti.", "Aunque lejos vagabas del paternal hogar,", "amoroso llámate aún."], ["Tierno, amante, paciente, tu Padre implora aún.", "Oye pues su voz que hoy te llama a ti.", "Vuelve mientras abogue por ti el Espíritu;", "amoroso llámate aún."], ["Ven, recibe el abrazo del Padre celestial.", "Oye pues su voz que hoy te llama a ti.", "Entra alegre al banquete que brinda, sin igual;", "amoroso llámate aún."]],
        "refrainFirst": false,
        "refrain": ["Llámate hoy a ti,", "cansado pródigo, ven;", "llámate hoy a ti,", "cansado pródigo, ven."]
      }, {
        "name": "Bienvenida da Jesús",
        "number": "217",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Bienvenida da Jesús", "en los brazos de su amor", "al que en busca de la luz", "vague ciego y con temor."], ["A sus pies descansarás;", "ejercita en él tu fe;", "de tus males sanarás;", "a Jesús, tu amigo, ve."], ["Recibirte prometió,", "date prisa en acudir;", "necesitas como yo vida", "que él te hará vivir."]],
        "refrainFirst": false,
        "refrain": ["Volveremos a cantar:", "\"Él recibe al pecador\".", "Claro hacedlo resonar:", "\"Él recibe al pecador\"."]
      }, {
        "name": "A Jesucristo ven sin tardar",
        "number": "218",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["A Jesucristo ven sin tardar,", "que entre nosotros hoy él está;", "y te convida con dulce afán,", "tierno diciendo: \"Ven\"."], ["Piensa que él solo puede colmar", "tu triste pecho de gozo y paz;", "y porque anhela tu bienestar,", "vuelve a decirte: \"Ven\"."], ["Su voz escucha sin vacilar,", "y grato acepta lo que hoy te da.", "Tal vez mañana no habrá lugar.", "No te detengas; ven."]],
        "refrainFirst": false,
        "refrain": ["¡Oh, cuán grata nuestra reunión,", "cuando allá, Señor, en tu mansión,", "contigo estemos en comunión", "gozando eterno bien!"]
      }, {
        "name": "Tan triste y tan lejos de Dios",
        "number": "219",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Tan triste y tan lejos de Dios me sentí", "y sin el perdón de Jesús.", "Mas cuando su voz amorosa oí", "que dijo: \"Oh, ven a la luz\"."], ["¡Qué amigo tan dulce es el tierno Jesús!", "¡Tan lleno de paz y de amor!", "De todo este mundo es la fúlgida luz", "el nombre del buen Salvador."], ["De mi alma el anhelo por siempre será", "más cerca vivir de la cruz,", "do santo poder y pureza me da", "la sangre de Cristo Jesús."], ["¡Oh! ven a Jesús, infeliz pecador;", "no vagues a ciegas ya más.", "Sí, ven a Jesús, tu benigno Señor,", "que en él salvación hallarás."]],
        "refrainFirst": false,
        "refrain": ["Yo todo dejé para andar en la luz,", "no moro en tinieblas ya más;", "encuentro la paz en seguir a Jesús", "y vivo en la luz de su faz."]
      }, {
        "name": "Allá la puerta abierta está",
        "number": "220",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Allá la puerta abierta está,", "su luz es refulgente.", "La cruz se mira más allá,", "señal de amor ferviente."], ["Si tienes fe avanza tú;", "la puerta es franca ahora.", "Si quieres palma, ten la cruz,", "señal de eterna gloria."], ["Pasando el río, más allá,", "en celestial pradera,", "con Cristo gozo y paz habrá,", "¡eterna primavera!"]],
        "refrainFirst": false,
        "refrain": ["¡Oh, cuánto me ama Dios a mí!", "La puerta abierta está por mí,", "por mí, por mí.", "Sí, quiero entrar allí."]
      }, {
        "name": "Puertas abiertas encontrarán",
        "number": "221",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Puertas abiertas encontrarán, unos sí, otros no;", "de alguien las glorias sin fin serán.", "¿Y tú? ¿y yo? ¿Y tú? ¿y yo?", "Calles de oro, mar de cristal, pleno reposo, perfecto amor.", "Unos tendrán celestial hogar:", "¿Y tú? ¿y yo? ¿Y tú? ¿y yo?"], ["Fieles discípulos de Jesús, unos sí, otros no,", "logran corona en vez de cruz.", "¿Y tú? ¿y yo? ¿Y tú? ¿y yo?", "Mora el Rey en gloriosa luz, con él no puede haber dolor,", "de alguien es esta beatitud:", "¿Y tú? ¿y yo? ¿Y tú? ¿y yo?"], ["Puertas cerradas encontrarán, unos sí, otros no;", "\"No te conozco\", escucharán.", "¿Y tú? ¿y yo? ¿Y tú? ¿y yo?", "Ciegos y sordos, no creen hoy, tarde sabrán de su grande error,", "el que desdeñan será su Juez:", "¿Y tú? ¿y yo? ¿Y tú? ¿y yo?"], ["Son herederos del porvenir, unos sí, otros no;", "los que procuran por Dios vivir.", "¿Y tú? ¿y yo? ¿Y tú? ¿y yo?", "Cuando concluya la dura lid, en compañía del Salvador", "alguien será sin cesar feliz:", "¿Y tú? ¿y yo? ¿Y tú? ¿y yo?"]]
      }, {
        "name": "Del trono celestial",
        "number": "222",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Del trono celestial al mundo descendí,", "sed y hambre padecí cual mísero mortal.", "Y todo fue por ti, por ti.", "¿Qué has hecho tú por mí?", "Y todo fue por ti, por ti.", "¿Qué has hecho tú por mí?"], ["Por darte la salud en cruenta cruz morí;", "tu sustituto fui en dura esclavitud.", "Y todo fue por ti, por ti.", "¿Qué has hecho tú por mí?", "Y todo fue por ti, por ti.", "¿Qué has hecho tú por mí?"], ["Del Padre celestial, completa bendición,", "eterna salvación, delicia perennal", "te doy de gracia a ti, a ti.", "¿Y huyes tú de mí?", "Te doy de gracia a ti, a ti", "¿Y huyes tú de mí?"], ["Los lazos de Satán quebranta, pecador,", "y el néctar de mi amor tus labios probarán.", "No dudes, ven a mí, a mí.", "¡Jesús, me rindo a ti!", "No dudes, ven a mí, a mí.", "¡Jesús, me rindo a ti!"]]
      }, {
        "name": "Oí la voz del Salvador",
        "number": "223",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Oí la voz del Salvador decir con tierno amor:", "\"¡Oh, ven a mí, descansarás, cargado pecador!\"", "Tal como estaba, a mi Jesús, cansado acudí,", "y luego dulce alivio y paz por fe de él recibí."], ["Oí la voz del Salvador decir: \"Venid, bebed;", "yo soy la fuente de salud que apaga toda sed\".", "Con sed de Dios, del vivo Dios, busqué a Emanuel;", "lo hallé; mi sed él apagó, y ahora vivo en él."], ["Oí su dulce voz decir: \"Del mundo soy la luz;", "miradme a mí y salvos sed; hay vida en mi cruz\".", "Mirando a Cristo, por la fe, mi norte y sol hallé;", "y en esa luz de vida, en él por siempre viviré."]]
      }, {
        "name": "Oí la voz del buen Jesús",
        "number": "224",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Oí la voz del buen Jesús: \"Sígueme, sígueme, sígueme.", "Te guiaré a eterna luz. Sígueme, sígueme, sígueme.", "Por ti la Ley toda cumplí, por ti la amarga hiel bebí,", "por ti la muerte cruel sufrí. Sígueme, sígueme, sígueme\"."], ["\"Oh, deja atrás el vil pecado. Sígueme, sígueme, sígueme.", "Hogar celeste he preparado. Sígueme, sígueme, sígueme.", "Oh, cuántas veces te llamé, y quebraste tú mi santa Ley,", "mas fiador por ti quedé. Sígueme, sígueme, sígueme\"."], ["\"En mí tú puedes descansar. Sígueme, sígueme, sígueme.", "Tus penas ven a entregar. Sígueme, sígueme, sígueme.", "Yo soy tu Dios, tu Salvador; y te amo mucho, pecador.", "Ya deja todo tu temor. Sígueme, sígueme, sígueme\"."], ["Sí, mi Jesús, te seguiré. Seguiré, seguiré, seguiré.", "Por ti yo todo dejaré. Dejaré, dejaré, dejaré.", "Muy débil soy y sin valor; sin ti no puedo andar, Señor.", "Mas lléname de tu vigor. Seguiré, seguiré, seguiré."]]
      }, {
        "name": "Un hombre llegose de noche a Jesús",
        "number": "225",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Un hombre llegose de noche a Jesús,", "buscando la senda de vida y luz;", "mas Cristo le dijo: \"Si a Dios quieres ver,", "tendrás que renacer\"."], ["Y tú, si en el cielo quisieres entrar", "y con los benditos allí descansar;", "si vida eterna quisieres tener,", "tendrás que renacer."], ["Amigo, no debes jamás desechar", "palabras que Cristo dignose hablar;", "si tú no deseas el cielo perder,", "tendrás que renacer."], ["Hay quienes Jesús ha de resucitar,", "los cuales querrás aquel día encontrar;", "pues este mensaje hoy debes creer:", "Tendrás que renacer."]],
        "refrainFirst": false,
        "refrain": ["\"Tendrás que renacer,", "tendrás que renacer;", "de cierto, de cierto te digo a ti:", "Tendrás que renacer\"."]
      }, {
        "name": "Buscad primero",
        "number": "226",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Buscad primero el reino de Dios", "y su perfecta justicia;", "y lo demás añadido será.", "Alelu, aleluya."], ["No solo de pan el hombre vivirá,", "sino de toda palabra", "que sale de la boca de Dios.", "Alelu, aleluya."], ["Pedid, pedid, y se os dará;", "buscad, buscad, y hallaréis;", "llamad, llamad, y se os abrirá.", "Alelu, aleluya."]]
      }, {
        "name": "Preste oídos el humano",
        "number": "227",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Preste oídos el humano a la voz del Salvador,", "regocíjese el que siente el pecado abrumador.", "Ya resuena el evangelio de la tierra en la ancha faz", "y de gracia ofrece al hombre el perdón, consuelo y paz."], ["Vengan cuantos se acongojan por lograr con qué vivir,", "y en su afán tan solo rinden servidumbre hasta morir.", "Hay vestido más precioso, blanco, puro y eternal;", "es Jesús quien da a las almas ese manto celestial."], ["Vengan todos los que sufran, los que sientan hambre y sed,", "los que débiles se encuentren, de este mundo a la merced.", "En Jesús hay pronto auxilio, hay hartura y bienestar;", "hay salud y fortaleza cual ninguno puede dar."], ["¿Por qué en rumbo siempre incierto vuestra vida recorréis?", "A Jesús venid, mortales, que muy cerca le tenéis.", "Él es vida en cielo y tierra, y la riqueza de su amor", "os mejora la presente y os reserva otra mejor."]]
      }, {
        "name": "Me buscaréis y me hallaréis",
        "number": "228",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Me buscaréis y me hallaréis,", "porque me buscaréis", "de todo vuestro corazón.", "Me buscaréis y me hallaréis,", "porque me buscaréis", "de todo corazón."]]
      }, {
        "name": "¿Has pensado lo que puede costar?",
        "number": "229",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Cuando llegue el momento de tu decisión", "no lo dejes por alto pasar;", "si lo haces, estás en peligro mortal.", "¿Has pensado lo que puede costar?"], ["La inmortal dicha tú puedes sacrificar", "por andar en la senda del mal.", "Si al Espíritu ahogas, te puedes perder.", "¿Has pensado lo que te ha de costar?"], ["Él te llama aún en su grande amor,", "todavía te invita a entrar.", "¿No te dejas salvar? ¿No le dices: \"Ya voy,", "he contado lo que puede costar\"?"]],
        "refrainFirst": false,
        "refrain": ["El pecado te hace tu alma perder.", "Aunque el mundo se postre a tus pies,", "puede ser que la puerta cerrándose esté.", "¿Has pensado lo que puede costar?"]
      }, {
        "name": "Abre tu corazón",
        "number": "230",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Abre tu corazón, abre tu corazón.", "Jesús entrará, y él te salvará", "si abres tu corazón."], ["Abro mi corazón, abro mi corazón.", "Ven, entra, Jesús; dame gozo y luz,", "te abro mi corazón."]]
      }, {
        "name": "Todo en el altar",
        "number": "231",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["¿Anhelaste la paz y crecer en la fe?", "¿bendiciones de Cristo obtener?", "Todo esto tendrás cuando rindas tu ser", "por completo a Jesús en su altar."], ["Si caminas con Dios en la luz de verdad,", "tendrás paz y poder al andar.", "Vivirás, pues, feliz; gozarás libertad;", "si tu vida está en el altar."], ["¿Quién podrá de su amor la grandeza contar?", "¡Cuán feliz con tu Dios estarás!", "Pues te llama el Pastor con su voz celestial,", "pon tu vida entera en su altar."]],
        "refrainFirst": false,
        "refrain": ["¿Entregaste tu vida entera a Dios?", "¿Le diste tu fiel corazón?", "Él te bendecirá y te hará descansar,", "y su paz te dará una canción."]
      }, {
        "name": "Entrégate en oración",
        "number": "232",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Puedes hoy con Cristo a solas conversar,", "él te espera con sincero amor;", "todas tus lágrimas puede consolar.", "Entrégate en oración."]]
      }, {
        "name": "Ven a la Fuente de vida",
        "number": "233",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Ven a la Fuente de vida,", "ven al amante Jesús;", "paz y perdón te ofrece.", "Ven, pues, al pie de la cruz."], ["Ven al Señor, ven ahora:", "consuelo y paz hallarás;", "vida de gozo y de calma", "en tu Maestro tendrás."], ["Tal como estás, ven ahora,", "tráele tu carga a él;", "oye su voz que te implora;", "Cristo Jesús siempre es fiel."], ["¡Oh, cuán preciosa promesa", "te hace tu buen Salvador!", "Vida tendrás para siempre", "junto a tu Dios y Señor."]],
        "refrainFirst": false,
        "refrain": ["Ven a Jesús, ven a Jesús,", "ven a los pies de la cruz sin tardar;", "paz y perdón te ofrece.", "Ven, no le dejes pasar."]
      }, {
        "name": "¿Temes que en la lucha?",
        "number": "234",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["¿Temes que en la lucha no podrás vencer?,", "¿que con las tinieblas has de contender?", "Abre, pues, la puerta de tu corazón,", "deja al Salvador entrar."], ["¿Es tu fe muy débil en la oscuridad?", "¿Son tus fuerzas pocas contra la maldad?", "Abre, pues, la puerta de tu corazón,", "deja al Salvador entrar."], ["¿Quieres ir gozándote en la senda aquí?", "¿Quieres que el Señor te utilice a ti?", "Abre, pues, la puerta de tu corazón,", "deja al Salvador entrar."]],
        "refrainFirst": false,
        "refrain": ["Deja al Salvador entrar,", "deja al Salvador entrar;", "abre, pues, la puerta de tu corazón,", "y entrará el Salvador."]
      }, {
        "name": "La razón de vivir",
        "number": "235",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Si no puedes luchar, si no sientes hoy paz,", "si en tu vida no encuentras sentido,", "si todo lo que haces te parece sin motivo,", "si no ves una razón para existir.", "Alguien hay que te ve, te conoce muy bien,", "alguien puede llenar tu vacío:", "es Jesús, quiere caminar contigo;", "solo tú se lo puedes permitir.", "En Jesús encontré la razón de creer,", "la razón de vivir y ser feliz;", "nada hay, nada existe, nada llenará tu ser,", "como Jesús, tu Amigo y Salvador."], ["Hoy yo puedo confiar, y mi vida entregar,", "hoy yo puedo hallar el camino;", "es Jesús, quien a salvarme vino;", "es Jesús a quien hoy podré sentir.", "En Jesús encontré la razón de creer,", "la razón de vivir y ser feliz;", "nada hay, nada existe, nada llenará tu ser,", "como Jesús, tu Amigo y Salvador.", "Mi buen Jesús, te doy mi corazón."]]
      }, {
        "name": "A Jesús entrega todo",
        "number": "236",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Has corrido tras un arco iris,", "y ahora muy cansado estás.", "Toma todos esos sueños rotos,", "y a los pies de Cristo ponlos ya."], ["Él no prometió sol todos los días;", "sé que a veces viene la tormenta.", "Pero nos ofrece un corazón nuevo,", "que cante aún en medio del dolor."]],
        "refrainFirst": false,
        "refrain": ["A Jesús, a Jesús, a Jesús entrega todo:", "sueños rotos y heridas del corazón.", "A Jesús, a Jesús, a Jesús entrega todo;", "y la tristeza en gozo cambiará."]
      }, {
        "name": "Jesús hoy espera entrar en tu ser",
        "number": "237",
        "category": "El evangelio",
        "subcategory": "Invitación",
        "verses": [["Jesús hoy espera entrar en tu ser,", "ábrele tu corazón.", "No dejes que el mundo te aparte de él,", "¿cuál será tu decisión?"], ["Si das este paso hacia Cristo el Señor,", "tierno te recibirá.", "Acéptalo y deja la oscuridad;", "él morará en tu ser."]],
        "refrainFirst": false,
        "refrain": ["Cada momento esperando está,", "y ahora te llama otra vez.", "Si le abres la puerta de tu corazón,", "él será tu Salvador."]
      }, {
        "name": "Yo escucho, buen Jesús",
        "number": "238",
        "category": "El evangelio",
        "subcategory": "Arrepentimiento",
        "verses": [["Yo escucho, buen Jesús, tu dulce voz de amor,", "que, desde el árbol de la cruz, invita al pecador.", "Yo soy pecador, nada hay bueno en mí;", "ser objeto de tu amor deseo y vengo a ti."], ["Tú ofreces el perdón de toda iniquidad,", "si el llanto inunda el corazón que acude a tu piedad.", "Yo soy pecador, ten de mí piedad;", "ve mi llanto de dolor y borra mi maldad."], ["Prometes aumentar la fe del que creyó,", "y gracia sobre gracia dar a quien en ti confió.", "Creo en ti, Señor, solo fío en ti;", "dame tu infinito amor, y basta para mí."]]
      }, {
        "name": "De Dios vagaba lejos yo",
        "number": "239",
        "category": "El evangelio",
        "subcategory": "Arrepentimiento",
        "verses": [["De Dios vagaba lejos yo,", "vuelvo hoy a ti;", "por sendas donde el mal reinó,", "vuelvo hoy a ti."], ["Cansado de pecar estoy,", "vuelvo hoy a ti;", "en ti espero desde hoy,", "vuelvo hoy a ti."], ["Confío solo en esto yo,", "vuelvo hoy a ti:", "en que Jesús por mí murió;", "vuelvo hoy a ti."], ["Limpiarme puedes, yo lo sé,", "vuelvo hoy a ti;", "pues, en tu sangre lávame,", "vuelvo hoy a ti."]],
        "refrainFirst": false,
        "refrain": ["Ya no más, oh Señor,", "voy errando así;", "a los brazos de tu amor,", "Cristo, vuelvo, oh sí."]
      }, {
        "name": "Te ruego, oh Dios",
        "number": "240",
        "category": "El evangelio",
        "subcategory": "Arrepentimiento",
        "verses": [["Te ruego, oh Dios: ¡Escúchame a mí!,", "¡ven, gran Libertador!,", "pues agobiado, anhelo ir a ti.", "¡ven, gran Libertador!"], ["No puedo abrigo en noche oscura hallar,", "¡ven, gran Libertador!,", "mas luz y vida tú me puedes dar,", "¡ven, gran Libertador!"], ["Atormentado, sin descanso y paz,", "¡ven, gran Libertador!,", "los ojos alzo a tu benigna faz,", "¡ven, gran Libertador!"], ["Mi voz contrita tú no desoirás,", "¡ven, gran Libertador!,", "mi humilde ruego pronto atenderás,", "¡ven, gran Libertador!"]],
        "refrainFirst": false,
        "refrain": ["Por áridas montañas yo vagué,", "muy lejos de mi buen Pastor.", "¡Oh, sálvame y llévame al redil,", "ven, gran Libertador!"]
      }, {
        "name": "Perdón te ruego, mi Señor y Dios",
        "number": "241",
        "category": "El evangelio",
        "subcategory": "Arrepentimiento",
        "verses": [["Si fui motivo de dolor, oh Cristo,", "si por mi causa el débil tropezó;", "si en tus pisadas caminar no quise,", "perdón te ruego, mi Señor y Dios."], ["Si vana y fútil mi palabra ha sido,", "si al que sufría en su dolor dejé;", "si egoísta fui en mis motivos,", "perdón te ruego, mi Señor y Dios."]],
        "refrainFirst": false,
        "refrain": ["Escucha, oh Dios, mi confesión humilde", "y líbrame de tentación sutil.", "Preserva siempre mi alma en tu rebaño.", "Perdón te ruego, mi Señor y Dios."]
      }, {
        "name": "Una es, Señor, mi petición",
        "number": "242",
        "category": "El evangelio",
        "subcategory": "Arrepentimiento",
        "verses": [["Una es, Señor, mi petición,", "pues en mi senda infiel erré:", "sea por agua o en crisol,", "¡oh, límpiame!, ¡oh, límpiame!"], ["Si al alma das más luz, Señor,", "agradecido cantaré;", "pero de un puro corazón", "más gozaré, más gozaré."], ["Solo en el limpio corazón", "podrá tu faz resplandecer", "y ser completa la visión", "de tu poder, de tu poder."], ["Quiero mi senda enderezar,", "libre del mal ser quiero hoy;", "pero sin ti vano es luchar,", "indigno soy, indigno soy."]],
        "refrainFirst": false,
        "refrain": ["¡Oh, límpiame de mi maldad", "en tu crisol, si he menester!", "No importa el medio, oh Señor,", "¡oh, límpiame!, ¡oh, límpiame!"]
      }, {
        "name": "Entrego todo a Cristo",
        "number": "243",
        "category": "El evangelio",
        "subcategory": "Arrepentimiento",
        "verses": [["Todo mi vil pecado", "dejo ante mi Jesús;", "él lleva toda carga,", "liberación me da,", "liberación me da."], ["Mis culpas traigo a Cristo,", "vengo a lavar mi mal;", "en su preciosa sangre,", "cual nieve quedará,", "cual nieve quedará."], ["Le entrego mi miseria,", "su plenitud me da;", "él sana mi alma enferma,", "y redención me da,", "y redención me da."], ["Yo quiero ser cual Cristo,", "con humildad vivir;", "yo anhelo estar con Cristo,", "siempre vivir con él.", "siempre vivir con él."]]
      }, {
        "name": "Padre, Dios",
        "number": "244",
        "category": "El evangelio",
        "subcategory": "Arrepentimiento",
        "verses": [["Padre, Dios, estoy pensando en ti.", "Ya no soy el mismo de ayer.", "Pero sé, me reconocerás.", "Padre, Dios, hoy me acordé de ti."], ["Padre, Dios, estoy volviendo a ti;", "siento que tú me recibirás.", "Creo que aún me perdonarás.", "Padre, Dios, mi senda va hacia ti."], ["Padre, Dios, mis bienes los perdí;", "mírame como un siervo más.", "Lleno estoy de indignidad y mal.", "Padre, Dios, estás viniendo a mí."]],
        "refrainFirst": false,
        "refrain": ["Buen Señor, decido regresar,", "porque sé que tú estarás allí.", "Veo hoy lo que antes no entendí;", "no estaré más lejos del hogar."]
      }, {
        "name": "Cúmplase, oh Cristo, tu voluntad",
        "number": "245",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Cúmplase, oh Cristo, tu voluntad.", "Solo tú puedes mi alma salvar.", "Cual alfarero, para tu honor", "vasija útil hazme, Señor."], ["Cúmplase, oh Cristo, tu voluntad.", "Quita de mi alma toda maldad.", "Cual blanca nieve hazla fulgir,", "y fiel y humilde hazme vivir."], ["Cúmplase, oh Cristo, tu voluntad.", "Toda dolencia puedes sanar;", "cuitas, pesares, con tu poder", "quieres hacerlos desvanecer."], ["Cúmplase, oh Cristo, tu voluntad.", "Mora en mi alma, dame tu paz,", "para que el mundo vea tu amor,", "tu obra perfecta, buen Salvador."]]
      }, {
        "name": "Te quiero, mi Señor",
        "number": "246",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Te quiero, mi Señor;", "habita en mí,", "y vencedor seré", "por fe en ti."], ["Te quiero, ¡oh Jesús!,", "mi Salvador.", "¡Oh!, hazme en verdad", "tu servidor."], ["Tu voluntad, Señor,", "enséñame;", "y de tu gran amor,", "¡oh!, cólmame."], ["¡Oh! mi gran Bienhechor,", "en tentación", "concédeme valor", "y protección."]],
        "refrainFirst": false,
        "refrain": ["Te quiero, sí, te quiero;", "siempre te anhelo;", "bendíceme, te ruego;", "acudo a ti."]
      }, {
        "name": "Yo te seguiré",
        "number": "247",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Yo te seguiré, ¡Oh Cristo!,", "dondequiera que estés;", "donde tú me guíes, sigo;", "sí, Señor, te seguiré."], ["Aunque duro el camino,", "sin señales y sin luz,", "seguiré siempre confiado", "en las huellas de Jesús."], ["Afligido, agotado,", "débil, lleno de dolor,", "regocíjome, pues ando", "en las huellas del Señor."], ["Si me guías al gran río", "del Jordán, no temeré;", "has pasado tú su frío,", "y gozoso seguiré."]],
        "refrainFirst": false,
        "refrain": ["Yo te seguiré, ¡oh Cristo!", "Tú moriste para mí.", "Aunque todos te negaren,", "yo, Señor, te seguiré."]
      }, {
        "name": "Que mi vida entera esté",
        "number": "248",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Que mi vida entera esté", "consagrada a ti, Señor;", "que a mis manos pueda guiar", "el impulso de tu amor."], ["Que mis labios, al hablar,", "hablen solo de tu amor;", "que mis bienes ocultar", "no los pueda a ti, Señor."], ["Que mi tiempo todo esté", "consagrado a tu loor,", "y mi mente y su poder", "pueda emplearlos en tu honor."], ["Toma, oh Dios, mi voluntad,", "y hazla tuya, nada más,", "y este pobre corazón;", "y tu trono en él tendrás."]],
        "refrainFirst": false,
        "refrain": ["Lávame en la sangre del Señor,", "límpiame de toda mi maldad;", "ríndote mi vida; hazla pues, Señor,", "tuya por la eternidad."]
      }, {
        "name": "Tal como soy",
        "number": "249",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Tal como soy de pecador,", "sin otra fianza que tu amor,", "a tu llamado vengo a ti,", "Cordero de Dios, heme aquí."], ["Tal como soy, buscando paz,", "en mi desgracia y mal tenaz,", "combate rudo siento en mí", "Cordero de Dios, heme aquí."], ["Tal como soy, con mi maldad,", "miseria, pena y ceguedad,", "pues hay remedio pleno en ti,", "Cordero de Dios, heme aquí."], ["Tal como soy, me aceptarás;", "perdón y alivio me darás,", "pues tu promesa ya creí,", "Cordero de Dios, heme aquí."]]
      }, {
        "name": "Padre, a tus pies me postro",
        "number": "250",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Padre, a tus pies me postro;", "rompe mis prisiones duras;", "oh, responde mientras llamo;", "pon tu Espíritu en mí."], ["Mientras Cristo intercede,", "mientras oro yo humilde,", "lo que necesito dame;", "pon tu Espíritu en mí."], ["No deseo ofenderte,", "viviré para agradarte", "y en el corazón guardarte;", "pon tu Espíritu en mí."]],
        "refrainFirst": false,
        "refrain": ["Pon tu Espíritu en mi alma;", "hazme lo que ser debiera;", "hazme puro en todo,", "libre del pecado;", "pon tu Espíritu en mí."]
      }, {
        "name": "No yo, sino él",
        "number": "251",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["No yo, sino él, reciba amor y honra;", "no yo, sino él, en mí ha de reinar;", "no yo, sino él, en todo cuanto haga;", "no yo, sino él, en todo mi pensar."], ["No yo, sino él, a confortar mis penas;", "no yo, sino él, mis llantos a enjugar;", "no yo, sino él, a aligerar mis cargas,", "no yo, sino él, mi duda a disipar."], ["Jesús, no más diré palabra ociosa;", "Jesús, no más, quisiera yo pecar;", "Jesús, no más, me venza el orgullo;", "Jesús, no más, inspire el \"yo\" mi hablar."], ["No yo, sino él, lo que me falta suple;", "no yo, sino él, da fuerza y sanidad;", "Jesús a ti, mi espíritu, alma y cuerpo,", "lo rindo hoy por la eternidad."]]
      }, {
        "name": "Dejo el mundo",
        "number": "252",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Dejo el mundo y sigo a Cristo,", "pues el mundo pasará;", "mas el tierno amor divino", "por los siglos durará."], ["Dejo el mundo y sigo a Cristo.", "Mi alma en él consolaré.", "Sé que él vela mis pisadas;", "su bondad ensalzaré."], ["Dejo el mundo y sigo a Cristo,", "mi benigno Salvador;", "en mis peregrinaciones", "brille en mí su resplandor."], ["Dejo el mundo y sigo a Cristo.", "Confiaré yo en su cruz", "hasta que sin velo mire", "cara a cara a mi Jesús."]],
        "refrainFirst": false,
        "refrain": ["¡Oh, qué amor inmensurable!", "¡Qué clemencia, qué bondad!", "¡Oh, la plenitud de gracia,", "prenda de inmortalidad!"]
      }, {
        "name": "Tuyo soy, Jesús",
        "number": "253",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Tuyo soy, Jesús, pues oí tu voz", "que en amor llamome a mí;", "mas anhelo en alas de fe subir,", "y más cerca estar de ti."], ["A seguirte a ti me consagro hoy,", "motivado por tu amor;", "y mi espíritu, alma y cuerpo doy", "por servirte, mi Señor."], ["¡Oh, cuán pura y santa delicia es", "de tu comunión gozar;", "conversar contigo y tu dulce voz", "cada día escuchar!"], ["De tu gran amor no comprenderé", "cuál es la profundidad,", "hasta que contigo Jesús, esté", "en gloriosa eternidad."]],
        "refrainFirst": false,
        "refrain": ["Aún más cerca, cerca de tu cruz,", "llévame, oh Salvador;", "aún más cerca, cerca, cerca de la luz", "viva yo, ¡oh buen Pastor!"]
      }, {
        "name": "Anhelo ser limpio",
        "number": "254",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Anhelo ser limpio y completo, Jesús;", "que mores en mi alma en tu fúlgida luz.", "Mis ídolos rompe, los que antes amé.", "¡Oh!, lávame y blanco cual nieve seré."], ["¡Oh, mírame desde tu trono de amor!", "Haz mi sacrificio completo, Señor.", "Te quiero rendir cuanto soy, cuanto sé.", "Pues lávame y blanco cual nieve seré."], ["Jesús, te suplico, postrado a tus pies,", "tu propia, perfecta justicia me des.", "Tu sangre expiatoria, la veo por fe.", "¡Oh!, lávame y blanco cual nieve seré."], ["Ve cómo paciente te espero aquí.", "Un corazón nuevo pon dentro de mí.", "Jamás rechazaste al que a ti fue.", "Pues lávame y blanco cual nieve seré."]],
        "refrainFirst": false,
        "refrain": ["Que solo así ser limpio podré.", "¡Oh!, lávame tú, y cual nieve seré."]
      }, {
        "name": "Oh Cristo, te adoro",
        "number": "255",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Oh Cristo, te adoro, te acepto por fe;", "por ti los caminos del mal ya dejé;", "de gracia salvaste mi alma, Señor;", "por esto de hinojos te rindo mi amor."], ["Me viste perdido y en condenación,", "y desde el Calvario me diste perdón;", "llevaste por mí las espinas, Señor;", "por esto de hinojos te rindo mi amor."], ["En todo momento, Jesús, te amaré;", "y mientras yo viva de ti cantaré.", "En valle de muerte serás mi Pastor;", "por esto de hinojos te rindo mi amor."], ["Después, en mansiones de luz celestial,", "de gozo inefable, de gloria eternal,", "darasme corona brillante, Señor;", "por esto de hinojos te rindo mi amor."]]
      }, {
        "name": "Jesús, yo he prometido",
        "number": "256",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Jesús, yo he prometido servirte con amor;", "concédeme tu gracia, mi amigo y Salvador.", "No temeré la lucha si tú a mi lado estás,", "ni perderé el camino si tú alumbrando vas."], ["El mundo está acechando y abunda en tentación,", "sutil es el engaño y loca la pasión;", "acércate, Maestro, revela tu piedad", "y escuda, fiel, mi alma de toda iniquidad."], ["Si ves mi mente errando del necio mal en pos,", "concédeme que escuche, Señor, tu clara voz.", "Aliéntame en la lucha, mi espíritu sostén,", "confórtame si temo, impúlsame en el bien."], ["Jesús, tú has prometido a todo aquel que va", "siguiendo tus pisadas, que al cielo llegará.", "Sostenme en el camino, y al fin, con dulce amor,", "trasládame a tu gloria, mi amigo y Salvador."]]
      }, {
        "name": "¡Oh! ven, te invito, Cristo",
        "number": "257",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Al venir al mundo dejaste atrás", "tu corona y majestad.", "Pero solo un pobre pesebre aquí", "te albergó aunque eras Rey."], ["Todo el cielo oyó el cántico sin par", "de los ángeles en Belén.", "Pero casi nadie te recibió,", "aunque eras el Salvador."], ["En las cuevas duermen las zorras bien", "y las aves al nido van.", "Pero tú no hallaste do reposar,", "aunque eras el Creador."], ["Pronto la trompeta resonará,", "victorioso vendrás, Señor.", "Con alegre voz tú me llamarás", "a vivir siempre junto a ti."]],
        "refrainFirst": false,
        "refrain": ["[1-3] ¡Oh! ven, te invito, Cristo,", "a morar en mi corazón.", "[4] ¡Qué gozo tendré aquel día", "cuando vuelvas, Jesús, por mí!"]
      }, {
        "name": "Tú dejaste tu trono",
        "number": "258",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Tú dejaste tu trono y corona por mí", "al venir a Belén a nacer;", "mas a ti no fue dado el entrar en mesón,", "y en pesebre te hicieron nacer."], ["En el cielo las huestes gloriosas te dan", "alabanza y seráfico honor,", "mas humilde viniste a la tierra a sufrir", "por salvar al más vil pecador."], ["Tú viniste, Señor, con tu gran bendición,", "para dar libertad y salud;", "mas con saña furiosa te hicieron morir", "aunque vieron tu amor y virtud."], ["Alabanzas sublimes los cielos darán", "cuando vengas glorioso de allí,", "y tu voz entre nubes dirá: \"Ven a mí,", "que a mi lado hay lugar para ti\"."]],
        "refrainFirst": false,
        "refrain": ["Ven a mi corazón, oh Cristo,", "pues en él hay lugar para ti.", "Ven a mi corazón, oh Cristo, ven,", "pues en él hay lugar para ti."]
      }, {
        "name": "Mi espíritu, alma y cuerpo",
        "number": "259",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Mi espíritu, alma y cuerpo,", "mi ser, mi vida entera,", "cual viva, santa ofrenda", "te entrego a ti, mi Dios."], ["Soy tuyo, Jesucristo,", "comprado con tu sangre;", "haz que contigo ande", "en plena comunión."], ["Espíritu divino,", "del Padre la promesa,", "sedienta, mi alma anhela", "de ti la santa unción."]],
        "refrainFirst": false,
        "refrain": ["Mi todo a Dios consagro", "en Cristo, el vivo altar.", "¡Descienda el fuego santo,", "su sello celestial!"]
      }, {
        "name": "Junto a la cruz de Cristo",
        "number": "260",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Junto a la cruz de Cristo anhelo siempre estar,", "refugio y sombra en la Roca puedo encontrar.", "En medio del desierto aquí, allí encuentro hogar", "do del calor y del trajín yo puedo descansar."], ["Bendita cruz de Cristo, a veces veo en ti", "la misma forma en fiel visión del que sufrió por mí;", "hoy mi contrito corazón confiesa la verdad", "de tu asombrosa redención y de mi indignidad."], ["Oh, Cristo, en ti he hallado completa y dulce paz;", "no busco bendición mayor que la de ver tu faz;", "sin atractivo el mundo está, pues ando en tu luz;", "avergonzado de mi mal, mi gloria es ya la cruz."]]
      }, {
        "name": "Salvador, a ti me rindo",
        "number": "261",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Salvador, a ti me rindo,", "obedezco sólo a ti.", "Mi Guiador, mi Fortaleza,", "todo encuentro, oh Cristo, en ti."], ["Te confiesa su delito", "mi contrito corazón.", "Oye, Cristo, mi plegaria;", "quiero en ti tener perdón."], ["A tus pies, Señor, entrego", "bienes, goces y placer.", "Que tu Espíritu me llene,", "y de ti sienta poder."], ["¡Oh, qué gozo encuentro en Cristo!", "¡Cuánta paz a mi alma da!", "A su causa me consagro,", "y su amor mi amor será."]],
        "refrainFirst": false,
        "refrain": ["Yo me rindo a ti,", "yo me rindo a ti;", "mis flaquezas,", "mis pecados,", "todo rindo a ti."]
      }, {
        "name": "Los tesoros del mundo",
        "number": "262",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Los tesoros del mundo no deseo juntar.", "Quiero entrar en tu aprisco, en tu célico hogar.", "En el libro del reino que en los cielos está,", "dime, Cristo benigno, ¿se halla mi nombre allá?"], ["Mis pecados son muchos cual la arena del mar;", "mas, Jesús, en tu sangre, me los puedes lavar.", "He aquí tus promesas, las que escritas están:", "\"Aunque rojos cual grana como nieve serán\"."], ["En la bella ciudad con sus mansiones de luz,", "do los santificados andarán con Jesús,", "donde el mal no entra nunca, donde el bien reinará,", "dime, Cristo benigno, ¿se halla mi nombre allá?"]],
        "refrainFirst": false,
        "refrain": ["¿Se halla mi nombre allá?", "¿Se halla mi nombre allá?", "En el libro del reino", "¿se halla mi nombre allá?"]
      }, {
        "name": "Entra en este corazón",
        "number": "263",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Entra en este corazón,", "haz en mí tu habitación,", "sea yo tu posesión,", "mora en mí, oh Cristo."], ["Pon tu Espíritu en mí,", "hazme muy leal a ti.", "Quiero serte fiel aquí,", "mora en mí, oh Cristo."], ["Hoy me entrego a ti, Señor,", "para ser tu ayudador.", "Quiero proclamar tu amor,", "mora en mí, oh Cristo."]],
        "refrainFirst": false,
        "refrain": ["Mora en mí, oh Cristo,", "mora en mí, oh Cristo.", "Entra en este corazón,", "mora en mí, oh Cristo."]
      }, {
        "name": "Un día más por Cristo",
        "number": "264",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Un día más por Cristo, y menos por vivir;", "mas hoy el cielo, que tanto anhelo,", "más cerca está que ayer.", "Colmada mi alma está de luz y amor."], ["Un día más por Cristo, por mi glorioso Rey;", "pues mis deberes ya son placeres:", "su amor proclamaré.", "Jesús murió; mi vida así compró."], ["Un día más por Cristo; cuán grato es para mí", "contar la historia, mostrar la gloria", "del que me salva aquí.", "Mi corazón rebosa en bendición."], ["¡Oh, cuán bendita obra de Cristo mi Señor!", "Tras prueba dura, un alma pura", "me da su santo amor.", "Y esto sé: con Cristo reinaré."]],
        "refrainFirst": false,
        "refrain": ["Un día más por Cristo,", "un día más por Cristo,", "un día más por Cristo,", "y menos por vivir."]
      }, {
        "name": "La senda ancha dejaré",
        "number": "265",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["La senda ancha dejaré,", "yo quiero por la angosta andar,", "y muchos no sabrán por qué,", "mas voy a mi celeste hogar."], ["Algunos quieren verme ir", "por el sendero del pecar;", "oír no puedo su llamar,", "pues voy a mi celeste hogar."], ["¿Por qué no quieres tú buscar,", "siguiendo en pos del Salvador,", "la hermosa tierra más allá?", "¡Oh! ven conmigo, pecador."]],
        "refrainFirst": false,
        "refrain": ["No puede el mundo ser mi hogar,", "no puede el mundo ser mi hogar;", "en gloria tengo mi mansión,", "no puede el mundo ser mi hogar."]
      }, {
        "name": "Vivo por Cristo",
        "number": "266",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Vivo por Cristo, confiando en su amor,", "vida me imparte, poder y valor;", "grande es el gozo que tengo por él,", "es de mi senda Jesús guía fiel."], ["Vivo por Cristo, murió pues por mí;", "siempre servirle yo quisiera aquí;", "porque me ha dado tal prueba de amor", "quiero rendirme por siempre al Señor."], ["Vivo por Cristo, doquiera que esté;", "ya por su ayuda sus obras haré;", "pruebas hoy llevo con gozo y amor,", "pues veo en ellas la cruz del Señor."]],
        "refrainFirst": false,
        "refrain": ["¡Oh, Salvador bendito!,", "me doy tan solo a ti,", "porque tú en el Calvario", "te diste allí por mí;", "no tengo más Maestro,", "yo fiel te serviré;", "a ti me doy pues tuyo soy,", "de mi alma eterno Rey."]
      }, {
        "name": "A la cruz de Cristo voy",
        "number": "267",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["A la cruz de Cristo voy.", "Débil, pobre y ciego soy.", "Mis riquezas nada son.", "Necesito salvación."], ["Cristo, a ti mi todo doy.", "Tiempo, amigos, cuanto soy,", "cuerpo y mente, tuyos son,", "en eterna posesión."], ["Tu promesa es mi salud.", "En tu sangre hallé virtud.", "Pecador me siento hoy,", "pero en Cristo salvo soy."], ["Yo confío en ti, Señor,", "mi bendito Salvador,", "y me postro ante tu cruz.", "¡Salva, oh sálvame, Jesús!"]]
      }, {
        "name": "Puedo oír tu voz llamando",
        "number": "268",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Puedo oír tu voz llamando,", "suavemente susurrando,", "que a mi alma está hablando:", "\"Trae tu cruz y ven en pos de mí\"."], ["Sufriré por ti, Maestro.", "Si el camino es siniestro", "tú serás refugio nuestro;", "moriré contigo, mi Jesús."], ["Me darás la gracia y gloria", "de obtener la gran victoria,", "y contar la dulce historia:", "que por mí Jesús su vida dio."]],
        "refrainFirst": false,
        "refrain": ["Seguiré do tú me guíes,", "seguiré do tú me guíes,", "seguiré do tú me guíes;", "dondequiera, fiel, te seguiré."]
      }, {
        "name": "Prefiero a mi Cristo",
        "number": "269",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Prefiero a mi Cristo al vano oropel;", "prefiero su gracia a riquezas sin fin.", "A casas y tierras prefiérole a él;", "será de mi alma fuerte paladín."], ["No quiero el aplauso del mundo falaz;", "prefiero en las filas de Cristo servir.", "La fama del mundo es liviana y fugaz;", "prefiero por siempre a Jesús seguir."], ["Más bello que el lirio en su níveo blancor,", "mi Cristo es más dulce aún que la miel.", "Su paz a mi alma dará el Señor;", "yo quiero que Cristo me conserve fiel."]],
        "refrainFirst": false,
        "refrain": ["Antes que ser rey de cualquier nación", "y en pecado gobernar,", "prefiero a mi Cristo, sublime don", "cual el mundo no ha de dar."]
      }, {
        "name": "Meditar en Jesús",
        "number": "270",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Meditar en Jesús ha de ser mi afán:", "me amó siendo yo un pecador;", "él ganó para mí la diadema celestial,", "en la cruz, do mostrome su amor."], ["Consultar a Jesús ha de ser mi afán,", "y Jesús me dará su clara luz.", "Sin Jesús, y por mí, nada quiero practicar;", "mis acciones inspire Jesús."], ["Predicar a Jesús ha de ser mi afán,", "y Jesús me dará fuerza y poder.", "Sin Jesús y su amor, vano fuera trabajar;", "de Jesús en la cruz, hablaré."], ["Imitar a Jesús ha de ser mi afán.", "En Jesús quiero mi modelo ver.", "Sin mirar a Jesús, nada bueno puedo hacer;", "mas fijándome en él, todo es bien."]],
        "refrainFirst": false,
        "refrain": ["Ven, ven, buen Jesús.", "Mora en mi corazón,", "lléname de santidad,", "pues contigo deseo andar."]
      }, {
        "name": "Hoy me llama el mundo en vano",
        "number": "271",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Hoy me llama el mundo en vano,", "quiero ser cual Cristo;", "ya no sirvo a lo mundano,", "quiero ser cual Cristo."], ["Mis cadenas Cristo ha roto,", "quiero ser cual Cristo;", "su servicio haré devoto,", "quiero ser cual Cristo."], ["Él al cielo va a llevarme,", "quiero ser cual Cristo;", "y corona quiere darme,", "quiero ser cual Cristo."]],
        "refrainFirst": false,
        "refrain": ["¡Ser como él de corazón!,", "es mi sola aspiración;", "en cualquiera condición", "quiero ser cual Cristo."]
      }, {
        "name": "De esclavitud",
        "number": "272",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["De esclavitud, de noche y pesar, vengo, Jesús, vengo, Jesús;", "tu libertad, tu luz a gozar, vengo, Jesús, a ti.", "De mi pobreza a tu plenitud, de mis dolores a tu salud,", "de mis pecados a tu virtud, vengo, Jesús, a ti."], ["De mi vergüenza y falta de luz, vengo, Jesús, vengo, Jesús;", "a tu justicia, al pie de la cruz, vengo, Jesús, a ti.", "De mi tristeza a consolación, de la tormenta a tu bendición,", "de la miseria a grata canción, vengo, Jesús, a ti."], ["De mi soberbio y vano vivir, vengo, Jesús, vengo, Jesús;", "siempre tu santa Ley a cumplir, vengo, Jesús, a ti.", "Porque me quitas todo temor, porque me das tu gozo, Señor,", "por tu perdón, rendido a tu amor, vengo, Jesús, a ti."], ["De la espantosa muerte al huir, vengo, Jesús, vengo, Jesús;", "a la alegría y luz del vivir, vengo, Jesús, a ti.", "Tú de la ruina me llevarás a tu redil, tu abrigo de paz;", "siempre a mirar tu gloria y tu faz, vengo, Jesús, a ti."]]
      }, {
        "name": "Tu vida, oh Salvador",
        "number": "273",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Tu vida, oh Salvador, diste aquí por mí;", "y nada quiero yo hoy negarte a ti.", "Rendida mi alma está, servirte ansía ya;", "quiero un tributo dar de amor a ti."], ["Al Padre sin cesar ruegas tú por mí;", "en mi debilidad yo confío en ti.", "Quiero mi cruz llevar, tu nombre declarar,", "y un canto entonar de amor a ti."], ["Ven a mi corazón, vive tú en mí;", "y cada día haré algo para ti.", "Al pobre algún favor, curar algún dolor,", "salvar a un pecador, por amor a ti."], ["Cuanto yo tengo y soy lo entrego a ti.", "En gozo o aflicción quiero serte fiel.", "Y cuando en Sion esté por siempre te daré", "loor con gratitud por amor a ti."]]
      }, {
        "name": "¿Qué te daré, Maestro?",
        "number": "274",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["¿Qué te daré, Maestro?", "Moriste tú por mí.", "¿Menos daré de lo que me das", "o todo te entregaré?"], ["¿Qué te daré, Maestro?", "Me redimiste tú.", "Poco es mi don, mas lo entregaré,", "Señor, bajo tu control."], ["¿Qué te daré, Maestro?", "Mi gran benefactor.", "Todo mi tiempo y bienes daré,", "a ti pertenecerán."]],
        "refrainFirst": false,
        "refrain": ["Mi Salvador Jesús,", "te diste tú por mí.", "Tu hogar dejaste allá", "para morir aquí.", "¿Qué te daré, Maestro?", "te diste tú por mí.", "No la mitad, mas todo mi ser,", "entregaré a ti."]
      }, {
        "name": "Humilde oración",
        "number": "275",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["En humilde oración", "quiero dedicar, Señor,", "mi indigno corazón", "por completo a ti.", "Hazme puro, santo y fiel,", "quiero obedecer tu Ley.", "Dame amor, bondad y fe,", "dame tu poder."]]
      }, {
        "name": "Con nuestras mentes",
        "number": "276",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Con nuestras mentes, oh Señor, queremos dar loor.", "Ven, Santo Espíritu de amor, verdad impártenos.", "Que al buscar tu voluntad, que en tu Palabra está,", "podamos escuchar, oh Dios, Tu clara y dulce voz."], ["Con nuestros cuerpos, oh Señor, queremos dar loor.", "Un templo somos para ti, do siempre anhelas vivir.", "Nos entregamos ante aquel que en una cruz murió.", "Nos consagramos a servir a nuestro Creador."], ["Con nuestro corazón, Señor, queremos dar loor.", "Mantennos puros para ver tus sendas por la fe.", "Nos inclinamos ante ti, te damos nuestro ser.", "Con mente, cuerpo y corazón queremos obedecer."]]
      }, {
        "name": "Amarte más",
        "number": "277",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Amarte más, Jesús, anhelo hoy;", "oye mi humilde voz, pues tuyo soy."], ["Del mundo el placer, antes busqué;", "mas por amor a ti, todo dejé."], ["En medio del dolor refinador,", "más cerca iré de ti, mi Salvador."], ["Y mientras viva aquí te serviré;", "pues, por la eternidad, te amaré."]],
        "refrainFirst": false,
        "refrain": ["Esta es mi oración:", "Quiero amarte más;", "amarte más,", "amarte más."]
      }, {
        "name": "¿Puede el mundo ver a Jesús en mí?",
        "number": "278",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["¿Vivo hoy tan cerca de mi Jesús,", "a pesar del mundo y su maldad,", "hasta el punto que en mí se pueda ver", "un reflejo de su luz?"], ["¿Amo yo a mi prójimo cual Jesús,", "no importa cuál sea su color?", "¿Y mi mano extiendo para ayudar", "al que lucha por ser fiel?"], ["Como un libro abierto mi vida es", "y en él las gentes mis actos ven:", "Si son buenos, los guiarán a él;", "y si no, las perderán."]],
        "refrainFirst": false,
        "refrain": ["¿Puede el mundo ver a Jesús en mí?", "¿Puede el mundo ver a Jesús en mí?", "¿Me comporto como él? Sus pisadas ¿sigo aquí?", "¿Puede el mundo ver a Jesús en mí?"]
      }, {
        "name": "Transfórmame a tu imagen",
        "number": "279",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Hoy pido en oración", "que perdones mi tibieza;", "pues quiero, de corazón,", "consagrarme a ti, Señor."], ["A otros quiero llevar", "tu mensaje de esperanza;", "viviendo para servir,", "compartiendo tu amor."]],
        "refrainFirst": false,
        "refrain": ["Renuévame, Señor;", "inspírame valor;", "transfórmame a tu imagen;", "renuévame, Señor."]
      }, {
        "name": "Ser semejante a Jesús",
        "number": "280",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Ser semejante a Jesús", "es lo que más deseo en la vida.", "Ser semejante a Jesús", "es el ansia sincera de todo mi ser.", "Ser semejante a Jesús:", "la ilusión para mí más querida."], ["Ser semejante a Jesús", "es el punto inicial de partida.", "Ser semejante a Jesús", "es la ruta segura de mi caminar.", "Ser semejante a Jesús:", "el destino final de mi vida."], ["Ser semejante a Jesús", "es mi diaria comida y bebida.", "Ser semejante a Jesús:", "el modelo perfecto que quiero imitar.", "Ser semejante a Jesús", "es el canto que entona mi vida."]],
        "refrainFirst": false,
        "refrain": ["Para hallar en la vida alegría y poder,", "quiero ser más semejante a Jesús."]
      }, {
        "name": "He decidido seguir a Cristo",
        "number": "281",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["He decidido seguir a Cristo,", "he decidido seguir a Cristo,", "he decidido seguir a Cristo.", "No vuelvo atrás, no vuelvo atrás."], ["Si otros vuelven, yo sigo a Cristo,", "si otros vuelven, yo sigo a Cristo,", "si otros vuelven, yo sigo a Cristo.", "No vuelvo atrás, no vuelvo atrás."], ["La cruz delante y el mundo atrás,", "la cruz delante y el mundo atrás,", "la cruz delante y el mundo atrás.", "No vuelvo atrás, no vuelvo atrás."]]
      }, {
        "name": "¡Brilla, Jesús!",
        "number": "282",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Cristo, tu gran amor me inspira;", "Cristo, tu gran amor me anima;", "pues tu luz ilumina la oscuridad,", "tu verdad me conduce a la libertad.", "Brilla en mí, brilla en mí."], ["Cristo, vengo a tu presencia,", "atraído por tu pureza.", "Examina mi vida y pruébame;", "limpia toda maldad con tu sangre.", "Brilla en mí, brilla en mí."]],
        "refrainFirst": false,
        "refrain": ["¡Brilla, Jesús!", "Danos hoy la luz que da tu gloria,", "pues tu calor trae paz y amor.", "Ven como el Sol", "de justicia y misericordia.", "¡Brilla, Jesús! Danos hoy tu luz."]
      }, {
        "name": "Ven, Señor Jesús",
        "number": "283",
        "category": "El evangelio",
        "subcategory": "Consagración",
        "verses": [["Ven, Señor Jesús, dirige mi pensar;", "ven, Señor Jesús, protege mi mirar;", "ven, Señor Jesús, modela mi hablar;", "oh, ven, Señor Jesús, mi corazón te entrego."]]
      }, {
        "name": "Me dice el Salvador",
        "number": "284",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Me dice el Salvador:", "\"Es poco tu poder;", "hijo débil, halla en mí", "todo cuanto has menester\"."], ["He hallado, mi Señor,", "que solo tu poder", "a mi duro corazón", "es capaz de enternecer."], ["Nada bueno hay en mí:", "tu gracia buscaré,", "y en la sangre de Jesús", "mis pecados lavaré."], ["Cuando ante el trono, allá,", "completo en él esté,", "a los pies de mi Jesús", "mi tributo ofreceré."]],
        "refrainFirst": false,
        "refrain": ["Todo debo a él, pues ya lo pagó;", "de las manchas del pecar cual nieve me lavó."]
      }, {
        "name": "Confío en Jesucristo",
        "number": "285",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Confío en Jesucristo,", "quien en la cruz murió.", "Por esa muerte invicto", "al cielo marcho yo.", "Con sangre tan preciosa", "mis culpas lava él:", "la derramó copiosa", "por mí ya Emanuel."], ["Me cubre tu justicia", "de plena protección;", "tú eres mi delicia,", "mi eterna salvación.", "Jesús, en ti descanso;", "reposo tú me das;", "tranquilo, pues, avanzo", "al cielo, donde estás."], ["A disfrutar invitas.", "Acepto, mi Señor,", "delicias infinitas y celestial amor.", "Espero al fin mirarte,", "oír tu dulce voz;", "espero yo cantarte,", "mi Salvador, mi Dios."]]
      }, {
        "name": "Hay una fuente sin igual",
        "number": "286",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Hay una fuente sin igual, la sangre de Emanuel,", "en donde lava cada cual las manchas que hay en él,", "las manchas que hay en él, las manchas que hay en él;", "en donde lava cada cual las manchas que hay en él."], ["El malhechor se convirtió muriendo en una cruz,", "al ver la sangre que vertió sin culpa el buen Jesús,", "sin culpa el buen Jesús, sin culpa el buen Jesús;", "al ver la sangre que vertió sin culpa el buen Jesús."], ["Y yo también, cuan malo soy, lavarme allí podré;", "y en tanto que en el mundo estoy su gloria cantaré,", "su gloria cantaré, su gloria cantaré;", "y en tanto que en el mundo estoy su gloria cantaré."], ["Y cuando del sepulcro ya resucitado esté,", "canción más noble y dulce allá en gloria entonaré,", "en gloria entonaré, en gloria entonaré;", "canción más nombre y dulce allá en gloria entonaré."]]
      }, {
        "name": "Rey de mi vida",
        "number": "287",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Rey de mi vida tú eres hoy;", "en ti me gloriaré;", "yo por tu cruz salvado soy:", "no te olvidaré."], ["Mas vi la luz amanecer", "de la eternidad;", "te vi, Señor, aparecer", "con inmortalidad."], ["Rey de mi vida, Rey de luz,", "en ti me gloriaré;", "por mí moriste en la cruz:", "no te olvidaré."]],
        "refrainFirst": false,
        "refrain": ["Después de tu Getsemaní,", "subiste a la cruz más cruel;", "todo sufrió tu amor por mí:", "yo quiero serte fiel."]
      }, {
        "name": "Al contemplarte, mi Salvador",
        "number": "288",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Al contemplarte, mi Salvador,", "y al meditar en tu gran amor,", "veo en mi vida mucho pecar.", "Tómala, Cristo, quiero triunfar."], ["Hondas heridas de transgresión", "manchan mi vida sin tu perdón.", "Cúbreme, Cristo, mora en mí,", "vive tu vida, vívela en mí."], ["Dame la dicha de tu perdón,", "dame tu manto de salvación;", "Cristo, lo acepto, dejo el pecar,", "dame las fuerzas para triunfar."], ["Reconciliado por tu morir,", "justificado por tu vivir,", "santificado por tu poder,", "glorificado al verte volver."]],
        "refrainFirst": false,
        "refrain": ["Cubre mi vida, Cristo Jesús.", "Blanca cual nieve la hace tu luz.", "Tuya es mi vida, soy pecador,", "pero en tu nombre soy vencedor."]
      }, {
        "name": "¿Qué me puede dar perdón?",
        "number": "289",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["¿Qué me puede dar perdón?", "Solo de Jesús la sangre.", "¿Y un nuevo corazón?", "Solo de Jesús la sangre."], ["Fue rescate eficaz", "solo de Jesús la sangre.", "Trajo santidad y paz", "solo de Jesús la sangre."], ["Cantaré junto a sus pies,", "solo de Jesús la sangre.", "El Cordero digno es.", "Solo de Jesús la sangre."]],
        "refrainFirst": false,
        "refrain": ["Precioso es el raudal", "que limpia todo mal.", "No hay otro manantial", "sino de Jesús la sangre."]
      }, {
        "name": "Fuente de la vida eterna",
        "number": "290",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Fuente de la vida eterna y de toda bendición,", "ensalzar tu gracia tierna debe todo corazón.", "Tu piedad inagotable se deleita en perdonar;", "solo tú eres adorable; gloria a ti debemos dar."], ["De los cánticos celestes te quisiéramos cantar,", "entonados por las huestes que viniste a rescatar.", "De los cielos descendiste porque nos tuviste amor;", "tierno te compadeciste y nos diste tu favor."], ["Toma nuestros corazones, llénalos de tu verdad,", "de tu Espíritu los dones, y de toda santidad.", "Guíanos en la obediencia, humildad, amor y fe;", "nos ampare tu clemencia; Salvador, propicio sé."]]
      }, {
        "name": "Perdido, fui a mi Jesús",
        "number": "291",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Perdido, fui a mi Jesús.", "Él vio mi condición;", "en mi alma derramó su luz;", "su amor me dio perdón."], ["En cruenta cruz mi Salvador", "su sangre derramó", "por este pobre pecador", "a quien así salvó."], ["Venció la muerte, ¡qué poder!,", "y el Padre le exaltó", "al trono, para interceder", "por tales cual soy yo."], ["Aunque él se fue, conmigo está", "el fiel Consolador,", "el guía que me llevará", "al reino del Señor."]],
        "refrainFirst": false,
        "refrain": ["Fue primero en la cruz donde yo vi la luz,", "y mi carga de pecado dejé;", "fue allí por fe do vi a Jesús,", "y siempre con él feliz seré."]
      }, {
        "name": "Por fe en Cristo, el Redentor",
        "number": "292",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Por fe en Cristo, el Redentor,", "se salva hoy el pecador;", "aunque sin merecer perdón,", "recibe plena salvación."], ["La vida antigua ya pasó", "y todo en nuevo se tornó,", "y aunque nada tenga aquí,", "herencia eterna tiene allí."], ["Aquí cual peregrino es;", "mansión allá tendrá después,", "arriba en gloria con Jesús,", "quien redimiole en la cruz."]],
        "refrainFirst": false,
        "refrain": ["¡Oh, gracia excelsa del amor,", "que Dios perdone al pecador!", "Si quiere presto confesar", "sus culpas, y en Jesús confiar,", "encontrará la salvación,", "pues él obró la redención."]
      }, {
        "name": "¿Quieres ser salvo de toda maldad?",
        "number": "293",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["¿Quieres ser salvo de toda maldad?", "Tan solo hay poder en mi Jesús.", "¿Quieres vivir y gozar santidad?", "Tan solo hay poder en Jesús."], ["¿Quieres ser libre de orgullo y pasión?", "Tan solo hay poder en mi Jesús.", "¿Quieres vencer toda cruel tentación?", "Tan solo hay poder en Jesús."], ["¿Quieres servir a tu Rey y Señor?", "Tan solo hay poder en mi Jesús.", "Ven, y ser salvo podrás en su amor.", "Tan solo hay poder en Jesús."]],
        "refrainFirst": false,
        "refrain": ["Hay poder, sí, sin igual poder", "en Jesús, quien murió;", "hay poder, sí, sin igual poder,", "en la sangre que él vertió."]
      }, {
        "name": "En Jesús por fe confío",
        "number": "294",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["En Jesús por fe confío, del pecado me salvó;", "y su sangre generosa en la cruz por mí vertió.", "De los hombres los pecados él cargó con sumisión,", "consumando por los siglos nuestra eterna redención.", "Consumando por los siglos nuestra eterna redención."], ["Salvación ofrece al hombre que abandone todo error,", "y en la fe de Jesucristo se encamine con fervor.", "Él es padre cariñoso, es amigo siempre fiel,", "y conforta nuestras penas si llegámonos a él,", "y conforta nuestras penas si llegámonos a él."], ["Vida eterna nos ofrece y gratuita salvación,", "si dejamos el pecado implorando su perdón.", "Nos absuelve y nos sostiene cuando asedia tentación;", "da consuelo al afligido y abrumado corazón,", "da consuelo al afligido y abrumado corazón."]]
      }, {
        "name": "Las manos, Padre",
        "number": "295",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Las manos, Padre, extiendo a ti;", "mi fiel ayuda sé.", "Si tú no cuidas ya de mí,", "¿a quién y adónde iré?"], ["En tu Hijo amado creo yo,", "conozco tu poder.", "¡Oh!, suple mi necesidad,", "renueva tú mi ser."], ["Los ojos alzo a ti, Señor,", "Autor tú de la fe.", "¡Oh!, dame el tan precioso don,", "pues sin él yo moriré."]],
        "refrainFirst": false,
        "refrain": ["Yo creo que en el Gólgota", "Jesús por mí murió,", "y con su sangre, del pecar", "a mi alma libertó."]
      }, {
        "name": "Comprado con sangre por Cristo",
        "number": "296",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Comprado con sangre por Cristo,", "gozoso al cielo ya voy;", "librado por gracia infinita,", "cual hijo en su casa estoy."], ["Soy libre de pena y culpa,", "su gozo él me hace sentir,", "él llena de gracia mi alma,", "con él es tan dulce vivir."], ["En Cristo Jesús yo medito,", "en todo momento y lugar;", "por tantas mercedes de Cristo", "su nombre me gozo en loar."], ["Yo sé que me espera corona,", "la cual a los fieles dará;", "me entrego con fe al Maestro,", "sabiendo que me guardará."]],
        "refrainFirst": false,
        "refrain": ["Por él, por él comprado con sangre yo soy;", "con él, con él, con Cristo al cielo yo voy."]
      }, {
        "name": "Salvado con sangre por Cristo",
        "number": "297",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Salvado con sangre por Cristo,", "me agrada cantar de su amor.", "Ya soy, por su gracia infinita,", "un hijo de mi Redentor."], ["Alabo al Señor Jesucristo", "con alma encendida en fervor.", "No puedo callar lo que he visto;", "por eso hoy canto su amor."], ["Seguro estoy de que en gloria", "veré al Autor de la Ley.", "Me guarda en su misericordia,", "elevo mi canto al Rey."]],
        "refrainFirst": false,
        "refrain": ["Por él, por él,", "comprado con sangre yo soy.", "Salvado por su sacrificio,", "con Cristo al cielo yo voy."]
      }, {
        "name": "Al Calvario, solo, Jesús ascendió",
        "number": "298",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Al Calvario, solo, Jesús ascendió", "llevando pesada cruz,", "y al morir en ella al mortal dejó", "un fanal de gloriosa luz,"], ["En la cruz tan solo el alma hallará", "la fuente de inspiración;", "nada grande y digno en el mundo habrá", "que en la cruz no halle aprobación,"], ["Yo por ella voy a mi hogar celestial,", "el rumbo marcando está;", "en mi oscura vida será el fanal", "y a su luz mi alma siempre irá."]],
        "refrainFirst": false,
        "refrain": ["La cruz solo me guiará,", "la cruz solo me guiará;", "a mi hogar de paz y eterno amor,", "la cruz solo me guiará,"]
      }, {
        "name": "Hay vida en mirar",
        "number": "299",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Hay vida en mirar a la santa cruz,", "Dice Jesús: \"Miradme a mí\".", "Nada el mundo y sus glorias son;", "tesoros brillantes se ven allí."], ["Y cuando miré a mi Salvador,", "dulces sonrisas él me dio;", "hoy del maligno soy vencedor,", "mirando a la cruz do Jesús murió."], ["Mirando a Jesús, siempre confiaré", "en sus promesas y poder;", "nunca vencido del mal seré;", "el Cielo me ayuda a obedecer."]],
        "refrainFirst": false,
        "refrain": ["¡Oh, mirad, pues hallaréis", "vida eterna allá en la cruz!", "Salvación recibiréis", "en el Redentor, Jesús,"]
      }, {
        "name": "Lejos de mi Padre Dios",
        "number": "300",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Lejos de mi Padre Dios,", "por Jesús hallado,", "por su gracia y por su amor", "solo fui salvado."], ["En Jesús, mi Salvador,", "pongo mi confianza;", "toda mi necesidad", "suple en abundancia."], ["Cerca de mi buen Pastor", "vivo cada día;", "toda gracia en ti, Señor,", "halla el alma mía."], ["Guárdame, Señor Jesús,", "para que no caiga;", "cual sarmiento de la vid,", "vida de ti traiga."]],
        "refrainFirst": false,
        "refrain": ["En Jesús, mi Señor, es mi gloria eterna;", "él me amó y me salvó en su gracia tierna."]
      }, {
        "name": "Cristo es mi amante Salvador",
        "number": "301",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Cristo es mi amante Salvador, mi bien, mi paz, mi luz;", "pues demostró su grande amor muriendo allá en la cruz.", "Cuando estoy triste encuentro en él consolador y amigo fiel;", "consolador, amigo fiel es Jesús."], ["Cristo es mi amante Salvador, su sangre me compró;", "por sus heridas y dolor perfecta paz me dio.", "Dicha inmortal con él tendré, y para siempre reinaré,", "dicha inmortal allí tendré con Jesús."], ["Cristo es mi amante Salvador, mi eterno Redentor.", "¡Jamás podré satisfacer la deuda de su amor!", "Le seguiré, pues, en la luz, no temeré llevar su cruz,", "no temeré llevar la cruz de Jesús."], ["Cristo es mi amante Salvador. Por él salvado soy;", "la Roca es de la eternidad en quien seguro estoy.", "Gloria inmortal con él tendré, y para siempre reinaré,", "gloria inmortal allí tendré con Jesús."]]
      }, {
        "name": "Grato es contar la historia",
        "number": "302",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Grato es contar la historia de celestial favor,", "de Cristo y de su gloria, de Cristo y de su amor.", "Me agrada referirla, pues sé que es la verdad;", "y nada satisface cual ella mi ansiedad."], ["Grato es contar la historia más bella que escuché,", "más áurea, más hermosa que cuanto yo soñé.", "Contarla siempre anhelo, pues hay quien nunca oyó", "que, para redimirle, el buen Jesús murió."], ["Grato es contar la historia que placentera es", "y es más, al repetirla, preciosa cada vez.", "La historia, pues, que canto, oíd con atención,", "porque es mensaje santo de eterna salvación."], ["Grato es contar la historia de todas la mejor,", "que cuanto más se escucha, infunde más amor.", "Y cuando allá en la gloria entone mi cantar,", "será la misma historia que tanto supe amar."]],
        "refrainFirst": false,
        "refrain": ["¡Cuán bella es esta historia! Mi tema allá en la gloria", "será ensalzar la historia de Cristo y de su amor."]
      }, {
        "name": "Sublime gracia",
        "number": "303",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Sublime gracia del Señor,", "de muerte me libró.", "Perdido fui, me rescató;", "fui ciego, me hizo ver."], ["Mi corazón enterneció,", "mis dudas disipó.", "Preciosa fue la hora en que", "rendí mi vida a él."], ["Jesús promete darme el bien;", "confío siempre en él.", "Mi protector y guía fiel", "me llevará al hogar."]]
      }, {
        "name": "Mi Redentor, el Rey de gloria",
        "number": "304",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Mi Redentor, el Rey de gloria,", "que vive yo seguro estoy;", "y da coronas de victoria;", "a recibir la mía voy."], ["En mi Señor Jesús confío,", "su sangre clama a mi favor;", "es dueño él de mi albedrío,", "estar con él es lo mejor."], ["De tanto amor me maravillo,", "y no me canso de cantar;", "me libertó de mi peligro,", "sufriendo todo en mi lugar."], ["Consuélome en su larga ausencia", "pensando: Pronto volverá;", "entonces su gloriosa herencia", "a cada fiel Jesús dará."]],
        "refrainFirst": false,
        "refrain": ["Que permanezca, no pidáis,", "entre el bullicio y el vaivén;", "el mundo hoy dejar quisiera,", "aun cuando fuese cual Edén.", "El día, nada más, aguardo en", "que el Rey me diga: \"Hijo, ven\"."]
      }, {
        "name": "Maravillosa su gracia es",
        "number": "305",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Maravillosa su gracia es,", "gracia que excede nuestro pecar.", "En el Calvario se derramó:", "allí su sangre Jesús vertió."], ["Grande es mi culpa, es como el mar", "que me amenaza con perdición.", "Pero mayor gracia Cristo da,", "arrepentido voy a la cruz."], ["Gracia infinita y eternal,", "siempre gratuita para el que cree.", "Tú, que anhelas ver a tu Dios,", "su gracia ahora ¿recibirás?"]],
        "refrainFirst": false,
        "refrain": ["Gracia, gracia, gracia que limpia y da perdón.", "Gracia, gracia, gracia más grande que mi pecar."]
      }, {
        "name": "Llegó Jesús",
        "number": "306",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Muy solitario, junto al sendero,", "un pobre ciego mendigaba pan;", "iba sin rumbo a tientas, en tinieblas.", "Llegó Jesús, y al ciego vista dio."], ["Atormentado por el enemigo", "entre las tumbas iba un hombre vil;", "su cuerpo el templo era del demonio.", "Llegó Jesús, y al diablo derrotó."], ["\"Inmundo soy\", clamaba el leproso;", "el sordomudo no podía hablar;", "la fiebre alzó la víctima en sus garras.", "Llegó Jesús, la raza a libertar."], ["Así los hombres de hoy son incapaces", "de dominar sus vicios, su temor;", "el corazón lo tienen quebrantado.", "Jesús les brinda el bálsamo de amor."]],
        "refrainFirst": false,
        "refrain": ["Llegó Jesús, y huyó el enemigo;", "llegó Jesús, el llanto a enjugar;", "se irán las sombras y entrará su gloria", "si a Jesús entrada quieres dar."]
      }, {
        "name": "Roca de la eternidad",
        "number": "307",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Roca de la eternidad,", "fuiste abierta para mí;", "sé mi escondedero fiel;", "solo encuentro paz en ti,", "rico, limpio manantial", "en el cual lavado fui."], ["Aunque fuese siempre fiel,", "aunque llore sin cesar,", "del pecado no podré", "justificación lograr;", "solo en ti teniendo fe,", "salvación podré gozar."], ["Mientras tenga que vivir", "en el mundo de maldad;", "cuando vaya a responder", "en tu augusto tribunal,", "sé mi escondedero fiel,", "Roca de la eternidad."]]
      }, {
        "name": "Dios descendió",
        "number": "308",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Día tan grande no puedo olvidar, día de gloria sin par;", "cuando en tinieblas al verme andar, vino a salvarme el Señor.", "Gran compasión tuvo Cristo de mí, gozo y paz me otorgó;", "sombras quitó, aliviando mi carga, la noche en día cambió."], ["Del Santo Espíritu yo renací en la familia de Dios.", "Justificado por Cristo el Señor, gozo la gran redención.", "Cristo pagó con su sangre por mí, indigno y vil pecador;", "me entregó su regalo de gracia y así él mi vida salvó."], ["Tengo esperanza de gloria eternal, me regocijo en Jesús;", "me ha preparado un bello lugar, en la mansión celestial.", "Siempre recuerdo con fe y gratitud, al contemplarlo en la cruz,", "que sus heridas me dieron la vida, ¡Alabo al bendito Jesús!"]],
        "refrainFirst": false,
        "refrain": ["Dios descendió y de gloria me llenó,", "cuando Jesús por gracia me salvó;", "fui ciego, me hizo ver y mi noche iluminó.", "Dios descendió y de gloria me llenó."]
      }, {
        "name": "La voz de Jesús",
        "number": "309",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Escuché muy dulce y llena de amor", "la divina voz de Jesús:", "\"Oh, querido hijo, yo soy tu Salvador;", "deja el mundo y ven hacia la luz\".", "Renuncié al mundo, a Cristo me entregué,", "lo invité a mi corazón;", "y tan solo entonces su dulce paz gocé,", "pues en él hallé salvación."], ["Siento la ternura en su voz, al decir:", "\"Alcanzaste pleno perdón;", "di a todos que muy feliz es tu vivir,", "y que al mundo ofrezco salvación\".", "Consagrado a él, mi deseo es anunciar", "su incomparable amor;", "y que por su gracia él quiere aceptar", "hasta al más vil pecador."], ["Tengo la dichosa esperanza de oír", "esa bella voz, musical;", "cuando salvo esté en eterno porvenir,", "en la hermosa Patria celestial.", "Al eterno hogar finalmente partiré;", "dejaré este mundo atrás.", "Con los santos ángeles pronto viviré", "y oiré la voz de Jesús."]]
      }, {
        "name": "Cristo, centro de mi vida",
        "number": "310",
        "category": "El evangelio",
        "subcategory": "Salvación y redención",
        "verses": [["Cristo, centro de mi vida,", "Salvador en aflicción;", "nadie viene a ti en vano,", "tu paciencia es sin fin."], ["Cristo, Redentor eterno,", "limpia nuestro corazón;", "que podamos perdonarnos,", "las ofensas olvidar."], ["Cristo, sobre todo reinas,", "eternal es tu poder.", "Infinita es tu gracia", "para todo pecador."]],
        "refrainFirst": false,
        "refrain": ["Del abismo líbrame,", "a tu lado quiero estar", "y por la eternidad alabarte sin cesar."]
      }, {
        "name": "Cuando junte Jesús las naciones",
        "number": "311",
        "category": "El evangelio",
        "subcategory": "Juicio",
        "verses": [["Cuando junte Jesús las naciones", "que ante él han de comparecer;", "¿Cómo hemos de estar en el juicio,", "el fallo del Juez al saber?"], ["¿Del Señor las palabras oiremos:", "\"Bien hecho, mi buen siervo fiel\"?", "¿O temblando de espanto, seremos", "borrados del libro por él?"], ["Mirará complacido a sus hijos,", "su sello en sus frentes verá;", "mientras ellos de hinojos le adoren,", "coronas de luz les pondrá."]],
        "refrainFirst": false,
        "refrain": ["Juntará todo el trigo en su troje,", "mas el viento el tamo esparcirá.", "¿Cómo hemos de estar en el juicio", "del gran día que pronto vendrá?"]
      }, {
        "name": "Día grande viene",
        "number": "312",
        "category": "El evangelio",
        "subcategory": "Juicio",
        "verses": [["Día grande viene, un día grande,", "día grande viene y cerca está,", "cuando justos y malos separados quedarán.", "¡Oh! hermano, ¿apercibido estás?"], ["Día triste viene, un día triste,", "día triste viene y cerca está,", "cuando: \"Id pecadores, no os conozco\", el Juez dirá.", "¡Oh! hermano, ¿apercibido estás?"], ["Día alegre viene, un día alegre,", "día alegre viene y cerca está;", "mas su luz brilla solo en los que aman al Señor.", "¡Oh! hermano, ¿apercibido estás?"]],
        "refrainFirst": false,
        "refrain": ["¿Te hallas listo?, ¿te hallas listo", "para el fallo del gran tribunal?", "¿Te hallas listo?,", "¿te hallas listo para el tribunal?"]
      }, {
        "name": "La hora del Juicio",
        "number": "313",
        "category": "El evangelio",
        "subcategory": "Juicio",
        "verses": [["Solemne anuncio Dios nos da: del Juicio el tiempo ya llegó.", "Nuestro destino sellará y cada caso el Juez verá.", "Su obra hará, concluirá el Juicio Investigador.", "El Juez vendrá con gran poder y el pago a todos les dará."], ["Aquel que vino a morir y darse por el pecador.", "Al cielo luego ascendió y pronto al mundo volverá.", "Es nuestro fiel Intercesor en el Santuario celestial;", "su sangre allí abogará, dando a sus hijos el perdón."], ["Solemne hora llegará y los que aman al Señor,", "ante su Juez habrán de estar; y el fallo entonces él dará.", "Oh, mi Jesús, confío en ti, a tu amor me aferro yo.", "Mi nombre, tú, confiesa hoy, y mis pecados borra así."], ["Oh, mi Señor, que pueda hoy sentir cuán seria hora es;", "Oh, lléname de tu amor, transfórmame con tu poder.", "Confiando en ti, con gran valor, yo quiero en la lid vencer;", "y un día estar contigo en Sion cantando a ti, mi Salvador."]]
      }, {
        "name": "Cristo, Rey omnipotente",
        "number": "314",
        "category": "El evangelio",
        "subcategory": "Juicio",
        "verses": [["Cristo, Rey omnipotente,", "victorioso ascendió;", "con sus manos extendidas", "impartiendo bendición.", "Fue de ángeles su hueste", "cuando al cielo ascendió;", "cuando al cielo ascendió."], ["Vio Daniel la hora del Juicio", "y los libros del Señor.", "En su trono Dios espera", "que descienda el Redentor.", "Ya se acerca, ya se acerca,", "en las nubes viene ya;", "en las nubes viene ya.", "3, Cúmplese lo revelado,", "la trompeta suena ya.", "Buenos, malos, se deslumbran", "al mirar su majestad.", "Ya regresa, ya regresa,", "en las nubes viene ya;", "en las nubes viene ya."]]
      }, {
        "name": "El Juicio empezó",
        "number": "315",
        "category": "El evangelio",
        "subcategory": "Juicio",
        "verses": [["El Juicio empezó, los libros se abrieron,", "¿cómo estaremos ante Dios?", "Todos los actos, cada palabra", "y pensamiento a luz saldrán."], ["El Juicio empezó con los que descansan,", "luego a los vivos tocará.", "Por el registro que hay en los cielos", "toda sentencia se dictará."], ["¡Oh!, ¿cómo estaremos cuando los libros", "muestren pecados sin limpiar,", "y cada caso quede sellado", "sin que haya otra apelación?"]],
        "refrainFirst": false,
        "refrain": ["¿Cómo estaremos ante el Juez?", "¿Cómo estaremos ante el Juez?", "¿Faltos hallados o perdonados?", "Cristo ofrece limpiarnos hoy."]
      }, {
        "name": "Hay un mundo feliz más allá",
        "number": "316",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["Hay un mundo feliz más allá,", "donde ángeles cantan en luz", "tributando eterno loor", "al invicto, glorioso Jesús."], ["Cantaremos con gozo a Jesús,", "al Cordero que nos rescató,", "que con sangre vertida en la cruz", "los pecados del mundo quitó."], ["Para siempre en el mundo feliz", "con los santos daremos honor", "al invicto, glorioso Jesús,", "a Jesús, nuestro Rey y Señor."]],
        "refrainFirst": false,
        "refrain": ["En el mundo feliz", "reinaremos con nuestro Señor", "en el mundo feliz", "reinaremos con nuestro Señor."]
      }, {
        "name": "En el hogar do nunca habrá",
        "number": "317",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["En el hogar do nunca habrá", "tristeza, muerte ni dolor,", "eterno gozo existirá,", "es la promesa del Señor."], ["Si vuestra senda angosta es,", "¡seguid!, no os desaniméis;", "felicidad habrá después", "en el hogar donde estaréis."], ["Es tiempo de ir a trabajar", "y dar las nuevas de salud", "de Aquel que a todos ha de dar", "hogar de eterna juventud."]],
        "refrainFirst": false,
        "refrain": ["Velad y esperad al Salvador;", "muy pronto vendrá el Redentor.", "¡Velad y orad! Vendrá el Salvador."]
      }, {
        "name": "En la mansión de mi Señor",
        "number": "318",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["En la mansión de mi Señor", "no habrá ya más tribulación,", "no habrá pesar, ningún dolor,", "que me quebrante el corazón."], ["Resulta triste estar aquí", "muy lejos de mi Redentor,", "mas morarán con él allí", "los redimidos por su amor."], ["Perfecto amor encontraré", "en la mansión do Cristo está;", "perfecta calma allí tendré,", "y mi alma al fin descansará."], ["En su presencia gozaré", "su inmenso amor, su gran bondad;", "feliz con Cristo reinaré", "por toda la eternidad."]],
        "refrainFirst": false,
        "refrain": ["Allá no habrá tribulación,", "ningún pesar, ningún dolor;", "y entonaré feliz canción", "de alabanza al Señor."]
      }, {
        "name": "Cuando mi lucha termine al final",
        "number": "319",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["Cuando mi lucha termine al final", "y me halle salvo en la playa eternal,", "junto al que adoro, mi Rey celestial,", "eterna gloria será para mí."], ["Cuando la gracia infinita me dé", "bella morada en la casa del Rey,", "maravillado su rostro veré,", "y eterna gloria será para mí."], ["Encontraré a mis amados allá;", "gozo cual río en redor correrá;", "dulce sonrisa Jesús me dará,", "eterna gloria será para mí."]],
        "refrainFirst": false,
        "refrain": ["Gloria sin fin eso será,", "gloria sin fin eso será.", "Cuando por gracia su faz vea allí,", "eterna gloria será para mí."]
      }, {
        "name": "Jamás se dice \"adiós\" allá",
        "number": "320",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["Cuán grato es con amigos vernos", "en tiempo tan veloz;", "mas siempre llega el día triste", "en que se dice \"adiós\"."], ["Cuán dulce es el consuelo dado", "por ellos al partir:", "que cuando venga Cristo en gloria,", "nos hemos de reunir."], ["Jamás habrá una despedida,", "tan dolorosa aquí;", "mas grata unión y paz eterna", "se gozarán allí."]],
        "refrainFirst": false,
        "refrain": ["Jamás se dice \"adiós\" allá,", "jamás se dice \"adiós\";", "en el país de gozo y paz", "jamás se dice \"adiós\"."]
      }, {
        "name": "Allá sobre montes",
        "number": "321",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["Allá sobre montes, en feliz país,", "la ciudad divina reposando está,", "Nuestros pies, aquí cansados, subirán:", "la mansión eterna divisamos ya."], ["Los antiguos profetas nos hablaron ya", "de las calles de oro de esa gran ciudad.", "Por la fe hoy la podemos contemplar,", "con sus muros de oro y jaspe, sin igual."], ["Hermano, hermana, ¿estarás allá,", "en aquella tierra do pesar no habrá?", "El mensaje santo hoy acepta fiel,", "y al venir el Salvador te llamará."]],
        "refrainFirst": false,
        "refrain": ["¡Vamos al hogar! ¡Vamos al hogar!", "¡Ved, sus torres brillan con gran esplendor!", "¡Ved la gloria cómo emana del Señor!", "Estaremos juntos por la eternidad", "con los ángeles, cantando en la Ciudad.", "¡Vamos al hogar!"]
      }, {
        "name": "Busquemos la patria",
        "number": "322",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["Busquemos la patria de justos y santos,", "do mora la dicha, do reina el amor.", "Dejemos ahora fugaces encantos", "que ciegan y llevan a eterno dolor."], ["Hermanos viajeros, felices marchemos,", "delicias eternas allí Dios dará;", "pues sobre collados de gloria andaremos,", "y herencia esa tierra de todos será."], ["Deseamos, hermano, en camino llevarte.", "Por ti detenidos estamos; ¡oh ven!", "En Cristo confía, que anhela salvarte", "y hacerte morar en su célico Edén."], ["Tal vez desconfiado, te estás preguntando:", "\"¿Quién puede mi negra conciencia limpiar?\"", "Jesús es el único; ven, pues, orando:", "\"Señor, haz que pueda a tu reino llegar\"."]]
      }, {
        "name": "He de conocerle entonces",
        "number": "323",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["Cuando aquí los afanes de mi vida cesen ya", "y amanezca bella aurora celestial,", "en las playas eternas mi llegada esperará", "el Señor con bienvenida paternal."], ["¡Oh!, qué gozo supremo cuando pueda ver su faz", "y en eterna vida estar con mi Señor;", "de su lado ya nunca me podrán quitar jamás", "los halagos de mi artero tentador."], ["He de ver a los míos que en la tierra ya perdí", "cuando en brazos de la muerte los dejé;", "y aunque de ellos entonces con dolor me despedí,", "junto al trono de Jesús los hallaré."], ["Al entrar por las puertas, en la célica ciudad,", "me uniré con los que allá triunfantes van;", "y del himno que alabe de mi Dios la majestad,", "los acentos por los siglos sonarán."]],
        "refrainFirst": false,
        "refrain": ["He de conocerle entonces,", "redimido, a su lado cuando esté;", "por las señas de los clavos", "en sus manos a Jesús conoceré."]
      }, {
        "name": "Pronto yo veré a Jesús",
        "number": "324",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["Pronto yo veré a Jesús el Rey", "con su hueste angelical;", "en la bella tierra do moraré,", "cara a cara lo veré."], ["En aquella tierra de paz y amor", "no habrá oscuridad,", "pues el resplandor de mi Salvador", "siempre me iluminará."], ["Redimido allí a Jesús iré", "a rendirle adoración,", "y feliz por siempre le cantaré", "pues me dio la redención."]],
        "refrainFirst": false,
        "refrain": ["En su gloria yo veré al Rey,", "y loores siempre cantaré;", "en la cruz murió mi Jesús por mí", "y muy pronto lo veré."]
      }, {
        "name": "No puede el mundo ser mi hogar",
        "number": "325",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["No puede el mundo ser mi hogar,", "sus bienes han de perecer;", "anhelo pronto a Sion llegar", "y a mi Maestro ver."], ["La muerte allí no existirá;", "tampoco la separación;", "tristeza nunca más habrá", "en la anhelada Sion."], ["Deseo pronto arribar", "al puerto de la eterna paz.", "Con Cristo quiero allí reinar", "dejando el mundo atrás."]],
        "refrainFirst": false,
        "refrain": ["Iré al hogar,", "a mi eterno y dulce hogar,", "mi hogar;", "sí, iré al hogar que Jesús fue a preparar."]
      }, {
        "name": "Un día yo he de faltar",
        "number": "326",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["Un día yo he de faltar", "de mi lugar en esta grey;", "mas ¡oh, qué gozo al despertar", "en el palacio de mi Rey!"], ["Un día a mí la muerte atroz", "vendrá, mas cuándo, no lo sé;", "solo esto sé, que con mi Dios", "un sitio yo feliz tendré."], ["Un día yo, tal como el sol,", "mi ocaso y fin tendré también;", "mas cuando venga el Salvador,", "su voz dirá: \"Ven, hijo, ven\"."], ["El día fausto aguardo yo,", "y sé que pronto llegará;", "vendrá en su gloria mi Señor", "y a su mansión me llevará."]],
        "refrainFirst": false,
        "refrain": ["Y cara a cara le veré,", "y viviré con él allí,", "y para siempre cantaré:", "\"Salvado por su gracia fui\"."]
      }, {
        "name": "Jerusalén, mi amado hogar",
        "number": "327",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["Jerusalén, mi amado hogar, anhelo en ti morar;", "tus calles de oro recorrer, lucientes como el sol;", "tu río cristalino ver, hermoso sin igual;", "y en sus verdes márgenes tranquilo reposar."], ["Jerusalén, mi amado hogar, en ti no habrá dolor;", "el llanto no existirá, ni muerte, ni clamor;", "allí no habrá enemistad, pues reinará el amor,", "y solo habrá felicidad con nuestro Redentor."], ["Ansío pronto a ti llegar, mi celestial hogar;", "con mis amados que perdí, hallarme otra vez,", "y conocer a Abraham, a Eva y Adán;", "y contemplar el rostro de Jesús, mi Salvador."]],
        "refrainFirst": false,
        "refrain": ["¡Jerusalén! ¡Jerusalén;", "Jerusalén, mi amado hogar!", "¡Oh, cuándo te veré!"]
      }, {
        "name": "¿Nos veremos junto al río?",
        "number": "328",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["¿Nos veremos junto al río", "cuyas aguas cristalinas", "fluyen puras, argentinas,", "desde el trono de nuestro Dios?"], ["En las márgenes del río", "do los serafines van,", "donde hay bellos querubines,", "da la dicha eterna Dios."], ["Antes de llegar al río,", "nuestras cargas al dejar,", "libres todos quedaremos", "por la gracia del Señor."], ["Pronto al río llegaremos,", "nuestra peregrinación", "terminando en los acentos", "de la célica canción."]],
        "refrainFirst": false,
        "refrain": ["¡Oh! sí, nos congregaremos", "en la ribera hermosa del río", "cuyas aguas vivas dimanan", "del trono de nuestro Dios."]
      }, {
        "name": "En la célica morada",
        "number": "329",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["En la célica morada", "de las cumbres del Edén,", "donde toda voz ensalza", "al Autor de todo bien,", "¿el pesar recordaremos,", "y la triste nublazón,", "tantas luchas del Espíritu", "con el débil corazón?"], ["Oración, deberes, penas,", "vías que anduvimos ya,", "poseyendo las riquezas", "que Jesús nos guarda allá,", "¿la memoria retendremos", "del pretérito dolor,", "del camino largo, aspérrimo,", "con sus luchas, su temor?"]],
        "refrainFirst": false,
        "refrain": ["Sí, allí será gratísimo", "conocer y alabar", "al Pastor fiel y benéfico", "que nos ayudó a llegar."]
      }, {
        "name": "Hay un feliz Edén",
        "number": "330",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["Hay un feliz Edén", "lejos de aquí,", "y gozará del bien", "el justo allí.", "Cantemos con fervor:", "\"Digno eres, oh Señor,", "de gloria y de honor; ¡loor a ti!\""], ["Marchad a aquel lugar,", "partid de aquí;", "un bello y dulce hogar tendréis allí.", "¡Oh, cuán feliz seré", "cuando a tu lado esté!", "Bendito viviré morando en ti."], ["Eterno resplandor", "fulgura allí.", "Eterno es el amor", "de Dios por mí.", "Corramos, pues, allá:", "bello aquel hogar será,", "por siempre habitará", "el santo allí."]]
      }, {
        "name": "La mañana gloriosa",
        "number": "331",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["Cuán gloriosa será la mañana", "cuando venga Jesús el Salvador;", "cuando todos, unidos como hermanos,", "bienvenida daremos al Señor."], ["Esperamos la mañana gloriosa", "para dar la bienvenida al Dios de amor.", "Donde todo será color de rosa,", "en la santa presencia del Señor."]],
        "refrainFirst": false,
        "refrain": ["No habrá necesidad de la luz el resplandor;", "ni el sol dará su luz, ni tampoco su calor.", "Allí llanto no habrá, ni tristeza, ni dolor;", "porque entonces Jesús, el Rey del cielo,", "para siempre será el Consolador."]
      }, {
        "name": "En la tierra adonde iré",
        "number": "332",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["En la tierra adonde iré", "muerte y llanto no habrá;", "gozo eterno allí tendré,", "y no hay noche allá."], ["Puertas bellas se abrirán", "en la Santa Ciudad;", "calles áureas se verán,", "y no hay noche allá."], ["Para siempre viviré", "en mi amado hogar;", "agua viva beberé,", "y no hay noche allá."]],
        "refrainFirst": false,
        "refrain": ["Dios las penas quitará,", "no habrá muerte ni dolor;", "tiempo no se contará,", "y no hay noche allá."]
      }, {
        "name": "Aunque en esta vida",
        "number": "333",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["Aunque en esta vida fáltenme riquezas,", "sé que allá en la gloria tengo mi mansión.", "Alma tan perdida entre las pobrezas,", "de mi Jesucristo tuvo compasión."], ["Así por el mundo yo voy caminando,", "pruebas me rodean y hay tentación.", "Pero Jesucristo, que me está probando,", "llevarame salvo hasta su mansión,"], ["Cristo a cada raza del linaje humano", "puede impartirle plena salvación.", "y una bella casa hecha por su mano", "fue a prepararle a la santa Sion."]],
        "refrainFirst": false,
        "refrain": ["Más allá del sol, más allá del sol,", "yo tengo un hogar, hogar bello hogar", "más allá del sol.", "Más allá del sol, más allá del sol,", "yo tengo un hogar, hogar, bello hogar", "más allá del sol."]
      }, {
        "name": "Cuánto anhelo llegar",
        "number": "334",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["Cuánto anhelo llegar al celeste hogar,", "a la margen del río de vida;", "sorpresas sin fin me aguardan allá;", "mas ¡oh, qué será ver a Cristo!"], ["Unos han de morir para no más vivir,", "mas los justos a vida retornan.", "¡Qué gozo será volverlos a ver!,", "mas ¡oh, qué será ver a Cristo!"], ["Cuando vaya a vivir en aquella ciudad,", "cuando vea el rostro divino,", "no habrá más dolor, ni muerte jamás.", "¡Oh, sí, qué será ver a Cristo!"]],
        "refrainFirst": false,
        "refrain": ["¡Oh, qué será ver a Cristo!", "¡Qué será ver al Señor!", "Pronto nos llevará al eterno hogar,", "mas ¡oh, qué será ver a Cristo!"]
      }, {
        "name": "Mi hogar celestial",
        "number": "335",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["Hay en el cielo un dulce hogar,", "donde prefiero yo morar;", "Jesús, mi Señor, me prepara allí", "un hogar, un bellísimo hogar."], ["Con mis amados me veré;", "a Cristo allí coronaré;", "loor le daré por la eternidad", "en mi hogar, mi bellísimo hogar."], ["La vida es breve, pronto iré", "a estar con mi glorioso Rey;", "con clara visión puedo contemplar", "mi hogar, mi bellísimo hogar."]],
        "refrainFirst": false,
        "refrain": ["Bello hogar, dulce hogar,", "donde anhelo estar.", "Miro al fulgor de la patria de luz:", "mi dulce hogar."]
      }, {
        "name": "Del bello país he leído",
        "number": "336",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["Del bello país he leído, y su hermosa ciudad capital,", "cuyas calles gloriosas son de oro, y de jaspe es su muro eternal;", "por el río las aguas de vida fluyen con perennal claridad;", "mas en cuanto a toda su gloria no se ha dicho aún la mitad."], ["Leí de aquellas mansiones que el Maestro fue a preparar,", "do los santos que aquí fueron fieles van por siempre jamás a gozar;", "no habrá muerte, dolor, ni pecado, sino reina la inmortalidad;", "mas en cuanto a su excelsa grandeza no se ha dicho aún la mitad."], ["Leí de Jesús el benigno, que al más vil pecador limpiará;", "cómo paz y perdón le confiere al que humilde buscándolos va.", "He leído que él nos protege, que se apiada de nuestra orfandad;", "mas de tanta bondad pregonada no se ha dicho aún la mitad."]],
        "refrainFirst": false,
        "refrain": ["No se ha dicho aún la mitad;", "no se ha dicho aún la mitad;", "de la Santa Ciudad tan gloriosa,", "no se ha dicho aún la mitad."]
      }, {
        "name": "Nunca más adiós",
        "number": "337",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["Hay una tierra sin maldad,", "de dicha, paz y amor;", "con días sin oscuridad", "y goces sin dolor."], ["Es siempre primavera allá", "y sin marchita flor.", "Muy poco nos separa ya", "de ese hogar mejor."], ["Si pudiésemos cual Moisés mirar", "la hermosa tierra más allá,", "los placeres que este mundo da", "no nos encantarían más."]],
        "refrainFirst": false,
        "refrain": ["Vamos a la tierra de Emanuel,", "la trompeta ya nos llama a Sion.", "Reinaremos siempre allí con él", "y nunca habrá separación.", "¡Qué! ¿Nunca más adiós?", "No, ¡nunca más adiós!", "¡Qué! ¿Nunca más adiós?", "No, ¡nunca más adiós!", "Reinaremos siempre allí con él", "y nunca habrá separación."]
      }, {
        "name": "Las riberas de dicha inmortal",
        "number": "338",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["Las riberas de dicha inmortal,", "la mansión de indecible placer,", "la bellísima luz celestial,", "¡cuántas glorias iremos a ver!"], ["Cada santo de Dios gozará,", "deslumbrante en pureza y candor;", "del Cordero en pos andará,", "cantará alabanzas de amor."], ["De mi viaje la terminación", "tan feliz, ya no tarda en llegar.", "Efectuada mi transformación,", "con Jesús para siempre he de estar."]],
        "refrainFirst": false,
        "refrain": ["En aquel porvenir", "que divisa con júbilo el fiel,", "más allá del Jordán,", "en la patria do reina Emanuel."]
      }, {
        "name": "A veces oigo un himno",
        "number": "339",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["A veces oigo un himno cual yo jamás oí:", "es cántico divino, igual no conocí;", "es santa melodía que expresa tierno amor;", "es célica armonía que exalta al Creador."], ["¡Qué dulce paz yo gozo oyendo un canto tal!", "El mundo tenebroso olvido y todo mal.", "Más dulce que las voces del viento y del mar", "es el cantar que llega del trono celestial."], ["El cántico sublime cual sueño llega a mí;", "paréceme su ritmo cual brisas del jardín.", "¡Dichoso pensamiento!: Salvado yo seré,", "y con los redimidos el himno entonaré."]],
        "refrainFirst": false,
        "refrain": ["¡Oh, música divina!", "¡Oh, canto del Edén!", "Es eco de la bella,", "feliz Jerusalén."]
      }, {
        "name": "¡Oh, qué música divina!",
        "number": "340",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["¡Oh, qué música divina se oye desde el mar!", "Embelesa los sentidos esta dulce canción.", "Son las voces celestiales, que me invitan a cantar,", "y me dan la bienvenida a la celestial mansión."], ["A orillas del Jordán estoy, meditando en Jesús;", "y ansiosamente anhelo ya su mano estrechar.", "Yo te ruego que envíes a tus ángeles de luz;", "haz que pueda yo unirme a ese canto sin par."], ["Aun con aguas muy profundas y un gran vendaval;", "si Jesús me lo permite, los abismos cruzaré.", "Pues su música divina es sublime, sin igual;", "quiero unirme al gran coro y a Cristo cantaré."]]
      }, {
        "name": "Más cerca del hogar",
        "number": "341",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["La Biblia asegura que Jesús", "muy pronto en las nubes volverá.", "¡Prepárate, mi hermano, porque hoy", "más cerca estamos del hogar!"], ["La obra ya debemos terminar,", "pues muchos necesitan conocer.", "Las buenas nuevas hay que proclamar:", "¡Más cerca estamos del hogar!"]],
        "refrainFirst": false,
        "refrain": ["Más cerca del hogar,", "más cerca del hogar,", "más cerca estamos hoy que ayer,", "más cerca del hogar."]
      }, {
        "name": "Después del río",
        "number": "342",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["He oído de una tierra hermosa y soñada,", "un lugar en donde la alegría es total;", "con mis ojos la diviso no muy distante,", "mis oídos ya escuchando música están.", "Cansadora es la vida aquí de este lado,", "y mis brazos casi ya no pueden remar;", "agotados y heridos por tantos días;", "necesito encontrar el Puerto de amor."], ["He soñado con aquellas bellas moradas,", "que ninguno ha visto en este mundo jamás;", "de belleza que no puede ser comparada,", "pues la gloria del Señor se encuentra allí.", "Mucho más hermoso que el sol al ponerse,", "o el reflejo del rocío en una flor,", "es aquel encuentro para los redimidos;", "pues valió la pena aquí luchar y vencer."]],
        "refrainFirst": false,
        "refrain": ["Después del río existe un lugar para mí,", "después del río existe paz.", "Después del río habrá felicidad sin fin,", "con mi Jesús voy a morar."]
      }, {
        "name": "Quiero llegar a ser parte del cielo",
        "number": "343",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["Quiero llegar a ser parte del cielo", "y estar preparado aquel día glorioso,", "cantando con los salvados", "y extenderle los brazos a mi Señor.", "Yo quiero estar cuando aparezca en las nubes", "y extenderle los brazos a mi Señor."], ["No habrá más muerte ni dolor", "en el hogar celestial;", "Cristo, con su amor,", "el llanto borrará.", "Cerca está el día del Señor.", "¡Oh! ¡Qué gozo será estar allí!"], ["Quiero llegar a ser parte del cielo", "y estar preparado aquel día glorioso,", "cantando con los salvados", "y extenderle los brazos a mi Señor.", "Yo quiero estar cuando aparezca en las nubes", "y extenderle los brazos a mi Señor."]]
      }, {
        "name": "Entonad un himno",
        "number": "344",
        "category": "El evangelio",
        "subcategory": "Hogar celestial",
        "verses": [["Entonad un himno que alegre el corazón,", "vamos pronto a nuestro eterno hogar;", "porque pasará esta noche de aflicción,", "vamos pronto a nuestro eterno hogar."], ["Cuanto pida Dios el fiel siervo cumplirá,", "vamos pronto a nuestro eterno hogar;", "y su Espíritu nuevas fuerzas nos dará,", "vamos pronto a nuestro eterno hogar."], ["Id a encaminar a los extraviados pies,", "vamos pronto a nuestro eterno hogar;", "el amor de Cristo enseñad con sencillez,", "vamos pronto a nuestro eterno hogar."], ["Hay perfecta paz y reposo más allá,", "vamos pronto a nuestro eterno hogar;", "en la Tierra Nueva ya lágrimas no habrá,", "vamos pronto a nuestro eterno hogar."]],
        "refrainFirst": false,
        "refrain": ["Vamos pronto, sí, vamos pronto, sí,", "a cruzar el fiero mar;", "tras la tempestad, nos veremos más alla;", "vamos pronto a nuestro eterno hogar."]
      }, {
        "name": "Canta, y tus penas se irán",
        "number": "345",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["Canta, y tus penas se irán,", "canta, y tus penas se irán;", "Jesús hoy promete quitártelas;", "canta, y tus penas se irán."], ["Ríe, tus cuitas se irán,", "ríe, tus cuitas se irán;", "Jesús hoy promete quitártelas;", "ríe, tus cuitas se irán."], ["Ora, y tus cargas se irán,", "ora, y tus cargas se irán;", "Jesús hoy promete quitártelas;", "ora, y tus cargas se irán."]]
      }, {
        "name": "¡Feliz el día!",
        "number": "346",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["¡Feliz el día en que escogí", "servirte, mi Señor y Dios!", "Preciso es que mi gozo en ti", "lo muestre hoy con obra y voz."], ["Del mundo oscuro ya salí:", "de Cristo soy y mío es él;", "su senda con placer seguí,", "resuelto a serie siempre fiel."], ["Reposa, débil corazón,", "a tus contiendas pon ya fin;", "tendrás más noble posesión", "y parte en superior festín."]],
        "refrainFirst": false,
        "refrain": ["¡Soy feliz! ¡Soy feliz!", "Y en su favor me gozaré.", "En libertad y luz me vi", "cuando triunfó en mí la fe,", "y el raudal carmesí,", "salud de mi alma enferma fue."]
      }, {
        "name": "Con gozo canto al Señor",
        "number": "347",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["Con gozo canto al Señor", "desde que salvo soy,", "pues es mi Rey, mi Salvador,", "desde que salvo soy."], ["Yo amo a Dios por su bondad,", "desde que salvo soy,", "y quiero hacer su voluntad,", "desde que salvo soy."], ["Un gozo tengo que es sin par", "desde que salvo soy;", "a Cristo alabo sin cesar", "desde que salvo soy."], ["Yo tengo hogar, al cual iré,", "desde que salvo soy;", "y allí seguro viviré,", "desde que salvo soy."]],
        "refrainFirst": false,
        "refrain": ["Desde que salvo soy, desde que salvo soy", "solo en él me gloriaré; desde que salvo soy", "en mi Redentor me gloriaré."]
      }, {
        "name": "Dicha grande",
        "number": "348",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["Dicha grande es la del hombre cuyas sendas rectas son,", "lejos de los pecadores, lejos de la tentación.", "A los malos consejeros deja por temor al mal;", "huye de la gente impía, burladora e inmoral."], ["Antes en la Ley divina cifra su mayor placer,", "meditando día y noche en su divinal saber.", "Este, como el árbol verde, bien regado, y en sazón,", "frutos abundantes rinde, y hojas que perennes son."], ["Cuanto emprende es prosperado, duradero le es el bien.", "Muy diverso resultado sacan los que nada creen,", "pues se pierden como el tamo que el ciclón arrebató,", "de pasiones remolino que a millones destruyó."], ["En el juicio ningún malo, por lo tanto, se alzará.", "Entre justos congregados, insensatos nunca habrá,", "porque Dios la vía mira por la cual los suyos van.", "Otra es la de los impíos: a la nada volverán."]]
      }, {
        "name": "Gran gozo hay en mi alma hoy",
        "number": "349",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["Gran gozo hay en mi alma hoy:", "Jesús conmigo está;", "contento con su amor estoy,", "su dulce paz me da."], ["En mi alma hay melodías hoy,", "canciones a mi Rey.", "Feliz y libre en Cristo soy", "y salvo por la fe."], ["Paz plena tengo en mi alma hoy,", "pues Cristo me salvó.", "Mis hierros rotos quedan ya:", "Jesús me libertó."], ["En mi alma hoy reina gratitud", "y loores a Jesús.", "En su presencia hay virtud,", "hay gozo en su luz."]],
        "refrainFirst": false,
        "refrain": ["Brilla el sol de Cristo en mi alma;", "cada día voy feliz así.", "Su faz sonriente al contemplar,", "¡cuánto gozo siento en mí!"]
      }, {
        "name": "Andando en la luz de Dios",
        "number": "350",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["Andando en la luz de Dios", "encuentro plena paz;", "voy adelante sin temor", "dejando el mundo atrás."], ["Vagaba en oscuridad", "sin ver al buen Jesús,", "mas por su amor y su verdad", "me iluminó la luz."], ["Las nubes y la tempestad", "no encubren a Jesús,", "y en medio de la oscuridad", "me gozo en su luz."], ["Verelo pronto tal cual es:", "raudal de pura luz;", "y eternamente gozaré", "a causa de su cruz."]],
        "refrainFirst": false,
        "refrain": ["Gozo y luz hay en mi alma hoy,", "gozo y luz hay, ya que salvo soy;", "desde que a Jesús vi, y a su lado fui,", "he sentido el gozo de su amor en mí."]
      }, {
        "name": "Yo tengo gozo",
        "number": "351",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["Yo tengo gozo, gozo, en mi corazón;", "en mi corazón, en mi corazón;", "yo tenso gozo, gozo en mi corazón.", "¡Gloria sea a nuestro Dios!"], ["Yo tengo gozo, paz y alegría en mi corazón;", "en mi corazón, en mi corazón;", "yo tenso gozo, paz y alegría en mi corazón.", "¡Gloria sea a nuestro Dios!"], ["Yo tengo a Cristo, a Cristo en mi corazón;", "en mi corazón; en mi corazón;", "yo tengo a Cristo, a Cristo en mi corazón.", "¡Gloria sea a nuestro Dios!"]]
      }, {
        "name": "Gozaos, Cristo es Rey",
        "number": "352",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["Gozaos, Cristo es Rey, brindadle todo honor.", "Gozaos y cantad por siempre su loor.", "Alzad la voz, os digo que os gocéis", "de todo corazón."], ["Jesús, el Salvador, ya reina con amor.", "Lavó las manchas ascendió con gran honor.", "Alzad la voz, os digo que os gocéis", "de todo corazón."], ["Su reino es eternal y nunca fallará;", "la llave de la muerte en su mano está.", "Alzad la voz, os digo que os gocéis", "de todo corazón."], ["Gozaos y esperad, muy pronto el Juez vendrá;", "a su hogar eterno él os llevará.", "Alzad la voz, os digo que os gocéis", "de todo corazón."]]
      }, {
        "name": "Suenan melodías en mi ser",
        "number": "353",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["Jesús me dio un dulce canto,", "desde el cielo lo envió;", "jamás se oyó más dulce música", "que este canto de amor."], ["En el Calvario dio su vida", "por salvarme mi Jesús.", "Pagó mis culpas y perdón me dio,", "me llenó de gozo y luz."], ["Será el tema allá en la gloria", "de mi eterna adoración.", "Será gloriosa la armonía", "con la hueste angelical."]],
        "refrainFirst": false,
        "refrain": ["Suenan melodías en mi ser.", "Sí, suena un cantar, en tono celestial.", "Suenan melodías en mi ser.", "Sí, suenan en mi corazón."]
      }, {
        "name": "Voy caminando",
        "number": "354",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["Voy caminando siempre contento,", "rumbo al cielo con Jesús.", "No tengo miedo, no tengo dudas,", "pues Jesús conmigo está."], ["Cuando débil estoy,", "él su mano me da.", "Juntos vamos así,", "su amor cantando."], ["Voy caminando siempre contento,", "rumbo al cielo con Jesús.", "No tengo miedo, no tengo dudas,", "pues Jesús conmigo está."]]
      }, {
        "name": "Yo voy feliz",
        "number": "355",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["Aquel en cuyo corazón", "reside el santo amor,", "de gozo siempre la canción", "eleva al Salvador."], ["Jesús me ama, bien lo sé,", "pues oye mi clamor;", "y si lo sigo por la fe", "será mi Redentor."], ["La vida aquí terminará,", "el fin ha de llegar;", "mas la promesa fiel será:", "Jesús me salvará."]],
        "refrainFirst": false,
        "refrain": ["Yo voy feliz con Cristo, el Rey,", "mi Salvador, mi Protector.", "Yo voy feliz con Cristo, el Rey;", "mis cargas Jesús me quitó."]
      }, {
        "name": "Gozo es conocer a Cristo",
        "number": "356",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["Gozo es conocer a Cristo,", "permaneciendo fiel y listo,", "siempre andando en sus sendas.", "Gozo es el Señor."], ["Gozo es ser de él testigo,", "gozo es Cristo por amigo;", "y disfrutar de su presencia;", "gozo es el Señor."], ["Alegre estoy,", "no importa si hay aflicción;", "ya sé el secreto:", "Es Cristo en mi ser."], ["Gozo es Cristo a mi lado;", "tengo alivio del pecado,", "pronto iré al santo cielo.", "Gozo es el Señor."]]
      }, {
        "name": "Jesús, tú eres mi alegría",
        "number": "357",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["Jesús, tú eres mi alegría,", "Jesús, tú eres mi cantar.", "Tú eres la senda más hermosa,", "por donde yo quiero caminar.", "Jesús, tú eres primavera", "que hace todo florecer.", "Tú eres la llama que me alumbra", "y hace brillar todo mi ser."], ["Jesús, tú eres melodía,", "la voz, el canto y la emoción.", "Tú eres la fuerza que me inspira,", "el canto aunque haya aflicción.", "Jesús, tú eres la esperanza", "que da la paz al corazón.", "Jesús, tú eres la energía", "que mueve la vida a la acción."], ["Jesús, tú eres mi camino,", "verdad, vida y salvación.", "Jesús, tú eres mi victoria,", "motivo de mi adoración.", "Jesús, mi Salvador querido,", "a ti dedico mi vivir.", "Tú eres siempre mi sustento,", "Jesús, tú eres todo en mí."]]
      }, {
        "name": "En el seno de mi alma",
        "number": "358",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["En el seno de mi alma una dulce quietud", "se difunde embargando mi ser,", "una calma infinita que solo podrán", "los amados de Dios comprender."], ["¡Qué tesoro yo tengo en la paz que me dio!", "En el fondo de mi alma ha de estar", "tan seguro que nadie quitarlo podrá,", "mientras vea los siglos pasar."], ["Esta paz inefable consuelo me da,", "pues descanso tan solo en Jesús;", "y en peligro mi alma ya nunca estará,", "porque estoy inundado en su luz."], ["Alma triste, que en rudo conflicto te ves,", "sola y débil, tu senda al seguir,", "haz de Cristo tu amigo, que fiel siempre es,", "y su paz tú podrás recibir."]],
        "refrainFirst": false,
        "refrain": ["Paz, paz, cuán dulce paz", "la que da nuestro Padre eternal;", "le ruego que inunden por siempre mi ser", "sus ondas de amor celestial."]
      }, {
        "name": "Regocijaos siempre",
        "number": "359",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["Regocijaos siempre", "en Jesús, nuestro Señor.", "Regocijaos siempre", "en Jesús, nuestro Señor.", "Gozaos, gozaos,", "lo vuelvo a repetir.", "Gozaos, gozaos,", "en Jesús, nuestro Señor."]]
      }, {
        "name": "En Jesucristo, mártir de paz",
        "number": "360",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["En Jesucristo, mártir de paz,", "en horas negras y de tempestad,", "hallan las almas dulce solaz,", "grato consuelo, felicidad."], ["En nuestras luchas, en el dolor,", "en tristes horas de gran tentación,", "calma le infunde, santo vigor,", "nuevos alientos al corazón."], ["Cuando en la lucha falte la fe", "y esté el alma por desfallecer,", "Cristo nos dice: \"Siempre os daré", "gracia divina, santo poder\"."]],
        "refrainFirst": false,
        "refrain": ["Gloria cantemos al Redentor,", "que por nosotros quiso morir;", "y que la gracia del Salvador", "siempre dirija nuestro vivir."]
      }, {
        "name": "Percibe mi alma un son",
        "number": "361",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["Percibe mi alma un son", "de dulce y alegre canción", "que llevo en mi corazón:", "¡Oh paz, el don de mi Dios!"], ["La paz que en la cruz Cristo dio,", "do todas mis deudas pagó,", "en mí fiel cimiento echó.", "¡Oh paz, el don de mi Dios!"], ["Por rey al Señor coroné,", "y mi alma de paz se llenó", "y halló el don más rico mi fe:", "¡la paz, el don de mi Dios!"], ["En paz con Jesús moraré,", "y cuando a su lado esté", "su paz inefable tendré,", "¡la paz, el don de mi Dios!"]],
        "refrainFirst": false,
        "refrain": ["¡Paz, paz, dulce paz,", "don admirable de Dios!", "¡Oh, paz, maravilla de paz,", "el don de amor de mi Dios!"]
      }, {
        "name": "Con sin igual amor",
        "number": "362",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["Con sin igual amor Cristo me ama,", "su dulce paz en mi alma derrama,", "y por salvarme su vida dio:", "ya pertenezco a él."], ["Por mis pecados fui condenado,", "mas hoy por Cristo soy perdonado;", "del hondo abismo me levantó:", "ya pertenezco a él."], ["Mi corazón palpita de gozo,", "Cristo es de Dios el don más precioso;", "por redimirme su sangre dio,", "ya pertenezco a él."]],
        "refrainFirst": false,
        "refrain": ["Ya pertenezco a Cristo,", "¡cuán pura es su amistad!", "Por las edades durará,", "y por la eternidad."]
      }, {
        "name": "Hay un canto nuevo en mi ser",
        "number": "363",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["Hay un canto nuevo en mi ser,", "es la voz de mi Jesús;", "que me infunde dulce paz y amor,", "al andar yo en su luz."], ["Él me da su gracia celestial,", "al cumplir su voluntad;", "de su trono fluyen cual raudal,", "las riquezas de bondad."], ["Muchas pruebas debo soportar", "pero Cristo me guiará;", "y a mí por fe me toca andar,", "su amor me sostendrá."], ["Cristo en las nubes volverá,", "a llevarme al hogar;", "para siempre mi alma gozará,", "porque en Cristo supe fiar."]],
        "refrainFirst": false,
        "refrain": ["Cristo, Cristo, Cristo,", "nombre sin igual;", "llene siempre mi alma", "esa nota celestial."]
      }, {
        "name": "Jesús da paz",
        "number": "364",
        "category": "La vida cristiana",
        "subcategory": "Gozo y paz",
        "verses": [["Jesús da paz,", "y derriba cualquier pared", "de división; nos trae paz.", "Jesús da paz,", "y derriba cualquier pared", "de división; nos trae paz.", "A él entrega hoy", "toda tu ansiedad;", "te cuidará, paz te dará.", "A él entrega hoy", "toda tu ansiedad;", "te cuidará, paz te dará."]]
      }, {
        "name": "Elevemos al Señor",
        "number": "365",
        "category": "La vida cristiana",
        "subcategory": "Gratitud",
        "verses": [["Elevemos al Señor nuestros himnos de loor,", "pues los campos visitó con su rica bendición.", "Ya la siega terminó, la cosecha se guardó;", "el invierno llegará, pero nada faltará."], ["Este mundo es de Dios, el eterno Sembrador;", "y en su mies han de crecer hasta el fin el mal y el bien.", "Todo grano brotará, flor y espiga crecerá;", "Oh, Señor, queremos ser, el buen trigo en tu mies."], ["Ven, Señor, a recoger la cosecha de tu haber;", "junta al pueblo en tu alfolí, tenlo siempre unido a ti.", "Libre ya de su pecar y sin penas que pasar;", "ven, levanta, oh Señor, la cosecha de tu amor."]]
      }, {
        "name": "En Cristo hallo amigo",
        "number": "366",
        "category": "La vida cristiana",
        "subcategory": "Gratitud",
        "verses": [["En Cristo hallo amigo y amante Salvador;", "contaré, pues, cuanto ha hecho él por mí.", "Hallándome perdido e indigno pecador,", "rescatome y hoy me guarda para sí.", "Me salva del pecado, me guarda de Satán,", "promete estar conmigo siempre aquí;", "consuela mi tristeza, me quita todo afán;", "grandes cosas Cristo ha hecho ya por mí."], ["Jesús jamás me falta, jamás me dejará;", "es mi fuerte y poderoso protector.", "Del mundo hoy me aparto, de toda vanidad,", "para consagrar la vida a mi Señor.", "Si el mundo me persigue, si sufro tentación,", "confiando en él podré vencer aquí;", "segura es la victoria, y elevo mi canción:", "grandes cosas Cristo ha hecho ya por mí."], ["Bien sé que Cristo en gloria muy pronto volverá,", "y entretanto me prepara un bello hogar.", "En la casa de mi Padre, mansión de luz y paz,", "el creyente fiel con él ha de morar.", "Y entrado en su gloria, pesar no sentiré,", "pues contemplaré su rostro siempre allí;", "con los santos redimidos gozoso cantaré:", "grandes cosas Cristo ha hecho ya por mí."]]
      }, {
        "name": "Gracias, Dios",
        "number": "367",
        "category": "La vida cristiana",
        "subcategory": "Gratitud",
        "verses": [["Gracias, Dios, por la salvación;", "gracias, Dios, por tu gran perdón.", "Gracias, Dios, por darme a mí", "la vida eterna, oh ¡gloria a ti!"]]
      }, {
        "name": "Padre amado",
        "number": "368",
        "category": "La vida cristiana",
        "subcategory": "Gratitud",
        "verses": [["Yo te amo más que a todo;", "si estoy débil, tú eres fuerte", "y a mi lado estás.", "Te agradezco, Padre amado,", "porque unes nuestras vidas", "a tu corazón."], ["Yo te amo más que a todo;", "si estoy débil, tu gran fuerza", "nunca fallará.", "Por tu gracia, te imploro", "que transformes hoy mi vida", "con tu bendición."]],
        "refrainFirst": true,
        "refrain": ["Padre amado,", "te agradezco por tus planes,", "grandes sueños tienes para mí.", "Tú me guías por las sendas de esta vida,", "a tu lado nada faltará."]
      }, {
        "name": "Gratitud y alabanza",
        "number": "369",
        "category": "La vida cristiana",
        "subcategory": "Gratitud",
        "verses": [["Gracias por tus bendiciones, Señor;", "te alabamos por todo buen don;", "gozo llena nuestra alma hoy."]]
      }, {
        "name": "Por la excelsa majestad",
        "number": "370",
        "category": "La vida cristiana",
        "subcategory": "Gratitud",
        "verses": [["Por la excelsa majestad de los cielos, tierra y mar;", "por las alas de tu amor que nos cubren sin cesar;", "te ofrecemos, oh Señor, alabanzas con fervor."], ["Por la calma nocturnal, por la tibia luz del sol,", "por el amplio cielo azul, por el árbol, por la flor;", "te ofrecemos, oh Señor, alabanzas con fervor."], ["Por los lazos del amor que en familia y amistad", "nos acercan hacia ti, nuestro Amigo siempre fiel;", "te ofrecemos, oh Señor, alabanzas con fervor."], ["Por las dádivas sin par que en Cristo tú nos das:", "en los cielos, gozo y luz, en la tierra, vida y paz;", "te ofrecemos, oh Señor, alabanzas con fervor."]]
      }, {
        "name": "Jesús te ama",
        "number": "371",
        "category": "La vida cristiana",
        "subcategory": "Gratitud",
        "verses": [["Gracias te doy por darme esperanza", "que muy pronto volverás.", "Gracias te doy por tu sacrificio", "que me dio la salvación."], ["Gracias te doy por darte alabanza", "y feliz poder cantar.", "Gracias te doy por la vida eterna", "que en breve he de gozar."]],
        "refrainFirst": false,
        "refrain": ["Jesús te ama, Jesús en la cruz lo demostró.", "Jesús hoy te invita: Dame tu corazón."]
      }, {
        "name": "¿Cómo agradecer?",
        "number": "372",
        "category": "La vida cristiana",
        "subcategory": "Gratitud",
        "verses": [["¿Cómo agradecer lo que hiciste en mi favor?", "Aunque indigno soy, me salvaste por tu gran amor.", "Las voces de un millón de seres", "no alcanzan para agradecer;", "pues lo que soy,", "y lo que espero ser,", "lo debo todo a ti.", "A Dios sea gloria,", "a Dios sea gloria,", "a Dios sea gloria,", "¡tanto hizo por mí!", "Me salvó con su sangre,", "me libró del pecado;", "a Dios sea gloria,", "¡tanto hizo por mí!"], ["Quiero vivir, Señor,", "para agradarte solo a ti;", "y si algo puedo lograr", "te daré la gloria, mi Salvador.", "Me salvó con su sangre,", "me libró del pecado;", "a Dios sea gloria", "¡tanto hizo por mí!"]]
      }, {
        "name": "Mi Redentor es Cristo",
        "number": "373",
        "category": "La vida cristiana",
        "subcategory": "Oracion y comunión",
        "verses": [["Mi Redentor es Cristo,", "mi gozo y mi canción;", "me salva, y me consuela", "en horas de aflicción."], ["En todos mis afanes", "alivio y luz me das;", "aunque ande en negras sombras", "no dudaré jamás."], ["Jesús, en ti confío,", "amigo y guía fiel;", "te quiero más que al mundo", "y cuanto se halla en él."], ["Serás de mi alma el gozo", "aquí donde hay maldad;", "también serás mi dicha", "allá en la eternidad."]],
        "refrainFirst": false,
        "refrain": ["¡Oh, Salvador bendito,", "mi canto elevo a ti;", "cual tú no tengo amigo", "que me ame tanto aquí!"]
      }, {
        "name": "Dulce comunión",
        "number": "374",
        "category": "La vida cristiana",
        "subcategory": "Oracion y comunión",
        "verses": [["Dulce comunión la que gozo ya", "en los brazos de mi Salvador.", "¡Qué gran bendición en su paz me da!", "¡Oh!, yo siento en mí su tierno amor."], ["¡Cuán dulce es vivir, cuán dulce es gozar", "en los brazos de mi Salvador!", "Quiero estar con él en su eterno hogar,", "siendo objeto de su tierno amor."], ["No habré de temer ni aun desconfiar,", "en los brazos de mi Salvador.", "En él puedo yo bien seguro estar", "de los lazos del vil tentador."]],
        "refrainFirst": false,
        "refrain": ["Libre, salvo", "de cuitas, penas y dolor;", "libre, salvo,", "en los brazos de mi Salvador."]
      }, {
        "name": "Sed puros y santos",
        "number": "375",
        "category": "La vida cristiana",
        "subcategory": "Oracion y comunión",
        "verses": [["Sed puros y santos, mirad al Señor,", "permaneced fieles, orad sin cesar;", "y que la Palabra del buen Salvador", "os lleve en la vida a servir y amar."], ["Sed puros y santos, Dios nos juzgará;", "orad en secreto: respuesta vendrá.", "Su Espíritu Santo revela a Jesús,", "y su semejanza en nos él pondrá."], ["Sed puros y santos, Cristo nos guiará;", "seguid su camino, en él confiad;", "en paz o en zozobra, la calma dará", "quien nos ha salvado de nuestra maldad."]]
      }, {
        "name": "Dulce oración",
        "number": "376",
        "category": "La vida cristiana",
        "subcategory": "Oracion y comunión",
        "verses": [["Dulce oración, dulce oración,", "de toda influencia mundanal", "elevas tú mi corazón", "al tierno Padre celestial.", "¡Oh, cuántas veces tuve en ti", "auxilio en ruda tentación!", "¡Y cuántos bienes recibí", "mediante ti, dulce oración!"], ["Dulce oración, dulce oración,", "al trono excelso de bondad", "tú llevarás mi petición", "a Dios, que escucha con piedad.", "Creyendo espero recibir", "divina y plena bendición,", "y que me ayudes a vivir", "junto a mi Dios, dulce oración."], ["Dulce oración, dulce oración,", "aliento y gozo al alma das;", "en este valle de aflicción", "consuelo siempre me serás.", "Tan solo el día cuando esté", "con Cristo en la celeste Sion,", "entonces me despediré", "feliz, de ti, dulce oración."]]
      }, {
        "name": "A los pies de Jesucristo",
        "number": "377",
        "category": "La vida cristiana",
        "subcategory": "Oracion y comunión",
        "verses": [["A los pies de Jesucristo,", "¡qué palabras me habla a mí!", "¡Sitio tan feliz, precioso,", "cada día encuentro aquí!", "Contemplando lo pasado,", "vuelvo a ver hoy la visión", "del amor tan puro y santo", "que ganó mi corazón."], ["A los pies de Jesucristo,", "¡cuánto bien se puede hallar!", "Dejo aquí mi mal, mis penas,", "y hallo grato descansar.", "A los pies de Cristo, humilde,", "lloro y gozo al orar,", "y anhelo gracia diaria", "de su plenitud sacar."], ["¡Oh, Señor bendito, dame", "tu divina y santa paz!", "Mira con amor a tu hijo,", "vea yo tu dulce faz.", "Dame el ánimo de Cristo,", "hazme santo, justo y fiel;", "ande yo con Cristo siempre,", "porque mi justicia es él."]]
      }, {
        "name": "¡Oh, qué amigo nos es Cristo!",
        "number": "378",
        "category": "La vida cristiana",
        "subcategory": "Oracion y comunión",
        "verses": [["¡Oh, qué amigo nos es Cristo!", "Él sintió nuestra aflicción", "y nos manda que llevemos", "todo a Dios en oración.", "¿Vive el hombre desprovisto", "de consuelo y protección?", "Es porque no tiene dicho", "todo a Dios en oración."], ["¿Vives débil y cargado", "de temor y tentación?", "A Jesús, tu amigo eterno,", "cuenta todo en oración.", "¿Te desprecian tus amigos?", "Dilo a él en oración:", "en sus brazos cariñosos", "paz tendrá tu corazón."], ["Jesucristo es nuestro amigo;", "de esto pruebas mil mostró", "al sufrir el cruel castigo", "que el culpable mereció.", "Y su pueblo redimido", "hallará seguridad", "fiando en este amigo eterno", "y esperando en su bondad."]]
      }, {
        "name": "Habla, Señor, a mi alma",
        "number": "379",
        "category": "La vida cristiana",
        "subcategory": "Oracion y comunión",
        "verses": [["Habla, Señor, a mi alma; hable tu dulce voz;", "susurre en tierna notas: \"Tú no estás solo, no\".", "Mi corazón prepara, presto a escuchar tu Ley;", "canciones mi alma llenen de gratitud y fe."], ["Habla a tus hijos siempre, dales tu santidad;", "llénalos de tu gozo; enséñales a orar.", "A ti consagren todo, vivan tan solo en ti,", "traigan tu reino pronto, vean tu rostro aquí."], ["Habla cual en lo antiguo diste tu santa Ley.", "Tus testimonios siempre quiero guardar por fe.", "Quiero magnificarte, quiero a tu gloria dar", "el grato testimonio de obedecer y amar."]],
        "refrainFirst": false,
        "refrain": ["Háblame en dulces notas, háblame con amor:", "\"Ya la victoria es tuya, no tengas más temor\".", "Háblame cada día, hable tu tierna voz;", "susurre en mis oídos: \"Tú no estás solo, no\"."]
      }, {
        "name": "Ando con Cristo",
        "number": "380",
        "category": "La vida cristiana",
        "subcategory": "Oracion y comunión",
        "verses": [["Ando con Cristo, somos amigos,", "y mantenemos fiel comunión;", "ya de su lado nunca me aparto;", "¡cuánto me alienta su comprensión!"], ["Los oropeles vanos del mundo", "abandonelos sin vacilar.", "Siendo su amor tan caro y profundo,", "llena de encanto nuestra amistad."], ["Hasta las pruebas que en mi camino", "quieren quitarme todo valor,", "solo son ayos que me conducen", "a la presencia del Salvador."]],
        "refrainFirst": false,
        "refrain": ["Ando con Cristo, somos amigos,", "todas mis cuitas las llevo a él.", "Ando con Cristo, marcho a su lado,", "oigo la suave voz de Emanuel."]
      }, {
        "name": "De mañana veo su faz",
        "number": "381",
        "category": "La vida cristiana",
        "subcategory": "Oracion y comunión",
        "verses": [["De mañana, veo su faz;", "en la tarde, me da solaz", "siendo oscuro, su voz da luz;", "por doquiera yo voy con Jesús."]]
      }, {
        "name": "A solas al huerto yo voy",
        "number": "382",
        "category": "La vida cristiana",
        "subcategory": "Oracion y comunión",
        "verses": [["A solas al huerto yo voy", "cuando duerme aún la floresta;", "y en quietud y paz con Jesús estoy,", "oyendo absorto allí su voz."], ["Tan dulce es la voz del Señor,", "que las aves guardan silencio;", "y tan solo se oye esa voz de amor,", "que inmensa paz al alma da."], ["Con él encantado yo estoy,", "aunque en torno lleguen las sombras;", "mas me ordena ir, y a escuchar yo voy,", "su voz doquier la pena esté."]],
        "refrainFirst": false,
        "refrain": ["Él conmigo está, puedo oír su voz;", "y me dice que suyo soy;", "y el encanto que hallo en él allí,", "con nadie podré tener."]
      }, {
        "name": "Habla a tu Dios de mañana",
        "number": "383",
        "category": "La vida cristiana",
        "subcategory": "Oracion y comunión",
        "verses": [["Habla a tu Dios de mañana,", "háblale al mediodía;", "habla a tu Dios en la noche,", "y dale tu corazón."], ["Oye a tu Dios de mañana,", "óyele al mediodía;", "oye a tu Dios en la noche,", "y dale tu corazón."], ["Venga el Señor de mañana,", "venga en el mediodía;", "venga el Señor en la noche,", "prepara tu corazón."]]
      }, {
        "name": "El jardín de oración",
        "number": "384",
        "category": "La vida cristiana",
        "subcategory": "Oracion y comunión",
        "verses": [["Hay jardín memorable y hermoso,", "do las rosas exhalan su olor;", "allí Cristo en santo reposo", "levantó su plegaria de amor."], ["Un vergel de eternal primavera", "porque Dios allí da bendición.", "Es el sitio do Cristo me espera,", "el bendito rincón de oración."], ["Hay un huerto do Cristo me ofrece", "mis penurias y cargas quitar.", "Allí el gozo del cielo florece", "y mi copa se ve rebosar."], ["Es el sitio do Cristo te invita", "a implorar su bondad y perdón.", "Es allí donde el alma contrita", "se recoge con fe y devoción."]],
        "refrainFirst": false,
        "refrain": ["Oh, qué bello jardín, el jardín de oración", "donde el alma se encuentra con Dios.", "Allí miro al Señor y escucho su voz,", "en la hora de la oración."]
      }, {
        "name": "Hablando con Jesús",
        "number": "385",
        "category": "La vida cristiana",
        "subcategory": "Oracion y comunión",
        "verses": [["Hablando con Jesús,", "todo está muy bien;", "hablando con Jesús,", "todo está muy bien.", "Si es mucho mi penar,", "lo alabo sin cesar,", "hablando con Jesús,", "todo está muy bien."]]
      }, {
        "name": "Hay un lugar de paz",
        "number": "386",
        "category": "La vida cristiana",
        "subcategory": "Oracion y comunión",
        "verses": [["Hay un lugar de paz,", "de sin igual solaz,", "do Cristo alivio a mi alma da.", "Sólo con él allí,", "lejos del arduo frenesí,", "le entrego mi ansiedad.", "Voy al jardín a orar,", "al monte a meditar", "y nuevas fuerzas recibir.", "De allí saldré por fe", "para servir donde él me lleve,", "brindando su amor."]]
      }, {
        "name": "Aparte del mundo",
        "number": "387",
        "category": "La vida cristiana",
        "subcategory": "Oracion y comunión",
        "verses": [["Aparte del mundo, Señor, me retiro,", "de lucha y tumultos ansioso de huir;", "de escenas horribles do el mal victorioso", "extiende sus redes y se hace servir."], ["El sitio apartado, la sombra tranquila,", "convienen al culto de ruego y loor;", "tu mano divina los hizo, sin duda,", "en bien del que humilde te sigue, Señor."], ["Allí, si tu aliento inspira a mi alma,", "y llega la gracia mi pecho a tocar,", "mis labios podrán, en tu altar encendidos,", "cantar alabanza a tu gloria sin par."], ["Te debo tributos de amor y de gracias", "por este abundante y glorioso festín,", "y cantos que puedan oírse en los cielos", "por años sin cuento, por siglos sin fin."]]
      }, {
        "name": "Debo decir a Cristo",
        "number": "388",
        "category": "La vida cristiana",
        "subcategory": "Oracion y comunión",
        "verses": [["Debo decir a Cristo mis pruebas;", "solo no puedo cargas llevar.", "Cristo me ayuda en mis angustias;", "pues con amor me puede cuidar."], ["Debo decirle mis aflicciones;", "él es mi Amigo fiel y leal.", "Cuando le pido, Cristo me escucha;", "calma mis penas, borra mi mal."], ["Las atracciones que el mundo ofrece,", "tientan mi alma para pecar.", "Solo con Cristo llego a ser fuerte", "y por su gracia puedo triunfar."]],
        "refrainFirst": false,
        "refrain": ["Debo decir a Cristo mis penas;", "solo no puedo cargas llevar.", "Debo decir a Cristo mis penas;", "solo Jesús las puede aliviar."]
      }, {
        "name": "Conversar con Jesucristo",
        "number": "389",
        "category": "La vida cristiana",
        "subcategory": "Oracion y comunión",
        "verses": [["Conversar con Jesucristo", "a través de la oración", "llena el alma de alegría,", "trae paz al corazón;", "y si tú también quisieras", "esta bendición gozar,", "dondequiera te encontrares", "no te olvides de orar."]]
      }, {
        "name": "Soy yo, Señor",
        "number": "390",
        "category": "La vida cristiana",
        "subcategory": "Oracion y comunión",
        "verses": [["Soy yo, soy yo, soy yo, Señor,", "el que necesita orar.", "Soy yo, soy yo, soy yo, Señor,", "el que necesita orar.", "No es mi madre, ni mi padre,", "sino yo, Señor,", "el que necesita orar.", "No es mi hermano, ni mi hermana,", "sino yo, Señor", "el que necesita orar."]]
      }, {
        "name": "¿Le importará a Jesús?",
        "number": "391",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["¿Le importará a Jesús", "que esté doliente mi corazón?", "Si ando en senda oscura de aflicción", "¿puede darme consolación?"], ["¿Le importará que en oscuridad", "camine con gran temor?", "Al anochecer, en la lobreguez,", "¿me acompañará el Salvador?"], ["¿Le importará si mi voluntad", "faltare en la prueba atroz;", "si he cedido al mal, a la tentación,", "y el llanto ahoga mi voz?"], ["¿Le importará cuando diga \"adiós\"", "al amigo más caro y fiel,", "y mi corazón lleno de aflicción", "haya de apurar la hiel?"]],
        "refrainFirst": false,
        "refrain": ["Le importa, sí; su corazón comparte ya mi dolor.", "Sí, mis días tristes, mis noches negras", "le importan al Señor."]
      }, {
        "name": "Hay quien vela mis pisadas",
        "number": "392",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Hay quien vela mis pisadas en la sombra y en la luz;", "por las sendas escarpadas me acompañará Jesús.", "Por los valles, por los montes, do me lleva su bondad,", "miro yo los horizontes de una nueva claridad."], ["Hay contacto que me explica la presencia del Señor;", "él mis penas santifica y me libra del temor.", "Cristo con su mano herida protección me puede dar", "cuando cruzo de la vida el inquieto y fiero mar."], ["Tengo Amigo, fiel y amante, de infinita compasión,", "que confiere paz constante al contrito corazón.", "En su amor hay eficacia, es amigo siempre fiel,", "hay en él verdad y gracia, es mi escudo y mi broquel."]],
        "refrainFirst": false,
        "refrain": ["Hay quien vela mis pisadas", "y mi senda trazará.", "A las célicas moradas", "es Jesús quien me guiará."]
      }, {
        "name": "Mi fe contempla a ti",
        "number": "393",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Mi fe contempla a ti,", "Cordero celestial,", "mi Salvador.", "Oye mi petición,", "quita mi transgresión,", "sea tu posesión", "desde hoy, Señor."], ["Al débil corazón", "tu gracia pueda dar", "fe y valor.", "Moriste tú por mí,", "sea mi amor a ti", "puro y ferviente así,", "cual vivo ardor."], ["Por senda oscura voy,", "no veo en derredor;", "mas me guiarás. Enjuga mi llorar,", "mi noche haz brillar", "y así no pueda errar", "de ti jamás."]]
      }, {
        "name": "¡Cuán firme cimiento!",
        "number": "394",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["¡Cuán firme cimiento ha puesto a la fe", "el Padre en su eterna Palabra de amor!", "¿Qué más a su pueblo pudiera añadir", "de lo que en su Libro ha dicho el Señor,", "de lo que en su Libro ha dicho el Señor?"], ["\"No tengas temor, pues contigo yo estoy.", "Sí, yo soy tu Dios, y te socorreré.", "Apoyo, sostén, fortaleza y poder.", "Con mi diestra justa yo te salvaré,", "con mi diestra justa yo te salvaré."], ["\"Las aguas profundas no te anegarán,", "ni aun cuando cruzares el mar de aflicción;", "pues siempre contigo en tu angustia andaré,", "trocando tus penas en gran bendición,", "trocando tus penas en gran bendición."], ["\"Si te hallas probado en ardiente crisol", "mi gracia potente tu fe sostendrá;", "tan solo la escoria deseo quemar,", "y el oro de tu alma más puro saldrá,", "y el oro de tu alma más puro saldrá\"."]]
      }, {
        "name": "¡Oh, cuán dulce es fiar en Cristo!",
        "number": "395",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["¡Oh, cuán dulce es fiar en Cristo", "y entregarle todo a él,", "esperar en sus promesas,", "y en sus sendas serle fiel!"], ["Es muy dulce fiar en Cristo", "y cumplir su voluntad,", "sin dudar de su palabra,", "siempre andando en la verdad."], ["Siempre quiero fiar en Cristo,", "mi precioso Salvador,", "que en la vida y en la muerte", "me sostiene con su amor."]],
        "refrainFirst": false,
        "refrain": ["¡Cristo!, ¡Cristo!, ¡cuánto te amo!", "Tu poder probaste en mi.", "¡Cristo!, ¡Cristo!, puro y santo,", "siempre quiero fiar en ti."]
      }, {
        "name": "¡Oh qué Salvador!",
        "number": "396",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["¡Oh qué Salvador es Jesús, el Señor!", "¡Bendito Señor para mí!", "El salva al más malo de su iniquidad,", "le brinda socorro aquí."], ["Veré a los amados a quienes dejé;", "con ellos por siempre estaré.", "Más quiero aún al amado Jesús:", "sus glorias yo entonaré."], ["Y cuando esta vida termine aquí,", "la lucha al fin dejaré;", "entonces a Cristo podré contemplar;", "loor a su nombre daré."], ["Y cuando en las nubes descienda Jesús,", "glorioso en el mundo a reinar,", "su gran salvación y perfecto amor,", "por siglos yo he de cantar."]],
        "refrainFirst": false,
        "refrain": ["Me escondo en la Roca que es Cristo, el Señor,", "y allí nada ya temeré;", "me escondo en la Roca que es mi Salvador,", "y en él siempre yo confiaré,", "y siempre con él viviré."]
      }, {
        "name": "Oh buen Señor, velada está",
        "number": "397",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Oh buen Señor, velada está", "tu santa forma aquí,", "pues el pecado, oh Jehová,", "te esconde hoy de mí."], ["Aunque no te distingo hoy,", "sé que conmigo estás;", "y si en afán o lucha estoy,", "tu auxilio me darás."], ["Como un sueño nocturnal,", "dorada ensoñación,", "es tu presencia sin igual", "la más feliz visión."], ["Tu forma puedo percibir", "en alas de la fe;", "con tu influencia en mi vivir,", "confiado andaré."]]
      }, {
        "name": "Cuando sopla airada la tempestad",
        "number": "398",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Cuando sopla airada la tempestad", "y la barca en grave peligro está,", "no se puede andar con seguridad", "sin tener el ancla que Dios nos da."], ["Arrecifes hay que marcando van", "el sendero triste de muerte cruel,", "donde vidas mil naufragando están", "sin tener un ancla ni timonel."], ["En las negras ondas de la ansiedad,", "cuando soplan vientos de destrucción,", "nuestra barca cruza la inmensidad,", "del Señor llevando la protección."]],
        "refrainFirst": false,
        "refrain": ["Ancla tenemos que nos dará", "apoyo firme en la tempestad;", "en la Roca eterna fija está;", "solo allí tendremos seguridad."]
      }, {
        "name": "En estos tiempos",
        "number": "399",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["En estos tiempos necesitas", "un Salvador, un ancla firme.", "Debes tener seguridad", "de que tu ancla en la Roca esté."], ["En estos tiempos necesitas", "la Santa Biblia, la Palabra.", "Debes tener seguridad", "de que tu ancla en la Roca esté."], ["En estos tiempos sé que tengo", "un Salvador, un ancla firme.", "Seguro estoy, seguro estoy,", "sé que mi ancla en la Roca está."]],
        "refrainFirst": false,
        "refrain": ["La Roca es Cristo,", "no hay otra igual;", "la Roca es Cristo,", "no hay otra igual.", "[1 y 2:] Debes tener seguridad", "de que tu ancla en la Roca esté.", "[3:] Seguro estoy, seguro estoy,", "sé que mi ancla en la Roca está."]
      }, {
        "name": "Castillo fuerte es nuestro Dios",
        "number": "400",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Castillo fuerte es nuestro Dios;", "defensa y buen escudo.", "Con su poder nos librará", "en este trance agudo.", "Con furia y con afán", "acósanos Satán.", "Por armas deja ver", "astucia y gran poder;", "cual él no hay en la tierra."], ["Luchar aquí sin el Señor,", "cuán vano hubiera sido.", "Mas por nosotros pugnará", "de Dios el Escogido.", "¿Sabéis quién es? Jesús,", "el que venció en la cruz;", "Señor de Sebaoth,", "omnipotente Dios,", "él triunfa en la batalla."], ["Aun cuando estén demonios mil", "prontos a devorarnos,", "no temeremos, porque Dios", "vendrá a defendernos.", "Que muestre su vigor", "Satán, y su furor;", "dañarnos no podrá,", "pues condenado está", "por la Palabra santa."], ["Sin destruir la dejará,", "aunque mal de su grado:", "es la Palabra del Señor", "que lucha a nuestro lado.", "Si llevan con furor", "los bienes, vida, honor,", "no habremos de temer;", "todo ha de perecer,", "de Dios el reino queda."]]
      }, {
        "name": "Eterna Roca es mi Jesús",
        "number": "401",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Eterna Roca es mi Jesús,", "refugio en la tempestad;", "confianza he puesto yo en él,", "refugio en la tempestad."], ["Es sombra en día de calor,", "refugio en la tempestad;", "defensa eterna es mi Señor", "refugio en la tempestad."], ["¡Oh!, Roca eterna, mi Jesús,", "refugio en la tempestad,", "sé tú mi guía, fuerza y luz,", "refugio en la tempestad."]],
        "refrainFirst": false,
        "refrain": ["Roca eterna, nuestra protección,", "nuestra fuerza, nuestro Salvador,", "nuestro auxilio en la tribulación,", "consolación en el dolor."]
      }, {
        "name": "¡Oh!, salvo en la Roca",
        "number": "402",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["¡Oh!, salvo en la Roca más alta que yo,", "la Roca de vida que Cristo abrió,", "brindando a su pueblo las aguas de paz.", "oh, Roca divina, serás mi solaz."], ["Si vienen conflictos, tumultos, dolor:", "a Cristo, mi Roca, yo voy sin temor;", "recibo consuelo, consejos y luz;", "la Roca divina es mi amante Jesús."], ["Y cuando mi vida se acerque a su fin", "y oiga por fe el divino clarín,", "tus límpidas aguas trayendo virtud,", "oh, Roca divina, serán mi salud."], ["El lóbrego valle no temo cruzar,", "pues tú me guiarás y me harás descansar;", "seguro en tu guarda, con paz dormiré:", "Jesús, el Eterno, es mi Roca, mi Rey."]],
        "refrainFirst": false,
        "refrain": ["Salvo en ti, salvo en ti,", "¡oh, Roca bendita,", "me escondo en ti!"]
      }, {
        "name": "Cuando en la lucha",
        "number": "403",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Cuando en la lucha me falte poder,", "alguien sabrá, alguien sabrá.", "¿Quién a mis ayes fin puede poner?", "alguien sabrá: es Cristo."], ["Olas de pruebas no las temeré;", "alguien sabrá, alguien sabrá;", "pues cuando mi alma en las sombras esté,", "alguien sabrá: es Cristo."], ["Débil, herido, abrumado del mal,", "alguien sabrá, alguien sabrá;", "miro con ansia al hogar celestial;", "alguien sabrá: es Cristo."]],
        "refrainFirst": false,
        "refrain": ["Alguien sabrá, alguien sabrá;", "cuando me oprima y me tiente Satán,", "Cristo me guardará siempre;", "alguien sabrá, es Cristo."]
      }, {
        "name": "A cualquiera parte",
        "number": "404",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["A cualquiera parte sin temor iré", "si Jesús dirige mi inseguro pie.", "Sin su compañía todo es pavor,", "mas si él me guía no tendré temor."], ["Con Jesús por guía adondequiera voy.", "Caminando en pos de él seguro estoy.", "y aunque padre y madre puédenme faltar,", "Jesucristo nunca me abandonará."], ["Dondequiera pueda estar, en tierra y mar,", "quiero ser su fiel testigo sin cesar.", "Y si por desierto mi camino va,", "un seguro albergue mi Jesús será."], ["Dondequiera afrontaré la noche atroz,", "porque siempre oigo su benigna voz;", "Él de día y noche a mi lado está;", "y en plena gloria me despertará."]],
        "refrainFirst": false,
        "refrain": ["Con Jesús por doquier, sin temor iré;", "si Jesús me guía, nada temeré."]
      }, {
        "name": "Sé quién es Jesús",
        "number": "405",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["No tengo duda, sé quién es Jesús;", "ya no me engañará el tentador."], ["Estoy contento, pues conmigo está", "a toda hora y en cualquier lugar."]],
        "refrainFirst": false,
        "refrain": ["Él es mi Salvador, mi Amigo fiel;", "me guía siempre con su amor.", "Él es mi Salvador, mi Amigo fiel,", "me guía siempre con su amor."]
      }, {
        "name": "Jesús es mi luz",
        "number": "406",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Jesús es mi luz, jamás temeré;", "de día y de noche en luz andaré.", "En horas de llanto, de luto y dolor,", "consuelo y gozo me infunde el Señor."], ["Jesús es mi luz; si en males estoy,", "al Padre benigno seguro yo voy.", "Allí está Cristo, mi fiel Mediador;", "sus méritos siempre pondrá a mi favor."], ["Jesús es mi luz, mi amparo y broquel;", "victoria obtendré; me mantiene muy fiel.", "Si débil estoy en mi ayuda vendrá;", "su brazo potente mi andar sostendrá."], ["Jesús es mi luz, mi todo, mi bien;", "le doy alma y cuerpo y mi mente también.", "Entono al Cordero alegre loor;", "con todos sus santos le rindo honor."]],
        "refrainFirst": false,
        "refrain": ["Jesús es mi luz, mi todo, mi bien;", "de día y de noche él es mi sostén.", "Jesús es mi luz, mi todo, mi bien;", "de día y de noche él es mi sostén."]
      }, {
        "name": "Muy cerca de mi Redentor",
        "number": "407",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Hay un lugar do quiero estar", "muy cerca de mi Redentor;", "allí podré yo descansar", "al fiel amparo de su amor."], ["Quitarme el mundo no podrá", "la paz que halló mi corazón;", "Jesús amante me dará", "la más segura protección."], ["Ni dudas ni temor tendré", "estando cerca de Jesús;", "rodeado siempre me veré", "con los fulgores de su luz."]],
        "refrainFirst": false,
        "refrain": ["Muy cerca de mi Redentor", "seguro asilo encontraré;", "me guardará del tentador,", "y ya de nada temeré."]
      }, {
        "name": "Cristo me ayuda por él a vivir",
        "number": "408",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Cristo me ayuda por él a vivir,", "Cristo me ayuda por él a morir.", "Hasta que llegue su gloria a ver,", "cada momento le entrego mi ser."], ["¿Siento pesares? Muy cerca él está.", "¿Siento dolores? Alivio me da.", "¿Tenso aflicciones? Me muestra su amor;", "cada momento me guarda el Señor."], ["¿Tengo amarguras? o ¿tengo temor?", "¿Tengo tristezas? Me inspira valor.", "¿Tengo conflictos o penas aquí?", "Cada momento se acuerda de mí."], ["¿Tengo flaquezas? o ¿débil estoy?", "Cristo me dice: \"Tu amparo yo soy\".", "Cada momento, en sombra o en luz,", "siempre anda junto conmigo Jesús."]],
        "refrainFirst": false,
        "refrain": ["Cada momento la vida me da;", "cada momento conmigo él está.", "Hasta que llegue su gloria a ver,", "cada momento le entrego mi ser."]
      }, {
        "name": "Si mi débil fe flaqueare",
        "number": "409",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Si mi débil fe flaqueare,", "él me sostendrá.", "Si el mal me amenazare,", "él me sostendrá."], ["Nunca yo podré afirmarme", "con tan débil fe;", "mas él puede dirigirme,", "y me sostendrá."], ["Son su gozo y complacencia", "cuantos él salvó,", "y al salvarme su clemencia,", "él me sostendrá."], ["Él no quiso ver perdida", "mi alma en la maldad;", "dio su sangre por mi vida,", "y él me sostendrá."]],
        "refrainFirst": false,
        "refrain": ["Él me sostendrá,", "él me sostendrá.", "Porque me ama el Salvador,", "él me sostendrá."]
      }, {
        "name": "Cuando te quiero",
        "number": "410",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Cuando te quiero, cerca tú estás;", "de nada temo, buen Salvador;", "siempre bondadoso me sostendrás,", "cuando te quiero más."], ["Cuando te quiero, listo tú estás,", "y abandonarme nunca podrás;", "paz por tristeza siempre me das,", "cuando te quiero más."], ["Cuando te quiero, mi buen Jesús,", "hora tras hora tú me guiarás;", "tiernos cuidados tú me darás,", "cuando te quiero más."], ["Cuando te quiero, vienes, Jesús,", "y tu presencia muy dulce es;", "corren las aguas de salvación,", "cuando te quiero más."]],
        "refrainFirst": false,
        "refrain": ["Cuando te quiero más,", "cuando te quiero más,", "cerca tú estás, mi buen Salvador,", "cuando te quiero más."]
      }, {
        "name": "Bajo sus alas",
        "number": "411",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Bajo sus alas ¡seguro descanso!", "Aunque anochece y amaga el turbión,", "en él confío, su brazo me guarda;", "hijo soy de su eternal redención."], ["Bajo sus alas ¡eterno refugio!", "Tanto lo anhela este fiel corazón.", "Si para mí no hay remedio en la tierra,", "Cristo me sana y me da bendición."], ["Bajo sus alas ¡placer indecible!", "Me escondo aquí mientras pase mi afán.", "Fiel protección de mi fiero enemigo,", "paz y salud me proporcionarán."]],
        "refrainFirst": false,
        "refrain": ["Salvo en Jesús, salvo en Jesús,", "¿quién de él podrá apartarme?", "Bajo sus alas siempre estaré", "salvo y seguro por siempre."]
      }, {
        "name": "Todas las promesas",
        "number": "412",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Todas la promesas del Señor Jesús", "son apoyo poderoso de mi fe.", "Mientras luche aquí buscando yo su luz,", "siempre en sus promesas confiaré."], ["Todas sus promesas para el hombre fiel,", "el Señor, por su Palabra, cumplirá;", "y confiado sé que para siempre en él", "dulce paz mi vida gozará."], ["Todas las promesas del Señor serán", "gozo y fuerza en nuestra vida terrenal;", "ellas en la dura lid nos sostendrán,", "y triunfar podremos sobre el mal."]],
        "refrainFirst": false,
        "refrain": ["Grandes, fieles,", "todas las promesas que el Señor ha dado;", "grandes, fieles,", "en ellas yo por siempre confiaré."]
      }, {
        "name": "Si la carga es pesada",
        "number": "413",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Si la carga es pesada,", "mirad a Dios;", "si vuestra alma está cansada,", "mirad a Dios."], ["El deber os llama, hermanos,", "mirad a Dios;", "pues dejad placeres vanos,", "mirad a Dios."], ["Cuando el mal parece fuerte,", "mirad a Dios;", "si os espanta aún la muerte,", "mirad a Dios."]],
        "refrainFirst": false,
        "refrain": ["Levantad la vista al cielo,", "mirad a Dios;", "si vuestra alma está cansada,", "mirad a Dios."]
      }, {
        "name": "¡Oh, buen Maestro, despierta!",
        "number": "414",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["¡Oh, buen Maestro, despierta! ¡Ve, ruge la tempestad!", "¡La gran extensión de los cielos se llena de oscuridad!", "¿No ves que aquí perecemos? ¿Puedes dormir así", "cuando el mar agitado nos abre profundo sepulcro aquí?"], ["Despavorido, oh Maestro, te busco con ansiedad.", "Mi alma angustiada se abate; arrecia la tempestad.", "Pasa el pecado a torrentes sobre mi frágil ser,", "y perezco, perezco, Maestro, ¡oh, quiéreme socorrer!"], ["Vino la calma, Maestro. Los vientos no rugen ya.", "Y sobre el cristal de las aguas el sol resplandecerá.", "Cristo, prolonga esta calma; no me abandones más;", "cruzaré los abismos contigo al puerto de eterna paz."]],
        "refrainFirst": false,
        "refrain": ["Los vientos, las ondas oirán tu voz: \"Haya paz\".", "Calmas la iras del negro mar;", "las luchas del alma las haces cesar,", "y así la barquilla do va el Señor", "hundirse no puede en el mar traidor,", "Doquier se cumpla tu voluntad: \"Haya paz, haya paz\".", "Tu voz resuena en la inmensidad: \"Paz, haya paz\"."]
      }, {
        "name": "Salvo en los tiernos brazos",
        "number": "415",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Salvo en los tiernos brazos de mi Jesús seré,", "y en su amoroso pecho siempre reposaré.", "Este es sin duda el eco de celestial canción,", "que de inefable gozo llena mi corazón."], ["En sus amantes brazos hallo solicitud;", "líbrame de tristeza, líbrame de inquietud.", "Y si vinieren pruebas, fáciles pasarán;", "lágrimas si vertiere, pronto se enjugarán."], ["Y cruzaré la noche lóbrega, sin temor,", "hasta que venga el día de perennal fulgor.", "¡Cuán placentero entonces con él será morar,", "y en la mansión de gloria siempre con él reinar!"]],
        "refrainFirst": false,
        "refrain": ["Salvo en los tiernos brazos", "de mi Jesús seré,", "y en su amoroso pecho", "siempre reposaré."]
      }, {
        "name": "¡Oh!, tenga yo la ardiente fe",
        "number": "416",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["¡Oh!, tenga yo la ardiente fe", "que ante enemigos mil", "no temblará ni aun cuando esté,", "ni aun cuando esté", "en la miseria vil,", "en la miseria vil."], ["Tal fe, que no se quejará", "ni bajo corrección,", "en hora amarga afirmará,", "afirmará", "en Dios el corazón,", "en Dios el corazón."], ["Refulge más si hay tempestad", "rugiendo en derredor,", "y cruzará la oscuridad,", "la oscuridad", "sin dudas ni temor,", "sin dudas ni temor."], ["Oh, dame a mí, Señor, tal fe,", "y, venga bien o mal,", "estando aquí gustar podré,", "gustar podré", "la dicha celestial,", "la dicha celestial."]]
      }, {
        "name": "Dame la fe de mi Jesús",
        "number": "417",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Dame la fe de mi Jesús,", "es mi oración, oh buen Señor:", "la fe que al alma da la paz,", "la fe que salva de temor;", "fe de los santos galardón,", "gloriosa fe de salvación."], ["Dame la fe que trae poder,", "que a los demonios da terror;", "que fieras no podrán vencer,", "ni dominarla el opresor;", "fe de los santos galardón,", "gloriosa fe de salvación."], ["Dame la fe que vencerá,", "bendita fe de mi Jesús.", "Dame la fe que fijará", "mi vista en su divina cruz;", "fe de los santos galardón,", "gloriosa fe de salvación."], ["Dame la fe que da el valor,", "que ayuda al débil a triunfar,", "que todo sufre con amor", "y puede en el dolor cantar;", "fe de los santos galardón,", "gloriosa fe de salvación."]]
      }, {
        "name": "Padre, yo vengo a ti",
        "number": "418",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Padre, yo vengo a ti; solo hay en ti poder.", "Protégeme, Señor, mi ayudador.", "Sombras tan solo aquí, más densas hoy que ayer,", "me cercan, mas me harás un vencedor."], ["Ampárame, Señor; dame tu dulce amor.", "Aleja el mal de mí, dame tu paz.", "¡Oh, Padre, líbrame de todo cruel dolor,", "y en el cielo al fin vea tu faz!"], ["Padre, yo vengo a ti; dame tu bendición.", "Confío en ti, Señor; sé mi sostén.", "Tu gracia y tu poder llenen mi corazón.", "Guíame por tu amor al santo Edén."]],
        "refrainFirst": false,
        "refrain": ["Padre, yo vengo a ti,", "oye mi voz;", "confío solo en ti,", "dame tu amor."]
      }, {
        "name": "Por la justicia de Jesús",
        "number": "419",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Por la justicia de Jesús,", "la sangre que por mí vertió,", "alcánzase perdón de Dios", "y cuanto bien nos prometió;", "que solo él rescata sé;", "segura base es de mi fe,", "segura base es de mi fe."], ["Así, turbada no veré", "mi paz, su incomparable don.", "Aun cuando un tiempo oculto esté", "me dejará su bendición.", "En mí no puedo hallar jamás", "la base firme de la paz,", "la base firme de la paz."], ["En la tormenta es mi sostén", "el pacto que juró y selló.", "Su amor es mi supremo bien,", "pues en la cruz me redimió.", "Jesús, la Peña, me será", "base única que durará,", "base única que durará."]]
      }, {
        "name": "Nunca desmayes",
        "number": "420",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Nunca desmayes, que en el afán", "Dios cuidará de ti;", "sus fuertes alas te cubrirán;", "Dios cuidará de ti."], ["En duras pruebas y en aflicción,", "Dios cuidará de ti;", "en tus conflictos y en tentación,", "Dios cuidará de ti."], ["De sus riquezas te suplirá;", "Dios cuidará de ti;", "jamás sus bienes te negará;", "Dios cuidará de ti."], ["Si vienen pruebas o cruel dolor,", "Dios cuidará de ti;", "tus cargas pon sobre el Salvador;", "Dios cuidará de ti."]],
        "refrainFirst": false,
        "refrain": ["Dios cuidará de ti;", "velando está su tierno amor;", "sí, cuidará de ti, Dios cuidará de ti."]
      }, {
        "name": "Cariñoso Salvador",
        "number": "421",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Cariñoso Salvador, huyo de la tempestad", "a tu seno protector, fiándome de tu bondad.", "Sálvame, Señor Jesús, de las olas, del turbión;", "hasta el puerto de salud guía tú mi embarcación."], ["Otro asilo aquí no hay, indefenso acudo a ti;", "mi necesidad me trae, porque mi peligro vi.", "Solamente en ti, Señor, hallo paz, consuelo y luz;", "vengo lleno de temor a los pies de mi Jesús."], ["Cristo, encuentro en ti poder, y no necesito más;", "me levantas, al caer; débil, ánimo me das.", "Al enfermo das salud, vista das al que no ve.", "Con amor y gratitud tu bondad ensalzaré."]]
      }, {
        "name": "Nada puede ya faltarme",
        "number": "422",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Nada puede ya faltarme, porque Dios mis pasos guía", "a la tierra saludable, en divinos frutos rica.", "Dulce néctar de reposo son sus aguas cristalinas;", "ellas dan salud al alma y la llenan de delicias."], ["Por la senda me conduce confortando el alma mía,", "por amor a su gran nombre, fuente viva de justicia.", "Cuando el tenebroso valle cruce de la muerte fría,", "no tendré temor alguno siendo Dios el que me guía."], ["Con su vara y su cayado me dará consuelo y vida,", "y ante los que me persiguen mesa me pondrá, surtida.", "Con el bálsamo divino da alivio a toda herida,", "y rebosa ya la copa que me colma de alegría."], ["La misericordia santa seguirá la senda mía,", "y de Dios en las mansiones moraré por largos días.", "Nada puede ya faltarme porque Dios mis pasos guía;", "él es mi Pastor amante, con ternura, fiel me cuida."]]
      }, {
        "name": "Pertenezco a mi Rey",
        "number": "423",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Pertenezco a mi Rey, hijo soy de su amor,", "y a sus regias moradas iré.", "Sus delicias sin fin revelome el Señor,", "y un lugar con sus hijos tendré."], ["Pertenezco a mi Rey, y que me ama lo sé;", "y a sus dones de amor celestial", "por doquiera que voy sin cesar hallaré", "como prueba de amor sin igual."], ["Pertenezco a mi Rey, y no dudo jamás", "que reunidos los suyos serán", "en la Sion celestial, que es morada de paz,", "do pesares jamás se hallarán."]],
        "refrainFirst": false,
        "refrain": ["Pertenezco a mi Rey, hijo soy de su amor,", "a los suyos jamás olvidó.", "En su regia mansión he de ver al Señor,", "y a su lado, feliz, viviré."]
      }, {
        "name": "¿Cómo podré estar triste?",
        "number": "424",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["¿Cómo podré estar triste, cómo entre sombras ir,", "cómo sentirme solo y en el dolor vivir?", "Si Cristo es mi consuelo, mi amigo siempre fiel;", "si aun las aves tienen seguro asilo en él.", "Si aun las aves tienen seguro asilo en él."], ["\"Nunca te desalientes\", oigo al Señor decir;", "y en su Palabra fiado hago al dolor huir.", "A Cristo paso a paso yo sigo sin cesar;", "y todas sus bondades me da sin limitar.", "Y todas sus bondades me da sin limitar."], ["Siempre que soy tentado o que en la sombra estoy,", "más cerca de él camino, y protegido voy.", "Si en mí la fe desmaya, y caigo en la ansiedad,", "¡Tan solo él me levanta, me da seguridad!", "¡Tan solo él me levanta, me da seguridad!"]],
        "refrainFirst": false,
        "refrain": ["¡Feliz cantando, alegre,", "yo vivo siempre aquí.", "Si él cuida de las aves,", "cuidará también de mí!"]
      }, {
        "name": "Día en día",
        "number": "425",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Día en día Cristo está conmigo,", "me consuela en medio del dolor.", "Pues confiando en su poder eterno,", "no me afano, ni me da temor.", "Sobrepuja todo entendimiento", "la perfecta paz del Salvador.", "En su amor tan grande e infinito", "siempre me dará lo que es mejor."], ["Día en día Cristo me acompaña", "y me brinda dulce comunión.", "Todos mis cuidados él los lleva;", "a él entrego mi alma y corazón.", "No hay medida del amor supremo", "de mi bondadoso y fiel Pastor.", "Él me suple lo que necesito", "pues el Pan de vida es mi Señor."], ["Oh, Señor, ayúdame este día", "a vivir de tal manera aquí", "que tu nombre esté glorificado", "pues anhelo honrarte sólo a ti.", "Con la diestra de tu gran justicia", "me sustentas en la turbación.", "Tus promesas son sostén y guía,", "siempre en ellas hay consolación."]]
      }, {
        "name": "Tengo paz",
        "number": "426",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Si paz como un río inunda mi ser,", "o pruebas azotan mi fe;", "no importa mi suerte, seguro estaré,", "pues mi alma está en paz con mi Dios."], ["Mi mal ha ocultado su rostro de mí;", "mas Cristo clavolo en la cruz;", "perdón alcancé, su amor me salvó.", "¡Gloria a Dios, hoy mi alma está en paz!"], ["Señor, apresura el día final;", "las nubes partirse veré.", "Vendrá el Señor, la trompeta oiré.", "¡Gloria a Dios, hoy mi alma está en paz!"]],
        "refrainFirst": false,
        "refrain": ["Tengo paz con mi Dios;", "tengo paz, tengo paz con mi Dios."]
      }, {
        "name": "Lleva todo tu pesar a Cristo",
        "number": "427",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Lleva todo tu pesar a Cristo,", "el mejor amigo para ti;", "te consuela y siempre está cercano,", "quiere bendecirte en tu vivir."], ["Si la ingratitud te hiere el alma;", "si amargura viene al corazón;", "mira que Jesús está a tu lado", "y te da consuelo en la aflicción."], ["Cristo te comprende en tus dolores;", "quiere tu alma enferma restaurar.", "Oh, confía en su amor inmenso;", "solo él te puede aliviar."]],
        "refrainFirst": false,
        "refrain": ["Lleva tu pesar a Cristo;", "él lo cargará y tus fuerzas repondrá.", "Lleva tu pesar a Cristo;", "en las pruebas él te sostendrá."]
      }, {
        "name": "Su oveja soy",
        "number": "428",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Junto a aguas tranquilas", "me apacienta Jesús;", "juntos andamos al atardecer.", "Todos somos ovejas", "de su tierno redil;", "su oveja soy.", "Aguas hay, protección,", "cuando estoy con el Señor Jesús.", "Soledad, aflicción,", "ya no temo con Jesús."]]
      }, {
        "name": "Él puede",
        "number": "429",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Él puede, él puede, yo sé que él puede;", "yo sé que Cristo puede salvarme del mal.", "Él puede, él puede, yo sé que él puede;", "yo sé que Cristo puede salvarme del mal.", "Sanó al quebrantado, al muerto levantó;", "él hizo al cojo caminar, y al ciego vista dio.", "Él puede, él puede, yo sé que él puede;", "yo sé que Cristo puede salvarme del mal."]]
      }, {
        "name": "Solo no estoy",
        "number": "430",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Solo no estoy, pues Cristo me acompaña", "por todas partes donde quiera voy.", "Mi corazón se alegra con su gracia:", "a cuantos quieran él les da su amor."], ["Solo no estoy, incluso en la tormenta,", "cuando me acosan vientos de aflicción.", "Seguro estoy pues sé que va conmigo", "y en sus brazos él me llevará."]],
        "refrainFirst": false,
        "refrain": ["Solo no estoy, Jesús está a mi lado;", "en él hallé amigo sin igual.", "De tal amigo apoyo y guía tengo;", "solo no estoy, Jesús conmigo está."]
      }, {
        "name": "A él mis problema le doy",
        "number": "431",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["A él mis problemas le doy;", "le traigo mis tristezas,", "las pongo a sus pies;", "y cada vez que yo no sé qué debo hacer,", "yo le doy mis problemas a él.", "A él mis problemas le doy;", "le traigo mis tristezas,", "las pongo a sus pies;", "y cada vez que yo no sé qué debo hacer,", "yo le doy mis tristezas al Señor,", "yo le doy mis problemas a él."]]
      }, {
        "name": "Como el ciervo",
        "number": "432",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Como el ciervo clama por las aguas,", "mi alma anhela a Dios.", "Solo tú satisfaces mi ansiedad", "llenándome de paz.", "Coro:", "Solo tú eres mi sostén,", "¿a quién iré sino solo a ti?", "Yo te rindo mi vida entera", "para siempre a ti, Señor."], ["Cristo, tú eres mi amigo y mi hermano,", "aunque eres Rey también.", "Yo te amo por sobre todo", "y te alabo, mi Señor."], ["Más que al oro te anhelo a ti,", "pues solo tú me podrás saciar.", "Solo tú eres mi alegría,", "la razón de mi existir."]]
      }, {
        "name": "Conmigo marcha un ángel",
        "number": "433",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["La ruta es larga y cruenta, es difícil ver el fin;", "pero mi alma entona un canto pues conmigo un ángel va.", "La noche es negra y turbia, no la alumbra débil luz;", "pero mi alma entona un canto pues conmigo un ángel va.", "Me cuida de la tentación, me cuida por su amor.", "Si oro, también ora él; va siempre junto a mí.", "Me olvidan mis amigos y muy solo puedo estar;", "pero mi alma entona un canto pues conmigo un ángel va."], ["Si nubes me rodean y una carga he de llevar,", "con valor y fe prosigo pues conmigo un ángel va.", "Un día la tristeza puede mi alma inundar,", "pero seguiré confiando que a mi lado el ángel va.", "Conmigo a la montaña va, conmigo sobre el mar.", "No hay miedo ni ningún temor; va el ángel junto a mí.", "El fin ha de llegar y mi Salvador vendrá; y mis ojos han de abrirse,", "y a mi ángel yo veré que hoy junto a mí está."]]
      }, {
        "name": "Jesús es mi vida",
        "number": "434",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Cuando el cielo y sus estrellas", "me hablan del amor de Dios;", "y las flores, al abrirse,", "buscan el calor del sol;", "pienso en ese amor eterno", "que cuidado a todos da;", "me inspira cada día", "para amarlo más y más."], ["Él conoce, él escucha,", "y me dice qué hacer.", "Él comparte mis momentos,", "sabe cómos y porqués.", "Él me guía a cada paso,", "me da siempre lo mejor;", "y aunque a veces no comprendo,", "me consuela con su amor."]],
        "refrainFirst": false,
        "refrain": ["Él es mi Vida; él es mi Luz;", "mi gran Amigo; mi buen Jesús.", "Nada marchitará ni cambiará", "mi amor por él.", "Mi Dios y mi Señor es todo para mí."]
      }, {
        "name": "Dios sabe, Dios oye, Dios ve",
        "number": "435",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Si hoy tú te sientes pequeño", "dirige tu vista a Dios.", "No dejes que sombras te envuelvan;", "entrega tu vida a Dios."], ["Si todos tus sueños se pierden;", "la vida se torna hostil;", "oh, nunca te des por vencido;", "entrega tus planes a Dios."]],
        "refrainFirst": false,
        "refrain": ["Dios sabe lo que hay en tu alma;", "Dios oye tu plegaria ferviente;", "Dios ve tu angustia y te calma;", "Dios hace de ti un gigante.", "Dios sabe lo que hay en tu alma;", "Dios oye tu plegaria ferviente;", "Dios ve tu angustia y te calma;", "Dios sabe, Dios oye, Dios ve."]
      }, {
        "name": "Él vive hoy",
        "number": "436",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Dios nos envió a Jesucristo", "para sufrir en mi lugar.", "Vivió y murió por rescatarme;", "vacía está su tumba, sé que él vive hoy."], ["Cuán dulce es un tierno niño,", "cuánta ternura y gozo da.", "Pero es mejor saber que él puede", "triunfar mañana porque Cristo vive hoy."]],
        "refrainFirst": false,
        "refrain": ["Él vive hoy, no hay mañana incierto.", "Él vive hoy, ya no hay temor.", "Mi vida tiene más sentido ahora,", "pues mi futuro en sus manos hoy está."]
      }, {
        "name": "Tu presencia, Padre amado, da consuelo",
        "number": "437",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Tu presencia, Padre amado,", "da consuelo, trae paz.", "Tu presencia, oh Señor,", "da seguridad al alma.", "Tu presencia danos hoy."]]
      }, {
        "name": "Mira hacia Dios",
        "number": "438",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Alegre y dulce es el vivir,", "o también puede triste ser;", "si quieres ánimo sentir,", "solo esto habrás de hacer:"], ["Si oscuras nubes ves llegar,", "si algún amigo te es infiel;", "cuando comienzas a dudar,", "solo esto habrás de hacer:"], ["Cuando no anhelas la oración,", "y ves tu fe desfallecer;", "si vagas lejos del Señor,", "solo esto habrás de hacer:"]],
        "refrainFirst": false,
        "refrain": ["Mira hacia Dios;", "Roca inmutable te será.", "Mira hacia Dios;", "él no te faltará jamás.", "Mira hacia Dios,", "y oscuridad no temerás.", "Confianza y fe tendrás,", "mirando a Dios."]
      }, {
        "name": "¡Oh!, quién pudiera andar con Dios",
        "number": "439",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["¡Oh!, quién pudiera andar con Dios,", "su dulce paz gozar,", "volviendo a ver de nuevo el sol", "de santidad y amor."], ["¡Oh!, tiempo aquel en que oí", "la dulce voz de Dios;", "su fiel acento de amor", "oyó mi corazón."], ["Contrito hoy me acerco a ti,", "pidiendo tu merced.", "Perdona el pecado vil", "con que te contristé."]]
      }, {
        "name": "Quiero, Jesús, contigo andar",
        "number": "440",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Quiero, Jesús, contigo andar,", "y en tu servicio trabajar;", "dime el secreto de saber", "llevar mis cargas con placer."], ["Haz que mi lengua sepa hablar", "solo el lenguaje del amor,", "y al extraviado pueda guiar", "hasta el redil de mi Pastor."], ["Tenme a tu lado, enséñame", "a ser paciente, noble y fiel;", "que en tus pisadas pueda andar,", "y al abatido consolar."], ["Dame del cielo aquella fe", "que en la tormenta ve la luz.", "Colme mi alma tu bondad,", "y viva siempre con tu paz."]]
      }, {
        "name": "Jesús, te necesito",
        "number": "441",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Jesús, te necesito, pues soy tan pobre y vil;", "recorro, peregrino, un mundo muy hostil.", "Tu amor será mi apoyo; me anima ver tu faz;", "en medio del peligro descanso en tu paz."], ["Jesús, te necesito, anhelo a ti servir;", "amargas aflicciones tú puedes compartir.", "Tu Espíritu me ayuda en cada tentación,", "me da en cada prueba consuelo y salvación."], ["Jesús, te necesito; muy pronto te veré,", "y en calles celestiales contigo andaré.", "Y con los redimidos elevaré mi voz", "cantándote loores, mi Salvador, mi Dios."]]
      }, {
        "name": "¡Oh! ¡Maestro y Salvador!",
        "number": "442",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["¡Oh! ¡Maestro y Salvador!,", "no me dejes desmayar;", "en tu gracia y en tu amor", "solo fío sin cesar,", "solo fío sin cesar."], ["Pobre y débil sé que soy,", "lo confieso, mi Señor.", "A tus pies rendido estoy,", "dame fuerzas y valor,", "dame fuerzas y valor."], ["Dime tú lo que he de ser,", "las palabras que he de hablar,", "lo que siempre debo hacer,", "mientras voy hacia el hogar,", "mientras voy hacia el hogar."], ["Solo así feliz seré", "en mi vida espiritual;", "solo así morar podré", "en la patria celestial,", "en la patria celestial."]]
      }, {
        "name": "Hay un lugar do quiero estar, cerca de ti",
        "number": "443",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Hay un lugar do quiero estar, cerca de ti, Señor;", "allí podré yo descansar en tu divino amor.", "Oh, ven, Jesús bendito, lléname de tu amor;", "mantenme siempre firme cerca de ti, Señor."], ["Hay un lugar de dulce paz, cerca de ti, Señor.", "Vivir yo quiero donde estás, querido Salvador.", "Oh, ven, Jesús bendito, lléname de tu amor;", "mantenme siempre firme cerca de ti, Señor."], ["Solo hay segura salvación cerca de ti, Señor;", "hay gozo y luz y bendición cerca de ti, Señor.", "Oh, Salvador bendito, recibe a un pecador,", "y tómame en tus brazos de paternal amor."]]
      }, {
        "name": "No me pases",
        "number": "444",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["No me pases, no me olvides,", "tierno Salvador;", "muchos gozan tus mercedes,", "oye mi clamor."], ["Ante el trono de tu gracia", "hallo dulce paz;", "nada aquí mi alma sacia,", "tú eres mi solaz."], ["Solo fío en tus bondades,", "guíame en tu luz,", "y mi alma no deseches,", "sálvame, Jesús."], ["Fuente viva de consuelo", "eres para mí;", "mi alma pone en ti su anhelo,", "solamente en ti."]],
        "refrainFirst": false,
        "refrain": ["Cristo, Cristo, oye tú mi voz;", "Salvador, tu gracia dame, oye mi clamor."]
      }, {
        "name": "Más de Jesús",
        "number": "445",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Más de Jesús deseo saber,", "más de su gracia conocer,", "más de su salvación gozar,", "más de su dulce amor gustar."], ["Más de Jesús deseo oír,", "más de su santa Ley cumplir,", "más de su voluntad saber,", "más de su Espíritu tener."], ["Más de Jesús, más oración,", "más cerca estar en comunión,", "más su Palabra meditar,", "más sus promesas alcanzar."], ["Más de Jesús allá veré,", "más semejante a él seré,", "más de su gloria he de gozar,", "más su gran nombre he de alabar."]],
        "refrainFirst": false,
        "refrain": ["Más quiero amarle,", "más quiero honrarle,", "más de su salvación gozar,", "más de su dulce amor gustar."]
      }, {
        "name": "Más cerca, oh Dios, de ti",
        "number": "446",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Más cerca, oh Dios, de ti quiero morar;", "aunque sobre una cruz me hayan de alzar.", "Entonaré allí este himno con fervor:", "Más cerca, oh Dios, de ti, más cerca, sí."], ["Si cual viajero voy con ansiedad,", "medroso al ver cerrar la oscuridad,", "aún en mi soñar me harás sentir que estoy", "más cerca, oh Dios, de ti, más cerca, sí."], ["Después, al despertar, a ti por fe", "de mi aflicción altar elevaré.", "Cercano sentiré el ángel del Señor.", "Más cerca, oh Dios, de ti, más cerca, sí."], ["Y cuando en tu mansión vea tu faz,", "y estrellas, luna y sol yo deje atrás,", "gozoso entonaré canción eterna allí:", "Más cerca, oh Dios, de ti, más cerca, sí."]]
      }, {
        "name": "Más santidad dame",
        "number": "447",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Más santidad dame, más odio al mal,", "más calma en las penas, más alto ideal;", "más fe en el Maestro, más consagración,", "más celo en servirle, más fe en la oración."], ["Más prudente hazme, más sabio en él,", "más firme en su causa, más fuerte y más fiel;", "más recto en la vida, más triste al pecar,", "más humilde hijo, más pronto en amar."], ["Más pureza dame, más fuerza en Jesús;", "más de su dominio, más paz en la cruz;", "más rica esperanza, más obras aquí,", "más ansia del cielo, más gozo allí."]]
      }, {
        "name": "Salvador, mi bien eterno",
        "number": "448",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Salvador, mi bien eterno,", "más que vida para mí,", "en mi fatigosa senda", "tenme siempre junto a ti;", "junto a ti, junto a ti,", "junto a ti, junto a ti;", "en mi fatigosa senda", "tenme siempre junto a ti."], ["No me afano por placeres,", "ni renombre busco aquí;", "vengan pruebas o desdenes,", "tenme siempre junto a ti;", "junto a ti, junto a ti,", "junto a ti, junto a ti;", "vengan pruebas o desdenes,", "tenme siempre junto a ti."], ["En el valle de la muerte", "no te alejes, sino allí,", "tu presencia me aliente", "tenme siempre junto a ti;", "junto a ti, junto a ti,", "junto a ti, junto a ti;", "tu presencia me aliente,", "tenme siempre junto a ti."]]
      }, {
        "name": "Cristo, mi piloto sé",
        "number": "449",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Cristo, mi piloto sé en el tempestuoso mar.", "Fieras ondas mi bajel van a hacerlo zozobrar.", "Mas si tú conmigo vas, pronto al puerto llegaré.", "Carta y brújula hallo en ti. ¡Cristo, mi piloto sé!"], ["Todo agita el huracán con indómito furor;", "mas los vientos cesarán al mandato de tu voz.", "Y al decir que haya paz, cederá sumiso el mar.", "De las aguas, tú el Señor, eres mi piloto fiel."], ["Cuando al fin cercano esté de la playa celestial,", "si el abismo ruge aún entre el puerto y mi bajel,", "en tu pecho al descansar quiero oír tu voz decir:", "\"Nada temas ya del mar, tu piloto siempre soy\"."]]
      }, {
        "name": "¡Oh Jesús, Pastor divino!",
        "number": "450",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["¡Oh Jesús, Pastor divino! Acudimos a rogar", "que desciendas amoroso tus corderos a buscar.", "¡Oh Pastor!, ven, tu rebaño te reclama sin cesar,", "te reclama sin cesar."], ["Al herido del pecado no lo dejes sucumbir;", "al que va por otra senda déjale tu voz oír.", "¡Ven, Pastor!, el lobo llega y nos quiere destruir,", "y nos quiere destruir."], ["Guíanos por tus senderos al aprisco del amor;", "llévanos cual corderitos en tu seno bienhechor.", "Guía, sí, a tus corderos, amantísimo Pastor,", "amantísimo Pastor."], ["Oye, Cristo, nuestro ruego, oye nuestra petición;", "ven, ampara tu rebaño con tu santa protección.", "Te lo piden tus corderos con humilde corazón,", "con humilde corazón."]]
      }, {
        "name": "Cerca, más cerca",
        "number": "451",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Cerca, más cerca, Cristo, de ti,", "fiel Salvador y mi eterno solaz;", "guárdame junto a tu corazón;", "siempre me abrigue ese Puerto de paz,", "siempre me abrigue ese Puerto de paz."], ["Cerca, más cerca; nada traeré,", "nada de mérito, al pie de tu cruz;", "solo mi herido y vil corazón,", "porque en tu sangre lo limpies, Jesús,", "porque en tu sangre lo limpies, Jesús."], ["Cerca, más cerca: tuyo seré;", "dejo con gozo el pecado falaz,", "todo su orgullo, pompa y placer;", "Cristo inmolado es mi eterno solaz,", "Cristo inmolado es mi eterno solaz."], ["Cerca, más cerca hasta el fin,", "hasta que ancle en el Puerto de amor,", "do por los siglos viva feliz,", "cerca, más cerca, de mi Salvador,", "cerca, más cerca, de mi Salvador."]]
      }, {
        "name": "Contigo quiero andar",
        "number": "452",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Contigo quiero andar, oh Dios,", "tal como Enoc, en pos de ti.", "Mi temblorosa mano ten;", "tu voz ya calme el vaivén.", "La senda oscura al transitar,", "Jesús, contigo quiero andar."], ["No puedo solo andar, Señor:", "tormentas rugen en redor,", "rodéanme engaños mil,", "me acosa el enemigo vil.", "¡Oh, calma el borrascoso mar!", "Jesús, contigo quiero andar."], ["Mi mano ten, pues; de hoy en mas,", "del mundo el mal dejando atrás,", "valiente seguiré tu luz,", "con la bandera de la cruz.", "Espero en Sion poder entrar,", "do yo contigo quiero andar."]]
      }, {
        "name": "Cómo ser cual Cristo",
        "number": "453",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Dime cómo debo amar,", "dime cómo trabajar;", "cómo en mi trato ser", "para ser cual Cristo."], ["Dime qué preparación", "puedo emprender aquí,", "esperando al Señor,", "para ser cual Cristo."], ["Dime, Padre, cómo hablar;", "dime, Padre, cómo orar;", "dime siempre al andar", "cómo ser cual Cristo."]],
        "refrainFirst": false,
        "refrain": ["Cómo ser cual Cristo,", "cómo ser cual Cristo;", "dime siempre al andar", "cómo ser cual Cristo."]
      }, {
        "name": "Yo quisiera andar con Cristo",
        "number": "454",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Yo quisiera andar con Cristo", "sobre el mar de Galilea;", "a su lado ir por Judea,", "ayudarlo en su gran misión."], ["Yo quisiera andar con Cristo,", "ver sus manos sanadoras,", "escucharlo siempre hablando", "del divino y celestial amor."], ["Yo quisiera estar con Cristo", "en el cielo con el Padre.", "Que me lleve cuando regrese,", "para estar muy junto a él, allá."]],
        "refrainFirst": false,
        "refrain": ["Con Cristo estar, con Cristo andar,", "poder sentir esa emoción.", "Bien sé, Señor, que un día estaré", "muy junto a ti, Jesús, en tu hogar."]
      }, {
        "name": "Mi mano ten",
        "number": "455",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Mi mano ten, Señor; me siento débil:", "sin ti no puedo riesgos afrontar;", "tenla Señor; mi vida el gozo llene", "de verme libre así de todo azar."], ["Mi mano ten; permite que me animen", "mi regocijo, mi esperanza en ti;", "tenla Señor, y compasivo impide", "que caiga en mal cual una vez caí."], ["Mi mano ten; mi senda es tenebrosa", "si no la alumbra tu radiante faz;", "por fe si alcanzo a percibir tu gloria,", "¡cuán grande gozo!, ¡cuán profunda paz!"]]
      }, {
        "name": "Como la mujer de junto al pozo",
        "number": "456",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Como la mujer de junto al pozo", "yo fui por agua, nada más.", "A Cristo oí decir con gozo:", "\"Ven a mí, y sed jamás tendrás\"."], ["Hay millones en el mundo enfermo", "que buscan goces sin valor,", "muy lejos del tesoro eterno", "que encuentro en Cristo, mi Señor."], ["Si las cosas vanas que brinda el mundo", "tu hambre dejan sin saciar,", "acude al pozo más profundo,", "fuente viva de salud sin par."]],
        "refrainFirst": false,
        "refrain": ["Cristo, llena mi frágil copa,", "ven y calma mi agobiante sed.", "Oh, Maestro, dame tu divino pan", "como dádiva de tu gran merced."]
      }, {
        "name": "More en mí la belleza del Salvador",
        "number": "457",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["More en mí la belleza del Salvador;", "su pureza tener pueda y fervor.", "¡Oh, divino Jesús! toma todo mi ser,", "y que puedan en mí tu belleza ver."]]
      }, {
        "name": "Orad por mí",
        "number": "458",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Orad por mí los que me amáis,", "la vida es dura para mí.", "Será más suave si me dais", "la fe en Jesús que yo perdí."], ["Orad por mí los que me amáis,", "si sufro en medio del dolor.", "Orad por lo que más queráis,", "que Dios me dé su gran valor."], ["Orad por mí los que me amáis,", "así mi fe no flaqueará.", "Seré más fuerte si oráis,", "y Dios mis pasos guardará."]],
        "refrainFirst": false,
        "refrain": ["Al Cielo orad en mi favor,", "que pueda yo vencer el mal;", "y ser, en Dios, un vencedor;", "y de su luz ser un fanal."]
      }, {
        "name": "Háblame más de Cristo",
        "number": "459",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Quiero escuchar de aquel grande amor", "que en el Calvario Dios nos mostró;", "quiero escuchar del buen Salvador,", "¡háblame más de Cristo!"], ["Cuando me asalte la tentación", "y aviesas redes tienda a mi pie;", "quiero tener en él protección,", "¡háblame más de Cristo!"], ["Cuando en la lucha falte la fe", "y el alma sienta desfallecer;", "quiero saber que ayuda tendré,", "¡háblame más de Cristo!"]],
        "refrainFirst": false,
        "refrain": ["Quiero escuchar la historia fiel", "de mi Jesús, mi Salvador;", "quiero vivir tan solo por él,", "¡háblame más de Cristo!"]
      }, {
        "name": "Quiero estar cerca de Cristo",
        "number": "460",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Quiero estar cerca de Cristo,", "en su presencia habitar.", "Siempre yo quiero servirlo,", "siempre a su lado he de andar."], ["Quiero estar cerca de Cristo,", "¡nada me separará!", "Pues por su gracia soy salvo,", "su voluntad he de obrar."], ["Quiero estar junto a mi Cristo,", "su fortaleza me da.", "Quiero contar sus bondades,", "y en su sendero avanzar."]],
        "refrainFirst": false,
        "refrain": ["Quiero estar junto a mi Cristo,", "quiero estar cerca de él.", "Es un Amigo constante", "y un Salvador siempre fiel."]
      }, {
        "name": "A tu lado anhelo estar",
        "number": "461",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Oh, Señor, muy débil soy;", "guárdame de todo mal.", "Solo en ti seguro estoy;", "junto a ti, mi Jesús, quiero andar."], ["En las pruebas y el dolor,", "cuando acosa el tentador,", "solo tú, Señor Jesús,", "eres mi fortaleza y luz."], ["Junto a ti, mi Salvador,", "para siempre quiero estar.", "Oh, condúceme al final,", "a tu dulce hogar celestial."]],
        "refrainFirst": false,
        "refrain": ["A tu lado anhelo estar;", "es mi ruego, mi Señor.", "Diariamente junto a ti,", "guíame para andar en tu amor."]
      }, {
        "name": "Dame a Cristo",
        "number": "462",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["De mañana al despertar,", "de mañana al despertar,", "de mañana al despertar,", "dame a Cristo."], ["En la noche al descansar,", "en la noche al descansar,", "en la noche al descansar,", "dame a Cristo."]],
        "refrainFirst": false,
        "refrain": ["Dame a Cristo,", "dame a Cristo.", "Nada más quiero yo,", "dame a Cristo."]
      }, {
        "name": "Mi oración",
        "number": "463",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Que mi vida, cada día,", "cada paso que dé", "sea un cuadro de amor,", "cual Cristo fue.", "Y que crezca en Jesús,", "que conozca", "más y más de su amor", "y aumente mi fe.", "Hasta el día feliz", "cuando junto a él", "pueda siempre vivir", "en su gloria:", "es mi oración."]]
      }, {
        "name": "Ven, inspíranos",
        "number": "464",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Ven, inspíranos; ven, enséñanos;", "Señor, por medio de tu Espíritu.", "Ven, anímanos; ven, transfórmanos;", "Señor, con tu poder y gloria."], ["Ven, ayúdanos; ven, consuélanos;", "Señor, concédenos tu dulce paz.", "Danos tu poder para obedecer", "y amarte por tu sacrificio."]],
        "refrainFirst": false,
        "refrain": ["Señor, queremos dar loor", "en gratitud a ti.", "Acepta nuestro corazón;", "Ven y transfórmalo hoy."]
      }, {
        "name": "Ven junto a mí",
        "number": "465",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Oh, Jesús, ven muy junto a mí.", "Oh, Jesús, ven muy junto a mí.", "Mientras ando por el mundo,", "oh, Jesús, ven muy junto a mí."], ["En mis pruebas ven junto a mí.", "En mis pruebas ven junto a mí.", "Si las sombras me rodean,", "oh, Jesús, ven muy junto a mí."], ["En mis penas ven junto a mí.", "En mis penas ven junto a mí.", "Si en mi alma hay tristeza,", "oh, Jesús, ven muy junto a mí."]]
      }, {
        "name": "Guíame, ¡oh Salvador!",
        "number": "466",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Guíame, ¡Oh Salvador!,", "por la senda de salud.", "A tu lado no hay temor;", "solo hay gozo, paz, quietud."], ["No me dejes, ¡oh Señor!,", "mientras en el mundo esté.", "Haz que arribe, sin temor,", "donde en ti descansaré."], ["Tú, de mi alma salvación", "en la ruda tempestad,", "al venir la tentación,", "¡que me libre tu piedad!"]],
        "refrainFirst": false,
        "refrain": ["¡Cristo! ¡Cristo!", "¡No me dejes, oh Señor!", "Siendo tú mi guía fiel,", "saldré más que vencedor."]
      }, {
        "name": "¡Siempre el Salvador conmigo!",
        "number": "467",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["¡Siempre el Salvador conmigo!", "Nada soy sin su poder.", "Su presencia necesito:", "voy, sin él, a perecer."], ["¡Siempre el Salvador conmigo!", "Puédeme la fe faltar.", "Sus palabras me consuelan;", "cual él nadie puede hablar."], ["¡Siempre el Salvador conmigo!", "Ilumíneme su faz", "en la calma, en la tormenta,", "en la lucha y en la paz."], ["¡Siempre el Salvador conmigo!", "Sus consejos me guiarán", "hasta que esté en la orilla", "anhelada del Jordán."]],
        "refrainFirst": false,
        "refrain": ["Me guiará mi Salvador;", "hoy en él yo confiaré.", "Con amor donde él me lleve", "sus pisadas seguiré."]
      }, {
        "name": "Paso a paso Dios me guía",
        "number": "468",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Paso a paso Dios me guía;", "¿qué más puedo ya pedir?", "Nunca dudo de su gracia,", "pues conmigo puede ir."], ["Paso a paso Dios me guía;", "gozo siempre al alma da;", "fuerza da al que es tentado;", "lo alimenta del maná."], ["Paso a paso Dios me guía.", "De mi afán fatigador,", "el descanso ha prometido", "en su reino mi Señor."]],
        "refrainFirst": false,
        "refrain": ["Paz divina y consuelo", "al confiar en él tendré,", "pues si algo sucediere,", "Cristo lo sabrá muy bien;", "pues si algo sucediere,", "Cristo lo sabrá muy bien."]
      }, {
        "name": "Jesús me guía",
        "number": "469",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Jesús me guía. ¡Cuánta paz", "he hallado en dicho tan veraz!", "En todo afán seguro estoy,", "pues Dios me guarda; suyo soy."], ["Ayer tinieblas y vaivén;", "mañana un florido Edén.", "Bonanza en torno o tempestad,", "me ampara siempre su bondad."], ["Señor, la mano que me das,", "contento tomo de hoy en más.", "Acepto alegre el porvenir,", "pues Dios me quiere conducir."], ["Y terminado mi quehacer,", "en mí probado tu poder,", "la muerte misma no huiré,", "pues aun allí contigo iré."]],
        "refrainFirst": false,
        "refrain": ["Jesús me guía, esto sé;", "su propia mano me guiará.", "En toda senda oscura aquí", "el Salvador conmigo va."]
      }, {
        "name": "Guíame, Dios",
        "number": "470",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Guíame, Dios,", "guía en tu justicia;", "tu senda allana frente a mí.", "Pues solo tú, Dios,", "solo tú, Dios,", "me haces vivir confiado."]]
      }, {
        "name": "Condúceme, Maestro",
        "number": "471",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Condúceme, Maestro, por tu bondad,", "y así jamás me falte seguridad;", "no puedo un solo paso sin ti andar;", "oh, mi Jesús, sé siempre mi Luminar."], ["En tu amor envuelve mi corazón,", "y dale paz y calma en la aflicción;", "que halle en ti reposo allá en la cruz,", "que siempre me halle cerca de ti, Jesús."], ["Y cuando fuerte azote la tempestad,", "y al alma desanime la adversidad,", "condúceme, Maestro, por tu bondad,", "y así jamás me falte seguridad."]]
      }, {
        "name": "Jesús, mi guía es",
        "number": "472",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Jesús, mi guía es,", "mi amigo y protector,", "refugio de todo mal;", "y cuando temor me da,", "yo confío en él.", "Yo confío en él;", "aunque débil soy", "me da su poder y su amor."], ["Jesús, mi Redentor,", "me guardas del temor,", "y llenas mi corazón", "con cantos de salvación;", "confiaré en ti.", "Yo confío en ti;", "aunque débil soy", "me das tu poder y tu amor.", "Yo confío en ti."]]
      }, {
        "name": "Háblame, y hablaré",
        "number": "473",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Oh, háblame, y hablaré", "cual eco vivo de tu voz.", "Tal como me buscaste tú,", "iré tras quien errante va."], ["Oh, guíame, y guiaré", "al pie que vacilante va.", "Susténtame, y yo daré", "maná al que hambriento está."], ["Oh, afírmame, y ayudaré", "a estar en pie en Roca fiel.", "Sostenme, y extenderé", "mi mano a quien va a caer."]]
      }, {
        "name": "¿Qué me importan?",
        "number": "474",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["¿Qué me importan del mundo las penas", "y doblada tener la cerviz?", "¿Qué me importa sufrir en cadenas", "si me espera una patria feliz?", "Resignado, tranquilo y dichoso,", "de la aurora vislumbro la luz;", "mis prisiones las llevo gozoso", "por Jesús, quien venció en la cruz."], ["Aunque preso, las horas se vuelan", "en gratísimo y santo solaz:", "con la Biblia mis males se ausentan,", "pues de darme la dicha es capaz.", "¡Libro santo!, mi vida ilumina;", "nunca, nunca te apartes de mí;", "contemplando tu bella doctrina,", "no hay males ni penas aquí."], ["¡Evangelio sublime, preciado!", "¡Bello pacto de amor sin igual!", "Quiero siempre tenerte a mi lado,", "refulgiendo cual puro fanal.", "Aun en valle de muerte, oscuro,", "el que acude a los pies del Señor", "hallará su consuelo seguro", "en el bello evangelio de amor."]]
      }, {
        "name": "El camino es escabroso",
        "number": "475",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["El camino es escabroso, y los pies sangrantes van.", "¿Cuánto dista Canaán? ¿Cuánto dista Canaán?", "Por su amparo suspiramos cada día más y más.", "¿Cuánto aún, cuánto dista Canaán?"], ["Por el árido desierto es penoso el caminar.", "¿Dista mucho Canaán? ¿Dista mucho Canaán?", "En la arena están las huellas de los que pasaron ya.", "¿Cuánto aún, cuánto dista Canaán?"], ["¡Oh, cuán dulce el descanso ha de ser en nuestro hogar!", "¡Ya se acerca Canaán! ¡Ya se acerca Canaán,", "donde todas nuestras penas ya no volverán jamás!", "¡Cerca ya, ya se acerca Canaán!"]],
        "refrainFirst": false,
        "refrain": ["Muy cansados, tan cansados,", "muy cansados de vagar", "por el desierto estamos ya.", "Muy cansados, tan cansados.", "¿Cuánto aún, cuánto dista Canaán?"]
      }, {
        "name": "¿Muy lejos el hogar está?",
        "number": "476",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["\"¿Muy lejos el hogar está?\",", "con ansia al guarda pregunté.", "\"Muy pronto el día llegará", "de contemplar al Rey\",", "me contestó; \"no llores más,", "cercano está del viaje el fin;", "alegre entonces entrarás al celestial festín\"."], ["Señales muchas se ven ya,", "en cielo, tierra y en el mar;", "la aurora luego rayará,", "del anhelado hogar.", "Consuélate, no llores más;", "el Redentor enjugará", "del siervo fiel las lágrimas; descanso le dará."], ["Jesús en breve volverá,", "¡qué pensamiento alentador!", "La creación ansiosa está", "de ver al Creador.", "Entonces ya terminará", "tristeza, muerte y dolor;", "un paraíso se abrirá al pueblo del Señor."]]
      }, {
        "name": "Los que aman al Señor",
        "number": "477",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Los que aman al Señor", "eleven su canción,", "que en dulces notas de loor,", "que en dulces notas de loor,", "ascienda a su mansión,", "ascienda a su mansión."], ["Unida está, oh Dios,", "tu fiel y amada grey;", "y cantan todos a una voz,", "y cantan todos a una voz,", "los hijos del gran Rey,", "los hijos del gran Rey."], ["Cantemos con fervor,", "dejemos el pesar,", "marchemos libres de temor,", "marchemos libres de temor,", "al más feliz hogar,", "al más feliz hogar."]],
        "refrainFirst": false,
        "refrain": ["A Sion caminamos,", "nuestra ciudad tan gloriosa;", "marchando todos cantamos", "de Dios y la bella mansión."]
      }, {
        "name": "Sé fiel siempre, hermano",
        "number": "478",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Sé fiel siempre, hermano, Jesús volverá.", "La espera se acerca al final.", "Muy pronto estarás junto a él más allá,", "cantando su himno triunfal.", "Hermano, sé fiel. permanece leal", "al Hijo de Dios, que mostró", "profundo, infinito amor, sin igual;", "murió y el rescate pasó."], ["Sé fiel siempre, hermano. La Santa Ciudad", "que Cristo nos fue a preparar", "portales de perla abrirá en brevedad,", "con júbilo, de par en par.", "Entonces, sé fiel, poco resta aquí", "de tanta amargura y dolor.", "La noche oscura de penas se irá.", "Vendrá la mañana de luz."], ["Sé fiel, siempre, hermano, que la eternidad", "tendrás tras luchar y vencer.", "Entonces el gozo tu llanto echará,", "corona de gloria tendrás.", "¡Hermano, sé fiel! Pronto escucharás", "al buen Salvador pronunciar:", "\"Muy bien, siervo fiel,", "es la hora de entrar", "al gozo eternal del Señor\"."]]
      }, {
        "name": "De la mano, Señor",
        "number": "479",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["De la mano, Señor, guíame cual pastor,", "pues cansado me siento del mal;", "en la noche sin luz, ven a mí, buen Jesús,", "guíame al hogar celestial."], ["Cuando vague sin paz, sé Jesús mi solaz;", "y al sentir que mi vida se va,", "oye tú mi clamor, y sostenme Señor;", "guíame al hogar celestial."]]
      }, {
        "name": "Digno eres, oh Señor",
        "number": "480",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Digno eres, oh Señor; que te honre nuestro ser,", "que te honre siempre nuestro ser.", "Lleno eres de bondad, mi alma se alegra en ti,", "mi alma canta y se alegra en ti."], ["Poderoso Creador, vida plena y luz me das,", "vida plena y clara luz me das.", "y tu sangre me salvó, me redimes con amor,", "me redimes con tu inmenso amor."], ["Me contemplas con piedad, hoy me dices: \"Ven a mí\",", "hoy me dices: \"Hijo ven a mí\".", "La maldad del pecador por tu amor olvidarás,", "por tu amor y bondad olvidarás."]],
        "refrainFirst": false,
        "refrain": ["De este mundo líbrame;", "lléname de fe y amor.", "Haz que pueda serte fiel.", "tómame, Señor mi Dios."]
      }, {
        "name": "Voy al cielo",
        "number": "481",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Voy al cielo, soy peregrino,", "a vivir eternamente con Jesús;", "pues él abrió ya amplio camino,", "al expirar sobre amarga cruz."], ["Duelo, muerte, amarga pena,", "nunca, nunca más se encontrarán allá;", "preciosa vida, de gozo llena,", "el alma mía disfrutará."], ["¡Tierra santa, hermosa y pura!", "entraré en ti, salvado por Jesús;", "y gozaré siempre la ventura,", "iluminado con santa luz."]],
        "refrainFirst": false,
        "refrain": ["Voy al cielo, soy peregrino,", "a vivir eternamente con Jesús."]
      }, {
        "name": "Quiero cantar",
        "number": "482",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Al transitar por este mundo oscuro,", "puedo cantar, no importa la aflicción.", "Al caminar, asciendo en el sendero,", "porque mi hogar está en el más allá."], ["Lo terrenal es de valor escaso", "al comprender que todo pasará;", "y el dolor que nos preocupa tanto", "sólo será recuerdo que pasó."]],
        "refrainFirst": false,
        "refrain": ["Quiero cantar, seguir cantando alegre,", "con fervor, confiando en su bondad;", "hasta llegar a la Ciudad hermosa", "que mi Señor fue a preparar."]
      }, {
        "name": "Cuando al cielo lleguemos",
        "number": "483",
        "category": "La vida cristiana",
        "subcategory": "Confianza y seguridad",
        "verses": [["Todos juntos de Jesús cantemos,", "su misericordia y su bondad.", "En mansiones celestiales", "nos prepara un bello hogar."], ["Mientras juntos peregrinamos,", "nubes oscurecerán el sol.", "Pero cuando el viaje acabe,", "no habrá sombras ni dolor."], ["Adelante, el premio nos espera:", "contemplar a nuestro Salvador,", "ingresar por los portales,", "y en las calles de oro andar."]],
        "refrainFirst": false,
        "refrain": ["Cuando al cielo lleguemos,", "¡día alegre, bendito aquel será!", "Cuando a Dios veamos,", "¡Victoria! vamos a cantar."]
      }, {
        "name": "Busca al Señor",
        "number": "484",
        "category": "La vida cristiana",
        "subcategory": "Peregrinación",
        "verses": [["Mi hermano, eleva tu alma hacia lo mejor.", "Deja lo que es pasajero, busca al Señor.", "Esta tierra pasará, ella nada puede dar.", "Ya es hora de elevarse al celeste hogar."], ["Como un río corre al mar sin interrupción;", "como un astro en su curso va sin variación;", "el que aceptó al Señor busca contemplar su faz;", "tiende hacia el cielo siempre, y recibe paz."], ["Peregrino, enjuga el llanto, hacia el premio vas.", "Pronto al Salvador viniendo en gran luz verás.", "Ya muy poco tardará, el hogar más cerca está.", "Quedarán atrás las penas, gozo nos dará."]]
      }, {
        "name": "Unidos en verdad",
        "number": "485",
        "category": "La vida cristiana",
        "subcategory": "Peregrinación",
        "verses": [["Por nuestro Señor, unidos en verdad,", "lo adoramos con fervor.", "Gozo da vivir unidos por la fe;", "siempre Jesús nos guiará."], ["Al gran capitán habremos de seguir;", "la gran victoria cerca está.", "Sin vacilación al cielo vamos,", "sí; que nadie falte allá."]],
        "refrainFirst": false,
        "refrain": ["Vamos al cielo, Jesús nos espera;", "hay un lugar para ti.", "Contigo, yo quiero cantar en el cielo;", "vamos, mi hermano, al hogar."]
      }, {
        "name": "En los pasos de Jesús",
        "number": "486",
        "category": "La vida cristiana",
        "subcategory": "Peregrinación",
        "verses": [["Sigo por el camino angosto al vivir aquí,", "donde cada paso en falso puede ser mi fin.", "Pero sigo al frente, siempre pongo yo mis pies", "sobre las pisadas que Jesús dejó por mí.", "Sigo hacia donde esta senda me guiará;", "sé que me llevará hasta el hogar de mi Jesús.", "Y si detrás de mí viniera alguien como yo,", "vería solo una huella, pues sigo a mi Jesús."]]
      }, {
        "name": "Cristo, eres justo Rey",
        "number": "487",
        "category": "La vida cristiana",
        "subcategory": "Obediencia",
        "verses": [["Cristo, eres justo Rey", "desde siempre y desde Edén.", "Guardan los cielos tu santa Ley,", "pues reina allá el bien.", "Quiero en tu humilde grey", "cumplir tu Ley de amor también."], ["Cielos y tierra y mar", "riges desde eternidad.", "Ruégote quieras mi vida guiar;", "toma mi voluntad", "y haz que en querer y obrar", "acate yo tu autoridad."], ["Oigase nuestra voz", "hasta en celestial confín:", "Gloria a ti, Verbo eterno y Dios;", "gloria, loor sin fin;", "gloria, aleluya, oh Dios.", "Loor a ti, loor sin fin."]]
      }, {
        "name": "Al andar con Jesús",
        "number": "488",
        "category": "La vida cristiana",
        "subcategory": "Obediencia",
        "verses": [["Al andar con Jesús en su fúlgida luz,", "en mi senda su gloria veré;", "y su voz he de oír, pues promete vivir", "con aquel que obedece por fe."], ["Si trabajo y penar tengo aquí que cargar,", "fortaleza en Jesús obtendré;", "pues alivia su amor mi afán, mi dolor,", "cuando ve que obedezco por fe."], ["Nunca pude saber de su amor el placer", "hasta que todo a Cristo entregué.", "Su bondad, su favor, su poder redentor", "goza aquel que obedece por fe."], ["Fiel amigo él es; sentareme a sus pies,", "y a su lado el camino andaré.", "Lo que él diga, haré, donde él quiera, iré,", "sin temor obedezco por fe."]],
        "refrainFirst": false,
        "refrain": ["Su santa Ley", "obedezco por fe,", "y feliz para siempre", "con Jesús estaré."]
      }, {
        "name": "Solo anhelo, Cristo amado",
        "number": "489",
        "category": "La vida cristiana",
        "subcategory": "Obediencia",
        "verses": [["Solo anhelo, Cristo amado,", "en tus leyes caminar;", "siempre hacer lo que es tu agrado,", "tus acciones imitar.", "Mas soy débil y cargado", "con mi mucha iniquidad,", "pues, oh Dios, yo he faltado", "a tu santa voluntad."], ["Ten piedad, oh Cristo amado,", "de este pobre pecador.", "Límpiame de mi pecado,", "oh, bendito Salvador.", "No permitas que me venza", "otra vez el tentador.", "¡Oh mi Dios!, con gran vergüenza", "te lo pido por tu amor."], ["Al que pide, es tu promesa", "darle lo que ha menester.", "Yo anhelo tu pureza,", "tu limpieza, tuyo ser.", "Y en tu reino, cuando vengas,", "ten memoria aún de mí,", "que por hijo tú me tengas", "y me lleves junto a ti."]]
      }, {
        "name": "Mejor que los sacrificios",
        "number": "490",
        "category": "La vida cristiana",
        "subcategory": "Obediencia",
        "verses": [["Mejor que los sacrificios es obedecer;", "la Ley guardar y atender a Dios, que ofrendar."], ["¿Dirás que Cristo te dispensó; que ya pagó?", "Recuerda sus Mandamientos, pues él lo ordenó."], ["Los hacedores de la Palabra vivirán.", "Es bueno oír y creer a Dios, y obedecer."]],
        "refrainFirst": false,
        "refrain": ["Vuelve al Señor; será bondadoso.", "Camina en sus Mandamientos.", "Mejor que los sacrificios es obedecer;", "la Ley guardar y atender a Dios, que ofrendar."]
      }, {
        "name": "Levántate, cristiano",
        "number": "491",
        "category": "La vida cristiana",
        "subcategory": "Servicio cristiano",
        "verses": [["Levántate, cristiano, levántate y trabaja;", "no dejes que tu vida se pase en la inacción.", "El que en el ocio vive a su Hacedor ultraja,", "no lleva sus deberes, ni cumple su misión."], ["Si quieres que la vida te ofrezca mil encantos,", "si quieres que la dicha te inspire paz y amor,", "trabaja con ahínco, sin miedo ni quebrantos;", "y un cielo de ventura verás en tu redor."], ["Trabaja por el mundo, trabaja por el Cielo,", "sembrando buenas obras, sembrando bendición.", "Virtud es el trabajo, alivio y fiel consuelo;", "y solo en él se encuentra la paz del corazón."]]
      }, {
        "name": "¡Trabajad! ¡Trabajad!",
        "number": "492",
        "category": "La vida cristiana",
        "subcategory": "Servicio cristiano",
        "verses": [["¡Trabajad! ¡Trabajad! Somos siervos de Dios;", "seguiremos la senda que Cristo trazó.", "Recibiendo el poder que su Espíritu da,", "el deber que nos toca cumplido será."], ["¡Trabajad! ¡Trabajad! Hay que dar de comer", "al que pan de la vida quisiere tener.", "Hay enfermos que irán a los pies del Señor", "al saber que por gracia los sana su amor."], ["¡Trabajad! ¡Trabajad! Fortaleza pedid;", "el reinado del mal con valor combatid;", "conducid sus cautivos al Libertador", "y decid que por gracia redime su amor."]],
        "refrainFirst": false,
        "refrain": ["¡Trabajad! ¡Trabajad!", "¡Esperad y velad!", "¡Siempre orad y confiad!,", "que Cristo pronto volverá."]
      }, {
        "name": "Hoy quiero trabajar contigo",
        "number": "493",
        "category": "La vida cristiana",
        "subcategory": "Servicio cristiano",
        "verses": [["Hoy quiero trabajar contigo, Dios,", "aunque indigno soy, y pecador.", "Me rindo entero a ti, te doy mi corazón,", "para poder vivir contigo, Dios."], ["Al ir a trabajar contigo, Dios,", "dame tu gracia hoy para amar;", "al pobre, ayudar; a enfermos, consolar,", "y almas alcanzar contigo, Dios."], ["Queremos trabajar contigo Dios,", "hasta poder llegar a tu hogar,", "donde la humanidad gozosa cantará", "y siempre morará contigo, Dios."]]
      }, {
        "name": "Cerca un alma agobiada está",
        "number": "494",
        "category": "La vida cristiana",
        "subcategory": "Servicio cristiano",
        "verses": [["Cerca un alma agobiada está,", "ve y ayúdala hoy;", "hazle algún acto de pura amistad,", "ve y ayúdala hoy."], ["¿Ves a un vecino sin fuerza y valor?", "Ve y ayúdale hoy;", "habla a su oído palabras de amor,", "ve y ayúdale hoy."], ["¿Otro confronta una cruel tentación?", "Ve y ayúdale hoy;", "clama al Señor en ferviente oración,", "ve y ayúdale hoy."], ["Hay quien ya busca el camino del bien.", "Ve y ayúdale hoy.", "Di con la Esposa y Espíritu: \"Ven\".", "Ve y ayúdale hoy."]],
        "refrainFirst": false,
        "refrain": ["Ve y ayúdale hoy.", "No tardes, mas di: \"Ya voy\".", "Al débil sé escudo y viste al desnudo.", "¡Oh, ve y ayúdale hoy!"]
      }, {
        "name": "Mi deber",
        "number": "495",
        "category": "La vida cristiana",
        "subcategory": "Servicio cristiano",
        "verses": [["Amar a alguien con más intenso amor,", "y hoy guiar al que errante va;", "orar con noble pensamiento a Dios", "y un dulce sonreír,", "hasta el atardecer,", "es mi deber."], ["Buscar verdad cual ciego busca luz;", "hacer mejor desde el amanecer;", "cuidar mi corazón para Jesús;", "y a su divina voz,", "si llama, responder,", "es mi deber."], ["Luego, al comparecer ante él allí,", "cuando acabada aquí mi obra esté,", "le rendiré homenaje a sus pies,", "dentro de la ciudad,", "donde él coronará", "mi gran deber."]]
      }, {
        "name": "Sus manos somos",
        "number": "496",
        "category": "La vida cristiana",
        "subcategory": "Servicio cristiano",
        "verses": [["Sus manos somos,", "que ayudan en el mundo.", "Somos sus pies,", "que van donde él los guía."], ["Sus ojos somos,", "que ven a los sufrientes.", "Somos su voz,", "que anuncia su regreso."]],
        "refrainFirst": false,
        "refrain": ["Somos su amor,", "lumbre en las tinieblas.", "Somos su luz", "en la oscuridad."]
      }, {
        "name": "Manos",
        "number": "497",
        "category": "La vida cristiana",
        "subcategory": "Servicio cristiano",
        "verses": [["Estas manos Dios me dio", "para que puedan servir", "al que a mi lado está", "y no puede sonreír.", "Con mis manos quiero dar", "paz, amor, felicidad;", "imitando a Jesús."], ["Estas manos se unirán", "a las de mi Salvador,", "demostrando así el poder", "que mi vida transformó.", "Tú también puedes andar", "de la mano de aquel", "que por ti su vida dio."]],
        "refrainFirst": false,
        "refrain": ["Manos ocupadas en la causa del amor.", "Manos consagradas al servicio del Señor.", "Manos preocupadas por asirse de Jesús.", "Manos que no temen el encuentro con la luz."]
      }, {
        "name": "Puedes demostrar con tus manos",
        "number": "498",
        "category": "La vida cristiana",
        "subcategory": "Servicio cristiano",
        "verses": [["Puedes demostrar", "con tus manos el amor de Cristo;", "¿lo harás?", "Verdadero amor", "es ayudar a los desamparados;", "¿amarás?", "Tú y yo debemos ser", "un ejemplo fiel.", "Tú y yo tenemos a Dios", "en el corazón.", "¿Qué harás?", "Puedes demostrar", "con tus manos el amor de Cristo;", "¿lo harás?", "Verdadero amor", "es ayudar a los desamparados;", "¿amarás?", "¿Qué harás?"]]
      }, {
        "name": "Jesús anduvo por aquí",
        "number": "499",
        "category": "La vida cristiana",
        "subcategory": "Servicio cristiano",
        "verses": [["Jesús anduvo por aquí", "haciendo siempre el bien.", "Su amor demuestra hoy en ti", "sirviéndole también."], ["A todos siempre ayudó,", "amó al pecador.", "Ayuda a aquel por quien murió", "Jesús, tu Salvador."], ["Jesús pagó, de la maldad,", "rescate eficaz.", "Si siembras hechos de bondad", "cosecharás la paz."]]
      }, {
        "name": "Hazme tu siervo",
        "number": "500",
        "category": "La vida cristiana",
        "subcategory": "Servicio cristiano",
        "verses": [["Hazme tu siervo, humilde y fiel.", "que ayude a todos con tu amor.", "Sea el ruego de mi corazón:", "hazme tu siervo, quiero escucharte", "y obedecerte, Señor."]]
      }, {
        "name": "Mi vida al servicio de Dios",
        "number": "501",
        "category": "La vida cristiana",
        "subcategory": "Servicio cristiano",
        "verses": [["Mi vida al servicio de Dios", "es el lema que vibra en mi ser.", "Al lado de Cristo persisto en vivir,", "y así muy feliz siempre estoy.", "Andando, sonriendo, sirviendo,", "cantando al cielo yo voy;", "pues quiero sentir", "el placer de vivir por Jesús."]]
      }, {
        "name": "Brilla en el sitio donde estés",
        "number": "502",
        "category": "La vida cristiana",
        "subcategory": "Servicio cristiano",
        "verses": [["Nunca esperes el momento de una gran acción,", "ni que pueda lejos ir tu luz;", "tus pequeños actos pueden ser de bendición,", "brilla en el sitio donde estés."], ["Puedes en tu cielo alguna nube disipar,", "haz a un lado tu egoísmo cruel.", "Aunque solo un corazón pudieres consolar,", "brilla en el sitio donde estés."], ["Puede tu talento alguna cosa descubrir", "do tu luz podrá resplandecer.", "De tu mano el Pan de vida puede aquí venir,", "brilla en el sitio donde estés."]],
        "refrainFirst": false,
        "refrain": ["Brilla en el sitio donde estés,", "brilla en el sitio donde estés.", "Puedes con tu luz algún perdido rescatar,", "brilla en el sitio donde estés."]
      }, {
        "name": "Oh Dios, que deseas la vida",
        "number": "503",
        "category": "La vida cristiana",
        "subcategory": "Servicio cristiano",
        "verses": [["Oh Dios, que deseas la vida y el bien", "de todos tus hijos mortales;", "Señor, fortalece a los que están", "luchando con la muerte cruel."], ["Haz fuertes sus manos y su corazón", "en contra de la enfermedad;", "que puedan bregar por el bienestar", "de toda la humanidad."], ["Al sanar al enfermo y calmar el dolor,", "impartan, Señor, tu amor;", "prediquen tu gracia y tu poder,", "que brinda salud eternal."], ["Concédeles sabiduría y valor,", "revélales tu voluntad.", "Que al consagrar sus vidas a ti,", "compartan tu gozo al servir."]]
      }, {
        "name": "Señor de todos",
        "number": "504",
        "category": "La vida cristiana",
        "subcategory": "Servicio cristiano",
        "verses": [["Señor de todos, dame amor", "por gentes de cualquier nación;", "y que en todos pueda ver", "a un hermano en ti, Señor."], ["Derriba el muro divisor", "entre tus hijos, mi Señor;", "que a mi vecino pueda ayudar", "y que nos una tu amor."], ["Perdóname si acaso erré", "con acto vil o insensatez;", "comprenda yo que cada traspié", "te crucifica otra vez."], ["Hazme valiente para hablar", "y defender del opresor;", "y si yo mismo víctima soy,", "ayúdame a perdonar."], ["Lléname con tu pleno amor,", "colme tu Espíritu mi ser.", "Que en cuanto toque con mi labor,", "tu toque el mundo pueda ver."]]
      }, {
        "name": "Hijo del reino",
        "number": "505",
        "category": "La vida cristiana",
        "subcategory": "Lucha contra el mal",
        "verses": [["Hijo del reino, ¿por qué estás durmiendo,", "cuando a la vista se halla tu hogar?", "Presto levántate, ciñe tus armas;", "en el conflicto tendrás que luchar."], ["Hijo del reino, ¿por qué tardas tanto,", "cuando delante el premio se ve?", "¡lzate, vístete, Cristo se acerca;", "sal a su encuentro, recibe a tu Rey."], ["No sigas más tras placeres mundanos:", "ves que su encanto pasando se va;", "rompe los lazos con que estás atado;", "a Cristo acude, que te amparará."], ["Fija tu vista constante en el Cielo;", "pasa la noche de tribulación;", "sobre las cúspides ya rompe el alba;", "¡hijo del reino, prorrumpe en canción!"]]
      }, {
        "name": "¡De pie, de pie, cristianos!",
        "number": "506",
        "category": "La vida cristiana",
        "subcategory": "Lucha contra el mal",
        "verses": [["¡De pie, de pie, cristianos!, soldados de la cruz.", "Seguid el estandarte de vuestro Rey, Jesús,", "pues victoriosamente sus huestes mandará,", "y al fiero enemigo, pujante, vencerá."], ["¡De pie, de pie, cristianos!, pues suena ya el clarín", "llamando al conflicto al bravo paladín.", "Soldados obedientes, henchíos de valor,", "portaos en la lucha con bríos y vigor."], ["¡De pie, de pie, cristianos!, en Dios la fuerza está;", "el débil brazo humano bien pronto os faltará.", "Tomando la armadura de Dios, con oración,", "donde el deber os llame, id presto con tesón."], ["¡De pie, de pie, cristianos!, al fin el galardón;", "si hoy la lucha es recia, mañana habrá canción.", "Quien salga victorioso corona obtendrá,", "y con el Rey de gloria por siempre vivirá."]]
      }, {
        "name": "Tentado, no cedas",
        "number": "507",
        "category": "La vida cristiana",
        "subcategory": "Lucha contra el mal",
        "verses": [["Tentado, no cedas; ceder es pecar;", "más fácil sería luchando triunfar.", "¡Valor!, pues, resuelto, domina tu mal;", "Dios puede librarte de asalto mortal."], ["Evita el pecado, procura agradar", "a Dios, a quien debes por siempre ensalzar;", "no manche tus labios impúdica voz;", "preserva tu vida de ofensas a Dios."], ["Amante, benigno y enérgico sé;", "en Cristo, tu amigo, pon toda tu fe;", "rinde a Dios tu vida, ríndele tu ser;", "corona te espera y vas a vencer."]],
        "refrainFirst": false,
        "refrain": ["En Jesús, pues, confía;", "en sus brazos tu alma", "hallará dulce calma;", "él te hará vencedor."]
      }, {
        "name": "Contendamos siempre por nuestra fe",
        "number": "508",
        "category": "La vida cristiana",
        "subcategory": "Lucha contra el mal",
        "verses": [["Contendamos siempre por nuestra fe,", "aunque ruja el mundo en derredor;", "nunca el tentador nos podrá vencer,", "pues nos librará el Señor."], ["No seamos tibios de corazón,", "ni dejemos nunca el primer amor;", "mantengamos firme la profesión", "de la fe del Salvador."], ["Procuremos todos la santidad,", "sin la cual ninguno verá al Señor;", "gozo, paz y eterna felicidad", "Cristo da al vencedor."]],
        "refrainFirst": false,
        "refrain": ["Si vencemos aquí, reinaremos allí,", "en la gloria celestial;", "si llevamos la cruz por amor de Jesús,", "la corona él nos dará."]
      }, {
        "name": "¡Firmes! ¡Fuertes!",
        "number": "509",
        "category": "La vida cristiana",
        "subcategory": "Lucha contra el mal",
        "verses": [["Siervos de Jesús, hombres de verdad,", "guardas del deber, somos, sí;", "libres de maldad, ricos en bondad,", "fieles en la lid seremos, sí."], ["Cuán hermosos son los que sin temor,", "listos, al llamar del clarín,", "luchan con valor, sufren sin cesar;", "guardas del deber hasta el fin."], ["Nuestro Capitán es ya vencedor,", "él nos redimió por su cruz.", "Guardas del deber, él nos da poder,", "él nos guiará hasta la luz."]],
        "refrainFirst": false,
        "refrain": ["¡Firmes! ¡Fuertes! Alcen la bandera,", "grande, noble emblema del deber.", "¡Prontos! ¡Bravos! Pasen la consigna,", "marchen todos, vamos a vencer."]
      }, {
        "name": "¿Quién está por Cristo?",
        "number": "510",
        "category": "La vida cristiana",
        "subcategory": "Lucha contra el mal",
        "verses": [["¿Quién está por Cristo? ¿Quién le servirá?", "A salvar a otros ¿quién le ayudará?", "¿Quién, dejando el mundo, contra el error", "luchará por siempre al lado del Señor?"], ["No por la corona ni el galardón", "entro en esta lucha contra el tentador.", "Por los pecadores Cristo se entregó;", "en llevarlos a sus pies me gozo yo."], ["No con oro o plata Cristo nos compró,", "sino con la sangre que en la cruz vertió.", "Los que a él acuden bendecidos son,", "libertad reciben, limpio corazón."], ["Rudo el conflicto sigue con Satán,", "mas lo venceremos: Cristo es Capitán.", "Su verdad eterna es nuestro pabellón;", "su presencia aviva todo corazón."]],
        "refrainFirst": false,
        "refrain": ["Por su magna gracia, su profundo amor,", "yo estoy por Cristo: es mi Rey, Señor."]
      }, {
        "name": "Marcharé en la divina luz",
        "number": "511",
        "category": "La vida cristiana",
        "subcategory": "Lucha contra el mal",
        "verses": [["Marcharé en la divina luz,", "marcharé, siempre marcharé;", "en las huellas del buen Jesús,", "siempre marcharé."], ["Marcharé con mi Guía fiel,", "marcharé, siempre marcharé;", "triunfaré por la fe en él,", "siempre marcharé."], ["Marcharé almas a buscar,", "marcharé, siempre marcharé;", "los perdidos a rescatar,", "siempre marcharé."], ["Por Jesús, más que vencedor", "marcharé, siempre marcharé;", "al hogar de mi Salvador,", "siempre marcharé."]],
        "refrainFirst": false,
        "refrain": ["Vestido blanco yo tendré,", "corona eterna ceñiré;", "feliz allí podré gozar,", "en el Edén sin par."]
      }, {
        "name": "Nunca estéis desanimados",
        "number": "512",
        "category": "La vida cristiana",
        "subcategory": "Lucha contra el mal",
        "verses": [["Nunca estéis desanimados:", "gracia divina obtendréis;", "Cristo os sostiene en la lucha,", "y en su poder triunfaréis."], ["Si os oprimen las cargas,", "si es sombrío el vivir,", "ved hacia el lado risueño,", "vuestro camino al seguir."], ["Nunca estéis desanimados:", "su brazo os sostendrá;", "siempre confiad en su gracia;", "vuestro luchar premiará."]],
        "refrainFirst": false,
        "refrain": ["Nunca os rindáis, nunca os rindáis,", "nunca os rindáis en las pruebas;", "Cristo las desterrará;", "fiad en Jesús, fiad en Jesús;", "cuanto más duras las luchas,", "tanto más gracia os dará."]
      }, {
        "name": "Honra al hombre de valor",
        "number": "513",
        "category": "La vida cristiana",
        "subcategory": "Lucha contra el mal",
        "verses": [["Honra al hombre de valor,", "pronto a obedecer", "el mandato del Señor,", "tal cual lo fue Daniel."], ["Muchos yacen sin valor,", "que pudieran ser", "nobles héroes del Señor,", "tal cual lo fue Daniel."], ["Lucha en nombre del Señor", "sin desfallecer.", "Sé en la lucha vencedor,", "tal cual lo fue Daniel."]],
        "refrainFirst": false,
        "refrain": ["A Daniel imita;", "dalo a conocer;", "muéstrate resuelto y firme,", "aunque solo estés."]
      }, {
        "name": "¡Despertad, despertad, oh cristianos!",
        "number": "514",
        "category": "La vida cristiana",
        "subcategory": "Lucha contra el mal",
        "verses": [["¡Despertad, despertad, oh cristianos!", "Vuestro sueño funesto dejad;", "que el cruel enemigo os acecha, y cautivos os quiere llevar.", "¡Despertad!, las tinieblas pasaron;", "de la noche no sois hijos ya,", "mas lo sois de la luz y del día, y tenéis el deber de luchar."], ["Despertad, preparad vuestras armas,", "vuestros lomos ceñid de verdad,", "y calzad vuestros pies, aprestados con el grato evangelio de paz.", "Basta ya de profundas tinieblas,", "basta ya de pereza mortal;", "¡revestid, revestid vuestro pecho con la cota de fe y caridad!"], ["La gloriosa armadura de Cristo", "acudid con valor a tomar,", "confiando en que el dardo enemigo impotente se ha de quebrar.", "¡Oh cristianos, antorchas del mundo!,", "de esperanza el yelmo tomad,", "levantad de la fe el escudo, y sin miedo corred a luchar."], ["No temáis, pues de Dios revestidos,", "¿qué enemigo venceros podrá,", "si tomáis por espada la Biblia,", "la Palabra del Dios de verdad? En la cruz hallaréis la bandera,", "en Jesús hallaréis Capitán;", "en el cielo obtendréis la corona: ¡A luchar! ¡A luchar! ¡A luchar!"]]
      }, {
        "name": "Despliegue el cristiano su santa bandera",
        "number": "515",
        "category": "La vida cristiana",
        "subcategory": "Lucha contra el mal",
        "verses": [["Despliegue el cristiano su santa bandera", "y muéstrela ufano al mundo falaz;", "soldados valientes, el triunfo os espera,", "seguid vuestra lucha constante y tenaz."], ["Despliegue el cristiano su santa bandera", "y luzca en el frente de audaz torreón;", "el monte y el valle, la hermosa pradera", "contemplen ondeando su gran pabellón."], ["Despliegue el cristiano su santa bandera,", "predique a los pueblos el Libro inmortal;", "presente a los hombres la luz verdadera", "que vierte ese claro, luciente fanal."], ["Despliegue el cristiano su santa bandera", "y muéstrese bravo, batiéndose fiel;", "ni montes ni fosos serán su barrera,", "pues lucha a su lado el divino Emanuel."]]
      }, {
        "name": "¡Firmes y adelante!",
        "number": "516",
        "category": "La vida cristiana",
        "subcategory": "Lucha contra el mal",
        "verses": [["¡Firmes y adelante, huestes de la fe,", "sin temor alguno, que Jesús nos ve!", "Jefe soberano, Cristo al frente va", "y la regia enseña tremolando está."], ["Al sagrado nombre de nuestro Adalid,", "tiembla el enemigo, y huye de la lid.", "Nuestra es la victoria; dad a Dios loor,", "y óigalo el maligno lleno de pavor."], ["Cual potente hueste va la grey de Dios,", "pues de su gran Jefe marcha siempre en pos.", "Es un solo cuerpo y uno es el Señor,", "una la esperanza y uno es el amor."], ["Tronos y coronas pueden perecer;", "de Jesús la iglesia firme ha de ser.", "Nada en contra de ella prevalecerá,", "porque la promesa nunca faltará."]],
        "refrainFirst": false,
        "refrain": ["¡Firmes y adelante, huestes de la fe,", "sin temor alguno, que Jesús nos ve!"]
      }, {
        "name": "De pie, oh grey de Dios",
        "number": "517",
        "category": "La vida cristiana",
        "subcategory": "Lucha contra el mal",
        "verses": [["De pie, oh grey de Dios,", "luchemos contra el mal;", "pues Cristo ya venció y nos da", "la vida eternal."], ["Oh jóvenes, de pie,", "marchemos sin flaquear;", "pues con Jesús por General", "saldremos a triunfar."], ["Mujeres de la grey,", "de pie brillemos hoy;", "el Salvador nos prometió:", "\"Contigo siempre estoy\"."], ["De pie para vencer,", "oh siervos de su grey;", "ejemplo nos dejó el Señor,", "luchemos por el Rey."]]
      }, {
        "name": "Jesús está buscando voluntarios hoy",
        "number": "518",
        "category": "La vida cristiana",
        "subcategory": "Lucha contra el mal",
        "verses": [["Jesús está buscando voluntarios hoy,", "que a la ruda lucha luego puedan ir;", "¿quién está dispuesto a escuchar su voz", "siendo voluntario, listo a combatir?"], ["Nos cercan las tinieblas densas del error,", "vamos sobre abismos hondos de maldad;", "para destruirlas llama el Salvador", "muchos voluntarios que amen la verdad."], ["La lucha es contra el vicio, la pereza, el mal,", "contra la ignorancia de la Ley de Dios;", "es una campaña que no tiene igual;", "¿quieres ir a ella, de Jesús en pos?"], ["El triunfo significa que domine el bien,", "que los hombres se amen, y que la verdad", "reine en las conciencias, siendo su sostén,", "y ha de ser, si ayudas, una realidad."]],
        "refrainFirst": false,
        "refrain": ["De Cristo voluntario tú puedes ser;", "otros ya se alistan, hazlo tú;", "Cristo es nuestro Jefe, no hay por qué temer.", "¿Quieres ser un voluntario de Jesús?"]
      }, {
        "name": "Despierta, hermano, sin demorar",
        "number": "519",
        "category": "La vida cristiana",
        "subcategory": "Lucha contra el mal",
        "verses": [["Despierta, hermano, sin demorar", "y toma las armas del bien;", "con fuerza y vigor lucharás por tu fe;", "y un cielo de gloria obtendrás."], ["La voz divina llama por ti:", "\"Fiel hijo, al Reino entrarás\".", "Con los redimidos, a Dios cantarás;", "dejando el mal vivirás."], ["Fija tu vista siempre en Jesús,", "dejando el lastre del mal.", "Con prisa avanza, siguiendo su luz;", "corona de vida tendrás."]]
      }, {
        "name": "¡Adelante! manda el Señor",
        "number": "520",
        "category": "La vida cristiana",
        "subcategory": "Lucha contra el mal",
        "verses": [["Cristo, nuestro Jefe, nos lleva a la lid;", "nunca perderemos si él nos dice: Id.", "Aun su justa causa se suele ignorar,", "mas yo fiel lo he de seguir."], ["Nuestro estandarte luce por doquier;", "con poder y gloria siempre se ha de ver.", "Cristo nuestro Jefe al mundo ya venció", "y yo fiel lo he de seguir."], ["La furiosa lucha larga no será,", "y a los vencedores nos congregará,", "donde entonaremos cántico triunfal;", "fieles lo hemos de seguir."]],
        "refrainFirst": false,
        "refrain": ["¡Adelante! Manda el Señor.", "¡Adelante! Vamos sin temor.", "¡Adelante! Canta ya la grey;", "la victoria es cierta con el Rey."]
      }, {
        "name": "Al Cristo ved",
        "number": "521",
        "category": "La vida cristiana",
        "subcategory": "Mayordomía",
        "verses": [["Al Cristo ved, de Dios el Hijo eterno;", "ved al Señor, el grande Creador;", "todo abandona por vivir cual siervo,", "del mal rodeado y presa del dolor."], ["En oración tu alma hoy derrama", "ante su trono por el pecador;", "lleva la luz de su Palabra santa", "a los que ignoran el divino amor."], ["Consagra fiel tus bienes, mayordomo,", "que del Señor manejas el caudal,", "para que puedan por el mundo todo", "salvarse almas del poder del mal."]],
        "refrainFirst": false,
        "refrain": ["Por ti todo esto", "hizo Jesús;", "¿cómo respondes", "a su divino amor?"]
      }, {
        "name": "Suenen las palabras",
        "number": "522",
        "category": "La vida cristiana",
        "subcategory": "Mayordomía",
        "verses": [["Suenen las palabras del buen Salvador:", "\"¡Oh!, traedme el diezmo al granero\".", "Aclamad a Cristo Dueño y Creador,", "dadle lo mejor primero."], ["Cristo nuevamente vuelve a decir:", "\"Con el diezmo y liberal ofrenda,", "muchos el mensaje hoy podrán oír", "y hallarán la santa senda\"."], ["Dad a Dios mejor y más completo don:", "vuestra vida entera y vuestra hacienda.", "Entregad a Cristo hoy el corazón,", "que es la más preciosa ofrenda."]],
        "refrainFirst": false,
        "refrain": ["\"Oh, traedme el diezmo al granero,", "probadme hoy\", dice Jehová;", "quien sobre tierras y dinero,", "bendiciones abundantes pondrá."]
      }, {
        "name": "Los sabios dan su ciencia",
        "number": "523",
        "category": "La vida cristiana",
        "subcategory": "Mayordomía",
        "verses": [["Los sabios dan su ciencia, los ricos plenitud,", "algunos dan sus fuerzas y otros su salud.", "Nosotros no tenemos riquezas ni poder;", "deseamos ofrendarle, ¿qué vamos a traer?"], ["El corazón amante, las alabanzas mil,", "y ser perseverantes al ir en pos de él.", "Son estos los tesoros que damos al Señor,", "sus hijos más humildes con todo nuestro amor."], ["Y todos los deberes que nos confiaron hoy", "haremos con esfuerzo para agradar a Dios.", "Así son los tesoros que damos al gran Rey", "los grandes y pequeños de su preciosa grey."]]
      }, {
        "name": "Traían en silencio presentes al Señor",
        "number": "524",
        "category": "La vida cristiana",
        "subcategory": "Mayordomía",
        "verses": [["Traían en silencio presentes al Señor;", "su amor humilde y puro les daba gran valor;", "palabras de consuelo y hechos de bondad,", "Jesús los recibía por su sinceridad."], ["Aparte de los otros un pobre viador", "miraba cómo daban tributos al Señor.", "Él nada poseía; sentía gran amor,", "¡Y cuánto anhelaba dar algo de valor!"], ["\"Señor\", clamó el hombre, \"acepta tú mi don,", "acepta lo que tengo: mi triste corazón\".", "Le dijo el buen Maestro al pobre viador:", "\"De todos los presentes es este el mejor\"."]],
        "refrainFirst": false,
        "refrain": ["¿Quisieras dar a Cristo", "el más precioso don?", "Di: \"Cristo, mi Maestro,", "te doy mi corazón\"."]
      }, {
        "name": "Con gratitud, llegamos",
        "number": "525",
        "category": "La vida cristiana",
        "subcategory": "Mayordomía",
        "verses": [["Con gratitud, llegamos", "a tu altar, Señor;", "gozosos te adoramos,", "cantamos con fervor."], ["Queremos devolverte", "el diezmo sin tardar;", "por eso hoy, fielmente,", "lo vamos a entregar."], ["Con gozo, nuestra ofrenda", "te damos, oh Señor;", "acéptala cual prenda", "de todo nuestro amor."]]
      }, {
        "name": "Oh, mi patria, te prometo hoy",
        "number": "526",
        "category": "La vida cristiana",
        "subcategory": "Amor a la patria",
        "verses": [["Oh, mi patria, te prometo hoy", "humilde lealtad;", "un servicio pleno y total", "brindado por amor;", "un amor que se interesa,", "que soporta la aflicción,", "que coloca sobre el altar", "lo amado y lo mejor;", "un amor que nunca dudará", "en entregarse fiel;", "un amor que no claudicará", "hasta vencer el mal."], ["Sé que hay otra Patria, celestial,", "de eterno gozo y paz.", "Incontables son sus ángeles,", "sublime es su Rey.", "Solo entrarán en ella los de puro corazón,", "los que pacificadores son", "y sirven por amor.", "Oh, Señor, que al vivir aquí", "te honre sin cesar;", "y que pronto pueda ingresar", "en tu hogar feliz."]]
      }, {
        "name": "Señor Jehová, omnipotente Dios",
        "number": "527",
        "category": "La vida cristiana",
        "subcategory": "Amor a la patria",
        "verses": [["Señor Jehová, omnipotente Dios,", "tú que los astros riges con poder,", "oye clemente nuestra humilde voz,", "nuestra oración hoy puedas responder."], ["A nuestra patria da tu bendición;", "enséñanos tus leyes a guardar;", "alumbra la conciencia y la razón,", "y reina siempre tú en todo hogar."], ["Danos valor al enfrentar el mal;", "condúcenos por sendas de virtud.", "Protégenos con mano paternal,", "concédenos tu paz, consuelo y luz."]]
      }, {
        "name": "Por montañas, muy cansado",
        "number": "528",
        "category": "La iglesia",
        "subcategory": "Iglesia",
        "verses": [["Por montañas, muy cansado, el rebaño caminó.", "Por desiertos ha vagado, duras pruebas soportó.", "El clarín está llamando firme y fiel, con clara voz:", "fe, unión y amor abunden, haya gozo y canción."], ["La verdad sus mentes colma en su senda al proseguir.", "Tus mandatos atesoran en sus vidas, al cumplir.", "Tu Palabra para ellos es más dulce que la miel;", "tus preceptos obedecen, pues desean serte fiel."], ["En la luz de tu pureza, en la bella y gran Ciudad,", "trasponiendo sus portales, los que amas estarán.", "¡Oh, cuán dulce es la promesa: el dolor no existirá;", "solo paz y alegría do tus hijos reinarán!"], ["Pronto te contemplaremos, en las nubes volverás.", "Los salvados, jubilosos, tu victoria cantarán.", "¡Cuánto ansío tu venida! ¡Ven, Jesús, no tardes más!", "¡Cuán bendita es la esperanza: pronto vamos al hogar!"]]
      }, {
        "name": "Iglesia de Cristo",
        "number": "529",
        "category": "La iglesia",
        "subcategory": "Iglesia",
        "verses": [["Iglesia de Cristo, reanima tu amor", "y espera, velando, a tu augusto Señor.", "Jesús, el Esposo, vestido de honor,", "viniendo se anuncia con fuerte clamor."], ["Si falta en algunos el santo fervor,", "la fe sea en todos el despertador.", "Velad, compañeros, velad sin temor,", "que está con nosotros el Consolador."], ["Quien sigue la senda del vil pecador,", "se entrega en los brazos de un sueño traidor.", "Mas para los siervos del buen Salvador,", "velar esperando es su anhelo mejor."]]
      }, {
        "name": "Somos un pequeño pueblo muy feliz",
        "number": "530",
        "category": "La iglesia",
        "subcategory": "Iglesia",
        "verses": [["Somos un pequeño pueblo muy feliz,", "somos un pequeño pueblo muy feliz.", "Cristo es nuestro Salvador", "y muy pronto volverá.", "Somos un pequeño pueblo muy feliz."], ["Hay señales del regreso de Jesús,", "pues hay guerras, hay temor y ansiedad;", "pestes y hambre por doquier,", "y aumenta la maldad;", "pronto Cristo nos traerá liberación."], ["Poco falta para el día del Señor,", "late nuestro corazón con emoción.", "Pronto vamos a elevar", "la mirada para ver", "a Jesús viniendo en gloria y majestad."]]
      }, {
        "name": "La familia de Dios",
        "number": "531",
        "category": "La iglesia",
        "subcategory": "Iglesia",
        "verses": [["¡Qué feliz! ¡Qué feliz! La familia de Dios.", "Todos somos hermanos en el Señor.", "Grandes y chicos, no importa el color,", "somos una familia, familia de Dios."], ["¡Soy feliz porque estoy en la iglesia de Dios!", "Me limpió con su sangre mi Salvador.", "Cristo nos guía al hogar celestial;", "somos una familia, familia de Dios."]]
      }, {
        "name": "Sagrado es el amor",
        "number": "532",
        "category": "La iglesia",
        "subcategory": "Iglesia",
        "verses": [["Sagrado es el amor", "que nos ha unido aquí,", "a los que oímos del Señor", "la voz que llama a sí."], ["A nuestro buen Jesús", "rogamos con fervor:", "que alúmbrenos la misma luz,", "nos una el mismo amor."], ["Nos vamos a separar,", "mas nuestra firme unión", "jamás podrase quebrantar", "por la separación."], ["Nos hemos de encontrar", "en celestial reunión;", "que nadie haya de faltar", "en la eterna Sion."]]
      }, {
        "name": "Cuán bueno y agradable",
        "number": "533",
        "category": "La iglesia",
        "subcategory": "Iglesia",
        "verses": [["¡Cuán bueno y agradable,", "ver a hermanos juntos!", "¡Cuán bueno y agradable,", "ver a hermanos juntos!", "Siempre unidos,", "todos en armonía,", "los hermanos en fraternal unión."]]
      }, {
        "name": "En tu nombre comenzamos",
        "number": "534",
        "category": "La iglesia",
        "subcategory": "Escuela Sabática",
        "verses": [["En tu nombre comenzamos", "esta escuela, ¡oh Señor!", "Con fervor te suplicamos", "seas nuestro Director."], ["Esta escuela nos enseña", "tu Palabra a obedecer,", "y tu Ley en nuestra vida", "ante el mundo a enaltecer."], ["Ven, Señor, a enseñarnos", "tus preceptos a cumplir.", "Ya reunidos, esperamos", "tu presencia aquí sentir."]],
        "refrainFirst": false,
        "refrain": ["Cada sábado venimos", "a tu escuela, ¡oh Jesús!", "Ven, Señor, a instruirnos", "en la ciencia de la cruz."]
      }, {
        "name": "Las faenas terminadas",
        "number": "535",
        "category": "La iglesia",
        "subcategory": "Sábado",
        "verses": [["Las faenas terminadas,", "con el nocturnal telón", "que de los celestes atrios", "ya desciende en bendición,", "viene el sábado glorioso,", "de lo alto el rico don."], ["Depongamos toda carga,", "toda cuita, todo afán;", "y pidamos ante el Padre,", "do sus hijos hoy están,", "en el sábado glorioso,", "el divino y vivo pan."], ["Padre, tu favor concede;", "por la noche guárdanos;", "que sintamos tu presencia;", "con la luz despiértanos", "en el sábado bendito,", "tu preciado día, oh Dios."]]
      }, {
        "name": "En sombras de la tarde",
        "number": "536",
        "category": "La iglesia",
        "subcategory": "Sábado",
        "verses": [["En sombras de la tarde el día ya declina,", "y el sábado se anuncia con gloria vespertina.", "Cual brisa refrescante en cálido camino,", "cual palma en el desierto, alivia al peregrino."], ["Durante la jornada que ahora ha terminado", "trabajo honesto y arduo las horas han llenado.", "Ahora a tu reposo mi alma agradecida", "se entrega, y te suplica la colmes de tu vida."], ["En este santo día anhelo tu presencia,", "pues quiero sin medida gozar de tu influencia.", "Mi alma fatigada en ti hallará reposo,", "y el sábado bendito darame santo gozo."]]
      }, {
        "name": "Sábado santo",
        "number": "537",
        "category": "La iglesia",
        "subcategory": "Sábado",
        "verses": [["Sábado santo de Jehová", "es este día de solaz.", "En él adoro al Hacedor,", "y halla mi alma así la paz,", "y halla mi alma así la paz."], ["Todas sus horas santas son;", "nunca las vivo para mí,", "y nada vil perturba así", "mi comunión feliz con Dios,", "mi comunión feliz con Dios."], ["Esta delicia semanal", "es anticipo aquí de Sion;", "me acerca al trono celestial", "al alentar mi devoción,", "al alentar mi devoción."], ["Todo pesar olvido hoy;", "dejo también mi cruel afán.", "Del sábado es autor Jesús:", "ríndole entonces culto a él,", "ríndole entonces culto a él."]]
      }, {
        "name": "Hoy es día de reposo",
        "number": "538",
        "category": "La iglesia",
        "subcategory": "Sábado",
        "verses": [["Hoy es día de reposo, día santo de solaz;", "es el día venturoso que nos trae dulce paz.", "Es el día señalado con el sello del amor;", "nuestro Dios lo ha designado: es el día del Señor."], ["Hoy cantamos de alegría al Autor del santo don,", "que nos da el festivo día y se goza en el perdón.", "Aceptemos, pues, con gusto el descanso semanal,", "esperando el día augusto del reposo celestial."], ["Los que a ti nos acercamos por Jesús, Dios de verdad,", "hoy alegres proclamamos tu justicia y tu bondad.", "En los fastos de la historia siempre se celebrará", "este día, y su memoria por los siglos durará."]]
      }, {
        "name": "¡Oh, día delicioso!",
        "number": "539",
        "category": "La iglesia",
        "subcategory": "Sábado",
        "verses": [["¡Oh, día delicioso de gozo, amor y paz;", "de llantos y pesares el bálsamo eficaz!", "En ti, postrados ante el trono celestial, cantamos:", "\"¡Santo, Santo!\", loando al Eternal."], ["Seguro puerto eres en toda tempestad,", "jardín do corren ríos de luz y santidad.", "Divina fuente eres en yermo terrenal.", "la cumbre de donde vemos la patria celestial."], ["Tus horas son sagradas, de santa reflexión,", "en que del mundo al cielo se eleva la atención;", "sacando gracias nuevas de ti al reposar,", "tu plenitud buscamos en nuestro eterno hogar."]]
      }, {
        "name": "Ya asoma el sol brillante",
        "number": "540",
        "category": "La iglesia",
        "subcategory": "Sábado",
        "verses": [["Ya asoma el sol brillante,", "vertiendo luz, calor;", "natura alegre canta:", "es día del Señor."], ["Perfume de las flores", "se eleva hacia Dios.", "Los pajarillos trinan", "con melodiosa voz."], ["Si pájaros y flores", "te alaban, oh Señor,", "tus hijos reverentes", "te alabarán mejor."]],
        "refrainFirst": false,
        "refrain": ["Hoy, sábado, reunidos", "en culto a ti, Señor,", "tus hijos redimidos", "te rinden su loor."]
      }, {
        "name": "Señor, reposamos",
        "number": "541",
        "category": "La iglesia",
        "subcategory": "Sábado",
        "verses": [["Señor, reposamos en tu santo día,", "cumpliendo el mandato legado por ti.", "Reposo buscamos, Dios nuestro, en tu seno;", "que así ordenaste en el Sinaí,", "que así ordenaste en el Sinaí."], ["Tus hijos se acercan, oh Dios, a tu trono", "en santa, ferviente, sincera oración,", "pidiéndote escuches su humilde plegaria,", "y gocen por siempre de tu protección,", "y gocen por siempre de tu protección."], ["Alienta a tus hijos, que obtengan victoria,", "que puedan fielmente tus leyes cumplir;", "y cuando vinieres, Señor, en tu gloria,", "que puedan por siempre contigo vivir,", "que puedan por siempre contigo vivir."]]
      }, {
        "name": "Amo tu sábado",
        "number": "542",
        "category": "La iglesia",
        "subcategory": "Sábado",
        "verses": [["Amo tu sábado;", "te adoraré, Señor;", "pues dulce paz encuentro en él,", "profunda y serena fe."], ["Coro angelical", "tu creación cantó;", "elevaré, también, mi voz", "en gratitud y amor."], ["Oh, nuestro Creador,", "tu generoso don", "me santifique en tu amor,", "y así veré tu faz."], ["Y en la Santa Sion", "siempre entonaré", "himnos de eterna gratitud", "por tu inefable amor."]]
      }, {
        "name": "No te olvides nunca del día del Señor",
        "number": "543",
        "category": "La iglesia",
        "subcategory": "Sábado",
        "verses": [["No te olvides nunca del día del Señor,", "de toda la semana, sin duda, es el mejor.", "Nos da reposo santo, nos colma de solaz,", "alumbra nuestra vida llenándola de paz."], ["Guarda el día santo, adora al Señor", "que dice a sus hijos: \"La senda al cielo soy\".", "Si humildes hoy seguimos a nuestro Salvador,", "la fuente de agua eterna daranos con amor."], ["Santas son tus horas y llenas de solaz;", "en ellas alabamos con gozo a Jesús.", "¡Oh Salvador amante, cuán bueno eres tú!", "Con tu presencia llenas mi vida de tu luz."]],
        "refrainFirst": false,
        "refrain": ["Santo sábado de paz, oh, bienvenido seas.", "Tú eres sello de amor, de Dios gran bendición."]
      }, {
        "name": "Hoy el sábado glorioso",
        "number": "544",
        "category": "La iglesia",
        "subcategory": "Sábado",
        "verses": [["Hoy el sábado glorioso", "nos invita a descansar.", "¡Qué tranquilo es el reposo,", "tras el arduo trabajar!"], ["Dios, que el día nos señala", "con mil pruebas de su amor,", "\"santo sábado\" lo llama:", "es el día del Señor."], ["Para el hombre fue apartado", "en la misma creación;", "fue por Cristo sancionado", "con su ejemplo y bendición."]]
      }, {
        "name": "Santo día",
        "number": "545",
        "category": "La iglesia",
        "subcategory": "Sábado",
        "verses": [["Santo día que el Señor", "en Edén santificó", "y lo dio como señal", "del poder que nos creó."], ["Gran congoja hallará", "quien del mundo siga en pos.", "El perfecto gozo está", "en la paz que ofrece Dios."], ["En el día del Señor,", "santa paz podrá gozar", "quien acuda, en la quietud,", "sus palabras a escuchar."]],
        "refrainFirst": false,
        "refrain": ["¡Santo sábado de paz,", "bendecido del Señor!", "Siempre el sábado será", "monumento de su amor."]
      }, {
        "name": "Santo sábado, bendito",
        "number": "546",
        "category": "La iglesia",
        "subcategory": "Sábado",
        "verses": [["Santo sábado, bendito,", "joya de la creación,", "que trasciende todo cambio,", "es eterna bendición."], ["El pecado y la tristeza", "pronto no existirán.", "Pero el sábado es eterno,", "y por siempre durará."], ["El reposo verdadero", "hoy buscamos, Dios, en ti.", "Que la paz del día santo,", "sea un remanso aquí."]],
        "refrainFirst": false,
        "refrain": ["Santo sábado de Dios,", "para el hombre lo creó."]
      }, {
        "name": "Sábado es",
        "number": "547",
        "category": "La iglesia",
        "subcategory": "Sábado",
        "verses": [["Sábado es, sábado es,", "día del Señor,", "lo hizo Dios por mí.", "Me alegraré, me alegraré,", "en su gran amor,", "en su gran amor.", "Sábado es, día del Señor;", "me alegraré", "en su gran amor.", "Sábado es, sábado es,", "día del Señor."]]
      }, {
        "name": "Mi corazón se llena de alegría",
        "number": "548",
        "category": "La iglesia",
        "subcategory": "Sábado",
        "verses": [["Mi corazón se llena de alegría", "tu santo día, sábado, al llegar.", "La creación entera en armonía", "con su belleza eleva su cantar."], ["En este mundo, el hombre ha olvidado", "reconocer tu amor y tu poder.", "Pero al guardar el Sábado sagrado", "tu pueblo te honra al guardar tu Ley."], ["Dios compasivo, tierno y poderoso,", "quiero morar en tu eterno hogar;", "y cuando llegue el sábado, gozoso,", "con los salvados te podré cantar."]]
      }, {
        "name": "Ya el fin se acerca",
        "number": "549",
        "category": "La iglesia",
        "subcategory": "Sábado",
        "verses": [["Ya el fin se acerca de tu día santo", "benigno acepta la oración, Señor,", "que te ofrecemos en humilde canto", "cual sacrifico de agradable olor."], ["Por las mercedes a tu amor debidas", "por el descanso y plácido solaz,", "mil gracias sean solo a ti rendidas", "Rey de los reyes, Príncipe de paz."], ["Haz que del mundo la escabrosa senda", "correr podamos con seguro pie;", "y en los conflictos que la duda tienda,", "tu luz alumbre nuestra débil fe."], ["Este descanso de sagrada holgura", "es de las almas celestial festín;", "nos anticipa la sin par ventura", "de aquel reposo que será sin fin."]]
      }, {
        "name": "Día santo del Señor",
        "number": "550",
        "category": "La iglesia",
        "subcategory": "Sábado",
        "verses": [["Día santo del Señor, ¡oh, cuán pronto en pasar!", "Solo vino poco ha; ya lo vemos terminar.", "Y volando al cielo va, fiel testigo allí será;", "y volando al cielo va, fiel testigo allí será."], ["¿Qué informe llevará al celeste tribunal?", "¿De maldades hablará? ¿de cuidado mundanal?", "¿O de santa adoración, con Jesús en comunión?", "¿O de santa adoración, con Jesús en comunión?"], ["¡Oh, perdónanos, Señor, el mal uso de tu don!", "Los preceptos de tu Ley graba en nuestro corazón.", "Es tu sábado, Señor, sello santo de tu amor;", "es tu sábado, Señor, sello santo de tu amor."]]
      }, {
        "name": "Embajador soy de mi Rey",
        "number": "551",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Soy peregrino aquí; no hallo do morar;", "en áurea playa está mi muy lejano hogar;", "yo soy embajador del reino allende el mar;", "embajador soy de mi Rey."], ["Mi Rey implora a los de senda mundanal", "que se arrepientan del pecado tan fatal;", "que presten atención al ruego celestial;", "embajador soy de mi Rey."], ["Más bello es mi hogar que el valle de Sarón;", "hay plena paz y amor en toda su extensión;", "y porque allí tengáis eterna posesión,", "embajador soy de mi Rey."]],
        "refrainFirst": false,
        "refrain": ["Mensaje traigo que anunciar,", "que aun ángeles quisieran dar.", "\"Reconciliaos hoy\", es la divina voz,", "\"reconciliaos hoy con Dios\"."]
      }, {
        "name": "¡Oh!, cuánto necesita",
        "number": "552",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["¡Oh!, cuánto necesita obreros el Señor,", "que vayan presurosos al campo de labor.", "Alegre yo le digo, con todo mi valor:", "Conmigo cuenta tú, Señor."], ["Ahora, en la batalla, ven, cíñeme, Señor;", "tu célica armadura dé aliento, fe y valor.", "Para vencer las huestes del fiero tentador,", "conmigo cuenta tú, Señor."], ["La carga que soporta la pobre humanidad,", "con todos mis esfuerzos yo debo aliviar;", "para salvar las almas, doquier me toque estar,", "conmigo cuenta tú, Señor."]],
        "refrainFirst": false,
        "refrain": ["Voy, Señor; voy, Señor,", "a trabajar alegre y con fervor.", "Sí, voy, Señor, voy, Señor;", "conmigo cuenta tú, Señor."]
      }, {
        "name": "¿Os pusisteis a arar?",
        "number": "553",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["¿Os pusisteis a arar?", "Pues seguid, seguid,", "aunque duro el terreno encontréis.", "Luchad con tesón, firme el corazón,", "y al fin rico paso tendréis.", "Los campos fecundos reverdecerán", "y áurea gloria revestirán;", "con gozo veréis cosechar la mies;", "entonad, pues, con brío el refrán:"], ["¿Os llamó Dios a su obra?", "Seguid, seguid,", "aunque densa la oscuridad.", "De Cristo Jesús vendrá clara luz,", "en su lumbre gloriosa andad.", "En aquel fausto día de gozo y solaz,", "en la patria de luz y amor,", "celeste mansión, feliz galardón", "os dará bondadoso el Señor."]],
        "refrainFirst": false,
        "refrain": ["Seguid, seguid, hermanos, seguid;", "cansados, no vaciléis.", "Venciendo, al fin alegre festín", "con Cristo gozaréis."]
      }, {
        "name": "Con Cristo avanza hoy",
        "number": "554",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Con Cristo avanza hoy,", "él te llamó a ti.", "Te ha guiado", "y te guardará en su amor.", "Él cumplirá fielmente lo que a", "su iglesia prometió."], ["Con Cristo avanza hoy,", "resuelto a alcanzar", "victorias en su nombre", "y por su poder.", "Te guardará,", "su Santo Espíritu", "te fortalecerá."], ["Con Cristo avanza hoy,", "las buenas nuevas da;", "que por su sangre", "salvación ofrece ya.", "Avanza hoy,", "imita al Salvador,", "con Cristo avanza hoy."]]
      }, {
        "name": "Hoy gozoso medito",
        "number": "555",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Hoy gozoso medito en la tierra mejor", "do al ponerse mi sol llegaré.", "Cuando me halle por gracia con Cristo el Señor,", "¿la corona de estrellas tendré?"], ["De la fuerza de Dios esperando el poder,", "trabajar quiero siempre y orar", "por las almas, y al fin, cual estrellas, saber", "que en mis sienes irán a brillar."], ["¡Oh!, qué gozo en los cielos será para mí", "vivas gemas poner a sus pies;", "y tener en mi frente corona allí,", "con sus joyas de gran brillantez."]],
        "refrainFirst": false,
        "refrain": ["¿Una bella corona de estrellas tendré", "por las almas que a Cristo llevé?", "Cuando llegue al hogar", "de la dicha sin par,", "¿la corona de estrellas tendré?"]
      }, {
        "name": "Yo quiero siempre brillar",
        "number": "556",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Yo quiero siempre brillar,", "siempre por Cristo brillar;", "en un mundo sin luz,", "quiero ser de Jesús.", "Yo quiero siempre brillar,", "siempre por Cristo brillar;", "hasta su venida en luz."]]
      }, {
        "name": "¿Qué estás haciendo por Cristo?",
        "number": "557",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["¿Qué estás haciendo por Cristo", "mientras vida él te da?", "¿Sembrando estás su Palabra", "o te hallas durmiendo quizá?"], ["¿Qué estás haciendo por Cristo,", "tu gran Rey y Señor?", "¿Cuidas el alma afligida", "con gracia, ternura y amor?"], ["¿Qué estás haciendo por Cristo?", "Pronto anochecerá.", "Ven sin tardar y trabaja,", "pues Cristo muy pronto vendrá."]],
        "refrainFirst": false,
        "refrain": ["¿Qué estás haciendo hoy para Cristo?", "¿Qué estás haciendo? ¿Hoy esperarás?", "¿Qué estás haciendo hoy para Cristo?", "Te dio su gracia; tú ¿qué le darás?"]
      }, {
        "name": "Ama a tus prójimos",
        "number": "558",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Ama a tus prójimos, piensa en sus almas,", "diles la historia de Cristo, el Señor;", "cuida del huérfano, hazte su amigo.", "Cristo le es Padre y fiel Salvador."], ["Aunque le digan \"No\", Dios los espera", "y con amor les ofrece salud.", "Si claman al Señor, él los perdona", "y los protege con solicitud."], ["Dentro del corazón triste, abatido,", "obra el Espíritu transformador,", "que lo conducirá, arrepentido,", "a Jesucristo su buen Redentor."], ["Salva a tus prójimos; Cristo te ayuda;", "fuerza de Dios será tuya en verdad.", "Él te bendecirá en tus esfuerzos;", "con él disfrutarás la eternidad."]],
        "refrainFirst": false,
        "refrain": ["Salva al incrédulo, mira el peligro;", "Dios le perdonará, Dios le amará."]
      }, {
        "name": "No te dé temor",
        "number": "559",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["No te dé temor hablar por Cristo,", "haz que brille en ti su luz;", "al que te salvó confiesa siempre,", "todo debes a Jesús."], ["No te dé temor vivir por Cristo", "cuanto de tu parte esté;", "obra con amor, con fe y constancia:", "tus trabajos premiará."], ["No te dé temor sufrir por Cristo", "los reproches, o el dolor;", "sufre con amor tus pruebas todas,", "cual sufrió tu Salvador."], ["No te dé temor morir por Cristo:", "vida y verdad es él;", "él te llevará con gran ternura", "a su célico vergel."]],
        "refrainFirst": false,
        "refrain": ["No te dé temor, no te dé temor,", "nunca, nunca, nunca.", "Es tu Salvador amante;", "nunca, pues, te dé temor."]
      }, {
        "name": "Cristo está buscando obreros",
        "number": "560",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Cristo está buscando obreros hoy", "que quieran ir con él.", "¿Quién dirá: \"Señor, contigo voy,", "yo quiero serte fiel\"?"], ["Cristo quiere mensajeros hoy", "que anuncien su verdad.", "¿Quién dirá: \"Señor, yo listo estoy,", "haré tu voluntad\"?"], ["Hay lugar si quieres trabajar", "por Cristo en su labor.", "Puedes de su gloria al mundo hablar,", "de su bondad y amor."], ["¿Vives ya salvado por Jesús?", "¿Su amor conoces ya?", "¡Habla, pues, anuncia que en la luz", "de Cristo vives ya!"]],
        "refrainFirst": false,
        "refrain": ["¡Oh Señor!, es mucha tu labor,", "y obreros faltan ya;", "danos luz, ardiente fe y valor,", "y obreros siempre habrá."]
      }, {
        "name": "Oigo del Señor la voz llamando",
        "number": "561",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Oigo del Señor la voz llamando:", "\"¿Quién irá este día a trabajar?", "¿Quién me traerá a los perdidos?", "¿Quién la senda angosta mostrará?\""], ["Cuando el mismo Dios tocó al profeta,", "dándole un nuevo corazón,", "y este oyó la voz que le llamaba:", "\"Heme aquí\", él pronto contestó."], ["Muchos miles y millones mueren,", "en la más completa oscuridad;", "anda pronto tú a rescatarlos;", "di: \"Maestro, voy con voluntad\"."], ["Pronto el Redentor vendrá en gloria,", "las trompetas lo anunciarán;", "y entonces se oirá al Maestro", "que dirá: \"Bien hecho, siervo fiel\"."]],
        "refrainFirst": false,
        "refrain": ["Habla, oh Dios, háblame;", "habla, y pronto te contestaré;", "habla, oh Dios, háblame,", "habla, y yo respondo: \"Heme aquí\"."]
      }, {
        "name": "Esparcid la luz de Cristo",
        "number": "562",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Esparcid la luz de Cristo", "en la densa oscuridad.", "Alumbrad a quien no ha visto", "más que el mundo de maldad."], ["Alumbradles el sendero", "de la eterna salvación,", "y que acepten del Cordero", "la promesa de expiación."], ["Como un barco zozobrante", "en las ondas de la mar,", "nuestro mundo tambaleante", "va muy pronto a naufragar."]],
        "refrainFirst": false,
        "refrain": ["Esparcid la luz de Cristo,", "dad las nuevas de la cruz,", "pues hay muchos que no han visto", "todavía al buen Jesús."]
      }, {
        "name": "Escuchad, Jesús nos dice",
        "number": "563",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Escuchad, Jesús nos dice: \"¿Quiénes van a trabajar?", "Campos blancos hoy aguardan que los vayan a segar\".", "Él nos llama, cariñoso, nos constriñe con su amor.", "¿Quién responde a su llamada: \"Heme aquí, voy yo, Señor\"?"], ["Si por tierras o por mares no pudieres transitar,", "puedes encontrar hambrientos en tu puerta que auxiliar.", "Si careces de riquezas, lo que dio la viuda da;", "pues si por Jesús lo dieres, él te recompensará."], ["Si como elocuente apóstol no pudieres predicar,", "puedes de Jesús decirles cuánto al hombre supo amar.", "Si no logras que sus culpas reconozca el pecador,", "conducir los niños puedes al benigno Salvador."]]
      }, {
        "name": "Pronto la noche viene",
        "number": "564",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Pronto la noche viene, tiempo es de trabajar;", "los que lucháis por Cristo, no hay que descansar,", "cuando la vida es sueño, gozo, vigor, salud,", "y es la mañana hermosa de la juventud."], ["Pronto la noche viene, tiempo es de trabajar;", "para salvar al mundo hay que batallar,", "cuando la vida alcanza toda su esplendidez,", "cuando es el mediodía de la madurez."], ["Pronto la noche viene, tiempo es de trabajar;", "si el pecador perece, idlo a rescatar,", "aun a la edad proyecta, débil y sin salud,", "aun a la misma tarde de la senectud."], ["Pronto la noche viene, ¡listos a trabajar!;", "¡listos!, que muchas almas hay que rescatar.", "¿Quién de la vida el día puede desperdiciar?", "\"Viene la noche cuando nadie puede obrar\"."]]
      }, {
        "name": "¡Ve, ve oh Sion!",
        "number": "565",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["¡Ve, ve oh Sion!, tu gran destino cumple;", "que Dios es luz al mundo proclamad;", "que el Hacedor de las naciones quiere", "que nadie muera en densa oscuridad."], ["Ve cuántos miles yacen todavía", "en las oscuras cárceles del mal;", "no saben que de Cristo la agonía", "fue para darles vida celestial."], ["Es tu deber que salves de la muerte", "las almas por las cuales él murió.", "Sé fiel, si no culpable quieres verte", "de que se pierda lo que Dios compró."], ["Tus hijos manda con el gran mensaje;", "con tu caudal impulso a ellos da.", "En oración sustenta, fiel, sus almas,", "que cuanto gastes Cristo pagará."]],
        "refrainFirst": false,
        "refrain": ["Alegres nuevas al mundo dad,", "nuevas de redención, de amor y libertad."]
      }, {
        "name": "Centinelas del Maestro",
        "number": "566",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Centinelas del Maestro,", "por doquiera pregonad", "de la cruz las buenas nuevas,", "de Jesús y su bondad."], ["Por la cima de los montes", "por los valles, por el mar,", "por doquier el evangelio", "hoy os toca proclamar."], ["A los antros del pecado,", "dondequiera haya aflicción,", "id con las alegres nuevas", "de la plena redención."]],
        "refrainFirst": false,
        "refrain": ["Centinelas del Maestro,", "la trompeta haced oír;", "y que el hombre que la escuche,", "vida pueda recibir."]
      }, {
        "name": "Si en valles de peligros",
        "number": "567",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Si en valles de peligros yo tengo que pasar,", "o si por altas cumbres en paz me toca andar;", "ya que seguro estoy si en sol o sombra voy,", "a cualquiera parte, iré con Jesús."], ["Si el agua de la vida es mi deber llevar,", "al pecador rendido por yermo al transitar;", "si es que me toca a mí llevar su amor allí,", "con tal que él me guíe, iré con Jesús."], ["Mas si es mi suerte en casa la santa cruz llevar,", "cuando otros la pregonan allende el ancho mar;", "por prueba de mi fe, su fallo aceptaré,", "y adonde él me lleve, iré con Jesús."], ["De cuanto manda Cristo no debo yo dudar;", "más bien seguir fielmente la voz que me ha de guiar.", "Si quedo, pues, o voy, contento siempre estoy,", "pues doquiera tengo conmigo a Jesús."]],
        "refrainFirst": false,
        "refrain": ["A cualquiera parte, iré con Jesús;", "doquiera que esté, del Cielo tendré la santa luz.", "Es mi privilegio aquí llevar su cruz;", "iré, pues, a cualquiera parte con Jesús."]
      }, {
        "name": "Hay lugar en la amplia viña",
        "number": "568",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Hay lugar en la amplia viña para todo labrador;", "ven y ayuda en la campiña del amante Salvador.", "Hoy esperan muchas almas la salud espiritual;", "diles que Jesús las llama a su reino celestial."], ["Quiero ser un buen maestro como fuera el Redentor;", "dar la Biblia al mundo entero, donde encuentre salvación.", "A los que le han conocido Jesucristo invita hoy", "a salvar a los perdidos por los cuales él murió."], ["Con las huestes celestiales ante el trono de Jesús", "han de unirse los mortales redimidos por la cruz.", "Y con gozo allá en la gloria cantaremos la canción", "de los triunfos y victorias que nos dieron redención."]],
        "refrainFirst": false,
        "refrain": ["Un lugar propicio quiero donde pueda trabajar;", "aunque humilde, yo prefiero ir de Cristo a predicar;", "dar la luz de Cristo al mundo", "que se encuentra en perdición,", "ha de ser celo profundo que domine el corazón."]
      }, {
        "name": "Id Y predicad el evangelio",
        "number": "569",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Oíd, oíd lo que nos manda el Salvador:", "\"Marchad, marchad, y proclamad mi amor;", "pues he aquí yo con vosotros estaré;", "los días todos hasta el fin os guardaré\"."], ["Mirad, mirad la condición del pecador;", "¡qué triste es! ¡qué llena de dolor!", "Sin luz, sin paz, camina a la destrucción,", "y no conoce el gran peligro en que está."], ["Salid, salid, embajadores del Señor;", "buscad, buscad al pobre pecador.", "Aprovechad el tiempo que el Señor os da,", "pues pronto el día de salud acabará."]],
        "refrainFirst": false,
        "refrain": ["Id, id por el mundo;", "id, id y predicad el evangelio.", "Id, id, va adelante", "el todopoderoso Salvador.", "Gloria, gloria, aleluya a Jesús.", "Gloria, gloria, aleluya a Jesús.", "Nuestras almas él salvó,", "nuestras manchas él lavó;", "proclamemos, pues, a todos su amor."]
      }, {
        "name": "Voluntario del Señor",
        "number": "570",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Voluntario del Señor,", "listo a servir,", "a llevar el evangelio", "do me toque ir."], ["En su viña quiero obrar", "y ocupado estar,", "mientras intercede", "Cristo presto a salvar."], ["Cuando venga el Señor,", "él me premiará,", "y su tierna voz:", "\"Bien hecho siervo fiel\", dirá."]],
        "refrainFirst": false,
        "refrain": ["Voluntad, voluntad,", "Cristo me pidió.", "Voluntad, voluntad,", "eso ofrezco yo;", "para Cristo quiero ser", "fiel trabajador,", "misionero voluntario", "para el Salvador."]
      }, {
        "name": "La historia de Cristo contemos",
        "number": "571",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["La historia de Cristo contemos,", "que dará al mundo la luz;", "la paz y el perdón anunciemos,", "comprados en cruenta cruz,", "comprados en cruenta cruz."], ["La historia de Cristo daremos", "al mortal que ignora su amor;", "nos dio Dios al Hijo, diremos,", "hallamos en él favor,", "hallamos en él favor."], ["A Jesús todos confesaremos,", "con sincero y fiel corazón;", "sus méritos invocaremos,", "y Dios nos dará el perdón,", "y Dios nos dará el perdón."]],
        "refrainFirst": false,
        "refrain": ["Rescatonos de las tinieblas,", "disipó nuestra oscuridad;", "él nos salvó, nuestra paz compró,", "nos dio luz y libertad."]
      }, {
        "name": "Pescadores de hombres",
        "number": "572",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Pescadores de hombres seréis,", "de hombres seréis,", "de hombres seréis;", "pescadores de hombres seréis", "al seguirme a mí.", "Al seguirme a mí, al seguirme a mí.", "Pescadores de hombres seréis,", "al seguirme a mí."], ["Cristo llama: \"Venid a mí,", "venid a mí, venid a mí\".", "Cristo llama: \"Venid a mí,", "y descansaréis\".", "\"Y descansaréis,", "y descansaréis\".", "Cristo llama: \"Venid a mí,", "y descansaréis\"."], ["Cristo dice: \"Yo soy la Luz,", "Yo soy la Luz, Yo soy la Luz\".", "Cristo dice: \"Yo soy la Luz,", "a la Luz venid\".", "\"A la Luz venid, a la Luz venid\".", "Cristo dice: \"Yo soy la Luz,", "a la Luz venid\"."]]
      }, {
        "name": "Te envío a ti",
        "number": "573",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Te envío a ti, a un mundo que perece,", "para servir a Dios con devoción.", "Te envío a ti, te sostendrá mi gracia,", "y en mi nombre tú podrás vencer."], ["Te envío a ti, aun mundo desolado", "que olvidó el precio de la cruz;", "ve a decirles cuánto Dios los ama,", "que por salvarlos a su Hijo dio."], ["Tal vez mañana sea ya muy tarde,", "y la cosecha puédase perder.", "Hoy es el día, anda y no te tardes,", "contigo iré la mies a recoger."], ["Te envío a ti, aunque el sendero pase", "por valles negros de tribulación.", "Se acerca el día en que podré decirte:", "buen siervo fiel, recibe el galardón.", "Como el Padre me envió a mí,", "yo te envío a ti."]]
      }, {
        "name": "Testimonio",
        "number": "574",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["En mi vivir voy a decir que Cristo", "murió por mí en la cruz y me salvó.", "En mi vivir voy a decir que Cristo", "me prometió que pronto volverá.", "Al cantar lleno de felicidad,", "mi vivir mostrará quién es Jesús.", "Al cantar lleno de felicidad,", "quiero ser la razón de vida y luz."], ["En mi hablar voy a contar que Cristo", "murió por mí en la cruz y me salvó.", "En mi hablar voy a contar que Cristo", "me prometió que pronto volverá.", "Al cantar lleno de felicidad,", "mi hablar contará quién es Jesús.", "Al cantar lleno de felicidad,", "quiero ser la razón de vida y luz."], ["En mi reír voy a mostrar que Cristo", "murió por mí en la cruz y me salvó.", "En mi reír vaya mostrar que Cristo", "me prometió que pronto volverá.", "Al cantar lleno de felicidad,", "mi reír mostrará quién es Jesús.", "Al cantar lleno de felicidad,", "quiero ser la razón de vida y luz."]]
      }, {
        "name": "Tocad trompeta ya",
        "number": "575",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Tocad trompeta ya,", "alegres en Sion;", "al mundo proclamad", "la eterna redención."], ["A Cristo predicad;", "decid que ya murió", "y con su potestad", "la muerte destruyó."], ["Vosotros que el favor", "del Cielo despreciáis,", "su gracia gozaréis", "si a Cristo pronto vais."], ["Llamadles con amor;", "id, ofrecedles paz.", "Es tarde, apresurad;", "que vuelvan a su faz."]],
        "refrainFirst": false,
        "refrain": ["Este es el año de bondad,", "volved a vuestra libertad,", "volved a vuestra libertad."]
      }, {
        "name": "Proclamo hoy que soy cristiano",
        "number": "576",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Proclamo hoy que soy cristiano,", "jamás su nombre negaré.", "Proclamo hoy que soy cristiano;", "con Jesucristo doquiera iré.", "Proclamo hoy que es poderoso,", "formó en mi alma un nuevo ser.", "Fía en él, que a todos salva;", "murió por mí, sí, también por ti.", "Proclamo hoy su amor eterno;", "ninguno como mi Jesús.", "Le doy mi vida para siempre.", "A Cristo sigo sin vacilar."], ["Proclamo hoy que vuelve pronto,", "pues su promesa cumplirá.", "Debemos todos prepararnos,", "con gran fervor y con devoción.", "Oh, mi hermano, vuelve Cristo,", "nos trae solo, dicha inmortal.", "Más allá de nuestros sueños,", "un mundo nuevo será sin fin.", "Proclama tú que eres cristiano,", "jamás su nombre has de negar.", "Proclama, sí, que eres cristiano,", "vive por Cristo, tu Salvador."]]
      }, {
        "name": "Yo quiero trabajar",
        "number": "577",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Yo quiero trabajar por el Señor,", "creyendo en su Palabra y en su amor;", "quiero, sí, cantar y orar,", "y ocupado siempre estar", "en la viña del Señor."], ["Yo quiero cada día trabajar,", "y esclavos del pecado libertar,", "llevarlos a Jesús,", "nuestro guía, nuestra luz,", "en la viña del Señor."], ["Yo quiero ser obrero de valor,", "confiando en el poder del Salvador.", "Quien quiera trabajar", "hallará también lugar", "en la viña del Señor."]],
        "refrainFirst": false,
        "refrain": ["Trabajar y orar", "en la viña, en la viña del Señor;", "sí, mi anhelo es orar,", "y ocupado siempre estar", "en la viña del Señor."]
      }, {
        "name": "El pueblo que conoce a su Dios",
        "number": "578",
        "category": "La iglesia",
        "subcategory": "Misión de la iglesia",
        "verses": [["Atardece en el mundo:", "hay violencia y maldad.", "Desafiantes las tinieblas", "hoy ocultan la verdad."], ["Atardece en el cielo:", "ya la gracia cesará.", "Lanza Dios el desafío:", "¿Quién mi obra acabará?"]],
        "refrainFirst": false,
        "refrain": ["\"Mas el pueblo que a su Dios conoce,", "se esforzará y hará\".", "¡De pie, de pie, oh pueblo!", "¡Conoces a tu Dios!", "¡Levántate y concluye", "la obra que él te dio!"]
      }, {
        "name": "La fuente veo",
        "number": "579",
        "category": "La iglesia",
        "subcategory": "Bautismo",
        "verses": [["La fuente veo carmesí,", "el ancho manantial,", "que de Jesús, mi Salvador,", "emana perennal."], ["Soy nueva criatura en él;", "me hizo renacer,", "y el hombre viejo nunca más", "podrá prevalecer."], ["Gozoso espero ir con Jesús", "a mi celeste hogar;", "allí, cual fuente, de la cruz", "su amor ha de manar."], ["Anhelo en la luz a andar", "sin el mundano error;", "deseo un limpio corazón", "que agrade al Salvador."]],
        "refrainFirst": false,
        "refrain": ["La fuente veo carmesí;", "con su poder me limpia a mí.", "¡Oh, gloria a Dios!, me limpia a mí,", "me limpia, ¡oh sí!, me limpia a mí."]
      }, {
        "name": "Las aguas del bautismo",
        "number": "580",
        "category": "La iglesia",
        "subcategory": "Bautismo",
        "verses": [["En las aguas de la muerte sumergido fue Jesús;", "mas su amor no fue apagado por las penas de la cruz.", "Levantose de la tumba, sus cadenas quebrantó,", "y triunfante y victorioso a los cielos ascendió."], ["En las aguas del bautismo hoy confieso yo mi fe:", "Jesucristo me ha salvado y en su amor me gozaré.", "En las aguas, humillado, a Jesús siguiendo voy;", "desde ahora para el mundo y el pecado muerto estoy."], ["Ya que estoy crucificado, ¿cómo más podré pecar?;", "por su gracia transformado, vida nueva he de llevar.", "A las aguas del bautismo me llevó la contrición;", "desde ahora me consagro al que obró mi redención."]]
      }, {
        "name": "El Pan de vida soy",
        "number": "581",
        "category": "La iglesia",
        "subcategory": "Cena del Señor",
        "verses": [["\"El Pan de vida soy\", dice el Señor;", "\"ven, alma hambrienta, ahora al Salvador;", "hambre jamás tendrá quien viene a mí,", "sed nunca sentirá quien cree en mí\"."], ["Vertiste tú por mí, buen Salvador,", "tu sangre, en prueba de tu santo amor.", "Cristo, hazme recordar tu gran dolor;", "aprecie yo tu amor y salvación."], ["Hazme vivir, Señor, cerca de ti;", "la deuda de tu amor la siento en mí;", "te entrego a ti mi ser, mi corazón.", "¡Loor a ti, Señor, y bendición!"]]
      }, {
        "name": "Hoy venimos cual hermanos",
        "number": "582",
        "category": "La iglesia",
        "subcategory": "Cena del Señor",
        "verses": [["Hoy venimos cual hermanos", "a la Cena del Señor;", "acerquémonos, cristianos;", "que nos llene santo amor."], ["En memoria de su muerte", "y la sangre que vertió,", "celebremos el banquete", "que en su amor nos ordenó."], ["Recordando las angustias", "que sufriera el Salvador,", "dividida se halla el alma", "entre el gozo y el dolor."], ["Invoquemos la presencia", "del divino Redentor;", "que nos mire con clemencia", "y nos llene de su amor."]]
      }, {
        "name": "La Cena de la Comunión",
        "number": "583",
        "category": "La iglesia",
        "subcategory": "Cena del Señor",
        "verses": [["Con gran amor Cristo preparó", "la Cena de la Comunión.", "Con devoción celebramos hoy", "su muerte y resurrección."], ["Con humildad les lavó los pies,", "ejemplo dio de amor sin par.", "Nos llama hoy a servir también,", "a compartir su inmenso amor."], ["Partió el pan y la copa dio;", "sublime don, pues nos amó.", "Cualquiera carga que tengas hoy,", "él por su cruz la quitará."], ["Nos llenarán, al salir de aquí,", "su gracia y paz, su gozo y luz.", "En su mansión siempre viviré.", "¡Oh, qué será ver al Señor!"]]
      }, {
        "name": "Amémonos, hermanos",
        "number": "584",
        "category": "La iglesia",
        "subcategory": "Cena del Señor",
        "verses": [["Amémonos, hermanos, con tierno y puro amor;", "pues somos la familia de nuestro Padre, Dios.", "Amémonos, hermanos, lo quiere el Salvador,", "que su preciosa sangre por todos derramó."], ["Amémonos, hermanos, en dulce comunión,", "y paz y afecto y gracia dará el Consolador.", "Amémonos, hermanos, y en nuestra santa unión", "no existan asperezas ni discordante voz."], ["Amémonos, hermanos, y al mundo pecador", "mostremos cómo viven los que salvados son.", "Amémonos, hermanos, de todo corazón;", "lo ordena Dios, el Padre: su Ley es ley de amor."]]
      }, {
        "name": "De rodillas partimos hoy el pan",
        "number": "585",
        "category": "La iglesia",
        "subcategory": "Cena del Señor",
        "verses": [["De rodillas partimos hoy el pan;", "de rodillas partimos hoy el pan."], ["De la copa bebemos hoy, Señor;", "de la copa bebemos hoy, Señor."], ["Te alabamos con gozo, oh Señor;", "te alabamos con gozo, oh Señor."]],
        "refrainFirst": false,
        "refrain": ["Y contritos aquí,", "nos postramos ante ti;", "Señor, escucha nuestra voz."]
      }, {
        "name": "En memoria de mí",
        "number": "586",
        "category": "La iglesia",
        "subcategory": "Cena del Señor",
        "verses": [["En memoria de mí, esto haced:", "hoy comed este pan, con unción.", "En memoria de mí, todos bebed", "la copa de comunión."], ["En memoria de mí, bien haced;", "al enfermo sanad con amor.", "En memoria de mí, doquiera vais", "llevad consolación, paz y perdón.", "Nuevo pacto hago hoy,", "mi sangre y cuerpo os doy;", "ofrenda preciosa de salvación", "y perdón eternal."], ["En memoria de mí, que os améis", "como yo, con amor fraternal.", "En memoria de mí, amad a Dios", "de corazón, amad a Dios, a Dios.", "En memoria de mí."]]
      }, {
        "name": "Te dedicamos, oh Señor",
        "number": "587",
        "category": "La iglesia",
        "subcategory": "Dedicación de un templo",
        "verses": [["Te dedicamos, oh Señor,", "hoy esta casa de oración.", "Con gratitud y devoción", "la consagramos a tu honor."], ["Que tu presencia siempre aquí", "sea un refugio al mortal.", "Escucha toda oración", "y acepta nuestra adoración."], ["Oh, Padre, ven a bendecir", "este lugar y a tu grey.", "Que en este templo siempre esté", "tu Santo Espíritu hasta el fin."]]
      }, {
        "name": "Ven, alma que lloras",
        "number": "588",
        "category": "La iglesia",
        "subcategory": "Funeral",
        "verses": [["Ven, alma que lloras, ven al Salvador;", "en tus tristes horas dile tu dolor.", "Sí, dile tu duelo; ven tal como estás;", "habla sin recelo, y no llores más."], ["Penas y tristezas, dilas al Señor;", "crueles desventuras, lágrimas y error;", "en su tierno seno tú descansarás;", "ven, Cristo es bueno, y no llores más."], ["Guía al extraviado, muéstrale tu luz.", "Lleva al angustiado al manso Jesús.", "La bendita nueva de celeste paz", "al triste, pues, lleva, y no llores más."]]
      }, {
        "name": "Perfecto amor",
        "number": "589",
        "category": "El hogar cristiano",
        "subcategory": "Boda",
        "verses": [["Perfecto amor del Cielo descendiendo,", "por ti, Señor, enviado a este hogar;", "por este don tan noble y venturoso", "las gracias hoy venímoste a dar."], ["Perfecto amor, ¡oh Dios!, tu don gratuito", "sea abundante, y de este hogar solaz;", "sagrado amor que sea para siempre", "fuente de gozo, esperanza y paz."], ["Perfecto amor, ¿qué más desea el alma,", "de bendiciones rebosando ya?", "Amor que ensalce, de tu amor la gloria,", "y que perdure por la eternidad."]]
      }, {
        "name": "Guía a ti, Señor",
        "number": "590",
        "category": "El hogar cristiano",
        "subcategory": "Hogar cristiano",
        "verses": [["Guía a ti, Señor, guía a ti", "los hijos tiernos que me has dado a mí.", "¡Oh, por tu grande amor, guíalos, Dios, a ti!"], ["Cuando del mundo vean el esplendor,", "entonces guárdalos del tentador.", "De sus engaños ¡oh, guíalos, Dios, a ti!"], ["Los niños a salvar vino Jesús,", "y anduvo sin pecar hasta la cruz.", "Suplico por tu amor: ¡guíalos, Dios, a ti!"], ["Aunque me falte fe quiero creer", "que este precioso don quieres tener:", "tiernos de corazón, hoy los devuelvo a ti."]],
        "refrainFirst": false,
        "refrain": ["Guíalos, Dios, a ti,", "guíalos, sí."]
      }, {
        "name": "Todo es bello en el hogar",
        "number": "591",
        "category": "El hogar cristiano",
        "subcategory": "Hogar cristiano",
        "verses": [["Todo es bello en el hogar cuando hay amor;", "nada allí podrá dañar cuando hay amor.", "Paz y gozo se hallarán, fuerzas se restaurarán", "y el Señor será el Guardián cuando hay amor."], ["Hasta en chozas hay placer cuando hay amor;", "odio y mal no puede haber cuando hay amor.", "Cada rosa en el jardín, los claveles y el jazmín", "a mis males ponen fin cuando hay amor."], ["Tiene el labio su canción cuando hay amor;", "llega el cielo al corazón cuando hay amor.", "El riacho al murmurar y las aves al cantar", "nos inspiran sin cesar cuando hay amor."], ["Mi Jesús, te ruego hoy más de ese amor.", "Ya que tuyo siempre soy, dame ese amor.", "Los que tienes en tu grey siempre andan en tu Ley", "y te honran como Rey por tu gran amor."]],
        "refrainFirst": false,
        "refrain": ["Con amor, con amor,", "todo es bello en derredor", "cuando hay amor."]
      }, {
        "name": "Si Dios está, ¡feliz hogar!",
        "number": "592",
        "category": "El hogar cristiano",
        "subcategory": "Hogar cristiano",
        "verses": [["Si Dios está, ¡feliz hogar!", "Su gran amor nos da.", "Un solo anhelo allí habrá:", "la dicha celestial."], ["Si Cristo está, ¡feliz hogar!", "Los niños cantarán", "y los adultos hablarán", "del Nombre sin igual."], ["Pido, Señor, tu bendición", "sobre mi humilde hogar,", "para que reine tu amor", "hasta la eternidad."]]
      }, {
        "name": "Hogar de mis recuerdos",
        "number": "593",
        "category": "El hogar cristiano",
        "subcategory": "Hogar cristiano",
        "verses": [["Hogar de mis recuerdos, a ti volver anhelo;", "no hay sitio bajo el cielo más dulce que el hogar.", "Posara yo en palacios, corriendo el mundo entero,", "a todos yo prefiero mi hogar, mi dulce hogar."], ["Allí la luz del cielo desciende más serena;", "de mil delicias llena la dicha del hogar.", "Allí las horas corren más breves y gozosas;", "memorias muy dichosas nos hablan sin cesar."], ["Más quiero que placeres que brinda tierra extraña,", "volver a la cabaña de mi tranquilo hogar.", "Allí mis pajarillos me alegran con sus cantos;", "allí con mil encantos está la dulce paz."], ["Un día muy cercano iré al hogar celeste,", "de dicha inefable, reposo y solaz.", "Allí no habrá más llanto, enfermedad, ni muerte;", "rebosará mi mente de gozo, amor y paz."]],
        "refrainFirst": false,
        "refrain": ["[1, 2 y 3:] ¡Mi hogar, mi hogar!", "No hay sitio bajo el cielo", "más dulce que mi hogar.", "[4:] ¡Mi hogar, mi hogar!", "Anhelo ir al cielo,", "mi eterno y dulce hogar."]
      }, {
        "name": "Señor, gracias por mi hogar",
        "number": "594",
        "category": "El hogar cristiano",
        "subcategory": "Hogar cristiano",
        "verses": [["Horas alegres hay", "en la niñez feliz,", "mientras mamá vigila fiel.", "Señor gracias, por mi hogar"], ["Horas inquietas hay", "cuando hay que decidir:", "sabios consejos recibir,", "Señor, gracias por mi hogar."], ["Horas serenas hay", "para reflexionar,", "y agradecidos hoy decir:", "Señor, gracias por mi hogar."]]
      }, {
        "name": "Feliz hogar",
        "number": "595",
        "category": "El hogar cristiano",
        "subcategory": "Hogar cristiano",
        "verses": [["Feliz hogar, el que a ti recibe,", "Señor Jesús, el huésped de honor.", "Feliz hogar, el que con alegría", "busca servirte siempre con amor."], ["Feliz hogar, dichosos los esposos", "que en unidad te buscan, oh Señor.", "Tu mano pueda, en toda circunstancia,", "rodearlos de un muro protector."], ["Feliz hogar, en el que te entregan", "los hijos, Dios, en oración de fe.", "Tiernos aún, aprenden a amarte,", "oyen tu voz, se sientan a tus pies."]]
      }, {
        "name": "Edificamos familias",
        "number": "596",
        "category": "El hogar cristiano",
        "subcategory": "Hogar cristiano",
        "verses": [["El hogar es lugar donde encontramos", "un refugio de paz y aceptación.", "Aunque no compartamos opiniones,", "nos reunimos allí pues hay amor."], ["Compartimos sonrisas y alegría,", "nuestros sueños se vuelven realidad.", "Las palabras de aprecio nunca faltan", "si Jesús es el centro del hogar."], ["Tu iglesia, Señor, es la familia", "que nos une, alienta y da valor.", "De distintas naciones y colores,", "pero todos con una misma fe."]],
        "refrainFirst": false,
        "refrain": ["Edificamos familias,", "necesitamos del Señor.", "Él es el único que puede", "llenar nuestras vidas con amor."]
      }, {
        "name": "Oración por un niño",
        "number": "597",
        "category": "El hogar cristiano",
        "subcategory": "Dedicación de un niño",
        "verses": [["Presentamos este niño;", "es tan frágil cual la flor;", "necesita de cariño,", "necesita de amor.", "Sus ojitos son muy puros;", "su sonrisa es cual la luz,", "y refleja la belleza", "que emana de Jesús."], ["Guía sus pequeños pasos,", "y enséñale a andar.", "En seguro y buen camino", "pueda siempre continuar.", "Que tu ángel lo proteja", "de este mundo y su maldad;", "Padre amante, ya es tuyo.", "En tus brazos guárdalo."]]
      }, {
        "name": "Cristo, yo te seguiré",
        "number": "598",
        "category": "El hogar cristiano",
        "subcategory": "Niños",
        "verses": [["Cristo, yo te seguiré;", "óigote llamándome;", "vengo a ti con fe y amor,", "y a tu mansión, Señor."], ["Mis ojuelos no verán;", "mis piecitos errarán;", "débil me hallo en el vaivén;", "mas tú eres mi sostén."], ["Cuando solo y triste estoy", "siempre a ti, Jesús, yo voy;", "y ¡qué gozo es para mí,", "seguirte siempre a ti!"]],
        "refrainFirst": false,
        "refrain": ["Yo te seguiré, yo te seguiré,", "yo te seguiré, bendito Salvador."]
      }, {
        "name": "En este bello día",
        "number": "599",
        "category": "El hogar cristiano",
        "subcategory": "Niños",
        "verses": [["En este bello día", "de gozo, amor y luz,", "cantamos alabanzas", "a nuestro Rey, Jesús."], ["Jesús amó a los niños", "estando aún aquí,", "y ahora en los cielos", "los ama siempre así."], ["La historia de su vida", "nos gusta escuchar;", "su amor y mansedumbre", "queremos imitar."], ["¡Oh! Salvador bendito,", "rogamos hoy a ti", "nos guardes del maligno", "en nuestra senda aquí."]],
        "refrainFirst": false,
        "refrain": ["Cantad, cantad,", "a Cristo dad loor;", "cantad, cantad", "la gloria del Señor."]
      }, {
        "name": "Cuando venga Jesucristo",
        "number": "600",
        "category": "El hogar cristiano",
        "subcategory": "Niños",
        "verses": [["Cuando venga Jesucristo", "en busca de joyas,", "todo niño redimido", "su gema será."], ["Quiere Cristo en su corona", "brillantes estrellas;", "cada gema que le adorna", "con sangre compró."], ["Él escoge por tesoros", "los niños amados,", "y en su seno a los corderos", "recibe Jesús."], ["Si los niños y las niñas", "acuden a Cristo,", "bellas joyas, escogidas,", "serán para él."]],
        "refrainFirst": false,
        "refrain": ["Como estrellas que brillan", "son los niños que le aman;", "son tesoros que adornan", "al Rey y Señor."]
      }, {
        "name": "Cuando leo en la Biblia",
        "number": "601",
        "category": "El hogar cristiano",
        "subcategory": "Niños",
        "verses": [["Cuando leo en la Biblia cómo llama Jesús", "y bendice a los niños con amor,", "yo también quisiera estar,", "y con ellos descansar en los brazos del tierno Salvador."], ["Ver quisiera sus manos sobre mí reposar,", "cariñosos abrazos de él sentir;", "su mirada disfrutar,", "las palabras escuchar: \"A los niños dejad a mí venir\"."], ["¡Cuántos hay que no saben de la bella mansión,", "y no quieren a Cristo recibir!", "Les quisiera yo mostrar", "que para ellos hay lugar en el cielo, do los convida a ir."], ["Yo espero aquel día venturoso, sin fin,", "el más grande, el más lúcido, el mejor,", "cuando de toda nación,", "niños mil sin distinción a los brazos acudan del Señor."]]
      }, {
        "name": "Es el amor divino",
        "number": "602",
        "category": "El hogar cristiano",
        "subcategory": "Niños",
        "verses": [["Es el amor divino", "mi gozo y mi placer,", "allana mi camino", "y me hace obedecer."], ["Del triste mundo lleno", "de muerte y dolor,", "quisiera yo llevar a Dios", "un pobre pecador."], ["Y cuando vaya al cielo", "con Cristo, mi Señor,", "allí por siempre cantaré", "de Dios y de su amor."]],
        "refrainFirst": false,
        "refrain": ["Dios es amor,", "soy su pequeñuelo;", "quiero ser santo", "como es él.", "Es el amor divino", "mi gozo y mi placer,", "allana mi camino", "y me hace obedecer."]
      }, {
        "name": "Yo temprano busco a Cristo",
        "number": "603",
        "category": "El hogar cristiano",
        "subcategory": "Niños",
        "verses": [["Yo temprano busco a Cristo,", "cada día aprendo de él;", "por la senda angosta sigo", "sus pisadas, firme y fiel."], ["Dondequiera que él me mande,", "yo con gozo presto voy;", "sé su voluntad divina", "aunque niño tierno soy."], ["A la puerta Cristo aguarda:", "él me quiere libertar;", "yo, confiando en su promesa,", "hoy invítole a entrar."]],
        "refrainFirst": false,
        "refrain": ["Cristo me ama; Cristo me ama;", "Cristo me ama, esto sé:", "él murió para salvarme;", "yo, ferviente, le amaré."]
      }, {
        "name": "Bellas las manitas son",
        "number": "604",
        "category": "El hogar cristiano",
        "subcategory": "Niños",
        "verses": [["Bellas las manitas son", "que obedecen a Jesús;", "bellos ojos los que están", "llenos de célica luz."], ["Hizo las manitas Dios,", "porque a él podrán servir;", "hizo al tierno corazón", "en su servicio latir."], ["Toda boca debe orar", "cada día al Salvador,", "y los pies habrán de andar", "siempre siguiendo al Señor."], ["Cuanto puedas tú hacer,", "Cristo te lo exigirá;", "haz de ello tu placer", "con el poder que él te da."]],
        "refrainFirst": false,
        "refrain": ["Bellas, sí, bellas las manos son,", "que obedecen a Jesús;", "bellos, los ojos que siempre están", "llenos de célica luz."]
      }, {
        "name": "Jesús tiene tiempo",
        "number": "605",
        "category": "El hogar cristiano",
        "subcategory": "Niños",
        "verses": [["Mientras Jesús enseñaba a la gente,", "madres traían sus niños a él.", "\"Salgan, no estorben\", dijeron algunos;", "\"no tiene tiempo, salid\"."]],
        "refrainFirst": false,
        "refrain": ["\"Por favor, den lugar, que se acerquen a mí;", "vengan ya, sin temor, pues los quiero recibir.", "Para dar bendición tengo tiempo y amor;", "de los niños mi reino será\"."]
      }, {
        "name": "Llama Jesús, el Buen Pastor",
        "number": "606",
        "category": "El hogar cristiano",
        "subcategory": "Niños",
        "verses": [["Llama Jesús, el Buen Pastor,", "a los corderos con amor.", "Tantas ovejas lejos van,", "de su redil vagando están."], ["¿Quién al Pastor ayudará", "y a los corderos buscará?", "¿Quién al redil los traerá,", "do protegidos los tendrá?"], ["Por el desierto lejos van,", "y en el frío errando están.", "Pues, el Señor te dice a ti:", "\"Mis corderitos trae a mí\"."]],
        "refrainFirst": false,
        "refrain": ["Búscalos, llámalos,", "los niñitos trae al Salvador.", "Búscalos, llámalos,", "los niñitos trae a Cristo."]
      }, {
        "name": "Nítido rayo por Cristo",
        "number": "607",
        "category": "El hogar cristiano",
        "subcategory": "Niños",
        "verses": [["Nítido rayo por Cristo,", "yo quiero siempre ser;", "en todo quiero agradarle,", "y hacerlo con placer."], ["A Cristo quiero acercarme", "en mi temprana edad;", "por siempre quiero amarlo", "y hacer su voluntad."], ["Nítido rayo en tinieblas", "deseo siempre ser.", "Almas perdidas a Cristo", "anhelo conducir."], ["Una mansión en el cielo", "fue Cristo a preparar;", "y cuando venga a buscarme", "con él iré a morar."]],
        "refrainFirst": false,
        "refrain": ["Un nítido rayo,", "nítido rayo por Cristo.", "Un nítido rayo,", "nítido rayo seré."]
      }, {
        "name": "Corazones siempre alegres",
        "number": "608",
        "category": "El hogar cristiano",
        "subcategory": "Jóvenes",
        "verses": [["Corazones siempre alegres,", "rebosando gratitud,", "somos los que a Dios amamos,", "redimida juventud."], ["Dios nos guía de la mano;", "nos ampara su poder;", "es su brazo poderoso", "y nos quiere defender."], ["Si en la lucha desmayamos", "o nos sitia la maldad,", "con su gracia nos anima,", "nos levanta su bondad."], ["Con sus fuerzas llevaremos", "muy gozosos nuestra cruz;", "victoriosos cantaremos", "en la gloria de su luz."]],
        "refrainFirst": false,
        "refrain": ["Siempre alegres vamos todos,", "llenos de felicidad;", "hermosísimo es el camino,", "hacia la eternidad."]
      }, {
        "name": "¡Oh jóvenes, venid!",
        "number": "609",
        "category": "El hogar cristiano",
        "subcategory": "Jóvenes",
        "verses": [["¡Oh jóvenes, venid! Su brillante pabellón", "Cristo ha desplegado ante la nación.", "A todos en sus filas os quiere recibir,", "y consigo a la pelea os hará salir."], ["¡Oh jóvenes, venid! El Caudillo Salvador", "quiere recibiros en su derredor;", "con él a la batalla salid sin vacilar.", "¡Vamos presto, compañeros, vamos a luchar!"], ["Las armas invencibles del Jefe guiador", "son el evangelio y su gran amor.", "Con ellos revestidos y llenos de poder,", "¡compañeros, acudamos, vamos a vencer!"], ["Quien venga a la pelea su voz escuchará;", "Cristo la victoria le concederá;", "salgamos, compañeros, luchemos bien por él;", "con Jesús conquistaremos inmortal laurel."]],
        "refrainFirst": false,
        "refrain": ["¡Vamos con Jesús, alistados sin temor!", "¡Vamos a la lid, inflamados de valor!", "Jóvenes, luchemos todos contra el mal,", "que en Jesús tenemos nuestro General."]
      }, {
        "name": "Escuchamos tu llamada",
        "number": "610",
        "category": "El hogar cristiano",
        "subcategory": "Jóvenes",
        "verses": [["Escuchamos tu llamada,", "respondemos con placer;", "una lealtad constante,", "nuestro voto debe ser."], ["Dondequiera tú nos guíes,", "vengan pruebas o desdén,", "seguiremos tu llamada;", "solo da la orden: \"Ven\"."], ["Tú nos diste aptitudes,", "que debemos emplear;", "nuestro tiempo todo es tuyo,", "te queremos ayudar."], ["Danos una gran tarea;", "la esperamos hoy, Señor.", "Con placer la cumpliremos,", "con arrojo y sin temor."]],
        "refrainFirst": false,
        "refrain": ["A ti, oh Jesús, damos todo nuestro ser,", "pues la juventud redimiste para ti.", "Con talentos consagrados empeñados en servir,", "la juventud del mundo, de Cristo ha de ser."]
      }, {
        "name": "Oh, juventud del Rey",
        "number": "611",
        "category": "El hogar cristiano",
        "subcategory": "Jóvenes",
        "verses": [["Oh, juventud del Rey,", "Jesús nos llama hoy.", "Digámosle de corazón:", "mi vida yo te doy."], ["Cumplamos la misión,", "que Cristo nos dejó,", "de predicar su amor", "a quien Satán aprisionó."]]
      }, {
        "name": "Jesús te necesita hoy",
        "number": "612",
        "category": "El hogar cristiano",
        "subcategory": "Jóvenes",
        "verses": [["Jesús te necesita hoy, cristiana juventud;", "tu fuerza solicita, tu celo y tu virtud.", "La frente, pues, levanta y empuña con valor", "la enseña sacrosanta de Cristo, tu Señor."], ["A noble lucha llama hoy Jesús tu Salvador;", "levántate y proclama los dones de su amor.", "Si siembra desalientos el malo con su voz,", "oculta tus lamentos, que va contigo Dios."]],
        "refrainFirst": false,
        "refrain": ["Adelante, cristiana juventud;", "adelante, vamos, vamos todos con valor.", "Adelante, cristiana juventud;", "adelante, jóvenes, ¡id a luchar!"]
      }, {
        "name": "Hoy nos toca trabajar",
        "number": "613",
        "category": "El hogar cristiano",
        "subcategory": "Jóvenes",
        "verses": [["Hoy nos toca trabajar, hay batallas que ganar,", "y nos pide el Capitán: voluntad, voluntad.", "Al sonido del clarín, presurosos acudid,", "jóvenes, el Señor llama hoy."], ["Los soldados de Satán derrotados quedarán.", "Cerca el triunfo vemos ya. ¡Voluntad, voluntad!", "La armadura hoy vestid, adelante proseguid,", "jóvenes, el Señor llama hoy."], ["La victoria es nuestra ya, Satanás vencido está.", "A ninguno ha de faltar voluntad, voluntad.", "Si este triunfo obtenéis, el laurel alcanzaréis,", "jóvenes, el Señor llama hoy."]],
        "refrainFirst": false,
        "refrain": ["Cristo es nuestro Capitán, firmes avanzad.", "Almas hay que rescatar; id sin vacilar,", "jóvenes, el Señor llama hoy."]
      }],
    "categories": {
      "main": [[0, 52, 77, 189, 203, 208, 344, 527, 588, 613], ["El culto", "Dios el Padre", "Jesucristo", "El Espíritu Santo", "Las Sagradas Escrituras", "El evangelio", "La vida cristiana", "La iglesia", "El hogar cristiano", "Amenes"]],
      "sub": [[0, 21, 34, 45, 47, 52, 59, 77, 92, 102, 106, 126, 157, 189, 203, 208, 237, 244, 283, 310, 315, 344, 364, 372, 390, 483, 465, 473, 486, 490, 504, 520, 525, 527, 533, 534, 550, 578, 580, 586, 587, 588, 589, 596, 597, 607, 613, 614], ["Adoración y alabanza", "Inicio del culto", "Cierre del culto", "Culto matutino", "Culto vespertino", "Amor y fidelidad de Dios", "Majestad y poder de Dios", "Nacimiento de Cristo", "Muerte de Cristo", "Resurrección de Cristo", "Amor de Cristo", "Alabanza de Cristo", "Segunda venida de Cristo", undefined, undefined, "Invitación", "Arrepentimiento", "Consagración", "Salvación y redención", "Juicio", "Hogar celestial", "Gozo y paz", "Gratitud", "Oracion y comunión", "Confianza y seguridad", "Petición y anhelo", "Dirección divina", "Peregrinación", "Obediencia", "Servicio cristiano", "Lucha contra el mal", "Mayordomía", "Amor a la patria", "Iglesia", "Escuela Sabática", "Sábado", "Misión de la iglesia", "Bautismo", "Cena del Señor", "Dedicación de un templo", "Funeral", "Boda", "Hogar cristiano", "Dedicación de un niño", "Niños", "Jóvenes", undefined]]
    }
  },
});

// export default data
